angular.module('app.directives').directive('tooltip', function(){
    return {
        restrict: 'A',
        scope: {
            data: '='
        },
        link: function(scope, element, attrs){
            $(element).tooltip({
                trigger: 'hover',
                title: scope.data,
                animation : false
            });
        }
    };
});