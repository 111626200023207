angular.module('app').controller('adminPackagesEditCtrl', function ($scope, $stateParams, $rootScope, $state, $translate, $filter, Notification, ngDialog, I3PackageSrv, I3ProductSrv) {
    $scope.productSelected = {};
    $scope.package = {};
    $scope.options_list = [];
    $scope.device_types_list = [];

    $scope.options = null;
    $scope.reverseTranslatedOptions = {};
    $scope.translatedOptions = null;
    I3PackageSrv.query({
        query: {
            _id: $stateParams.packageId
        },
        options: {
            populate: {
                path: "_products",
                model: "product"
            }
        }
    }, 1, 0, function (response) {
        $scope.package = response.result[0];
        $scope.options_list = $scope.package._products;
        $scope.device_types_list = $scope.package.device_types;
    }, function (err) {
        console.log(err);
    });

    $scope.save = function () {
        // Save the key in database (non-translated)
        $scope.package._products = $scope.options_list.map(function (option) {
            return option._id;
        });

        $scope.package.device_types = $scope.device_types_list;

        // Creating a new package
        I3PackageSrv.updateById($scope.package._id, $scope.package, function (response) {

            if (response.success) {
                Notification.success({
                    title: $translate.instant("PRODUCT_UPDATED"),
                    message: $translate.instant("PRODUCT_UPDATED_SUCC")
                });
                $state.go('app.admin.packages');
                $scope.package = {};
            } else {
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_UPDATE") + " " + $scope.package.name + "!"
                });
                $scope.package = {};
            }
        }, function (err) {
            console.log(err);

            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("ERROR_OCCURED_UDPDATE") + " " + $scope.package.name + "!"
            });
            $scope.package = {};

        })

    };

$scope.loadOtherProducts = function ($select) {
    I3ProductSrv.query({
        query: {
            name: {
                '$regex': $select.search,
                '$options': 'i'
            },
            _id: {
                $nin: $scope.options_list.map(function (val) {
                    if (val) {
                        return {'_id': val._id};
                    }
                })
            }
        }
        }, 10000, 1,
        function (data) {
            if (data.success) {
                $scope._ADMIN.products = data.result;
            } else {
                console.error(data.error)
            }
        },
        function (data) {
            console.dir(data)

        })
}
    /**
     * Cancel creating/editing package
     */
    $scope.cancel = function () {
        $scope.package = {};
        $state.go('app.admin.packages');
    };

    /**
     * Add options
     */
    $scope.addOptions = function (opt) {
        //console.log(opt)
        if (opt) {
            var filtred = $filter("filter")($scope.options_list, {
                _id: opt._id
            }, true);
            if (filtred.length > 0) {
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("ALREADY_EXIST")
                });

            } else {
                $scope.options_list.push(opt);
                //$scope.options.splice($scope.translatedOptions.indexOf(opt), 1 );
                //$scope.translatedOptions.splice($scope.translatedOptions.indexOf(opt), 1);

            }
        } else {
            Notification.error({
                title: $translate.instant("ERROR"),
                message: $translate.instant("NO_OPTIONS")
            });

        }

        $scope.package.options = null;
    }

    /**
     * Remove options
     */
    $scope.removeOptions = function (index, opt) {
        $scope.options_list.splice(index, 1);
        $scope.options.push($scope.reverseTranslatedOptions[opt]);
        $scope.translatedOptions.push(opt);
    };

    /**
     * Add device type
     */
    $scope.addDeviceType = function (deviceType) {
        if (deviceType) {
            if ($scope.device_types_list.indexOf(deviceType) >= 0) {
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("ALREADY_EXIST")
                });

            } else {
                $scope.device_types_list.push(deviceType);
            }
        } else {
            Notification.error({
                title: $translate.instant("ERROR"),
                message: $translate.instant("NO_DEVICE_TYPE")
            });

        }
        $scope.device_types = '';
    }

    /**
     * Remove device type
     */
    $scope.removeDeviceType = function (index) {
        $scope.device_types_list.splice(index, 1);
    };


}).filter('translation', function () {
    return function (translatedObject) {

        var translatedOptions = [];

        for (key in translatedObject) {
            translatedOptions.push(translatedObject[key]);
        }
        return translatedOptions;

    }
});