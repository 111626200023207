angular.module('app').controller('pvEditCtrl', function ($scope, $rootScope, $state, $stateParams, $translate, I3ProductSrv, I3InstallationGroupSrv, I3ClientSrv, I3InterventionSrv, $filter, Notification, ngDialog, I3PvSrv, constantSrv, i3HelperSrv) {
    var pvToEdit = null;
    $scope.$state = $state;

    $scope.intervention = {};
    $scope.installation = {};
    $scope.document = {};
    $scope.listClientInvoice = []
    $scope.ResList = constantSrv.getResponsabilite();
    /*  i3HelperSrv.checkEditPermissionPV($stateParams.pvId, function (res) {
       
          if (res && res.result.length > 0) {
        
              Notification.error({title:$translate.instant("CANNOT_EDIT_PV"),message:$translate.instant("PV_USED_IN_INVENTORY",{ref:res.result[0].ref})})
              $state.go('app.home.pvs.detail',{
                  pvId:$stateParams.pvId
              })
          } else {*/

    i3HelperSrv.getEnums(function (res) {
        $scope.interventionRequest_type = res.INTERVENTION_TYPE.LIST;

    }, function (err) {
        console.error(err)
    })
    I3PvSrv.query({
        query: {
            _id: $stateParams.pvId
        },
        options: {
            populate: [{
                path: "products._product"
            },
            {
                path: "_articles"
            },
            {
                path: "products.zones._zone",
                select: 'name'

            },
            {
                path: "_plateforme",
                select: 'name'
            },
            {
                path: "_documents._document",
                select: 'original_name'

            }
            ]
        }
    }, 1, 0, function (res) {

        if (res && res.success && res.result.length > 0) {
            $scope.pv_sync = res.result[0];
            console.log("TCL: $scope.pv_sync ", $scope.pv_sync)
            console.log("TCL: $scope.pv_sync.type", $scope.pv_sync.type)
            if ($scope.pv_sync.type == "INSTALLATION")
                $scope.get_install();
            if ($scope.pv_sync.type == "INTERVENTION")
                $scope.get_intervention();
            pvToEdit = angular.copy($scope.pv_sync);
        } else
            Notification.error($translate.instant("FIND_BY_ID_ERROR"));
    }, function (err) {
        Notification.error($translate.instant("FIND_BY_ID_ERROR"))
    })
    /*   }

   }, function (err) {
       Notification.error({title:$translate.instant("ERROR"),message:$translate.instant("CONNECTION_SERVER_ERROR")})
       $state.go('app.home.pvs');
   })*/
    $scope.uploadFiles = function (files, errFiles) {
        if (!$scope.pv_sync._documents) {
            $scope.pv_sync._documents = [];
            pvToEdit._documents = [];
        }
        $scope.errFiles = errFiles;
        i3HelperSrv.uploadFiles(files, {
            _plateforme: $scope.pv_sync._plateforme && $scope.pv_sync._plateforme._id ? $scope.pv_sync._plateforme._id : $scope.pv_sync._plateforme,
            related_model: [{
                model: "PV",
                model_id: $scope.pv_sync._id
            }]
        }, function (res) {
            if (res && res.success) {
                angular.forEach(res.result, function (file) {
                    var elem = {
                        _document: file,
                        _type: $scope.document._type
                    };

                    $scope.pv_sync._documents.push(elem);
                    pvToEdit._documents.push(elem)
                });
                $scope.saveFiles();
            }
        },
            function (response) {
                if (response.status)
                    $scope.errorMsg = response.status + ': ' + response.data;
            },
            function (evt) {

            });

    };

    $scope.get_install = function () {
        console.log('ssssss');
        I3InstallationGroupSrv.query({
            query: {
                _id: $scope.pv_sync._installation
            },
            options: {
                populate: [{
                    path: "_plateforme"
                }]
            }
        }, 1, 0, function (res) {
            if (res && res.success && res.result.length > 0) {
                $scope.installation = res.result[0];

                if (!$scope.pv_sync.client_id) {
                    $scope.verify();
                } else {
                    if (!$scope.pv_sync.asset_id) {
                        $scope.verify_asset();
                    }
                }
               if( !$scope.pv_sync.asset_dev){
                $scope.installation.assets.forEach(function (elem) {
                    if (elem.name == $scope.pv_sync._asset) {
                        if (elem.vin && elem.vin.length > 0) {

                            $scope.pv_sync.asset_dev = $scope.pv_sync._asset + '/' + elem.vin;

                            I3PvSrv.updateById($scope.pv_sync._id, $scope.pv_sync, function (response) {

                            }, function (err) {

                            })
                        }


                    }

                });
               }
              


            } else
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("FIND_BY_ID_ERROR")
                });
        }, function (err) {
            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("FIND_BY_ID_ERROR")
            });
        })
    }
    $scope.get_intervention = function () {

        I3InterventionSrv.query({
            query: {
                _id: $scope.pv_sync._intervention
            },
            options: {
                populate: [{
                    path: "_plateforme"
                }]
            }
        }, 1, 0, function (res) {
            if (res && res.success && res.result.length > 0) {
                $scope.intervention = res.result[0];
                $scope.pv_sync._type_intervention = ($scope.pv_sync._type_intervention) ? $scope.pv_sync._type_intervention : $scope.intervention.type;

                if (!$scope.pv_sync.client_id) {
                    $scope.verify2();
                } else {
                    if (!$scope.pv_sync.asset_id) {
                        $scope.verify_asset();
                    }
                }
            } else
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("FIND_BY_ID_ERROR")
                });
        }, function (err) {
            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("FIND_BY_ID_ERROR")
            });
        })
    }
    $scope.verify = function () {
        var modal = ngDialog.open({
            controller: "ClientEdSyncDlgCtrl",
            templateUrl: "app/commons/dialogs/client/client.ed.sync.dlg.tpl.html",
            className: 'ngdialog-theme-default custom-width',
            scope: $scope,
            width: '900px',
            showClose: false,
            closeByEscape: false,
            closeByNavigation: true,
            closeByDocument: false,
            data: {
                "pv": true
            },
            resolve: {
                installation: function () {
                    return $scope.installation;
                }
            }
        });
        modal.closePromise.then(function (data) {
            $scope.pv_sync.client_id = data.value._id;
            $scope.pv_sync.client_name = data.value.name;
            $scope.pv_sync._client_code = data.value.client_code;
            I3PvSrv.updateById($scope.pv_sync._id, $scope.pv_sync, function (response) {
                if (response.success) {
                    Notification.success({
                        title: $translate.instant("SYNCHRONIZED_CLIENT"),
                        message: $translate.instant("SYNCHRONIZED_CLIENT_SUCC")
                    });
                    $scope.verify_asset();
                } else {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("ERROR_OCCURED_UPDATE") + "!"
                    });
                }
            }, function (err) {
                var msg = "";
                if (err.error.errorString) {
                    msg = err.error.errorString;
                }
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: msg
                });
            })
        }, function () {
        });
    }
    $scope.verify2 = function () {
        var modal = ngDialog.open({
            controller: "ClientIntervSyncDlgCtrl",
            templateUrl: "app/commons/dialogs/client/client.interv.sync.dlg.tpl.html",
            className: 'ngdialog-theme-default custom-width',
            scope: $scope,
            width: '900px',
            showClose: false,
            closeByEscape: false,
            closeByNavigation: true,
            closeByDocument: false,
            resolve: {
                intervention: function () {
                    return $scope.intervention;
                }
            }
        });
        modal.closePromise.then(function (data) {
            $scope.pv_sync.client_id = data.value._id;
            $scope.pv_sync.client_name = data.value.name;
            $scope.pv_sync._client_code = data.value.client_code;
            I3PvSrv.updateById($scope.pv_sync._id, $scope.pv_sync, function (response) {
                if (response.success) {
                    Notification.success({
                        title: $translate.instant("SYNCHRONIZED_CLIENT"),
                        message: $translate.instant("SYNCHRONIZED_CLIENT_SUCC")
                    });
                    $scope.verify_asset();
                } else {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("ERROR_OCCURED_UPDATE") + "!"
                    });
                }
            }, function (err) {
                var msg = "";
                if (err.error.errorString) {
                    msg = err.error.errorString;
                }
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: msg
                });
            })
        }, function () {
        });
    }

    $scope.verify_asset = function () {
        console.log('verifassrt');
        var modal = ngDialog.open({
            controller: "assetSyncDlgCtrl",
            templateUrl: "app/commons/dialogs/asset/asset.sync.dlg.tpl.html",
            className: 'ngdialog-theme-default custom-width',
            scope: $scope,
            width: '900px',
            showClose: false,
            closeByEscape: false,
            closeByNavigation: true,
            closeByDocument: false,
            data: {
                "pv": true
            },
            resolve: {
                installation: function () {
                    return $scope.installation;
                },
                intervention: function () {
                    return $scope.intervention;
                }
            }
        });
        modal.closePromise.then(function (data) {
            if (data.value != false) {
                if ($scope.pv_sync.error) {
                    delete $scope.pv_sync.error
                }

                $scope.pv_sync.asset_id = data.value._id;
                $scope.pv_sync._asset = data.value.name;
                $scope.pv_sync.asset_dev = data.value.name + '/' + data.value._dev;
                I3PvSrv.updateById($scope.pv_sync._id, $scope.pv_sync, function (response) {
                    if (response.success) {
                        Notification.success({
                            title: $translate.instant("SYNCHRONIZED_CLIENT"),
                            message: $translate.instant("SYNCHRONIZED_CLIENT_SUCC")
                        });

                    } else {
                        Notification.error({
                            title: $translate.instant("ERROR"),
                            message: $translate.instant("ERROR_OCCURED_UPDATE") + "!"
                        });
                    }
                }, function (err) {
                    var msg = "";
                    if (err.error.errorString) {
                        msg = err.error.errorString;
                    }
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: msg
                    });
                })

            } else {
                $scope.pv_sync.error = true
                I3PvSrv.updateById($scope.pv_sync._id, $scope.pv_sync, function (response) {
                    if (response.success) {
                        Notification.error({
                            title: $translate.instant("PROBLEM_SYNCHRONIZED_ASSET"),
                            message: $translate.instant("PROBLEM_SYNCHRONIZED_ASSET_ERROR")
                        });

                    } else {
                        Notification.error({
                            title: $translate.instant("ERROR"),
                            message: $translate.instant("ERROR_OCCURED_UPDATE") + "!"
                        });
                    }
                }, function (err) {
                    var msg = "";
                    if (err.error.errorString) {
                        msg = err.error.errorString;
                    }
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: msg
                    });
                })




            }




        }, function () {






        });
    }
    $scope.downloadFile = function (file) {
        i3HelperSrv.downloadFile(file._document._id, file._document.original_name, function success(res) {

        }, function error(err) {

            Notification.error($translate.instant(err));
        }, function progress(evt) {
            console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '%');
        })
    };
    $scope.saveFiles = function () {
        delete pvToEdit.__v;
        I3PvSrv.updateById(pvToEdit._id, pvToEdit, function (response) {
            if (response.success) {
                Notification.success({
                    title: $translate.instant("UPDATE_SUCC"),
                    message: $translate.instant("PV_UPDATED_SUC")
                });
            } else {
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_UPDATE")
                });
            }
        }, function (err) {
            var msg = "";
            if (err.error.errorString) {
                msg = err.error.errorString;
            }
            Notification.error({
                title: $translate.instant('ERROR'),
                message: msg
            });
        })
    };
    $scope.openProductDialog = function () {

        ngDialog.open({
            templateUrl: "app/commons/dialogs/pv/product.dlg.tpl.html",
            className: 'ngdialog-theme-default custom-width-800',
            controller: "productDlgCtrl",
            appendClassName: 'ngdialog-custom',
            scope: $scope
        })
    }
    $scope.editProduct = function (element, index) {
        ngDialog.open({
            templateUrl: "app/commons/dialogs/pv/product.edit.dlg.html",
            className: 'ngdialog-theme-default custom-width-800',
            controller: "productEditDlgCtrl",
            appendClassName: 'ngdialog-custom',
            scope: $scope,
            resolve: {
                index: function () {
                    return index;
                }
            }
        })
    };
    $scope.removeProduct = function (element, index) {
        $scope.pv_sync.products.splice(index, 1);
    };

    $scope.updatePv = function () {
        delete pvToEdit.__v;
        if ($scope.pv_sync._client_code && $scope.pv_sync._client_code != null) {
            var query = {
                query: {
                    code_client: $scope.pv_sync._client_code
                },
                options: { populate: [{ path: "_subClients" }] }


            }
            I3ClientSrv.query(query, 1, 0, function (res) {
                if (res && res.success) {
                    if (res.result.length > 0) { // client exist dans tcfleet-sav 

                        if (res.result[0]._subClients && res.result[0]._subClients.length > 0) {// client groupe ou client simple  exist dans tcfleet-sav 
                            $scope.listClientInvoice = res.result[0]._subClients
                            ngDialog.open({
                                templateUrl: "app/commons/dialogs/pv/clientInvoice.dlg.tpl.html",
                                controller: "clientInvoiceDlgCtrl",
                                scope: $scope,

                            }).closePromise.then(function (data) {
                                if (data.value && data.value._id) {

                                    $scope.pv_sync.client_filiale = data.value.name;
                                    $scope.pv_sync._client_code = data.value.code_client || null;
                                    $scope.pv_sync.SAP = data.value.SAP || null;
                                    $scope.pv_sync.clientSav_id = data.value._id;



                                    I3PvSrv.updateById($stateParams.pvId, $scope.pv_sync, function (response) {
                                        if (response.success) {
                                            Notification.success({
                                                title: $translate.instant("UPDATE_SUCC"),
                                                message: $translate.instant("PV_UPDATED_SUC")
                                            });
                                            $state.go('app.home.pvs');
                                            $scope.pv_sync = {};
                                        } else {
                                            Notification.error({
                                                title: $translate.instant('ERROR'),
                                                message: $translate.instant("ERROR_OCCURED_UPDATE")
                                            });
                                            $scope.pv_sync = {};
                                        }
                                    }, function (err) {
                                        var msg = "";
                                        if (err.error.errorString) {
                                            msg = err.error.errorString;
                                        }
                                        Notification.error({
                                            title: $translate.instant('ERROR'),
                                            message: msg
                                        });
                                    })
                                }





                            })

                        } else {


                            I3PvSrv.updateById($stateParams.pvId, $scope.pv_sync, function (response) {
                                if (response.success) {
                                    Notification.success({
                                        title: $translate.instant("UPDATE_SUCC"),
                                        message: $translate.instant("PV_UPDATED_SUC")
                                    });
                                    $state.go('app.home.pvs');
                                    $scope.pv_sync = {};
                                } else {
                                    Notification.error({
                                        title: $translate.instant('ERROR'),
                                        message: $translate.instant("ERROR_OCCURED_UPDATE")
                                    });
                                    $scope.pv_sync = {};
                                }
                            }, function (err) {
                                var msg = "";
                                if (err.error.errorString) {
                                    msg = err.error.errorString;
                                }
                                Notification.error({
                                    title: $translate.instant('ERROR'),
                                    message: msg
                                });
                            })


                        }



                    } else {

                        I3PvSrv.updateById($stateParams.pvId, $scope.pv_sync, function (response) {
                            if (response.success) {
                                Notification.success({
                                    title: $translate.instant("UPDATE_SUCC"),
                                    message: $translate.instant("PV_UPDATED_SUC")
                                });
                                $state.go('app.home.pvs');
                                $scope.pv_sync = {};
                            } else {
                                Notification.error({
                                    title: $translate.instant('ERROR'),
                                    message: $translate.instant("ERROR_OCCURED_UPDATE")
                                });
                                $scope.pv_sync = {};
                            }
                        }, function (err) {
                            var msg = "";
                            if (err.error.errorString) {
                                msg = err.error.errorString;
                            }
                            Notification.error({
                                title: $translate.instant('ERROR'),
                                message: msg
                            });
                        })
                    }





                } else {

                    I3PvSrv.updateById($stateParams.pvId, $scope.pv_sync, function (response) {
                        if (response.success) {
                            Notification.success({
                                title: $translate.instant("UPDATE_SUCC"),
                                message: $translate.instant("PV_UPDATED_SUC")
                            });
                            $state.go('app.home.pvs');
                            $scope.pv_sync = {};
                        } else {
                            Notification.error({
                                title: $translate.instant('ERROR'),
                                message: $translate.instant("ERROR_OCCURED_UPDATE")
                            });
                            $scope.pv_sync = {};
                        }
                    }, function (err) {
                        var msg = "";
                        if (err.error.errorString) {
                            msg = err.error.errorString;
                        }
                        Notification.error({
                            title: $translate.instant('ERROR'),
                            message: msg
                        });
                    })
                }


            }, function (err) {
                console.log('err:', err)

            })
        } else {

            I3PvSrv.updateById($stateParams.pvId, $scope.pv_sync, function (response) {
                if (response.success) {
                    Notification.success({
                        title: $translate.instant("UPDATE_SUCC"),
                        message: $translate.instant("PV_UPDATED_SUC")
                    });
                    $state.go('app.home.pvs');
                    $scope.pv_sync = {};
                } else {
                    Notification.error({
                        title: $translate.instant('ERROR'),
                        message: $translate.instant("ERROR_OCCURED_UPDATE")
                    });
                    $scope.pv_sync = {};
                }
            }, function (err) {
                var msg = "";
                if (err.error.errorString) {
                    msg = err.error.errorString;
                }
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: msg
                });
            })


        }








    };

    $scope.cancelPv = function () {
        if ($state.current.name === "app.home.pvs.edit") {
            $state.go('app.home.pvs');
        } else {
            ngDialog.closeAll();
        }
    };

    /**
     * Removing document
     * @param document
     */
    $scope.remove = function (pv, index, file) {
        $scope.pv_sync = pv;
        $scope.index = index;
        ngDialog.open({
            templateUrl: "app/commons/dialogs/confirm/document.delete.dlg.tpl.html",
            controller: "confirmDeleteDocumentDlgCtrl",
            scope: $scope
        })

    };
    /**
     * Removing document
     * @param document
     */
    $scope.remove = function (pv, index, file) {
        $scope.file = file;
        $scope.pv_sync = pv;
        $scope.index = index;

        ngDialog.open({
            templateUrl: "app/commons/dialogs/pv/document.delete.dlg.tpl.html",
            controller: "pvDeleteDocumentDlgCtrl",
            scope: $scope
        })

    };

    $scope.downloadZipGrouppedfile = function () {
        console.log(" $scope.pv_sync", $scope.pv_sync)
        try {
            var params = {
                _ids: []
            }
            var comments = $translate.instant("PVS") + "\r\n";
            comments += "\t" + $translate.instant("CLIENT") + " :" + $scope.pv_sync.client_name + "\r\n";
            comments += "\t" + $translate.instant("PV_NUMBER") + " :" + $scope.pv_sync.pv_ref + "\r\n";
            comments += "\t" + $translate.instant("purchase_order_nbr") + " :" + $scope.pv_sync.purchase_order + "\r\n";
            comments += "\t" + $translate.instant("ASSET") + " :" + $scope.pv_sync._asset + "\r\n";
            comments += "-------------------------------------------------------------------------\r\n";
            comments += "\t- " + $translate.instant("DOCUMENTS") + " :\r\n";
            if ($scope.pv_sync._documents && $scope.pv_sync._documents.length > 0) {
                // params.ids = {pv: []}
                $scope.pv_sync._documents.forEach(function (doc) {
                    if (doc._document._id)
                        params._ids.push(doc._document._id);
                })
                comments += "\t\t + pv : " + $translate.instant("DOCUMENTS") + " " + $translate.instant("PVS") + "\r\n";
            }
            /* $scope.interventionRequest.assets.forEach(function (asset) {
                 var asset_tag=asset.name;
                  if(asset.vin){
                      asset_tag=asset.vin
                  }
                 params.groups[asset_tag]=[];
                 if(asset.docs && asset.docs.length>0){
                     asset.docs.forEach(function (doc) {
                         if(doc._id)
                             params.groups[asset_tag].push(doc._id);
                     })
                     if(asset.gray_card_back && asset.gray_card_back.length>0){
                         params.groups[asset_tag].push(asset.gray_card_back);
                     }
                     if(asset.gray_card_front && asset.gray_card_front.length>0){
                         params.groups[asset_tag].push(asset.gray_card_front);
                     }

                 }

             })*/
            params.comments = comments
            i3HelperSrv.downloadZip(params, "PV_" + $scope.pv_sync._asset + "_" + $scope.pv_sync.pv_ref + "_docs.zip", function (res) {

            }, function (err) {
                console.log(err)
            }, function (percent) {
                console.log(percent)
            })
        } catch (e) {

            console.error(e)
        }
    }
});