angular.module('app.dialogs').controller('statusDlgCtrl', function ($scope,$translate,Notification) {
    $scope.data_to_send = [];
    $scope.init = $scope.ngDialogData.prop.status;
 
    $scope.assigned = $scope.ngDialogData.prop._assigned_to;
    //console.log("TCL: $scope.ngDialogData.prop", $scope.assigned)
    $scope.dialog = $scope.init;
    $scope.assignedto = $scope.assigned;
    $scope.update = function () {
        //console.log($scope.dialog)
    }
    // console.log("  $scope.assigned ",  $scope.assigned )
    $scope.update_users = function (assignedto) {
        $scope.assignedto = assignedto;
    }
    $scope.EditStatus = function () {
        if($scope.ngDialogData.prop.purchase_order == undefined || $scope.ngDialogData.prop.purchase_order =="" && $$scope.ngDialogData.prop.status =="NEW" && $scope.data_to_send[0]=="READY"){
      
            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("ADD_PURCHASE_ORDER_NBR")
            });
          
           
        }else{

            $scope.data_to_send[0] = $scope.dialog;
            $scope.data_to_send[1] = $scope.assignedto;
            $scope.closeThisDialog($scope.data_to_send);

        }



      
    }
    $scope.cancel = function () {
        $scope.closeThisDialog(false);
    }
});