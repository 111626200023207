angular.module('app').controller('adminProductsEditCtrl', function ($scope, $rootScope, $state, $stateParams, $translate, $filter, Notification, ngDialog, I3ProductSrv) {
    $scope.product = {};
    I3ProductSrv.query({
        query: {
            _id: $stateParams.productId
        },
        options:{populate:'_unsubscribe_product _installation_prod  _intervention_prod _subscription_prod'}
    }, 1, 0, function (res) {
        if (res && res.result) {
            $scope.product = res.result[0];
           
        }
    })
    $scope.save = function () {
        I3ProductSrv.updateById($scope.product._id, $scope.product, function (response) {
            if (response.success) {
                Notification.success({
                    title: $translate.instant("PRODUCT_UPDATED"),
                    message: $translate.instant("PRODUCT_UPDATED_SUCC")
                });
                $state.go('app.admin.products');

                $scope.product = {};
            } else {
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_UPDATE") + " " + $scope.product.name + "!"
                });
                $scope.product = {};
            }
        }, function (err) {
            console.log(err);

            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("ERROR_OCCURED_UDPDATE") + " " + $scope.product.name + "!"
            });
            $scope.product = {};

        })
    };


           $scope.serviceProducts=[];
           $scope.operationProducts=[];





    /**
     * Cancel creating/editing product
     */
    $scope.cancel = function () {
        $scope.product = {};
        $state.go('app.admin.products');
    };

});