angular.module('app.dialogs').controller('editStockVirtDlgCtrl', function ($scope,index) {

$scope.vstock={};
$scope.vstock.name=$scope.stock.virtual_stock[index].name;
$scope.vstock.description=$scope.stock.virtual_stock[index].description;
$scope.vstock.type=$scope.stock.virtual_stock[index].type;
    $scope.editstock = function () {

        $scope.stock.virtual_stock[index].name=$scope.vstock.name;
        $scope.stock.virtual_stock[index].description =$scope.vstock.description;
        $scope.stock.virtual_stock[index].type =$scope.vstock.type;
        $scope.closeThisDialog($scope.stock);
    };
});;