angular.module('app.dialogs').controller('synchroneClienteDlgCtrl', function ($scope, I3ClientSrv,invoiceClientSrv) {

    $scope.synchrone = {}
    $scope.clientSav={}

    $scope.ClientsInvoiceSync=[]

invoiceClientSrv.query({
    query:{_id:{$nin:$scope.Clients.map(function(val){
     
            return  val.invoice_id
        
       
    })

    }
}
}, 1000, 0, function (res) {

  
    if (res && res.result.length>0) {
       $scope.ClientsInvoiceSync=res.result;
       //console.log('ClientsInvoice:',  $scope.ClientsInvoice)


    }
})

    $scope.getClientSav = function (sav_client) {
      
        $scope.clientSav = sav_client 
       
    }
    $scope.getInvoice = function (invoice_client) {

        $scope.clientSav.invoice_id = $scope.synchrone.invoice_client;
    }

    $scope.addSynchrone = function () {
       


                $scope.closeThisDialog($scope.synchrone);
                $scope.loadClients()
              
         
       









    }
    $scope.cancel = function () {
        $scope.closeThisDialog(false);
    }
});