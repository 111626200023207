angular.module('app.dialogs').controller('addDashboardDlgCtrl', function ($scope,NGIFleetHelperSrv,$translate,Notification,iaxeedCompanySrv,NGIFleetClientSrv,plateformHelperSrv) {
   
   
   
    $scope.dash={}

    $scope.searchClient = function ($select) {

        if ($scope.platform && $scope.platform.type) {
            if ($scope.platform.type === 'NGI_FLEET') {
                NGIFleetClientSrv.getClient($select.search, 10, 0, function (res) {
                
                    $scope.clients = res.result;
                  
                }, function (err) {
                    console.log("err", err)
                })
            } else {
                iaxeedCompanySrv.query({
                    query: {
                        name: {
                            '$regex': $select.search,
                            '$options': 'i'
                        }
                    }
                }, 10, 0, function (res) {
                    $scope.clients = res.result;
                 
                }, function (err) {
                    console.log("err", err)
                },true)
            }
        }
    };
   
  $scope.addDash = function () {
    var client = $scope.platform.dash._clients.find(function(elem){return   elem._id == $scope.dash.client._id })

    if(client == undefined){
        
        $scope.closeThisDialog($scope.dash);
    }else
    if( client._id ==$scope.dash.client._id){
        Notification.error({
            title: $translate.instant('ERROR'),
            message: $translate.instant("CLIENT_EXIST") + "!"
        });
        $scope.closeThisDialog(false)
    
    }
    
     
  };

  $scope.cancel = function () {
    $scope.closeThisDialog(false);
}

});