angular.module('app.dialogs').controller('addSubClientDlgCtrl', function ($scope, I3ClientSrv) {

    $scope.subClient = null;
    $scope.subClients = []
    var start = 0;
    var number = 1000;
    var query = {
        "_plateforme": $scope.client._plateforme,
        "SUB_CLIENT": true
    }
    I3ClientSrv.query({ query: query }, number, start, function (res) {


        if (res.success && res.result.length > 0) {

            $scope.subClients = res.result;


        }
    }, function (err) {
        console.log('err:', err)

    })



    $scope.getsubClient = function (subClient) {
        $scope.subClient =subClient


    }

    $scope.addSubClient = function () {
        if ($scope.client._subClients && $scope.client._subClients.length > 0) {
            $scope.client._subClients.push($scope.subClient)
        } else {
            $scope.client._subClients = []
            $scope.client._subClients.push($scope.subClient)

           
        }

        $scope.closeThisDialog($scope.subClient);
    }
    $scope.cancel = function () {
        $scope.closeThisDialog(false);
    }
})
