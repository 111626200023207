angular.module('app.dialogs').controller('synchroneProductDlgCtrl', function ($scope, invoiceProductSrv, I3ProductSrv) {

    $scope.synchrone = {}


    I3ProductSrv.query({}, 1000, 0, function (res) {
        if (res && res.result) {
            $scope.savProducts = res.result

        }
    })

    $scope.getProductSav = function (sav_product) {
        $scope.product = sav_product
        if($scope.product.type ==$scope.synchrone.invoice_product.type){
            $scope.product.external_id = $scope.synchrone.invoice_product;
            $scope.type=true
         }else{
            $scope.type=false
    
    
         }
    }
    $scope.getInvoice = function (invoice_product) {
     if($scope.product.type ==$scope.synchrone.invoice_product.type ){
        $scope.product.external_id = $scope.synchrone.invoice_product;
        $scope.type=true
     }
     else{
        $scope.type=false


     }
    }

    $scope.addSynchrone = function () {
        $scope.product.external_id = $scope.synchrone.invoice_product;

        I3ProductSrv.updateById($scope.product._id, $scope.product, function (response) {
            if (response.success) {

                $scope.closeThisDialog($scope.synchrone);
                $scope.loadProducts()
                $scope.product = {};
            } else {

                $scope.product = {};
            }
        }, function (err) {
            console.log(err);


            $scope.product = {};

        })









    }
    $scope.cancel = function () {
        $scope.closeThisDialog(false);
    }
});