/**
 * Created by Kais on 11/03/2015.
 */
var bndModule = angular.module("bndGeoServices", ['ngResource']);
/**
 * configure bndGeoServices
 */

/**
 * angularJS service as resource for acccessing to BND geo-service
 */
bndModule.factory("bndServiceResource", ['$resource','$rootScope', function ($resource,$rootScope) {
    return {
        geoCode: $resource($rootScope.config.BND_URI, null,
            {
                'query': {method: 'GET'}
            }),
        reverseGeoCode: $resource($rootScope.config.BND_URI, null,
            {
                'query': {method: 'GET'}
            }),
        routing: $resource($rootScope.config.BND_URI, null,
            {
                'query': {method: 'GET'}
            }),
        getFeature: $resource($rootScope.config.BND_URI+ "?NOLOAD", null,
            {
                'query': {method: 'GET', responseType: "text", isArray: false}
            })
    };

}]);

/**
 * AngularJs service used as interface for accessing to BND goeService
 */
bndModule.factory("bndServiceInterface", ['bndServiceResource', "$q", function (bndGeoService, $q) {

    /**
     * geocode the given address. please
     * @param params    an address object example :    {

                bbox: MY_BBOX,
                country: MY_COUNTRY,
                countrycode: MY8COUNTRY_CODE,
                district : MY_DISTRICT,
                postalCode  : MY_POSTAL_CODE,
                state : MY_STATE,
                city: MY_CITY ,
                street: MY_STREET,
                streetNumber: MY_STREET_NUMBER
                 }
     * @param maxResult The maximum number of items used to perform the research and returned items by the server. If not defined, no limits will be used
     * @returns {promise|fd.g.promise}
     */
    this.geoCode = function (params, maxResult, language) {
        var defer = $q.defer();

        if (params === undefined || params == null) {
            defer.reject("error.unvalid.params");
        } else {
            var requestFormatter = new BndRequestFormatter();

            bndGeoService.geoCode.query(requestFormatter.formatRequest(formatters.GEO_CODING_FORMATTER, params, maxResult, language)).$promise.then(
                // success call
                function (res) {
                    // console.log(res);
                    if (res.errorCode !== undefined && res.errorCode != null && res.errorCode <= 0) {
                        // server response error (may an XML response )

                        defer.reject(res);
                    } else {

                        var responseFormatter = new BndResponseFormatter();
                        defer.resolve(responseFormatter.formatResponse(formatters.GEO_CODING_FORMATTER, res));
                    }

                },
                // an error  occur
                function (error) {
                    // HTTP error
                    defer.reject({"errorCode": -2, "errorString": "server.httpError", errorDetails: error});
                }
            )
        }

        return defer.promise;
    };
    /**
     * Reverse Geo-coding is the process of translating a (Latitude, Longitude) location back to an address, place, city, state or identifying other attributes about or near the location
     * @param params
     * @param maxResult
     * @param language
     * @returns {promise|fd.g.promise}
     */
    this.reverseGeoCode = function (params, maxResult, language) {
        var defer = $q.defer();

        if (params === undefined || params == null) {
            defer.reject("error.unvalid.params");
        } else {

            var requestFormatter = new BndRequestFormatter();

            bndGeoService.reverseGeoCode.query(requestFormatter.formatRequest(formatters.REVERSE_GEO_CODING_FORMATTER, params, maxResult, language)).$promise.then(
                // success call
                function (res) {
                    //console.log(res);
                    if (res.errorCode !== undefined && res.errorCode != null && res.errorCode <= 0) {
                        // server response error (may an XML response )

                        defer.reject(res);
                    } else {

                        var responseFormatter = new BndResponseFormatter();
                        defer.resolve(responseFormatter.formatResponse(formatters.REVERSE_GEO_CODING_FORMATTER, res));
                    }

                },
                // an error  occur
                function (error, data) {
                    // HTTP error
                    defer.reject({"errorCode": -2, "errorString": "server.httpError", errorDetails: error});
                }
            )
        }

        return defer.promise;
    };


    /**
     * Reverse Geocoding for Batch works is the process of translating a (Latitude, Longitude) location back to an address, place, city, state or identifying other attributes about or near the location.
     * @param params
     * @param maxResult
     * @param language
     * @returns {promise|fd.g.promise}
     */
    this.revGeoBatch = function (params, maxResult, language) {
        var defer = $q.defer();

        if (params === undefined || params == null) {
            defer.reject("error.unvalid.params");
        } else {


            var requestFormatter = new BndRequestFormatter();

            bndGeoService.reverseGeoCode.query(requestFormatter.formatRequest(formatters.REVERSE_GEO_CODING_BATCH_FORMATTER, params, maxResult, language)).$promise.then(
                // success call
                function (res) {
                    //console.log(res);
                    if (res.errorCode !== undefined && res.errorCode != null && res.errorCode <= 0) {
                        // server response error (may an XML response )

                        defer.reject(res);
                    } else {

                        var responseFormatter = new BndResponseFormatter();
                        defer.resolve(responseFormatter.formatResponse(formatters.REVERSE_GEO_CODING_BATCH_FORMATTER, res));
                    }

                },
                // an error  occur
                function (error) {
                    // HTTP error
                    defer.reject({"errorCode": -2, "errorString": "server.httpError", errorDetails: error});
                }
            )
        }

        return defer.promise;
    };

    /**
     * To perform route computations through an inter-connected road network.
     * @param params
     * @param maxResult
     * @param language
     * @returns {promise|fd.g.promise}
     */
    this.calculateRoute = function (params, language) {
        var defer = $q.defer();

        if (params === undefined || params == null) {
            defer.reject("error.unvalid.params");
        } else {

            var requestFormatter = new BndRequestFormatter();

            bndGeoService.routing.query(requestFormatter.formatRequest(formatters.ROUTING_FORMATTER, params, null, language)).$promise.then(
                // success call
                function (res) {
                    //console.log(res);
                    if (res.errorCode !== undefined && res.errorCode != null && res.errorCode <= 0) {
                        // server response error (may an XML response )

                        defer.reject(res);
                    } else {

                        var responseFormatter = new BndResponseFormatter();

                        defer.resolve(res);
                    }

                },
                // an error  occur
                function (error) {
                    // HTTP error
                    defer.reject({"errorCode": -2, "errorString": "server.httpError", errorDetails: error});
                }
            )


        }

        return defer.promise;
    };

    /**
     * get feature .
     * @param params
     * @param maxResult
     * @param language
     * @returns {promise|fd.g.promise}
     */
    this.getFeature = function (params, maxResult, language) {
        var defer = $q.defer();

        if (params === undefined || params == null) {
            defer.reject("error.unvalid.params");
        } else {

            var requestParams = geoServerConstantParam;
            requestParams.xy = (params.xy !== undefined) ? params.xy : null;
            requestParams.radius = (params.radius !== undefined) ? params.radius : null;
            if (params.options !== undefined && params.options != null && params.options.length > 0)
                requestParams.options = params.options;

            requestParams.layers = (params.layers !== undefined) ? params.layers : "";
            requestParams.attributes = (params.attributes !== undefined) ? params.attributes.toString() : null;
            if (params.attCompares !== undefined && params.attCompares != null && params.attCompares.length > 0)
                requestParams.attCompares = params.attCompares;
            requestParams.action = "feature";
            requestParams.searchtype = "contains";



            bndGeoService.getFeature.query(requestParams).$promise.then(
                // success call
                function (res) {
                    //console.log("______")
                   // console.log(res)
                    defer.resolve(res);
                },
                // an error  occur
                function (error) {
                    defer.reject(error);
                }
            )
        }

        return defer.promise;
    };


    return this;
}]);

/**
 * implentation of the adapter  for benomad geo service
 */
bndModule.factory("bndServiceAdapter", ['bndServiceInterface', "$q", function (bndServiceInterface, $q) {
    /**
     * Address geo-coding
     * @param maxresult
     * @param country
     * @param city
     * @param street
     * @param streetNumber
     * @param language
     * @param state
     * @param postalCode
     * @param district
     * @param countrycode
     */
    this.geoCodeAddress = function (maxresult, country, city, street, streetNumber, language, state, postalCode, district, countrycode) {
        var defer = $q.defer();
        var param = {
            country: country,
            city: city,
            street: street,
            streetNumber: streetNumber,
            state: state,
            postalCode: postalCode,
            district: district,
            countrycode: countrycode
        };
        bndServiceInterface.geoCode(param, maxresult, language).then(
            function (res) {
                defer.resolve(res)
            },
            function (error) {
                defer.reject(error);
            });
        return defer.promise;
    };

    /**
     * reverse geocode
     * @param maxresult
     * @param lng
     * @param lat
     * @param radius
     * @param transportType see {@link reversGeoCodingConst.transportType}
     * @param language
     * @param speed
     * @param angle
     * @param option see {@link reversGeoCodingConst}
     */


    this.reverseGeoCode = function (maxresult, lng, lat, radius, language) {
        var defer = $q.defer();
        var param = {
            position: new ngiWebMap.Point(lng, lat),
            radius: radius

        };
        bndServiceInterface.reverseGeoCode(param, maxresult, language).then(
            function (res) {
                defer.resolve(res)
            },
            function (error) {
                defer.reject(error);
            });
        return defer.promise;
    };
    /**
     * Reverse Geocoding for Batch works is the process of translating a (Latitude, Longitude) location back to an address, place, city, state or identifying other attributes about or near the location.
     * @param maxresult
     * @param lng
     * @param lat
     * @param radius
     * @param language
     */
    this.revGeoBatch = function (maxresult, lng, lat, radius, language) {
        var defer = $q.defer();
        var param = {
            position: new ngiWebMap.Point(lng, lat),
            radius: radius
        };
        bndServiceInterface.revGeoBatch(param, maxresult, language).then(
            function (res) {
                defer.resolve(res)
            },
            function (error) {
                defer.reject(error);
            });
        return defer.promise;
    };
    /**
     * calculate route for the given road steps
     * @param maxresult
     * @param roadSteps an array  of point define the steps of the road each point must has (lat , lng)  attribute as respectively latitude and longitude of point
     * @param criterias see {@link routingConst.criterias}
     * @param transportType {@link transportTypeConst}
     * @param language
     * @param option an array of string  represents the options to set for returned result see {@link routingConst.option} for available values
     */


    this.calculateRoute = function (roadSteps, criterias, options, transportType, language) {
        var defer = $q.defer();

        var param = {
            roadSteps: roadSteps,
            criterias: criterias,
            transportType: transportType,
            options: options

        };
        bndServiceInterface.calculateRoute(param, language).then(
            function (res) {
                defer.resolve(res)
            },
            function (error) {
                defer.reject(error);
            });
        return defer.promise;
    };
    /**
     * calculate route between two point
     * @param maxresult
     * @param roadSteps an array  of point define the steps of the road each point must has (lat , lng)  attribute as respectively latitude and longitude of point
     * @param criterias see {@link routingConst.criterias}
     * @param transportType {@link transportTypeConst}
     * @param language
     * @param option an array of string  represents the options to set for returned result see {@link routingConst.option} for available values
     */
    this.calculateRoute2Points = function (departPoint, arrivalPoint, criterias, transportType, language, option) {
        var defer = $q.defer();
        var param = {
            roadSteps: [roadSteps, arrivalPoint],
            criterias: criterias,
            transportType: transportType

        };
        bndServiceInterface.calculateRoute(param, language).then(
            function (res) {
                defer.resolve(res)
            },
            function (error) {
                defer.reject(error);
            });
        return defer.promise;
    };

    /**
     * Get Poi near to given lng lat
     * @param maxresult
     * @param lng longitude
     * @param lat latitude
     * @param radius   Maximum search radius around GPS point. The value is in meter.
     * @param language
     * @param classids an array of class Id to filter with it
     * @param names not used yet
     * @param option
     */
    this.findPointOfInterests = function (maxResult, lng, lat, radius, language, classids, names, option) {
        var defer = $q.defer();


        var param = {
            xy: lng + "," + lat,
            radius: radius,
            layers: classids.toString(),
            attCompares: "",
            attributes: "20306",
            options: (option !== undefined && option != null ) ? option.toString() : null

        };

        bndServiceInterface.getFeature(param, maxResult, language).then(
            // success call
            function (res) {
                defer.resolve(res);
            },
            // an error  occur
            function (error, data) {
                defer.reject(error);
            }
        );


        return defer.promise;
    };

    return this;


}]);


