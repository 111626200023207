angular.module('app.dialogs').controller('confirmDeletePackageDlgCtrl', function ($scope, $rootScope, ngDialog, I3PackageSrv, Notification, $translate) {

    $scope.confirm = function () {
        I3PackageSrv.delete($scope.package._id, function (response) {

            if(response.success){
                //$scope.packages.splice($scope.packages.indexOf($scope.package), 1);

                Notification.success({
                    title: $translate.instant("PRODUCT_DELETED_TITLE"),
                    message: $translate.instant("PRODUCT_DELETED_SUC")
                });
                $scope.loadProducts()
                $scope.package = {};
            }else{
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("PRODUCT_DELETED_ERROR")
                });
                $scope.package = {};
            }
        }, function (err) {
            console.log(err);

            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("PRODUCT_DELETED_ERROR")
            });

            $scope.package = {};
        });

        $scope.package = {};
        $scope.closeThisDialog();
    }

});