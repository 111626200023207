angular.module('app').controller("assetSyncDlgCtrl", function ($scope, plateformHelperSrv, $translate, iaxeedAssetSrv, Notification) {
    //console.log($scope.pv_sync);
    $scope.sg_asset = {};
    $scope.assets = [];
    $scope.auto = true;
    // console.log("rr", $scope.installation);
    // console.log("dd", $scope.intervention);
    $scope.suggest_asset = function (platform) {
        plateformHelperSrv.setPlateFormAndLogin(platform, function (res) {
            if (res && res.success) {
                $scope.platformeLogin = res;
                if ($scope.platformeLogin && $scope.platformeLogin.result && $scope.platformeLogin.result.token) {
                    iaxeedAssetSrv.query({
                        query: {
                            _company_owner: $scope.pv_sync.client_id,
                            name: {
                                '$regex': $scope.pv_sync._asset,
                                '$options': 'i'
                            }
                        }
                    }, 1, 0, function (res) {
                        if (res.result.length > 0) {
                            $scope.sg_asset = res.result[0];
                            // console.log("f", $scope.sg_asset)
                        }

                        //console.log($scope.sg_asset)

                    }, function (err) {
                        console.log("err", err)
                        Notification.error({
                            title: $translate.instant("ERROR"),
                            message: $translate.instant("PLATFORM_UNAUTHORIZED_ACCESS_ERR")
                        });
                    })
                }
            } else {
                // console.log("no")
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("PLATFORM_CNX_ERROR")
                });
            }
        }, function (err) {
            console.log(err);
            Notification.error({
                title: $translate.instant("ERROR"),
                message: $translate.instant("PLATFORM_CNX_ERR")
            });
        })
    };
    if ($scope.pv_sync.type == "INSTALLATION")
        $scope.suggest_asset($scope.installation._plateforme);
    else if ($scope.pv_sync.type == "INTERVENTION") {
        $scope.suggest_asset($scope.intervention._plateforme);
    }

    $scope.change_asset = function (asset) {
        console.log("c", asset)

        $scope.sg_asset = asset;
        $scope.auto = false;
        console.log("d", $scope.sg_asset)

    };

    $scope.find_asset = function ($select) {

        if ($scope.platformeLogin && $scope.platformeLogin.result && $scope.platformeLogin.result.token) {
            iaxeedAssetSrv.query({
                query: {
                    _company_owner: $scope.pv_sync.client_id,
                    name: {
                        '$regex': $select.search,
                        '$options': 'i'
                    }
                }
            }, 10, 0, function (res) {
                $scope.assets = res.result;
                console.log("e", $scope.assets)

                //console.log($scope.sg_asset)

            }, function (err) {
                console.log("err", err)
            })
        }
    };

    $scope.save = function () {
        $scope.closeThisDialog($scope.sg_asset);
    };

    $scope.selectPlateform = function (platform) {
        plateformHelperSrv.setPlateFormAndLogin(platform, function (res) {}, function (err) {
            console.log(err);
        })
    };

});