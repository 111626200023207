/**
 * Created by Mohamed Saad on 15/12/2017.
 */

angular.module("app").controller("adminRoleCtrl",function ($scope,$rootScope, $state, $translate, I3RoleSrv,ngDialog, Notification) {
    $scope.itemsByPage=50;
    $scope.roleList = [];
    /*clear  search field of company*/
    /*load companies list*/
    $scope.loadRoles = function (tableState,ctrl) {
        if ( !$scope.stCtrl && ctrl )
        {
            $scope.stCtrl = ctrl;
        }

        if( !tableState && $scope.stCtrl ) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0; // This is NOT the page number, but the index of item in the list that you want to use to display the table.
        var number = $scope.itemsByPage;
        var query = {
            options:{}
        };


        if (tableState.pagination) {
            var pagination = tableState.pagination;
            // This is NOT the page number, but the index of item in the list that you want to use to display the table.
            number =  pagination.number ||  $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
        }

        var requery = {};


        query.query=requery;
        I3RoleSrv.query(query,number,start,
            function (data) {
                if (data.success) {
                    $scope.roleList = data.result;

                    $scope.Rolecount = data.total_count;

                    tableState.pagination.numberOfPages =Math.ceil(data.total_count/ number);
                    tableState.pagination.totalItemCount = data.total_count;
                    $scope.tableState = tableState;
                } else {
                    console.error(data.error)
                }

            }, function (data) {
                // console.log(data)
            })
    };
    $scope.selectPage = function () {
        $scope.loadRoles({})
    };
    $scope.remove = function (user) {
        $scope.role = user;

        ngDialog.open({
            templateUrl : "app/commons/dialogs/roles/role.delete.dlg.tpl.html",
            controller : "confirmDeleteRoleDlgCtrl",
            scope: $scope
        })

    };

});