angular.module('app').controller('dashboardEditCtrl', function ($scope, $stateParams, $translate, ngDialog, I3StatusSrv, Notification, iaxeedCompanySrv, $state, I3PlateformeSrv, NGIFleetHelperSrv, plateformHelperSrv) {



    I3PlateformeSrv.getById($stateParams.platformId, function (data) {
        if (data.success) {
            $scope.platform = data.result;
            $scope.selectPlateform($scope.platform)
            // console.log($scope.platform)
        } else {
            console.log(data.error)
        }
    })


    I3PlateformeSrv.query({}, 10000, 1, function (response) {
        $scope.plateformes = response.result;
    }, function (err) {
        console.log(err);
    });

    $scope.selectPlateform = function (platform) {
        $scope.clients = [];
        if (platform.type === 'NGI_FLEET') {
            NGIFleetHelperSrv.setPlateFormAndLogin(platform, function (res) {

            }, function (err) {
                console.log(err);
            })
        } else {
            plateformHelperSrv.setPlateFormAndLogin(platform, function (res) {
                if (res.success == false) {
                    $scope.error = res;
                    Notification.error({
                        title: $translate.instant('ERROR'),
                        message: $translate.instant("ERROR_OCCURED_AUTHORIZATION") + "!"
                    });
                }
            }, function (err) {
                $scope.error = err;
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_AUTHORIZATION") + "!"
                });
            })
        }

    }

    $scope.editplatform = function () {
        // Update platform
        // console.log($scope.platform)
        I3PlateformeSrv.updateById($scope.platform._id, $scope.platform, function (response) {
            if (response.success) {

                $state.go('app.admin.dashboard', {}, { reload: true });
                Notification.success({
                    title: $translate.instant("DASHBOARD_CREATED"),
                    message: $translate.instant("DASHBOARD_CREATED_SUC")
                });

            } else {
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_CREATED") + "!"
                });
            }
        }, function (err) {
            console.log(err);

            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("ERROR_OCCURED_CREATED") + "!"
            });

        })
    };





    $scope.openDashboardDialogue = function () {

        var modal = ngDialog.open({
            templateUrl: "app/commons/dialogs/dashboard/dashboard.dlg.html",
            controller: "addDashboardDlgCtrl",
            scope: $scope
        });
        modal.closePromise.then(function (data) {

            var dash = {
                _id: data.value.client._id,
                name: data.value.client.name
            }

            $scope.platform.dash._clients.push(dash);


        }, function () {

        });
    };


    $scope.editDash = function (element, index) {
        $scope.comt = index;
        var dialoge = ngDialog.open({
            templateUrl: "app/commons/dialogs/dashboard/dashboard.edit.dlg.html",
            controller: "editDashboardDlgCtrl",
            scope: $scope,
            resolve: {
                index: function () {
                    return index;
                }
            }
        });
        dialoge.closePromise.then(function (data) {




        }, function () {

        });
    };

    $scope.openIPaddressDialogue = function () {

        var modal = ngDialog.open({
            templateUrl: "app/commons/dialogs/dashboard/ipAddress.add.dlg.html",
            controller: "addIPaddressDlgCtrl",
            scope: $scope
        });
        modal.closePromise.then(function (data) {
            if (data.value.address) {


                var ip = {
                    address: data.value.address,
                    comments: data.value.comments
                }

                $scope.platform.dash.ip_address.push(ip);

            }
        }, function () {

        });
    };


    $scope.editIpaddress = function (element, index) {
        $scope.ipIndex = index;
        var dialoge = ngDialog.open({
            templateUrl: "app/commons/dialogs/dashboard/ipAddress.edit.dlg.html",
            controller: "editIPaddressDlgCtrl",
            scope: $scope,
            resolve: {
                index: function () {
                    return index;
                }
            }
        });
        dialoge.closePromise.then(function (data) {




        }, function () {

        });
    };



    $scope.removeDashboard = function (element, index) {
        $scope.platform.dash._clients.splice(index, 1);

    };
    $scope.removeIpaddress = function (element, index) {
        $scope.platform.dash.ip_address.splice(index, 1);

    };
    $scope.cancel = function () {

        $state.go('app.admin.dashboard');
    }
})