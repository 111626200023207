/**
 * Project: ngiTracking
 * Created by NGI team 06/02/2017.
 */
angular
    .module('app').run(function ($rootScope, $transitions, accessSrv, $window, $location, $state, $timeout, CONFIG, $translate, $cookieStore, i3HelperSrv, plateformHelperSrv, NGIFleetHelperSrv) {
        $rootScope.config = CONFIG;
        $rootScope.$state = $state;
        $rootScope.appData = {};
        //set language from cookies
        var lang = "fr";
        if ($window.sessionStorage.lang && ["fr", 'en'].indexOf($window.sessionStorage.lang) >= 0) {
            lang = $window.sessionStorage.lang;
        }
        $translate.use(lang);
        // Check permissions
        $rootScope.checkAccess = function (model, action, type) {
            //check backendAccess only
            var authorized = false;

            if ($rootScope.SESSION.user._role.permissions) {

                $rootScope.SESSION.user._role.permissions.forEach(function (value) {

                    var splitted = value.split("_");
                    var crud = splitted[0];
                    var accessType = splitted[1];
                    var roleModel = splitted.slice(2, splitted.length).join("_");
                    // Check if crud is read only (Do not show on admin)
                    if (roleModel === model) {

                        if (crud.indexOf(action) >= 0) {

                            authorized = !(type && accessType !== type);
                        } else {

                            authorized = false;
                        }
                    }

                })
            }
            return authorized;
        }
        $rootScope.checkPermission = function (model, action, type, main, menu) {
            var menuAccess = accessSrv.getAccessUser(main, menu)

            var authorized = false;

            if ($rootScope.SESSION.user._role.permissions) {

                $rootScope.SESSION.user._role.permissions.forEach(function (value) {

                    var splitted = value.split("_");
                    var crud = splitted[0];
                    var accessType = splitted[1];
                    var roleModel = splitted.slice(2, splitted.length).join("_");
                    // Check if crud is read only (Do not show on admin)

                    if (roleModel === model) {

                        if ($rootScope.SESSION.user.menus && $rootScope.SESSION.user.menus.length > 0) {

                            if (menuAccess && menuAccess == true) {

                                if (crud.indexOf(action) >= 0) {

                                    authorized = !(type && accessType !== type);

                                } else {

                                    authorized = false;
                                }
                                //authorized = !(type && accessType !== type);
                            } else {

                                authorized = false;
                            }
                        } else {
                            if (crud.indexOf(action) >= 0) {

                                authorized = !(type && accessType !== type);
                            } else {

                                authorized = false;
                            }
                        }

                    }
                })
            }
            return authorized;
        };

        if ($window.sessionStorage.token&&$window.sessionStorage.SESSION) {
            $rootScope.SESSION = JSON.parse($window.sessionStorage.SESSION);
            i3HelperSrv.setLoggedInStatus(true);
            if ($window.sessionStorage.plateformToken) {
                try {
                    var plateformSession = JSON.parse($window.sessionStorage.plateformSession);
                    $rootScope.plateform = JSON.parse($window.sessionStorage.plateform);
                    if ($rootScope.plateform.name == "NGI_FLEET") {
                        NGIFleetHelperSrv.setPlateForm($rootScope.plateform, plateformSession);
                    } else {
                        plateformHelperSrv.setPlateForm($rootScope.plateform, plateformSession);
                    }

                } catch (e) {
                    console.warn(e);
                }

            }

        } else {
            $location.path("/login");
        }

        //Check route accessibility based on permissions
        $rootScope.checkRouteAccess = function (access) {
            return $rootScope.checkPermission(access[0], access[1]);
        };
        /* $rootScope.$on('$routeChangeSuccess', function () {
             console.log("mmmmmmmmmmmmmm")
             if ($rootScope.SESSION.user.menus && $rootScope.SESSION.user.menus.length > 0) {
                 if (toState.data != undefined) {
                     if(accessSrv.getAccessUser(toState.data.access[0], toState.data.access[1]) == false ) {
                         console.log('DENY');
                         console.log(  $transitions)
                         console.log(  $window)
                         console.log(  $state.go())
                         $location.url("/login");

                     }
                     else {
                         console.log(  $state.go())
                         console.log('ALLOW');

                     }
                 }

             }
         });*/
        $transitions.onSuccess({}, function ($transitions) {

            var fromState = $transitions.$from();
            var toState = $transitions.$to();
            //console.log("sta console.log('ALLOW');te changed");

            if ($rootScope.SESSION && $rootScope.SESSION.user.menus && $rootScope.SESSION.user.menus.length > 0) {
                if (toState.data != undefined) {
                    if (accessSrv.getAccessUser(toState.data.access[0], toState.data.access[1]) == false) {

                        $location.url("/login");

                    } else {
                        //console.log($state.go())
                        // console.log('ALLOW');

                    }
                }

            }

        });
        /**
         *
         * Intercept transition and check route accessibility
         * - If allowed : return
         * - If not allowed:
         * # if 'fromState' exist in navigation history then go to 'fromState'.
         * # otherwise (URL wasn't clicked but typed which results 'toState' with no 'fromState') go to 'login'.
         *
         */

        $transitions.onBefore({}, function ($transitions) {
            moment.updateLocale($translate.use(), {
                week: {
                    dow: 0
                }
            })
            var fromState = $transitions.$from();
            var toState = $transitions.$to();

            var signedIn = $rootScope.SESSION;
            if (signedIn) {
                if (fromState.name === "" || fromState.name === "login") {

                }

                var pub = angular.isUndefined(toState.access);

                if (!pub) {
                    if (!$rootScope.checkRouteAccess(toState.access)) {
                        if (fromState !== "") {
                            return false;
                        } else {

                            $state.go("login");
                            return false;
                        }
                    }
                }

            }

        });

    })
    .factory('authInterceptor', function ($rootScope, $q, $window, CONFIG) {
        return {
            request: function (config) {
                config.headers = config.headers || {};

                if (config.url.indexOf(CONFIG.API_URI) >= 0) {

                    if ($window.sessionStorage.token && !config.headers.Authorization) {
                        config.headers.Authorization = 'Bearer ' + $window.sessionStorage.token;
                        config.headers["oo-apikey"] = $rootScope.SESSION.user.oo_key;
                    }

                } else if ($rootScope.plateform &&
                    $rootScope.plateform.url &&
                    config.url.indexOf($rootScope.plateform.url) >= 0) {

                    if ($window.sessionStorage.plateformToken && !config.headers.Authorization) {
                        config.headers.Authorization = 'Bearer ' + $window.sessionStorage.plateformToken;
                    }
                }

                return config;
            },
            response: function (response) {
                /* if(response.status === 400 || response.status === 401 || response.status === 403 ) {
                     $rootScope.$broadcast("sessionExpired", response.status);
                 }*/

                return response || $q["when"](response);
            },

            responseError: function (response) {
                //
                //Except total api for session expired
                if (response.status === 401 && response.config.url && response.config.url.indexOf(CONFIG.API_URI) >= 0) {

                    $rootScope.$broadcast("sessionExpired", response);
                } else {
                    $rootScope.$broadcast("responseError", response);
                }
                return response;
            }
        }
    })
    .config(['$qProvider', function ($qProvider) {
        $qProvider.errorOnUnhandledRejections(false);

    }])
    .run(["$rootScope", "$q", "$state", function ($rootScope, $q, $state) {
        $rootScope.$on("sessionExpired", function (response) {

            $state.go("login");
        });

    }]).config(["$httpProvider", function ($httpProvider) {
        $httpProvider.interceptors.push('authInterceptor');

    }]).run(function ($rootScope, $q) {
        if (typeof Stimulsoft !== 'undefined') {

        } else {
            var defer = $q.defer();
            $rootScope.loadScriptPromise = defer.promise;
            //RESOLVE XLSX conflict with Stimulsoft lib
            _XLSX = XLSX;
            _XLS = XLS;
            zip.installJS("app/assets/utils/stimulsoft_flat/cdn.zip", ["stimulsoft.reports.js", "stimulsoft.viewer.js"], function () {

                //console.log("script loaded ");

                if ($rootScope.loadScriptPromise) {
                    defer.resolve();
                }

            });

        }
    })
    .run(function (editableOptions) {
        editableOptions.theme = 'bs3';
    });