angular.module('app.dialogs').controller('productGenerateDlgCtrl', function ($scope, constantSrv,I3ProductSrv) {
    //$scope._HOME.subscriptionTypes = constantSrv.getSubscriptionTypes();
    var today = moment().startOf("day").clone();
    $scope.pvProduct = {}
    $scope.categoryZone = {}
    $scope.zonesList = []
    var now = moment().clone();
    $scope.dayRes = now;

    if (!$scope.pv.products)
        $scope.pv.products = []
    $scope.addPvProduct = function () {
        $scope.pvProduct.zones = $scope.zonesList;
        $scope.pv.products.push($scope.pvProduct)
        $scope.gnPv.products.push($scope.pvProduct)
        //console.log($scope.pv)
        $scope.closeThisDialog();
    };

    $scope.getProductsByType = function (type) {
        $scope.selectedProductType = type;
        $scope.pvProduct._product = null;
        I3ProductSrv.query({ query: {
            type: type,
        },     options: {
            populate: [{
                    path: 'code',
    
                }
              
            ]
        }}, 10000, 1, function (response) {
        
         $scope.products=  response.result
        
        }, function (err) {
            console.log(err);
        });
     
    }

    $scope.getCategoryByZone = function (zone) {
        $scope.categoryZone.category = null;
        $scope.zoneCategories = zone.travel_costs.map(function (e) {
            return e.category;
        });

    }

    $scope.removeCategory = function (index) {
        $scope.zonesList.splice(index, 1);

    }

    $scope.addCategoryZone = function () {
        if ($scope.categoryZone._zone && $scope.categoryZone.category && $scope.categoryZone.quantity) {
            $scope.zonesList.push($scope.categoryZone)
            $scope.categoryZone = {}
        }

    }
});