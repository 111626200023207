angular.module("app").controller("deliveryRequestDlgCtrl", function ($scope,constantSrv, $rootScope, $filter, $state, $translate, ngDialog, Notification) {
$scope.asset = {
    req_options:{
    canbus: 0,
    device: 0,
    direct_line: 0,
    door_sensor: 0,
    group_cold_sensor: 0,
    ibutton_reader: 0,
    ibutton_tag: 0,
    immobilizer_12V: 0,
    immobilizer_24V: 0,
    sim_supplier_ngi: 0,
    temp_probe: 0}};
    $scope.oderProduct=[]
    $scope.product_consomable = {}
    $scope.listProductC = [];




constantSrv.convertProduct($scope.asset.req_options,function(err,listProd,invoicableProduct,consommableProduct) {
    if (err) {
        console.error(err,listProd)
    } else {
        $scope.invoicableProduct=invoicableProduct;
        $scope.consommableProduct=consommableProduct;
                try {
                    $scope.oderProduct=listProd.map(function(prod){
                        prod.isInvoicable=true;
                        return prod;
                    })
                
                }catch(e) {
                    console.error(e);
                }



    }
})
$scope.addDeliveryProduct = (function(){


   $scope.oderProduct.forEach(function(element) {
       if(element.qty != 0){
        $scope.listProductC.push(element)
       }
      
    });



    if($scope.listProductC.length>0 ){

        var delivery = {
            comment : $scope.comment,
            req_options :  $scope.oderProduct,
           
        }
        $scope.closeThisDialog(delivery)
  
    }else{
        Notification.error($translate.instant("SELECT_PRODUCT_ERROR"));
    }
  

})

$scope.getProductConsomable = function (productC) {
    productC.qty = 0
    var prod={
        _product:productC,
            qty:0
    }
   
   $scope.oderProduct.push(prod)
//    console.log($scope.oderProduct)
   
   $scope.product_consomable = {}
}


$scope.remove = function(prodC,index){
    $scope.oderProduct.splice(index,1)
}

})