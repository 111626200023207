angular.module("app.services").factory('PvGen', function ($q, i3HelperSrv, I3ProductSrv, I3deliverySlipSrv, I3GoodsReturnSlipSrv) {
    /**
     * PV generator
     * @param installationRequest
     * @param installationId
     * @param deliverySplit
     * @param goodsReturnSplit
     * @constructor
     */
    function PVGen(installationRequest, installationId, pvNumber, type) {
        this.installationRequest = installationRequest;
        this.installationId = installationId;
        this.pvNumber = pvNumber
        this.products = [];
        this.type = type;
        // console.log(installationRequest,installationId)
        this.installation = this.getInstallation();

    }
    PVGen.prototype.prepare = function () {
        var defer = $q.defer();
        var self = this;

        var promises = [
            this.getPVProducts(),
            this.getDeliveryOrderByPVId(),
            this.getDeliveryReturnByPVId(),
        ]
        $q.all(promises).then(function (res) {
            try {
                //   console.log(res)
                self.preperareProducts()

                defer.resolve(self);
            } catch (e) {
                console.error(e);
            }
        }, function (err) {
            defer.reject(err)
        })
        return defer.promise;
    }
    PVGen.prototype.prepareIntervention = function () {
        var defer = $q.defer();
        var self = this;

        var promises = [
            this.getPVProducts(),
            this.getDeliveryOrderByPVId(),
            this.getDeliveryReturnByPVId(),
        ]
        $q.all(promises).then(function (res) {
            try {
                //   console.log(res)
                self.preperareProductsIntervention()

                defer.resolve(self);
            } catch (e) {
                console.error(e);
            }
        }, function (err) {
            defer.reject(err)
        })
        return defer.promise;
    }
    PVGen.prototype.getPVProducts = function () {
        var defer = $q.defer();
        var self = this;
        // console.log("getPvProduscts");
        this.getProductsList().then(function (res) {
            // console.log(res);
            defer.resolve(res);
        }, function (err) {
            defer.reject(err);
        })
        return defer.promise;
    }
    PVGen.prototype.getDeliveryOrderByPVId = function () {
        var defer = $q.defer();
        var self = this;
        I3deliverySlipSrv.query({ query: { _plateforme: this.installationRequest._plateforme._id, pv_ref: this.pvNumber }, options: { populate: "products._product" } }, 1, 0, function (res) {
            if (res && res.success && res.result.length > 0) {
                self.deliverySplit = res.result[0];
                defer.resolve(res.result[0])
                // console.log("getDeliveryOrderByPVId resolved",self.deliverySplit)
            } else {
                self.deliverySplit = { products: [] };
                defer.resolve({ products: [] })
            }

        }, function (err) {
            defer.reject(err);
        })
        return defer.promise;
    }
    PVGen.prototype.getDeliveryReturnByPVId = function () {
        var defer = $q.defer();
        var self = this;
        I3GoodsReturnSlipSrv.query({ query: { "pv.pv_ref": this.pvNumber }, options: { populate: "products._product" } }, 1, 0, function (res) {
            if (res && res.success && res.result.length > 0) {
                self.goodsReturnSplit = res.result[0];
                // console.log("getDeliveryReturnByPVId resolved",self.goodsReturnSplit)
                defer.resolve(res.result[0])

            } else {
                self.goodsReturnSplit = { products: [] };
                defer.resolve({ products: [] })
                // console.log("getDeliveryReturnByPVId resolved",self.deliverySplit)
            }
        }, function (err) {
            defer.reject(err);
        })
        return defer.promise;
    }
    PVGen.prototype.getProductsList = function () {
        var defer = $q.defer();
        var self = this;
        this.invoicableProduct = [];
        this.productList = [];
        i3HelperSrv.getEnums(function (enums) {
            try {
                if (enums && enums.code_products) {
                    const invoiceable_id = ["GPS_DEVICE", "SIM_CARD", "CAN_BUS", "DIRECT_LINE", "RELAY_12V", "RELAY_24V", "IBUTTON_READER", "IBUTTON_TAG", "DOOR_SENSOR", "TEMPERATURE_PROBE", "GROUP_STATE"];
                    const invoiceableProductCode = [];
                    const consumablesProductCode = [];
                    invoiceable_id.forEach(function (id) {
                        if (enums.code_products[id]) {
                            invoiceableProductCode.push(enums.code_products[id])
                        }
                    })

                    Object.keys(enums.code_products).forEach(function (e) {
                        if (invoiceableProductCode.indexOf(enums.code_products[e]) < 0) {
                            consumablesProductCode.push(enums.code_products[e])
                        }
                    })


                    I3ProductSrv.query({
                        query: { type: "MATERIAL" }, options: {
                            populate: [
                                { path: "_installation_prod" },
                                { path: "_unsubscribe_product" }, { path: "_intervention_prod" }, { path: "_subscription_prod" }]
                        }
                    }, 10000, 1, function (res) {
                        try {
                            if (res.success && res.result && res.result.length > 0) {
                                self.productList = res.result;
                                var invoicableProduct = [];
                                var consommablesProduct = [];
                                res.result.forEach(function (prod) {
                                    var isInvoicable = false;
                                    if (prod.code && prod.code.product_type) {
                                        if (invoiceableProductCode.indexOf(prod.code.product_type) >= 0) {
                                            invoicableProduct.push(prod);
                                        } else {

                                            consommablesProduct.push(prod);
                                        }
                                    } else {
                                        consommablesProduct.push(prod);
                                    }
                                })
                                self.invoicableProduct = invoicableProduct;
                                defer.resolve(invoicableProduct);
                                // console.log("getProductsList resolved")
                            } else {
                                defer.reject("NO_PRODUCT_FOUND");
                            }
                        } catch (e) {
                            console.error(e);
                            defer.reject(e);
                        }
                    }, function (err) {
                        defer.reject(err);
                    })
                } else {
                    defer.reject("Configuration server :  enums products not found");
                }
            } catch (e) {
                console.error(e);
                defer.reject(e);
            }
        }, function (err) {
            defer.reject(err);
        })


        return defer.promise;
    }

    /**
     * get installation (asset) from installation request
     * @returns {null}
     */
    PVGen.prototype.getInstallation = function () {
        var self = this;

        if (this.type == "INSTALLATION") {
            // console.log("ggggggggggggggggggggggggggggg")
            var installationsFiltered = this.installationRequest.assets.filter(function (installation) {
                return installation._id == self.installationId;
            })
            if (installationsFiltered && installationsFiltered.length > 0) {
                return installationsFiltered[0];
            } else {
                return null;
            }
        } else {
            // console.log("ffffffffffffff")
            var installationsFiltered = this.installationRequest.asset.filter(function (installation) {
                return installation._id == self.installationId;
            })
            if (installationsFiltered && installationsFiltered.length > 0) {
                return installationsFiltered[0];
            } else {
                return null;
            }
        }


    }
    /**
     * prepare Products including all  products :  material ,  operation,  services
     * @returns {PVGen}
     */
    PVGen.prototype.preperareProducts = function () {


        var materials = this.getProductsMaterial();
        var subscriptions = this.getProductsSubscription(materials);
        var operations = this.getProductsOperation(materials);
        this.products = materials.concat(subscriptions).concat(operations);
        this.products = this.products.filter(function (value) {
            return value.quantity > 0
        })

        return this;
    }
    PVGen.prototype.preperareProductsIntervention = function () {


        var materials = this.getProductsMaterialIntervention();
        //  var  subscriptions=this.getProductsSubscription(materials);
        //   var  operations=this.getProductsOperation(materials);
        this.products = materials;
        //   console.log('this.products',this.products)
        /* 
       materials.concat(subscriptions).concat(operations);
         this.products=this.products.filter(function (value) {
             return  value.quantity>0
         })*/

        return this;
    }
    PVGen.prototype.getProductsMaterialIntervention = function () {
        var deliveredProducts = [];
        var returnedProducts = [];
        if (this.deliverySplit && this.deliverySplit.products && this.deliverySplit.products.length > 0) {
            deliveredProducts = this.deliverySplit.products;
        }
        if (this.goodsReturnSplit && this.goodsReturnSplit.products && this.goodsReturnSplit.products.length > 0) {
            returnedProducts = this.goodsReturnSplit.products;
        }
        var installed = this.getRealInstalledProducts(deliveredProducts, returnedProducts);
        // console.log(installed)
        return installed
    }
    /**
     * get installed material  from and convert it to Invoiceable product
     */
    PVGen.prototype.getProductsMaterial = function () {
        var deliveredProducts = [];
        var returnedProducts = [];
        if (this.deliverySplit && this.deliverySplit.products && this.deliverySplit.products.length > 0) {
            deliveredProducts = this.deliverySplit.products;
        }
        if (this.goodsReturnSplit && this.goodsReturnSplit.products && this.goodsReturnSplit.products.length > 0) {
            returnedProducts = this.goodsReturnSplit.products;
        }
        var installed = this.getRealInstalledProducts(deliveredProducts, returnedProducts);
        // console.log(installed)
        return this.convertToInvoiceableMaterials(installed)
    }
    /**
     * get real installed materials depends on delivered and  returned
     * @param delivered
     * @param returned
     * @returns {Array}
     */
    PVGen.prototype.getRealInstalledProducts = function (delivered, returned) {
        var installed = [];
        if (delivered.length > 0) {
            if (returned && returned.length > 0) {
                var installedGroupped = {}
                var products = {}
                delivered.forEach(function (product) {
                    if (!installedGroupped[product._product._id]) {
                        installedGroupped[product._product._id] = product.qty;
                        products[product._product._id] = product._product;
                    } else installedGroupped[product._product._id] += product.qty

                })
                returned.forEach(function (returnedProduct) {
                    if (!installedGroupped[returnedProduct._product._id]) {
                        console.error('INVALID RETEUREND PRODUCT my it has been never délivered', returnedProduct);
                    }
                    else installedGroupped[product._product._id] -= product.qty
                })
                Object.keys(installedGroupped).forEach(function (value) {
                    if (installedGroupped[value] > 0)
                        installed.push({
                            _product: products[value],
                            qty: installedGroupped[value]
                        })
                })
                return installed;
            } else {
                installed = delivered;
                return installed;
            }
        } else {
            return [];
        }
    }
    PVGen.prototype.convertToInvoiceableMaterials = function (products) {
        try {
            var self = this;
            var HasCanBus = false, HasDirectLine = false;
            // console.log(products)
            var withFuelMeasure = (HasCanBus = this.installation.req_options.canbus > 0) || (HasDirectLine = this.installation.req_options.direct_line);
            var invoicableProducts = []


            products.forEach(function (product) {

                if (product._product.code.product_type == "GPS_DEVICE") {
                    if (withFuelMeasure) {
                        var filtered = self.productList.filter(function (prod) {
                            return prod && prod.code && prod.code.product_type && prod.code.product_type == "GPS_DEVICE" && prod.is_stockable == false;
                        })
                        if (filtered && filtered.length > 0) {
                            invoicableProducts.push({
                                _product: filtered[0],
                                quantity: product.qty,
                                withFuelMeasure: false,
                                gpsDevice: true,
                                commissioningDate: self.installation.commissioningDate || self.installation.install_Date || self.installation.planned_Date,
                                zones: []

                            });
                        }
                    } else {
                        invoicableProducts.push({
                            _product: product._product,
                            quantity: product.qty,
                            withFuelMeasure: false,
                            gpsDevice: true,
                            commissioningDate: self.installation.commissioningDate || self.installation.install_Date || self.installation.planned_Date,
                            zones: []

                        });

                    }
                } else if (product._product.code.product_type == "M.CANBUS" || product._product.code.product_type == "M.DIRECT") {
                    if (withFuelMeasure) {
                        var filtered = self.productList.filter(function (prod) {
                            return prod && prod.code && prod.code.product_type && prod.code.product_type == "M.CANBUS" && prod.is_stockable == false;
                        })
                        if (filtered && filtered.length > 0) {
                            invoicableProducts.push({
                                _product: filtered[0],
                                quantity: product.qty,
                                withFuelMeasure: false,
                                gpsDevice: true,
                                commissioningDate: self.installation.commissioningDate || self.installation.install_Date || self.installation.planned_Date,
                                zones: []

                            });
                        }
                    } else {
                        //TODO throw error asset has no option installed fuel measure but has delivered  fuel measurement  device ???
                    }
                } else {
                    if (self.isInvoiceableProduct(product)) {
                        invoicableProducts.push({
                            _product: product._product,
                            quantity: product.qty,
                            withFuelMeasure: false,
                            commissioningDate: self.installation.commissioningDate || self.installation.install_Date || self.installation.planned_Date,
                            zones: []

                        });
                    } else {
                        console.debug("product not invoiceable")
                    }
                }

            })
        } catch (e) {
            console.log(e);
        }
        return invoicableProducts;
    }
    PVGen.prototype.isInvoiceableProduct = function (product) {
        // check if product is invoiceable
        var invoicable = false;
        this.invoicableProduct.forEach(function (prod) {
            if (prod._id == product._product._id) {
                invoicable = true;
            }
        })
        return invoicable;
    }
    PVGen.prototype.getProductsSubscription = function (materials) {
        var subscriptions = [];
        var self = this;
        if (materials && materials.length > 0) {
            materials.forEach(function (prod) {
                if (prod._product._subscription_prod) {
                    subscriptions.push({
                        "commissioningDate": prod.commissioningDate,
                        "_product": self.getProductByRelatedId(prod._product._subscription_prod, "_subscription_prod"),
                        "billingDate": moment.utc(prod.commissioningDate).add(1, "months"),
                        "subscriptionType": "ANNUAL",
                        "zones": [],
                        "quantity": prod.quantity
                    })
                }
            })
            return subscriptions;
        } else {
            return [];
        }
    }

    PVGen.prototype.getProductsOperation = function (materials) {
        var operations = [];
        var self = this;
        if (materials && materials.length > 0) {
            materials.forEach(function (prod) {
                if (prod.gpsDevice && prod._product._installation_prod) {
                    operations.push({
                        "commissioningDate": prod.commissioningDate,
                        "_product": self.getProductByRelatedId(prod._product._installation_prod, "_installation_prod"),
                        "zones": [],
                        "quantity": prod.quantity
                    })
                }
            });

            return operations;
        } else {
            return [];
        }
    }
    PVGen.prototype.includeTravelCosts = function (zone, category) {
        var self = this;
        var foundedProd = false;
        for (var i = 0; i < this.products.length; i++) {
            if (self.products[i]._product.type == "OPERATION") {
                self.products[i].zones = [];
                self.products[i].zones.push({
                    _zone: zone,
                    category: category.category,
                    quantity: 1
                })
                foundedProd = true;
                break;
            }

        }
        if (!foundedProd) {
            var withFuelMeasure = (HasCanBus = this.installation.req_options.canbus > 0) || (HasDirectLine = this.installation.req_options.direct_line);
            if (withFuelMeasure) {
                var filtered = self.productList.filter(function (prod) {
                    return prod && prod.code && prod.code.product_type && prod.code.product_type == "GPS_DEVICE" && prod.is_stockable == false;
                })
                self.products.push({
                    "commissioningDate": self.installation.commissioningDate || self.installation.install_Date || self.installation.planned_Date,
                    "_product": self.getProductByRelatedId(filtered[0]._installation_prod, "_installation_prod"),
                    "zones": [{
                        _zone: zone,
                        category: category.category,
                        quantity: 1
                    }
                    ],
                    "quantity": 0
                })


            } else {
                var filtered = self.productList.filter(function (prod) {
                    return prod && prod.code && prod.code.product_type && prod.code.product_type == "GPS_DEVICE" && prod.is_stockable == true;
                })
                self.products.push({
                    "commissioningDate": self.installation.commissioningDate || self.installation.install_Date || self.installation.planned_Date,
                    "_product": self.getProductByRelatedId(filtered[0]._installation_prod, "_installation_prod"),
                    "zones": [{
                        _zone: zone,
                        category: category.category,
                        quantity: 1
                    }
                    ],
                    "quantity": 0
                })
            }
        }



    }
    PVGen.prototype.removeTravelCosts = function (zone, category) {
        var self = this;
        for (var i = 0; i < this.products.length; i++) {

            self.products[i].zone = [];

        }


    }
    PVGen.prototype.getProductByRelatedId = function (prodId, attr) {
        var self = this;
        var product = null;
        for (var i = 0; i < this.productList.length; i++) {
            if (self.productList[i][attr] && self.productList[i][attr]._id == (prodId._id || prodId)) {
                return product = self.productList[i][attr];
            }
        }
        return product
    }

    return PVGen;
})