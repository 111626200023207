
angular.module('app').controller('clientSynchroneCtrl', function ($scope, invoiceClientSrv, $rootScope, I3InstallationGroupSrv, I3ClientSrv, $state, $translate, $filter, Notification, ngDialog) {


    $scope.Clients = [];
    $scope.ClientsInvoice = [];
    $scope.clientsync = {}



    invoiceClientSrv.query({}, 1000, 0, function (res) {

        if (res && res.result.length > 0) {
            $scope.ClientsInvoice = res.result;



        }
    })
    $scope.loadClients = function () {

        var start = 0;
        var number = 100;
        var options = {
            populate: [


            ],
            sortBy: { creation_dt: -1 }
        };
        var query = { options: options };
        //  var requery = {external_id:{ $exists: true }};
        //query.query = requery;

        I3ClientSrv.query({}, number, start, function (res) {

            if (res && res.result.length > 0) {
                $scope.Clients = res.result;



            }
        })



    }


    /*
    var agg_query = [
        {
            $group: {
                _id: '$client_id',
    
                client_name: {
                    $first: '$client_name'
                },
                code_client: {
                    $first: '$code_client'
                },
                SAP: {
                    $first: '$SAP'
                },
                _plateforme: {
                    $first: '$_plateforme'
                }
            }
        }
            ];
    I3InstallationGroupSrv.agg({aggregate: agg_query}, 1000, 0, function (response) {
    
    
        if (response.success) {
            response.result.forEach(function(element) {
          
                
                var client={
                        name:element.client_name,
                        SAP:element.SAP,
                        code_client:element.code_client,
                        tcfleet_id:element._id,
                        _plateforme:element._plateforme
    
                }
    
    
               
         
    
    
    
            });
          
            
        }
    });*/

    $scope.synchroneClient = function (client, index) {


        ngDialog.open({
            templateUrl: "app/commons/dialogs/invoiceSynchrone/synchroneClient.dlg.tpl.html",
            controller: "synchroneClienteDlgCtrl",
            className: 'ngdialog-theme-default custom-width-500',
            appendClassName: 'ngdialog-custom',
            scope: $scope
        }).closePromise.then(function (data) {
            // console.log('data:', data)
            if (data.value && data.value.invoice_client) {
                $scope.invoiceClientSync = data.value.invoice_client
                I3ClientSrv.query({ query: { _id: client._id } }, 1, 0, function (res) {
                    //  console.log('res:', res)



                    if (res && res.result.length > 0) {

                        $scope.clientsync = res.result[0]

                        // $scope.clientsync.SAP=data.value.invoice_client.SAP || null;

                        $scope.clientsync.invoice_id = data.value.invoice_client._id || null;


                        I3ClientSrv.updateById($scope.clientsync._id, $scope.clientsync, function (res) {
                            if (res.success) {

                                $scope.loadClients()
                                invoiceClientSrv.query({ query: { _id: data.value.invoice_client._id } }, 1000, 0, function (res) {

                                    if (res && res.result.length > 0) {
                                        data.value.invoice_client.external_id = $scope.clientsync._id;
                                        data.value.invoice_client.SAP = $scope.clientsync.SAP;
                                        data.value.invoice_client._client_code = ($scope.clientsync.code_client) ? $scope.clientsync.code_client : null;
                                        data.value.invoice_client.SUB_CLIENT = ($scope.clientsync.SUB_CLIENT) ? $scope.clientsync.SUB_CLIENT : undefined,
                                            data.value.invoice_client.client_group = ($scope.clientsync.client_group) ? $scope.clientsync.client_group : undefined,
                                            invoiceClientSrv.updateById(data.value.invoice_client._id, data.value.invoice_client, function (res) {

                                                if (res && res.result.length > 0) {

                                                    Notification.success({
                                                        title: $translate.instant("CLIENT_INVOICE_SYNC_SUCC"),
                                                        message: $translate.instant('CLIENT_INVOICE_SYNC_UPDATED')
                                                    });
                                                    $scope.loadClients()

                                                }
                                            })

                                    }
                                })





                            } else {
                                Notification.warning({
                                    title: $translate.instant("UPDATE_ERROR"),
                                    message: $translate.instant('CLIENT_SYNC_UPDATE_FAILED')
                                });
                            }
                        }, function (err) {
                            Notification.error({
                                title: $translate.instant("UPDATE_ERROR"),
                                message: $translate.instant('CLIENT_SYNC_UPDATE_FAILED')
                            });
                            console.log(err);
                        })





                    }
                })



            }







        }, function () {

        });

    }
    $scope.cancel = function () {

        $state.go('app.admin.invoiceConnector');
    };

    $scope.addClientInvoice = function () {

        ngDialog.open({
            templateUrl: "app/commons/dialogs/invoiceSynchrone/addClientInvoice.tpl.html",
            controller: "addClienteInvoiceDlgCtrl",
            className: 'ngdialog-theme-default custom-width-500',
            appendClassName: 'ngdialog-custom',
            scope: $scope
        }).closePromise.then(function (data) {

            if (data.value && data.value.invoice_client) {

                var invoiceClient = {

                    name: data.value.invoice_client.name,
                    _client_code: data.value.invoice_client.code_client,
                    _subsidiary: data.value.invoice_client._plateforme.external_id,
                    _ctry: (data.value.invoice_client.client_data && data.value.invoice_client.client_data._ctry) ? data.value.invoice_client.client_data._ctry : null,
                    contact: (data.value.invoice_client.contact) ? data.value.invoice_client.contact : data.value.invoice_client.client_data.contact,
                    external_id: data.value.invoice_client._id,
                    SAP: data.value.invoice_client.SAP,
                    SUB_CLIENT: (data.value.invoice_client.SUB_CLIENT) ? data.value.invoice_client.SUB_CLIENT : undefined,
                    client_group: (data.value.invoice_client.client_group) ? data.value.invoice_client.client_group : undefined,

                }

                invoiceClientSrv.add(invoiceClient, function (res) {



                    if (res && res.result && res.result._id) {
                        data.value.invoice_client.invoice_id = res.result._id
                        I3ClientSrv.updateById(data.value.invoice_client._id, data.value.invoice_client, function (res) {
                            if (res.success) {
                                Notification.success({
                                    title: $translate.instant("CLIENT_INVOICE_ADD"),
                                    message: $translate.instant('CLIENT_INVOICE_ADD_SUCC')
                                });

                            }
                            $scope.loadClients()
                        })
                    }

                }, function (err) {
                    console.log(err)
                })

            }
        })





    }


    $scope.removeClienSync = function (client, index) {


        invoiceClientSrv.query({ query: { _id: client.invoice_id } }, 1, 0, function (res) {


            if (res && res.result.length > 0) {

                delete res.result[0].external_id;

                var clientinvoice = res.result[0];
                delete clientinvoice.__v;
                invoiceClientSrv.updateById(clientinvoice._id, { "external_id": null }, function (response) {

                    if (response && response.success) {

                        $scope.loadClients()
                    }
                })



            }
        })



        var client = $scope.Clients[index];
        delete client.__v;
        I3ClientSrv.updateById(client._id, { "invoice_id": null }, function (resp) {

            if (resp.success) {
                Notification.success({
                    title: $translate.instant("CLIENT_INVOICE_SYNC_REMOVED"),
                    message: $translate.instant('CLIENT_INVOICE_SYNC_REMOVED_SUCC')
                });


                $scope.loadClients()

            }
        })


    }


})

