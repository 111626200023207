angular.module('app').controller('sidebarDashboardCtrl', function ($rootScope ,$scope,$state) {
    $scope.listMenu = [];
    $scope.loadMenu = function(){
        var tab = [
            {
                name: "INSTALLATIONS",
                permission: $rootScope.checkPermission('INSTALLATION','R'),
                route:'app.dashboard.install',
                icon:'fa fa-cog'
            },
            {
                name: "INSTALLATIONS_REQUEST",
                permission: $rootScope.checkPermission('INSTALLATION','R'),
                route:'app.dashboard.request',
                icon:'fa fa-folder-open-o'
            },
            {
                name: "CLAIMS",
                permission: $rootScope.checkPermission('CLAIM','R'),
                route:'app.dashboard.claims',
                icon:'fa fa-bug'
            },
            {
                name: "INTERVENTIONS",
                permission: $rootScope.checkPermission('INTERVENTION','R'),
                route:'app.dashboard.interventions',
                icon:'fa fa-wrench'
            }
            ]
        angular.forEach(tab, function (item) {
            if(item.permission){
                $scope.listMenu.push(item)
            }
        });

        if($scope.listMenu && $scope.listMenu.length > 0){
            if($state.current.name ==='app.dashboard')
                $state.go($scope.listMenu[0].route);
            else $state.go($state.current.name);
        }
    }
    $scope.loadMenu()
});