angular.module('app').controller("returnSlipCommentEditCtrl", function ($scope, Notification,index) {

    $scope.product=$scope.returnSlip.products[index]
      
      $scope.addreturnSlipDescription=function(){
  
          $scope.returnSlip.products[index]=$scope.product
          $scope.closeThisDialog($scope.returnSlip)
  
      }
      })