angular.module("app").controller("goodsReturnSlipCtrl", function ($scope, $rootScope, $filter, $state, $translate, ngDialog, Notification,I3StockSrv,I3GoodsReturnSlipSrv) {
    $scope.returnSlips=[]; 
    $scope.search = {};
    $scope.itemsByPage = 50;



    $scope.loadGoodsReturnSlip=function(tableState, ctrl){

        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }
        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }

        var start = 0;
        var number = $scope.itemsByPage;



        var options = {
            populate: [
                {
                    path: 'products_installed._product',
                    select: 'name'
                },{
                    path: 'products_return._product',
                    select: 'name'
                },
                {
                    path: 'products_installed._article',
                    select: 'serialNumber imei'
                },
                {
                    path: 'products_return._article',
                    select: 'serialNumber imei'
                },
                {
                    path: '_plateforme',
                    select: 'name'
                }
               
                ,
                {
                    path: 'products_return._stock',
                    select: 'name'
                }
                
                ,
                {
                    path: 'products_installed._stock',
                    select: 'name'
                }
                /*   ,
                {
                    path: 'delivery_slip',
                    select: 'reference'
                }
             ,
                {
                    path: 'pv',
                    select: 'pv_ref'
                }*/
            ],
            sortBy: {creation_dt: -1}
        };



        var query = {options: options};
        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
        }
     
     
        var requery = {};
       
        if ($scope.search) {
            
            if ($scope.search.ref) {
    
                requery["pv.pv_ref"] ={'$regex' :$scope.search.ref, '$options' : 'i'};
     
            }
            if ($scope.search.delivery_slip) {
       
                requery["delivery_slip.reference"] ={'$regex' :$scope.search.delivery_slip, '$options' : 'i'};
     
            }
            if ($scope.search._product_return) {
              
                requery["products_return._product"] = $scope.search._product_return._id;
     
            }
            if ($scope.search._product_installed) {
              
                requery["products_installed._product"] = $scope.search._product_installed._id;
     
            }
            
            if ($scope.search._plateforme) {
            
                requery["_plateforme"] = $scope.search._plateforme._id;
     
            }
            if ( $scope.search.reference) {
                requery["reference"] ={'$regex' :$scope.search.reference, '$options' : 'i'};
    
            }
        }
     
     
        query.query = requery;


 
        I3GoodsReturnSlipSrv.query(query, number, start,
            function (data) {
     
                if (data.success) {
                    $scope.returnSlips = data.result;
              
                    $scope.returnSlipsCount = data.total_count;
                    tableState.pagination.numberOfPages = Math.ceil(data.total_count / number);
                    tableState.pagination.totalItemCount = data.total_count;
                    $scope.tableState = tableState;
                } else {
                    console.error(data.error)
                }
            },
            function (err) {
                console.log("err", err)
            })
     
     
     };
    
     I3StockSrv.query({}, 10000, 1, function (res) {
        if (res.success) {
            $scope.ListStocks = res.result;
            $scope.virtual_stocks = [];
            $scope.ListStocks.forEach(function (val) {
                $scope.virtual_stocks = $scope.virtual_stocks.concat(val.virtual_stock.map(function (stock) {
                    stock._stock = val.name;
                    return stock
                }));
            })
     
        }
     }, function (err) {
        console.log(err);
     });
    
    $scope.getVirtualStokById=function(virtualStockId){
      if(virtualStockId){
          var filtred = $scope.virtual_stocks.filter(function(val){
              return  val._id == virtualStockId;
          })
          if(filtred && filtred.length>0){
              return  filtred[0];
          }else{
              return undefined;
          }
      }else {
          return undefined;
      }
    }




    $scope.remove = function (returnSlip) {
        $scope.returnSlip = returnSlip;
    
        ngDialog.open({
            templateUrl: "app/commons/dialogs/confirm/returnSlip.delete.dlg.tpl.html",
            controller: "confirmDeletereturnSlipDlgCtrl",
            scope: $scope
        })
    
    };
})