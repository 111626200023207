/**
 * Created by SIG-32 on 18/03/2019.
 */
angular.module('app').controller('installationRequestAddCtrl', function ($rootScope, $scope, I3InstallationGroupSrv, ngDialog, Notification,I3ClientSrv, $translate, $state, $stateParams, messageBox, I3CompatibilitySrv) {
    $scope.compatibilityRequest = null;
    //
    $scope.compatibilityAssetsCount = 0;
    $scope.installation = {
        comments: [{
            owner: $rootScope.SESSION.user._id
        }]
    };
    $scope.installation.docs = [];
    var now = moment().clone();
    $scope.dayRes = now;
    $scope.pageSize = 25;
    $scope.selectedPeriod = 'day';
    $scope.emailFollow = "";
    $scope.user = "";
    $scope.com = [];
    $scope.com3 = [];
    $scope.statusInstall = [];
    $scope.liststatusavailInstallation = [];
    $scope.installationsStatus = [];
    $scope.come = false;
    $scope.show_ui = false;
    $scope.val = false;
    $scope.loading = false;
    $scope.selected = null;
    $scope.previous = null;
    $scope.old_comments = 0;
    $scope.package = {};
    $scope.selectedIndex = 0;
    $scope.currentPage = 0;
    $scope.pageSize = 25;
    $scope.current_asset = {};
    $scope.numberOfPages = 1;
    $scope.filterParams = {
        NEW: true,
        PLANNED: true,
        INSTALLED: true,
        COMMISSIONING: true,
        SUSPENDED: true
    };
    // Get the compatibility request by id
    $scope.getCompatibility = function () {
        var query = {
            _id: $stateParams.compatibilityId
        };
        var options = {
            populate: '_plateforme assets.place_id assets.comments._user clientSav_id'
        };
        I3CompatibilitySrv.query({
            query: query,
            options: options
        }, 1, 0, function (data) {
            if (data.success) {
                $scope.compatibilityRequest = data.result[0];
          
                $scope.installation.contact={}
                // Add an installation request from a compatibility
                if ($scope.compatibilityRequest) {
                            if($scope.compatibilityRequest.clientSav_id && $scope.compatibilityRequest.clientSav_id._id){
                                $scope.installation.contact.email=($scope.compatibilityRequest.clientSav_id.contact && $scope.compatibilityRequest.clientSav_id.contact.mail)?$scope.compatibilityRequest.clientSav_id.contact.mail : null
                                $scope.installation.contact.phone=($scope.compatibilityRequest.clientSav_id.contact &&$scope.compatibilityRequest.clientSav_id.contact.phone)?$scope.compatibilityRequest.clientSav_id.contact.phone : null
                                $scope.installation.contact.phone2=($scope.compatibilityRequest.clientSav_id.contact && $scope.compatibilityRequest.clientSav_id.contact.phone2)?$scope.compatibilityRequest.clientSav_id.contact.phone2 : null
                                $scope.installation.contact.name=($scope.compatibilityRequest.clientSav_id.contact && $scope.compatibilityRequest.clientSav_id.contact.name)?$scope.compatibilityRequest.clientSav_id.contact.name : null
                                $scope.installation.contact.fax=($scope.compatibilityRequest.clientSav_id.contact && $scope.compatibilityRequest.clientSav_id.contact.fax)?$scope.compatibilityRequest.clientSav_id.contact.fax : null
                                $scope.installation.SAP=$scope.compatibilityRequest.clientSav_id.SAP || null
                            }


                    $scope.installation._compatibility = $scope.compatibilityRequest._id;
                    $scope.installation._plateforme = $scope.compatibilityRequest._plateforme;
                    $scope.installation.client_name = $scope.compatibilityRequest.client;
                    $scope.installation.case_number = $scope.compatibilityRequest.case_number;
                    $scope.installation.code_client = $scope.compatibilityRequest.code_client;
                    $scope.installation.clientSav_id = $scope.compatibilityRequest.clientSav_id || null;
                    if ($scope.compatibilityRequest.assets && $scope.compatibilityRequest.assets.length > 0) {
                        $scope.compatibilityAssetsCount = $scope.compatibilityRequest.assets.length;
                        $scope.installation.assets = [];

                        $scope.installationCopy = angular.copy($scope.compatibilityRequest);

                        angular.forEach($scope.compatibilityRequest.assets, function (asset) {
                            var compatibilityRes_options = asset.res_options;
                            var newAsset = angular.copy(asset);
                            newAsset.req_options = compatibilityRes_options;
                            newAsset.status = "NEW";
                            delete newAsset.res_options;

                            $scope.installation.assets.push(newAsset);

                        });
                    }

                }

            } else {
                console.log(data.error)
            }
        }, function (error) {
            if (error) {
                console.log(error);
                $state.go("app.home.compatibilityRequest")
            }
        });
    }();

    $scope.save = function (installRequest) {

        var install = angular.copy(installRequest);

        install._plateforme = installRequest._plateforme._id;

        if (install.comments[0].msg && install.comments[0].msg.length > 0) {

        } else {
            delete install.comments;
        }
        if ($scope.documents) {
            install.docs = $scope.documents;
        }

        I3InstallationGroupSrv.add(install, function (res) {

            if (res && res.success) {
                // Update compatibility status
                $scope.compatibilityRequest.status = "CLOSED";
                I3CompatibilitySrv.updateById($stateParams.compatibilityId, $scope.compatibilityRequest, function (response) {

                    if (response.success) {
                        $state.go("app.home.compatibilityRequest");
                        Notification.success($translate.instant("INSTALLATION_GROUP_ADDED"));

                    } else {

                        Notification.error({
                            title: $translate.instant('ERROR'),
                            message: $translate.instant("ERROR_OCCURED_UPDATE") + " " + $scope.compatibilityRequest.case_number + "!"
                        });
                    }
                }, function (err) {

                    var msg = "";
                    if (err.error.errorString) {
                        msg = err.error.errorString;
                    }
                    Notification.error({
                        title: $translate.instant('ERROR'),
                        message: msg
                    });
                })

            } else {

                //TODO handle error

                if (res && res.error && res.error.errorType && res.error.errorType == -4) {
                    Notification.error($translate.instant("INSTALLATION_GROUP_ORDER_DUP"));
                } else {
                    Notification.error($translate.instant("INSTALLATION_GROUP_ADD_EEROR"));
                }
            }

        }, function (err) {

        })
        // Notification.success("INSTALLATION_GROUP_ADDED");

    }

    //#region assetslide and filters
    //set currentPage to next
    $scope.nextpage = function () {
        $scope.selectedIndex = 0;
        $scope.currentPage = $scope.currentPage + 1;
    };
    //set currentPage to previous
    $scope.previouspage = function () {
        $scope.selectedIndex = 0;
        $scope.currentPage = $scope.currentPage - 1;
    };
    //reset current page and selectedIndex after search
    $scope.resetindex = function () {
        if ($scope.namev && $scope.namev.trim() != '') {
            $scope.currentPage = 0;
            $scope.selectedIndex = 0;
        }
    };
    //watch for asset results change for asset copy
    $scope.$watch('limited_fname_fstatus_asset[selectedIndex]', function (newVal, oldVal) {
        if ($scope.limited_fname_fstatus_asset && $scope.limited_fname_fstatus_asset[$scope.selectedIndex]) {
            //console.log(newVal);
            $scope.current_asset = angular.copy(newVal);
            $scope.reset_asset = angular.copy(newVal);
        }
    });
    //watch for asset results change for showing the progress bar
    $scope.$watch('fname_fstatus_asset.length', function (newVal, oldVal) {
        //$scope.loading = true;
        if ($scope.fname_fstatus_asset) {
            $scope.numberOfPages = Math.floor(((newVal - 1) / $scope.pageSize));
            console.log("TCL: $scope.numberOfPages", $scope.numberOfPages)
        } else {
            $scope.numberOfPages = 1;
        }
        /* $timeout(function () {}, 2000).then(function () {
            $scope.loading = false;
        }); */
    });
    //can't disable all asset status and reset current page and selectedIndex after availables status change
    $(":checkbox").click(function () {
        $scope.currentPage = 0;
        $scope.selectedIndex = 0;
        if ($("input:checked").length == 1) {
            $(':checked').prop('disabled', true);
        } else {
            $(':checked').prop('disabled', false);
        }
    });
    //filter asset by name
    $scope.name_filter = function (asset) {
        if ($scope.namev && $scope.namev.trim() != '')
            return (asset.name.toLowerCase().indexOf($scope.namev.toLowerCase()) > -1)
        else
            return (true)
    };
    //filter assets (with a searched term) by status
    $scope.filterStatus = function (item, index, array) {
        var status = "NEW";
        if (item.status) {
            status = item.status;
        }
        return ($scope.filterParams.NEW && $scope.fname_asset[index].status == "NEW" || $scope.filterParams.PLANNED && $scope.fname_asset[index].status == "PLANNED" || $scope.filterParams.INSTALLED && $scope.fname_asset[index].status == "INSTALLED" || $scope.filterParams.COMMISSIONING && $scope.fname_asset[index].status == "COMMISSIONING" || $scope.filterParams.SUSPENDED && $scope.fname_asset[index].status == "SUSPENDED");
    };
    // get the length of filtered assets (with a searched term) by the status NEW
    $scope.quertstatus_new = function (item, index, array) {
        return $scope.fname_asset[index].status == "NEW"
    };
    // get the length of filtered assets (with a searched term) by the status VALIDATED
    $scope.quertstatus_pln = function (item, index, array) {
        return $scope.fname_asset[index].status == "PLANNED"
    };
    $scope.quertstatus_ins = function (item, index, array) {
        return $scope.fname_asset[index].status == "INSTALLED"
    };
    $scope.quertstatus_cms = function (item, index, array) {
        return $scope.fname_asset[index].status == "COMMISSIONING"
    };
    $scope.quertstatus_spe = function (item, index, array) {
        return $scope.fname_asset[index].status == "SUSPENDED"
    };
    $scope.quertstatus_new_2 = function (item, index, array) {
        return $scope.limited_fname_fstatus_asset[index].status == "NEW"
    };
    $scope.quertstatus_pln_2 = function (item, index, array) {
        return $scope.limited_fname_fstatus_asset[index].status == "PLANNED"
    };
    $scope.quertstatus_ins_2 = function (item, index, array) {
        return $scope.limited_fname_fstatus_asset[index].status == "INSTALLED"
    };
    $scope.quertstatus_cms_2 = function (item, index, array) {
        return $scope.limited_fname_fstatus_asset[index].status == "COMMISSIONING"
    };
    $scope.quertstatus_spe_2 = function (item, index, array) {
        return $scope.limited_fname_fstatus_asset[index].status == "SUSPENDED"
    };
    //#endregion assetslide and filters
    //#endregion update data
    //#region utility
    $scope.export = function (fileType) {
        var info = {
            date: ($scope.installationRequest && $scope.installationRequest.begin_dt ? $scope.installationRequest.begin_dt : ""),
            company: ($scope.installationRequest && $scope.installationRequest.client_name ? $scope.installationRequest.client_name : ""),
            telFax: ($scope.installationRequest && $scope.installationRequest.contact && $scope.installationRequest.contact.fax ? $scope.installationRequest.contact.fax : ""),
            mail: ($scope.installationRequest && $scope.installationRequest.contact && $scope.installationRequest.contact.email ? $scope.installationRequest.contact.email : ""),
            contact: ($scope.installationRequest && $scope.installationRequest.contact && $scope.installationRequest.contact.name ? $scope.installationRequest.contact.name : ""),
            tel: ($scope.installationRequest && $scope.installationRequest.contact && $scope.installationRequest.contact.phone ? $scope.installationRequest.contact.phone : ""),
            adressInstal: "Adresses multiples",
            data: true
        }
        data = [];
        $scope.installationRequest.assets.forEach(function (val) {
            data.push({
                "marque": val.brand,
                "model": val.model,
                "imm": val.name,
                "device_type": val.device_type,
                "_package": val._package.name
            })
        })
        var ds = {
            name: "pvMultiinstall",
            data: {
                info: info,
                data: data,
                fileName: "PV_install " + moment().format("YYY-MM-DD")
            }
        }
        if (fileType == "XLSX") {
            reportingSrv.generateExcel("templatePVinstallMulti.mrt", ds, function () {}, function () {
                Notification.error("L'export a échoué ");
            })
        } else if (fileType == "DOCX") {
            reportingSrv.generateWord("templatePVinstallMulti.mrt", ds, function () {}, function () {
                Notification.error("L'export a échoué ");
            })
        } else if (fileType == "preview") {
            //console.log("preview")
            reportingSrv.preview("templatePVinstallMulti.mrt", ds, function () {}, function () {
                Notification.error("La prévisualisation  a échoué ");
            })
        } else {
            reportingSrv.generatePdf("templatePVinstallMulti.mrt", ds, function () {}, function () {
                Notification.error("L'export a échoué ");
            })
        }
    }

    $scope.getcolor = function (x) {
        if (x) {
            return {
                'background-color': '#ffc8d2'
            };
        }
    }

    $scope.downloadFile = function (file) {
        i3HelperSrv.downloadFile(file._id, file.original_name, function success(res) {}, function error(err) {
            Notification.error($translate.instant(err));
        }, function progress(evt) {
            //console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '%');
        })
    };
    //#endregion utility
    //#region comments
    $scope.addComment = function (content, asset) {
        if (content) {
            var commentAdded = {};
            commentAdded.date = new Date();
            commentAdded._user = $rootScope.SESSION.user._id;
            commentAdded.comment = content;
            commentAdded.new = true;
            asset.comments.push(commentAdded);
            $scope.content = "";
            asset.save_state = 'unsaved';
            $scope.current_asset.save_state = 'unsaved';
        }
    };
    $scope.addComment2 = function (msg) {
        if (msg) {
            var commentAdded = {};
            commentAdded.date = new Date();
            commentAdded.owner = $rootScope.SESSION.user._id;
            commentAdded.msg = msg;
            commentAdded.new = true;
            $scope.installation.comments.push(commentAdded);
            $scope.content2 = "";
            $scope.change_comp_state();
        }

    };
    $scope.show_com = function (x, y) {
        if (y > 0) {
            $scope.com[x] = !$scope.com[x];
        }
    };
    $scope.show_com3 = function (x, y) {
        if (y > 0) {
            $scope.com3[x] = !$scope.com3[x];
        }
    };
    $scope.show_come = function (y) {
        if (y > 0) {
            $scope.come = !$scope.come;
        }
    };
    $scope.show_come10 = function (y) {
        if (y > 0) {
            $scope.come10 = !$scope.come10;
        }
    };
    $scope.show_come20 = function () {

        $scope.come20 = !$scope.come20;

    };
    $scope.comp_d = function (comment) {
        if (comment.new) {
            return true
        } else return false
    };
    $scope.deleteComment = function (index) {
        $scope.installation.comments.splice(index, 1);
        $scope.change_comp_state();
    };
    $scope.deleteAssetComment = function (index, asset) {
        asset.comments.splice(index, 1);
        asset.save_state = 'unsaved';
        $scope.current_asset.save_state = 'unsaved';
    };
    //#endregion comments
    //#region photos
    //show front photo
    $scope.afficher_front = function (x) {
        var modal = document.getElementById('myModal');
        var img = document.getElementById('imga_' + x);
        var modalImg = document.getElementById("img01");
        var captionText = document.getElementById("caption");
        var span = document.getElementsByClassName("close")[0];
        modal.style.display = "block";
        // modalImg.src = x.dataURL;
        modalImg.src = $scope.getImageUrl(x)
        captionText.innerHTML = $translate.instant("FRONT_PIC");
    };
    //show back photo
    $scope.afficher_back = function (x) {
        var modal = document.getElementById('myModal');
        var img = document.getElementById('imgb_' + x);
        var modalImg = document.getElementById("img01");
        var captionText = document.getElementById("caption");
        var span = document.getElementsByClassName("close")[0];
        modal.style.display = "block";
        // modalImg.src = x.dataURL;
        modalImg.src = $scope.getImageUrl(c)
        captionText.innerHTML = $translate.instant("BACK_PIC");
    };

    $scope.getImageUrl = function (path) {
        if (path) {

            if (path.dataURL) {
                path = path.dataURL
            }
            if (path.indexOf("data:image") >= 0) {
                return path;
            } else {
                return $rootScope.config.API_URI + "/document/registration_card/" + path + "?token=" + window.sessionStorage.token;
            }
        }
    }

    //close photo modal
    $scope.close = function () {
        var modal = document.getElementById('myModal');
        modal.style.display = "none";
    };
    //#endregion photos
    //#region Files
    $scope.getFiles = function (files) {
        I3DocumentSrv.query({
            query: {
                _id: {
                    '$in': files
                }
            }
        }, 10000, 1, function (res) {
            if (res.success) {
                $scope.docs = res.result;
            }
        }, function (err) {
            console.log(err);
        });
    };
    $scope.uploadFiles = function (files, errFiles) {
        $scope.files = files;
        $scope.errFiles = errFiles;
        i3HelperSrv.uploadFiles(files, {
            _plateforme: $scope.installation._plateforme && $scope.installation._plateforme._id ? $scope.installation._plateforme._id : $scope.installation._plateforme,
            related_model: [{
                model: "INSTALLATION",
                model_id: $scope.installation._id
            }]
        }, function (res) {
            //console.log(res)
            angular.forEach(res.result, function (file) {
                console.log(file)
                $scope.installation.docs.push(file)
            });
            $scope.change_comp_state();
            //file.result = res.data;
        }, function (response) {
            if (response.status)
                $scope.errorMsg = response.status + ': ' + response.data;
        }, function (evt) {});
    };
    $scope.deleteFiles = function (index) {
        $scope.installation.docs.splice(index, 1);
        $scope.change_comp_state();
    };
    $scope.deleteAssetFiles = function (asset, index) {
        asset.docs.splice(index, 1);
        asset.save_state = 'unsaved';
        $scope.current_asset = angular.copy(asset);
    };
    $scope.uploadAssetFiles = function (files, errFiles, x, asset) {
        var index = $scope.installation.assets.indexOf(asset);
        $scope.files = files;
        $scope.errFiles = errFiles;
        i3HelperSrv.uploadFiles(files, {
            _plateforme: $scope.installation._plateforme && $scope.installation._plateforme._id ? $scope.installation._plateforme._id : $scope.installation._plateforme,
            related_model: [{
                model: "INSTALLATION",
                model_id: $scope.installation._id
            }]
        }, function (res) {
            //console.log(res)
            angular.forEach(res.result, function (file) {
                //console.log(file)
                $scope.installation.assets[index].docs.push(file);

            });
            asset.save_state = 'unsaved';
            $scope.current_asset.save_state = 'unsaved';
        }, function (response) {
            if (response.status)
                $scope.errorMsg = response.status + ': ' + response.data;
        }, function (evt) {});
    };
    $scope.downloadFile = function (file) {
        i3HelperSrv.downloadFile(file._id, file.original_name, function success(res) {}, function error(err) {
            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant(err)
            });
        }, function progress(evt) {
            console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '%');
        })
    };
    //#endregion Files
    //#region followers
    $scope.follow = function () {
        if ($scope.emailFollow && $scope.emailFollow !== "") {
            var follower = {};
            if (!$scope.installation.followers) {
                $scope.installation.followers = [];
            }
            follower.email = $scope.emailFollow;
            var query = {
                "contact.mail": $scope.emailFollow
            };
            var options = {};
            var exist = false;
            angular.forEach($scope.installation.followers, function (follower) {
                if (follower.email.toLowerCase() === $scope.emailFollow.toLowerCase()) {
                    exist = true;
                }
            });
            if (!exist) {
                I3UserSrv.query({
                    query: query,
                    options: options
                }, 10000, 0, function (res) {
                    if (res.success) {
                        follower.user = res.result[0];
                    }
                    $scope.installation.followers.push(follower);
                    $scope.change_comp_state();
                    delete $scope.installation.__v;
                    $scope.emailFollow = "";
                });
            } else {
                Notification.warning({
                    title: $translate.instant("ALREADY_FOLLOWER_TITLE"),
                    message: $translate.instant("ALREADY_FOLLOWER_INS")
                });
                $scope.emailFollow = "";
            }
        }
    };
    $scope.removeFollower = function (follower) {
        if ($scope.installation.followers.indexOf(follower) >= 0) {
            $scope.installation.followers.splice($scope.installation.followers.indexOf(follower), 1);
            $scope.change_comp_state();
            delete $scope.installation.__v;
        }
    };
    //#endregion followers

    $scope.change_state = function (asset) {
        asset.save_state = 'unsaved';
        $scope.current_asset.save_state = 'unsaved';
    }

    $scope.remove_asset = function (asset, index) {

        $scope.installation.assets.splice(index, 1);

        $scope.installationCopy.assets.splice(index, 1)

    }

});