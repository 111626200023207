angular.module('app').controller('adminProductsCtrl', function ($scope, $rootScope, $state, $translate, $filter, Notification, ngDialog, I3ProductSrv) {

    $scope.search = {};

    $scope.product = {};
    $scope.itemsByPage = 50;
    //$scope.search = {};

    /**
     * Getting products
     */

    $scope.loadProducts  = function (tableState,ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }

        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;

        var options =  {
            populate: [
                {
                    path: '_subsidiary',
                    select: 'name'
                }
            ],
            sortBy: {creation_dt: -1}
        };

        var query = {options:options};


        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
        }
        var requery = {};


        if (tableState.search && tableState.search.predicateObject) {
            if (tableState.search.predicateObject.name) {
                requery["name"] = {"$regex": tableState.search.predicateObject.name, "$options": "i"};
            }
        }
        if($scope.search) {
            if ( $scope.search.type) {
                requery["type"] = $scope.search.type;
            }

        }

        query.query=requery;

        I3ProductSrv.query(query , number, start, function (response) {
            //  console.log(response)
            if(response.success){
                $scope.products = response.result;
                $scope.productCount = response.total_count;
                tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
                tableState.pagination.totalItemCount = response.total_count;
                //tableState.pagination.start=start*number-1>=0? start*number-1: 0;
                $scope.tableState = tableState;
            }
            else {
                console.error(response.error)
            }


        }, function (response) {
            console.log(response)

        })
    };

    /**
     * Removing product
     * @param product
     */
    $scope.remove = function (product) {
        $scope.product = product;

        ngDialog.open({
            templateUrl : "app/commons/dialogs/confirm/product.delete.dlg.tpl.html",
            controller : "confirmDeleteProductDlgCtrl",
            scope: $scope
        })

    };


});