angular.module('app').controller('adminZonesAddCtrl', function ($scope, $timeout, $rootScope, $state, $translate, $filter, Notification, ngDialog, I3ZoneSrv) {

    $scope.zone = {};

    $scope.save = function () {

        I3ZoneSrv.add($scope.zone, function (response) {
            if (response.success) {
                Notification.success({
                    title: $translate.instant("ZONE_CREATED"),
                    message: $translate.instant("ZONE_ADDED_SUC")
                });
                $state.go('app.admin.zones');

            } else {
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_CREATE") + " " + $scope.zone.name + "!"
                });
            }

        }, function (err) {

            console.log(err);

            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("ERROR_OCCURED_CREATE") + " " + $scope.zone.name + "!"
            });
            $scope.zone = {};
        })

    };
    $timeout(function () {}, 500).then(function () {
        //if (($rootScope.SESSION.user && $rootScope.SESSION.user._role.permissions && $rootScope.SESSION.user._role.permissions.indexOf('CRUD_O_PLATEFORME') > -1) && $scope._ADMIN && $scope._ADMIN.platforms && $scope._ADMIN.platforms[0])
        if($rootScope.checkAccess("PLATEFORME","R","O"))
        $scope.zone._plateforme = $scope._ADMIN.platforms[0];
    });

    $scope.editCost = function (element, index) {
        ngDialog.open({
            templateUrl: "app/commons/dialogs/zone/travel_costs.edit.dlg.html",
            controller: "travelCostEditDlgCtrl",
            scope: $scope,
            resolve: {
                index: function () {
                    return index;
                }
            }
        })
    };
    $scope.openTravelCostDialog = function () {

        ngDialog.open({
            templateUrl: "app/commons/dialogs/zone/travel_costs.dlg.html",
            controller: "travelCostDlgCtrl",
            scope: $scope
        })
    };
    $scope.removeCost = function (element, index) {
        $scope.zone.travel_costs.splice(index, 1);
    };
    /**
     * Cancel creating/editing zone
     */
    $scope.cancel = function () {
        $scope.zone = {};
        $state.go('app.admin.zones');
    };

});