/**
 * Created by Mohamed Saad on 19/01/2018.
 */

angular.module("app.services").factory('countrySrv', function ($translate){

    var service={};
    service.getCountryList=function(){
return [

    {"name":$translate.instant("Afghanistan"), "code": "AF"},
    {"name":$translate.instant( "Åland Islands"), "code": "AX"},
    {"name":$translate.instant( "Albania"), "code": "AL"},
    {"name":$translate.instant( "Algeria"), "code": "DZ"},
    {"name":$translate.instant( "American Samoa"), "code": "AS"},
    {"name":$translate.instant( "Andorra"), "code": "AD"},
    {"name":$translate.instant( "Angola"), "code": "AO"},
    {"name":$translate.instant( "Anguilla"), "code": "AI"},
    {"name":$translate.instant( "Antarctica"), "code": "AQ"},
    {"name":$translate.instant( "Antigua and Barbuda"), "code": "AG"},
    {"name":$translate.instant( "Argentina"), "code": "AR"},
    {"name":$translate.instant( "Armenia"), "code": "AM"},
    {"name":$translate.instant( "Aruba"), "code": "AW"},
    {"name":$translate.instant( "Australia"), "code": "AU"},
    {"name":$translate.instant( "Austria"), "code": "AT"},
    {"name":$translate.instant( "Azerbaijan"), "code": "AZ"},
    {"name":$translate.instant( "Bahamas"), "code": "BS"},
    {"name":$translate.instant( "Bahrain"), "code": "BH"},
    {"name":$translate.instant( "Bangladesh"), "code": "BD"},
    {"name":$translate.instant( "Barbados"), "code": "BB"},
    {"name":$translate.instant( "Belarus"), "code": "BY"},
    {"name":$translate.instant( "Belgium"), "code": "BE"},
    {"name":$translate.instant( "Belize"), "code": "BZ"},
    {"name":$translate.instant( "Benin"), "code": "BJ"},
    {"name":$translate.instant( "Bermuda"), "code": "BM"},
    {"name":$translate.instant( "Bhutan"), "code": "BT"},
    {"name":$translate.instant( "Bolivia"), "code": "BO"},
    {"name":$translate.instant( "Bosnia and Herzegovina"), "code": "BA"},
    {"name":$translate.instant( "Botswana"), "code": "BW"},
    {"name":$translate.instant( "Bouvet Island"), "code": "BV"},
    {"name":$translate.instant( "Brazil"), "code": "BR"},
    {"name":$translate.instant( "British Indian Ocean Territory"), "code": "IO"},
    {"name":$translate.instant( "Brunei Darussalam"), "code": "BN"},
    {"name":$translate.instant( "Bulgaria"), "code": "BG"},
    {"name":$translate.instant( "Burkina Faso"), "code": "BF"},
    {"name":$translate.instant( "Burundi"), "code": "BI"},
    {"name":$translate.instant( "Cambodia"), "code": "KH"},
    {"name":$translate.instant( "Cameroon"), "code": "CM"},
    {"name":$translate.instant( "Canada"), "code": "CA"},
    {"name":$translate.instant( "Cape Verde"), "code": "CV"},
    {"name":$translate.instant( "Cayman Islands"), "code": "KY"},
    {"name":$translate.instant( "Central African Republic"), "code": "CF"},
    {"name":$translate.instant( "Chad"), "code": "TD"},
    {"name":$translate.instant( "Chile"), "code": "CL"},
    {"name":$translate.instant( "China"), "code": "CN"},
    {"name":$translate.instant( "Christmas Island"), "code": "CX"},
    {"name":$translate.instant( "Cocos (Keeling) Islands"), "code": "CC"},
    {"name":$translate.instant( "Colombia"), "code": "CO"},
    {"name":$translate.instant( "Comoros"), "code": "KM"},
    {"name":$translate.instant( "Congo"), "code": "CG"},
    {"name":$translate.instant( "Congo, The Democratic Republic of the"), "code": "CD"},
    {"name":$translate.instant( "Cook Islands"), "code": "CK"},
    {"name":$translate.instant( "Costa Rica"), "code": "CR"},
    {"name":$translate.instant( "Cote D\"Ivoire"), "code": "CI"},
    {"name":$translate.instant( "Croatia"), "code": "HR"},
    {"name":$translate.instant( "Cuba"), "code": "CU"},
    {"name":$translate.instant( "Cyprus"), "code": "CY"},
    {"name":$translate.instant( "Czech Republic"), "code": "CZ"},
    {"name":$translate.instant( "Denmark"), "code": "DK"},
    {"name":$translate.instant( "Djibouti"), "code": "DJ"},
    {"name":$translate.instant( "Dominica"), "code": "DM"},
    {"name":$translate.instant( "Dominican Republic"), "code": "DO"},
    {"name":$translate.instant( "Ecuador"), "code": "EC"},
    {"name":$translate.instant( "Egypt"), "code": "EG"},
    {"name":$translate.instant( "El Salvador"), "code": "SV"},
    {"name":$translate.instant( "Equatorial Guinea"), "code": "GQ"},
    {"name":$translate.instant( "Eritrea"), "code": "ER"},
    {"name":$translate.instant( "Estonia"), "code": "EE"},
    {"name":$translate.instant( "Ethiopia"), "code": "ET"},
    {"name":$translate.instant( "Falkland Islands (Malvinas)"), "code": "FK"},
    {"name":$translate.instant( "Faroe Islands"), "code": "FO"},
    {"name":$translate.instant( "Fiji"), "code": "FJ"},
    {"name":$translate.instant( "Finland"), "code": "FI"},
    {"name":$translate.instant( "France"), "code": "FR"},
    {"name":$translate.instant( "French Guiana"), "code": "GF"},
    {"name":$translate.instant( "French Polynesia"), "code": "PF"},
    {"name":$translate.instant( "French Southern Territories"), "code": "TF"},
    {"name":$translate.instant( "Gabon"), "code": "GA"},
    {"name":$translate.instant( "Gambia"), "code": "GM"},
    {"name":$translate.instant( "Georgia"), "code": "GE"},
    {"name":$translate.instant( "Germany"), "code": "DE"},
    {"name":$translate.instant( "Ghana"), "code": "GH"},
    {"name":$translate.instant( "Gibraltar"), "code": "GI"},
    {"name":$translate.instant( "Greece"), "code": "GR"},
    {"name":$translate.instant( "Greenland"), "code": "GL"},
    {"name":$translate.instant( "Grenada"), "code": "GD"},
    {"name":$translate.instant( "Guadeloupe"), "code": "GP"},
    {"name":$translate.instant( "Guam"), "code": "GU"},
    {"name":$translate.instant( "Guatemala"), "code": "GT"},
    {"name":$translate.instant( "Guernsey"), "code": "GG"},
    {"name":$translate.instant( "Guinea"), "code": "GN"},
    {"name":$translate.instant( "Guinea-Bissau"), "code": "GW"},
    {"name":$translate.instant( "Guyana"), "code": "GY"},
    {"name":$translate.instant( "Haiti"), "code": "HT"},
    {"name":$translate.instant( "Heard Island and Mcdonald Islands"), "code": "HM"},
    {"name":$translate.instant( "Holy See (Vatican City State)"), "code": "VA"},
    {"name":$translate.instant( "Honduras"), "code": "HN"},
    {"name":$translate.instant( "Hong Kong"), "code": "HK"},
    {"name":$translate.instant( "Hungary"), "code": "HU"},
    {"name":$translate.instant( "Iceland"), "code": "IS"},
    {"name":$translate.instant( "India"), "code": "IN"},
    {"name":$translate.instant( "Indonesia"), "code": "ID"},
    {"name":$translate.instant( "Iran, Islamic Republic Of"), "code": "IR"},
    {"name":$translate.instant( "Iraq"), "code": "IQ"},
    {"name":$translate.instant( "Ireland"), "code": "IE"},
    {"name":$translate.instant( "Isle of Man"), "code": "IM"},
    {"name":$translate.instant( "Israel"), "code": "IL"},
    {"name":$translate.instant( "Italy"), "code": "IT"},
    {"name":$translate.instant( "Jamaica"), "code": "JM"},
    {"name":$translate.instant( "Japan"), "code": "JP"},
    {"name":$translate.instant( "Jersey"), "code": "JE"},
    {"name":$translate.instant( "Jordan"), "code": "JO"},
    {"name":$translate.instant( "Kazakhstan"), "code": "KZ"},
    {"name":$translate.instant( "Kenya"), "code": "KE"},
    {"name":$translate.instant( "Kiribati"), "code": "KI"},
    {"name":$translate.instant( "Korea, Democratic People\"S Republic of"), "code": "KP"},
    {"name":$translate.instant( "Korea, Republic of"), "code": "KR"},
    {"name":$translate.instant( "Kuwait"), "code": "KW"},
    {"name":$translate.instant( "Kyrgyzstan"), "code": "KG"},
    {"name":$translate.instant( "Lao People\"S Democratic Republic"), "code": "LA"},
    {"name":$translate.instant( "Latvia"), "code": "LV"},
    {"name":$translate.instant( "Lebanon"), "code": "LB"},
    {"name":$translate.instant( "Lesotho"), "code": "LS"},
    {"name":$translate.instant( "Liberia"), "code": "LR"},
    {"name":$translate.instant( "Libyan Arab Jamahiriya"), "code": "LY"},
    {"name":$translate.instant( "Liechtenstein"), "code": "LI"},
    {"name":$translate.instant( "Lithuania"), "code": "LT"},
    {"name":$translate.instant( "Luxembourg"), "code": "LU"},
    {"name":$translate.instant( "Macao"), "code": "MO"},
    {"name":$translate.instant( "Macedonia, The Former Yugoslav Republic of"), "code": "MK"},
    {"name":$translate.instant( "Madagascar"), "code": "MG"},
    {"name":$translate.instant( "Malawi"), "code": "MW"},
    {"name":$translate.instant( "Malaysia"), "code": "MY"},
    {"name":$translate.instant( "Maldives"), "code": "MV"},
    {"name":$translate.instant( "Mali"), "code": "ML"},
    {"name":$translate.instant( "Malta"), "code": "MT"},
    {"name":$translate.instant( "Marshall Islands"), "code": "MH"},
    {"name":$translate.instant( "Martinique"), "code": "MQ"},
    {"name":$translate.instant( "Mauritania"), "code": "MR"},
    {"name":$translate.instant( "Mauritius"), "code": "MU"},
    {"name":$translate.instant( "Mayotte"), "code": "YT"},
    {"name":$translate.instant( "Mexico"), "code": "MX"},
    {"name":$translate.instant( "Micronesia, Federated States of"), "code": "FM"},
    {"name":$translate.instant( "Moldova, Republic of"), "code": "MD"},
    {"name":$translate.instant( "Monaco"), "code": "MC"},
    {"name":$translate.instant( "Mongolia"), "code": "MN"},
    {"name":$translate.instant( "Montserrat"), "code": "MS"},
    {"name":$translate.instant( "Morocco"), "code": "MA"},
    {"name":$translate.instant( "Mozambique"), "code": "MZ"},
    {"name":$translate.instant( "Myanmar"), "code": "MM"},
    {"name":$translate.instant( "Namibia"), "code": "NA"},
    {"name":$translate.instant( "Nauru"), "code": "NR"},
    {"name":$translate.instant( "Nepal"), "code": "NP"},
    {"name":$translate.instant( "Netherlands"), "code": "NL"},
    {"name":$translate.instant( "Netherlands Antilles"), "code": "AN"},
    {"name":$translate.instant( "New Caledonia"), "code": "NC"},
    {"name":$translate.instant( "New Zealand"), "code": "NZ"},
    {"name":$translate.instant( "Nicaragua"), "code": "NI"},
    {"name":$translate.instant( "Niger"), "code": "NE"},
    {"name":$translate.instant( "Nigeria"), "code": "NG"},
    {"name":$translate.instant( "Niue"), "code": "NU"},
    {"name":$translate.instant( "Norfolk Island"), "code": "NF"},
    {"name":$translate.instant( "Northern Mariana Islands"), "code": "MP"},
    {"name":$translate.instant( "Norway"), "code": "NO"},
    {"name":$translate.instant( "Oman"), "code": "OM"},
    {"name":$translate.instant( "Pakistan"), "code": "PK"},
    {"name":$translate.instant( "Palau"), "code": "PW"},
    {"name":$translate.instant( "Palestinian Territory, Occupied"), "code": "PS"},
    {"name":$translate.instant( "Panama"), "code": "PA"},
    {"name":$translate.instant( "Papua New Guinea"), "code": "PG"},
    {"name":$translate.instant( "Paraguay"), "code": "PY"},
    {"name":$translate.instant( "Peru"), "code": "PE"},
    {"name":$translate.instant( "Philippines"), "code": "PH"},
    {"name":$translate.instant( "Pitcairn"), "code": "PN"},
    {"name":$translate.instant( "Poland"), "code": "PL"},
    {"name":$translate.instant( "Portugal"), "code": "PT"},
    {"name":$translate.instant( "Puerto Rico"), "code": "PR"},
    {"name":$translate.instant( "Qatar"), "code": "QA"},
    {"name":$translate.instant( "Reunion"), "code": "RE"},
    {"name":$translate.instant( "Romania"), "code": "RO"},
    {"name":$translate.instant( "Russian Federation"), "code": "RU"},
    {"name":$translate.instant( "RWANDA"), "code": "RW"},
    {"name":$translate.instant( "Saint Helena"), "code": "SH"},
    {"name":$translate.instant( "Saint Kitts and Nevis"), "code": "KN"},
    {"name":$translate.instant( "Saint Lucia"), "code": "LC"},
    {"name":$translate.instant( "Saint Pierre and Miquelon"), "code": "PM"},
    {"name":$translate.instant( "Saint Vincent and the Grenadines"), "code": "VC"},
    {"name":$translate.instant( "Samoa"), "code": "WS"},
    {"name":$translate.instant( "San Marino"), "code": "SM"},
    {"name":$translate.instant( "Sao Tome and Principe"), "code": "ST"},
    {"name":$translate.instant( "Saudi Arabia"), "code": "SA"},
    {"name":$translate.instant( "Senegal"), "code": "SN"},
    {"name":$translate.instant( "Serbia and Montenegro"), "code": "CS"},
    {"name":$translate.instant( "Seychelles"), "code": "SC"},
    {"name":$translate.instant( "Sierra Leone"), "code": "SL"},
    {"name":$translate.instant( "Singapore"), "code": "SG"},
    {"name":$translate.instant( "Slovakia"), "code": "SK"},
    {"name":$translate.instant( "Slovenia"), "code": "SI"},
    {"name":$translate.instant( "Solomon Islands"), "code": "SB"},
    {"name":$translate.instant( "Somalia"), "code": "SO"},
    {"name":$translate.instant( "South Africa"), "code": "ZA"},
    {"name":$translate.instant( "South Georgia and the South Sandwich Islands"), "code": "GS"},
    {"name":$translate.instant( "Spain"), "code": "ES"},
    {"name":$translate.instant( "Sri Lanka"), "code": "LK"},
    {"name":$translate.instant( "Sudan"), "code": "SD"},
    {"name":$translate.instant( "Suriname"), "code": "SR"},
    {"name":$translate.instant( "Svalbard and Jan Mayen"), "code": "SJ"},
    {"name":$translate.instant( "Swaziland"), "code": "SZ"},
    {"name":$translate.instant( "Sweden"), "code": "SE"},
    {"name":$translate.instant( "Switzerland"), "code": "CH"},
    {"name":$translate.instant( "Syrian Arab Republic"), "code": "SY"},
    {"name":$translate.instant( "Taiwan, Province of China"), "code": "TW"},
    {"name":$translate.instant( "Tajikistan"), "code": "TJ"},
    {"name":$translate.instant( "Tanzania, United Republic of"), "code": "TZ"},
    {"name":$translate.instant( "Thailand"), "code": "TH"},
    {"name":$translate.instant( "Timor-Leste"), "code": "TL"},
    {"name":$translate.instant( "Togo"), "code": "TG"},
    {"name":$translate.instant( "Tokelau"), "code": "TK"},
    {"name":$translate.instant( "Tonga"), "code": "TO"},
    {"name":$translate.instant( "Trinidad and Tobago"), "code": "TT"},
    {"name":$translate.instant( "Tunisia"), "code": "TN"},
    {"name":$translate.instant( "Turkey"), "code": "TR"},
    {"name":$translate.instant( "Turkmenistan"), "code": "TM"},
    {"name":$translate.instant( "Turks and Caicos Islands"), "code": "TC"},
    {"name":$translate.instant( "Tuvalu"), "code": "TV"},
    {"name":$translate.instant( "Uganda"), "code": "UG"},
    {"name":$translate.instant( "Ukraine"), "code": "UA"},
    {"name":$translate.instant( "United Arab Emirates"), "code": "AE"},
    {"name":$translate.instant( "United Kingdom"), "code": "GB"},
    {"name":$translate.instant( "United States"), "code": "US"},
    {"name":$translate.instant( "United States Minor Outlying Islands"), "code": "UM"},
    {"name":$translate.instant( "Uruguay"), "code": "UY"},
    {"name":$translate.instant( "Uzbekistan"), "code": "UZ"},
    {"name":$translate.instant( "Vanuatu"), "code": "VU"},
    {"name":$translate.instant( "Venezuela"), "code": "VE"},
    {"name":$translate.instant( "Viet Nam"), "code": "VN"},
    {"name":$translate.instant( "Virgin Islands, British"), "code": "VG"},
    {"name":$translate.instant( "Virgin Islands, U.S."), "code": "VI"},
    {"name":$translate.instant( "Wallis and Futuna"), "code": "WF"},
    {"name":$translate.instant( "Western Sahara"), "code": "EH"},
    {"name":$translate.instant( "Yemen"), "code": "YE"},
    {"name":$translate.instant( "Zambia"), "code": "ZM"},
    {"name":$translate.instant( "Zimbabwe"), "code": "ZW"}
].sort(function(a, b){
     return    a.name.localeCompare(b.name)
    });

    };

    return service;
})