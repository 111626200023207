angular.module('app').controller('adminStocksCtrl', function ($scope, $rootScope, $state, $translate, $filter, Notification, ngDialog, I3ProductSrv,I3StockSrv) {

    $scope.stock = {};
    $scope.itemsByPage = 50;
    $scope.search = {};



    /**
     * Getting users
     */
    $scope.loadStocks = function (tableState,ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }

        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;
        var options =  {
            populate: [
                {
                    path: '_plateforme',
                    select: 'name'
                }
            ],
            sortBy: {creation_dt: -1}
        };

        var query = {options:options};

        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
   }

        var requery = {};
        if (tableState.search && tableState.search.predicateObject) {
            if (tableState.search.predicateObject.name) {
                requery["name"] = {"$regex": tableState.search.predicateObject.name, "$options": "i"};
            }

            if (tableState.search.predicateObject.description ) {
                requery["description"] = {'$regex' :tableState.search.predicateObject.description, '$options' : 'i'}
            }

        }
        if($scope.search) {
            if ( $scope.search._plateforme) {
                requery["_plateforme"] = $scope.search._plateforme._id;
            }

        }

        query.query=requery;

        I3StockSrv.query(query , number, start, function (response) {
            if(response.success){
                $scope.stocks = response.result;
                $scope.stockCount = response.total_count;
                tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
                tableState.pagination.totalItemCount = response.total_count;


            } else {
                console.error(response.error)
              }


            }, function (response) {
            //   console.log(response)

            })
        
    };


    /**
     * Removing stock
     * @param stock
     */
    $scope.remove = function (stock) {
        $scope.stock = stock;

        ngDialog.open({
            templateUrl : "app/commons/dialogs/confirm/stock.delete.dlg.tpl.html",
            controller : "confirmDeleteStockDlgCtrl",
            scope: $scope
        })

    };

});