angular.module("app").controller("subscriptionsDetailCtrl", function ($scope,$stateParams, $rootScope, $filter, $state, $translate, ngDialog,I3ProductSrv,I3SubscriptionSrv, Notification) {
   
      
    $scope.search = {}
    $scope.subscription={}
    $scope.itemsByPage = 10;
    $scope.types = ["MONTHLY","ANNUAL"];
    $scope.natures = ["SUBSCRIPTION","UNSUBSCRIBE"];
    $scope.statues = ["ENABLED","DISABLED"];
    $scope.products=[]
    
    I3ProductSrv.query({}, 10000, 1, function (response) {
        $scope.products = response.result;
    }, function (err) {
        console.log(err);
    });
    


    $scope.loadSubscriptionsDetails = function (tableState, ctrl) {

        var query ={
            "_client._id":   $stateParams.clientId
        }
        var options={populate:"_plateforme _product"};
        I3SubscriptionSrv.query({query:query,options:options}, 10000, 0,
            function (data) {
       
                if (data.success) {
                 

                    if(data.result && data.result.length>0) {
                        try{
                        
                        $scope.clientName = data.result[0]._client ? data.result[0]._client.name : "";
                        $scope.subscriptions  = data.result.filter(function (val) {
                        
                            return val.isUnsubscribe == false || val.isUnsubscribe == undefined || val.isUnsubscribe == null
                        });

                            $scope.unSubscriptions = data.result.filter(function (val) {
                            return val.isUnsubscribe == true
                        });


                        $scope.subscriptionDetailsCount = 0,
                            $scope.subscriptions.forEach(function (val) {
                               
                                if (val.quantity > 0) {
                                    $scope.subscriptionDetailsCount += val.quantity
                                }
                            })
                        $scope.unSubscriptionDetailsCount = 0,
                            $scope.unSubscriptions.forEach(function (val) {
                        
                                if (val.quantity > 0) {
                                    $scope.unSubscriptionDetailsCount += val.quantity
                                }
                            
                                var _unsubscribe_products = $scope.products.filter(function (prod) {
                                  
                                    return val._unsubscribe_product == prod._id
                                });
                               
                                if (_unsubscribe_products && _unsubscribe_products.length > 0) {
                                    val._unsubscribe_product = _unsubscribe_products[0];
                                } else {
                                    val._unsubscribe_product = null;
                                }
                            })


                           // console.log("subscriptionDetailsCount" , $scope.subscriptionDetailsCount)
                           // console.log("subscriptionDetailsCount" , $scope.subscriptions)

                    }catch(e){
                        console.log(e);
                        }
                    }


                    tableState.pagination.numberOfPages = Math.ceil(data.total_count / number);
                    tableState.pagination.totalItemCount = data.total_count;
                    $scope.tableState = tableState;
                } else {
                    console.error(data.error)
                }
            },
            function (data) {
             //   console.log(data)
            })
           
    };

    $scope.cancelDetails = function () {
        if ($state.current.name === "app.accounts.subscriptions.detail") {
            $state.go('app.accounts.subscriptions');
        } else {
            ngDialog.closeAll();
        }
    };

    setTimeout(function(){
        $scope.loadSubscriptionsDetails();
    })

   
   });