/**
 * Created by Kais CHAIBI on 30/01/2018.
 */

angular.module("app.services").factory('NGIFleetHelperSrv', function ($http, $rootScope, $window, $injector) {

    function NGIFleetHelperSrv() {

    }

    NGIFleetHelperSrv.prototype.getPlatform = function () {
        return this.plateForm;
    }
    NGIFleetHelperSrv.prototype.setPlateFormAndLogin = function (plateform, success, error) {
        this.plateForm = plateform;
        $rootScope.plateform = undefined
        this.login(success, error);
    }
    NGIFleetHelperSrv.prototype.login = function (success, error) {
        var self = this;

        if (this.plateForm && this.plateForm.url) {

            $http.post(this.plateForm.url ,
                {
                    "jsonrpc": "2.0",
                    "method": "logon",
                    "params": [this.plateForm.username, this.plateForm.password, "ngi_portal"],
                    "id": 1
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }

            ).then(function (response) {
                    // this callback will be called asynchronously
                    // when the response is available
                    if (response && response.data && response.data.result) {


                      $window.sessionStorage.plateformToken = response.data.result;
                        // console.log(this)
                        try {


                        $injector.get("NGIFleetClientSrv").setApiUri(self.plateForm.url);
                        $injector.get("NGIFleetAssetSrv").setApiUri(self.plateForm.url);


                        }catch(e){
                            console.log(e);
                        }
                         self.plateformSession = response.data.result;
                        $rootScope.plateform = self.plateForm;
                        $window.sessionStorage.plateformSession = JSON.stringify(response.data.result);
                        $window.sessionStorage.plateform = JSON.stringify(self.plateForm);
                        success(response.data);
                    }
                    else if (!response.data.success && response.data.error) {

                        success(response.data)
                    }
                    else {

                        error(response);
                    }

                })
        } else {
            error("INVALID_PLATEFORM")
        }
    }
    NGIFleetHelperSrv.prototype.testLogin = function (plateform, success, error) {
        if (plateform && plateform.url) {

            $http.post(this.plateForm.url ,
                {
                    "jsonrpc": "2.0",
                    "method": "logon",
                    "params": [this.plateForm.username, this.plateForm.password, ""],
                    "id": 1
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }

            ).then(function (response) {
                // this callback will be called asynchronously
                // when the response is available
                if (response && response.data && response.data.result) {
                    success(response.data);


                }
                else if (!response.data.success && response.data.error) {

                    error(response.data)
                }
                else {

                    error(response);
                }

            })
        } else {
            error("INVALID_PLATEFORM")
        }
    }

    NGIFleetHelperSrv.prototype.setPlateForm = function (plateform, plateformSession) {
        this.plateForm = plateform;
        this.plateformSession = plateformSession;
        $rootScope.plateform = plateform;

        if (plateform && plateform.url) {


                $injector.get("NGIFleetClientSrv").setApiUri(plateform.url)
                $injector.get("NGIFleetAssetSrv").setApiUri(plateform.url)

        }

    }


    return new NGIFleetHelperSrv();
})