/**
 * Created by Mohamed Saad on 15/12/2017.
 */

angular.module("app").controller("adminRoleAddCtrl", function ($scope, $state, I3RoleSrv, $filter, $translate, Notification,constantSrv) {
    $scope.toDo = "PERMISSION";
    $scope.accessTypes = [{
            code: null,
            label: "SELECT_ACCESS_TYPE"
        },
        {
            code: "X",
            label: "NO_LIMIT_ACCESS"
        },
        /*{
            code: "A",
            label: "ATTACHED_ACCESS"
        },*/
        {
            code: "O",
            label: "OWNER_ACCESS"
        }/*,
        {
            code: "U",
            label: "USER_ACCESS"
        }*/

    ];

    $scope.accessTypes2 = [{
            code: "X",
            label: "NO_LIMIT_ACCESS"
        }/*,
        {
            code: "A",
            label: "ATTACHED_ACCESS"
        }*/,
        {
            code: "O",
            label: "OWNER_ACCESS"
        },/*
        {
            code: "U",
            label: "USER_ACCESS"
        }*/
    ];

    $scope.profiles = constantSrv.getProfiles();
    $scope.listProfiles = Object.keys($scope.profiles)
    
    $scope.allRoles = function () {
        I3RoleSrv.query({}, 10000, 0, function (data) {
            if (data.success) {
                $scope.roleChildsList = data.result;
                $scope.roleChildsList.count = data.total_count;

            } else {
                console.error(data.error)
            }

        }, function (data) {
            // console.log(data)
        })
    }
    I3RoleSrv.getById($scope.SESSION.user._role._id, function (res) {
        if (res.success) {
            $scope.allRoles();
            $scope.userRole = res.result;
        } else {
            console.log(res.error)
        }
        $scope.userRole.permissions = $scope.userRole.permissions.map(function (perm) {
            res = perm.substring(perm.indexOf("_") + 1, perm.length);
            var modelName = res.substring(res.indexOf("_") + 1, res.length);
            return {
                model: modelName,
                r: false,
                c: false,
                u: false,
                d: false,
                accessType: null
            }
        });
    }, function (err) {

    });
    $scope.addRole = function () {
        var permissions = [];
        if ($scope.userRole) {
            permissions = $scope.userRole.permissions.map(function (perm) {
                var permString = (perm.c && 'C' || '') + (perm.r && 'R' || '') + (perm.u && 'U' || '') + (perm.d && 'D' || '') + "_" + (perm.accessType || 'U') + "_" + perm.model;
                return permString;
            })
        }
        $scope.role.permissions = permissions;
        I3RoleSrv.add($scope.role, function (data) {
            if (data.success) {
                if ($state.current.name === "app.admin.roles.add") {
                    $state.go('app.admin.roles');
                    Notification($translate.instant("ALERT_ROLE_ADDED", {
                        name: $scope.role._id
                    }));
                    $scope.loadRoles()

                }

            } else {
                console.log(data.error)
            }
        });
    };
    $scope.permForm = function () {
        $scope.toDo = "PERMISSION";
    };
    $scope.childForm = function () {
        $scope.toDo = "CHILDS";
    };

    $scope.cancel = function () {
        $scope.role = {};
        $state.go('app.admin.roles');
    }

});