angular.module('app.dialogs').controller('editDashboardDlgCtrl', function ($scope,$translate,Notification,NGIFleetHelperSrv,iaxeedCompanySrv,NGIFleetClientSrv,plateformHelperSrv) {
   
    
    $scope.dash={}
  if($scope.platform.dash._clients.length>0){
    $scope.dash.client=$scope.platform.dash._clients[$scope.comt];
  }


    $scope.searchClient = function ($select) {

        if ($scope.platform && $scope.platform.type) {
            if ($scope.platform.type === 'NGI_FLEET') {
                NGIFleetClientSrv.getClient($select.search, 10, 0, function (res) {
                
                    $scope.clients = res.result;
                  
                }, function (err) {
                    console.log("err", err)
                })
            } else {
                iaxeedCompanySrv.query({
                    query: {
                        name: {
                            '$regex': $select.search,
                            '$options': 'i'
                        }
                    }
                }, 10, 0, function (res) {
                    $scope.clients = res.result;
                 
                }, function (err) {
                    console.log("err", err)
                },true)
            }
        }
    };
   
  $scope.editDash = function () {
   
    var client = $scope.platform.dash._clients.find(function(elem) {return elem._id == $scope.dash.client._id })

if(client == undefined){
    $scope.platform.dash._clients[$scope.comt]=$scope.dash.client;
    $scope.closeThisDialog($scope.platform);
}else
if( client._id ==$scope.dash.client._id){
    Notification.error({
        title: $translate.instant('ERROR'),
        message: $translate.instant("CLIENT_EXIST") + "!"
    });
    $scope.closeThisDialog(false)

}
   

  
  };

  $scope.cancel = function () {
    $scope.closeThisDialog(false);
}

});