angular.module("app.services").factory('ExceptionUtilsSrv', function ($translate){

    var utils ={};

    utils.processDBError = function(errorDetails){
            var title  =null;
            var  msg=null;
            var errorType="UNKNOWN_ERROR";
                if(errorDetails && errorDetails.name==="ValidationError"){
                    msg ="";
                    errorType="VALIDATION_ERROR";
                    title=$translate.instant("VALIDATION_ERROR");
                        Object.keys(errorDetails.errors).forEach(function(path){
                            if(errorDetails.errors[path] && errorDetails.errors[path].name =="CastError"){
                                errorType="CAST_ERROR";

                                if( msg &&  msg.length!=0) msg+="<br>";
                                msg+= "- " + $translate.instant("CAST_ERROR_MESSAGE",{path:$translate.instant(errorDetails.errors[path].path.split(".")[errorDetails.errors[path].path.split(".").length-1]),value:errorDetails.errors[path].value});
                            }else if(errorDetails.errors[path].kind=="unique"){
                                if(msg && msg.length!=0) msg+="<br/>";
                                msg+="- " +$translate.instant("PATH_EXPECTED_TO_BE_UNIQUE",{path:$translate.instant(errorDetails.errors[path].path.split(".")[errorDetails.errors[path].path.split(".").length-1]),value:errorDetails.errors[path].value});
                            }else if(errorDetails.errors[path].kind=="required"){
                                if( msg  &&  msg.length!=0) msg+="<br>";
                                msg+="- " +$translate.instant("PATH_IS_REQUIRED",{path:$translate.instant(errorDetails.errors[path].path.split(".")[errorDetails.errors[path].path.split(".").length-1]),value:errorDetails.errors[path].value});
                            }else if(errorDetails.errors[path].kind=="max"){
                                if( msg  &&  msg.length!=0) msg+="<br>";
                                console.log(errorDetails.errors[path],{path:$translate.instant(errorDetails.errors[path].path.split(".")[errorDetails.errors[path].path.split(".").length-1]),value:errorDetails.errors[path].value,max:errorDetails.errors[path].properties.max})
                                msg+="- " +$translate.instant("MAX_VALUE_EXCEEDED",{path:$translate.instant(errorDetails.errors[path].path.split(".")[errorDetails.errors[path].path.split(".").length-1]),value:errorDetails.errors[path].value,max:errorDetails.errors[path].properties.max});
                            }else if(errorDetails.errors[path].kind=="min"){
                                if( msg  &&  msg.length!=0) msg+="<br>";

                                msg+="- " +$translate.instant("MIN_VALUE_EXCEEDED",{path:$translate.instant(errorDetails.errors[path].path.split(".")[errorDetails.errors[path].path.split(".").length-1]),value:errorDetails.errors[path].value,min:errorDetails.errors[path].properties.min});
                            }
                        })
                }else{
                    // NOT a mongodb  error
                    return null ;
                }


            return {type : errorType,msg:msg,title:title};

    }
    return utils;
})