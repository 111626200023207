angular.module('app.dialogs').controller('editStatusDlgCtrl', function ($rootScope, $scope, index, $state, Notification, ngDialog) {
    $scope.statu = {};
    if (!$scope.status.status)
        $scope.status.status = [];
    $scope.statu.name = $scope.status.status[index].name;
    $scope.statu._id = $scope.status.status[index]._id;
    $scope.statu.description = $scope.status.status[index].description;
    $scope.statu.processing_time_limit = $scope.status.status[index].processing_time_limit;
    $scope.editestatus = function (status) {
        // console.log(status);
        $scope.status.status[index] = status;
        $scope.closeThisDialog();
    };
});