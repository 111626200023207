angular.module('app.dialogs').controller('ClaimStatusDlgCtrl', function ($scope) {
    //console.log($scope.ngDialogData.prop)
    $scope.dialog ={} ;
    if($scope.ngDialogData.status == 'InDevelopment' || $scope.ngDialogData.status == 'InIntervention'){
        $scope.init = $scope.ngDialogData.prop.status;
        $scope.dialog.status = $scope.init;
        $scope.dialog.level ="LEVEL_3"
    }else{
        $scope.init = $scope.ngDialogData.prop.status;
        $scope.dialog.status = $scope.init;
        $scope.dialog.level = $scope.ngDialogData.prop.level;
    }
   
  

    $scope.dialog.comment = "";



 


    $scope.update = function (stat) {
        //console.log($scope.dialog)
        if(stat){
            $scope.ngDialogData.status = stat
            if($scope.ngDialogData.status == 'InDevelopment' || $scope.ngDialogData.status == 'InIntervention'){
            
                $scope.dialog.level ="LEVEL_3"
            }else{
                $scope.dialog.level = $scope.ngDialogData.prop.level;
            }
        }
      

    }
    $scope.updateLevel = function () {
        //console.log($scope.dialog)
    }
    $scope.EditStatus = function () {
        $scope.closeThisDialog($scope.dialog);
    }
    $scope.cancel = function () {
        $scope.closeThisDialog(false);
    }
});