
angular.module('app').controller("pvDeleteDocumentDlgCtrl", function ($scope, $state, I3PvSrv, Notification, $translate) {
    $scope.confirm = function () {
        $scope.pv._documents.splice($scope.index, 1);
        delete $scope.pv.__v;
        I3PvSrv.updateById($scope.pv._id, $scope.pv, function (response) {
            if (response.success) {
                Notification.success({
                    title: $translate.instant("PV_UPDATED_TITLE"),
                    message: $translate.instant("PV_UPDATED_SUC")
                });
            } else {
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_UPDATE")
                });
            }
        }, function (err) {
            var msg = "";
            if (err.error.errorString) {
                msg = err.error.errorString;
            }
            Notification.error({
                title: $translate.instant('ERROR'),
                message: msg
            });
        })
        $scope.closeThisDialog();
    }
});