angular
  .module("app")
  .controller("mainCtrl", function ($scope, accessSrv, $state, $rootScope, i3HelperSrv, ngDialog,  $translate,$cookieStore,blockUIConfig) {
    $scope.dashMenu = [];
    $scope.homeMenu = [];
    $scope.adminMenu = [];
    $scope.monitMenu = [];
    $scope.subscMenu = [];
    $scope.stocksMenu = [];
    $scope.tog_sb = true;
    $scope.pin_sb = false;


    $scope.language = "fr";
    if ($cookieStore.get('lang') === 'fr') {
        $scope.language = "fr";
        blockUIConfig.message = $translate.instant("LOADING")
    } else if ($cookieStore.get('lang') === 'en') {
        $scope.language = "en";
        blockUIConfig.message = $translate.instant("LOADING")
    } else {
        $scope.language = "fr";
        blockUIConfig.message = $translate.instant("LOADING")
    }
    $scope.loadMenu = function () {
      var tab_dash = [{
          name: "DASH",
          permission: $rootScope.checkPermission("DASH", "R", null, "DASHBOARD", "DASH"),
          route: "app.dashboard.dash",
          icon: "fa fa-chalkboard",
          access: accessSrv.getAccessUser("DASHBOARD", "DASH"),
        },
        {
          name: "HISTORY",
          permission: $rootScope.checkPermission("HISTORY", "R", null, "DASHBOARD", "HISTORY"),
          route: "app.dashboard.history",
          icon: "fa fa-clock",
          access: accessSrv.getAccessUser("DASHBOARD", "HISTORY"),
        }
      ];
      var tab_home = [{
          name: "COMPATIBILITY_REQUEST",
          permission: $rootScope.checkPermission("COMPATIBILITY_REQUEST", "R", null, "HOME", "COMPATIBILITY_REQUEST"),
          route: "app.home.compatibilityRequest",
          icon: "fa fa-briefcase",
          access: accessSrv.getAccessUser("HOME", "COMPATIBILITY_REQUEST"),
        },
        {
          name: "COMPATIBILITY_CHECK",
          permission: $rootScope.checkPermission("COMPATIBILITY_CHECK", "R", null, "HOME", "COMPATIBILITY_CHECK"),
          route: "app.home.compatibilityCheck",
          icon: "fa fa-business-time",
          access: accessSrv.getAccessUser("HOME", "COMPATIBILITY_CHECK"),
        },

        {
          name: "INSTALLATION_REQUEST",
          permission: $rootScope.checkPermission("INSTALLATION_REQUEST", "R", null, "HOME", "INSTALLATION_REQUEST"),
          route: "app.home.installRequest",
          icon: "fa fa-hammer",
          access: accessSrv.getAccessUser("HOME", "INSTALLATION_REQUEST"),
        },
        {
          name: "INSTALLS",
          permission: $rootScope.checkPermission("INSTALLATION", "R", null, "HOME", "INSTALLATION"),
          route: "app.home.install",
          icon: "fa fa-screwdriver",
          access: accessSrv.getAccessUser("HOME", "INSTALLATION"),
        },
        {
          name: "INSTALLATION_VALIDATION",
          permission: $rootScope.checkPermission(
            "INSTALLATION_VALIDATION",
            "R",
            null,
            "HOME",
            "INSTALLATION_VALIDATION"
          ),
          route: "app.home.installValidation",
          icon: "fa fa-check-circle",
          access: accessSrv.getAccessUser("HOME", "INSTALLATION_VALIDATION"),
        },
        {
          name: "CLAIMS",
          permission: $rootScope.checkPermission("CLAIM", "R", null, "HOME", "CLAIM"),
          route: "app.home.claims",
          icon: "fa fa-bullhorn",
          access: accessSrv.getAccessUser("HOME", "CLAIM"),
        },
        {
          name: "INTERVENTION_REQUEST",
          permission: $rootScope.checkPermission("INTERVENTION_REQUEST", "R", null, "HOME", "INTERVENTION_REQUEST"),
          route: "app.home.interventionRequest",
          icon: "fa fa-file-signature",
          access: accessSrv.getAccessUser("HOME", "INTERVENTION_REQUEST"),
        },
        {
          name: "INTERVENTIONS",
          permission: $rootScope.checkPermission("INTERVENTION", "R", null, "HOME", "INTERVENTION"),
          route: "app.home.intervention",
          icon: "fa fa-wrench",
          access: accessSrv.getAccessUser("HOME", "INTERVENTION"),
        },
        {
          name: "INTERVENTION_VALIDATION",
          permission: $rootScope.checkPermission(
            "INTERVENTION_VALIDATION",
            "R",
            null,
            "HOME",
            "INTERVENTION_VALIDATION"
          ),
          route: "app.home.interventionValidation",
          icon: "fa fa-check-circle",
          access: accessSrv.getAccessUser("HOME", "INTERVENTION_VALIDATION"),
        },

        {
          name: "PVS",
          permission: $rootScope.checkPermission("PV", "R", null, "HOME", "PV"),
          route: "app.home.pvs",
          icon: "fa fa-dolly-flatbed in",
          access: accessSrv.getAccessUser("HOME", "PV"),
        },
      ];
      var tab_admin = [{
          name: "PLATFORMS",
          permission: $rootScope.checkPermission("PLATEFORME", "R", null, "ADMIN", "PLATEFORME"),
          route: "app.admin.platform",
          icon: "fa fa-laptop",
          access: accessSrv.getAccessUser("ADMIN", "PLATEFORME"),
        },
        {
          name: "CLIENTS",
          permission: $rootScope.checkPermission("CLIENT", "R", null, "ADMIN", "CLIENT"),
          route: "app.admin.clients",
          icon: "fa fa-user-tie",
          access: accessSrv.getAccessUser("ADMIN", "CLIENT"),
        },
        {
          name: "USERS",
          permission: $rootScope.checkPermission("USER", "R", null, "ADMIN", "USER"),
          route: "app.admin.users",
          icon: "fa fa-users",
          access: accessSrv.getAccessUser("ADMIN", "USER"),
        },
        /*  {
                             name: "CLAIMS",
                             permission: $rootScope.checkPermission('CLAIM', 'R'),
                             route: 'app.admin.claims',
                             icon: 'fa fa-bullhorn'
                         }, */
        {
          name: "PRODUCTS",
          permission: $rootScope.checkPermission("PRODUCT", "R", null, "ADMIN", "PRODUCT"),
          route: "app.admin.products",
          icon: "fa fa-shopping-cart",
          access: accessSrv.getAccessUser("ADMIN", "PRODUCT"),
        },
        {
          name: "PRODUCT_CODE",
          permission: $rootScope.checkPermission("PRODUCT_CODE", "R", null, "ADMIN", "PRODUCT_CODE"),
          route: "app.admin.product_code",
          icon: "fa fa-code-fork",
          access: accessSrv.getAccessUser("ADMIN", "PRODUCT_CODE"),
        },
        {
          name: "STOCK",
          permission: $rootScope.checkPermission("STOCK", "R", null, "ADMIN", "STOCK"),
          route: "app.admin.stocks",
          icon: "fa fa-pie-chart",
          access: accessSrv.getAccessUser("ADMIN", "STOCK"),
        },
        /* {
                            name: "PACKAGES",
                            permission: $rootScope.checkPermission('PACKAGE', 'R'),
                            route: 'app.admin.packages',
                            icon: 'fa fa-shopping-bag'
                        },*/
        {
          name: "DOCUMENTS",
          permission: $rootScope.checkPermission("DOCUMENT", "R", null, "ADMIN", "DOCUMENT"),
          route: "app.admin.documents",
          icon: "fa fa-copy",
          access: accessSrv.getAccessUser("ADMIN", "DOCUMENT"),
        },
        {
          name: "ZONES",
          permission: $rootScope.checkPermission("ZONE", "R", null, "ADMIN", "ZONE"),
          route: "app.admin.zones",
          icon: "fa fa-map-marked-alt",
          access: accessSrv.getAccessUser("ADMIN", "ZONE"),
        },
        {
          name: "ROLES",
          permission: $rootScope.checkPermission("ROLE", "R", null, "ADMIN", "ROLE"),
          route: "app.admin.roles",
          icon: "fa fa-user-shield",
          access: accessSrv.getAccessUser("ADMIN", "ROLE"),
        },
        {
          name: "MAILING_SETTING",
          permission: $rootScope.checkPermission("SETTING", "R", null, "ADMIN", "SETTING"),
          route: "app.admin.settings",
          icon: "fa fa-envelope",
          access: accessSrv.getAccessUser("ADMIN", "SETTING"),
        },
        {
          name: "ACTIONS_LOG",
          permission: $rootScope.checkPermission("ACTION_LOG", "R", null, "ADMIN", "ACTION_LOG"),
          route: "app.admin.actionlogs",
          icon: "fa fa-history",
          access: accessSrv.getAccessUser("ADMIN", "ACTIONS_LOG"),
        },
        {
          name: "STATUS",
          permission: $rootScope.checkPermission("STATUS", "R", null, "ADMIN", "STATUS"),
          route: "app.admin.status",
          icon: "fa fa-check",
          access: accessSrv.getAccessUser("ADMIN", "STATUS"),
        },
        {
          name: "WORKFLOW",
          permission: $rootScope.checkPermission("WORKFLOW", "R", null, "ADMIN", "WORKFLOW"),
          route: "app.admin.workflow",
          icon: "fa fa-sitemap",
          access: accessSrv.getAccessUser("ADMIN", "WORKFLOW"),
        },
        {
          name: "DASHBOARD",
          permission: $rootScope.checkPermission("DASHBOARD", "R", null, "ADMIN", "DASHBOARD"),
          route: "app.admin.dashboard",
          icon: "fa fa-dashboard",
          access: accessSrv.getAccessUser("ADMIN", "DASHBOARD"),
        },
        {
          name: "INVOICE_CONNECTOR",
          permission: $rootScope.checkPermission("INVOICE_CONNECTOR", "R", null, "ADMIN", "INVOICE_CONNECTOR"),
          route: "app.admin.invoiceConnector",
          icon: "fa fa-sync",
          access: accessSrv.getAccessUser("ADMIN", "INVOICE_CONNECTOR"),
        },
      ];
      var tab_monit = [{
          name: "ANOMALY",
          permission: $rootScope.checkPermission("MONITORING", "R", null, "MONITORING", "MONITORING"),
          route: "app.monitoring.anomaly",
          icon: "fa fa-exclamation-triangle",
          access: accessSrv.getAccessUser("MONITORING", "MONITORING"),
        },
        {
          name: "STATISTIC_PLATFORM",
          permission: $rootScope.checkPermission("STATISTIC_PLATFORM", "R", null, "MONITORING", "STATISTIC_PLATFORM"),
          route: "app.monitoring.statistic",
          icon: "fa fa-chart-line",
          access: accessSrv.getAccessUser("MONITORING", "STATISTIC_PLATFORM"),
        },
      ];
      var tab_subscrib = [{
          name: "SUBSCRIPTION",
          permission: $rootScope.checkPermission("SUBSCRIPTION", "R", null, "ACCOUNTS_SUBSCRIPTION", "SUBSCRIPTION"),
          route: "app.accounts.subscriptions",
          icon: "fa fa-address-card-o",
          access: accessSrv.getAccessUser("ACCOUNTS_SUBSCRIPTION", "SUBSCRIPTION"),
        },
        {
          name: "ACCOUNTS",
          permission: $rootScope.checkPermission("SUBSCRIPTION", "R", null, "ACCOUNTS_SUBSCRIPTION", "ACCOUNTS"),
          route: "app.accounts.clients",
          icon: "fa fa-university",
          access: accessSrv.getAccessUser("ACCOUNTS_SUBSCRIPTION", "ACCOUNTS"),
        },
      ];
      var tab_stock = [{
          name: "DELIVERY_REQUEST",
          permission: $rootScope.checkPermission("DELIVERY_REQUEST", "R", null, "STOCK_MANAGEMENT", "DELIVERY_REQUEST"),
          route: "app.stocks.deliveryRequest",
          icon: "fa fa-file-alt",
          access: accessSrv.getAccessUser("STOCK_MANAGEMENT", "DELIVERY_REQUEST"),
        },
        {
          name: "GOODS_RECEIPT",
          permission: $rootScope.checkPermission("RECEIPT_SLIP", "R", null, "STOCK_MANAGEMENT", "RECEIPT_SLIP"),
          route: "app.stocks.reception",
          icon: "fa fa-receipt",
          access: accessSrv.getAccessUser("STOCK_MANAGEMENT", "RECEIPT_SLIP"),
        },
        {
          name: "DELIVERY_SLIP",
          permission: $rootScope.checkPermission("DELIVERY_SLIP", "R", null, "STOCK_MANAGEMENT", "DELIVERY_SLIP"),
          route: "app.stocks.deliverySlip",
          icon: "fa fa-file",
          access: accessSrv.getAccessUser("STOCK_MANAGEMENT", "DELIVERY_SLIP"),
        },
        {
          name: "GOODS_RETURN_SLIP",
          permission: $rootScope.checkPermission(
            "GOODS_RETURN_SLIP",
            "R",
            null,
            "STOCK_MANAGEMENT",
            "GOODS_RETURN_SLIP"
          ),
          route: "app.stocks.goodsReturnSlip",
          icon: "fa fa-file",
          access: accessSrv.getAccessUser("STOCK_MANAGEMENT", "GOODS_RETURN_SLIP"),
        },
        {
          name: "STOCK_STATUS",
          permission: $rootScope.checkPermission("STOCK", "R", null, "STOCK_MANAGEMENT", "STOCK"),
          route: "app.stocks.status",
          icon: "fa fa-pie-chart",
          access: accessSrv.getAccessUser("STOCK_MANAGEMENT", "STOCK"),
        },
        {
          name: "ARTICLES",
          permission: $rootScope.checkPermission("ARTICLE", "R", null, "STOCK_MANAGEMENT", "ARTICLE"),
          route: "app.stocks.articles",
          icon: "fa fa-pie-chart",
          access: accessSrv.getAccessUser("STOCK_MANAGEMENT", "ARTICLE"),
        },
        {
          name: "STOCK_MOVEMENT",
          permission: $rootScope.checkPermission("STOCK_MOVEMENT", "R", null, "STOCK_MANAGEMENT", "STOCK_MOVEMENT"),
          route: "app.stocks.stockMovement",
          icon: "fa fa-pie-chart",
          access: accessSrv.getAccessUser("STOCK_MANAGEMENT", "STOCK_MOVEMENT"),
        },
      ];

      angular.forEach(tab_monit, function (item) {
        if (item.permission) {
          $scope.monitMenu.push(item);
        }
      });

      angular.forEach(tab_dash, function (item) {
        if (item.permission) {
          $scope.dashMenu.push(item);
        }
      });
      angular.forEach(tab_home, function (item) {
        if (item.permission) {
          $scope.homeMenu.push(item);
        }
        /* if (item.permission) {
                      $scope.homeMenu.push(item)
                    }*/
      });
      angular.forEach(tab_subscrib, function (item) {
        if (item.permission) {
          $scope.subscMenu.push(item);
        }
        /*  if (item.permission) {
                        $scope.subscMenu.push(item)
                    }*/
      });
      angular.forEach(tab_stock, function (item) {
        if (item.permission) {
          $scope.stocksMenu.push(item);
        }
        /* if (item.permission) {
                        $scope.stocksMenu.push(item)

                    }*/
      });
      angular.forEach(tab_admin, function (item) {
        if (item.permission) {
          $scope.adminMenu.push(item);
        }
        /* if (item.permission) {
                        $scope.adminMenu.push(item)

                    }*/
      });

      if ($state.current.name.startsWith("app.dashboard")) {
      
        if ($state.current.name === "app.dashboard") $state.go($scope.dashMenu[0].route);
        else $state.go($state.current.name);
      } else if ($state.current.name.startsWith("app.home")) {
        if ($state.current.name === "app.home") $state.go($scope.homeMenu[0].route);
        else $state.go($state.current.name);
      } else if ($state.current.name.startsWith("app.admin")) {
        if ($state.current.name === "app.admin") $state.go($scope.adminMenu[0].route);
        else $state.go($state.current.name);
      } else if ($state.current.name.startsWith("app.accounts")) {
        if ($state.current.name === "app.accounts") $state.go($scope.tab_subscrib[0].route);
        else $state.go($state.current.name);
      } else if ($state.current.name.startsWith("app.stocks")) {
        if ($state.current.name === "app.stocks") $state.go($scope.tab_stock[0].route);
        else $state.go($state.current.name);
      } else if ($state.current.name.startsWith("app.monitoring")) {
        if ($state.current.name === "app.stocks") $state.go($scope.tab_monit[0].route);
        else $state.go($state.current.name);
      }
    };
    /*  if ($scope.homeMenu && $scope.homeMenu.length > 0) {
              if ($state.current.name === 'app.home')
                  $state.go($scope.homeMenu[0].route);
              else $state.go($state.current.name);
          }
          if ($scope.adminMenu && $scope.adminMenu.length > 0) {
              if ($state.current.name === 'app.admin')
                  $state.go($scope.adminMenu[0].route);
              else $state.go($state.current.name);
          } */

    $scope.loadMenu();

    $scope.pin_bar = function () {
      $scope.pin_sb = !$scope.pin_sb;
    };
    $scope.godash = function () {
      $state.go($scope.dashMenu[0].route);
    };
    $scope.gohome = function () {
      $state.go($scope.homeMenu[0].route);
    };

    $scope.gomonit = function () {
      $state.go($scope.monitMenu[0].route);
    };
    $scope.gosub = function () {
      $state.go($scope.subscMenu[0].route);
    };
    $scope.gostock = function () {
   //   console.log($scope.stocksMenu[0].route);
      $state.go($scope.stocksMenu[0].route);
    };

    $scope.goadmin = function () {
      $state.go($scope.adminMenu[0].route);
    };

    $scope.isActive = function (state) {
      return $state.current.name.startsWith(state);
    };

    $scope.logout = function () {
      i3HelperSrv.logout(function () {
        $rootScope.appData = {};
        $rootScope.maplayers = {};
        $state.go("login", {});
      });
    };

    $scope.profileEdit = function () {
      ngDialog.open({
        template: "app/commons/dialogs/profile/profile.dlg.tpl.html",
        controller: "profileDlgCtrl",
        className: "ngdialog-theme-default custom-width",
      });
    };
    $scope.showAbout = function () {

      ngDialog.open({
        template: "app/commons/dialogs/about/about.dlg.tpl.html",
        controller: "abDlgCtrl",
        className: 'ngdialog-theme-default custom-width-800',
        appendClassName: 'ngdialog-custom',

      });

    }
  });