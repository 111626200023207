angular.module("app").controller("deliveryRequestInfosCtrl", function ($scope, $rootScope, $stateParams, $filter, $state, $translate, ngDialog, messageBox, Notification, I3DeliveryRequestSrv, reportingSrv, iaxeedAssetSrv, plateformHelperSrv, I3deliverySlipSrv, i3HelperSrv, constantSrv) {
    $scope.deliveryRequest = {};
    $scope.asetid = {}
    $scope.asset = {}
    $scope.template = '';
    $scope.template2 = '';
    $scope.prd = [];
    $scope.pvref = '';
    $scope.bcref = '';
    $scope.liststatusavailDelivery = [];
    var now = moment().toDate();
    $scope.timeleft = 0;
    $scope.now = moment()
    $scope.redv = 0;

    $scope.getvalue = function (deadline, deadline_period) {
        if (deadline && deadline_period) {
            var deadline_start = moment(deadline).subtract(deadline_period, 'h');
            console.log(deadline_start)
            var date2 = moment(deadline);
            var date1 = moment();
            $scope.date_limite = date2;
            var differenceInMs = date2.diff(date1); // diff yields milliseconds
            var duration = moment.duration(differenceInMs);
            var differenceInHours = Math.floor(duration.asHours());

            if (-differenceInHours >= 1000) {
                $scope.timeleft = 1000
            } else
                $scope.timeleft = -differenceInHours;
            var redv = Math.floor(100 - ((differenceInHours / deadline_period) * 100))
            if (redv >= 100) redv = 100
            console.log(redv)
            return redv
        }
    }

    //$scope.statusList = ["NEW", "READY_TO_DELIVER", "DELIVRE", "STOCK_UNAVAILABLE", "CANCELED"];
    constantSrv.getModel("DELIVERY_REQUEST").getAllStatus(function (err, stat) {

        $scope.statusList = stat.map(function (val) {
            return val.name
        })
    })

    i3HelperSrv.getEnums(function (enums) {
        if (enums && enums.code_products) {

            $scope.codeProducts = enums.code_products;
        } else {
            console.error("Wrong config server enums ")
        }

    }, function (er) {
        console.error('err', er)
    })
    I3DeliveryRequestSrv.query({
        query: {
            _id: $stateParams.deliveryRequestId
        },
        options: {
            populate: [{
                    path: "_plateforme",
                }, {
                    path: "created_by",
                    select: 'first_name last_name'
                }, {
                    path: "products._product",
                    select: 'name'
                }, {
                    path: "installation_id",
                    model: "installation_group"
                }, {
                    path: "intervention_id",
                    model: "intervention"
                },
                {
                    path: 'status_histo._modified_by',
                    select: "first_name  last_name login"
                }
            ]
        }
    }, 1, 0, function (res) {
        if (res && res.success && res.result.length > 0) {
            $scope.deliveryRequest = res.result[0];
            $scope.redv = $scope.getvalue($scope.deliveryRequest.dead_line, $scope.deliveryRequest.dead_line_period);
            constantSrv.getModel("DELIVERY_REQUEST").getAvailableStatusByStatus($scope.deliveryRequest.status, function (err, val) {

                try {
                    $scope.liststatusavailDelivery = val._availStatus.map(function (elem) {
                        return elem.name
                    })
                } catch (e) {
                    console.error(e);
                }
            })
            console.log("$scope.deliveryRequest888", $scope.deliveryRequest)
            $scope.getdeliveryslip();
            if ($scope.deliveryRequest.intervention_id) {
                if($translate.use()=="fr"){
                    $scope.template = 'pv_interv.mrt';
                    $scope.template2 = 'deliveryreq.mrt';
                   }else{
                    $scope.template = 'pv_interv-en.mrt';
                    $scope.template2 = 'deliveryreq-en.mrt';
                   }
               /*  $scope.template = 'pv_interv.mrt';
                $scope.template2 = 'deliveryreq.mrt'; */
                $scope.asetid = $scope.deliveryRequest.intervention_id.asset.filter(function (aset) {
                    return aset._id == $scope.deliveryRequest.asset_id
                })[0].asset_id
                console.log("9", $scope.asetid)
                //$scope.pvNumber = $scope.deliveryRequest.intervention_id.
                $scope.getasset($scope.asetid, $scope.deliveryRequest._plateforme)
            }

            if ($scope.deliveryRequest.installation_id) {
                if($translate.use()=="fr"){
                    $scope.template = 'pv_install.mrt';
                    $scope.template2 = 'deliveryreq.mrt';
                   }else{
                    $scope.template = 'pv_install-en.mrt';
                    $scope.template2 = 'deliveryreq-en.mrt';
                   }
              /*   $scope.template = 'pv_install.mrt';
                $scope.template2 = 'deliveryreq.mrt'; */
                $scope.asetid = $scope.deliveryRequest.installation_id.assets.filter(function (aset) {
                    return aset._id == $scope.deliveryRequest.asset_id
                })[0]
                $scope.asset = $scope.asetid
                console.log($scope.asset)
            }

        } else
            Notification.error($translate.instant("FIND_BY_ID_ERROR"));
    }, function (err) {
        Notification.error($translate.instant("FIND_BY_ID_ERROR"))
    })

    $scope.isavailable = function (status) {
        //   console.log($scope.liststatusavailDelivery)
        if ($scope.liststatusavailDelivery.indexOf(status) != -1 && $scope.deliveryRequest.status != status) {
            return true
        } else {
            return false
        }
    };

    $scope.getasset = function (assetId, plateforme) {
        console.log("$scope.getasset -> assetId", assetId)
        plateformHelperSrv.setPlateFormAndLogin(plateforme, function (res) {
                if (res.success) {
                    $scope.platformeLogin = res;
                    var options = {};
                    if ($scope.platformeLogin && $scope.platformeLogin.result && $scope.platformeLogin.result.token) {

                        var query = {
                            _id: assetId
                        };
                    }
                    iaxeedAssetSrv.query({
                        query: query,
                        options: options
                    }, 1, 1, function (res1) {
                        if (res1.result && res1.result.length > 0) {

                            $scope.asset = res1.result[0];
                            console.log($scope.asset)
                        }
                    }, function (err) {
                        console.log(err);
                    });
                } else {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("PLATFORM_CNX_ERROR")
                    });
                }
            },
            function (err) {
                console.log(err)
            });
    }
    $scope.changeDeliveryRequestStatus = function (deliveryRequest, status) {

        if (status == "DELIVRE") {
            I3deliverySlipSrv.query({
                query: {
                    delivery_request_id: deliveryRequest._id
                }

            }, 1, 0, function (resp) {

                if (resp && resp.success && resp.result.length > 0) {
                    $scope.deliverySlip = resp.result[0]
                    messageBox.confirm("UPDATE", "UPDATE_DELIVERY_REQUEST").then(function (res) {
                        if (res) {
                            $scope.deliveryRequest.status = status

                            I3DeliveryRequestSrv.updateById($scope.deliveryRequest._id, $scope.deliveryRequest, function (response) {
                                if (response.success) {
                                    $scope.deliverySlip.confirmed = true
                                    I3deliverySlipSrv.updateById($scope.deliverySlip._id, $scope.deliverySlip, function (respo) {
                                        if (respo.success) {

                                        } else {
                                            Notification.error({
                                                title: $translate.instant('ERROR'),
                                                message: $translate.instant("ERROR_OCCURED_UPDATE")
                                            });
                                        }
                                    })

                                    constantSrv.getModel("DELIVERY_REQUEST").getAvailableStatusByStatus($scope.deliveryRequest.status, function (err, val) {

                                        try {
                                            $scope.liststatusavailDelivery = val._availStatus.map(function (elem) {
                                                return elem.name
                                            })
                                        } catch (e) {
                                            console.error(e);
                                        }
                                    })
                                    Notification.success({
                                        title: $translate.instant("DELIVERY_REQUEST_UPDATED_TITLE"),
                                        message: $translate.instant("DELIVERY_REQUEST_UPDATED_SUC")
                                    });
                                } else {
                                    Notification.error({
                                        title: $translate.instant('ERROR'),
                                        message: $translate.instant("ERROR_OCCURED_UPDATE")
                                    });
                                }
                            }, function (err) {
                                var msg = "";
                                if (err.error.errorString) {
                                    msg = err.error.errorString;
                                }
                                Notification.error({
                                    title: $translate.instant('ERROR'),
                                    message: msg
                                });
                            })
                        }
                    }, function (reject) {})

                } else {
                    Notification.error({
                        title: $translate.instant('DELIVERY_SLIP_DONT_EXIST_TITLE'),
                        message: $translate.instant("DELIVERY_SLIP_DONT_EXIST")
                    });
                    // Notification.error($translate.instant("DELIVERY_SLIP_DONT_EXIST"));
                }

            }, function (err) {
                Notification.error($translate.instant("FIND_BY_ID_ERROR"))
            })

        } else {
            messageBox.confirm("UPDATE", "UPDATE_DELIVERY_REQUEST").then(function (res) {
                if (res) {
                    $scope.deliveryRequest.status = status

                    I3DeliveryRequestSrv.updateById($scope.deliveryRequest._id, $scope.deliveryRequest, function (response) {
                        if (response.success) {
                            constantSrv.getModel("DELIVERY_REQUEST").getAvailableStatusByStatus($scope.deliveryRequest.status, function (err, val) {

                                try {
                                    $scope.liststatusavailDelivery = val._availStatus.map(function (elem) {
                                        return elem.name
                                    })
                                } catch (e) {
                                    console.error(e);
                                }
                            })
                            Notification.success({
                                title: $translate.instant("DELIVERY_REQUEST_UPDATED_TITLE"),
                                message: $translate.instant("DELIVERY_REQUEST_UPDATED_SUC")
                            });
                        } else {
                            Notification.error({
                                title: $translate.instant('ERROR'),
                                message: $translate.instant("ERROR_OCCURED_UPDATE")
                            });
                        }
                    }, function (err) {
                        var msg = "";
                        if (err.error.errorString) {
                            msg = err.error.errorString;
                        }
                        Notification.error({
                            title: $translate.instant('ERROR'),
                            message: msg
                        });
                    })
                }
            }, function (reject) {})

        }

    };

    $scope.getdeliveryslip = function () {
        I3deliverySlipSrv.query({
            query: {
                delivery_request_id: $scope.deliveryRequest._id
            },
            options: {
                populate: "products._product products._article"
            }
        }, 1, 0, function (res) {
            if (res && res.success && res.result.length > 0) {
                console.log(res.result[0]);
                $scope.valideDeliverySlip = res.result[0]
                $scope.bcref = res.result[0].reference
                $scope.pvref = res.result[0].pv_ref
                $scope.prd = res.result[0].products;
                //$scope.statusList = ["NEW", "READY_TO_DELIVER", "DELIVRE", "STOCK_UNAVAILABLE"];
                console.log("getDeliveryOrderByPVId resolved", $scope.pvref)
                console.log("getDeliveryOrderByPVId resolved", $scope.prd)
            } else {
                console.log("error")
            }
        }, function (err) {
            console.log(err)
        })

    }

    $scope.exportPV = function (fileType) {
        var info = {}
        if ($scope.deliveryRequest.installation_id) {
            info = {
                npv: ($scope.pvref ? $scope.pvref : ""),
                nbc: ($scope.deliveryRequest && $scope.deliveryRequest.installation_id && $scope.deliveryRequest.installation_id.purchase_order ? $scope.deliveryRequest.installation_id.purchase_order : ""),

                client: ($scope.deliveryRequest && $scope.deliveryRequest.client ? $scope.deliveryRequest.client : ""),
                contact: ($scope.deliveryRequest && $scope.deliveryRequest.installation_id && $scope.deliveryRequest.installation_id.contact && $scope.deliveryRequest.installation_id.contact.name ? $scope.deliveryRequest.installation_id.contact.name : ""),
                tel: ($scope.deliveryRequest && $scope.deliveryRequest.installation_id && $scope.deliveryRequest.installation_id.contact && $scope.deliveryRequest.installation_id.contact.phone ? $scope.deliveryRequest.installation_id.contact.phone : ""),

                date: now,
                adress: ($scope.deliveryRequest && $scope.deliveryRequest.adress ? $scope.deliveryRequest.adress : ""),
                asset_type: ($scope.asset && $scope.asset.type ? $translate.instant($scope.asset.type) : ""),
                asset_model: ($scope.asset && $scope.asset.model ? $scope.asset.model : ""),
                asset_brand: ($scope.asset && $scope.asset.brand ? $scope.asset.brand : ""),
                asset_name: ($scope.asset && $scope.asset.name ? $scope.asset.name : ""),
                data: true
            }
        } else {
            info = {
                npv: ($scope.pvref ? $scope.pvref : ""),
                nbc: ($scope.deliveryRequest && $scope.deliveryRequest.intervention_id && $scope.deliveryRequest.intervention_id.purchase_order ? $scope.deliveryRequest.intervention_id.purchase_order : ""),

                client: ($scope.deliveryRequest && $scope.deliveryRequest.client ? $scope.deliveryRequest.client : ""),
                contact: ($scope.deliveryRequest && $scope.deliveryRequest.intervention_id && $scope.deliveryRequest.intervention_id.contact && $scope.deliveryRequest.intervention_id.contact.name ? $scope.deliveryRequest.intervention_id.contact.name : ""),
                tel: ($scope.deliveryRequest && $scope.deliveryRequest.intervention_id && $scope.deliveryRequest.intervention_id.contact && $scope.deliveryRequest.intervention_id.contact.phone ? $scope.deliveryRequest.intervention_id.contact.phone : ""),

                date: now,
                adress: ($scope.deliveryRequest && $scope.deliveryRequest.adress ? $scope.deliveryRequest.adress : ""),
                asset_type: ($scope.asset && $scope.asset.type ? $translate.instant($scope.asset.type) : ""),
                asset_model: ($scope.asset && $scope.asset.model ? $scope.asset.model : ""),
                asset_brand: ($scope.asset && $scope.asset.brand ? $scope.asset.brand : ""),
                asset_name: ($scope.asset && $scope.asset.name ? $scope.asset.name : ""),
                data: true
            }

        }
        var data = {};
        var reverseEnumsProductCode = {};
        Object.keys($scope.codeProducts).forEach(function (key) {
            data[key] = 0;
            reverseEnumsProductCode[$scope.codeProducts[key]] = key
        })

        $scope.prd.forEach(function (product) {
            if (product._product && product._product.code && product._product.code.product_type && product.qty) {

                if (data[reverseEnumsProductCode[product._product.code.product_type]] != undefined) {
                    data[reverseEnumsProductCode[product._product.code.product_type]] = product.qty
                }
            }
        })

        var ds = {
            name: "pvSingleInstalll",
            data: {
                info: info,
                data: data,
                fileName: "PV_install " + ($scope.asset.name || '') + " " + moment().format("YYY-MM-DD")
            }
        }
        console.log(ds)
        if (fileType == "XLSX") {
            reportingSrv.generateExcel($scope.template, ds, function () {}, function () {
                Notification.error("L'export a échoué ");
            })
        } else if (fileType == "DOCX") {
            reportingSrv.generateWord($scope.template, ds, function () {}, function () {
                Notification.error("L'export a échoué ");
            })
        } else if (fileType == "preview") {
            //console.log("preview")
            reportingSrv.preview($scope.template, ds, function () {}, function () {
                Notification.error("La prévisualisation  a échoué ");
            })
        } else {
            reportingSrv.generatePdf($scope.template, ds, function () {}, function () {
                Notification.error("L'export a échoué ");
            })
        }
    }

    $scope.exportBC = function (fileType) {
        var info = {}
        if ($scope.deliveryRequest.installation_id) {
            info = {
                npv: ($scope.bcref ? $scope.bcref : ""),
                nbc: ($scope.deliveryRequest && $scope.deliveryRequest.installation_id && $scope.deliveryRequest.installation_id.purchase_order ? $scope.deliveryRequest.installation_id.purchase_order : ""),

                client: ($scope.deliveryRequest && $scope.deliveryRequest.client ? $scope.deliveryRequest.client : ""),
                contact: ($scope.deliveryRequest && $scope.deliveryRequest.installation_id && $scope.deliveryRequest.installation_id.contact && $scope.deliveryRequest.installation_id.contact.name ? $scope.deliveryRequest.installation_id.contact.name : ""),
                tel: ($scope.deliveryRequest && $scope.deliveryRequest.installation_id && $scope.deliveryRequest.installation_id.contact && $scope.deliveryRequest.installation_id.contact.phone ? $scope.deliveryRequest.installation_id.contact.phone : ""),

                date: now,
                asset_type: ($scope.asset && $scope.asset.type ? $translate.instant($scope.asset.type) : ""),
                asset_model: ($scope.asset && $scope.asset.model ? $scope.asset.model : ""),
                asset_brand: ($scope.asset && $scope.asset.brand ? $scope.asset.brand : ""),
                asset_name: ($scope.asset && $scope.asset.name ? $scope.asset.name : ""),
                data: true
            }
        } else {
            info = {
                npv: ($scope.bcref ? $scope.bcref : ""),
                nbc: ($scope.deliveryRequest && $scope.deliveryRequest.intervention_id && $scope.deliveryRequest.intervention_id.purchase_order ? $scope.deliveryRequest.intervention_id.purchase_order : ""),

                client: ($scope.deliveryRequest && $scope.deliveryRequest.client ? $scope.deliveryRequest.client : ""),
                contact: ($scope.deliveryRequest && $scope.deliveryRequest.intervention_id && $scope.deliveryRequest.intervention_id.contact && $scope.deliveryRequest.intervention_id.contact.name ? $scope.deliveryRequest.intervention_id.contact.name : ""),
                tel: ($scope.deliveryRequest && $scope.deliveryRequest.intervention_id && $scope.deliveryRequest.intervention_id.contact && $scope.deliveryRequest.intervention_id.contact.phone ? $scope.deliveryRequest.intervention_id.contact.phone : ""),

                date: now,
                asset_type: ($scope.asset && $scope.asset.type ? $translate.instant($scope.asset.type) : ""),
                asset_model: ($scope.asset && $scope.asset.model ? $scope.asset.model : ""),
                asset_brand: ($scope.asset && $scope.asset.brand ? $scope.asset.brand : ""),
                asset_name: ($scope.asset && $scope.asset.name ? $scope.asset.name : ""),
                data: true
            }

        }
        var data = [];
        /* var reverseEnumsProductCode = {};
        Object.keys($scope.codeProducts).forEach(function (key) {
            data[key] = 0;
            reverseEnumsProductCode[$scope.codeProducts[key]] = key
        }) */

        $scope.prd.forEach(function (product) {
            if (product._product && product._product.code && product._product.code.product_type && product.qty) {

                data.push({
                    "name": product._product && product._product.name ? $translate.instant(product._product.name) : '',
                    "qte": product.qty,
                    "IME": product && product._article && product._article.imei ? product._article.imei : '',
                    "SN": product && product._article && product._article.serialNumber ? product._article.serialNumber : '',
                });
                /* if (data[reverseEnumsProductCode[product._product.code.product_type]] != undefined) {
                    data[reverseEnumsProductCode[product._product.code.product_type]] = product.qty
                } */
            }
        })

        var ds = {
            name: "pvSingleInstalll",
            data: {
                info: info,
                data: data,
                fileName: "PV_BL " + ($scope.asset.name) + " " + moment().format("YYY-MM-DD")
            }
        }
        console.log(ds)
        if (fileType == "XLSX") {
            reportingSrv.generateExcel($scope.template2, ds, function () {}, function () {
                Notification.error("L'export a échoué ");
            })
        } else if (fileType == "DOCX") {
            reportingSrv.generateWord($scope.template2, ds, function () {}, function () {
                Notification.error("L'export a échoué ");
            })
        } else if (fileType == "preview") {
            //console.log("preview")
            reportingSrv.preview($scope.template2, ds, function () {}, function () {
                Notification.error("La prévisualisation  a échoué ");
            })
        } else {
            reportingSrv.generatePdf($scope.template2, ds, function () {}, function () {
                Notification.error("L'export a échoué ");
            })
        }
    }
})