angular.module('app').controller('installationDetailsAdminCtrl', function ($rootScope, $scope, I3PlateformeSrv, I3UserSrv, plateformHelperSrv, iaxeedCompanySrv, I3ProductSrv, ngDialog, Notification, $state, $stateParams, messageBox, I3InstallationSrv, constantSrv, $translate, iaxeedAssetSrv, I3ActionLogSrv, ObjectDiff, iaxeedSocketSrv, i3HelperSrv) {

    function filter(obj1, obj2) {
        var result = {};
        for (key in obj1) {
            if (obj2[key] !== obj1[key]) result[key] = obj2[key];
            if (typeof obj2[key] === 'array' && typeof obj1[key] === 'array')
                result[key] = arguments.callee(obj1[key], obj2[key]);
            if (typeof obj2[key] === 'object' && typeof obj1[key] === 'object')
                result[key] = arguments.callee(obj1[key], obj2[key]);
        }
        return result;
    }
    $scope.Object = Object;
    $scope.statusList = constantSrv.getInstallationStatus();
    if ($stateParams.id && $stateParams.id.length > 0) {

        I3InstallationSrv.query({
            query: {
                _id: $stateParams.id
            },
            options: {
                populate: [{
                    path: "_created_by"
                }, {
                    path: "docs"
                }, {
                    path: "comments.owner",
                    model: "user"
                }, {
                    path: "_assigned_to"
                }, {
                    path: "_product"
                }, {
                    path: 'followers.user'
                }, {
                    path: "_installation_group",
                    populate: {
                        path: "_plateforme",
                        model: "plateforme"
                    }
                }]
            }
        }, 1, 0, function (res) {

            if (res && res.success && res.result.length > 0) {
                $scope.installation = res.result[0];

                $scope.plateforme = $scope.installation._installation_group._plateforme;
                I3ActionLogSrv.query({
                    query: {
                        "_model": "INSTALLATION",
                        "_object._id": "#OBJID#" + $scope.installation._id + "#OBJIDEND#"
                    },
                    options: {
                        populate: "_user",
                        sortBy: {
                            creation_dt: -1
                        }
                    }
                }, 10, 0, function (res) {
                    if (res && res.result) {
                        var action_logs = [];
                        res.result.forEach(function (val, index) {

                            if (val._action === "UPDATE") {

                                if (index < res.result.length - 1) {

                                    var changes = ObjectDiff.diff(res.result[index + 1]._object, val._object);

                                    var objectChanges = {
                                        others: []
                                    };
                                    if (changes.changed === "object change") {

                                        if (changes.value && Object.keys(changes.value).length > 0) {
                                            Object.keys(changes.value).forEach(function (key) {
                                                if (key === "status" && changes.value[key].changed === "primitive change") {
                                                    objectChanges.STATUS = {
                                                        oldVal: changes.value[key].removed,
                                                        newVal: changes.value[key].added
                                                    }
                                                } else if (key === "begin_dt" && changes.value[key].changed === "primitive change") {
                                                    objectChanges.START_DATE = {
                                                        oldVal: changes.value[key].removed,
                                                        newVal: changes.value[key].added
                                                    }
                                                } else if (key === "end_dt" && changes.value[key].changed === "primitive change") {
                                                    objectChanges.END_DATE = {
                                                        oldVal: changes.value[key].removed,
                                                        newVal: changes.value[key].added
                                                    }
                                                } else if (key === "asset_name" && changes.value[key].changed === "primitive change") {
                                                    objectChanges.ASSET = {
                                                        oldVal: changes.value[key].removed,
                                                        newVal: changes.value[key].added
                                                    }
                                                } else if (key !== 'modif_dt' && changes.value[key].changed === "primitive change" || changes.value[key].changed === "object change") {
                                                    objectChanges.others.push(key);
                                                }
                                            });
                                            if (Object.keys(objectChanges).length > 1 || objectChanges.others.length > 0) {
                                                val.changes = objectChanges;
                                            }

                                        }
                                    }

                                }
                                if (val.changes)
                                    action_logs.push(val);
                            } else {
                                action_logs.push(val);
                            }

                        });
                        $scope.actionLogs = action_logs;
                        if ($scope.plateforme.type == 'NGI_FLEET') {
                            return;
                        }
                        plateformHelperSrv.setPlateFormAndLogin($scope.plateforme, function (res) {

                            if (res.success) {
                                $scope.plateformeToken = res.result.token;

                                $scope.asset = {};

                                var query = {
                                    _id: $scope.installation.asset
                                };
                                var options = {};

                                iaxeedAssetSrv.query({
                                    query: query,
                                    options: options
                                }, 10000, 1, function (response) {

                                    if (response.success) {
                                        $scope.asset = response.result[0];
                                    }
                                }, function (err) {
                                    console.log(err);
                                })

                            }
                        });
                    }
                }, function (err) {

                })
            } else {
                $state.go("app.home.installRequest");
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("FIND_BY_ID_ERROR")
                });
            }

        }, function (err) {
            Notification.error({
                title: $translate.instant("ERROR"),
                message: $translate.instant("FIND_BY_ID_ERROR")
            });
        })
    } else {

        $state.go("app.home.installRequest");
    }

    /**
     * Following the current installation
     */
    $scope.follow = function () {
        if ($scope.emailFollow && $scope.emailFollow !== "") {

            var follower = {};
            if (!$scope.installation.followers) {
                $scope.installation.followers = [];
            }

            follower.email = $scope.emailFollow;

            var query = {
                "contact.mail": $scope.emailFollow
            };
            var options = {};

            var exist = false;

            angular.forEach($scope.installation.followers, function (follower) {
                if (follower.email.toLowerCase() === $scope.emailFollow.toLowerCase()) {
                    exist = true;
                }
            });

            if (!exist) {
                I3UserSrv.query({
                    query: query,
                    options: options
                }, 10000, 0, function (res) {
                    if (res.success) {
                        follower.user = res.result[0];
                    }

                    $scope.installation.followers.push(follower);

                    delete $scope.installation.__v;

                    I3InstallationSrv.updateById($scope.installation._id, $scope.installation, function (response) {
                        if (response.success) {
                            Notification.success({
                                title: "Following claim",
                                message: "The email address" + $scope.emailFollow + " is now following installation " + $scope.installation._installation_group._plateforme.name
                            });

                            $scope.emailFollow = "";
                        } else {
                            Notification.error({
                                title: $translate.instant("ERROR"),
                                message: $translate.instant("ERROR_OCCURED_UPDATE") + " " + $scope.installation._installation_group._plateforme.name + "!"
                            });
                            $scope.installation = {};
                        }
                    }, function (err) {
                        var msg = "";
                        if (err.error.errorString) {
                            msg = err.error.errorString;
                        }
                        Notification.error({
                            title: $translate.instant("ERROR"),
                            message: msg
                        });
                    })
                });
            } else {
                Notification.warning({
                    title: "Already follower",
                    message: "This email is already subscribed as a follower here"
                });
                $scope.emailFollow = "";
            }

        }

    };

    /**
     * Remove follower
     */
    $scope.removeFollower = function (follower) {
        if ($scope.installation.followers.indexOf(follower) >= 0) {
            $scope.installation.followers.splice($scope.installation.followers.indexOf(follower), 1);
            delete $scope.installation.__v;

            I3InstallationSrv.updateById($scope.installation._id, $scope.installation, function (response) {
                if (response.success) {
                    Notification.success({
                        title: "Removing installation follower",
                        message: "The follower of installation " + $scope.installation._installation_group._plateforme.name + " was removed successfully"
                    });
                } else {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("ERROR_OCCURED_UPDATE") + " " + $scope.installation._installation_group._plateforme.name + "!"
                    });
                    $scope.installation = {};
                }
            }, function (err) {
                var msg = "";
                if (err.error.errorString) {
                    msg = err.error.errorString;
                }
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: msg
                });
            })
        }
    };

    $scope.loadAssets = function () {
        iaxeedAssetSrv.query({
            query: {
                "_company_owner": $scope.installationRequest.client_id
            }
        }, 1000, 0, function (res) {

            $scope.assets = res.result;
        }, function (err) {
            console.error(err);
        })
    };

    $scope.updateInstallationProp = function (prop, installation, $data) {
        var updateData = {};
        if (prop === "status") {
            if ($data === "Planned" && !updateData.planned_begin_dt) {
                updateData.planned_begin_dt = new Date();
                installation.planned_begin_dt = new Date();
            } else if ($data === "InProgress" && !updateData.begin_dt) {
                updateData.begin_dt = new Date();
                installation.begin_dt = new Date();
            } else if ($data === "Done" && !updateData.end_dt) {
                updateData.end_dt = new Date();
                installation.end_dt = new Date();
            }

        }
        if (prop === "planned_begin_dt" && installation.status === "New") {
            updateData.status = "Planned";
            installation.status = "Planned"
        }
        if (prop === "begin_dt" && installation.status !== "InProgress" && installation.status !== "Done") {
            updateData.status = "InProgress";
            installation.status = "InProgress"
        }
        if (prop === "end_dt" && installation.status !== "Done") {
            updateData.status = "Done";
            installation.status = "Done";
        }
        updateData[prop] = $data;
        if (prop === "asset") {
            updateData = {
                asset: $data._id,
                asset_name: $data.name
            };
            installation.asset_name = $data.name;

        }
        I3InstallationSrv.updateById(installation._id, updateData, function (res) {

        }, function (err) {

        })
    };

    $scope.removeComment = function (index) {
        messageBox.confirm("DELETE_COMMENTS_CONFIRM_TITLE", "DELETE_COMMENTS_CONFIRM_MESSSAGE").then(function (res) {
            if (res) {
                $scope.installation.comments.splice(index, 1);
                I3InstallationSrv.updateById($scope.installation._id, {
                    "comments": $scope.installation.comments
                }, function (res) {
                    if (res && res.success) {
                        Notification.success($translate.instant("COMMENT_DELETED"));
                        var comment = {
                            msg: msg,
                            date: new Date(),
                            owner: $rootScope.SESSION.user
                        };
                        $scope.installation.comments.push(comment);

                    } else {
                        Notification.error({
                            title: $translate.instant("ERROR"),
                            message: $translate.instant("COMMENT_DELETE_FAIL")
                        });
                    }

                }, function (err) {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("COMMENT_DELETE_FAIL")
                    });
                })
            }

        })
    };
    $scope.addComment = function (msg) {

        if (msg && msg.length > 0) {

            var comment = {
                msg: msg,
                date: new Date(),
                owner: $rootScope.SESSION.user._id
            };

            if (!$scope.installation.comments) $scope.installation.comments = [];
            var installationCmt = angular.copy($scope.installation.comments);
            installationCmt.push(comment);

            I3InstallationSrv.updateById($scope.installation._id, {
                "comments": installationCmt
            }, function (res) {
                if (res && res.success) {
                    Notification.success($translate.instant("COMMENT_ADDED"));
                    var comment = {
                        msg: msg,
                        date: new Date(),
                        owner: $rootScope.SESSION.user
                    };
                    $scope.installation.comments.push(comment);
                    $scope.comment = null;

                } else {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("COMMENT_ADD_FAIL")
                    });
                }

            }, function (err) {
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("COMMENT_ADD_FAIL")
                });
            })
        }
    };

    $scope.opened = {};

    $scope.open = function ($event, elementOpened) {
        $event.preventDefault();
        $event.stopPropagation();

        $scope.opened[elementOpened] = !$scope.opened[elementOpened];
    };

    $scope.deviceInfo = function () {
        var dialog = ngDialog.open({
            controller: "realtimeDlgCtrl",
            templateUrl: "app/commons/dialogs/realtime/realtime.dlg.tpl.html",
            scope: $scope,
            className: 'ngdialog-theme-default custom-width'
        });

        dialog.closePromise.then(function () {
            iaxeedSocketSrv.closeListner('newMsg');
        });
    };

    $scope.uploadFiles = function (files, errFiles) {
        $scope.files = files;
        $scope.errFiles = errFiles;
        i3HelperSrv.uploadFiles(files, {related_model:[{model:"INSTALLATION",model_id:$scope.installation._id}]}, function (res) {
            angular.forEach(res.result, function (file) {
                $scope.installation.docs.push(file)
            });
            I3InstallationSrv.updateById($stateParams.id, {
                docs: $scope.installation.docs
            }, function (res) {
                if (res && res.success) {
                    Notification.success($translate.instant("INSTALLATION_UPDATED"));
                } else {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("INSTALLATION_UPDATED_ERROR")
                    });
                }
            }, function (err) {

            })
            file.result = res.data;
        }, function (response) {
            if (response.status)
                $scope.errorMsg = response.status + ': ' + response.data;
        }, function (evt) {

        });
    };

    $scope.downloadFile = function (file) {
        i3HelperSrv.downloadFile(file._id, file.original_name, function success(res) {

        }, function error(err) {
            Notification.error({
                title: $translate.instant("ERROR"),
                message: $translate.instant(err)
            });
        }, function progress(evt) {
            //console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '%');
        })
    };

});