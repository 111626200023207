angular.module('app').controller('installDashboardCtrl', function ($scope, $rootScope, I3InstallationSrv, Notification) {

    $scope.selectedPeriod = 'month';
    var now = moment().clone();

    $scope.startRes = moment().startOf("day").clone();
    $scope.endRes = now;
    $scope.dayRes = now;
    $scope.monthRes = now;
    $scope.pickerStart = {
        format: "DD/MM/YYYY",
        init: moment().startOf("day").clone(),
        position: "top left",
        autoTrans: false,
        enableTime: false,
        enableDate: true,
        enableMinutes: false,
        defaultView: "DATE",
        getDate: function (date) {
            $scope.startRes = date;
            $scope.pickerEnd.minDate = date;
            getInstallationsData();
        }
    };

    $scope.pickerEnd = {
        format: "DD/MM/YYYY",
        position: "top left",
        init: now,
        autoTrans: false,
        enableTime: false,
        enableDate: true,
        enableMinutes: false,
        defaultView: "DATE",
        getDate: function (date) {
            $scope.endRes = date;
            $scope.pickerStart.maxDate = date;
            getInstallationsData();
        }
    };

    $scope.pickerDay = {
        format: "DD/MM/YYYY",
        position: "top left",
        init: now,
        autoTrans: false,
        enableTime: false,
        enableDate: true,
        enableMinutes: false,
        defaultView: "DATE",
        getDate: function (date) {
            $scope.dayRes = date;
            getInstallationsData();
        }
    };

    $scope.pickerMonth = {
        name: "pickerMonth",
        format: "MM/YYYY",
        position: "top left",
        init: now,
        autoTrans: false,
        enableTime: false,
        enableDate: true,
        enableMinutes: false,
        defaultView: "MONTH",
        getDate: function (date) {
            $scope.monthRes = date;
            getInstallationsData();
        }
    };

    $scope.openCalendar = function (e, picker) {
        $scope[picker].open = true;
    };

    $scope.selectPeriod = function (period) {
        $scope.selectedPeriod = period;
        if ($scope.selectedPeriod === "month") {
            getInstallationsData();
        } else if ($scope.selectedPeriod === "day") {
            getInstallationsData();
        } else if ($scope.selectedPeriod === "week") {
            getInstallationsData();
        } else if ($scope.selectedPeriod === "free") {
            getInstallationsData();
        }
    };

    var getQuery = function () {

        var match = {};
        var agg = getDailyQuery();

        if ($scope.selectedPeriod === 'day') {
            match = {

                "creation_dt": {
                    "$gt": '#ISODATE#' + $scope.dayRes.clone().startOf('day').format('YYYY-MM-DD') + "T00:00:00.000Z" + '#ISODATEEND#',
                    "$lt": '#ISODATE#' + $scope.dayRes.clone().add('1', 'days').format('YYYY-MM-DD') + "T00:00:00.000Z" + '#ISODATEEND#'
                }

            }

        } else if ($scope.selectedPeriod === 'week') {
            match = {

                "creation_dt": {
                    "$gt": '#ISODATE#' + $scope.dayRes.clone().subtract(7, 'd').format('YYYY-MM-DD') + "T00:00:00.000Z" + '#ISODATEEND#',
                    "$lt": '#ISODATE#' + $scope.dayRes.clone().format('YYYY-MM-DD') + "T00:00:00.000Z" + '#ISODATEEND#'
                }

            };
        } else if ($scope.selectedPeriod === 'month') {
            match = {

                "creation_dt": {
                    "$gt": '#ISODATE#' + $scope.monthRes.clone().startOf('month').format('YYYY-MM-DD') + "T00:00:00.000Z" + '#ISODATEEND#',
                    "$lt": '#ISODATE#' + $scope.monthRes.clone().endOf('month').format('YYYY-MM-DD') + "T00:00:00.000Z" + '#ISODATEEND#'
                }

            };
        } else if ($scope.selectedPeriod === 'free') {
            match = {

                "creation_dt": {
                    "$gt": '#ISODATE#' + $scope.startRes.clone().format('YYYY-MM-DD') + "T00:00:00.000Z" + '#ISODATEEND#',
                    "$lt": '#ISODATE#' + $scope.endRes.clone().add('1', 'days').format('YYYY-MM-DD') + "T00:00:00.000Z" + '#ISODATEEND#'
                }

            };
        } else {
            match = {

                "creation_dt": {
                    "$gt": '#ISODATE#' + $scope.startRes.clone().format('YYYY-MM-DD') + "T00:00:00.000Z" + '#ISODATEEND#',
                    "$lt": '#ISODATE#' + $scope.endRes.clone().add('1', 'days').format('YYYY-MM-DD') + "T00:00:00.000Z" + '#ISODATEEND#'
                }

            };
        }

        var query = [{
                "$match": match
            },
            {
                "$project": {
                    "status": "$status",
                    "_installation_group": "$_installation_group",
                    'date': agg.project
                }
            },
            {
                $lookup: {
                    from: "installation_group",
                    "localField": "_installation_group",
                    "foreignField": "_id",
                    as: "_installation_group"
                }
            },
            {
                "$project": {
                    "status": "$status",
                    "_installation_group": {
                        $arrayElemAt: ["$_installation_group", 0]
                    },
                    'date': "$date"
                }
            },
            {
                $group: {
                    _id: agg.group,

                    "count": {
                        "$sum": 1
                    },

                    "data": {

                        $push: {

                            "status": "$status",
                            "count": {
                                "$sum": 1
                            }

                        }
                    }

                }
            },
            {
                "$sort": {
                    "_id": 1
                }
            }

        ]

        return query
    };

    var getDailyQuery = function () {
        return {

            "project": {

                "y": {
                    "$year": {
                        "$add": ["$creation_dt", moment().utcOffset() * 60 * 1000]
                    }
                },
                "m": {
                    "$month": {
                        "$add": ["$creation_dt", moment().utcOffset() * 60 * 1000]
                    }
                },
                "d": {
                    "$dayOfMonth": {
                        "$add": ["$creation_dt", moment().utcOffset() * 60 * 1000]
                    }
                }

            },
            "group": {
                "y": "$date.y",
                "m": "$date.m",
                "d": "$date.d",
                "_installation_group": "$_installation_group.client_name"
            }

        }
    };

    var getInstallationsData = function () {
        var dailyGroup = {};
        var clientGrouping = {}
        var histoData = [];
        I3InstallationSrv.agg({
            aggregate: getQuery()
        }, 100000, 0, function (res) {

            $scope.installationsPerDay = [];
            $scope.statusOpen = [];
            $scope.statusClosed = [];
            $scope.installationsOpen = 0;
            $scope.installationsClosed = 0;
            $scope.seriesPl = [];
            if (res && res.success && res.result && res.result.length > 0) {

                var result = angular.copy(res.result);
                result.forEach(function (value) {

                    var _date = moment({
                        year: value._id.y,
                        month: value._id.m - 1,
                        day: value._id.d
                    });

                    var day = _date.format("YYYY-MM-DD");
                    var clientName = "OTHER"

                    if (value._id && value._id._installation_group)
                        clientName = value._id._installation_group;

                    if (!dailyGroup[day]) dailyGroup[day] = [];
                    if (!clientGrouping[clientName]) clientGrouping[clientName] = [];
                    if (value && value.data && value.data.length > 0) {
                        dailyGroup[day] = dailyGroup[day].concat(value.data);
                    }
                    if (value && value.data && value.data.length > 0) {
                        clientGrouping[clientName] = clientGrouping[clientName].concat(value.data);
                    }
                })

                Object.keys(dailyGroup).forEach(function (key) {

                    var _date = moment(key, "YYYY-MM-DD");
                    var value = dailyGroup[key];
                    if (value && value && value.length > 0) {
                        var elemTab = value;

                        var counter1 = 0;
                        var counter2 = 0;

                        elemTab.forEach(function (elem) {
                            histoData.push(elem);
                            if (elem && elem.status == 'New' || elem.status == 'Planned') {
                                counter1 += elem.count;
                            }
                            if (elem && elem.status == 'Done' || elem.status == 'Cancelled' || elem.status == 'Rejected') {
                                counter2 += elem.count;
                            }
                        });
                        $scope.statusOpen.push([_date.valueOf(), counter1]);
                        $scope.statusClosed.push([_date.valueOf(), counter2]);
                    }
                    $scope.installationsOpen += counter1;
                    $scope.installationsClosed += counter2;
                    $scope.installationsPerDay.push([_date.valueOf(), value.count]);

                });

                $scope.installationByClients = [];
                Object.keys(clientGrouping).forEach(function (key) {
                    var clientInstall = {
                        client_name: key
                    }

                    var value = clientGrouping[key];
                    if (value && value && value.length > 0) {
                        var elemTab = value;

                        var counter1 = 0;
                        var counter2 = 0;

                        elemTab.forEach(function (elem) {
                            histoData.push(elem);
                            if (elem && elem.status == 'New' || elem.status == 'Planned') {
                                counter1 += elem.count;
                            }
                            if (elem && elem.status == 'Done' || elem.status == 'Cancelled' || elem.status == 'Rejected') {
                                counter2 += elem.count;
                            }
                        });

                    }
                    clientInstall.totalCount = (counter1 || 0) + (counter2 || 0)
                    clientInstall.opened = (counter1 || 0);
                    clientInstall.closed = (counter2 || 0);
                    if (clientInstall.totalCount && Number.isFinite(clientInstall.closed)) {
                        clientInstall.progress = 100 * clientInstall.closed / clientInstall.totalCount
                    }
                    $scope.installationByClients.push(clientInstall);

                });

              //  console.log($scope.installationByClients)
                var groups = _.groupBy(histoData, function (val) {
                    return val.pl;
                });
                angular.forEach(groups, function (value, key) {
                    var elem = {};

                    if (key) {
                        elem.name = key;

                        if (value) {
                            elem.y = value.length
                        }
                        $scope.seriesPl.push(elem)
                    }
                });
                $scope.showChart();
            } else if (res.success) {
                $scope.showChart();
                Notification.warning('Pas de données pour la periode séléctionnée')
            } else {
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("LOADING_ERROR")
                });
            }
        }, function (err) {});

    };

    $scope.showChart = function () {
        Highcharts.chart('chart', {
            chart: {
                zoomType: 'x'
            },
            title: {
                text: 'Installations'
            },

            subtitle: {
                text: ''
            },

            yAxis: {
                title: {
                    text: 'Nombre des installations'
                }
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: {
                    month: '%e. %b',
                    year: '%b'
                },
                title: {
                    text: 'Date'
                }
            },
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle'
            },

            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },
                    pointStart: 2017
                }
            },

            series: [{
                name: 'Installations',
                data: $scope.installationsPerDay
            }, {
                name: 'Installations ouvertes',
                data: $scope.statusOpen
            }, {
                name: 'Installations fermées',
                data: $scope.statusClosed,
            }],

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            },
            credits: {
                enabled: false
            }
        });

    }

});