angular.module("app").controller("deliverySlipEditCtrl", function ($scope, $state, $timeout, $stateParams, $rootScope, $filter,statusBox, $translate, ngDialog, Notification, I3deliverySlipSrv, I3StockSrv, I3ArticleSrv,I3StockStatusSrv) {

    $scope.deliverySlip = {};
    $scope.ListArticleMov = [];
    I3deliverySlipSrv.query({
        query: {
            _id: $stateParams.deliverySlipId
        },
        options: {
            populate: [{
                    path: "products._stock",
                    select: "name"
                },
                {
                    path: "products._article",
                    select: "serialNumber"
                },
                {
                    path: "products._product",
                    select: 'name'

                },
                {
                    path: "_plateforme",
                    select: 'name'
                },
                {
                    path: "_stock",
                    select: 'name virtual_stock'

                }

            ]
        }
    }, 1, 0, function (res) {

        if (res && res.success && res.result.length > 0) {
            $scope.deliverySlip = res.result[0];
         $scope.deliveryCopy= angular.copy(res.result[0])
        } else
            Notification.error($translate.instant("FIND_BY_ID_ERROR"));
    }, function (err) {
        Notification.error($translate.instant("FIND_BY_ID_ERROR"))
    })

    $timeout(function () {}, 500).then(function () {
        if (($rootScope.checkAccess("PLATEFORME","R","O")) && $scope._STOCK && $scope._STOCK.ALLPlateformes && $scope._STOCK.ALLPlateformes[0])
            $scope.deliverySlip._plateforme = $scope._STOCK.ALLPlateformes[0];
    });
    I3StockSrv.query({}, 10000, 1, function (res) {
        if (res.success) {
            $scope.ListStocks = res.result;
            $scope.virtual_stocks = [];
            $scope.ListStocks.forEach(function (val) {
                $scope.virtual_stocks = $scope.virtual_stocks.concat(val.virtual_stock.map(function (stock) {
                    stock._stock = val.name;
                    return stock
                }));
            })

        }
    }, function (err) {
        console.log(err);
    });

    $scope.openDeliveryDialog = function () {

        ngDialog.open({
            templateUrl: "app/commons/dialogs/deliverySlip/deliverySlip.add.dlg.html",
            className: 'ngdialog-theme-default custom-width-800',
            appendClassName: 'ngdialog-custom',
            controller: "addDeliveryProductDlgCtrl",
            scope: $scope
        })

    };
    $scope.checkForAvailabilityStock=function(cb){

            var query = {
                $or: []
            }
            var productsGrouped = _.groupBy($scope.deliverySlip.products, function (value) {
                console.log(value._product && value._product._id ? value._product._id : value._product + "#" + value._stock && value._stock._id ? value._stock._id : value._stock + "#" + (value.virtual_stock && value.virtual_stock._id ? value.virtual_stock._id : value.virtual_stock))
                return (value._product && value._product._id ? value._product._id : value._product) + "#" + (value._stock && value._stock._id ? value._stock._id : value._stock) + "#" + (value.virtual_stock && value.virtual_stock._id ? value.virtual_stock._id : value.virtual_stock)
            });

            $scope.deliverySlip.products.forEach(function (value) {
                query.$or.push(
                    {
                        _product: value._product && value._product._id ? value._product._id : value._product,
                        _stock: value._stock && value._stock._id ? value._stock._id : value._stock,
                        virtual_stock: value.virtual_stock && value.virtual_stock._id ? value.virtual_stock._id : value.virtual_stock
                    }
                )
            })

        I3StockStatusSrv.query({query:query},10000,0,function(res,err){
            try {
            if(err){

                cb(err)
            }else if(res && res.result && res.result.length>0){
                    var unavailableInStockProduct=[];
               Object.keys(productsGrouped).forEach(function(group){
                         var count=0;
                   productsGrouped[group].forEach(function(product){
                       if(product.qty){
                           count+=product.qty;
                       }
                   })
                   res.result.forEach(function(stock){ console.log(stock)
                       if(stock._product+"#"+stock._stock+"#"+stock.virtual_stock == group && count>stock.qty){
                           productsGrouped[group][0].vStock= $scope.getVirtualStokById(productsGrouped[group][0].virtual_stock);
                           unavailableInStockProduct.push({product:productsGrouped[group][0],available:stock.qty, total:count})

                       }
                   })
               })
                cb(null,unavailableInStockProduct);

            }else{
                var unavailableInStockProduct=[];
                Object.keys(productsGrouped).forEach(function(group) {
                    var count=0;
                    productsGrouped[group].forEach(function(product){
                        if(product.qty){
                            count+=product.qty;
                        }
                    })
                    productsGrouped[group][0].vStock = $scope.getVirtualStokById(productsGrouped[group][0].virtual_stock);
                    unavailableInStockProduct.push({
                        product: productsGrouped[group][0],
                        available: 0,
                        total: count
                    })
                })
                cb(null,unavailableInStockProduct);

            }
            }catch(e){
                console.error(e);
            }
        },function (err) {
            cb(err,null);

        })

    }
    $scope.getVirtualStokById = function (virtualStockId) {
        if (virtualStockId) {
            var filtred = $scope.virtual_stocks.filter(function (val) {
                return val._id == virtualStockId;
            })
            if (filtred && filtred.length > 0) {
                return filtred[0];
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    }

    $scope.removeDeliveryProduct = function (element, index) {
        $scope.deliverySlip.products.splice(index, 1);
        /*   var dialoge = ngDialog.open({
               templateUrl: "app/commons/dialogs/deliverySlip/deliverySlip.delete.dlg.html",
               className: 'ngdialog-theme-default custom-width-800',
               appendClassName: 'ngdialog-custom',
               controller: "deleteDeliveryProductDlgCtrl",
               scope: $scope,
               data: element,
               resolve: {
                   index: function () {
                       return index;
                   }
               }
           })
           dialoge.closePromise.then(function (data) {

               if (data && data.value != undefined && data.value != "$closeButton" && data.value != "$document") {

                           $scope.articlemodif._stock = data.value._stock._id
                           $scope.articlemodif.virtual_stock = data.value.virtual_stock._id,
                           $scope.articlemodif._product=data.value._product;
                           $scope.articlemodif.old_stock= $scope.deliverySlip._stock;
                           $scope.articlemodif.old_stock_virtual= $scope.deliverySlip.virtual_stock;
                           $scope.articlemodif._article= data.value._article || null;
                           $scope.articlemodif.qty=data.value.qty
                           $scope.ListArticleMov.push($scope.articlemodif)
                           console.log($scope.ListArticleMov)

                   $scope.deliverySlip.products.splice(index, 1);

                  }

           }, function () {

           });*/

    };

    $scope.cancel = function () {
        $scope.deliverySlip = {};
        $state.go('app.stocks.deliverySlip');
    };

    /*
        $scope.openDeliveryDialog = function () {

            ngDialog.open({
                templateUrl: "app/commons/dialogs/deliverySlip/deliverySlip.add.dlg.html",
                className: 'ngdialog-theme-default custom-width-800',
                appendClassName: 'ngdialog-custom',
                controller: "addDeliveryProductDlgCtrl",
                scope: $scope
            })

        };
    */

    $scope.save = function () {
        $scope.checkForAvailabilityStock(function(err,res) {
            if(err){
                //TO DO AFFICHER UN MESSAGE D'ERREUR
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_STOCK") + "!"
                });
            }else if(res && res.length>0){
                // some product unavailable in stok
                statusBox.confirm("STOCK_UNAVAILABLE", res)
               
               
                console.log(res)
            }else {
                // ALL IS OK
                I3deliverySlipSrv.updateById($scope.deliverySlip._id, $scope.deliverySlip, function (response) {

                    if (response.success) {

                        Notification.success({
                            title: $translate.instant("DELIVERY_SLIP_UPDATED"),
                            message: $translate.instant("DELIVERY_SLIP_UPDATED_SUCC")
                        });
                        $state.go('app.stocks.deliverySlip');
                        $scope.deliverySlip = {};
                    } else {
                        Notification.error({
                            title: $translate.instant('ERROR'),
                            message: $translate.instant("ERROR_OCCURED_UPDATE") + "!"
                        });
                        //$scope.deliverySlip = {};
                    }
                }, function (err) {
                    console.log(err);

                    Notification.error({
                        title: $translate.instant('ERROR'),
                        message: $translate.instant("ERROR_OCCURED_UDPDATE") + "!"
                    });
                    //$scope.deliverySlip = {};

                })
            }
        })

    };

    $scope.getRef = function (ref) {
        console.log("reference", ref)
        if (ref != undefined) {

            I3deliverySlipSrv.query({
                query: {
                    reference: ref
                }
            }, 10000, 1, function (res) {
                if (res.success) {

                    if (res.result.length > 0) {
                        if (res.result[0]._id != $scope.deliverySlip._id)
                            $scope.valide = true
                    } else {
                        $scope.valide = false
                    }

                }
            }, function (err) {
                console.log(err);
            });

        }
    }
    $scope.getPvNumber = function (pv) {

        if (pv != undefined) {

            I3deliverySlipSrv.query({
                query: {
                    pv_ref: pv
                }
            }, 10000, 1, function (res) {
                if (res.success) {
                    console.log(res.result);
                    if (res.result.length > 0) {
                        if (res.result[0]._id != $scope.deliverySlip._id)
                            $scope.pvValide = true
                    } else {
                        $scope.pvValide = false
                    }

                }
            }, function (err) {
                console.log(err);
            });

        }
    } 

 $scope.getStock = function (stock){
  
     if(stock && stock._id != $scope.deliveryCopy._stock._id ) {
         $scope.deliverySlip.virtual_stock = null
     }else{
        $scope.deliverySlip.virtual_stock = $scope.deliveryCopy.virtual_stock
     }
 }

})