angular.module('app').controller("confirmDeleteAssetDlgCtrl", function ($scope, $state, Notification, $translate) {
    $scope.confirm_delete = false;
    $scope.confirm = function () {
        $scope.confirm_delete = true
        Notification.success({
            title: $translate.instant("ASSET_DELETED_TITLE"),
            message: $translate.instant("ASSET_DELETED_SUC")
        });
        $scope.closeThisDialog($scope.confirm_delete);
    }
});