angular.module('app').controller('monitoringCtrl', function ($stateParams,$rootScope, $scope, $state, $window, messageBox, ngDialog, Notification, iaxeedFleetSrv, iaxeedAssetSrv, iaxeedCompanySrv, plateformHelperSrv, I3PlateformeSrv, iaxeedMonitoringSrv, iaxeedDeviceMsgSrv, $translate, iaxeedSocketSrv, $timeout) {
    //? (0) scope declarations
    $scope.filters = {}
    $scope.filter = false;
    $scope.updateMonitorNormal = false;
    $scope.MonitorCritical = [];
    $scope.MonitorCriticalTotal = 0;
    $scope.MonitorMajor = [];
    $scope.MonitorMajorTotal = 0;
    $scope.MonitorNormal = [];
    $scope.MonitorNormalTotal = 0;
    $scope.MonitorMinor = [];
    $scope.MonitorMinorTotal = 0;
    $scope.MonitorTrivialTotal = 0;
    $scope.MonitorTrivial = [];
    $scope.total = 0;
    $scope.search = {};
    $scope.itemsByPage = 50;
    $scope.highDisabled = false;
    $scope.mediumDisabled = false;
    $scope.lowDisabled = false;
    $scope.selectedPlatform = null;
    $scope.f_status = "New";
    $scope.status_new = true;
    $scope.first_load = true;
    $scope.status_Filter=["New","disabled"];
    $scope.selectedStatus = null
    //? (2) init and load data
    $scope.init = function () {
        counts = 0;
        avgCount = 0;
        $scope.avg = 0;
        $scope.assetCnt = 0;
        $scope.companyCnt = 0;
        $scope.updateMonitorNormal = false;
        $scope.MonitorCriticalTotal = 0;
        $scope.MonitorMajorTotal = 0;
        $scope.MonitorNormalTotal = 0;
        $scope.MonitorMinorTotal = 0;
        $scope.MonitorTrivialTotal = 0;
        $scope.total = 0;
        $scope.getAssetCount();
        $scope.getClientCount();
        $scope.loadMonitoringData($scope.selectedCompany, $scope.selectedFleet, $scope.selectedAsset);
        $scope.loadMonitorings();
    };

    if ($stateParams.filter)
        $scope.f_status = $stateParams.filter;
    // load table
    $scope.loadMonitorings = function (tableState, ctrl) {
        if (!$scope.first_load) {
            $scope.loadMonitorings2(tableState, ctrl);
        } else {
            $scope.first_load = false;
        }
    };

    //
    $scope.loadMonitorings2 = function (tableState, ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }
        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;
        var query = {
            category: $scope.selectedCategory ? $scope.selectedCategory._id : undefined,
            status :$scope.selectedStatus ? $scope.selectedStatus : undefined,
        };
        if ($scope.f_status && $scope.f_status != "New") {
            query.ack = $scope.f_status;
        } else {
            query.$or = [{
                ack: $scope.f_status
            }, {
                ack: null
            }]
        }
        var options = {
            populate: '_company_owner _asset _rule'
        };
        if ($scope.currentPage) {
            start = $scope.currentPage;
        }
        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
        }
        if (tableState.sort && tableState.sort.predicate) {
            options.sortBy = {};
            var predicate = tableState.sort.predicate;
            options.sortBy[predicate] = ((tableState.sort.reverse) ? -1 : 1);
        }
        if ($scope.selectedCategory) {
            query.category = $scope.selectedCategory ? $scope.selectedCategory._id : undefined;
        }
        if ($scope.selectedStatus) {
            query.status = $scope.selectedStatus ? $scope.selectedStatus : undefined;
        }
        if ($scope.selectedAsset) {
            query._asset = $scope.selectedAsset._id;
        } else if ($scope.selectedFleet && $scope.selectedFleet._assets && $scope.selectedFleet._assets.length > 0) {
            query._asset = {
                $in: $scope.selectedFleet._assets
            };
        } else if ($scope.selectedCompany) {
            query._company_owner = $scope.selectedCompany._id;
        }
        var prioQuery = getPriorityQuery($scope.lowDisabled, $scope.mediumDisabled, $scope.highDisabled);
        if (prioQuery != null && prioQuery != undefined) {
            query.priority = prioQuery;
        }
        iaxeedMonitoringSrv.query({
            query: query,
            options: options
        }, number, start, function (response) {
            if (response.success) {
                $scope.monitorings = response.result;
                $scope.monitoringCount = response.total_count;
                tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
                tableState.pagination.totalItemCount = response.total_count;
            } else {
                Notification.error($translate.instant("LOADING_ERROR"));
            }
        });
    };
    // load filter table + alert count
    $scope.loadMonitoringData = function (company, fleet, asset) {
        var agg = [];

        if (asset) {
            agg = [{
                    $match: {

                        _asset: asset ? "#OBJID#" + asset._id + "#OBJIDEND#" : undefined,
                        end_dt: null
                    }
                },
                {
                    $group: {
                        _id: {
                            priority: "$priority"
                        },
                        cnt: {
                            $sum: 1
                        }
                    }
                }
            ];
        } else if (fleet) {
            agg = [{
                    $match: {
                        ack: $scope.f_status,
                        //_asset:fleet?{$in : fleet._assets}:undefined,
                        _asset: fleet._assets ? {
                            $in: fleet._assets.map(function (val) {
                                return "#OBJID#" + val + "#OBJIDEND#"
                            })
                        } : undefined,
                        end_dt: null
                    }
                },
                {
                    $group: {
                        _id: {
                            priority: "$priority"
                        },
                        cnt: {
                            $sum: 1
                        }
                    }
                }
            ];
        } else if (company) {
            agg = [{
                    $match: {
                        ack: $scope.f_status,
                        _company_owner: company ? "#OBJID#" + company._id + "#OBJIDEND#" : undefined,
                        end_dt: null
                    }
                },
                {
                    $group: {
                        _id: {
                            priority: "$priority"
                        },
                        cnt: {
                            $sum: 1
                        }
                    }
                }
            ];
        } else {
            $scope.selectedAsset = null;
            $scope.selectedFleet = null;
            $scope.selectedCompany = null;
            agg = [{
                    $match: {
                        category: $scope.selectedCategory ? $scope.selectedCategory._id : undefined,
                        status : $scope.selectedStatus ? $scope.selectedStatus : undefined,
                        end_dt: null
                    }
                },
                {
                    $group: {
                        _id: {
                            priority: "$priority"
                        },
                        //data: {$push: "$$ROOT"},
                        cnt: {
                            $sum: 1
                        }
                    }
                }
            ];
        }
        if ($scope.f_status && $scope.f_status != "New") {
            agg[0].$match.ack = $scope.f_status;
        } else {
            agg[0].$match.$or = [{
                ack: $scope.f_status
            }, {
                ack: null
            }]
            delete agg[0].$match.ack
        }
        delete agg[0].$match.end_dt
        iaxeedMonitoringSrv.agg({
            aggregate: agg
        }, 10000, 0, function (res) {
            if (res && res.success) {

                $scope.processMonitoring(res.result)
            } else {
                Notification.error($translate.instant("SERVER_UNAVAILABLE"));
            }
        }, function (err) {
            if (err.status === -1) {
                Notification.error($translate.instant("IMPOSSIBLE_CNX"));
            } else if (err.status === 401) {
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS")
                });
            } else {
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("LOADING_ERROR")
                });
            }
        })
    };
    $scope.reloadData = function () {
        //console.log("Reloading data ...");
        $scope.MonitorCriticalTotal = 0;
        $scope.MonitorMajorTotal = 0;
        $scope.MonitorNormalTotal = 0;
        $scope.MonitorMinorTotal = 0;
        $scope.MonitorTrivialTotal = 0;
        $scope.loadMonitoringData($scope.selectedCompany, $scope.selectedFleet, $scope.selectedAsset);
        if ($scope.stCtrl) {
            $scope.stCtrl.tableState().pagination.start = 0;
            $scope.stCtrl.pipe();
        }
    }
    //? (3) used and commented
    //refreesh
    $scope.refresh = function () {
        $state.reload();
        $scope.first_load = true;
        //$scope.init();
    }
    //select platform and reset all filters
    $scope.selectPlatform = function (platform) {
     
        $scope.selectedFleet = null;
        $scope.selectedAsset = null;
        $scope.selectedCompany = null;
        $scope.filters._asset = null;
        $scope.filters._fleet = null;
        $scope.filters._client = null;
        $scope.filters.category = null;
        $scope.filters.status = null
        if ($rootScope.selectedPlatform && $rootScope.selectedPlatform._id == platform._id) {

        } else {
            $rootScope.$broadcast("platform:selected");
        }
        $rootScope.selectedPlatform = platform;
        plateformHelperSrv.setPlateFormAndLogin(platform, function (res) {
            if (res && res.success) {
                if (iaxeedSocketSrv.closeListner && typeof iaxeedSocketSrv.closeListner === "function") {
                    iaxeedSocketSrv.closeListner('newMsg');
                }
                iaxeedSocketSrv.connect(platform);
                iaxeedSocketSrv.on('server:connected', function () {
                    iaxeedSocketSrv.emit('user:connected', {
                        user: JSON.parse($window.sessionStorage.plateformSession).user
                    });
                });
                $rootScope.$broadcast("platform:changed", $rootScope.selectedPlatform);
                $rootScope.change_dt = new Date();
            } else {
                Notification.error($translate.instant("CHECK_CNX_SETTINGS"));
            }
        }, function (err) {
            if (err.status === -1) {
                Notification.error($translate.instant("IMPOSSIBLE_CNX"));
            } else if (err.status === 401) {
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS")
                });
            } else {
                Notification.error($translate.instant("LOADING_ERROR_PLATFORM"));
            }
        })
    };
    //select company and reset child filters
    $scope.selectCompany = function (company) {
        $scope.selectedFleet = null;
        $scope.selectedAsset = null;
        $scope.filters._asset = null;
        $scope.filters._fleet = null;
        $scope.selectedCompany = company
        $scope.reloadData()
    }
    //select fleet and reset child filters
    $scope.selectFleet = function (fleet) {
        $scope.selectedAsset = null;
        $scope.filters._asset = null;
        $scope.selectedFleet = fleet
        $scope.reloadData()
    }
    //select asset
    $scope.selectAsset = function (asset) {
        $scope.selectedAsset = asset
        $scope.reloadData()
    }
    //select category
    $scope.selectCategory = function (category) {
        $scope.selectedCategory = category
        $scope.reloadData()
    }
    $scope.selectStatus = function (status) {
       if(typeof status =="string"){
        $scope.selectedStatus = status
        $scope.reloadData()
       }
     
    }
    //priority filter
    $scope.applyPriorityFilter = function (type) {
        if (type == 'low') {
            $scope.lowDisabled = !$scope.lowDisabled;
        } else if (type == 'medium') {
            $scope.mediumDisabled = !$scope.mediumDisabled;
        } else {
            $scope.highDisabled = !$scope.highDisabled;
        }
        if ($scope.stCtrl) {
            $scope.stCtrl.tableState().pagination.start = 0;
            $scope.stCtrl.pipe();
        }
    }
    // get priority
    function getPriorityQuery(lowDisabled, mediumDisabled, highDisabled) {
        if (lowDisabled && mediumDisabled && highDisabled) {
            return null;
        } else {
            var enabledPrio = [];
            if (!lowDisabled) {
                enabledPrio.push(0);
            }
            if (!mediumDisabled) {
                enabledPrio.push(1);
            }
            if (!highDisabled) {
                enabledPrio.push(2);
            }
            if (enabledPrio && enabledPrio.length > 0) {
                if (enabledPrio.length == 1) {
                    return enabledPrio[0];
                } else if (enabledPrio.length == 3) {
                    return null // no need for filter get all
                } else {
                    return {
                        $in: enabledPrio
                    };
                }
            } else {
                return null;
            }
        }
    }
    //alert count
    $scope.processMonitoring = function (result) {
        if (result && result.length > 0) {
            result.forEach(function (val) {
                if (val._id.priority >= 2) {
                    $scope.MonitorMajorTotal = (val.cnt || 0);
                } else if (val._id.priority == 1) {
                    $scope.MonitorNormalTotal = (val.cnt || 0);
                } else if (val._id.priority == 0) {
                    $scope.MonitorMinorTotal = (val.cnt || 0);
                }
            })
        }
    }
    //asset count
    $scope.getAssetCount = function () {
        var query = {};
        if ($rootScope.selectedPlatform.dash && $rootScope.selectedPlatform.dash._clients && $rootScope.selectedPlatform.dash._clients.length > 0) {
            query = {
                query: {
                    _company_owner: {
                        $nin: $rootScope.selectedPlatform.dash._clients.map(function (val) {
                            return val._id
                        })
                    }
                }
            }
        }
        iaxeedAssetSrv.query(query, 1, 0, function (res) {
            if (res && res.success) {
                $scope.assetCnt = res.total_count;
            }
        }, function (err) {
            if (err.status === -1) {
                console.log($translate.instant("IMPOSSIBLE_CNX"))
                //Notification.error($translate.instant("IMPOSSIBLE_CNX"));
            } else if (err.status === 401) {
                console.log($translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS"))
                /* Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS")
                }); */
            } else {
                console.log($translate.instant("LOADING_ERROR_ASSET_COUNT"))
                //Notification.error($translate.instant("LOADING_ERROR_ASSET_COUNT"));
            }
        })
    };
    //client count
    $scope.getClientCount = function () {
        var query = {};

        if ($rootScope.selectedPlatform.dash && $rootScope.selectedPlatform.dash._clients && $rootScope.selectedPlatform.dash._clients.length > 0) {
            query = {
                query: {
                    _id: {
                        $nin: $rootScope.selectedPlatform.dash._clients.map(function (val) {
                            return val._id
                        })
                    }
                }
            }
        }
        iaxeedCompanySrv.query(query, 1, 0, function (res) {
            if (res && res.success) {
                $scope.companyCnt = res.total_count;
            }
        }, function (err) {
            if (err.status === -1) {
                //Notification.error($translate.instant("IMPOSSIBLE_CNX"));
                console.log($translate.instant("IMPOSSIBLE_CNX"))
            } else if (err.status === 401) {
                console.log($translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS"))
                /*  Notification.error({
                     title: $translate.instant("ERROR"),
                     message: $translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS")
                 }); */
            } else {
                console.log($translate.instant("LOADING_ERROR_COMPANY"))
                //Notification.error($translate.instant("LOADING_ERROR_COMPANY"));
            }
        })
    };
    //search client with ui-select refrech
    $scope.searchClient = function ($select) {
        if (!$select.open) return;
        iaxeedCompanySrv.query({
            query: {
                name: {
                    '$regex': $select.search,
                    '$options': 'i'
                }
            }
        }, 10, 0, function (res) {
            if (res && res.success) {
                $scope.clients = res.result;
            } else {
                console.log($translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS"))
                //Notification.error($translate.instant("SERVER_UNAVAILABLE"));
            }
        }, function (err) {
            if (err.status === -1) {
                console.log($translate.instant("IMPOSSIBLE_CNX"))
                // Notification.error($translate.instant("IMPOSSIBLE_CNX"));
            } else if (err.status === 401) {
                console.log($translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS"))
                /*  Notification.error({
                     title: $translate.instant("ERROR"),
                     message: $translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS")
                 }); */
            } else {
                //Notification.error($translate.instant("LOADING_ERROR_CLIENT"));
                console.log($translate.instant("LOADING_ERROR_CLIENT"))
            }
        })
    };
    //search fleet with ui-select refrech
    $scope.searchFleet = function ($select) {
        if (!$select.open) return;
        iaxeedFleetSrv.query({
            query: {
                _company_owner: $scope.selectedCompany ? $scope.selectedCompany._id : undefined
            }
        }, 10, 0, function (res) {
            if (res && res.success) {
                $scope.fleets = res.result;
            } else {
                console.log($translate.instant("LOADING_ERROR_CLIENT"))
                //Notification.error($translate.instant("SERVER_UNAVAILABLE"));
            }
        }, function (err) {
            if (err.status === -1) {
                console.log($translate.instant("IMPOSSIBLE_CNX"))
                //Notification.error($translate.instant("IMPOSSIBLE_CNX"));
            } else if (err.status === 401) {
                console.log($translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS"))
                /* Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS")
                }); */
            } else {
                console.log($translate.instant("LOADING_ERROR_FLEET"))
                // Notification.error($translate.instant("LOADING_ERROR_FLEET"));
            }
        })
    };
    //search asset with ui-select refrech
    $scope.searchAsset = function ($select) {
        if (!$select.open) return;
        iaxeedAssetSrv.query({
            query: {
                _company_owner: $scope.selectedCompany ? $scope.selectedCompany._id : undefined,
                _id: $scope.selectedFleet ? {
                    $in: $scope.selectedFleet._assets
                } : undefined,
                name: {
                    '$regex': $select.search,
                    '$options': 'i'
                }
            }
        }, 10, 0, function (res) {
            if (res && res.success) {
                $scope.assets = res.result;
            } else {
                console.log($translate.instant("SERVER_UNAVAILABLE"))
                // Notification.error($translate.instant("SERVER_UNAVAILABLE"));
            }
        }, function (err) {
            if (err.status === -1) {
                console.log($translate.instant("IMPOSSIBLE_CNX"))
                // Notification.error($translate.instant("IMPOSSIBLE_CNX"));
            } else if (err.status === 401) {
                console.log($translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS"))
                /* Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS")
                }); */
            } else {
                console.log($translate.instant("LOADING_ERROR_ASSET"))
                // Notification.error($translate.instant("LOADING_ERROR_ASSET"));
            }
        })
    };
    //search category with ui-select refrech
    $scope.searchCategory = function ($select) {
        if (!$select.open) return;
        var query = [{
            $match: {}
        }, {
            $group: {
                _id: "$category"
            }
        }]
        /*  if ($scope.search._client && $scope.search._client._id) {
             query[0].$match._company_owner = $scope.search._client._id;
         }
         if ($scope.search._asset && $scope.search._asset._id) {
             query[0].$match._asset = $scope.search._asset._id;
         } */
        iaxeedMonitoringSrv.agg({
                aggregate: query
            }, 10, 0, function (res) {
                if (res && res.success) {
                    $scope.categories = res.result
                    //console.log("TCL: $scope.searchCategory -> $scope.categories", $scope.categories)
                } else {
                    $scope.categories = [];
                }
            },
            function (err) {
                if (err.status === -1) {
                    console.log($translate.instant("IMPOSSIBLE_CNX"))
                    //Notification.error($translate.instant("IMPOSSIBLE_CNX"));
                } else if (err.status === 401) {
                    console.log($translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS"))
                    /*  Notification.error({
                         title: $translate.instant("ERROR"),
                         message: $translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS")
                     }); */
                } else {
                    console.log($translate.instant("LOADING_ERROR_CATEGORY"))
                    //Notification.error($translate.instant("LOADING_ERROR_CATEGORY"));
                }
            })
    };
    //remove alert from monitoring table
    $scope.remove = function (monitoring) {
        messageBox.confirm("DELETE", "MONITORING_DELETE_CONFIRM_MESSAGE").then(function (res) {
            if (res) {
                iaxeedMonitoringSrv.delete(monitoring._id, function (res) {
                    if (res && res.success) {
                        Notification.success({
                            title: $translate.instant("DELETE_SUCC"),
                            message: $translate.instant("MONITORING_DELETED")
                        });
                        $scope.reloadData()
                    } else {
                        Notification.warn($translate.instant("MONITORING_DELETE_ERROR"));
                    }
                }, function (err) {
                    Notification.error($translate.instant("MONITORING_DELETE_ERROR"));
                })
            }
        }, function (reject) {
            //Notification.error($translate.instant("INSTALLATION_REQUEST_DELETE_ERROR"));
        });
    };
    //change status new/processed
    $scope.change_status = function () {
        //console.log("changing status ...")
        if ($scope.status_new) {
            $scope.f_status = "New";
        } else {
            $scope.f_status = "Processed";
        }
        $scope.init();
        //$scope.loadMonitorings();
    }
    //? (4) event listenners
    // on destroy event
    $scope.$on('destroy', function (event) {
        //console.log("destroying ...")
        newRealtimeMonitoring();
        updateRealtimeMonitoring();
        PlatformSelectedListener();
    })
    // clean up on destroy
    $scope.$on('$destroy', cleanUp);

    function cleanUp() {
        //console.log("clean up called ")
        $scope.first_load = true;
        angular.element($window).off('resize', onResize);
        myListener();
        changePlatformListener();
    }
    //on resize window
    function onResize() {
        //console.log("onResize", angular.element("#charContainer").width());
        var width = 100;
        if ($("#charContainer").width() > 100) {
            width = $("#charContainer").width();
        }
        $scope.TimeLinewidth = width;
        $scope.$apply();
    }
    setTimeout(function () {
        onResize();
    });
    //wait until resize end for trigging resize event
    var resizeId;
    angular.element($window).on('resize', function () {
        clearTimeout(resizeId);
        resizeId = setTimeout(onResize, 200);
    });
    // create timeline and initiate data after content load
    $scope.$on('$viewContentLoaded', function (event, viewName, viewContent) {
        if (viewName) {
            //console.log("view loaded")
            //? (1) loading platform
            I3PlateformeSrv.query({
                query: {
                    type: "IAXEED"
                }
            }, 10000, 0, function (res) {
                if (res && res.success) {
                    $scope.platformList = res.result;

                    if ($scope.platformList && $scope.platformList.length > 0) {
                        var plateform = null;
                        if ($window.sessionStorage.plateform) {
                            try {
                                plateform = JSON.parse($window.sessionStorage.plateform)
                            } catch (e) {
                                logger.warn("invalid plateform from  cookie select first from list");
                                plateform = null;
                            }
                        }
                        if(!plateform && $rootScope.checkAccess("PLATEFORME","R","O")){
                            plateform=$scope.platformList[0]
                        }
                        if (plateform) {
                            for (var i = 0; i < $scope.platformList.length; i++) {
                                if (plateform._id === $scope.platformList[i]._id) {
                                    $rootScope.selectedPlatform = $scope.platformList[i];
                                    $scope.selectedPlatform = $scope.platformList[i];
                                    break;
                                }
                            }
                        }
                        if (!$rootScope.selectedPlatform){
                            $rootScope.selectedPlatform =  $scope.selectedPlatform ;
                                 // $rootScope.selectedPlatform = $scope.platformList[0];
                            //$scope.selectedPlatform = $scope.platformList[0];
                           
                        }
                         
                        if (iaxeedSocketSrv.closeListner && typeof iaxeedSocketSrv.closeListner === "function") {
                            iaxeedSocketSrv.closeListner('newMsg');
                        }
                  
                        plateformHelperSrv.setPlateFormAndLogin($rootScope.selectedPlatform, function (res) {
                            if (res && res.success) {
                                $scope.init();
                                iaxeedSocketSrv.connect($rootScope.selectedPlatform);
                                iaxeedSocketSrv.on('server:connected', function () {
                                    iaxeedSocketSrv.emit('user:connected', {
                                        user: JSON.parse($window.sessionStorage.plateformSession).user
                                    });

                                    $timeout(function () {
                                        $rootScope.$broadcast("platform:changed", $rootScope.selectedPlatform);

                                        $rootScope.change_dt = new Date();
                                    });
                                });
                            } else {
                                console.error(res);
                                Notification.error({
                                    title: $translate.instant("ERROR"),
                                    message: $translate.instant("LOADING_ERROR")
                                });
                            }
                        }, function (err) {
                            console.log(err);
                        })
                    }
                }
            }, function (err) {
                console.log(err);
            });
            createTimeline();

        }
    });

    function createTimeline() {
        var chart = new SmoothieChart({
            grid: {
                fillStyle: '#f5f5f5'
            },
            labels: {
                fillStyle: '#FF0000',
                precision: 0
            },
            maxValueScale: 1,
            minValue: 0
        });
        chart.addTimeSeries(random, {
            strokeStyle: 'rgba(0, 0, 0, 1)',
            fillStyle: 'rgba(0, 0, 0, 0.2)',
            lineWidth: 2
        });
        chart.streamTo(document.getElementById("chart"), 1000);
    }
    // listen for new msg
    var myListener = $rootScope.$on('newMsg', function (event, data) {
        //console.log("new msg")
        counts++
    });
    //Device message Time series
    var random = new TimeSeries();
    $scope.MsgCount = 0;
    var counts = 0;
    var avgCount = 0;
    setInterval(function () {
        avgCount += counts;
        if (angular.isUndefined($scope.avg)) {
            $scope.avg = counts * 60;
            $scope.$apply()
        }

        counts = 0;
    }, 1000);
    setInterval(function () {
        $scope.avg = avgCount;
        avgCount = 0;
        $scope.$apply()
    }, 60000);
    setInterval(function () {
        random.append(new Date().getTime(), counts);
        $scope.MsgCount = counts
    }, 500);
    //check if is filtered before alert listener
    function isFiltred(monitoring) {
        var filtered = false;
        if (!$scope.selectedCategory) {
            if (!$scope.selectedAsset && !$scope.selectedFleet && !$scope.selectedCompany) {
                return false;
            } else if ($scope.selectedAsset) {
                if ($scope.selectedAsset._id === monitoring._asset) {
                    return false;
                } else {
                    return true;
                }
            } else if ($scope.selectedFleet) {
                if ($scope.selectedFleet._assets && $scope.selectedFleet._assets.indexOf(monitoring._asset) >= 0) {
                    return false;
                } else {
                    return true;
                }
            } else if ($scope.selectedCompany) {
                if ($scope.selectedCompany._id == monitoring._company_owner) {
                    return false;
                } else {
                    return true;
                }
            }
            if (!filtered) {
                if ($scope.highDisabled && monitoring.priority >= 2) filtered = true;
                if ($scope.mediumDisabled && monitoring.priority == 1) filtered = true;
                if ($scope.lowDisabled && monitoring.priority == 0) filtered = true;
            }
            return filtered;
        } else {
            if (!$scope.selectedAsset && !$scope.selectedFleet && !$scope.selectedCompany) {
                if (monitoring.category === $scope.selectedCategory._id) {
                    return false;
                } else {
                    return true;
                }
            } else if ($scope.selectedAsset) {
                if ($scope.selectedAsset._id === monitoring._asset && monitoring.category === $scope.selectedCategory._id) {
                    return false;
                } else {
                    return true;
                }
            } else if ($scope.selectedFleet) {
                if ($scope.selectedFleet._assets && $scope.selectedFleet._assets.indexOf(monitoring._asset) >= 0 && monitoring.category === $scope.selectedCategory._id) {
                    return false;
                } else {
                    return true;
                }
            } else if ($scope.selectedCompany) {
                if ($scope.selectedCompany._id == monitoring._company_owner && monitoring.category === $scope.selectedCategory._id) {
                    return false;
                } else {
                    return true;
                }
            }
            if (!filtered) {
                if ($scope.highDisabled && monitoring.priority >= 2) filtered = true;
                if ($scope.mediumDisabled && monitoring.priority == 1) filtered = true;
                if ($scope.lowDisabled && monitoring.priority == 0) filtered = true;
            }
            return filtered;
        }
    }
    // new alert?
    var newRealtimeMonitoring = $rootScope.$on("newMonitor", function (event, data) {
        //console.log("newMonitor")
        if (isFiltred(data)) return;
        //if not filtred continue
        $scope.total++;
        //console.log("TCL: $scope.total", $scope.total)
        if (data.priority === 2) {
            $scope.MonitorMajorTotal = $scope.MonitorMajorTotal + 1;
        } else if (data.priority === 1) {
            $scope.MonitorNormalTotal = $scope.MonitorNormalTotal + 1;
        } else if (data.priority === 0) {
            $scope.MonitorMinorTotal = $scope.MonitorMinorTotal + 1;
        }
        $scope.$apply();
    });
    // update alert?
    var updateRealtimeMonitoring = $rootScope.$on("updateMonitor", function (event, data) {
        //console.log("updateMonitor")
        if (isFiltred(data)) return;
        //if not filtred continue
        $scope.total--;
        //console.log("TCL: $scope.total", $scope.total)
        if (data.priority === 2) {
            $scope.MonitorMajorTotal = $scope.MonitorMajorTotal - 1;
        } else if (data.priority === 1) {
            $scope.MonitorNormalTotal = $scope.MonitorNormalTotal - 1;
        } else if (data.priority === 0) {
            $scope.MonitorMinorTotal = $scope.MonitorMinorTotal - 1;
        }
        $scope.$apply()
    });
    // init after platform changed
    var changePlatformListener = $scope.$on("platform:changed", function (evt, args) {
        //console.log("platform changed")
        $scope.init();
        if ($scope.stCtrl) {
            $scope.stCtrl.tableState().pagination.start = 0;
            $scope.stCtrl.pipe();
        }
    });
    // reset after platform selected ?
    var PlatformSelectedListener = $scope.$on("platform:selected", function (evt, args) {
        //console.log("platform selected")
        counts = 0;
        avgCount = 0;
        $scope.avg = 0;
        $scope.assetCnt = 0;
        $scope.companyCnt = 0;
        $scope.updateMonitorNormal = false;
        $scope.MonitorCriticalTotal = 0;
        $scope.MonitorMajorTotal = 0;
        $scope.MonitorNormalTotal = 0;
        $scope.MonitorMinorTotal = 0;
        $scope.MonitorTrivialTotal = 0;
        $scope.total = 0;
        $scope.monitorings = [];
        $scope.monitoringCount = 0;
        if ($scope.stCtrl) {
            $scope.stCtrl.tableState().pagination.numberOfPages = 0;
            $scope.stCtrl.tableState().pagination.totalItemCount = 0;
            $scope.stCtrl.tableState().pagination.start = 0;
            $scope.stCtrl.pipe();
        }
        if (iaxeedSocketSrv.closeListner && typeof iaxeedSocketSrv.closeListner == 'function')
            iaxeedSocketSrv.closeListner();
    })
    //? (5) unused
    $scope.getServerVersion = function () {
        plateformHelperSrv.getVersion(function (res) {
            if (res && res.version) {
                $scope.version = res;
            }
        }, function (err) {})
    };
    $scope.showListError = function (category, priority, count) {
        $scope.category = category;
        $scope.priority = priority;
        $scope.count = count;
        ngDialog.openConfirm({
            template: 'app/commons/dialogs/error/error.dlg.html',
            overlay: true,
            showClose: true,
            controller: 'ListErrorCtrl',
            scope: $scope,
            width: '90%'
        })
    };
});