angular.module('app').controller('compatibilityRequestCtrl', function ($scope, I3CompatibilitySrv, $rootScope, messageBox, Notification, constantSrv, $translate, $stateParams) {
    $scope.itemsByPage = 50;
    $scope.search = {};
    $scope.requestEditStatus = []
    $scope.statusLoaded = false;
    var i = 0;

    if ($stateParams.filter)
        $scope.search.requestStatus = $stateParams.filter;
    //if ($stateParams.platform)

    //console.log($stateParams)
    // $scope.search.platform = $stateParams.platform;
    //$scope.requestStatus = constantSrv.getCompatibilityRequestStatus();
    constantSrv.getModel("COMPATIBILITY").getAllStatus(function (err, status) {

        $scope.requestStatus = status.map(function (val) {
            return val.name
        })

    })

    // $scope.requestEditStatus = constantSrv.getCompatibilityEditReqStatus();
    constantSrv.getModel("COMPATIBILITY").getAllStatus(function (err, stat) {
        var CompatibilityEditReqStatus = stat.map(function (value) {
            return value.name
        })
        CompatibilityEditReqStatus.forEach(function (elem) {
            if (elem && elem == "NEW" || elem == "READY") {
                $scope.requestEditStatus.push(elem)
            }
        })
        $scope.statusLoaded = true;
    })

    //Check if state is active
    $scope.isActive = function (state) {
        return $state.current.name.startsWith(state);
    };

    $scope.loadCompatibilities = function (tableState, ctrl) {

        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }
        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;
        var query = {
            options: {
                columns: "_plateforme client status case_number assets.name creation_dt",
                populate: '_plateforme',
                sortBy: {
                    creation_dt: -1
                }
            }
        };

        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
        }

        var requery = {};
        requery['status'] = {
            "$in": $scope.requestStatus
        };
        if ($scope.search && $scope.search.client) {
            requery["client"] = {
                '$regex': $scope.search.client,
                '$options': 'i'
            };

        }
        if ($scope.search && $scope.search.platform) {
            requery["_plateforme"] = $scope.search.platform._id;

        }
        if ($scope.search && $scope.search.case_number) {
            requery["case_number"] = {
                '$regex': $scope.search.case_number,
                '$options': 'i'
            };

        }
        if ($scope.search.requestStatus) {
            requery["status"] = $scope.search.requestStatus;

        }
        if (tableState.sort && Object.keys(tableState.sort).length > 0) {
            if (tableState.sort.predicate == "creation_dt") {
                query.options.sortBy.creation_dt = tableState.sort.reverse ? -1 : 1;

            }
        }
        query.query = requery;

        I3CompatibilitySrv.query(query, number, start, function (response) {
            console.log(response)
            if (response.success) {
                $scope.compatibilitiesCount = response.total_count;
                tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
                tableState.pagination.totalItemCount = response.total_count;
                $scope.tableState = tableState;
                $scope.compatibilities = response.result;
                if (($stateParams.platform) && (i == 0))
                {
                    console.log('rrrrr');
                    var elementPos = $scope._HOME.platforms.map(function (plat) {
                        return plat._id;
                    }).indexOf($stateParams.platform);

                    $scope.search.platform = $scope._HOME.platforms[elementPos];
                    i = 1;
                }

            }
        });
    };

    $scope.remove = function (compatibility) {

        messageBox.confirm("DELETE", "DELETE_COMPATIBILITY_REQUEST_MESSAGE").then(function (res) {

            if (res) {
                I3CompatibilitySrv.delete(compatibility._id, function (res) {
                    if (res && res.success) {
                        Notification.success({
                            title: $translate.instant("DELETE_SUCC"),
                            message: $translate.instant("COMPATIBILITY_REQUEST_DELETED")
                        });
                        $scope.loadCompatibilities();
                        if ($scope.stCtrl) {
                            $scope.stCtrl.pipe();
                        }
                    } else {
                        Notification.warn({
                            title: $translate.instant("DELETE_ERROR"),
                            message: $translate.instant("COMPATIBILITY_REQUEST_DELETE_ERROR")
                        });
                    }

                }, function (err) {
                    Notification.error({
                        title: $translate.instant("DELETE_ERROR"),
                        message: $translate.instant("COMPATIBILITY_REQUEST_DELETE_ERROR")
                    });
                })

            }

        }, function (reject) {
            //Notification.error($translate.instant("INSTALLATION_REQUEST_DELETE_ERROR"));
        });

    };
});