angular.module('app.dialogs').controller('confirmDeleteReceiptDlgCtrl', function ($scope, $rootScope, ngDialog, I3ReceiptSlipSrv, Notification, $state, $translate) {

    try {
        $scope.confirm = function () {
            I3ReceiptSlipSrv.delete($scope.receipt._id, function (response) {
                if (response.success) {
                    //$scope.roles.splice($scope.roles.indexOf($scope.role), 1);
                    //$scope.loadStatus();
                    $scope.loadReceipts()
                    Notification.success({
                        title: $translate.instant("GOODS_RECEIPT_DELETED_TITLE"),
                        message: $translate.instant("GOODS_RECEIPTS_DELETED_SUC")
                    });

                    $scope.receipt = {};
                } else {
                    Notification.error({
                        title: $translate.instant('ERROR'),
                        message: $translate.instant("GOODS_RECEIPTS_DELETED_ERROR")
                    });
                    $scope.receipt = {};
                }
            }, function (err) {
                console.log(err);

                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("GOODS_RECEIPTS_DELETED_ERROR")
                });

                $scope.receipt = {};
            });

            $scope.receipt = {};
            $scope.closeThisDialog();

        }
    } catch (e) {
        console.error(e);
    }

});