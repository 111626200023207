angular.module('app').controller('confirmDeleteWorkflowDlgCtrl',function($scope,I3WorkflowSrv , Notification,  $state,$translate){




    $scope.confirm = function () {
        I3WorkflowSrv.delete($scope.workflow._id, function (response) {
            if(response.success){
                //$scope.roles.splice($scope.roles.indexOf($scope.role), 1);
                //$scope.loadStatus();
                Notification.success({
                    title: $translate.instant("WORKFLOW_DELETED_TITLE"),
                    message : $translate.instant("WORKFLOW_DELETED_SUC")
                });
                $scope.loadWorkflow()
                $scope.workflow = {};
            }else{
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("WORKFLOW_DELETED_ERROR")
                });
                $scope.workflow = {};
            }
        }, function (err) {
            console.log(err);

            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("WORKFLOW_DELETED_ERROR")
            });

            $scope.workflow = {};
        });

       $scope.workflow = {};
        $scope.closeThisDialog();
       
    }

















})