angular.module('app.dialogs').controller('synchronePlateformeDlgCtrl', function ($scope, I3PlateformeSrv) {

    $scope.synchrone = {}


 

    $scope.getPlateformeSav = function (sav_plateforme ) {
      
        $scope.plateformeSav = sav_plateforme 
       
    }
    $scope.getInvoice = function (invoice_plateforme) {

        $scope.plateformeSav.external_id = $scope.synchrone.invoice_plateforme;
    }

    $scope.addSynchrone = function () {
        $scope.plateformeSav.external_id = $scope.synchrone.invoice_plateforme;

        I3PlateformeSrv.updateById($scope.plateformeSav._id, $scope.plateformeSav, function (response) {
            console.log(response)
            if (response.success) {

                $scope.closeThisDialog($scope.synchrone);
                $scope.loadPlatformes()
                $scope.plateformeSav = {};
            } else {

                $scope.plateformeSav = {};
            }
        }, function (err) {
            console.log(err);


            $scope.plateformeSav = {};

        })









    }
    $scope.cancel = function () {
        $scope.closeThisDialog(false);
    }
});