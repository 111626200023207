angular.module('app.dialogs').controller('confirmDeleteZoneDlgCtrl', function ($scope, $rootScope, ngDialog, I3ZoneSrv, Notification, $translate) {

    $scope.confirm = function () {
        I3ZoneSrv.delete($scope.zone._id, function (response) {

            if(response.success){

                Notification.success({
                    title: $translate.instant("ZONE_DELETED_TITLE"),
                    message: $translate.instant("ZONE_DELETED_SUC")
                });
                $scope.loadZones()
                $scope.zone = {};
            }else{
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ZONE_DELETED_ERROR")
                });
                $scope.zone = {};
            }
        }, function (err) {
            console.log(err);

            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("ZONE_DELETED_ERROR")
            });

            $scope.zone = {};
        });

        $scope.zone = {};
        $scope.closeThisDialog();
    }

});