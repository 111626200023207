angular.module('app').controller('productCodeAddCtrl',function ($scope,$translate,ngDialog, Notification,$state,i3HelperSrv,I3ProductCodeSrv) {

    $scope.save=function(){
        var productCode = angular.copy($scope.productCode);
        productCode.product_type=productCode.product_type.code;
        I3ProductCodeSrv.add(productCode,function(res){
            if(res.success){
                Notification.success({
                    title: $translate.instant("PRODUCT_CODE_CREATED"),
                    message: $translate.instant("PRODUCT_CODE_CREATED_SUCCESS")
                });
                $state.go('app.admin.product_code');

            }else{
                if(res.error && res.error.details && res.error.details.code==11000){
                    Notification.error({
                        title: $translate.instant('ERROR'),
                        message: $translate.instant("PRODUCT_CODE_DUPPLICATED") + " " + $scope.productCode._id + "!"
                    });
                }else{
                    Notification.error({
                        title: $translate.instant('ERROR'),
                        message: $translate.instant("ERROR_OCCURED_CREATE") + " " + $scope.productCode._id + "!"
                    });
                }


            }
        },function(er){
            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("ERROR_OCCURED_CREATE") + " " + $scope.productCode._id + "!"
            });
        })

    }
})