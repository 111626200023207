angular.module('app.dialogs').controller('productEditDlgCtrl', function ($rootScope, $translate, $scope, index, $state, Notification,I3StockSrv,I3StockStatusSrv,constantSrv) {
    var today = moment().startOf("day").clone();
    //$scope._HOME.subscriptionTypes = constantSrv.getSubscriptionTypes();
    $scope.stock = {}
    $scope.virtual_stocks={}
    $scope.isOutOfStock = true;
    $scope.showOutOfSotck = false;
    $scope.checkStock = false
    $scope.pvProduct = {}
    $scope.categoryZone = {}
    $scope.zonesList = []
    var now = moment().clone();
    $scope.dayRes = now;
    $scope.pv = $scope.pv || $scope.pv_sync
    if (!$scope.pv.products)
        $scope.pv.products = []

    $scope.getProductsByType=function(type){
        $scope.selectedProductType = type;
        $scope.pvProduct.quantity = null
        $scope.pvProduct._product = null;
        $scope.zonesList=[];
        $scope.pvProduct.commissioningDate=null,
            $scope.products =$scope._HOME.products.filter(function(e){

                return e.type ==type;
            })
            if (type !== "MATERIAL") {

                $scope.checkStock = false
            }
    }
    $scope.productType = $scope.pv.products[index]._product.type;
    $scope.getProductsByType($scope.productType);
    if ($scope.pv.products[index].zones)
        $scope.zonesList = $scope.pv.products[index].zones;
    $scope.pvProduct._product = $scope.pv.products[index]._product;
    $scope.pvProduct.quantity = $scope.pv.products[index].quantity;
    $scope.pvProduct.subscriptionType = $scope.pv.products[index].subscriptionType;
    $scope.pvProduct.commissioningDate = $scope.pv.products[index].commissioningDate;
    $scope.pvProduct.billingDate = $scope.pv.products[index].billingDate;
    $scope.pvProduct.zones = $scope.zonesList;
    $scope.removeCategory = function (index) {
        $scope.zonesList.splice(index, 1);
    }
    
    $scope.updatePvProduct = function () {
        $scope.pvProduct.zones = $scope.zonesList;
        //$scope.pvProduct.commissioningDate =   $scope.pvProduct.commissioningDate ;
     
       // $scope.pv.products[index] = $scope.pvProduct;


        $scope.checkStockAvailable()
        if ($scope.checkStock == false &&  $scope.pv.products[index].type == "MATERIAL") {
            $scope.pv.products[index].commissioningDate =   $scope.pvProduct.commissioningDate ;
            $scope.pv.products[index].billingDate =   $scope.pvProduct.billingDate ;
            $scope.pv.products[index] = $scope.pvProduct
            $scope.closeThisDialog();
            Notification.success({
                title: $translate.instant("PRODUCT_UPDATED"),
                message: $translate.instant("PRODUCT_UPDATED_SUCC")
            });
        } else if ($scope.pvProduct._product.type != "MATERIAL") {
            $scope.pv.products[index].commissioningDate =   $scope.pvProduct.commissioningDate ;
            $scope.pv.products[index].billingDate =   $scope.pvProduct.billingDate ;
            $scope.pv.products[index] = $scope.pvProduct
            $scope.closeThisDialog();
            Notification.success({
                title: $translate.instant("PRODUCT_UPDATED"),
                message: $translate.instant("PRODUCT_UPDATED_SUCC")
            });
        }

       



    };

    $scope.getCategoryByZone = function (zone) {
        $scope.categoryZone.category = null;
        $scope.zoneCategories = zone.travel_costs.map(function (e) {
            return e.category;
        });

    }

    $scope.addCategoryZone = function () {
        if ($scope.categoryZone._zone && $scope.categoryZone.category && $scope.categoryZone.quantity) {
            $scope.zonesList.push($scope.categoryZone)
            $scope.categoryZone = {}
        }

    }
    ////////////////////controle Products Stocks////////////////////////////

    $scope.checkStockAvailable = function () {

        $scope.isOutOfStock = true;
        $scope.showOutOfSotck = false;
        $scope.availableInStock = 0;



        if ($scope.pvProduct && $scope.pvProduct._product && $scope.pvProduct._product.type == "MATERIAL") {




            I3StockSrv.query({ query:{_plateforme: $scope.pv._plateforme} }, 10000, 1, function (res) {
                if (res.success) {
                    $scope.ListStocks = res.result;
                    $scope.virtual_stocks = [];
                    $scope.ListStocks.forEach(function (val) {
                      
                        $scope.stock = val
                        val.virtual_stock.forEach(function (stock) {
                            if (stock.type == "REEL") {
                                $scope.virtual_stocks = stock

                            }
                        });
                    })


                    var query = {
                        _product: $scope.pvProduct._product._id,
                        _stock: $scope.stock._id,
                        virtual_stock: $scope.virtual_stocks._id

                    }
                    I3StockStatusSrv.query({ query: query }, 10000, 0, function (res) {

                        if (res && res.success && res.result.length > 0) {
                            $scope.availableInStock = res.result[0].qty
                            $scope.availableInStockDiff = res.result[0].qty - $scope.pvProduct.quantity

                            $scope.isOutOfStock = $scope.availableInStockDiff < $scope.pvProduct.quantity;
                            if ($scope.isOutOfStock) {
                                $scope.showOutOfSotck = true;
                                $scope.checkStock = true
                            } else {
                                $scope.showOutOfSotck = false;
                                $scope.checkStock = false
                            }


                        } else {
                            $scope.showOutOfSotck = true;
                            $scope.checkStock = true
                        }

                    }, function (err) {
                        console.log("enable to get stock status");
                    })

                }
            }, function (err) {
                console.log(err);
            });
        }
    }


    $scope.selectProd = function (prod) {
        $scope.checkStock = false;
        $scope.pvProduct.quantity = null
    }


});