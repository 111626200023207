angular.module("app.directives").directive('exportInstallationXlsx', function ($rootScope,$translate) {
    return {
        scope: {install: '='},
        templateUrl: "app/commons/directives/export/exportInstallation.drv.html",
        link: function ($scope, $elm) {
            $elm.on('click', function () {
                var htmltable= document.getElementById('mytable_'+$scope.install._id);
                var html = htmltable.outerHTML;
                window.open('data:application/vnd.ms-excel,' + escape(html));


            })
        }

    }
})