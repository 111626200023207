angular.module("app").controller("deliverySlipAddCtrl", function ($scope, $timeout, $rootScope, $filter, $state, $translate, ngDialog, Notification, I3deliverySlipSrv, I3StockSrv) {

    $scope.deliverySlip = {};
    $scope.valide = null
    $scope.pvValide = null
    $timeout(function () {}, 500).then(function () {
        if (( $rootScope.checkAccess("PLATEFORME","R","O")) && $scope._STOCK && $scope._STOCK.ALLPlateformes && $scope._STOCK.ALLPlateformes[0])
            $scope.deliverySlip._plateforme = $scope._STOCK.ALLPlateformes[0];
    });

    $scope.save = function () {

        // Creating a new deliverySlip
        I3deliverySlipSrv.add($scope.deliverySlip, function (response) {
            console.log(response)
            if (response.success) {
                Notification.success({
                    title: $translate.instant("DELIVERY_SLIP_CREATED"),
                    message: $translate.instant("DELIVERY_SLIP_CREATED_SUC")
                });
                $state.go('app.stocks.deliverySlip', {}, {
                    reload: true
                });

            } else {
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("DELIVERY_SLIP_ERROR_OCCURED_CREATE") + "!"
                });
              //  $scope.deliverySlip = {};
               // $state.go("app.stocks.deliverySlip");
            }

        }, function (err) {

            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("DELIVERY_SLIP_ERROR_OCCURED_CREATE") + "!"
            });
           // $scope.deliverySlip = {};
          //  $state.go("app.stocks.deliverySlip");
        })
    };

    $scope.openDeliveryDialog = function () {

        ngDialog.open({
            templateUrl: "app/commons/dialogs/deliverySlip/deliverySlip.add.dlg.html",
            className: 'ngdialog-theme-default custom-width-800',
            appendClassName: 'ngdialog-custom',
            controller: "addDeliveryProductDlgCtrl",
            scope: $scope
        })

    };

    I3StockSrv.query({}, 10000, 1, function (res) {
        if (res.success) {
            $scope.ListStocks = res.result;
            $scope.virtual_stocks = [];
            $scope.ListStocks.forEach(function (val) {
                $scope.virtual_stocks = $scope.virtual_stocks.concat(val.virtual_stock.map(function (stock) {
                    stock._stock = val.name;
                    return stock
                }));
            })

        }
    }, function (err) {
        console.log(err);
    });

    $scope.getVirtualStokById = function (virtualStockId) {
        if (virtualStockId) {
            var filtred = $scope.virtual_stocks.filter(function (val) {
                return val._id == virtualStockId;
            })
            if (filtred && filtred.length > 0) {
                return filtred[0];
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    }

    $scope.removeDeliveryProduct = function (element, index) {
        $scope.deliverySlip.products.splice(index, 1);
    };
    /**
     * editing stock_virtual
     */
    /*$scope.editDeliveryProduct= function (prod, index) {

        ngDialog.open({
            templateUrl: "app/commons/dialogs/deliverySlip/deliverySlip.edit.dlg.html",
            controller: "editDeliveryProductDlgCtrl",
            scope: $scope,
            resolve: {
                index: function () {
                    return index;
                }
            }
        })
    };*/
    /**
     * Cancel creating/editing stock
     */
    $scope.cancel = function () {
        $scope.deliverySlip = {};
        $state.go('app.stocks.deliverySlip');
    };

    $scope.getRef = function (ref) {
        console.log("reference", ref)
        if (ref != undefined) {

            I3deliverySlipSrv.query({
                query: {
                    reference: ref
                }
            }, 10000, 1, function (res) {
                if (res.success) {
                    console.log(res.result);
                    if (res.result.length > 0) {

                        $scope.valide = true
                    } else {
                        $scope.valide = false
                    }

                }
            }, function (err) {
                console.log(err);
            });

        }
    }
    $scope.getPvNumber = function (pv) {

        if (pv != undefined) {

            I3deliverySlipSrv.query({
                query: {
                    pv_ref: pv
                }
            }, 10000, 1, function (res) {
                if (res.success) {
                    console.log(res.result);
                    if (res.result.length > 0) {

                        $scope.pvValide = true
                    } else {
                        $scope.pvValide = false
                    }

                }
            }, function (err) {
                console.log(err);
            });

        }
    }

})