

angular.module('app.dialogs').controller('serialDlgCtrl', function ($scope, I3ArticleSrv, I3StockSrv) {

    $scope._serials = []
    $scope.listProduct = []
    $scope.search = {};
    $scope.itemsByPage = 50;
    $scope.listarticles = []
 

    if ($scope.pv.products && $scope.pv.products.length > 0) {
        $scope.listProduct = $scope.pv.products.filter(function (val) {
            return val._product.is_trackSerial == true
        })

    }


    I3StockSrv.query({}, 10000, 1, function (res) {
        if (res.success) {
            $scope.ListStocks = res.result;
            $scope.virtual_stocks = [];
            $scope.ListStocks.forEach(function (val) {
                $scope.virtual_stocks = $scope.virtual_stocks.concat(val.virtual_stock.map(function (stock) {
                    stock._stock = val.name;
                    return stock
                }));
            })

        }
    }, function (err) {
        console.log(err);
    });

    $scope.loadArticles = function (tableState, ctrl) {
  
  

 


            if (!$scope.stCtrl && ctrl) {
                $scope.stCtrl = ctrl;
            }
            if (!tableState && $scope.stCtrl) {
                $scope.stCtrl.pipe();
                return;
            }
            var start = 0;
            var number = $scope.itemsByPage;
            var options = {
                populate: [
                    {
                        path: '_product',
                        select: 'name'
                    },
                    {
                        path: '_stock',
                        select: 'name'
                    },
                ],
                sortBy: { creation_dt: -1 }
            };

            var query = { options: options };
            if (tableState.pagination) {
                var pagination = tableState.pagination;
                number = pagination.number || $scope.itemsByPage;
                start = pagination.start / number + 1 || 1;
            }


            var requery = {};
           if (tableState.search && tableState.search.predicateObject) {
                if (tableState.search.predicateObject.serialNumber) {
                    requery.serialNumber = { $regex: "^" + tableState.search.predicateObject.serialNumber, $options: 'i' }
                }
                if (tableState.search.predicateObject.imei) {
                    requery.imei = { $regex: "^" + tableState.search.predicateObject.imei, $options: 'i' }
                }
            }
            if ($scope.search) {
                if ($scope.search._stock) {
                    requery["_stock"] = $scope.search._stock._id;

                }
                if ($scope.search._virtualStock) {
                    requery["virtual_stock"] = $scope.search._virtualStock._id;

                }
                if ($scope.search._product) {
                    requery["_product"] = $scope.search._product._product._id;

                }

            }


            query.query = requery;
          
       

            I3ArticleSrv.query(query, number, start,
                function (data) {
                   
                    if (data.success) {
                        $scope.listarticles=data.result;

                        if ($scope.pv._articles && $scope.pv._articles.length > 0) {
                            $scope.pv._articles.forEach(function (elem) {

                                if (elem) {
                                    $scope.listarticles.forEach(function (key) {
                                        if (elem._id == key._id) {
                                            key.selected = true
                                        }
                                    })
                                }
                            });

                        }
                        $scope.articlesCount = data.total_count;
                        tableState.pagination.numberOfPages = Math.ceil(data.total_count / number);
                        tableState.pagination.totalItemCount = data.total_count;
                        $scope.tableState = tableState;
                    } else {
                        console.error(data.error)
                    }
                },
                function (err) {
                    console.log("err", err)
                })

      
    
    
    };



    $scope.getVirtualStokById = function (virtualStockId) {
        if (virtualStockId) {
            var filtred = $scope.virtual_stocks.filter(function (val) {
                return val._id == virtualStockId;
            })
            if (filtred && filtred.length > 0) {
                return filtred[0];
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    }
    $scope.setStock = function (article) {
        if (article.selected == true) {
            $scope._serials.push(article)

        } else {
            $scope._serials.slice(article)
        }
    }
    $scope.addPvSerial = function () {
        $scope.pv._articles = $scope._serials

        $scope.closeThisDialog($scope.pv)



    }

});