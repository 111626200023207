angular.module("app").controller('stocksCtrl', function ($scope, $state,I3ProductSrv,I3PlateformeSrv,I3StockSrv) {
    $scope.$state = $state;
    $scope._STOCK={}
    I3ProductSrv.query({}, 10000, 1, function (res) {
        if (res.success) {
            $scope._STOCK.ALLProducts = res.result.filter(function (val) {
                return val.type == "MATERIAL"
            });

        }
    }, function (err) {
        console.log(err);
    });
    I3PlateformeSrv.query({}, 10000, 1, function (res) {
        if (res.success) {
            $scope._STOCK.ALLPlateformes = res.result

        }
    }, function (err) {
        console.log(err);
    });
    I3StockSrv.query({}, 10000, 1, function (res) {
     
        if (res.success) {
            $scope._STOCK.ALLStocks = res.result;
            $scope._STOCK.virtual_stocks = [];
            $scope._STOCK.ALLStocks.forEach(function (val) {
                $scope._STOCK.virtual_stocks = $scope._STOCK.virtual_stocks.concat(val.virtual_stock.map(function (stock) {
                    stock._stock = val.name;
                    return stock
                }));
            })
    

        }
    }, function (err) {
        console.log(err);
    });
    
});