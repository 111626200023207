angular
    .module('app.directives')
    .directive('uiCalendar', function ($cookieStore, $translate) {
        return {
            templateUrl: "app/commons/directives/uiCalendar/uiCalendar.tpl.html",
            scope: {
                events : "="
            },
            link: function (scope, elem, attr) {
                moment.locale($cookieStore.get('lang'));

                scope.view = "MONTH";

                $(function () {
                    $('[data-toggle="tooltip"]').tooltip()
                });

                scope.$watch('events', function () {
                    _buildCal();
                });

                scope.getHoursOfDay = function () {
                    var array = [];
                    for(var i = 0; i<= 23; i++){
                       if(i <= 9){
                           array.push("0"+i+":00");
                       }else{
                           array.push(i+":00");
                       }
                    }
                    return array;
                };

                var isToday = function (date) {
                    return moment().clone().startOf('day').diff(date, 'days') === 0;
                };
                var isSelected = function (date) {
                    return scope.date.clone().startOf('day').diff(date, 'days') === 0;
                };

                var isCurrentMonth = function (date) {
                    return scope.date.get("month") === date.get("month");
                };

                var  week_of_month = function (date) {

                    var prefixes = [1,2,3,4,5];

                    return prefixes[0 | moment(date).date() / 7]

                };

                var dayHasEvent = function (date) {
                    var evt = {};
                    angular.forEach(scope.events, function (event) {

                       var startEvent = moment(event.start).clone();
                       var endEvent = moment(event.end).clone();

                       if(moment(date).isBetween(startEvent, endEvent) || date.format('DD/MM/YYYY') === startEvent.format('DD/MM/YYYY') || date.format('DD/MM/YYYY') === endEvent.format('DD/MM/YYYY')){
                           evt.isEvent = true;
                           if(date.format('DD/MM/YYYY') === startEvent.format('DD/MM/YYYY')){
                               evt.isEventStart = true;
                               evt.text = event.text;
                               evt.tooltip = $translate.instant('START_DATE') +": "+ moment(startEvent).format('DD/MM/YYYY HH:mm')+"<br>";
                               evt.tooltip += $translate.instant('END_DATE') +": "+ moment(endEvent).format('DD/MM/YYYY HH:mm');
                           }
                           if(date.format('DD/MM/YYYY') === endEvent.format('DD/MM/YYYY')){
                               evt.isEventEnd = true
                           }
                       }
                    });

                    return evt;

                };
                var getWeekDays = function() {
                   var weekDays =  moment.weekdays();
                   var tmp ="";
                   tmp = weekDays[0];
                   weekDays.splice(0,1);
                    weekDays[weekDays.length] = tmp;
                    return weekDays;
                };

                var hourHasEvent = function (date) {
                    var evt = {};
                    angular.forEach(scope.events, function (event) {
                        var startEvent = moment(event.start).clone();
                        var endEvent = moment(event.end).clone();

                        if(moment(date).isBetween(startEvent, endEvent) || moment(date).format('DD/MM/YYYY HH') === moment(startEvent).format('DD/MM/YYYY HH') || moment(date).format('DD/MM/YYYY HH') === moment(startEvent).format('DD/MM/YYYY HH')){
                            evt.isEvent = true;
                            if(moment(date).format('DD/MM/YYYY HH') === moment(startEvent).format('DD/MM/YYYY HH')){
                                evt.isEventStart = true;
                                evt.text = event.text;
                                evt.tooltip = $translate.instant('START_DATE') +": "+ moment(startEvent).format('DD/MM/YYYY HH:mm')+"<br>";
                                evt.tooltip += $translate.instant('END_DATE') +": "+ moment(endEvent).format('DD/MM/YYYY HH:mm');
                                evt.startAt = moment(startEvent).format('mm');
                            }
                            if(moment(date).format('DD/MM/YYYY HH') === moment(endEvent).format('DD/MM/YYYY HH')){
                                evt.isEventEnd = true;
                                evt.endAt = moment(endEvent).format('mm');
                            }
                        }
                    });

                    return evt;
                };

                var dayIndexInWeek = function (date) {
                    return date.day();
                };

                scope.date = moment().clone();
                scope.weekNum = week_of_month(scope.date);
                scope.dayNum = dayIndexInWeek(scope.date);

                var _buildCal = function () {
                    scope.calendar = [];
                    scope.weekDays = getWeekDays();
                    scope.months = moment.monthsShort();
                    scope.today = moment().clone();

                    scope.startOfMonth = moment(scope.date).startOf('month').startOf('week');
                    scope.endOfMonth = moment(scope.date).endOf('month').endOf('week');

                    scope.numDays = Math.abs(scope.endOfMonth.diff(scope.startOfMonth, 'days'));

                    scope.numWeeks = Math.round(scope.numDays/7);

                    for(var i = 0; i < scope.numWeeks; i++){
                        var week = { id : i };
                        week.days  = [];
                        for(var j=0; j < 7; j++){
                            var day = {
                                date : scope.startOfMonth.clone(),
                                num : scope.startOfMonth.format("DD"),
                                event : dayHasEvent(scope.startOfMonth.clone().startOf('day')),
                                selected : isSelected(scope.startOfMonth.clone().startOf('day')),
                                today: isToday(scope.startOfMonth.startOf('day')),
                                currentMonth: isCurrentMonth(scope.startOfMonth.clone().startOf('day'))
                            };
                            day.hours = [];
                            for(var k=0; k <= 23; k++){

                                day.hours.push({
                                    id: k,
                                    hour: scope.startOfMonth.format("HH"),
                                    date: scope.startOfMonth.clone(),
                                    event: hourHasEvent(scope.startOfMonth.clone())
                                });

                                scope.startOfMonth.add(1, 'hours');
                            }
                            week.days.push(day);
                        }
                        scope.calendar.push(week);
                    }
                };

                _buildCal();

                scope.updateDate = function (obj, action) {
                    if(obj === "MONTH"){
                        switch(action){
                            case 'next' :
                                scope.date.add(1, 'month');
                                break;
                            case 'prev' :
                                scope.date.subtract(1, 'month');
                                break;
                        }
                    }

                    if(obj === "WEEK"){
                        switch(action){
                            case 'next' :
                                scope.date.add(7, 'days');
                                scope.weekNum = week_of_month(scope.date);
                                break;
                            case 'prev' :
                                scope.date.subtract(7, 'days');
                                scope.weekNum = week_of_month(scope.date);
                                break;
                        }
                    }

                    if(obj === "DAY"){
                        switch(action){
                            case 'next' :
                                scope.date.add(1, 'days');
                                scope.dayNum = dayIndexInWeek(scope.date);
                                break;
                            case 'prev' :
                                scope.date.subtract(1, 'days');
                                scope.dayNum = dayIndexInWeek(scope.date);
                                break;
                        }
                    }



                    _buildCal();
                };

                scope.setToday = function () {
                    scope.date = moment().clone();
                    scope.weekNum = week_of_month(scope.date);
                    scope.dayNum = dayIndexInWeek(scope.date);
                    _buildCal();
                };

                scope.changeView = function (view) {
                    scope.view = view;
                    scope.weekNum = week_of_month(scope.date);
                    scope.dayNum = dayIndexInWeek(scope.date);
                }
            }
        }
    });