angular.module('app').controller('statusAddCtrl', function ($scope, $translate, ngDialog, I3StatusSrv, Notification, $state) {
    $scope.status = {};
    $scope.Models = ["COMPATIBILITY", "COMPATIBILITY_STUDY", "INSTALLATION_REQUEST", "INSTALLATION", "INTERVENTION", "CLAIM", "STOCK"]
    $scope.liststatus = []

    $scope.getStatusbyModel = function (model) {

        I3StatusSrv.query({
            query: {
                model: $scope.status.model
            }
        }, 10000, 0, function (data) {
            if (data.success) {
                $scope.liststatus = data.result
                if ($scope.liststatus.length > 0) {
                    Notification.warning({
                        title: $translate.instant('ALERT_INFO'),
                        message: $translate.instant("STATUS_EXISTS") + "!"
                    });
                }

            } else {
                console.error(data.error)
            }

        }, function (data) {
            // console.log(data)
        })
    }
    $scope.addStatus = function () {

        I3StatusSrv.add($scope.status, function (response) {
            if (response.success) {
                Notification.success({
                    title: $translate.instant("STATUS_CREATED"),
                    message: $translate.instant("STATUS_ADDED_SUC")
                });
                $state.go('app.admin.status');
                $scope.status = {};
            } else {
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_CREATE") + "!"
                });
            }
        }, function (err) {
            console.log(err);
            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("ERROR_OCCURED_CREATE") + "!"
            });
            $scope.status = {};
        })
    };
    $scope.openStatusDialogue = function () {

        ngDialog.open({
            templateUrl: "app/commons/dialogs/status/status.dlg.html",
            controller: "addStatusDlgCtrl",
            scope: $scope
        })
    };

    $scope.editstatus = function (element, index) {

        ngDialog.open({
            templateUrl: "app/commons/dialogs/status/status.edit.dlg.html",
            controller: "editStatusDlgCtrl",
            scope: $scope,
            resolve: {
                index: function () {
                    return index;
                }
            }
        })
    };

    $scope.removeStatus = function (element, index) {
        $scope.status.status.splice(index, 1);
    };

    $scope.cancel = function () {
        $scope.status = {};
        $state.go('app.admin.status');
    }
})