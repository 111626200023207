/**
 * Created by Mohamed Saad on 01/02/2018.
 */
angular.module('app').controller('clientFormListCtrl', function ($rootScope, $scope, I3InstallationSrv, I3InstallationGroupSrv, I3PlateformeSrv, plateformHelperSrv, iaxeedCompanySrv, I3ProductSrv, ngDialog, Notification, $state, messageBox, $translate, constantSrv, i3HelperSrv) {

    $scope.search = {};
    $scope.itemsByPage = 50;

    $scope.status = constantSrv.getStatusValidation().concat(constantSrv.getStatusProscpection());
    $scope.phases = constantSrv.getPhase();
    var searchPhases = ['InProcpection', 'InValidation'];

    /**
     * change to create mode
     */
    $scope.create = function () {

        $state.go("app.home.installRequestAdd");
        $scope.mode = "CREATE";
    };

    $scope.generateTempToken = function (installationRequest) {
        i3HelperSrv.getInstallationTempKey(installationRequest._id, function (res) {

            if (res && res.success) {
                if (!installationRequest.temp_token) {
                    ngDialog.open({
                        controller: "publicLinkDlgCtrl",
                        templateUrl: "app/commons/dialogs/client/publiclink.dlg.tpl.html",
                        scope: $scope,
                        resolve: {
                            access: function () {
                                return res.result;
                            },
                            installationRequest: function () {
                                return installationRequest;
                            }
                        },
                        className: 'ngdialog-theme-default custom-width'
                    }).closePromise.then(function () {
                        if (installationRequest.status !== 'Sent')
                            I3InstallationGroupSrv.updateById(installationRequest._id, {
                                status: 'Sent'
                            }, function (res) {
                                if (res && res.success) {
                                    $scope.loadInstallationRequest()
                                } else {
                                    Notification.error({
                                        title: $translate.instant("ERROR"),
                                        message: $translate.instant("ERROR")
                                    });
                                }
                            }, function (err) {})
                    });
                } else {
                    ngDialog.open({
                        templateUrl: "app/commons/dialogs/client/addNewlink.dlg.tpl.html",
                        scope: $scope,
                        className: 'ngdialog-theme-default custom-width'
                    }).closePromise.then(function (data) {
                        if (data) {
                            ngDialog.open({
                                controller: "publicLinkDlgCtrl",
                                templateUrl: "app/commons/dialogs/client/publiclink.dlg.tpl.html",
                                scope: $scope,
                                resolve: {
                                    access: function () {
                                        return res.result;
                                    },
                                    installationRequest: function () {
                                        return installationRequest;
                                    }
                                },
                                className: 'ngdialog-theme-default custom-width'
                            }).closePromise.then(function () {
                                if (installationRequest.status !== 'Sent')
                                    I3InstallationGroupSrv.updateById(installationRequest._id, {
                                        status: 'Sent'
                                    }, function (res) {
                                        if (res && res.success) {
                                            $scope.loadInstallationRequest()
                                        } else {
                                            Notification.error({
                                                title: $translate.instant("ERROR"),
                                                message: $translate.instant("ERROR")
                                            });
                                        }

                                    }, function (err) {})
                            });
                        }
                    });
                }
            } else {
                console.log("error, ", res);
            }
        }, function (err) {
            console.log(err)
        })
    };

    $scope.showDetails = function (installationRequest) {
        $state.go("app.home.clientForm.details", {
            id: installationRequest._id
        });
    }

    $scope.remove = function (installationRequest) {

        messageBox.confirm("DELETE_INSTALLATION_REQUEST_TITLE", "DELETE_INSTALLATION_REQUEST_MESSAGE").then(function (res) {

            if (res) {
                I3InstallationGroupSrv.delete(installationRequest._id, function (res) {
                    if (res && res.success) {
                        Notification.success($translate.instant("INSTALLATION_REQUEST_DELETED"));
                        if ($scope.stCtrl) {
                            $scope.stCtrl.pipe();
                        }
                    } else {
                        Notification.warn($translate.instant("INSTALLATION_REQUEST_DELETE_ERROR"));
                    }

                }, function (err) {
                    Notification.error($translate.instant("INSTALLATION_REQUEST_DELETE_ERROR"));
                })

            }

        }, function (reject) {})
    };

    /**
     * Get all  plateform
     */
    I3PlateformeSrv.query({}, 10000, 1, function (response) {
        $scope.platforms = response.result;
    }, function (err) {
        console.log(err);
    });
    /**
     * select and authenticate plateforme
     * @param platform
     */
    $scope.selectPlateform = function (platform) {

        plateformHelperSrv.setPlateFormAndLogin(platform, function (res) {

            console.log(res);
        }, function (err) {
            console.log(err);
        })
    };
    $scope.selectClient = function (comapny) {
        if (comapny.contact) {

            if (!$scope.installationRequest.contact) $scope.installationRequest.contact = {};
            $scope.installationRequest.contact.fax = comapny.contact.fax;
            $scope.installationRequest.contact.email = comapny.contact.mail;
            $scope.installationRequest.contact.phone = comapny.contact.phone;

        }

    };
    $scope.searchClient = function ($select) {

        iaxeedCompanySrv.query({
            query: {
                name: {
                    '$regex': $select.search,
                    '$options': 'i'
                }
            }
        }, 10, 0, function (res) {

            $scope.clients = res.result;
        }, function (err) {

        })
    };
    $scope.addInstallation = function () {

        ngDialog.open({
            template: 'app/commons/dialogs/installationsGroup/installation.add.dlg.tpl.html',
            controller: 'addInstallationDlgCtrl',
            scope: $scope
        })
    };
    $scope.addRequest = function (request) {
        ngDialog.open({
            template: 'app/commons/dialogs/clientsForm/client.form.add.tpl.html',
            controller: 'addClientFormCtrl',
            scope: $scope,
            width: '800px',
            resolve: {
                request: function () {
                    return request
                }
            }
        }).closePromise.then(function (data) {
            if (data) {
                $scope.loadInstallationRequest()
            }

        });
    };

    $scope.loadInstallationRequest = function (tableState, ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }

        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;

        if (tableState.pagination && tableState.pagination.number) {
            number = tableState.pagination.number;
        }
        if (tableState.pagination && tableState.pagination.start) {
            start = Math.ceil((tableState.pagination.start + 1) / number);
        }
        var options = {
            populate: ['_plateforme', {
                path: '_installationIds',
                populate: {
                    path: '_assigned_to',
                    model: 'user'
                }
            }]
        };

        var query = {
            phase: {
                $in: searchPhases
            }
        };

        if (tableState.search && tableState.search.predicateObject) {
            if (tableState.search.predicateObject.client_name) {
                query.client_name = {
                    '$regex': tableState.search.predicateObject.client_name,
                    '$options': 'i'
                }
            }
            if (tableState.search.predicateObject.ref) {
                query.ref = {
                    '$regex': tableState.search.predicateObject.ref,
                    '$options': 'i'
                }
            }

        }
        if ($scope.search && $scope.search.status) {
            query.status = $scope.search.status;
        }
        I3InstallationGroupSrv.query({
            query: query,
            options: options
        }, number, start, function (response) {
            if (response.success) {
                $scope.installationRequests = response.result.map(function (e) {
                    e.installCount = 0;
                    e.installDoneCount = 0;
                    if (e.installations && e.installations.length > 0) {
                        e.installations.forEach(function (val) {
                            e.installCount += (Number.isFinite(val.asset_count) ? val.asset_count : 0);

                        })

                    }
                    if (e._installationIds && e._installationIds.length > 0) {
                        e._installationIds.forEach(function (val) {
                            if (val.status == "Done") e.installDoneCount++;

                        })

                    }

                    e.progress = e.installCount ? e.installDoneCount * 100 / e.installCount : 0
                    return e;
                });
                $scope.requestsCount = response.total_count;
                tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
                tableState.pagination.totalItemCount = response.total_count;
                $scope.tableState = tableState;
            }
        });
    };

    $scope.cancel = function () {
        $scope.mode = "LIST";
    };

    $scope.save = function (installRequest) {
        var install = angular.copy(installRequest);
        install.client_id = installRequest.client_id._id;
        install._plateforme = installRequest.plateform._id;
        install.client_name = installRequest.client_id.name;

        if (install.comments[0].msg && install.comments[0].msg.length > 0) {

        } else {
            delete install.comments;
        }
        I3InstallationGroupSrv.add(install, function (res) {

            if (res) {
                Notification.success($translate.instant("INSTALLATION_GROUP_ADDED"));
            }
        }, function (err) {

        })
    };

    $scope.removeStatus = function (predefined) {
        var index = $scope.predefinedClaimStatus.indexOf(predefined);
        if (index > -1) {
            $scope.predefinedClaimStatus.splice(index, 1)
            $scope.loadInstallationRequest();
        }
    };

    $scope.updateInstallationRequestProp = function (prop, $data, id) {
        var updateData = {};
        if (prop == "status") {
            updateData[prop] = $data;
        }
        I3InstallationGroupSrv.updateById(id, updateData, function (res) {
            Notification.success($translate.instant("INSTALLATION_GROUP_UPDATED"));
        }, function (err) {})
    };

});