angular.module('app').controller("ClientIntervSyncDlgCtrl", function ($scope, $q, plateformHelperSrv, $translate, iaxeedCompanySrv, Notification) {
    // console.log($scope.intervention)
    $scope.sg_client = {};
    $scope.clients = [];
    $scope.auto = true;
    $scope.suggest_client = function () {
        plateformHelperSrv.setPlateFormAndLogin($scope.intervention._plateforme, function (res) {
            if (res && res.success) {
                $scope.platformeLogin = res
                var query = {
                    name: {
                        '$regex': $scope.intervention.client_name,
                        '$options': 'i'
                    }
                }
                if ($scope.intervention._client_code) {
                    query = {
                        $or: [{
                                name: {
                                    '$regex': $scope.intervention.client_name,
                                    '$options': 'i'
                                }
                            },
                            {
                                client_code: $scope.intervention._client_code
                            }
                        ]
                    }
                }
                if ($scope.platformeLogin && $scope.platformeLogin.result && $scope.platformeLogin.result.token) {
                    iaxeedCompanySrv.query({
                        query: query
                    }, 1, 0, function (res) {
                        //console.log(res)
                        // $scope.sg_client = res.result[0];
                        if (res.result.length > 0) {
                            $scope.sg_client = res.result[0];
                            if ($scope.intervention._client_code) {
                                res.result.forEach(function (client) {
                                    if (client.client_code == $scope.intervention._client_code) {
                                        $scope.sg_client = client;
                                    }
                                })
                            }
                            // console.log("f", $scope.sg_client)
                        }
                        //console.log($scope.sg_client)
                    }, function (err) {
                        console.log("err", err)
                    })
                } else {
                    // console.log("no")
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("PLATFORM_CNX_ERROR")
                    });
                }
            }
        }, function (err) {
            console.log(err);
            Notification.error({
                title: $translate.instant("ERROR"),
                message: $translate.instant("PLATFORM_CNX_ERR")
            });
        })
    }
    $scope.suggest_client()
    $scope.change_client = function (client) {
        // console.log("c", client)
        $scope.sg_client = client;
        $scope.auto = false;
        // console.log("d", $scope.sg_client)
    }
    $scope.find_client = function ($select) {
        if ($scope.platformeLogin && $scope.platformeLogin.result && $scope.platformeLogin.result.token) {
            iaxeedCompanySrv.query({
                query: {
                    name: {
                        '$regex': $select.search,
                        '$options': 'i'
                    }
                }
            }, 10, 0, function (res) {
                $scope.clients = res.result;
                // console.log("e", $scope.clients)
                //console.log($scope.sg_client)
            }, function (err) {
                console.log("err", err)
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("PLATFORM_UNAUTHORIZED_ACCESS_ERR")
                });
            })
        }
    }
    $scope.save = function () {
        $scope.closeThisDialog($scope.sg_client);
    }
    $scope.selectPlateform = function (platform) {
        plateformHelperSrv.setPlateFormAndLogin(platform, function (res) {}, function (err) {
            console.log(err);
        })
    };
});