/**
 * Created by Kais CHAIBI on 15/03/2018.
 */

angular
    .module("app.directives")
    .directive('fabExport', function () {
        return {
            templateUrl: "app/commons/directives/export/export.btns.tpl.html"
        }

    })