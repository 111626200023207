angular.module('app').filter('vStockbyStock', function() {
   
    return function (stock,stocklist) {
            if(stock!=undefined && stocklist!=undefined)
                var virtualStockList=stocklist.virtual_stock;
                var filteredStock=virtualStockList;
                var stockId=stock;
                if(stock && stock._id){
                    stockId=stock._id;
                }
              
                if(filteredStock && filteredStock.length>0) {
                    filteredStock = filteredStock.filter(function (val) {
                        if (val._id == stockId) {
                            return true;
                        } else {
                            return false;
                        }
                    })
                    
                   if (filteredStock.length > 0) {
                        if (filteredStock[0]) {
                            return filteredStock[0].name;
                        }
                    }
                }else{
                    return "" ;
                }

    };
})
