angular.module('app').controller("confirmDeleteUserDlgCtrl", function ($scope, $state, I3UserSrv, Notification, $translate) {

    $scope.confirm = function () {
        I3UserSrv.delete($scope.user._id, function (response) {
            if(response.success){   
                $scope.users.splice($scope.users.indexOf($scope.user), 1);
                
                Notification.success({
                    title: $translate.instant("USER_DELETED_TITLE"),
                    message: $translate.instant("USER_DELETED_SUC")
                });

                $scope.loadUsers();
                $scope.tableState.pagination.start = 0;
                
                $scope.user = {};
            }else{
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("USER_DELETED_ERROR")
                });
                $scope.user = {};
            }
        }, function (err) {
            console.log(err);

            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("USER_DELETED_ERROR")
            });

            $scope.user = {};
        });

        $scope.user = {};
        $scope.closeThisDialog();
    }
});