angular.module('app').controller('ListErrorCtrl', function ($scope, $rootScope, iaxeedMonitorRuleSrv, iaxeedMonitoringSrv, iaxeedCompanySrv, iaxeedAssetSrv) {

    $scope.itemsByPage = 5;
    $scope.search = {}
    $scope.loadMonitorings = function (tableState, ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }

        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;

        var query = {
            'priority': $scope.priority,
            'category': $scope.category,
            'end_dt': null
        };

        if ($scope.selectedAsset) {
            query._asset = $scope.selectedAsset._id;
            $scope.search._asset = $scope.selectedAsset;
        } else if ($scope.selectedFleet) {
            query._asset = {
                $in: $scope.selectedFleet._assets
            };
        } else if ($scope.selectedCompany) {
            query._company_owner = $scope.selectedCompany._id;

        }
        if ($scope.selectedCompany)
            $scope.search._client = $scope.selectedCompany;

        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
        }
        if (tableState.sort && tableState.sort.predicate) {
            options.sortBy = {};

            var predicate = tableState.sort.predicate;
            options.sortBy[predicate] = ((tableState.sort.reverse) ? -1 : 1);
        }

        if ($scope.search._asset) {
            query._asset = $scope.search._asset._id;
        }
        if ($scope.search._rule) {
            query._rule = $scope.search._rule;
        }
        if ($scope.search._client) {
            query._company_owner = $scope.search._client._id;
        }

        iaxeedMonitoringSrv.query({
            query: query,
            options: {
                populate: ' _company_owner _asset _rule'
            }
        }, number, start, function (response) {
            if (response.success) {
                $scope.monitorings = response.result;
                $scope.monitoringCount = response.total_count;
                tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
                tableState.pagination.totalItemCount = response.total_count;
                $scope.tableState = tableState;
            }
        });
    };
    /**
     * search by client
     * @param $select
     */
    $scope.searchClient = function ($select) {
        iaxeedCompanySrv.query({
            query: {
                name: {
                    '$regex': $select.search,
                    '$options': 'i'
                }
            }
        }, 10, 0, function (res) {
            $scope.clients = res.result;
        }, function (err) {

        })
    };
    /**
     * search by asset
     * @param $select
     */
    $scope.searchAsset = function ($select) {
        if ($scope.search._client) {
            iaxeedAssetSrv.query({
                query: {
                    _company_owner: $scope.search._client._id,
                    name: {
                        '$regex': $select.search,
                        '$options': 'i'
                    }
                }
            }, 10, 0, function (res) {
                $scope.assets = res.result;
            }, function (err) {

            })
        } else {
            iaxeedAssetSrv.query({
                query: {
                    _company_owner: $scope.search.client,
                    name: {
                        '$regex': $select.search,
                        '$options': 'i'
                    }
                }
            }, 10, 0, function (res) {
                $scope.assets = res.result;
            }, function (err) {

            })
        }
    };

    /**
     * search by asset
     * @param $select
     */
    $scope.searchRule = function ($select) {
        if ($scope.search._rule) {
            iaxeedMonitorRuleSrv.query({
                query: {
                    name: {
                        '$regex': $select.search,
                        '$options': 'i'
                    }
                }
            }, 10, 0, function (res) {
                $scope.rules = res.result;
            }, function (err) {

            })
        }
    };
});