angular.module("app").controller("stockStatusCtrl", function ($scope, $rootScope, $filter, $state, $translate, ngDialog, Notification, I3StockSrv, I3ProductSrv, I3StockStatusSrv, I3PlateformeSrv) {
    $scope.$state = $state;
    $scope._STOCK = {};
    $scope.search = {};
    $scope.itemsByPage = 50;
    $scope.articles = [];
    $scope.allPlateformes = []
    I3PlateformeSrv.query({}, 10000, 1, function (res) {
        if (res.success) {
            $scope.allPlateformes = res.result
            console.log('$scope.allPlateformes :', $scope.allPlateformes)

        }
    }, function (err) {
        console.log(err);
    });
    I3ProductSrv.query({}, 10000, 1, function (res) {
        if (res.success) {
            $scope._STOCK.ALLProducts = res.result.filter(function (val) {
                return val.type == "MATERIAL"
            });

        }
    }, function (err) {
        console.log(err);
    });

    I3StockSrv.query({}, 10000, 1, function (res) {
        if (res.success) {
            $scope._STOCK.ALLStocks = res.result;

            $scope.virtual_stocks = [];

            $scope.loadStockStatus()


        }
    }, function (err) {
        console.log(err);
    });
    $scope.loadStockStatus = function (tableState, ctrl) {

        var start = 0;
        var number = 10000;
        $scope.products = [];
        var query = [
            {
                $group: {
                    _id: { _stock: "$_stock", "virtual_stock": "$virtual_stock", "_product": "$_product" },
                    cnt: { $sum: "$qty" }
                }

            },
            {
                $lookup:
                {
                    from: "stock",
                    localField: "_id._stock",
                    foreignField: "_id",
                    as: "_stock"
                }


            },
            {
                $lookup:
                {
                    from: "product",
                    localField: "_id._product",
                    foreignField: "_id",
                    as: "_product"
                }


            },
            {
                $project: {
                    _id: "$_id._stock",
                    _virtual_Stock: "$_id.virtual_stock",
                    "_product": { "$arrayElemAt": ["$_product", 0] },
                    "_stock": { "$arrayElemAt": ["$_stock", 0] },
                    "cnt": "$cnt"
                }
            },
            {
                $group: {
                    _id: "$_id",
                    stock: { $first: "$_stock" },
                    data: { $push: { _product: "$_product", cnt: "$cnt", _virtual_Stock: "$_virtual_Stock" } }
                }

            }

        ]




        try {
            I3StockStatusSrv.agg({ aggregate: query }, number, start,
                function (data) {

                    console.log(data)
                    if (data.success) {
                        var result = data.result;

                        result = result.map(function (val) {
                            var stock = val.stock;


                            if (val && val.data && val.data.length > 0) {
                                val.data.forEach(function (stat) {
                                    if (stat._product && stat._product._id) {
                                        var filtered = stock.virtual_stock.filter(function (vstock) {
                                            if (!vstock.products) vstock.products = {};
                                            return stat._virtual_Stock == vstock._id;
                                        })
                                        if (!stock.virtual_stock.products) stock.virtual_stock.products = {};
                                        if (filtered && filtered.length > 0) {
                                            filtered[0].products[stat._product._id] = stat.cnt
                                        }
                                        var productExists = false;
                                        var productsFounded = $scope.products.filter(function (val) {
                                            if (val._id == stat._product._id) {
                                                productExists = true;
                                            }
                                        })
                                        if (!productExists) {
                                            $scope.products.push(stat._product)
                                        }
                                        //  console.log($scope.products)
                                    } else {
                                        console.warn("Product deleted")
                                    }
                                })






                                return stock;
                            }


                        });


                        $scope._STOCK.ALLStocks = result;


                        for (let index = 0; index < $scope.allPlateformes.length; index++) {

                            for (let j = 0; j < $scope._STOCK.ALLStocks.length; j++) {

                                if ($scope.allPlateformes[index]._id.toString() == $scope._STOCK.ALLStocks[j]._plateforme.toString()) {
                                    $scope._STOCK.ALLStocks[j].virtual_stock.unshift({
                                        name: 'Dernière commande',
                                        products: {
                                            '5e8c512819e6da33eeda8843': $scope.allPlateformes[index].device_qty,
                                            '5ef62bf458c8667918a8899f': $scope.allPlateformes[index].canbus_qty
                                        }
                                    })
                                }


                            }
                        }









                    } else {
                        console.error(data.error)
                    }
                },
                function (err) {
                    console.log("err", err)
                })

        } catch (e) {
            console.error(e);
        }

    };
    $scope.getVirtualStokById = function (virtualStockId) {
        if (virtualStockId) {
            var filtred = $scope.virtual_stocks.filter(function (val) {
                return val._id == virtualStockId;
            })
            if (filtred && filtred.length > 0) {
                return filtred[0];
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    }

    $scope.remove = function (article) {
        $scope.article = article;
        ngDialog.open({
            templateUrl: "app/commons/dialogs/confirm/article.delete.dlg.tpl.html",
            controller: "confirmDeleteArticleDlgCtrl",
            scope: $scope
        })
    };


})