angular.module('app').controller("returnSlipCommentCtrl", function ($scope, Notification,index) {

  $scope.product=$scope.deliverySlips.products[index]
    
    $scope.addreturnSlipDescription=function(){

        $scope.deliverySlips.products[index]=$scope.product
        $scope.closeThisDialog($scope.deliverySlip)

    }
    })