angular.module("app.services").factory("CompatibilityUtilsSrv", ["$filter",function ($filter) {
            var utils ={};
            utils.accessoriesList=["device","canbus","direct_line","temp_probe","door_sensor","ibutton_reader","ibutton_tag","group_cold_sensor","immobilizer_12V","immobilizer_24V","sim_supplier_ngi"]
            utils.groupByZone = function(compatibility){
                var zones={};
                compatibility.assets.forEach(function(val){
                     if(!zones[val.place_zone]) zones[val.place_zone]=[];
                    zones[val.place_zone].push(val);
                });
                return  zones;
            };
        utils.getOptionsStats=function(assets){
                    var options = {};
                    assets.forEach(function(val){
                      
                        utils.accessoriesList.forEach(function(key){
                           
                            if(val.req_options[key] && val.req_options[key] >-1){
                             
                                if(!options[key]) options[key]=0;
                                options[key]+=val.req_options[key];
                       
                             
                            }
                        })
                    });

            return options;
        };
            return utils;
}])