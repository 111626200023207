angular.module('app').controller("addClientCompatibiltyDlgCtrl", function ($scope, $state, I3ClientSrv, ngDialog, $stateParams, CompatibilityUtilsSrv, Notification, $translate) {

    $scope.client = {};

    if ($scope.compatibility && $scope.compatibility._plateforme) {
        $scope.client._plateforme = $scope.compatibility._plateforme
    } else {
        $scope.client._plateforme = $scope.compatibilityRequest._plateforme
    }



    $scope.save = function (client) {
        $scope.client = client



        I3ClientSrv.add(client, function (response) {

            if (response.success) {
                Notification.success({
                    title: $translate.instant("CLIENT_CREATED"),
                    message: $translate.instant("CLIENT_ADDED_SUC")
                });
                if ($scope.compatibility && $scope.compatibility._plateforme) {
                    $scope.compatibility.client = response.result
                } else {
                    $scope.compatibilityRequest.client = response.result
                }

                $scope.client = {}
                $scope.closeThisDialog();


            } else {
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_CREATE") + " " + client.name + "!"
                });
            }
        }, function (err) {

            console.log(err);

            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("ERROR_OCCURED_CREATE") + " " + client.name + "!"
            });
            $scope.client = {};
        })
    }


})




