angular.module('app').controller('adminClientEditCtrl', function ($scope, $rootScope, $stateParams, accessSrv, I3ClientSrv, $state, $translate, I3UserSrv, i3HelperSrv, I3RoleSrv, ngDialog, Notification, constantSrv) {

    $scope.client = {};

    I3ClientSrv.query({
        query: {
            _id: $stateParams.ClientId
        },
        options: { populate: '_plateforme _subClients' }
    }, 1, 0, function (res) {
        if (res && res.result) {
            $scope.client = res.result[0];
            $scope.client.SUB_CLIENT = (res.result[0].SUB_CLIENT) ? res.result[0].SUB_CLIENT : false;
            $scope.ClientCopy = angular.copy($scope.client);



        }
    })




    $scope.save = function () {

        if (!$scope.client.status) {
            $scope.client.status = false
        }

        if ($scope.client.SUB_CLIENT == true && $scope.client._subClients) {
            delete $scope.client._subClients
        }

        I3ClientSrv.updateById($scope.client._id, $scope.client, function (response) {
            if (response.success) {
                Notification.success({
                    title: $translate.instant("CLIENT_UPDATED"),
                    message: $translate.instant("CLIENT_UPDATED_SUCC")
                });
                if ($scope.client._subClients && $scope.client._subClients.length > 0) {

                    for (let index = 0; index < $scope.client._subClients.length; index++) {
                        const element = $scope.client._subClients[index];
                        element.client_group = $scope.client.name
                        I3ClientSrv.updateById(element._id, element, function (res) {
                            if (res.success) {

                            }

                        })
                    }

                    $state.go('app.admin.clients');

                    $scope.client = {};


                } else {
                    $state.go('app.admin.clients');

                    $scope.client = {};
                }


            } else {
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_UPDATE") + " " + $scope.client.name + "!"
                });
                $scope.client = {};
            }
        }, function (err) {
            console.log(err);

            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("ERROR_OCCURED_UDPDATE") + " " + $scope.client.name + "!"
            });
            $scope.client = {};

        })
    };



    $scope.toggleSelection = function (status) {


        $scope.client.status = status
    }
    $scope.cancel = function () {

        $state.go('app.admin.clients');
    };


    $scope.editCustomer = function () {
        ngDialog.open({
            templateUrl: "app/commons/dialogs/status/status.edit.dlg.html",
            controller: "editSubClientDlgCtrl",
            scope: $scope,
            resolve: {
                index: function () {
                    return index;
                }
            }
        })


    }

    $scope.openStatusDialogue = function () {
        ngDialog.open({
            templateUrl: "app/commons/dialogs/subClient/subClient.add.dlg.html",
            controller: "addSubClientDlgCtrl",
            scope: $scope,

        })


    }
    $scope.removeCustomer = function (cli, index) {

        $scope.client._subClients.splice(index, 1);

    }
    $scope.verifSubCLient = function (subClient) {

        if (subClient && subClient == false) {
            if ($scope.client._subClients) {
                $scope.client._subClients = []
            }
        }

    }

})