angular.module("app").controller("deliverySlipCtrl", function ($scope, $rootScope, $filter, $state, $translate, ngDialog, Notification, I3InterventionSrv, I3StockSrv, I3deliverySlipSrv) {
    $scope.deliverySlips = [];

    $scope.search = {};
    $scope.itemsByPage = 50;

    $scope.loadDeliverySlip = function (tableState, ctrl) {

        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }
        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;
        var options = {
            populate: [{
                    path: 'products._product',
                    select: 'name'
                },
                {
                    path: 'products._stock',
                    select: 'name'
                },
                {
                    path: '_plateforme',
                    select: 'name'
                },
                {
                    path: 'products._article',
                    select: 'serialNumber imei'
                },
                {
                    path: '_stock',
                    select: 'name'
                }
            ],
            sortBy: {
                creation_dt: -1
            }
        };

        var query = {
            options: options
        };
        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
        }

        var requery = {};

        if ($scope.search) {
            /*if ($scope.search._stock) {

                requery["products._stock"] = $scope.search._stock._id;

            }*/
            if ($scope.search && $scope.search.client) {
                requery["client"] = {
                    '$regex': $scope.search.client,
                    '$options': 'i'
                };

            }
            if ($scope.search.asset) {

                requery["asset"] = {
                    '$regex': $scope.search.asset,
                    '$options': 'i'
                };
            }

            if ($scope.search._stock) {

                requery["_stock"] = $scope.search._stock._id;

            }
            if ($scope.search._virtualStock) {

                requery["virtual_stock"] = $scope.search._virtualStock._id;

            }
            if ($scope.search._product) {

                requery["products._product"] = $scope.search._product._id;

            }
            if ($scope.search._plateforme) {

                requery["_plateforme"] = $scope.search._plateforme._id;

            }
            if ($scope.search.delivery_ref) {
                requery["reference"] = {
                    '$regex': $scope.search.delivery_ref,
                    '$options': 'i'
                };

            }
        }

        query.query = requery;

        I3deliverySlipSrv.query(query, number, start,
            function (data) {

                if (data.success) {
                    $scope.deliverySlips = data.result;
                    console.log("$scope.deliverySlips ", $scope.deliverySlips)
                    $scope.deliverySlipsCount = data.total_count;
                    tableState.pagination.numberOfPages = Math.ceil(data.total_count / number);
                    tableState.pagination.totalItemCount = data.total_count;
                    $scope.tableState = tableState;
                } else {
                    console.error(data.error)
                }
            },
            function (err) {
                console.log("err", err)
            })

    };

    I3StockSrv.query({}, 10000, 1, function (res) {
        if (res.success) {
            $scope.ListStocks = res.result;
            $scope.virtual_stocks = [];
            $scope.ListStocks.forEach(function (val) {
                $scope.virtual_stocks = $scope.virtual_stocks.concat(val.virtual_stock.map(function (stock) {
                    stock._stock = val.name;
                    return stock
                }));
            })

        }
    }, function (err) {
        console.log(err);
    });

    $scope.getVirtualStokById = function (virtualStockId) {
        if (virtualStockId) {
            var filtred = $scope.virtual_stocks.filter(function (val) {
                return val._id == virtualStockId;
            })
            if (filtred && filtred.length > 0) {
                return filtred[0];
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    }
    $scope.remove = function (deliverySlip) {
        $scope.deliverySlip = deliverySlip;

        ngDialog.open({
            templateUrl: "app/commons/dialogs/confirm/deliverySlip.delete.dlg.tpl.html",
            controller: "confirmDeletedeliverySlipDlgCtrl",
            scope: $scope
        })

    };

})