angular.module('app.dialogs').controller('settingFieldsDlgCtrl', function ($scope, constantSrv, isfieldName, $rootScope, ngDialog, modelId, updateCfg, isSetting, I3RoleSrv, I3SettingSrv, Notification, $translate, i3HelperSrv) {
    try {

        // console.log(isSetting)
        $scope.res = [];
        var keys = '';
        $scope.config = {};
        var statusKeys = [];
        var modelName="";
        $scope.selectedStatus = { status: [], key: {} };
        $scope.listStatus = [];
        if (modelId == "installation_group") {
            modelName = "installation_request"

        } else {

            modelName = modelId

        }
        constantSrv.getStatusbyModel(modelName).getStatus(function (err, status) {

            $scope.listStatus = status
        });
        i3HelperSrv.getFieldsByCollectionName(modelName.toUpperCase(), function (response) {
            if (response) {

                $scope.fields = [];
                //  $scope.fields = Object.keys(res);
                var fieldKeys = Object.keys(response).filter(function (val) {

                    return val === 'status'
                });

                var res = "";

                if (typeof updateCfg != 'boolean' && updateCfg != undefined && updateCfg.status && updateCfg.status.length > 0) {

                    $scope.selectedStatus.key.value = true;
                    res = updateCfg.status;
                    $scope.selectedStatus.exception = true;
                    $scope.selectedStatus.status = res;
                    // console.log($scope.listStatus.length == res.length)
                    if (res.length > 0) {
                        $scope.selectedStatus.key.value = true;
                        $scope.selectedStatus.all = false;
                        $scope.selectedStatus.exception = true;
                    }


                } else {
                    if (updateCfg == 'status') {
                        $scope.selectedStatus.key.value = true;
                        $scope.selectedStatus.all = true;
                        $scope.selectedStatus.exception = false;

                    }
                }

                angular.forEach(fieldKeys, function (e) {

                    $scope.fields.push({
                        code: e,
                        value: $scope.selectedStatus.key.value || false
                    });

                })




                if (updateCfg === true) {
                    $scope.all = true;
                    $scope.selectedStatus.key.value = false;
                } if (updateCfg === false) {
                    $scope.nothing = true;
                    $scope.selectedStatus.key.value = false;
                }


                if (updateCfg.status && updateCfg.status.length > 0) {
                    $scope.selectedStatus.exception = true;
                    $scope.selectedStatus.key.value = true;

                }
                /*  if ($scope.field_list_notif.length > 0) {
                      var res = $scope.field_list_notif.split(" ");
                      angular.forEach($scope.fields, function (e) {
                          var index = res.indexOf(e)
                          if (index >= 0) {
                              $scope.fields[e] = true
                          } else {
                              $scope.fields[e] = false
                          }
                      })
                  } else if ($scope.field_list_notif === true) {
                      $scope.all = true
                  } else if ($scope.field_list_notif === false) {
                      $scope.nothing = true
                  }*/
            } else {
                console.log('error')
            }
        }, function (err) {
            console.log(err)
        });

        $scope.getSelectedFields = function (status) {
            $scope.all = false;
            $scope.nothing = false;
            $scope.selectedStatus.key = status;
            if ($scope.selectedStatus.status.length > 0) {
                $scope.selectedStatus.all = false
            } else {
                $scope.selectedStatus.all = true
            }


            keys = '';
            for (var key in $scope.fields) {
                if (!isFinite(key)) {
                    if (keys === '') {
                        keys += key;
                    } else {
                        keys += " " + key;
                    }
                }
            }
            //console.log('$scope.fields',$scope.fields)
        };
        $scope.getSelectedStatus = function (statusList) {
            $scope.selectedStatus.exception = false
            isSetting[isfieldName].update = 'status'


        }
        $scope.getSelectedStatusList = function (statusList) {


            statusKeys = statusList


        }



        $scope.getNothing = function () {

            $scope.all = false;
            $scope.selectedStatus.key.value = false;
            $scope.fields[0].value = false;
            $scope.selectedStatus.all = false;
            statusKeys = []
        }
        $scope.getAll = function () {
            // console.log('$scope.selectedStatus.all', $scope.all)
            $scope.nothing = false;
            $scope.selectedStatus.key.value = false;
            $scope.fields[0].value = false;
            $scope.selectedStatus.all = false
            statusKeys = []
        }







        $scope.confirm = function () {

            if ($scope.all) {
                isSetting[isfieldName].update = true;

                $scope.closeThisDialog(true)
            } else if ($scope.selectedStatus.all) {

                isSetting[isfieldName].update = 'status'
                $scope.closeThisDialog(isSetting[isfieldName].update);

            } else if (statusKeys && statusKeys !== '' && statusKeys.length > 0 && $scope.selectedStatus.exception == true) {

                          if (statusKeys.length > 0) {
                    isSetting[isfieldName].update = {
                        status: statusKeys
                    }
                }
                $scope.closeThisDialog(isSetting[isfieldName].update);

            } else if ($scope.nothing) {

                isSetting[isfieldName].update = false
                $scope.closeThisDialog(isSetting[isfieldName].update);
            } else {
                $scope.closeThisDialog();
            }






        }
    } catch (e) {
        console.error(e);
    }



    $scope.selectStatusEx = function (exStatus) {
        $scope.selectedStatus.all = false;

        if (exStatus == true) {

            $scope.selectedStatus.status = []
        }

    }
});