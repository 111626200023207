angular.module('app.directives').directive('statusHisto', function ($rootScope, $compile, $timeout) {
    return {
        template: "<a href class='dfpopo'  ng-click='showHisto()' data-toggle='popover' data-placement: 'left'  bs-popover  data-original-title='{{\"STATUS_HISTORY\" | translate}}' data-content='{{content}}'><i class='fa fa-history'></i></a>",
        restrict: 'AE',
        scope: {
            histoList: '='
        },
        link: function (scope, element, attrs) {
            // console.log(scope.histoList)
            var htmlData = '<div class=" direcstat">';
            htmlData += '<div class="comments" ng-repeat="histo in histoList |  orderBy:\'-date\'">';

            htmlData += '<span class="spdir">{{$index + 1}}</span><div   style="padding-right: 0;">\n' +
                '                                        <div class="card comment">\n' +
                '                                            <div class="card-body comcrd">\n' +
                '                                                <p>\n' +
                '                                                    {{histo.old_status | translate}} <i class="fa fa-arrow-right"> </i> {{histo.new_status | translate}}\n' +
                '                                                </p> <hr>\n' +
                '<div class="usedir" ng-if="histo._modified_by.login"> <i class="fa fa-user"></i>{{histo._modified_by.first_name }} {{histo._modified_by.last_name }} ({{histo._modified_by.login }})</div>\n' +
                '                                                <small class="comdir" class="text-muted" title="{{comment.date | date:\'yyyy-MM-dd HH:mm:ss\'}}">\n' +
                '                                   <i class="fa fa-calendar"> </i>                 {{histo.date | date:\'yyyy-MM-dd\'}} <i class="fa fa-clock-o"></i>\n' +
                '                                                    {{histo.date | date:\'HH:mm:ss\'}}    </small><br>    \n' +
                '                                            </div> \n' +
                '                                        </div>\n' +
                '                                    </div> </div><br></div>';

            var el = $compile(htmlData)(scope);
            $timeout(function () {

                scope.content = el.html()
            });

        }
    };
});