angular.module('app').controller("addClientFormCtrl", function ($scope, request, constantSrv, I3InstallationGroupSrv, Notification, $translate) {

    $scope.phases = constantSrv.getPhase();

    if(request){
        $scope.installationRequest = request
    }


    $scope.add=function(installRequest){
        var install = angular.copy(installRequest);
        if(request && request._id){
            if($scope.installationRequest){
                install.client_id=installRequest.client_id._id;
                install.client_name=installRequest.client_id.name;
            }
            I3InstallationGroupSrv.updateById($scope.installationRequest._id,install,function(res){
                if(res){
                    Notification.success($translate.instant("INSTALLATION_GROUP_UPDATED"));
                    $scope.closeThisDialog(res)
                }
            },function(err){
            })

        }else{
            install.phase = 'InProcpection';
            install.status = 'Created';
            I3InstallationGroupSrv.add(install,function(res){
                if(res){
                    Notification.success($translate.instant("INSTALLATION_GROUP_ADDED"));
                    $scope.closeThisDialog(res)
                }
            },function(err){
            })
        }

    };

});