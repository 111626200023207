angular.module('app').controller('installationDetailsCtrl', function ($rootScope, $scope, i3HelperSrv, I3DocumentSrv, I3InstallationGroupSrv, I3UserSrv, ngDialog, Notification, $state, $stateParams, messageBox, constantSrv, $translate, reportingSrv) {
    //#region scopes
    var installationToEdit = null;
    var now = moment().clone();
    $scope.dayRes = now;
    $scope.pageSize = 25;
    $scope.selectedPeriod = 'day';
    $scope.emailFollow = "";
    $scope.user = "";
    $scope.com = [];
    $scope.com3 = [];
    $scope.statusInstall = [];
    $scope.liststatusavailInstallation = [];
    $scope.installationsStatus = [];
    $scope.come = false;
    $scope.show_ui = false;
    $scope.val = false;
    $scope.loading = false;
    $scope.selected = null;
    $scope.previous = null;
    $scope.old_comments = 0;
    $scope.package = {};
    $scope.PLANNED_Count = 0;
    $scope.INSTALLED_Count = 0;
    $scope.COMMISSIONING_Count = 0;
    $scope.SUSPENDED_Count = 0;
    $scope.NEW_Count = 0;
    $scope.selectedIndex = 0;
    $scope.currentPage = 0;
    $scope.pageSize = 25;
    $scope.current_asset = {};
    $scope.numberOfPages = 1;
    $scope.disab_input_status = ["COMMISSIONING"];
    $scope.filterParams = {
        NEW: true,
        PLANNED: true,
        INSTALLED: true,
        COMMISSIONING: true,
        SUSPENDED: true
    };

    $scope.timeleft = 0;
    $scope.now = moment()
    $scope.redv = 0;

    $scope.getvalue = function (deadline, deadline_period) {
        if (deadline && deadline_period) {
            var deadline_start = moment(deadline).subtract(deadline_period, 'h');
          //  console.log(deadline_start)
            var date2 = moment(deadline);
            var date1 = moment();
            $scope.date_limite = date2;
            var differenceInMs = date2.diff(date1); // diff yields milliseconds
            var duration = moment.duration(differenceInMs);
            var differenceInHours = Math.floor(duration.asHours());

            if (-differenceInHours >= 1000) {
                $scope.timeleft = 1000
            } else
                $scope.timeleft = -differenceInHours;
            var redv = Math.floor(100 - ((differenceInHours / deadline_period) * 100))
            if (redv >= 100) redv = 100
            console.log(redv)
            return redv
        }
    }
    //#endregion scopes
    //#region initial data
    I3UserSrv.getById($rootScope.SESSION.user._id, function (response) {
        $scope.user = response.result.login;
    }, function (err) {
        console.log(err);
    });
    $scope.assetStatusInstall = constantSrv.getAssetStatusInstall();
    $scope.getAssetStatus = function (state) {
        return constantSrv.getAssetStatusInstallByState(state)
    };
    constantSrv.getModel("INSTALLATION_REQUEST").getAllStatus(function (err, status) {
        $scope.installationsStatus = status.map(function (val) {
            return val.name
        })
    });
    /*constantSrv.getModel("INSTALLATION").getAllStatus(function (err, status) {
        $scope.statusInstall = status.map(function (val) {
            return val.name
        })
    });*/

    $scope.getInstallation = function () {
        I3InstallationGroupSrv.query({
            query: {
                _id: $stateParams.id
            },
            options: {
                populate: [{
                        path: '_plateforme',

                    }, {
                        path: 'assets.place_id',
                        select: 'name'
                    }, {
                        path: 'assets.comments._user',
                        select: 'login'
                    }, {
                        path: "docs assets.docs"
                    },
                    {
                        path: "comments.owner",
                        select: 'login'
                    }, {
                        path: "_created_by",
                        // select: 'login'
                    },
                    {
                        path: 'status_histo._modified_by',
                        select: "first_name  last_name login"
                    }
                ]
            }
        }, 1, 0, function (res) {
            if (res && res.success && res.result.length > 0) {
                $scope.installation = res.result[0];
                $scope.redv = $scope.getvalue($scope.installation.dead_line, $scope.installation.dead_line_period);
                if (!$scope.installation.client_id && ($scope.installation.status === 'DONE' || $scope.installation.status === 'READY')) {
                    $scope.verify();
                }
                $scope.test_table();
                $scope.getFiles($scope.installation.docs);
                $scope.old_comments = $scope.installation.comments.length;
                if (!$scope.installation.comments || $scope.installation.comments.length <= 0)
                    $scope.installation.comments = [{
                        owner: $rootScope.SESSION.user._id
                    }];

                $scope.installation.save_state = 'saved';
                $scope.reset_comp = angular.copy($scope.installation);
                installationToEdit = angular.copy($scope.installation);

                constantSrv.getModel("INSTALLATION_REQUEST").getAvailableStatusByStatus(installationToEdit.status, function (err, val) {
                    $scope.liststatusavailInstallation = val._availStatus.map(function (elem) {
                        return elem.name
                    })
                });
            } else
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("FIND_BY_ID_ERROR")
                });
        }, function (err) {
            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("FIND_BY_ID_ERROR")
            });
        });
    }();

    $scope.equal0 = function (currentValue) {
        return currentValue < 1;
    }
    $scope.test_table = function () {
        //console.log($scope.installation)
        var arr = [];
        angular.forEach($scope.installation.assets, function (asset) {
            asset.new_status = asset.status;
            angular.forEach(asset.req_options, function (value, key) {
                arr.push(value);
            });
            if (arr.every($scope.equal0))
                asset.is0 = true;
            else
                asset.is0 = false;
            arr = [];
        });
    };
    $scope.isavailable = function (status) {
        if ($scope.liststatusavailInstallation.indexOf(status) != -1 && installationToEdit.status != status) {
            return true
        } else
            return false
    };
    $scope.verify = function () {
        var modal = ngDialog.open({
            controller: "ClientEdSyncDlgCtrl",
            templateUrl: "app/commons/dialogs/client/client.ed.sync.dlg.tpl.html",
            className: 'ngdialog-theme-default custom-width',
            scope: $scope,
            width: '900px',
            showClose: false,
            closeByEscape: false,
            closeByNavigation: true,
            closeByDocument: false,
            resolve: {
                installation: function () {
                    return $scope.installation;
                }
            }
        });
        modal.closePromise.then(function (data) {
            $scope.installation.client_id = data.value._id;
            $scope.installation.client_name = data.value.name;
            $scope.installation.code_client = data.value.client_code;
            I3InstallationGroupSrv.updateById($scope.installation._id, $scope.installation, function (response) {
                if (response.success) {
                    Notification.success({
                        title: $translate.instant("SYNCHRONIZED_CLIENT"),
                        message: $translate.instant("SYNCHRONIZED_CLIENT_SUCC")
                    });
                } else {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("ERROR_OCCURED_UPDATE") + "!"
                    });
                }
            }, function (err) {
                var msg = "";
                if (err.error.errorString) {
                    msg = err.error.errorString;
                }
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: msg
                });
            })
        }, function () {});
    }
    //#endregion initial data
    //#region assetslide and filters
    //set currentPage to next
    $scope.nextpage = function () {
        $scope.selectedIndex = 0;
        $scope.currentPage = $scope.currentPage + 1;
    };
    //set currentPage to previous
    $scope.previouspage = function () {
        $scope.selectedIndex = 0;
        $scope.currentPage = $scope.currentPage - 1;
    };
    //reset current page and selectedIndex after search
    $scope.resetindex = function () {
        if ($scope.namev && $scope.namev.trim() != '') {
            $scope.currentPage = 0;
            $scope.selectedIndex = 0;
        }
    };
    //watch for asset results change for asset copy
    $scope.$watch('limited_fname_fstatus_asset[selectedIndex]', function (newVal, oldVal) {
        if ($scope.limited_fname_fstatus_asset && $scope.limited_fname_fstatus_asset[$scope.selectedIndex]) {
            //console.log(newVal);
            $scope.current_asset = angular.copy(newVal);
            $scope.reset_asset = angular.copy(newVal);
        }
    });
    //watch for asset results change for showing the progress bar
    $scope.$watch('fname_fstatus_asset.length', function (newVal, oldVal) {
        //$scope.loading = true;
        if ($scope.fname_fstatus_asset) {
            $scope.numberOfPages = Math.floor(((newVal - 1) / $scope.pageSize));
            console.log("TCL: $scope.numberOfPages", $scope.numberOfPages)
        } else {
            $scope.numberOfPages = 1;
        }
        /* $timeout(function () {}, 2000).then(function () {
            $scope.loading = false;
        }); */
    });
    //can't disable all asset status and reset current page and selectedIndex after availables status change
    $(":checkbox").click(function () {
        $scope.currentPage = 0;
        $scope.selectedIndex = 0;
        if ($("input:checked").length == 1) {
            $(':checked').prop('disabled', true);
        } else {
            $(':checked').prop('disabled', false);
        }
    });
    //filter asset by name
    $scope.name_filter = function (asset) {
        if ($scope.namev && $scope.namev.trim() != '')
            return (asset.name.toLowerCase().indexOf($scope.namev.toLowerCase()) > -1)
        else
            return (true)
    };
    //filter assets (with a searched term) by status
    $scope.filterStatus = function (item, index, array) {
        var status = "NEW";
        if (item.status) {
            status = item.status;
        }
        return ($scope.filterParams.NEW && $scope.fname_asset[index].status == "NEW" || $scope.filterParams.PLANNED && $scope.fname_asset[index].status == "PLANNED" || $scope.filterParams.INSTALLED && $scope.fname_asset[index].status == "INSTALLED" || $scope.filterParams.COMMISSIONING && $scope.fname_asset[index].status == "COMMISSIONING" || $scope.filterParams.SUSPENDED && $scope.fname_asset[index].status == "SUSPENDED");
    };
    // get the length of filtered assets (with a searched term) by the status NEW
    $scope.quertstatus_new = function (item, index, array) {
        return $scope.fname_asset[index].status == "NEW"
    };
    // get the length of filtered assets (with a searched term) by the status VALIDATED
    $scope.quertstatus_pln = function (item, index, array) {
        return $scope.fname_asset[index].status == "PLANNED"
    };
    $scope.quertstatus_ins = function (item, index, array) {
        return $scope.fname_asset[index].status == "INSTALLED"
    };
    $scope.quertstatus_cms = function (item, index, array) {
        return $scope.fname_asset[index].status == "COMMISSIONING"
    };
    $scope.quertstatus_spe = function (item, index, array) {
        return $scope.fname_asset[index].status == "SUSPENDED"
    };
    $scope.quertstatus_new_2 = function (item, index, array) {
        return $scope.limited_fname_fstatus_asset[index].status == "NEW"
    };
    $scope.quertstatus_pln_2 = function (item, index, array) {
        return $scope.limited_fname_fstatus_asset[index].status == "PLANNED"
    };
    $scope.quertstatus_ins_2 = function (item, index, array) {
        return $scope.limited_fname_fstatus_asset[index].status == "INSTALLED"
    };
    $scope.quertstatus_cms_2 = function (item, index, array) {
        return $scope.limited_fname_fstatus_asset[index].status == "COMMISSIONING"
    };
    $scope.quertstatus_spe_2 = function (item, index, array) {
        return $scope.limited_fname_fstatus_asset[index].status == "SUSPENDED"
    };
    //#endregion assetslide and filters
    //#region update data
    //changed  state to unsaved
    $scope.change_state = function (asset) {
        asset.save_state = 'unsaved';
        $scope.current_asset.save_state = 'unsaved';
    }
    //reset asset
    $scope.cancel = function (asset) {
        var index = $scope.installation.assets.indexOf(asset);
        $scope.installation.assets[index] = angular.copy($scope.reset_asset);
        $scope.current_asset = angular.copy($scope.reset_asset);
        $scope.installation.assets[index].new_status = asset.status;
        $scope.content = "";
    };
    $scope.copyreq = function (asset) {
        asset.res_options = angular.copy(asset.req_options);
    };
    $scope.changeInstallationStatus = function (status) {
        if ($scope.liststatusavailInstallation.indexOf(status) != -1 && installationToEdit.status != status) {
            messageBox.confirm("UPDATE", "UPDATE_INSTALLATION_STATUS").then(function (res) {
                if (res) {
                    $scope.installation.status = status
                    installationToEdit.status = status;
                    delete installationToEdit.__v;
                    I3InstallationGroupSrv.updateById(installationToEdit._id, installationToEdit, function (response) {
                        if (response.success) {
                            delete installationToEdit.__v;
                            Notification.success({
                                title: $translate.instant("INSTALLATION_UPDATED_TITLE"),
                                message: $translate.instant("INSTALLATION_UPDATED_SUC")
                            });
                        } else {
                            Notification.error({
                                title: $translate.instant('ERROR'),
                                message: $translate.instant("ERROR_OCCURED_UPDATE")
                            });
                        }
                    }, function (err) {
                        var msg = "";
                        if (err.error.errorString) {
                            msg = err.error.errorString;
                        }
                        Notification.error({
                            title: $translate.instant('ERROR'),
                            message: msg
                        });
                    })
                }
            }, function (reject) {})
        }
    };

    $scope.change_comp_state = function () {
        console.log("$scope.installation", $scope.installation)
        console.log("installationToEdit", installationToEdit)
        $scope.installation.save_state = 'unsaved';
        installationToEdit.save_state = 'unsaved';

    };
    $scope.resetInstallation = function () {
        $scope.installation.comments = angular.copy($scope.reset_comp.comments);
        $scope.installation.docs = angular.copy($scope.reset_comp.docs);
        $scope.installation.followers = angular.copy($scope.reset_comp.followers);
        $scope.installation.save_state = 'saved';
    }
    $scope.updateInstallation = function () {
        installationToEdit.comments = angular.copy($scope.installation.comments);
        installationToEdit.docs = angular.copy($scope.installation.docs);
        installationToEdit.followers = angular.copy($scope.installation.followers);
        $scope.installation.save_state = 'saved';
        installationToEdit.save_state = 'saved';
        delete $scope.installation.__v;
        delete installationToEdit.__v;
        $scope.reset_comp = angular.copy(installationToEdit);
        /* if (installationToEdit.comments[0].msg && installationToEdit.comments[0].msg.length > 0) {} else {
            delete installationToEdit.comments;
        } */
        angular.forEach($scope.documents, function (file) {
            installationToEdit.docs.push(file._id);
        });
        console.log("TCL: $scope.updateInstallation -> installationToEdit.comments", installationToEdit)
        I3InstallationGroupSrv.updateById(installationToEdit._id, installationToEdit, function (res) {
                if (res && res.success) {
                    Notification.success({
                        title: $translate.instant("UPDATE_SUCC"),
                        message: $translate.instant("INSTALLATION_UPDATED_SUC")
                    });
                } else {
                    Notification.error({
                        title: $translate.instant('ERROR'),
                        message: $translate.instant("INSTALLATION_UPDATE_ERROR")
                    });
                }
            },
            function (err) {
                console.log(err)
            })
    };
    $scope.updateInstallationAsset = function (asset) {
        var index = $scope.installation.assets.indexOf(asset);
        asset.status = asset.new_status;
        if (installationToEdit) {
            delete installationToEdit.__v;
            installationToEdit.assets[index] = angular.copy(asset);
            I3InstallationGroupSrv.updateById($scope.installation._id, installationToEdit, function (res) {
                //console.log("TCL: $scope.updateInstallationAsset -> installationToEdit", installationToEdit)
                if (res && res.success) {
                    asset.save_state = 'saved';
                    $scope.current_asset = angular.copy(asset);
                    delete installationToEdit.__v;
                    Notification.success({
                        title: $translate.instant("UPDATE_SUCC"),
                        message: $translate.instant("ASSET_UPDATED_SUCC")
                    });
                    I3InstallationGroupSrv.query({
                        query: {
                            _id: $stateParams.id
                        },
                        options: {
                            populate: [{
                                path: "assets.comments._user",
                                select: 'login'
                            }]
                        }
                    }, 1, 0, function (res) {
                        if (res && res.success && res.result.length > 0) {
                            $scope.installation.assets[index].comments = res.result[0].assets[index].comments;
                        }
                    })
                } else {
                    //console.log("res ", res)
                    Notification.error({
                        title: $translate.instant("UPDATE_ERROR"),
                        message: $translate.instant("ASSET_UPDATED_ERR")
                    });
                }
            }, function (err) {})
        }
    };
    //#endregion update data
    //#region utility
    $scope.export = function (fileType) {
        var info = {
            date: ($scope.installationRequest && $scope.installationRequest.begin_dt ? $scope.installationRequest.begin_dt : ""),
            company: ($scope.installationRequest && $scope.installationRequest.client_name ? $scope.installationRequest.client_name : ""),
            telFax: ($scope.installationRequest && $scope.installationRequest.contact && $scope.installationRequest.contact.fax ? $scope.installationRequest.contact.fax : ""),
            mail: ($scope.installationRequest && $scope.installationRequest.contact && $scope.installationRequest.contact.email ? $scope.installationRequest.contact.email : ""),
            contact: ($scope.installationRequest && $scope.installationRequest.contact && $scope.installationRequest.contact.name ? $scope.installationRequest.contact.name : ""),
            tel: ($scope.installationRequest && $scope.installationRequest.contact && $scope.installationRequest.contact.phone ? $scope.installationRequest.contact.phone : ""),
            adressInstal: "Adresses multiples",
            data: true
        }
        data = [];
        $scope.installationRequest.assets.forEach(function (val) {
            data.push({
                "marque": val.brand,
                "model": val.model,
                "imm": val.name,
                "device_type": val.device_type,
                "_package": val._package.name
            })
        })
        var ds = {
            name: "pvMultiinstall",
            data: {
                info: info,
                data: data,
                fileName: "PV_install " + val.name + " " + moment().format("YYY-MM-DD")
            },

        }
        if (fileType == "XLSX") {
            reportingSrv.generateExcel("templatePVinstallMulti.mrt", ds, function () {}, function () {
                Notification.error("L'export a échoué ");
            })
        } else if (fileType == "DOCX") {
            reportingSrv.generateWord("templatePVinstallMulti.mrt", ds, function () {}, function () {
                Notification.error("L'export a échoué ");
            })
        } else if (fileType == "preview") {
            //console.log("preview")
            reportingSrv.preview("templatePVinstallMulti.mrt", ds, function () {}, function () {
                Notification.error("La prévisualisation  a échoué ");
            })
        } else {
            reportingSrv.generatePdf("templatePVinstallMulti.mrt", ds, function () {}, function () {
                Notification.error("L'export a échoué ");
            })
        }
    }

    $scope.getcolor = function (x) {
        if (x) {
            return {
                'background-color': '#ffc8d2'
            };
        }
    }

    $scope.downloadFile = function (file) {
        i3HelperSrv.downloadFile(file._id, file.original_name, function success(res) {}, function error(err) {
            Notification.error($translate.instant(err));
        }, function progress(evt) {
            //console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '%');
        })
    };
    //#endregion utility
    //#region comments
    $scope.addComment = function (content, asset) {
        if (content) {
            var commentAdded = {};
            commentAdded.date = new Date();
            commentAdded._user = $rootScope.SESSION.user._id;
            commentAdded.comment = content;
            commentAdded.new = true;
            asset.comments.push(commentAdded);
            $scope.content = "";
            asset.save_state = 'unsaved';
            $scope.current_asset.save_state = 'unsaved';
        }
    };
    $scope.addComment2 = function (msg) {
        if (msg) {
            var commentAdded = {};
            commentAdded.date = new Date();
            commentAdded.owner = $rootScope.SESSION.user._id;
            commentAdded.msg = msg;
            commentAdded.new = true;
            $scope.installation.comments.push(commentAdded);
            $scope.content2 = "";
            $scope.change_comp_state();
        }

    };
    $scope.show_com = function (x, y) {
        if (y > 0) {
            $scope.com[x] = !$scope.com[x];
        }
    };
    $scope.show_com3 = function (x, y) {
        console.log("x", x)
        console.log("y", y)
        if (y > 0) {
            $scope.com3[x] = !$scope.com3[x];
        }
        console.log("$scope.com3[x] ", $scope.com3[x])
    };
    $scope.show_come = function (y) {
        if (y > 0) {
            $scope.come = !$scope.come;
        }
    };
    $scope.show_come10 = function (y) {

        if (y > 0) {
            $scope.come10 = !$scope.come10;
        }
    };
    $scope.show_come20 = function () {

        $scope.come20 = !$scope.come20;

    };
    $scope.comp_d = function (comment) {
        if (comment.new) {
            return true
        } else return false
    };
    $scope.deleteComment = function (index) {
        $scope.installation.comments.splice(index, 1);
        $scope.change_comp_state();
    };
    $scope.deleteAssetComment = function (index, asset) {
        asset.comments.splice(index, 1);
        asset.save_state = 'unsaved';
        $scope.current_asset.save_state = 'unsaved';
    };
    //#endregion comments
    //#region photos
    //show front photo
    $scope.afficher_front = function (x) {
        var modal = document.getElementById('myModal');
        var img = document.getElementById('imga_' + x);
        var modalImg = document.getElementById("img01");
        var captionText = document.getElementById("caption");
        var span = document.getElementsByClassName("close")[0];
        modal.style.display = "block";
        //  modalImg.src = x.dataURL;

        modalImg.src = $scope.getImageUrl(x)
        captionText.innerHTML = $translate.instant("FRONT_PIC");
    };
    //show back photo
    $scope.afficher_back = function (x) {
        var modal = document.getElementById('myModal');
        var img = document.getElementById('imgb_' + x);
        var modalImg = document.getElementById("img01");
        var captionText = document.getElementById("caption");
        var span = document.getElementsByClassName("close")[0];
        modal.style.display = "block";
        // modalImg.src = x.dataURL;
        modalImg.src = $scope.getImageUrl(x)
        captionText.innerHTML = $translate.instant("BACK_PIC");
    };
    //close photo modal
    $scope.close = function () {
        var modal = document.getElementById('myModal');
        modal.style.display = "none";
    };
    //#endregion photos
    //#region Files
    $scope.getFiles = function (files) {
        I3DocumentSrv.query({
            query: {
                _id: {
                    '$in': files
                }
            }
        }, 10000, 1, function (res) {
            if (res.success) {
                $scope.docs = res.result;
            }
        }, function (err) {
            console.log(err);
        });
    };
    $scope.deleteAssetFiles = function (asset, index) {
        messageBox.confirm("DELETE_FILE_ASSET_CONFIRM_TITLE", "DELETE_FILE_ASSET_CONFIRM_MESSSAGE").then(function (res) {
            console.log(res)
            if (res == true) {
                asset.docs.splice(index, 1);
                asset.save_state = 'unsaved';
                $scope.current_asset = angular.copy(asset);
            }
        })

    };
    $scope.uploadAssetFiles = function (files, errFiles, x, asset) {
        var index = $scope.installation.assets.indexOf(asset);
        $scope.files = files;
        $scope.errFiles = errFiles;
        i3HelperSrv.uploadFiles(files, {
            _plateforme: $scope.installation._plateforme && $scope.installation._plateforme._id ? $scope.installation._plateforme._id : $scope.installation._plateforme,
            related_model: [{
                model: "INSTALLATION",
                model_id: $scope.installation._id
            }]
        }, function (res) {
            //console.log(res)
            angular.forEach(res.result, function (file) {
                //console.log(file)
                $scope.installation.assets[index].docs.push(file);

            });
            asset.save_state = 'unsaved';
            $scope.current_asset.save_state = 'unsaved';
        }, function (response) {
            if (response.status)
                $scope.errorMsg = response.status + ': ' + response.data;
        }, function (evt) {});
    };

    $scope.downloadFile = function (file) {
        i3HelperSrv.downloadFile(file._id, file.original_name, function success(res) {}, function error(err) {
            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant(err)
            });
        }, function progress(evt) {
            console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '%');
        })
    };
    //#endregion Files
    //#region followers
    $scope.follow = function () {
        if ($scope.emailFollow && $scope.emailFollow !== "") {
            var follower = {};
            if (!$scope.installation.followers) {
                $scope.installation.followers = [];
            }
            follower.email = $scope.emailFollow;
            var query = {
                "contact.mail": $scope.emailFollow
            };
            var options = {};
            var exist = false;
            angular.forEach($scope.installation.followers, function (follower) {
                if (follower.email.toLowerCase() === $scope.emailFollow.toLowerCase()) {
                    exist = true;
                }
            });
            if (!exist) {
                I3UserSrv.query({
                    query: query,
                    options: options
                }, 10000, 0, function (res) {
                    if (res.success) {
                        follower.user = res.result[0];
                    }
                    $scope.installation.followers.push(follower);
                    $scope.change_comp_state();
                    delete $scope.installation.__v;
                    $scope.emailFollow = "";
                });
            } else {
                Notification.warning({
                    title: $translate.instant("ALREADY_FOLLOWER_TITLE"),
                    message: $translate.instant("ALREADY_FOLLOWER_INS")
                });
                $scope.emailFollow = "";
            }
        }
    };
    $scope.removeFollower = function (follower) {
        if ($scope.installation.followers.indexOf(follower) >= 0) {
            $scope.installation.followers.splice($scope.installation.followers.indexOf(follower), 1);
            $scope.change_comp_state();
            delete $scope.installation.__v;
        }
    };
    //#endregion followers

    $scope.getImageUrl = function (path) {
        if (path) {

            if (path.dataURL) {
                path = path.dataURL
            }
            if (path.indexOf("data:image") >= 0) {
                return path;
            } else {
                return $rootScope.config.API_URI + "/document/registration_card/" + path + "?token=" + window.sessionStorage.token;
            }
        }
    }

    $scope.downloadZipGrouppedfile = function () {

        try {
            var params = {
                groups: {}
            }
            var comments = $translate.instant("INSTALLS") + "\r\n";
            comments += "\t" + $translate.instant("CLIENT") + " :" + $scope.installation.client_name + "\r\n";
            comments += "\t" + $translate.instant("CASE_NUMBER") + " :" + $scope.installation.case_number + "\r\n";
            comments += "\t" + $translate.instant("purchase_order_nbr") + " :" + $scope.installation.purchase_order + "\r\n";
            comments += "-------------------------------------------------------------------------\r\n";
            comments += "\t- " + $translate.instant("DOCUMENTS") + " :\r\n";

            if ($scope.installation.docs && $scope.installation.docs.length > 0) {
                params.groups = {
                    installation: []
                }
                $scope.installation.docs.forEach(function (doc) {
                    if (doc._id)
                        params.groups.installation.push(doc._id);
                })
                comments += "\t\t + installation : " + $translate.instant("DOCUMENTS") + " " + $translate.instant("Installation") + "\r\n";
            }
            $scope.installation.assets.forEach(function (asset) {
                var asset_tag = asset.name;
                if (asset.vin) {
                    asset_tag = asset.vin
                }
                params.groups[asset_tag] = [];
                comments += "\t\t + " + asset_tag + " : " + $translate.instant("DOCUMENTS") + " " + $translate.instant("ASSET") + " " + asset.name + "\r\n";
                if (asset.docs && asset.docs.length > 0) {
                    asset.docs.forEach(function (doc) {
                        if (doc._id)
                            params.groups[asset_tag].push(doc._id);
                    })

                }
                if (asset.gray_card_back && asset.gray_card_back.length > 0) {
                    params.groups[asset_tag].push(asset.gray_card_back);
                }
                if (asset.gray_card_front && asset.gray_card_front.length > 0) {
                    params.groups[asset_tag].push(asset.gray_card_front);
                }

            })
            params.comments = comments

            i3HelperSrv.downloadZip(params, "Installation_" + $scope.installation.case_number + "docs.zip", function (res) {
                console.log(res)

            }, function (err) {
                console.log(err)
            }, function (percent) {
                console.log(percent)
            })
        } catch (e) {

            console.error(e)
        }
    }

    $scope.downloadZipGrouppedfileASSET = function (asset) {

        var comments = $translate.instant("INSTALLS") + "\r\n";
        comments += "\t" + $translate.instant("CLIENT") + " :" + $scope.installation.client_name + "\r\n";
        comments += "\t" + $translate.instant("CASE_NUMBER") + " :" + $scope.installation.case_number + "\r\n";
        comments += "\t" + $translate.instant("purchase_order_nbr") + " :" + $scope.installation.purchase_order + "\r\n";
        comments += "\t" + $translate.instant("ASSET") + " :" + asset.name + "\r\n";
        try {
            var params = {
                _ids: []
            }
            if (asset.docs && asset.docs.length > 0) {

                asset.docs.forEach(function (doc) {
                    if (doc._id)
                        params._ids.push(doc._id);
                })
            }
            if (asset.gray_card_back && asset.gray_card_back.length > 0) {
                params._ids.push(asset.gray_card_back);
            }
            if (asset.gray_card_front && asset.gray_card_front.length > 0) {
                params._ids.push(asset.gray_card_front);
            }
            /*   $scope.installation.assets.forEach(function (asset) {
                  var asset_tag=asset.name;
                   if(asset.vin){
                       asset_tag=asset.vin
                   }
                  params.groups[asset_tag]=[];
                  if(asset.docs && asset.docs.length>0){
                      asset.docs.forEach(function (doc) {
                          if(doc._id)
                              params.groups[asset_tag].push(doc._id);
                      })
                      if(asset.gray_card_back && asset.gray_card_back.length>0){
                          params.groups[asset_tag].push(asset.gray_card_back);
                      }
                      if(asset.gray_card_front && asset.gray_card_front.length>0){
                          params.groups[asset_tag].push(asset.gray_card_front);
                      }

                  }

              })*/

            params.comments = comments
            i3HelperSrv.downloadZip(params, "Installation_" + $scope.installation.client_name + "_" + asset.name + "_docs.zip", function (res) {
                console.log(res)

            }, function (err) {
                console.log(err)
            }, function (percent) {
                console.log(percent)
            })
        } catch (e) {

            console.error(e)
        }
    }

    $scope.deleteFiles = function (index) {
        messageBox.confirm("DELETE_FILE_CONFIRM_TITLE", "DELETE_FILE_CONFIRM_MESSSAGE").then(function (res) {
            console.log(res)
            if (res == true) {
                $scope.installation.docs.splice(index, 1);
                $scope.change_comp_state();
            }
        })
    };
});