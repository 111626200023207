angular.module('app').controller("addInterventionDlgCtrl", function ($scope, $state, $stateParams, I3UserSrv, I3ClaimSrv, I3InterventionSrv, Notification, $translate,i3HelperSrv) {
    $scope.intervention = {};
    $scope.intervention.asset =[];
    // console.log($scope.assets)
    I3UserSrv.query({}, 10000, 1, function (res) {
        if (res.success) {
            $scope.users = res.result;
        }
    }, function (err) {
        console.log(err);
    });
    $scope.selectUser = function (user) {
        $scope.intervention._assigned_to = user
    }
    i3HelperSrv.getEnums(function (res) {
        $scope.intervention_type = res.INTERVENTION_TYPE.LIST ;

    }, function (err) {
        console.error(err)
    })
    $scope.save = function () {
       if($scope.intervention.asset && $scope.intervention.asset.length >0){
        $scope.intervention.asset = $scope.intervention.asset.map(function(val){

                return {
                    asset_id : val._id,
                    asset_name :val.name,
                    
                }



        })


       }



        var interv = angular.copy($scope.intervention);
        interv._claim = $scope.claim._id;
        if ($scope.intervention._assigned_to) {
            if ($scope.intervention._assigned_to.length > 0 && $scope.intervention._assigned_to[0]._id) {
                interv._assigned_to = [];
                angular.forEach($scope.intervention._assigned_to, function (user) {
                    interv._assigned_to.push(user._id);
                });
            } else {
                delete interv._assigned_to;
            }
        }
        interv.client_id= $scope.claim.client_id
        interv.client_name = $scope.claim.client_name
        interv._plateforme=$scope.claim._plateforme
        interv._client_code = $scope.claim._client_code || undefined
        I3InterventionSrv.add(interv, function (res) {
            if (res && res.success) {
                $scope.closeThisDialog(res.result);
                Notification.success({
                    message: $translate.instant("INTERVENTION_ADDED")
                });
            } else {
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("INTERVENTION_FAILED")
                });
            }
        }, function (err) {
            console.log(err);
        });
        //$state.go('app.admin.claims');
    }


    $scope.selectAsset = function (asset) {




    }
});