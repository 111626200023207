angular.module('app').controller('homeClaimDetailCtrl', function ($scope, $rootScope, $q, $state, $timeout, $stateParams, $window, $translate, iaxeedSocketSrv, geoServiceProxy, iaxeedSensorSrv, I3ClaimSrv, I3PlateformeSrv, ngDialog, Notification, iaxeedAssetSrv, plateformHelperSrv, I3UserSrv, I3InterventionSrv, constantSrv, I3DocumentSrv, i3HelperSrv, openProjectSrv) {

    $scope.claim = {};
    var claimToEdit = null;
    //$scope.status = constantSrv.getClaimsStatus();
    $scope.liststatusAvailClaim = []
    constantSrv.getModel("CLAIM").getAllStatus(function (err, status) {
        $scope.status = status.map(function (val) {
            return val.name
        })
    })

    $scope.emailFollow = "";
    $scope.come = false;
    $scope.com = [];
    $scope.com3 = [];
    $scope.show_com = function (x) {
        $scope.com[x] = !$scope.com[x];
    }
    $scope.show_com3 = function (x) {
        $scope.com3[x] = !$scope.com3[x];
    }
    $scope.show_come = function () {
        $scope.come = !$scope.come;
    }
    $scope.show_come10 = function () {
        $scope.come10 = !$scope.come10;
    }
    $scope.show_come20 = function () {
        $scope.come20 = !$scope.come20;
    }

    $scope.timeleft = 0;
    $scope.now = moment()
    $scope.redv = 0;

    $scope.getvalue = function (deadline, deadline_period) {
        if (deadline && deadline_period) {
            var deadline_start = moment(deadline).subtract(deadline_period, 'h');
           // console.log(deadline_start)
            var date2 = moment(deadline);
            var date1 = moment();
            $scope.date_limite = date2;
            var differenceInMs = date2.diff(date1); // diff yields milliseconds
            var duration = moment.duration(differenceInMs);
            var differenceInHours = Math.floor(duration.asHours());

            if (-differenceInHours >= 1000) {
                $scope.timeleft = 1000
            } else
                $scope.timeleft = -differenceInHours;
            var redv = Math.floor(100 - ((differenceInHours / deadline_period) * 100))
            if (redv >= 100) redv = 100
          //  console.log(redv)
            return redv
        }
    }

    /**
     * select and authenticate plateforme
     * @param platform
     */

    $scope.uploadFiles = function (files, errFiles) {
        $scope.files = files;
        $scope.errFiles = errFiles;
        //console.log("files ", files)
        i3HelperSrv.uploadFiles(files, {
            _plateforme: $scope.claim._plateforme && $scope.claim._plateforme._id ? $scope.claim._plateforme._id : $scope.claim._plateforme,
            related_model: [{
                model: "CLAIM",
                model_id: $scope.claim._id
            }]
        }, function (res) {
          //  console.log("res ", res)

            angular.forEach(res.result, function (file) {
             //   console.log("file ", file)

                $scope.claim.docs.push(file)
            });
            I3ClaimSrv.updateById($stateParams.claimId, {
                docs: $scope.claim.docs
            }, function (res) {
                if (res && res.success) {
                    Notification.success({
                        title: $translate.instant("SUCCESS"),
                        message: $translate.instant("CLAIM_UPDATED")
                    });
                } else {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("CLAIM_UPDATED_ERROR")
                    });
                }
            }, function (err) {

            })
            file.result = res.data;
        }, function (response) {
            if (response.status)
                $scope.errorMsg = response.status + ': ' + response.data;
        }, function (evt) {

        });
    };

    $scope.downloadFile = function (file) {
        i3HelperSrv.downloadFile(file._id, file.original_name, function success(res) {}, function error(err) {
            Notification.error({
                title: $translate.instant("ERROR"),
                message: $translate.instant(err)
            });
        }, function progress(evt) {
        //    console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '%');
        })
    };

    $scope.getInterventions = function (claim) {
        I3InterventionSrv.query({
            query: {
                _claim: {
                    '$in': claim
                }
            }
        }, 10000, 1, function (res) {
            if (res.success) {
                $scope.interventions = res.result;
               // console.log("TCL: $scope.getInterventions -> $scope.interventions", $scope.interventions)
            }
        }, function (err) {
            console.log(err);
        });
    };

    $scope.getClaims = function () {
        var query = {
            query: {
                _id: $stateParams.claimId
            },
            options: {
                populate: [{
                        path: '_created_by'
                    },
                    {
                        path: '_plateforme'
                    },
                    {
                        path: 'docs'
                    },
                    {
                        path: 'followers.user'
                    },
                    {
                        path: 'comments.owner',
                        select: 'login'
                    },
                    {
                        path: 'status_histo._modified_by',
                        select: "first_name  last_name login"
                    }
                ]
            }
        };
        I3ClaimSrv.query(query, 1000, 0, function (response) {
            $scope.claim = response.result[0];
            $scope.redv = $scope.getvalue($scope.claim.dead_line, $scope.claim.dead_line_period);
            claimToEdit = angular.copy($scope.claim)
            $scope.plateforme = $scope.claim._plateforme;
            if ($scope.plateforme.type == 'NGI_FLEET') {
                return
            }
            plateformHelperSrv.setPlateFormAndLogin($scope.plateforme, function (res) {
                    if (res.success) {
                        $scope.platformeLogin = res;
                        var assetIds = [];
                        angular.forEach($scope.claim.asset, function (asset) {
                            assetIds.push(asset.asset_id);
                        });
                        if ($scope.platformeLogin && $scope.platformeLogin.result && $scope.platformeLogin.result.token) {
                            var query = {
                                _id: {
                                    $in: assetIds
                                }
                            };
                        }
                        var options = {};
                        iaxeedAssetSrv.query({
                            query: query,
                            options: options
                        }, 10000, 1, function (res1) {
                            if (res1.result) {
                                $scope.assets = res1.result;
                            }
                        }, function (err) {
                            console.log(err);
                        });

                        constantSrv.getModel("CLAIM").getAvailableStatusByStatus(claimToEdit.status, function (err, val) {
                            $scope.liststatusAvailClaim = val._availStatus.map(function (elem) {
                                return elem.name
                            })
                        });

                    } else {
                        Notification.error({
                            title: $translate.instant("ERROR"),
                            message: $translate.instant("PLATFORM_CNX_ERR")
                        });
                        $scope.claim = {};

                    }
                },
                function (err) {
                    console.log(err)
                });

            if ($scope.claim.ticket && $rootScope.SESSION.user.oo_key) {
                openProjectSrv.getTaskById($scope.claim.ticket, function (res) {

                    if (res) {
                     //   console.log(res);
                        $scope.ticket = res;
                    } else {
                        Notification.error({

                            title: $translate.instant("ERROR"),
                            message: $translate.instant("CANNOT_GET_OPEN_PROJECT_TICKET", {
                                id: $scope.claim.ticket
                            })
                        });
                    }

                }, function (err) {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("CANNOT_GET_OPEN_PROJECT_TICKET_ERROR")
                    });
                })
            } else if ($scope.claim.ticket) {
                $scope.ticketUrl = $rootScope.config.OPEN_PROJECT_URI + 'work_packages/' + $scope.claim.ticket + '/activity';
            }
            $scope.getInterventions($scope.claim._id);
        }, function (err) {
            console.log(err);
        });
    };

    $scope.showRealtime = function (asset) {
        $scope.asset = asset;
        var dialog = ngDialog.open({
            controller: "realtimeDlgCtrl",
            templateUrl: "app/commons/dialogs/realtime/realtime.dlg.tpl.html",
            scope: $scope,
            className: 'ngdialog-theme-default custom-width'
        });

        dialog.closePromise.then(function () {
            iaxeedSocketSrv.closeListner('newMsg');
        });
    };

    /**
     * Following the current claim
     */
    $scope.follow = function () {
        if ($scope.emailFollow && $scope.emailFollow !== "") {
            var follower = {};
            if (!$scope.claim.followers) {
                $scope.claim.followers = [];
            }

            follower.email = $scope.emailFollow;

            var query = {
                "contact.mail": $scope.emailFollow
            };
            var options = {};

            var exist = false;

            angular.forEach($scope.claim.followers, function (follower) {
                if (follower.email.toLowerCase() === $scope.emailFollow.toLowerCase()) {
                    exist = true;
                }
            });

            if (!exist) {
                I3UserSrv.query({
                    query: query,
                    options: options
                }, 10000, 0, function (res) {
                    if (res.success) {
                        follower.user = res.result[0];
                    }

                    $scope.claim.followers.push(follower);

                    delete $scope.claim.__v;

                    I3ClaimSrv.updateById($scope.claim._id, $scope.claim, function (response) {
                        if (response.success) {
                            Notification.success({
                                title: "Following claim",
                                message: "The email address" + $scope.emailFollow + " is now following claim " + $scope.claim._plateforme.name
                            });

                            $scope.emailFollow = "";
                        } else {
                            Notification.error({
                                title: "Erreur",
                                message: $translate.instant("ERROR_OCCURED_UPDATE") + " " + $scope.claim._plateforme.name + "!"
                            });
                            $scope.claim = {};
                        }
                    }, function (err) {
                        var msg = "";
                        if (err.error.errorString) {
                            msg = err.error.errorString;
                        }
                        Notification.error({
                            title: $translate.instant("ERROR"),
                            message: msg
                        });
                    })
                });
            } else {
                Notification.warning({
                    title: "Already follower",
                    message: "This email is already subscribed as a follower here"
                });
                $scope.emailFollow = "";
            }
        }

    };

    /**
     * Remove follower
     */
    $scope.removeFollower = function (follower) {
        if ($scope.claim.followers.indexOf(follower) >= 0) {
            $scope.claim.followers.splice($scope.claim.followers.indexOf(follower), 1);
            delete $scope.claim.__v;

            I3ClaimSrv.updateById($scope.claim._id, $scope.claim, function (response) {
                if (response.success) {
                    Notification.success({
                        title: "Removing claim follower",
                        message: "The follower of claim " + $scope.claim._plateforme.name + " was removed successfully"
                    });
                } else {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("ERROR_OCCURED_UPDATE") + " " + $scope.claim._plateforme.name + "!"
                    });
                    $scope.claim = {};
                }
            }, function (err) {
                var msg = "";
                if (err.error.errorString) {
                    msg = err.error.errorString;
                }
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: msg
                });
            })
        }
    };

    /**
     * Getting claim
     */
    $scope.add = function (content) {
        if (content) {
            var commentAdded = {};
            commentAdded.date = new Date();
            commentAdded.owner = $rootScope.SESSION.user._id;
            commentAdded.msg = content;
            $scope.claim.comments.push(commentAdded);
            I3ClaimSrv.updateById($scope.claim._id, $scope.claim, function (response) {
                if (response.success) {
                    Notification.success($translate.instant("ALERT_COMMENT_ADDED"));
                    $scope.getClaims();
                }
            });
            $scope.content = "";
        }

    };
    /**
     * Save user
     */
    $scope.save = function () {
        angular.forEach($scope.documents, function (file) {
            if (!$scope.claim.docs) {
                $scope.claim.docs = [];
            }
            $scope.claim.docs.push(file._id);
        });
        I3ClaimSrv.updateById($scope.claim._id, $scope.claim, function (response) {
            if (response.success) {
                Notification.success({
                    title: "User updated",
                    message: "Claim " + $scope.claim._plateforme.name + " was updated successfully!"
                });
                $state.go('app.home.claims');
                $scope.claim = {};
            } else {
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("ERROR_OCCURED_UPDATE") + " " + $scope.claim._plateforme.name + "!"
                });
                $scope.claim = {};
            }
        }, function (err) {
            var msg = "";
            if (err.error.errorString) {
                msg = err.error.errorString;
            }
            Notification.error({
                title: $translate.instant("ERROR"),
                message: msg
            });
        })

    };

    /**
     * Cancel editing mode
     */
    $scope.cancel = function () {
        $scope.claim = {};
        $state.go('app.home.claims');
    };

    /**
     * Add intervention
     * @param claim
     */
    $scope.addIntervention = function (claim) {
        $scope.claim = claim;
        var modal = ngDialog.open({
            templateUrl: "app/commons/dialogs/confirm/add.intervention.dlg.tpl.html",
            controller: "addInterventionDlgCtrl",
            scope: $scope
        })
        modal.closePromise.then(function () {
            $scope.claim.status = "InIntervention";
            I3ClaimSrv.updateById($scope.claim._id, $scope.claim, function (response) {
                if (response.success) {

                } else {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("ERROR_OCCURED_UPDATE") + " " + $scope.claim._plateforme.name + "!"
                    });
                }
            }, function (err) {
                var msg = "";
                if (err.error.errorString) {
                    msg = err.error.errorString;
                }
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: msg
                });
            })
        });
    };
    $scope.addDevTicket = function () {
        var modal = ngDialog.open({
            template: 'app/commons/dialogs/openproject/task.add.dlg.tpl.html',
            controller: 'openProjectTaskAddDlgCtrl',
            scope: $scope

        });

        modal.closePromise.then(function (res) {
            console.log(res);
            if (res && res.value) {
                $scope.claim.ticket = res.value.id;
                $scope.ticket = res.value
            }
            $scope.claim.status = "InDevelopment";
            I3ClaimSrv.updateById($scope.claim._id, $scope.claim, function (response) {
                if (response.success) {

                } else {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("ERROR_OCCURED_UPDATE") + " " + $scope.claim._plateforme.name + "!"
                    });
                }
            }, function (err) {
                var msg = "";
                if (err.error.errorString) {
                    msg = err.error.errorString;
                }
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: msg
                });
            })

        });
    };
    $scope.getClaims();

    $scope.downloadFile = function (file) {
        i3HelperSrv.downloadFile(file._id, file.original_name, function success(res) {}, function error(err) {
            Notification.error({
                title: $translate.instant("ERROR"),
                message: $translate.instant(err)
            });
        }, function progress(evt) {
           // console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '%');
        })
    };

    $scope.isavailable = function (status) {
        if ($scope.liststatusAvailClaim.indexOf(status) != -1 && claimToEdit.status != status) {

            return true
        } else
            return false
    };

    $scope.downloadZipGrouppedfile = function () {

        try {
            var params = {
                _ids: []
            }

            var comments = $translate.instant("CLAIMS") + "\r\n";
            comments += "\t" + $translate.instant("CLIENT") + " :" + $scope.claim.client_name + "\r\n";
            comments += "\t" + $translate.instant("REFERENCE") + " :" + $scope.claim.ref + "\r\n";
            comments += "-------------------------------------------------------------------------\r\n";
            comments += "\t- " + $translate.instant("DOCUMENTS") + " :\r\n";

            if ($scope.claim.docs && $scope.claim.docs.length > 0) {
                // params.ids = {claim: []}
                $scope.claim.docs.forEach(function (doc) {
                    if (doc._id)
                        params._ids.push(doc._id);
                })
                comments += "\t\t + Réclamation : " + $translate.instant("DOCUMENTS") + " " + $translate.instant("CLAIMS") + "\r\n";
            }
            /* $scope.claim.assets.forEach(function (asset) {
                 var asset_tag=asset.name;
                  if(asset.vin){
                      asset_tag=asset.vin
                  }
                 params.groups[asset_tag]=[];
                 if(asset.docs && asset.docs.length>0){
                     asset.docs.forEach(function (doc) {
                         if(doc._id)
                             params.groups[asset_tag].push(doc._id);
                     })
                     if(asset.gray_card_back && asset.gray_card_back.length>0){
                         params.groups[asset_tag].push(asset.gray_card_back);
                     }
                     if(asset.gray_card_front && asset.gray_card_front.length>0){
                         params.groups[asset_tag].push(asset.gray_card_front);
                     }

                 }

             })*/
            params.comments = comments

            i3HelperSrv.downloadZip(params, "Réclamation_" + $scope.claim.client_name + '_' + $scope.claim.ref + "_docs.zip", function (res) {
                console.log(res)

            }, function (err) {
                console.log(err)
            }, function (percent) {
                console.log(percent)
            })
        } catch (e) {

            console.error(e)
        }
    }

});