angular.module("app.services").factory('dashboardSrv', function ($rootScope, $q, plateformHelperSrv, $http, I3PlateformeSrv, iaxeedMonitoringSrv, iaxeedAssetSrv, iaxeedCompanySrv, $translate) {

    var service = {};

    service.getDataClients = function (plat) {
        var plateform = undefined;
        var Serve = {}
        if ($rootScope.selectedPlatform && $rootScope.selectedPlatform.name !== "ALL_PLATEFORMES") {
            // console.log("ssssssssssssssssss")
            plateform = angular.copy($rootScope.selectedPlatform);
        } else if ($rootScope.SESSION.user._plateforme) {
            // console.log("lllllllllllllll")
            plateform = angular.copy($rootScope.SESSION.user._plateforme);
        } else if (plat) {
            // console.log("mmmmmmmmmmm")
            plateform = plat
        }

        var defer = $q.defer()
        var self = this

        // console.log('plateform', plateform);
        if (plateform != undefined && plateform._id) {

            plateformHelperSrv.setPlateFormAndLogin(plateform, function (res) {

                if (res) {

                    self.getAssetCount(plateform).then(function (asset) {

                        if (asset) {

                            self.assetCount = asset
                        }
                    })
                    self.getClientCount(plateform).then(function (client) {

                        if (client) {

                            self.clientCount = client
                        }
                    })

                    self.getAccountClients(plateform).then(function (Accountclient) {

                        if (Accountclient) {

                            self.Accountclient = Accountclient;
                            Serve.ACCOUNTS_CLIENTS = self.Accountclient;
                        }
                    })
                }

                var agg = [{
                    "$match": {
                        "status": "New"
                    }
                }, {
                    "$project": {

                        "status": "$ack",
                        "High": {
                            "$cond": [{
                                $eq: ['$priority', 2]
                            },
                                2,
                                ''

                            ]
                        },
                        "Medium": {
                            "$cond": [{
                                $eq: ['$priority', 1]
                            },
                                1,
                                ''

                            ]
                        },
                        "low": {
                            "$cond": [{
                                $eq: ['$priority', 0]
                            },
                                0,
                                ''

                            ]
                        },
                    }
                }, {

                    $group: {
                        _id: "$status",
                        "High": {
                            "$sum": {
                                "$cond": [{
                                    $eq: ['$High', 2]
                                },
                                    1,
                                    0

                                ]
                            }
                        },
                        "Medium": {
                            "$sum": {
                                "$cond": [{
                                    $eq: ['$Medium', 1]
                                },
                                    1,
                                    0

                                ]
                            }
                        },
                        "low": {
                            "$sum": {
                                "$cond": [{
                                    $eq: ['$low', 0]
                                },
                                    1,
                                    0

                                ]
                            }
                        }

                    }
                }];

                iaxeedMonitoringSrv.agg({
                    aggregate: agg
                }, 10000, 0, function (response) {

                    if (response && response.success) {

                        Serve.anomalies_null = {
                            High: 0,
                            low: 0,
                            Medium: 0,
                            total: 0
                        },
                            Serve.anomalies_new = {
                                High: 0,
                                low: 0,
                                Medium: 0,
                                total: 0
                            },
                            Serve.anomalies_treated = {
                                High: 0,
                                low: 0,
                                Medium: 0,
                                total: 0
                            },

                            response.result.forEach(function (val) {
                                if (val._id == null) {
                                    Serve.anomalies_null = val
                                    Serve.anomalies_null.total = val.High + val.low + val.Medium
                                }
                                if (val._id == "New") {
                                    Serve.anomalies_new = val
                                    Serve.anomalies_new.total = val.High + val.low + val.Medium
                                }

                                if (val._id == "Processed") {
                                    Serve.anomalies_treated = val;
                                    Serve.anomalies_treated.total = val.High + val.low + val.Medium
                                }
                            });
                        Serve.anomalies_open = {
                            _id: "New",
                            High: Serve.anomalies_new.High + Serve.anomalies_null.High,
                            low: Serve.anomalies_new.low + Serve.anomalies_null.low,
                            Medium: Serve.anomalies_new.Medium + Serve.anomalies_null.Medium,
                            total: Serve.anomalies_new.total + Serve.anomalies_null.total
                        }
                        var sum = Serve.anomalies_treated.total + Serve.anomalies_open.total;
                        Serve.anomalies_treated.av = parseFloat((Serve.anomalies_treated.total / sum * 100).toFixed(2));
                        Serve.STATS_CLIENTS = {
                            clientCount: self.clientCount,
                            assetCount: self.assetCount
                        }

                        self.getDashboard().then(function (dasc) {

                            var daschboard = {}
                            if (dasc) {

                                daschboard = Object.assign(dasc[0], dasc[1], dasc[2], dasc[3], dasc[4], dasc[5], dasc[6], dasc[7], dasc[8], dasc[9], Serve)

                                defer.resolve(daschboard)

                            }
                        })

                    } else {
                        Notification.error($translate.instant("SERVER_UNAVAILABLE"));
                    }
                }, function (err) {
                    if (err.status === -1) {
                        Notification.error($translate.instant("IMPOSSIBLE_CNX"));
                    } else if (err.status === 401) {
                        Notification.error({
                            title: $translate.instant("ERROR"),
                            message: $translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS")
                        });
                    } else {
                        Notification.error({
                            title: $translate.instant("ERROR"),
                            message: $translate.instant("LOADING_ERROR")
                        });
                    }
                })
            })

        } else {

            self.getDashboard().then(function (dasc) {

                var daschboard = {}
                if (dasc) {

                    daschboard = Object.assign(dasc[0], dasc[1], dasc[2], dasc[3], dasc[4], dasc[5], dasc[6], dasc[7], dasc[8], dasc[9])

                    defer.resolve(daschboard)

                }
            })

        }

        return defer.promise

    }

    service.getDashboard = function () {
        var defer = $q.defer();
        I3PlateformeSrv.getDashboard(function (response, error) {
            if (error) {
                defer.reject(error)
            } else {
                self.Dashboard = response.result
                defer.resolve(self.Dashboard)
            }
        })

        return defer.promise

    }
    service.getMonotoring = function () {
        var defer = $q.defer();

        I3PlateformeSrv.getMonotoring(function (response, error) {
            if (error) {
                defer.reject(error)
            } else {

                defer.resolve(response.result)
            }
        })

        return defer.promise

    }
    service.getAssetCount = function (platform) {

        var defer = $q.defer();
        var query = {};
        if (platform.dash && platform.dash._clients && platform.dash._clients.length > 0) {

            query = {
                query: {
                    _company_owner: {
                        $nin: platform.dash._clients.map(function (val) {
                            return val._id
                        })
                    }
                }
            }
        }
        iaxeedAssetSrv.query(query, 1, 0, function (res) {

            if (res && res.success) {
                defer.resolve(res.total_count);
            }
        }, function (err) {
            if (err.status === -1) {
                // console.log($translate.instant("IMPOSSIBLE_CNX"))
                //Notification.error($translate.instant("IMPOSSIBLE_CNX"));
            } else if (err.status === 401) {
                // console.log($translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS"))
                /* Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS")
                }); */
            } else {
                // console.log($translate.instant("LOADING_ERROR_ASSET_COUNT"))
                //Notification.error($translate.instant("LOADING_ERROR_ASSET_COUNT"));
            }
        })

        return defer.promise;
    };
    //client count
    service.getClientCount = function (platform) {
        var query = {};
        var defer = $q.defer();
        if (platform.dash && platform.dash._clients && platform.dash._clients.length > 0) {
            query = {
                query: {
                    _id: {
                        $nin: platform.dash._clients.map(function (val) {
                            return val._id
                        })
                    }
                }
            }
        }
        iaxeedCompanySrv.query(query, 1, 0, function (res) {
            if (res && res.success) {
                defer.resolve(res.total_count);
                //callback(null,res.total_count) ;
            }
        }, function (err) {
            if (err.status === -1) {
                //Notification.error($translate.instant("IMPOSSIBLE_CNX"));
                // console.log($translate.instant("IMPOSSIBLE_CNX"))
            } else if (err.status === 401) {
                // console.log($translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS"))
                /*  Notification.error({
                     title: $translate.instant("ERROR"),
                     message: $translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS")
                 }); */
            } else {
                // console.log($translate.instant("LOADING_ERROR_COMPANY"))
                //Notification.error($translate.instant("LOADING_ERROR_COMPANY"));
            }
        })
        return defer.promise;
    };

    service.getAccountClients = function (platform) {

        var defer = $q.defer();
        var self = this
        self.dash_ids = platform.dash._clients.map(function (val) {
            return val._id
        })

        var agg = [{
            $project: {
                _company_owner: "$_company_owner",
                asset: {
                    name: "$name",
                    gps_dt: "$rt.gps_dt"
                }
            }
        },
        {

            $group: {
                _id: "$_company_owner",
                assets: {
                    $push: "$asset"
                }
            }

        },
        {
            $lookup: {
                from: "company",
                localField: "_id",
                foreignField: "_id",
                as: "_company_owner"
            }
        },
        {
            $project: {
                "_company_owner": {
                    "$arrayElemAt": ["$_company_owner", 0]
                },
                assets: "$assets"
            }
        },
        {
            $project: {
                "_company_owner": {
                    "_id": "$_company_owner._id",
                    name: "$_company_owner.name",
                    expiration_dt: "$_company_owner.expiration_dt"
                },
                "assets": "$assets"
            }
        }
        ]
        iaxeedAssetSrv.agg({
            aggregate: agg
        }, 10000, 0, function (res) {

            self.assetCount = 0;
            if (res && res.success) {

                var result = res.result.sort(function (a, b) {
                    return b.assets.length - a.assets.length
                });

                // console.log('result:', result.length)
                var result2 = result.filter(function (client) {
                    return self.fdoun(self.dash_ids, result.length, client)

                })
                result2 = result2.map(function (val) {
                    self.assetCount += val.assets.length;
                    val.enabledAsset = [];
                    val.disabledAsset = [];
                    val.assets.forEach(function (asset) {
                        if (asset && asset.gps_dt) {

                            if (moment().add(-2, "days").diff(asset.gps_dt, "days") >= 0) {

                                val.disabledAsset.push(asset);
                            } else {
                                val.enabledAsset.push(asset);
                            }
                        } else {
                            val.disabledAsset.push(asset);
                        }
                    })
                    return val;
                })
                self.clients = result2;
                self.clientCount = result2.length;
                self.ACCOUNTS = {
                    enabledAsset: 0,
                    disabledAsset: 0,
                    clientCount: self.clientCount
                }

                self.clients.forEach(function (val) {

                    self.ACCOUNTS.enabledAsset += val.enabledAsset.length
                    self.ACCOUNTS.disabledAsset += val.disabledAsset.length

                })

                defer.resolve(self.ACCOUNTS)
            } else {

            }
        },
            function (err) {
                if (err.status === -1) {
                    Notification.error($translate.instant("IMPOSSIBLE_CNX"));
                } else if (err.status === 401) {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS")
                    });
                } else {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("LOADING_ERROR")
                    });
                }
            })
        return defer.promise;
    };

    service.fdoun = function (array1, length, client) {
        var self = this
        self.idx = -1;
        for (var i = 0; i < length; i++) {
            if (array1[i] == client._company_owner._id) {
                self.idx = i;
                break
            }

        }
        if (self.idx == -1)
            return true
        else
            return false
    }

    return service;
})