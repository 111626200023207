angular.module('app.dialogs').controller('confirmDeleteArticleDlgCtrl', function ($scope, $rootScope, ngDialog, I3ArticleSrv, Notification, $state, $translate) {

    try {
        $scope.confirm = function () {
            I3ArticleSrv.delete($scope.article._id, function (response) {
                if (response.success) {
                    //$scope.roles.splice($scope.roles.indexOf($scope.role), 1);
                    //$scope.loadStatus();
                    $scope.loadArticles()
                    Notification.success({
                        title: $translate.instant("ARTICLE_DELETED_TITLE"),
                        message: $translate.instant("ARTICLE_DELETED_SUC")
                    });

                    $scope.article = {};
                } else {
                    Notification.error({
                        title: $translate.instant('ERROR'),
                        message: $translate.instant("ARTICLE_DELETED_ERROR")
                    });
                    $scope.article = {};
                }
            }, function (err) {
                console.log(err);

                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ARTICLE_DELETED_ERROR")
                });

                $scope.article = {};
            });

            $scope.article = {};
            $scope.closeThisDialog();

        }
    }catch (e){
        console.error(e);
    }

});