/**
 * Created by Kais CHAIBI on 05/12/2017.
 */
angular.module('app').controller('installationRequestCtrl', function ($scope, I3InstallationGroupSrv, plateformHelperSrv, Notification, $state, messageBox, $translate, constantSrv, $stateParams) {

    //$scope.search={};
    $scope.itemsByPage = 50;
    $scope.tableStyle = 'list';
    $scope.statusLoaded = false;
    var i = 0;
    //  $scope.statusInstallRequest = constantSrv.getInstallRequestStatus(); if ($stateParams.filter)if ($stateParams.filter)

    constantSrv.getModel("INSTALLATION_REQUEST").getAllStatus(function (err, status) {
        if (status) {
            $scope.statusInstallRequest = status.map(function (val) {
                return val.name
            })

            $scope.statusLoaded = true;
        }

    })
    /*  constantSrv.getModel("INSTALLATION_REQUEST").getAllStatus(function (err, stat) {

         $scope.installRequestEditStatus = stat.map(function (val) {
             return val.name
         })
     }) */
    // $scope.installRequestEditStatus = constantSrv.getInstallRequestEditStatus();
    var compare = false;
    $scope.search = {}
    if ($stateParams.filter)
        $scope.search.statusInstallRequest = $stateParams.filter;
    /**
     * Change table style (list / tree)
     */

    $scope.changeTableStyle = function (style) {
        $scope.tableStyle = style;
    };

    $scope.toggleRequest = function (request) {
        request.expanded = !request.expanded;
    };

    $scope.showDetails = function (installationRequest) {
        $state.go("app.home.installRequest.details", {
            id: installationRequest._id
        });
    }

    $scope.getcol = function (status) {
        if (status !== "NEW") return "color:red;";
    }

    $scope.remove = function (installationRequest) {

        messageBox.confirm("DELETE", "DELETE_INSTALLATION_REQUEST_MESSAGE").then(function (res) {

            if (res) {
                I3InstallationGroupSrv.delete(installationRequest._id, function (res) {
                    if (res && res.success) {
                        Notification.success({
                                title: $translate.instant("DELETE_SUCC"),
                                message: $translate.instant("INSTALLATION_REQUEST_DELETED")
                            }

                        );
                        $scope.loadInstallationRequest();
                        if ($scope.stCtrl) {
                            $scope.stCtrl.pipe();
                        }
                    } else {
                        Notification.warn({
                            title: $translate.instant("DELETE_ERROR"),
                            message: $translate.instant("INSTALLATION_REQUEST_DELETE_ERROR")
                        });
                    }

                }, function (err) {
                    Notification.error({
                        title: $translate.instant("DELETE_ERROR"),
                        message: $translate.instant("INSTALLATION_REQUEST_DELETE_ERROR")
                    });
                })

            }

        }, function (reject) {
            //Notification.error($translate.instant("INSTALLATION_REQUEST_DELETE_ERROR"));
        });

    };

    $scope.loadInstallationRequest = function (tableState, ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }

        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;

        var query = {
            options: {
                columns: 'purchase_order client_name _plateforme  assets.status status creation_dt place_id assets.name',
                populate: '_plateforme assets.place_id',
                sortBy: {
                    creation_dt: -1
                }
            }
        };

        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
        }

        var requery = {};
        if ($scope.search) {
            if ($scope.search.client) {
                requery["client_name"] = {
                    '$regex': $scope.search.client,
                    '$options': 'i'
                };
            }
            if ($scope.search.purchase_order) {
                requery["purchase_order"] = {
                    '$regex': $scope.search.purchase_order,
                    '$options': 'i'
                };
            }
            if ($scope.search.platform) {
                requery["_plateforme"] = $scope.search.platform._id;
            }
            if ($scope.search.statusInstallRequest) {
                requery["status"] = $scope.search.statusInstallRequest;
            }
            if ($scope.search.asset) {
                requery["assets.name"] = {
                    '$regex': $scope.search.asset,
                    '$options': 'i'
                };
            }

        }
        if (tableState.sort && Object.keys(tableState.sort).length > 0) {
            if (tableState.sort.predicate == "creation_dt") {
                query.options.sortBy.creation_dt = tableState.sort.reverse ? -1 : 1;

            }
        }
        query.query = requery;
        I3InstallationGroupSrv.query(query, number, start, function (response) {
            if (response.success) {
                $scope.requestsCount = response.total_count;
                tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
                tableState.pagination.totalItemCount = response.total_count;
                $scope.tableState = tableState;
                $scope.installationRequests = response.result.map(function (e) {
                    e.commissioningCount = 0;
                    e.totalAsset = 0;
                    if (e.assets && e.assets.length > 0) {
                        e.totalAsset = e.assets.length;
                        e.assets.forEach(function (val) {
                            if (val.status == "COMMISSIONING")
                                e.commissioningCount++;
                        })
                    }
                    e.progress = e.totalAsset ? e.commissioningCount * 100 / e.totalAsset : 0;
                    return e;
                });
                if (($stateParams.platform) && (i == 0)) {
                    console.log('rrrrr');
                    var elementPos = $scope._HOME.platforms.map(function (plat) {
                        return plat._id;
                    }).indexOf($stateParams.platform);

                    $scope.search.platform = $scope._HOME.platforms[elementPos];
                    i = 1;
                }

            }
        });
    };

    $scope.cancel = function () {
        $scope.mode = "LIST";
    };

});