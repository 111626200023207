angular.module('app').controller('adminUserAddCtrl', function ($scope, $rootScope, accessSrv, $state, $translate, I3UserSrv, i3HelperSrv, I3RoleSrv, ngDialog, Notification,constantSrv) {
    $scope.visiblePwd = false
    $scope.user = {};
    $scope.accessModels = accessSrv.getAllModule()
    $scope.modelAccess = 'DASHBOARD';
    $scope.modelMenus = accessSrv.getAllAccessMenu();

    /**
     * Getting roles
     */
    I3RoleSrv.query({}, 10000, 1, function (response) {
        if (response.success) {
            $scope.roles = response.result;
        }
        if ($rootScope.checkAccess("PLATEFORME","R","O"))
            $scope.user._plateforme = $scope._ADMIN.platforms[0];

    });

    /**
     * Save user
     */
    $scope.save = function () {

        $scope.user.menus = accessSrv.getGroupMenubyModel($scope.modelMenus)
        I3UserSrv.add($scope.user, function (response) {
            if (response.success) {
                Notification.success({
                    title: $translate.instant("USER_CREATED_TITLE"),
                    message: $translate.instant("USER_CREATED_SUC")
                });
                $state.go('app.admin.users');
                $scope.user = {};
            } else {

                if(response.error && response.error.errorCode=="error_user_validation_error" && response.error.details && response.error.details.errors && response.error.details.errors.login && response.error.details.errors.login.kind=="unique"){

                    Notification.error({
                        title: $translate.instant('ERROR'),
                        message: $translate.instant("DUPLICATED_LOGIN_USER",{login: $scope.user.login })
                    });
                }else{
                    Notification.error({
                        title: $translate.instant('ERROR'),
                        message: $translate.instant("ERROR_OCCURED_CREATE") + " " + $scope.user.login + "!"
                    });
                }


            }
        }, function (err) {
            var msg = "";
            if (err.error.errorString) {
                msg = err.error.errorString;
            }
            Notification.error({
                title: $translate.instant('ERROR'),
                message: msg
            });
        })
    };

    /**
     * Cancel editing mode
     */
    $scope.cancel = function () {
        $scope.user = {};
        $state.go('app.admin.users');
    }

    $scope.show_access = function () {
        $scope.perm = !$scope.perm;
    }
    $scope.getModel = function (model) {
        $scope.modelAccess = model;

    }

    $scope.getMenubyModel = function (model, menu) {

       // console.log("$scope.modelMenus", $scope.modelMenus)

    }
    $scope.generate = function () {
        //var finalPassword = constantSrv.generatePassword();
        $scope.user.password = i3HelperSrv.generatePassword();
    }
    $scope.showPassword = function () {
        var x = document.getElementById("password");
        if (x.type === "password") {
            $scope.visiblePwd = true;
            x.type = "text";
        } else {
            $scope.visiblePwd = false;
            x.type = "password";
        }
    };



    $scope.getRole = function(role){
$scope.profiles =constantSrv.getProfiles()
$scope.ListMenu= $scope.profiles[role._id] ;
console.log('$scope.ListMenu',$scope.ListMenu)
$scope.modelMenus = accessSrv.getALLMenuAccess($scope.ListMenu);

    }
   $scope.resetAccess = function(){

    $scope.profiles =constantSrv.getProfiles()
    $scope.ListMenu= $scope.profiles[$scope.user._role._id] ;
 
    $scope.modelMenus = accessSrv.getALLMenuAccess($scope.ListMenu);

   }
});