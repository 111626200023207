angular.module('app').controller("ClientSyncDlgCtrl", function ($scope, plateformHelperSrv, $translate, iaxeedCompanySrv, I3InstallationGroupSrv, installation, Notification) {
    // console.log($scope.installationRequest)
    $scope.sg_client = {};
    $scope.clients = [];
    $scope.auto = true;
    $scope.suggest_client = function () {
        plateformHelperSrv.setPlateFormAndLogin($scope.installationRequest._plateforme, function (res) {
            if (res && res.success) {
                iaxeedCompanySrv.query({
                    query: {
                        name: {
                            '$regex': $scope.installationRequest.client_name,
                            '$options': 'i'
                        }
                    }
                }, 1, 0, function (res) {
                    //console.log(res)
                    // $scope.sg_client = res.result[0];
                    if (res.result.length > 0) {
                        $scope.sg_client = res.result[0];
                        // console.log("f", $scope.sg_client)
                    }

                    //console.log($scope.sg_client)

                }, function (err) {
                    console.log("err", err)
                })
            } else {
                // console.log("no")
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("PLATFORM_CNX_ERROR")
                });
            }
        }, function (err) {
            console.log(err);
            Notification.error({
                title: $translate.instant("ERROR"),
                message: $translate.instant("PLATFORM_CNX_ERR")
            });
        })
    }

    $scope.suggest_client()

    $scope.change_client = function (client) {
        // console.log("c", client)

        $scope.sg_client = client;
        $scope.auto = false;
        // console.log("d", $scope.sg_client)

    }
    $scope.find_client = function ($select) {

            iaxeedCompanySrv.query({
                query: {
                    name: {
                        '$regex': $select.search,
                        '$options': 'i'
                    }
                }
            }, 10, 0, function (res) {
                $scope.clients = res.result;
                console.log("e", $scope.clients)

                //console.log($scope.sg_client)

            }, function (err) {
                console.log("err", err)
            })
        
    }

    $scope.save = function () {
        $scope.closeThisDialog($scope.sg_client);
    }

    $scope.selectPlateform = function (platform) {
        plateformHelperSrv.setPlateFormAndLogin(platform, function (res) {}, function (err) {
            console.log(err);
        })
    };

});