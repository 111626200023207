angular.module('app.dialogs').controller('endDateDlgCtrl', function ($scope) {

    $scope.moment = moment();
    var now = moment().clone();
    var inventory = $scope.ngDialogData.prop;
    if (inventory.begin_dt) {
        var min_date = moment(new Date(inventory.begin_dt));
    } else {
        var min_date = moment(new Date(inventory.creation_dt));
    }

    $scope.dayRes = now;
    $scope.pickerDay = {
        format: "DD/MM/YYYY",
        position: "top left",
        init: moment(inventory.end_dt),
        autoTrans: false,
        enableTime: false,
        enableDate: true,
        enableMinutes: false,
        defaultView: "DATE",
        minDate: min_date,
        getDate: function (date) {
            $scope.dayRes = date;
        }
    };
    $scope.EditDate = function () {
        $scope.closeThisDialog($scope.dayRes);
    }
    $scope.cancel = function () {
        $scope.closeThisDialog(false);
    }
});