angular.module('app.dialogs').controller('addClienteInvoiceDlgCtrl', function ($scope, I3ClientSrv,invoiceClientSrv) {

   $scope.synchrone={};
    var start = 0;
    var number = 100;
    var options = {
        populate: [
            {
                path: '_plateforme',
               
            },
           
         ],
         sortBy: {creation_dt: -1}
     };


     var query = {options: options};
 var requery = {$or:[{invoice_id:{ $exists: false }},{invoice_id:null}]};
query.query = requery;

I3ClientSrv.query(query, number, start, function (res) {
        
        if (res && res.result.length>0) {
           $scope.ClientsADD=res.result;
      
         
    
    
        }
    })

  



  
    $scope.getInvoice = function (sev_client) {
   // console.log('sev_client:', sev_client)

       
    }

    $scope.addClientInvoice = function () {
       


                $scope.closeThisDialog($scope.synchrone);
                $scope.loadClients()
              
         
       









    }
    $scope.cancel = function () {
        $scope.closeThisDialog(false);
    }
});