angular.module('app').controller("interventionGenerationPVCtrl", function ($scope, PvGen, $state, Notification, ngDialog, $translate, I3PvSrv, I3ZoneSrv, I3ClientSrv) {


    $scope.isUniquePVRef = false;
    $scope.pv = {};
    $scope.categorys = []
    $scope.category = {}
    $scope.travelCost = false;
    $scope.docs = [];
    $scope.zone = {};
    $scope.ListFilterProducts = []
    $scope.productsOptions = []
    $scope.documents = [];
    $scope.srch = false;
    $scope.listClientInvoice = []
    $scope.subClient = null;
    var query = {
        query: {
            code_client: $scope.intervention._client_code
        },
        options: { populate: [{ path: "_subClients" }] }


    }
    I3ClientSrv.query(query, 1, 0, function (res) {
        if (res && res.success) {
            if (res.result.length > 0) {
                if (res.result[0]._subClients && res.result[0]._subClients.length > 0) {// client groupe ou client simple  exist dans tcfleet-sav 
                    $scope.listClientInvoice = res.result[0]._subClients



                }

            }
        }
    }, function (err) {
        console.log(err)
    })
    $scope.pvNumber = function () {

        $scope.genertatePV()
    }
    $scope.genertatePV = function () {


        try {
            $scope.srch = true;
            var pvGen = new PvGen($scope.intervention, $scope.asset._id, $scope.pv.pv_ref, "INTERVENTION");

            pvGen.prepareIntervention().then(function (res) {


                $scope.gnPv = res;
                // console.log($scope.gnPv)
            }, function (err) {
                console.log(err);
            })
        } catch (e) {

        }
    }
    $scope.confirm = function () {
        if ($scope.intervention.docs && $scope.intervention.docs.length > 0) {
            $scope.documents = $scope.intervention.docs.map(function (val) {

                return {
                    _document: val,
                    _type: "OTHER"
                }


            })
        }

        var charged_to_client = false;
        if ($scope.intervention.payable) {
            // dans la garentie
            if ($scope.intervention.charged_to_client == "CLIENT") {
                charged_to_client = "CLIENT";
            } else {
                charged_to_client = "NGI";
            }
        } else {
            charged_to_client = "CLIENT";
        }

        var pv = {
            _plateforme: $scope.intervention._plateforme,
            client_name: $scope.intervention.client_name,
            clientSav_id: ($scope.subClient != null && $scope.subClient._id) ? $scope.subClient._id : $scope.intervention.clientSav_id,
            client_filiale: ($scope.subClient != null && $scope.subClient.name) ? $scope.subClient.name : null,
            client_id: $scope.intervention.client_id || null,
            _asset: $scope.asset.asset_name,
            _intervention: $scope.intervention._id,
            type: "INTERVENTION",
            SAP: ($scope.subClient != null && $scope.subClient.SAP) ? $scope.subClient.SAP : null,
            purchase_order: $scope.intervention.purchase_order,
            _documents: $scope.documents,
            pv_ref: $scope.pv.pv_ref,
            _client_code: ($scope.subClient != null && $scope.subClient.code_client) ? $scope.subClient.code_client : $scope.intervention.code_client,
            products: $scope.gnPv.products,
            charged_to_client: charged_to_client //$scope.intervention.payable
        }
        I3PvSrv.add(pv, function (res) {
            if (res && res.success) {

                Notification.success({
                    title: $translate.instant("PV_ADDED_TITLE"),
                    message: $translate.instant("PV_ADDED_SUC")
                });
                $scope.closeThisDialog(true);

            } else {
                Notification.error($translate.instant("PV_ADD_ERROR"));
                //TODO handle error
            }
        }, function (err) { })


    }
    $scope.cancel = function () {

        $scope.closeThisDialog();
    }
    $scope.getZone = function (zone) {


        I3ZoneSrv.query({
            query: {
                _id: zone

            }
        }, 1, 0, function (response) {

            $scope.categorys = response.result[0].travel_costs;

        }, function (err) {
            console.log(err);
        });




    }
    $scope.confirmTravel = function (zone, category) {

        if ($scope.gnPv.products.length == 0) {

            Notification.warning($translate.instant("ADD_MINIMUM_OPERATION"));
        } else {

            $scope.gnPv.products.forEach(function (val) {
                if (val && val._product.type == "OPERATION") {
                    $scope.productsOptions.push(val)
                }


            });
            // console.log("  $scope.productsOptions", $scope.productsOptions)
            if ($scope.productsOptions.length > 0) {

                $scope.gnPv.includeTravelCosts(zone, category)
                Notification.success($translate.instant("TRAVEL_COSTS_ADD_SUCC"));

            } else {

                Notification.warning($translate.instant("ADD_MINIMUM_OPERATION"));
            }

        }



    }

    $scope.getTravelCost = function (travelCost, zone, category) {
        $scope.travelCost = travelCost


        $scope.gnPv.removeTravelCosts(zone, category)
    }

    $scope.editProduct = function (element, index) {
        $scope.pv.products = $scope.gnPv.products
        ngDialog.open({
            templateUrl: "app/commons/dialogs/pv/product.edit.dlg.html",
            className: 'ngdialog-theme-default custom-width-800',
            controller: "productEditDlgCtrl",
            appendClassName: 'ngdialog-custom',
            scope: $scope,
            resolve: {
                index: function () {
                    return index;
                }
            }
        })
    };
    $scope.removeProduct = function (element, index) {


        $scope.gnPv.products.splice(index, 1);
    };




    $scope.openProductDialog = function () {
        $scope.productTypes = ["SERVICE", "OPERATION"]




        ngDialog.open({
            template: "app/commons/dialogs/pv/product.AddGenerate.dlg.tpl.html",
            className: 'ngdialog-theme-default custom-width-800',
            appendClassName: 'ngdialog-custom',
            controller: "productGenerateDlgCtrl",
            scope: $scope
        })
    };

    $scope.pvNumber2 = function () {

        $scope.isUniquePVRef = false;
        I3PvSrv.query({
            query: {
                pv_ref: $scope.pv.pv_ref,
                _plateforme: $scope.intervention._plateforme
            }
        }, 10000, 1, function (res) {
            if (res.success) {
                if (res.result.length > 0 && $scope.pv._id != res.result[0]._id) {
                    $scope.isUniquePVRef = false;
                    // console.log("eeeeeeeeeeeeeeeeeeeeeeeee")
                } else {
                    $scope.isUniquePVRef = true;
                    // console.log("fffffffffffffffffffffffff")
                }
            }
        }, function (err) {
            console.log(err);
        });
    }

    $scope.update_search = function () {
        $scope.srch = false;
        $scope.pvNumber2();
    }

    $scope.getsubClient = function (clientInvoice) {


        $scope.subClient = clientInvoice
    }
});