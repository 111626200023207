/**
 * Created by Mohamed Saad on 18/12/2017.
 */

angular.module("app").controller("settingsEditCtrl", function ($scope, $state, I3SettingSrv, $stateParams, $filter, $translate, Notification, I3RoleSrv, i3HelperSrv, ngDialog) {

    $scope.config = {};
    $scope.role_list = [];
    $scope.config.rolesConfig = [];



    var query = {
        _id: $stateParams.id
    };
    var options = {
        populate: [{
            path: 'usersConfig.user',
            select: 'first_name last_name login'
        }]
    };
    I3SettingSrv.query({
        query: query,
        options: options
    }, 1, 0, function (data) {

        $scope.setting = data.result[0];
        // console.log(" $scope.setting", $scope.setting);
        $scope.role_list = $scope.setting.rolesConfig;


        if(!$scope.setting.followers){
            $scope.setting.followers = {}
        }
        if(!$scope.setting.created_by){
            $scope.setting.created_by = {}
       }
       if(!$scope.setting.assigned_to){
        $scope.setting.assigned_to = {}
       }






    }, function (err) {
        console.log(err);

    })



    $scope.removeRole = function (index) {
        $scope.setting.rolesConfig.splice(index, 1);
    };
    $scope.removeUser = function (index) {
      
        $scope.setting.usersConfig.splice(index,1);
    };
    $scope.addRole = function () {
        var dialog = ngDialog.open({
            templateUrl: "app/commons/dialogs/settings/setting.role.dlg.tpl.html",
            controller: "settingRoleDlgCtrl",
            width: '600px',
            scope: $scope,
            resolve: {
                updateCfg: function () {
                    return undefined;
                },
                isRoleConfig: function () {
                    return true;
                },
                isNewRole: function () {
                    return true;
                },
                modelId: function () {
                    return $scope.setting._id;
                }
            }
        })
        dialog.closePromise.then(function (data) {

            if (data && data.value  && data.value !== '$closeButton' && data.value !== '$document') {

                if (!$scope.setting["rolesConfig"] || $scope.setting["rolesConfig"].length < 0) $scope.setting["rolesConfig"] = [];

                $scope.setting.rolesConfig[index].update = data.value;

            } else if (data.value === false) {
              //  $scope.setting.rolesConfig[index].update = false;
            }
        })
    };

    $scope.editSetting = function () {
        if (!Array.isArray($scope.config.rolesConfig)) {
            $scope.config.rolesConfig = []
        }
        $scope.config._id = $scope.setting._id;
        $scope.config.rolesConfig = $scope.role_list;
        $scope.config.usersConfig = $scope.setting.usersConfig;
        $scope.config.followers = $scope.setting.followers;
        $scope.config.created_by = $scope.setting.created_by;
        $scope.config.assigned_to = $scope.setting.assigned_to;
        I3SettingSrv.updateById($scope.config._id, $scope.config, function (response) {
            if (response && response.success) {
                Notification.success($translate.instant('SETTING_ADDED'));
                $state.go("app.admin.settings")
            } else {
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("FAILED")
                });
            }
        });
    }

    $scope.editFieldForUpdate = function (fieldName, updateCfg) {

        var dialog = ngDialog.open({
            templateUrl: "app/commons/dialogs/settings/setting.fields.dlg.tpl.html",
            controller: "settingFieldsDlgCtrl",
            width: '600px',
            resolve: {
                updateCfg: function () {
                    return updateCfg  ;
                },
                isfieldName: function () {
                    return fieldName;
                },
                isSetting: function () {
                    return $scope.setting;
                },
              
                modelId: function () {
                    return $scope.setting._id;
                }
            }
        })
        dialog.closePromise.then(function (data) {
            console.log(data)
            if (data && data.value  && data.value !== '$closeButton' && data.value !== '$document') {
     
               
                
                    $scope.setting[fieldName].update = data.value;
                

                    console.log('setting',$scope.setting)

            }  else if (data.value === false) {
                //   $scope.setting[fieldName].update = false;
              }
        
        })

    }
    $scope.editFieldForUpdateByRole = function (role, updateCfg, index) {

        var dialog = ngDialog.open({
            templateUrl: "app/commons/dialogs/settings/setting.role.dlg.tpl.html",
            controller: "settingRoleDlgCtrl",
            width: '600px',
            resolve: {
                updateCfg: function () {
                    return updateCfg;
                },
                isRoleConfig: function () {
                    return true;
                },
                isNewRole: function () {
                    return false;
                },
                modelId: function () {
                    return $scope.setting._id;
                }
            }
        })
        dialog.closePromise.then(function (data) {

            if (data && data.value  && data.value !== '$closeButton' && data.value !== '$document') {

                if (!$scope.setting["rolesConfig"] || $scope.setting["rolesConfig"].length < 0) $scope.setting["rolesConfig"] = [];

                $scope.setting.rolesConfig[index].update = data.value;

            } else if (data.value === false) {
             $scope.setting.rolesConfig[index].update = false;
            }
        })

    }
    $scope.editFieldForUpdateByUser = function (user, updateCfg, index) {

        var dialog = ngDialog.open({
            templateUrl: "app/commons/dialogs/settings/setting.user.dlg.tpl.html",
            controller: "settingUserDlgCtrl",
            width: '600px',
            resolve: {
                updateCfg: function () {
                    return updateCfg;
                },
                isUserConfig: function () {
                    return true;
                },
                isNewUser: function () {
                    return false;
                },
                modelId: function () {
                    return $scope.setting._id;
                }
            }
        })
        dialog.closePromise.then(function (data) {
    
            if (data && data.value && data.value !== '$closeButton' && data.value !== '$document') {

                if (!$scope.setting["usersConfig"] || $scope.setting["usersConfig"].length < 0) $scope.setting["usersConfig"] = [];

                $scope.setting.usersConfig[index].update = data.value;



            } else if (data.value === false) {
              
               $scope.setting.usersConfig[index].update = false;
            }
        })

    }


    
    $scope.addUser = function () {
        var dialog = ngDialog.open({
            templateUrl: "app/commons/dialogs/settings/setting.user.dlg.tpl.html",
            controller: "settingUserDlgCtrl",
            width: '600px',
            scope: $scope,
            resolve: {
                updateCfg: function () {
                    return undefined;
                },
                isUserConfig: function () {
                    return true;
                },
                isNewUser: function () {
                    return true;
                },
                modelId: function () {
                    return $scope.setting._id;
                }
            }
        })
        dialog.closePromise.then(function (data) {
            if (data && data.value && data.value !== '$closeButton' && data.value !== '$document') {

                if (!$scope.setting["usersConfig"] || $scope.setting["usersConfig"].length < 0) $scope.setting["usersConfig"] = [];

                $scope.setting.usersConfig[index].update = data.value;

            } else if (data.value === false) {
               // $scope.setting.usersConfig[index].update = false;
            }
        })
    };
    $scope.isString = function (prop) {

        return angular.isDefined(prop) && angular.isString(prop)

    }
});