angular.module('app').controller('compatibilityCheckDetailsCtrl', function ($scope, $rootScope, $translate, Notification, $stateParams, I3CompatibilitySrv) {
    //#region scopes
    //vars and scopes

    $scope.com = [];
    $scope.loading = false;
    $scope.selected = null;
    $scope.previous = null;
    $scope.compatibility = {};
    $scope.tab = "1";
    $scope.toggle = false;
    $scope.GrpZones = [];
    $scope.nbr_assets = $scope.nbr_device = $scope.nbr_bus = $scope.nbr_direct_line = $scope.temp_probe = $scope.door_sensor = $scope.ibutton_reader = $scope.ibutton_tag = $scope.group_cold_sensor = $scope.immobilizer_12V = $scope.immobilizer_24V = $scope.nbr_pus = $scope.nbr_card_total = 0;
    $scope.nbr_device2 = $scope.nbr_direct_line2 = $scope.nbr_bus2 = $scope.temp_probe2 = $scope.door_sensor2 = $scope.ibutton_reader2 = $scope.ibutton_tag2 = $scope.group_cold_sensor2 = $scope.immobilizer_12V2 = $scope.immobilizer_24V2 = $scope.nbr_pus2 = $scope.nbr_card_total2 = 0;
    $scope.selectedIndex = 0;
    $scope.currentPage = 0;
    $scope.pageSize = 25;
    $scope.current_asset = {};
    $scope.numberOfPages = 1;

    $scope.filterParams = {
        NEW: true,
        VALIDATED: true
    };
    $scope.timeleft = 0;
    $scope.now = moment()
    $scope.redv = 0;

    $scope.getvalue = function (deadline, deadline_period) {
        if (deadline && deadline_period) {
            var deadline_start = moment(deadline).subtract(deadline_period, 'h');
            console.log(deadline_start)
            var date2 = moment(deadline);
            var date1 = moment();
            $scope.date_limite = date2;
            var differenceInMs = date2.diff(date1); // diff yields milliseconds
            var duration = moment.duration(differenceInMs);
            var differenceInHours = Math.floor(duration.asHours());

            if (-differenceInHours >= 1000) {
                $scope.timeleft = 1000
            } else
                $scope.timeleft = -differenceInHours;
            var redv = Math.floor(100 - ((differenceInHours / deadline_period) * 100))
            if (redv >= 100) redv = 100
            console.log(redv)
            return redv
        }
    }
    //#endregion scopes
    //#region initial data
    $scope.getCompatibility = function () {
        var zones = []
        var assets = [];
        var zone_Indexed = {};
        var query = {
            _id: $stateParams.id
        };
        var options = {
            populate: [{
                    path: '_plateforme',
                    select: 'name'
                }, {
                    path: '_created_by',
                    select: 'last_name first_name'
                }, {
                    path: '_modified_by',
                    select: 'last_name first_name'
                }, {
                    path: 'assets.place_id',
                    select: 'name'
                }, {
                    path: 'assets.comments._user',
                    select: 'login'
                },
                {
                    path: 'status_histo._modified_by',
                    select: "first_name  last_name login"
                }
            ]
        };
        I3CompatibilitySrv.query({
            query: query,
            options: options
        }, 1, 0, function (response) {
            if (response.success) {
                $scope.compatibility = response.result[0];
                $scope.redv = $scope.getvalue($scope.compatibility.dead_line, $scope.compatibility.dead_line_period);
                $scope.test_table();
                $scope.current_asset = angular.copy($scope.compatibility.assets[0]);
                $scope.nbr_assets = $scope.compatibility.assets.length;
                for (var index = 0; index < $scope.nbr_assets; index++) {
                    assets.push($scope.compatibility.assets[index]);
                    var ast = assets[index].place_id._id;
                    var nm = assets[index].place_id.name;
                    var ro = assets[index].req_options;
                    var so = assets[index].res_options;
                    var cards = assets[index].total_card_number;
                    zone_Indexed[ast] = {
                        id: ast,
                        name: nm,
                        card: cards,
                        request: ro,
                        res: so
                    }
                    zones.push(zone_Indexed[ast]);
                }
                var result2 = Object.values(
                    zones.reduce(function (a, o) {
                        var id = o.id,
                            name = o.name,
                            card = o.card,
                            request = o.request,
                            res = o.res;
                        a[id] || (a[id] = {
                            id: id,
                            card: card,
                            name: name,
                            unique_cards: new Set(),
                            nb_carte: 0,
                            request: {},
                            res: {}
                        });
                        if (card != null)
                            a[id].unique_cards.add(card);
                        a[id].nb_carte = a[id].unique_cards.size;
                        Object.keys(request).forEach(function (k) {
                            (a[id].request[k] = (a[id].request[k] || 0) + request[k])
                        });
                        Object.keys(res).forEach(function (k) {
                            (a[id].res[k] = (a[id].res[k] || 0) + res[k])
                        });
                        return a;
                    }, Object.create(null))
                );
                result2.forEach(function (o) {
                    delete o.card;
                    delete o.unique_cards;
                });
                $scope.GrpZones = result2;
                //console.log($scope.GrpZones);
                //$scope.nbr_device = result2.map(function(item) { return item.request.device)
                $scope.nbr_device = result2.map(function (item) {
                    return item.request.device
                }).reduce(function (prev, next) {
                    return prev + next
                });
                $scope.nbr_bus = result2.map(function (item) {
                    return item.request.canbus
                }).reduce(function (prev, next) {
                    return prev + next
                });
                $scope.nbr_direct_line = result2.map(function (item) {
                    return item.request.direct_line
                }).reduce(function (prev, next) {
                    return prev + next
                });
                $scope.temp_probe = result2.map(function (item) {
                    return item.request.temp_probe
                }).reduce(function (prev, next) {
                    return prev + next
                });
                $scope.door_sensor = result2.map(function (item) {
                    return item.request.door_sensor
                }).reduce(function (prev, next) {
                    return prev + next
                });
                $scope.ibutton_reader = result2.map(function (item) {
                    return item.request.ibutton_reader
                }).reduce(function (prev, next) {
                    return prev + next
                });
                $scope.ibutton_tag = result2.map(function (item) {
                    return item.request.ibutton_tag
                }).reduce(function (prev, next) {
                    return prev + next
                });
                $scope.group_cold_sensor = result2.map(function (item) {
                    return item.request.group_cold_sensor
                }).reduce(function (prev, next) {
                    return prev + next
                });
                $scope.immobilizer_12V = result2.map(function (item) {
                    return item.request.immobilizer_12V
                }).reduce(function (prev, next) {
                    return prev + next
                });
                $scope.immobilizer_24V = result2.map(function (item) {
                    return item.request.immobilizer_24V
                }).reduce(function (prev, next) {
                    return prev + next
                });
                $scope.nbr_pus = result2.map(function (item) {
                    return item.request.sim_supplier_ngi
                }).reduce(function (prev, next) {
                    return prev + next
                });
                $scope.nbr_device2 = result2.map(function (item) {
                    return item.res.device
                }).reduce(function (prev, next) {
                    return prev + next
                });
                $scope.nbr_bus2 = result2.map(function (item) {
                    return item.res.canbus
                }).reduce(function (prev, next) {
                    return prev + next
                });
                $scope.nbr_direct_line2 = result2.map(function (item) {
                    return item.res.direct_line
                }).reduce(function (prev, next) {
                    return prev + next
                });
                $scope.temp_probe2 = result2.map(function (item) {
                    return item.res.temp_probe
                }).reduce(function (prev, next) {
                    return prev + next
                });
                $scope.door_sensor2 = result2.map(function (item) {
                    return item.res.door_sensor
                }).reduce(function (prev, next) {
                    return prev + next
                });
                $scope.ibutton_reader2 = result2.map(function (item) {
                    return item.res.ibutton_reader
                }).reduce(function (prev, next) {
                    return prev + next
                });
                $scope.ibutton_tag2 = result2.map(function (item) {
                    return item.res.ibutton_tag
                }).reduce(function (prev, next) {
                    return prev + next
                });
                $scope.group_cold_sensor2 = result2.map(function (item) {
                    return item.res.group_cold_sensor
                }).reduce(function (prev, next) {
                    return prev + next
                });
                $scope.immobilizer_12V2 = result2.map(function (item) {
                    return item.res.immobilizer_12V
                }).reduce(function (prev, next) {
                    return prev + next
                });
                $scope.immobilizer_24V2 = result2.map(function (item) {
                    return item.res.immobilizer_24V
                }).reduce(function (prev, next) {
                    return prev + next
                });
                $scope.nbr_pus2 = result2.map(function (item) {
                    return item.res.sim_supplier_ngi
                }).reduce(function (prev, next) {
                    return prev + next
                });
                $scope.nbr_card_total = new Set(assets.map(function (item) {
                    return item.total_card_number
                }).filter(function (val) {
                    return val != null;
                })).size;
                //var a = CompatibilityUtilsSrv.groupByZone($scope.compatibility);
            } else {
                console.log(response.error)
            }
        }, function (err) {
            console.log(err);
        });
    }();
    //check if currentvalue < 1 (we test only the asset with req_options >= 1 )
    $scope.equal0 = function (currentValue) {
        return currentValue < 1;
    };
    //test if all request options < 1 to show a message ("nothing to show") with the asset boolean (is0)
    $scope.test_table = function () {
        var arr = [];
        angular.forEach($scope.compatibility.assets, function (asset) {
            asset.new_status = asset.status;
            angular.forEach(asset.req_options, function (value, key) {
                arr.push(value);
            });
            if (arr.every($scope.equal0))
                asset.is0 = true;
            else
                asset.is0 = false;
            arr = [];
        });
    };
    //#endregion initial data
    //#region assetslide and filters
    //set currentPage to next
    $scope.nextpage = function () {
        $scope.selectedIndex = 0;
        $scope.currentPage = $scope.currentPage + 1;
    };
    //set currentPage to previous
    $scope.previouspage = function () {
        $scope.selectedIndex = 0;
        $scope.currentPage = $scope.currentPage - 1;
    };
    //reset current page and selectedIndex after search
    $scope.resetindex = function () {
        if ($scope.namev && $scope.namev.trim() != '') {
            $scope.currentPage = 0;
            $scope.selectedIndex = 0;
        }
    };
    //watch for asset results change for asset copy
    $scope.$watch('limited_fname_fstatus_asset[selectedIndex]', function (newVal, oldVal) {
        if ($scope.limited_fname_fstatus_asset && $scope.limited_fname_fstatus_asset[$scope.selectedIndex]) {
            //console.log(newVal);
            $scope.current_asset = angular.copy(newVal);
            $scope.reset_asset = angular.copy(newVal);
        }
    });
    //watch for asset results change for showing the progress bar
    $scope.$watch('fname_fstatus_asset.length', function (newVal, oldVal) {
        //$scope.loading = true;
        if ($scope.fname_fstatus_asset) {
            $scope.numberOfPages = Math.floor(((newVal - 1) / $scope.pageSize));
            console.log("TCL: $scope.numberOfPages", $scope.numberOfPages)
        } else {
            $scope.numberOfPages = 1;
        }
        /* $timeout(function () {}, 2000).then(function () {
            $scope.loading = false;
        }); */
    });
    //can't disable all asset status and reset current page and selectedIndex after availables status change
    $(":checkbox").click(function () {
        $scope.currentPage = 0;
        $scope.selectedIndex = 0;
        if ($("input:checked").length == 1) {
            $(':checked').prop('disabled', true);
        } else {
            $(':checked').prop('disabled', false);
        }
    });
    //filter asset by name
    $scope.name_filter = function (asset) {
        if ($scope.namev && $scope.namev.trim() != '')
            return (asset.name.toLowerCase().indexOf($scope.namev.toLowerCase()) > -1)
        else
            return (true)
    };
    //filter assets (with a searched term) by status
    $scope.filterStatus = function (item, index, array) {
        var status = "NEW";
        if (item.status) {
            status = item.status;
        }
        return ($scope.filterParams.NEW && $scope.fname_asset[index].status == "NEW" || $scope.filterParams.VALIDATED && $scope.fname_asset[index].status == "VALIDATED");
    };
    // get the length of filtered assets (with a searched term) by the status NEW
    $scope.quertstatus_new = function (item, index, array) {
        return $scope.fname_asset[index].status == "NEW"
    };
    // get the length of filtered assets (with a searched term) by the status VALIDATED
    $scope.quertstatus_vld = function (item, index, array) {
        return $scope.fname_asset[index].status == "VALIDATED"
    };
    $scope.quertstatus_vld_2 = function (item, index, array) {
        return $scope.limited_fname_fstatus_asset[index].status == "VALIDATED"
    };
    $scope.quertstatus_new_2 = function (item, index, array) {
        return $scope.limited_fname_fstatus_asset[index].status == "NEW"
    };
    //#endregion assetslide and filters
    //#region update data
    //#endregion update data
    //#region utility
    $scope.change_tab = function (x) {
        $scope.tab = x;
    };

    $scope.comparaison = function () {
        $scope.toggle = !$scope.toggle;
    };
    $scope.getcolor = function (x) {
        if (x) {
            return {
                'background-color': '#ffc8d2'
            };
        }
        /* else {
            return {
                'background-color': 'limegreen'
            };
        } */
    };
    //#endregion utility
    //#region comments
    //toggle show comment
    $scope.show_com = function (x, y) {
        if (y > 0) {
            $scope.com[x] = !$scope.com[x];
        }
    };
    //add asset Comment
    $scope.addComment = function (content, asset) {
        if (content) {
            var commentAdded = {};
            commentAdded.date = new Date();
            commentAdded._user = $rootScope.SESSION.user._id;
            commentAdded.comment = content;
            commentAdded.new = true;
            asset.comments.push(commentAdded);
            $scope.content = "";
            asset.save_state = 'unsaved';
            $scope.current_asset.save_state = 'unsaved';
        }
    };
    //delete asset Comment
    $scope.deleteAssetComment = function (index, asset) {
        asset.comments.splice(index, 1);
        asset.save_state = 'unsaved';
        $scope.current_asset.save_state = 'unsaved';
    };

    $scope.change_state = function (asset) {
        asset.save_state = 'unsaved';
        $scope.current_asset.save_state = 'unsaved';
    };

    $scope.resetCompatibilityRequest = function () {

        $scope.compatibility.save_state = 'saved';
    }
    //check if asset comment is new
    $scope.comp_d = function (comment) {
        if (comment.new) {
            return true
        } else return false
    };

    $scope.updateCompatibilityRequestAsset = function (asset) {

        I3CompatibilitySrv.updateById($scope.compatibility._id, $scope.compatibility, function (response) {
            if (response.success) {
                asset.save_state = 'saved';
                asset.wasnew = true;
                $scope.auto = false;
                $scope.current_asset = angular.copy(asset);
                delete $scope.compatibility.__v;
                Notification.success({
                    title: $translate.instant("UPDATE_SUCC"),
                    message: $translate.instant("ASSET_UPDATED_SUCC")
                });
            } else {
                Notification.error({
                    title: $translate.instant('UPDATE_ERROR'),
                    message: $translate.instant("ASSET_UPDATED_ERR")
                });
            }
        }, function (err) {
            var msg = "";
            if (err.error.errorString) {
                msg = err.error.errorString;
            }
            Notification.error({
                title: $translate.instant('ERROR'),
                message: msg
            });
        })
    };
    //#endregion comments
    //#region photos
    $scope.afficher_front = function (x) {
        var modal = document.getElementById('myModal');
        var img = document.getElementById('imga_' + x);
        var modalImg = document.getElementById("img01");
        var captionText = document.getElementById("caption");
        var span = document.getElementsByClassName("close")[0];
        modal.style.display = "block";
        // modalImg.src = x.dataURL;
        modalImg.src = $scope.getImageUrl(x)
        captionText.innerHTML = $translate.instant("FRONT_PIC");
    };
    //show back photo
    $scope.afficher_back = function (x) {
        var modal = document.getElementById('myModal');
        var img = document.getElementById('imgb_' + x);
        var modalImg = document.getElementById("img01");
        var captionText = document.getElementById("caption");
        var span = document.getElementsByClassName("close")[0];
        modal.style.display = "block";
        //   modalImg.src = x.dataURL;
        modalImg.src = $scope.getImageUrl(x)
        captionText.innerHTML = $translate.instant("BACK_PIC");
    };
    //close photo modal
    $scope.close = function () {
        var modal = document.getElementById('myModal');
        modal.style.display = "none";
    };
    //#endregion photos
    $scope.getImageUrl = function (path) {
        if (path) {

            if (path.dataURL) {
                path = path.dataURL
            }
            if (path.indexOf("data:image") >= 0) {
                return path;
            } else {
                return $rootScope.config.API_URI + "/document/registration_card/" + path + "?token=" + window.sessionStorage.token;
            }
        }
    }

});