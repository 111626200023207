angular.module('app').controller('statisticCtrl', function ($rootScope, $scope, $state, $window, messageBox, ngDialog, Notification, iaxeedFleetSrv, iaxeedAssetSrv, iaxeedCompanySrv, plateformHelperSrv, I3PlateformeSrv, iaxeedMonitoringSrv, iaxeedTokenSrv, $translate, iaxeedSocketSrv, $timeout,$q) {
    //? (0) scope declarations
    $scope.selectedPeriod = 'month';
    var defer= $q.defer();
    var queuedRequest= false; /// wait for connexion to platform
    var now = moment().clone();
    $scope.clientTree = {}
    var tokenList = [];
    $scope.startRes = moment().startOf("day").clone();
    $scope.endRes = now;
    $scope.dayRes = now;
    $scope.monthRes = now;

    var tokenBycompany = {
        count: 0,
        dates: {}
    };
    var tokenByUser = {
        count: 0,
        dates: {}
    };

    $scope.pickerStart = {
        format: "DD/MM/YYYY",
        init: moment().startOf("day").clone(),
        position: "top left",
        autoTrans: false,
        enableTime: false,
        enableDate: true,
        enableMinutes: false,
        defaultView: "DATE",
        getDate: function (date) {
            $scope.startRes = date;
            $scope.pickerEnd.minDate = date;
            getStats();
            $scope.date_show();
        }
    };

    $scope.pickerEnd = {
        format: "DD/MM/YYYY",
        position: "top left",
        init: now,
        autoTrans: false,
        enableTime: false,
        enableDate: true,
        enableMinutes: false,
        defaultView: "DATE",
        getDate: function (date) {
            $scope.endRes = date;
            $scope.pickerStart.maxDate = date;
            getStats();
            $scope.date_show();
        }
    };

    $scope.pickerDay = {
        format: "DD/MM/YYYY",
        position: "top left",
        init: now,
        autoTrans: false,
        enableTime: false,
        enableDate: true,
        enableMinutes: false,
        defaultView: "DATE",
        getDate: function (date) {
            $scope.dayRes = date;
            getStats();
            $scope.date_show();
        }
    };

    $scope.pickerMonth = {
        name: "pickerMonth",
        format: "MM/YYYY",
        position: "top left",
        init: now,
        autoTrans: false,
        enableTime: false,
        enableDate: true,
        enableMinutes: false,
        defaultView: "MONTH",
        getDate: function (date) {
            $scope.monthRes = date;
            getStats();
            $scope.date_show();
        }
    };

    $scope.date_show = function () {

        if ($scope.selectedPeriod === "day") {
            $scope.day1 = $scope.dayRes.clone().format('DD/MM/YYYY');
        } else if ($scope.selectedPeriod === "week") {
            $scope.week1 = $scope.dayRes.clone().subtract(7, "days").format('DD/MM/YYYY');
            $scope.week2 = $scope.dayRes.clone().format('DD/MM/YYYY');
        } else if ($scope.selectedPeriod === "month") {
            $scope.month1 = $scope.monthRes.clone().format('MM/YYYY');
        } else {

            $scope.free1 = $scope.startRes.clone().format('DD/MM/YYYY');
            $scope.free2 = $scope.endRes.clone().format('DD/MM/YYYY');
        }
    }

    $scope.PrintMe = function (DivID) {

        var display_setting = "toolbar=yes,location=no,";
        display_setting += "directories=yes,menubar=yes,";
        display_setting += "scrollbars=yes,width=650, height=600, left=100, top=25";
        var content_value = document.getElementById('toPrint').innerHTML;
        var docPrint = window.open("", "", display_setting);
        
        docPrint.document.open();
        docPrint.document.write('<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN"');
        docPrint.document.write('"http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">');
        docPrint.document.write('<html xmlns="http://www.w3.org/1999/xhtml" xml:lang="en">');
        docPrint.document.write('<head>');
        var cssFileName = "main.css";
        $("link[rel='stylesheet']").each(function (index, val) {
            docPrint.document.write('<link rel="stylesheet" type="text/css" href="' + $(val).attr("href") + '"/>');
            if ($(val).attr("href").indexOf('styles/app-') >= 0) {
                cssFileName = $(val).attr("href"); 
            }
        });
        docPrint.document.write('<link rel="stylesheet" type="text/css" href="' + cssFileName + '"/>');
        docPrint.document.write('<style>@media print{@page {size: portrait}}</style>');
        docPrint.document.write('<style>@media (min-width: 100px) { th {font-size: 15px !important;background-color: #0a65ef !important; }} .highcharts-container{width: 100% !important;max-width: 100% !important;min-height: 400px!important;}.highcharts-container svg {max-width: 100% !important;min-height: 400px !important;} .no-print{display: none !important;} .grd {padding: 10px;} .grd .table th {background-color: dodgerblue !important;} .grd .table thead th {color: white !important;} .grd .table td+td {text-align: center;}</style>');
        docPrint.document.write('</head><body onLoad="self.print()" style=""><section id="monitor"><div class="monitoring-heading comp_req_asset_filtre"></div><div class="card"> ');
        docPrint.document.write(content_value);
        docPrint.document.write('</div></section></body></html>');
        docPrint.document.close();
        docPrint.focus();
        if (docPrint.document)
            console.log(docPrint.document)
    }

    function filterTokens(token) {

        var toAdd = true;
        if ($rootScope.selectedPlatform && $rootScope.selectedPlatform.dash) {
            if ($rootScope.selectedPlatform.dash && $rootScope.selectedPlatform.dash._clients && $rootScope.selectedPlatform.dash._clients.length > 0) {
                var filterdClientIds = $rootScope.selectedPlatform.dash._clients || [];
                filterdClientIds = filterdClientIds.map(function (val) {
                    return val._id;
                })
                var ipAddress = $rootScope.selectedPlatform.dash.ip_address || [];
                var ipAddress = ipAddress.map(function (val) {
                    return val.address;
                });

                if (token._company_owner && token._company_owner._id && filterdClientIds.indexOf(token._company_owner._id) >= 0) {
                    toAdd = false;
                }
                if (toAdd && ipAddress.length > 0 && token.session_data && token.session_data.remoteIpAddress && token.session_data.remoteIpAddress.length > 0 && ipAddress.indexOf(token.session_data.remoteIpAddress[0]) >= 0) {
                    toAdd = false;
                }
                //console.log(toAdd);
                return toAdd;
            }

        } else {
            return toAdd;
        }

    }
    $scope.status_new = true;
    $scope.first_load = true;

    //? (2) init and load data
    $scope.init = function () {
        if (!$rootScope.selectedPlatform) return;
        $scope.getAssetCount();
        $scope.getClientCount();
        tokenBycompany = {};
        tokenByUser = {};

    };
    $scope.$on('$viewContentLoaded', function (event, viewName, viewContent) {
        if (viewName) {
            //console.log("view loaded")
            //? (1) loading platform
            I3PlateformeSrv.query({
                query: {
                    type: "IAXEED"
                }
            }, 10000, 0, function (res) {
                if (res && res.success) {
                    $scope.platformList = res.result;
                    if ($scope.platformList && $scope.platformList.length > 0) {
                        var plateform = null;
                        if ($window.sessionStorage.plateform) {
                            try {
                                plateform = JSON.parse($window.sessionStorage.plateform)
                            } catch (e) {
                                logger.warn("invalid plateform from  cookie select first from list");
                                plateform = null;
                            }
                        }
                        if (!plateform && $rootScope.checkAccess("PLATEFORME", "R", "O")) {
                            plateform = $scope.platformList[0]
                        }
                        if (plateform) {
                            for (var i = 0; i < $scope.platformList.length; i++) {
                                if (plateform._id === $scope.platformList[i]._id) {
                                    $rootScope.selectedPlatform = $scope.platformList[i];
                                    $scope.selectedPlatform = $scope.platformList[i];
                                    break;
                                }
                            }
                        }
                        if (!$rootScope.selectedPlatform) {
                            $rootScope.selectedPlatform = $scope.selectedPlatform;
                            // $rootScope.selectedPlatform = $scope.platformList[0];
                            //$scope.selectedPlatform = $scope.platformList[0];
                        }

                        if (iaxeedSocketSrv.closeListner && typeof iaxeedSocketSrv.closeListner === "function") {
                            iaxeedSocketSrv.closeListner('newMsg');
                        }
                        plateformHelperSrv.setPlateFormAndLogin($rootScope.selectedPlatform, function (res) {
                            if (res && res.success) {
                                $scope.init();
                                iaxeedSocketSrv.connect($rootScope.selectedPlatform);
                                iaxeedSocketSrv.on('server:connected', function () {
                                    iaxeedSocketSrv.emit('user:connected', {
                                        user: JSON.parse($window.sessionStorage.plateformSession).user
                                    });

                                    $timeout(function () {
                                        $rootScope.$broadcast("platform:changed", $rootScope.selectedPlatform);

                                        $rootScope.change_dt = new Date();
                                    });
                                });
                            } else {
                                console.error(res);
                                Notification.error({
                                    title: $translate.instant("ERROR"),
                                    message: $translate.instant("LOADING_ERROR")
                                });
                            }
                        }, function (err) {
                            console.log(err);
                        })
                    }
                }
            }, function (err) {
                console.log(err);
            });

        }
    });

    $scope.selectPlatform = function (platform) {

        $scope.selectedFleet = null;
        $scope.selectedAsset = null;
        $scope.selectedCompany = null;

        if ($rootScope.selectedPlatform && $rootScope.selectedPlatform._id == platform._id) {} else {
            $rootScope.$broadcast("platform:selected");
        }
        $rootScope.selectedPlatform = platform;
        plateformHelperSrv.setPlateFormAndLogin(platform, function (res) {
            if (res && res.success) {
                if (iaxeedSocketSrv.closeListner && typeof iaxeedSocketSrv.closeListner === "function") {
                    iaxeedSocketSrv.closeListner('newMsg');
                }
                iaxeedSocketSrv.connect(platform);
                iaxeedSocketSrv.on('server:connected', function () {
                    iaxeedSocketSrv.emit('user:connected', {
                        user: JSON.parse($window.sessionStorage.plateformSession).user
                    });
                });
                $rootScope.$broadcast("platform:changed", $rootScope.selectedPlatform);
                $rootScope.change_dt = new Date();
                getStats();
            } else {
                Notification.error($translate.instant("CHECK_CNX_SETTINGS"));
            }
        }, function (err) {
            if (err.status === -1) {
                Notification.error($translate.instant("IMPOSSIBLE_CNX"));
            } else if (err.status === 401) {
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS")
                });
            } else {
                Notification.error($translate.instant("LOADING_ERROR_PLATFORM"));
            }
        })
    };

    //asset count
    $scope.getAssetCount = function () {
        var query = {};

        if ($rootScope.selectedPlatform.dash && $rootScope.selectedPlatform.dash._clients && $rootScope.selectedPlatform.dash._clients.length > 0) {
            query = {
                query: {
                    _company_owner: {
                        $nin: $rootScope.selectedPlatform.dash._clients.map(function (val) {
                            return val._id
                        })
                    }
                }
            }
        }
        iaxeedAssetSrv.query(query, 1, 0, function (res) {
            if (res && res.success) {
                $scope.assetCnt = res.total_count;
            }
        }, function (err) {
            if (err.status === -1) {
                console.log($translate.instant("IMPOSSIBLE_CNX"))
                //Notification.error($translate.instant("IMPOSSIBLE_CNX"));
            } else if (err.status === 401) {
                console.log($translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS"))
                /* Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS")
                }); */
            } else {
                console.log($translate.instant("LOADING_ERROR_ASSET_COUNT"))
                //Notification.error($translate.instant("LOADING_ERROR_ASSET_COUNT"));
            }
        })
    };
    //client count
    $scope.getClientCount = function () {
        var query = {};

        if ($rootScope.selectedPlatform.dash && $rootScope.selectedPlatform.dash._clients && $rootScope.selectedPlatform.dash._clients.length > 0) {
            query = {
                query: {
                    _id: {
                        $nin: $rootScope.selectedPlatform.dash._clients.map(function (val) {
                            return val._id
                        })
                    }
                }
            }
        }
        iaxeedCompanySrv.query(query, 1, 0, function (res) {
            if (res && res.success) {
                $scope.companyCnt = res.total_count;
            }
        }, function (err) {
            if (err.status === -1) {
                //Notification.error($translate.instant("IMPOSSIBLE_CNX"));
                console.log($translate.instant("IMPOSSIBLE_CNX"))
            } else if (err.status === 401) {
                console.log($translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS"))
                /*  Notification.error({
                     title: $translate.instant("ERROR"),
                     message: $translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS")
                 }); */
            } else {
                console.log($translate.instant("LOADING_ERROR_COMPANY"))
                //Notification.error($translate.instant("LOADING_ERROR_COMPANY"));
            }
        })
    };

    var changePlatformListener = $scope.$on("platform:changed", function (evt, args) {
        //console.log("platform changed")
        defer.resolve();
        $scope.init();

    });
    $scope.$on('destroy', function (event) {

        changePlatformListener();

    })

    $scope.selectPeriod = function (period) {
        $scope.selectedPeriod = period;
        if ($scope.selectedPeriod === "month") {
            getStats();
        } else if ($scope.selectedPeriod === "day") {
            getStats()
        } else if ($scope.selectedPeriod === "week") {
            getStats()
        } else if ($scope.selectedPeriod === "free") {
            getStats()
        }
    };

    function getStats() {
        if(!$rootScope.selectedPlatform){

            defer.promise.then(function(){
                queuedRequest =false;
                getStats()
            })
            return;
        }
        queuedRequest=false;
        tokenBycompany = {};
        tokenByUser = {};
        var query = {};
        if ($scope.selectedPeriod === 'day') {
            query = {

                "creation_dt": {
                    "$gt": $scope.dayRes.clone().startOf('day').toDate().toISOString(),
                    "$lt": $scope.dayRes.clone().add('1', 'days').endOf("day").toDate().toISOString()
                }

            }

        } else if ($scope.selectedPeriod === 'week') {
            query = {

                "creation_dt": {
                    "$gt": $scope.dayRes.clone().subtract(7, 'd').toDate().toISOString(),
                    "$lt": $scope.dayRes.clone().endOf("day").toDate().toISOString()
                }

            };
        } else if ($scope.selectedPeriod === 'month') {
            query = {

                "creation_dt": {
                    "$gt": $scope.monthRes.clone().startOf('month').toDate().toISOString(),
                    "$lt": $scope.monthRes.clone().endOf('month').toDate().toISOString()
                }

            };
        } else if ($scope.selectedPeriod === 'free') {
            query = {

                "creation_dt": {
                    "$gt": $scope.startRes.clone().toDate().toISOString(),
                    "$lt": $scope.endRes.clone().add('1', 'days').endOf("day").toDate().toISOString()
                }

            };
        } else {
            query = {

                "creation_dt": {
                    "$gt": $scope.startRes.clone().toDate().toISOString(),
                    "$lt": $scope.endRes.clone().add('1', 'days').toDate().toISOString()
                }

            };
        }

        var options = {
            populate: "_user _company_owner"
        }
        iaxeedTokenSrv.query({
            query: query,
            options: options
        }, 100000, 0, function (res) {

            if (res.success) {
                tokenList = res.result.filter(filterTokens);
            } else {
                tokenList = [];
            }

            groupData();
        }, function (er) {
            console.log(er);
        })
    }

    function showPieChart(id, series, opt) {

        if (series && series.length > 0 && series[0].data && series[0].data.length > 0) {

            Highcharts.chart(id, {
                credits: {
                    enabled: false
                },
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie'
                },
                title: {
                    text: opt.name
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            formatter: function (value) {
                                function timeFromSecs(seconds) {
                                    return (
                                        Math.floor(seconds / 86400) + $translate.instant('DAYS_DURATION') +
                                        Math.floor(((seconds / 86400) % 1) * 24) + $translate.instant('HOURS_DURATION') +
                                        Math.floor(((seconds / 3600) % 1) * 60) + $translate.instant('MINUTES_DURATION') +
                                        Math.round(((seconds / 60) % 1) * 60) + $translate.instant('SECONDS_DURATION'));
                                }

                                var label = '<b>' + this.key + '</b> : ' + (this.percentage.toFixed(1)) + "% (";
                                if (opt && opt.isDuration) {

                                    label += timeFromSecs(this.y)
                                } else {
                                    label += this.y
                                }
                                label += ")"
                                return label
                            }
                            // format: '<b>{point.name}</b>:<br>{point.percentage:.1f} %<br>total: {point.value:.1f }'
                        }
                    }
                },
                series: series
            });
        } else {
            Highcharts.chart(id, {
                credits: {
                    enabled: false
                },
                xAxis: {
                    categories: []
                },
                title: {
                    text: opt.name
                },
                series: []

            }, function (chart) {
                chart.renderer.text($translate.instant('NO_DATA_BY_PERIOD'), 100, 86)
                    .css({
                        fontSize: '13px',
                        color: '#666666'
                    }).add();

            })
        }
    }

    function buildCharts() {
        function timeFromSecs(seconds) {
            return (
                Math.floor(seconds / 86400) + $translate.instant('DAYS_DURATION') +
                Math.floor(((seconds / 86400) % 1) * 24) + $translate.instant('HOURS_DURATION') +
                Math.floor(((seconds / 3600) % 1) * 60) + $translate.instant('MINUTES_DURATION') +
                Math.round(((seconds / 60) % 1) * 60) + $translate.instant('SECONDS_DURATION'));
        }
        $scope.tree_data = [];
        var connectionCountSeries = [{
            name: $translate.instant('CONNECTION_COUNT_BY_COMPANY'),
            colorByPoint: true,
            data: []
        }];
        var connectionDurationSeries = [{
            name: $translate.instant('CONNECTION_DURATION_BY_COMPANY'),
            colorByPoint: true,
            data: []
        }];
        if (tokenBycompany && Object.keys(tokenBycompany).length > 0) {
            Object.keys(tokenBycompany).forEach(function (companyId) {
                var company = tokenBycompany[companyId];
                if (company.count) {
                    var tree = {
                        name: company.name,
                        count: company.count,
                        duration: timeFromSecs(company.duration),
                        children: [],
                        icons: {
                            iconLeaf: "fa fa-building",
                            iconCollapse: "fa fa-minus-square",
                            iconExpand: "fa fa-plus-square"
                        }
                    };
                    Object.keys(tokenByUser).forEach(function (userKey) {
                        if (tokenByUser[userKey] && tokenByUser[userKey]._company_owner == companyId) {
                            tree.children.push({
                                name: tokenByUser[userKey].first_name + " " + tokenByUser[userKey].last_name + '(' + tokenByUser[userKey].login + ')',
                                count: tokenByUser[userKey].count,
                                duration: timeFromSecs(tokenByUser[userKey].duration),
                                icons: {
                                    iconLeaf: "fa fa-user"
                                }
                            })
                        }
                    })
                    connectionCountSeries[0].data.push({
                        name: company.name,
                        y: company.count
                    });
                    connectionDurationSeries[0].data.push({
                        name: company.name,
                        y: company.duration
                    });
                    tree.children.sort(function (a, b) {
                        return b.count - a.count;
                    })

                    $scope.tree_data.push(tree);

                }
            })
            $scope.tree_data.sort(function (a, b) {
                return b.count - a.count;
            })
        }

        showPieChart("chartConnexionCount", connectionCountSeries, {
            name: $translate.instant('CONNECTION_COUNT_BY_COMPANY')
        });
        /*showPieChart("chartConnexionTime", connectionDurationSeries, {
            name: $translate.instant('CONNECTION_DURATION_BY_COMPANY'),
            isDuration: true
        });*/

    }

    function groupData() {

        if (tokenList && tokenList.length > 0) {
            try {
                tokenList.forEach(function (token) {

                    if (token._company_owner && token._company_owner.name) {
                        if (!tokenBycompany[token._company_owner._id]) {
                            tokenBycompany[token._company_owner._id] = {
                                duration: 0,
                                count: 0,
                                dates: [],
                                name: token._company_owner.name
                            };
                        }
                        if (token.application && token.application.indexOf("SOCKET_IO") > 0) {
                            tokenBycompany[token._company_owner._id].count++;
                            tokenBycompany[token._company_owner._id].duration += (token.duration || 0);
                            tokenBycompany[token._company_owner._id].dates.push(token);
                        }
                    }
                    if (token._user && token._user._id) {
                        if (!tokenByUser[token._user._id]) {
                            tokenByUser[token._user._id] = {
                                duration: 0,
                                count: 0,
                                dates: [],
                                first_name: token._user.first_name,
                                last_name: token._user.last_name,
                                login: token._user.login,
                                _company_owner: token._company_owner._id
                            };
                        }
                        if (token.application && token.application.indexOf("SOCKET_IO") > 0) {
                            tokenByUser[token._user._id].count++;
                            tokenByUser[token._user._id].duration += (token.duration || 0);
                            tokenByUser[token._user._id].dates.push(token);
                        }
                    }

                })

            } catch (e) {
                console.error(e);
            }
        } else {
            tokenByUser = {};
            tokenBycompany = {};
        }

        try {
            buildCharts();
        } catch (e) {
            console.error(e);
        }
        showWorldMap();
        showDailyChart();
        $scope.col_defs = [{
            field: "count",
            displayName: $translate.instant("CONNECTION_COUNT")
        }];
        $scope.treeExpandProperty = {
            field: "name",
            displayName: $translate.instant("NAME")
        }

    }

    function showWorldMap() {
        try {

            var geoData = [];
            var grouppedByCountry = {};
            if (tokenList && tokenList.length > 0) {
                tokenList.forEach(function (token) {

                    if (token.session_data && token.session_data.geoData && Number.isFinite(token.session_data.geoData.lon) && Number.isFinite(token.session_data.geoData.lat)) {
                        if (token.session_data.geoData.countryCode.toLowerCase() != 'tn') {
                            /* console.log(token.session_data.geoData) */
                        }
                        if (!grouppedByCountry[token.session_data.geoData.countryCode.toLowerCase()]) grouppedByCountry[token.session_data.geoData.countryCode.toLowerCase()] = {
                            count: 0
                        };
                        grouppedByCountry[token.session_data.geoData.countryCode.toLowerCase()].count++;
                        /*geoData.push({
                            "name": token._company_owner && token._company_owner.name ? token._company_owner.name : "",
                            "lat": token.session_data.geoData.lat,
                            "lon": token.session_data.geoData.lon,
                            "country": token.session_data.geoData.countryCode.toLowerCase()
                        })*/
                    }
                })
                Object.keys(grouppedByCountry).forEach(function (key) {
                    geoData.push([key, grouppedByCountry[key].count]);
                })

            } else {
                Highcharts.chart('worldDistribution', {
                    credits: {
                        enabled: false
                    },
                    xAxis: {
                        categories: []
                    },
                    title: {
                        text: $translate.instant('CONNECTION_DISTRIBUTION_BY_COUNTRY')
                    },
                    series: []

                }, function (chart) {
                    chart.renderer.text($translate.instant('NO_DATA_BY_PERIOD'), 100, 86)
                        .css({
                            fontSize: '13px',
                            color: '#666666'
                        }).add();

                })
                return;
            }

            if (geoData.length <= 0) {

                Highcharts.chart('worldDistribution', {
                    credits: {
                        enabled: false
                    },
                    xAxis: {
                        categories: []
                    },
                    title: {
                        text: $translate.instant('CONNECTION_DISTRIBUTION_BY_COUNTRY')
                    },
                    series: []

                }, function (chart) {
                    chart.renderer.text($translate.instant('NO_DATA_BY_PERIOD'), 100, 86)
                        .css({
                            fontSize: '13px',
                            color: '#666666'
                        }).add();

                })
            } else {

                Highcharts.Map('worldDistribution', {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        map: 'custom/world-palestine-highres'
                    },

                    title: {
                        text: $translate.instant('CONNECTION_DISTRIBUTION_BY_COUNTRY')
                    },

                    subtitle: {
                        text: $translate.instant('CONNECTION_GEO_LOCATION_WARNING')
                    },

                    mapNavigation: {
                        enabled: true,
                        buttonOptions: {
                            verticalAlign: 'bottom'
                        }
                    },

                    colorAxis: {
                        min: 0
                    },

                    series: [{
                        data: geoData,
                        name: $translate.instant('CONNECTION_COUNT'),
                        states: {
                            hover: {
                                color: '#BADA55'
                            }
                        },
                        dataLabels: {
                            enabled: true,
                            format: '{point.name}'
                        }
                    }]
                });
            }
        } catch (e) {
            console.error(e);
        }
    }

    function showDailyChart() {

        try {
            var tokenByCompanyByDay = {};
            var dates = []
            if (tokenBycompany && Object.keys(tokenBycompany).length > 0) {

                Object.keys(tokenBycompany).forEach(function (companyId) {
                    if (!tokenByCompanyByDay[companyId]) tokenByCompanyByDay[companyId] = {
                        name: tokenBycompany[companyId].name,
                        dates: {}
                    };
                    tokenBycompany[companyId].dates.forEach(function (token) {
                        if (!tokenByCompanyByDay[companyId].dates[moment(token.creation_dt).format("YYYY-MM-DD")]) tokenByCompanyByDay[companyId].dates[moment(token.creation_dt).format("YYYY-MM-DD")] = 0;
                        tokenByCompanyByDay[companyId].dates[moment(token.creation_dt).format("YYYY-MM-DD")]++;
                        if (dates.indexOf(moment(token.creation_dt).format("YYYY-MM-DD")) < 0)
                            dates.push(moment(token.creation_dt).format("YYYY-MM-DD"));
                    })

                })
                var series = [];
                dates.sort(function (a, b) {
                    if (a && b) {
                        return moment(a, "YYYY-MM-DD").diff(moment(b, "YYYY-MM-DD"));
                    } else if (a) {
                        return 1
                    } else {
                        return -1;
                    }
                })
                Object.keys(tokenByCompanyByDay).forEach(function (companyId) {
                    var serie = {
                        name: tokenByCompanyByDay[companyId].name,
                        data: []
                    };
                    dates.forEach(function (date) {
                        if (tokenByCompanyByDay[companyId].dates[date]) {
                            serie.data.push(tokenByCompanyByDay[companyId].dates[date]);
                        } else {
                            serie.data.push(0);
                        }
                    })
                    series.push(serie);
                });

                console.log(angular.copy(series))
                Highcharts.chart('dailyConnexionCount', {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        type: 'column',
                        options3d: {
                            enabled: true,
                            depth: 100
                        }
                    },

                    title: {
                        text: $translate.instant('CONNECTION_DISTRIBUTION_PER_DAY')
                    },

                    xAxis: {
                        categories: dates,
                        labels: {
                            skew3d: true,
                            style: {
                                fontSize: '16px'
                            }
                        }
                    },

                    yAxis: {
                        allowDecimals: false,
                        min: 0,
                        title: {
                            text: $translate.instant('CONNECTION_COUNT'),
                            skew3d: true
                        }
                    },

                    tooltip: {
                        headerFormat: '<b>{point.key}</b><br>',
                        pointFormat: '<span style="color:{series.color}">\u25CF</span> {series.name}: {point.y} / {point.stackTotal}'
                    },

                    plotOptions: {
                        column: {
                            stacking: 'normal',
                            depth: 40
                        }
                    },

                    series: series
                });
            } else {

                Highcharts.chart('dailyConnexionCount', {
                    credits: {
                        enabled: false
                    },
                    xAxis: {
                        categories: []
                    },
                    title: {
                        text: $translate.instant('CONNECTION_DISTRIBUTION_PER_DAY')
                    },
                    series: []

                }, function (chart) {
                    chart.renderer.text($translate.instant('NO_DATA_BY_PERIOD'), 100, 86)
                        .css({
                            fontSize: '13px',
                            color: '#666666'
                        }).add();

                })

            }
        } catch (e) {

            console.error(e);
        }
    }

});