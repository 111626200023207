angular.module('app.dialogs').controller('synchroneZoneDlgCtrl', function ($scope, I3ZoneSrv) {

    $scope.synchrone = {}
    $scope.valide = false

    I3ZoneSrv.query({}, 10000, 0, function (res) {
        if (res && res.result) {
            $scope.savZones = res.result;


        }
    })

    $scope.getZoneSav = function (sav_zone) {

        $scope.zoneSav = sav_zone

    }
    $scope.getInvoice = function (invoice_zone) {
  
        if ($scope.synchrone.sav_zone)
            $scope.zoneSav.external_id = $scope.synchrone.invoice_zone;
    }

    $scope.addSynchrone = function () {
        $scope.zoneSav.external_id = $scope.synchrone.invoice_zone;

   
       
     
            I3ZoneSrv.updateById($scope.zoneSav._id, $scope.zoneSav, function (response) {
         
                if (response.success) {

                    $scope.closeThisDialog($scope.synchrone);
                    $scope.loadZones()
                    $scope.zoneSav = {};
                } else {

                    $scope.zoneSav = {};
                }
            }, function (err) {
                console.log(err);


                $scope.zoneSav = {};

            })
        




    }
    $scope.cancel = function () {
        $scope.closeThisDialog(false);
    }



    $scope.remove = function (category, index) {
        $scope.zoneSav.travel_costs.splice(index,1)

    }

    $scope.addCategory = function (cate, index) {

        var ZoneCategory = {
            category: cate.category,
            capacity: cate.capacity
        }
        if(!$scope.zoneSav.travel_costs ){
            $scope.zoneSav.travel_costs = [];
            $scope.zoneSav.travel_costs.push(ZoneCategory)
        }else{
       
            if ( $scope.zoneSav.travel_costs.length!= $scope.synchrone.invoice_zone.travel_costs.length ) {
                $scope.zoneSav.travel_costs.push(ZoneCategory);
            }
       
           
        }
        if ( $scope.zoneSav.travel_costs.length== $scope.synchrone.invoice_zone.travel_costs.length ) {
            var sav_Category=$scope.zoneSav.travel_costs.map(function(elem){
                return elem.category
            }).sort();
            var invoice_Category=$scope.synchrone.invoice_zone.travel_costs.map(function(val){
                return val.category
            }).sort();

       
            if(JSON.stringify(sav_Category)==JSON.stringify(invoice_Category)){
              
                $scope.valide = true
            }else{
          

                $scope.valide = false
            }


        }
    }
    
   





});