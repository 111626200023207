angular
    .module('app',
        [
            'app.services',
            'app.directives',
            'app.dialogs',
            'angular.filter',
            'geoServiceProxyModule',
            'ngAnimate',
            'ngSanitize',
            'ngCookies',
            'angular-jwt',
            'ui.router',
            'ui.validate',
            'ui-notification',
            'ui.grid',
            'pascalprecht.translate',
            'angularSpinner',
            'ngProgress',
            'ngDialog',
            'ui.select',
            'smart-table',
            "ui.bootstrap",
            "xeditable",
            "FBAngular",
            'ds.objectDiff',
            "ngFileUpload",
            "ngFileSaver",
            "ngclipboard",
            "blockUI",
            "ng-mfb",
            "underscore",
            'ui.odometer',
            'treeGrid',
            'ngMaterial',
            'ngMessages', 'ui.grid.selection', 'ui.grid.exporter', 'ui.grid.pagination'

        ]);

angular.module("app").factory('ajax-loader', ["$rootScope", "$q", "$window", "usSpinnerService", "CONFIG", "blockUI", function ($rootScope, $q, $window, usSpinnerService, CONFIG, blockUI) {
        var requests = 0;

        function show() {
            if (!requests) {

                $rootScope.$broadcast("ajax-start");

                /*  usSpinnerService.spin('spinner-1');*/
                blockUI.start()
            }
            requests++;

        }

        function hide() {
            requests--;
            if (!requests) {

                $rootScope.$broadcast("ajax-stop");
                /*usSpinnerService.stop('spinner-1');*/
                blockUI.stop();

            }

        }

        return {
            'request': function (config) {

                config.headers = config.headers || {};
                if (config.url.indexOf(CONFIG.API_URI) >= 0) {
                    if ($window.sessionStorage.token && !config.headers.Authorization) {
                        config.headers.Authorization = 'Bearer ' + $window.sessionStorage.token;
                    }
                } else if ($rootScope.plateform &&
                    $rootScope.plateform.url && $rootScope.plateform.type != "NGI_FLEET" &&
                    config.url.indexOf($rootScope.plateform.url) >= 0) {
                    if ($window.sessionStorage.plateformToken && !config.headers.Authorization) {
                        config.headers.Authorization = 'Bearer ' + $window.sessionStorage.plateformToken;
                    }
                }

                if (config.url.indexOf("NOLOAD") === -1)
                    show();
                return $q["when"](config);
            },
            'response': function (response) {

                if (response.config.url.indexOf("NOLOAD") === -1)
                    hide();
                return $q["when"](response);
            },
            'responseError': function (rejection) {

                if (response.config.url.indexOf("NOLOAD") === -1)
                    hide();
                return $q["when"](rejection);
            }
        };
    }]).config(["$httpProvider", function ($httpProvider) {
        $httpProvider.interceptors.push('authInterceptor');
        $httpProvider.interceptors.push('ajax-loader');
    }])

    .decorator("$xhrFactory", [
        "$delegate", "$injector",
        function ($delegate, $injector) {
            return function (method, url) {
                var xhr = $delegate(method, url);
                var $http = $injector.get("$http");
                var callConfig = $http.pendingRequests[$http.pendingRequests.length - 1];
                if (angular.isFunction(callConfig.onProgress))
                    xhr.addEventListener("progress", callConfig.onProgress);
                return xhr;
            };
        }
    ]).filter('filesize', function () {
        var units = [
            'bytes',
            'KB',
            'MB',
            'GB',
            'TB',
            'PB'
        ];

        return function (bytes, precision) {
            if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) {
                return '?';
            }

            var unit = 0;

            while (bytes >= 1024) {
                bytes /= 1024;
                unit++;
            }

            return bytes.toFixed(+precision) + ' ' + units[unit];
        };
    })
    .filter('monitorpriority', function (constantSrv, $filter) {

        var priorities = constantSrv.getMonitoringPriority();
        return function (value, var2) {

            if (Number.isFinite(value) && value >= 0 && value <= 4) {
                var filredPrio = $filter('filter')(priorities, {
                    value: value
                }, true);
                if (filredPrio && filredPrio.length > 0) {
                    return filredPrio[0].code;
                } else {
                    return "-"
                }
            } else {
                return "-";
            }
        };
    });