angular.module('app').controller('adminInstallationsCtrl', function ($rootScope, $scope, I3InstallationGroupSrv, I3PlateformeSrv, plateformHelperSrv, I3ProductSrv, ngDialog, Notification, $state, messageBox, I3InstallationSrv, constantSrv, $translate, iaxeedAssetSrv, NGIFleetAssetSrv, NGIFleetHelperSrv) {
    $scope.itemsByPage = 50;
    $scope.search = {};
    $scope.Object = Object;
    $scope.statusList = constantSrv.getInstallationStatus();
    $scope.installCount = 0;
    /**
     * find out intersection between arrays
     * @param a
     * @param b
     * @returns {Array}
     */
    function intersect(a, b) {
        var d = {};
        var results = [];
        for (var i = 0; i < b.length; i++) {
            d[b[i]] = true;
        }
        for (var j = 0; j < a.length; j++) {
            if (d[a[j]])
                results.push(a[j]);
        }
        return results;
    }
    I3ProductSrv.query({}, 10000, 1, function (res) {
        if (res.success) {
            $scope.products = res.result;
        }
    }, function (err) {
        console.log(err);
    });
    $scope.loadInstallations = function (tableState, ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }
        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;
        var options = {
            populate: [{
                    path: "_product"
                },
                {
                    path: "_assigned_to",
                    model: "user"
                },
                {
                    path: "_installation_group",
                    populate: {
                        path: '_plateforme',
                        model: 'plateforme'
                    }
                }
            ]
        };
        var query = {};
        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            $scope.itemsByPage = number
            start = pagination.start / number + 1 || 1;
        }
        if (tableState.sort && tableState.sort.predicate) {
            options.sortBy = {};
            var predicate = tableState.sort.predicate;
            options.sortBy[predicate] = ((tableState.sort.reverse) ? -1 : 1);
        }
        if (tableState.search && tableState.search.predicateObject) {
            if (tableState.search.predicateObject.asset_name) {
                query["asset_name"] = {
                    "$regex": tableState.search.predicateObject.asset_name,
                    "$options": "i"
                };
            }
            if (tableState.search.predicateObject.client_name) {
                query["_installation_group"] = {
                    client_name: {
                        "$regex": tableState.search.predicateObject.client_name,
                        "$options": "i"
                    }
                };
            }
            if (tableState.search.predicateObject._plateforme) {
                query["_installation_group"] = {
                    _plateforme: {
                        "$regex": tableState.search.predicateObject._plateforme,
                        "$options": "i"
                    }
                };
            }
        }
        if (!$scope.search.asset_name) {
            if (tableState.search && tableState.search.predicateObject) {
                if (tableState.search.predicateObject.asset_name) {
                    $scope.search.asset_name = tableState.search.predicateObject.asset_name
                    query.asset_name = {
                        '$regex': $scope.search.asset_name,
                        '$options': 'i'
                    }
                }
            } else {
                query.asset_name = {
                    '$regex': '',
                    '$options': 'i'
                }
            }
        } else {
            if (tableState.search && tableState.search.predicateObject) {
                tableState.search.predicateObject.asset_name = $scope.search.asset_name
            }
            query.asset_name = {
                '$regex': $scope.search.asset_name,
                '$options': 'i'
            }
        }
        if (tableState.search && tableState.search.predicateObject) {
            $scope.search.asset_name = tableState.search.predicateObject.asset_name
        }
        if ($scope.search) {
            /*
                        if ($scope.search && $scope.search._plateforme) {
                            query._plateforme= $scope.search._plateforme._id;
                        }*/
            if ($scope.search && $scope.search._product) {
                query._product = $scope.search._product._id;
            }
            if ($scope.search.status) {
                query["status"] = {
                    '$regex': $scope.search.status,
                    $options: '-i'
                }
            }
            if ($scope.search._installation_groups && $scope.search._installation_group_plateform) {
                query["_installation_group"] = {
                    '$in': intersect($scope.search._installation_groups.ig, $scope.search._installation_group_plateform.ig)
                }
            } else if ($scope.search._installation_group_plateform) {
                query["_installation_group"] = {
                    '$in': $scope.search._installation_group_plateform.ig
                }
            } else if ($scope.search._installation_groups) {
                query["_installation_group"] = {
                    '$in': $scope.search._installation_groups.ig
                }
            }
        }
        I3InstallationSrv.query({
            query: query,
            options: options
        }, number, start, function (response) {
            if (response.success) {
                $scope.installations = response.result;
                $scope.installAdminCount = response.total_count;
                tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
                tableState.pagination.totalItemCount = response.total_count;
                $scope.tableState = tableState;
            }
        });
    };
    $scope.editInstallation = function (installation, index) {
        try {
            var dialog = ngDialog.open({
                template: 'app/commons/dialogs/installation/installation.list.add.dlg.tpl.html',
                controller: 'installationListAddDlgCtrl',
                scope: $scope,
                resolve: {
                    installations: function () {
                        return angular.copy(installation)
                    },
                    index: function () {
                        return index
                    },
                    plateforme: function () {
                        return installation._installation_group._plateforme
                    }
                }
            });
            dialog.closePromise.then(function (data) {
                if (data) {
                    $scope.loadInstallations();
                }
            });
        } catch (e) {
            console.error(e);
        }
    };
    $scope.searchClient = function ($select) {
        var aggQuery = [{
                $match: {
                    "client_name": {
                        "$regex": $select.search,
                        "$options": "i"
                    }
                }
            },
            {
                $group: {
                    _id: "$client_name",
                    ig: {
                        $push: "$_id"
                    }
                }
            },
            {
                $project: {
                    _id: "$_id",
                    client_name: "$_id",
                    ig: "$ig"
                }
            }
        ];
        I3InstallationGroupSrv.agg({
            aggregate: aggQuery
        }, 10, 0, function (res) {
            $scope.clients = res.result;
        }, function (err) {})
    };
    $scope.searchPlatform = function ($select) {
        // console.log("searchPlatform",$select);
        var aggQuery = [{
                $group: {
                    _id: "$_plateforme",
                    ig: {
                        $push: "$_id"
                    }
                }
            },
            {
                $lookup: {
                    from: "plateforme",
                    localField: "_id",
                    foreignField: "_id",
                    as: "plateforme"
                }
            },
            {
                $project: {
                    _id: "$_id",
                    _plateforme: {
                        $arrayElemAt: ["$plateforme", 0]
                    },
                    ig: "$ig"
                }
            },
            {
                $project: {
                    _id: "$_id",
                    name: "$_plateforme.name",
                    ig: "$ig"
                }
            },
            {
                $match: {
                    "name": {
                        "$regex": $select.search,
                        "$options": "i"
                    }
                }
            }
        ];
        I3InstallationGroupSrv.agg({
            aggregate: aggQuery
        }, 10, 0, function (res) {
            // console.log('ddddddddddddd',res);
            $scope.platforms = res.result;
        }, function (err) {})
    };
    $scope.selectPlateform = function (platform, cb) {
        $scope.assets = [];
        if (platform.type == "NGI_FLEET") {
            NGIFleetHelperSrv.setPlateFormAndLogin(platform, function (res) {
                if (res.result) {
                    cb(null, true);
                } else {
                    cb(true, false);
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("PLATFORM_CNX_ERROR")
                    });
                }
                //console.log("NGIFleetHelperSrv", res);
            }, function (err) {
                cb(true, false);
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("PLATFORM_CNX_ERR")
                });
            })
        } else {
            plateformHelperSrv.setPlateFormAndLogin(platform, function (res) {
                if (res && res.success) {
                    cb(false, true);
                } else {
                    cb(true, false);
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("PLATFORM_CNX_ERROR")
                    });
                }
            }, function (err) {
                cb(true, false);
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("PLATFORM_CNX_ERR")
                });
            })
        }
    }
    $scope.loadAssets = function (index) {
        $scope.assets = [];
        var callback = function (plateforme, cb) {
            //noop
            cb();
        }
        if (!$scope.installations[index]._installation_group || !$scope.installations[index]._installation_group._plateforme || !$scope.installations[index]._installation_group._plateforme) return; // no plateform
        if ($rootScope.plateform && $rootScope.plateform._id != $scope.installations[index]._installation_group._plateforme._id) {
            callback = $scope.selectPlateform;
        }
        callback($scope.installations[index]._installation_group._plateforme, function (err, res) {
            if (err) {
                return;
            } else {
                if ($scope.installations[index]._installation_group._plateforme.type == 'NGI_FLEET') {
                    NGIFleetAssetSrv.getAssetByClient($scope.installations[index]._installation_group.client_id, null, 1000, 0, function (res) {
                        $scope.assets = res.result;
                    }, function (err) {
                        console.log(err);
                    })
                } else {
                    iaxeedAssetSrv.query({
                        query: {
                            "_company_owner": $scope.installations[index]._installation_group.client_id
                        }
                    }, 1000, 0, function (res) {
                        $scope.assets = res.result;
                    }, function (err) {
                        console.error(err);
                    })
                }
            }
        })
    };
    $scope.updateInstallationRequestProp = function (prop, $data) {
        var updateData = {};
        //console.log(prop == "status");
        if (prop == "status") {
            updateData[prop] = $data;
        }
        I3InstallationGroupSrv.updateById($scope.installationRequest._id, updateData, function (res) {
            Notification.success($translate.instant("INSTALLATION_GROUP_UPDATED"));
        }, function (err) {})
    };
    $scope.updateInstallationProp = function (prop, installation, $data) {
        var updateData = {};
        //console.log(prop);
        if (prop == "status") {
            if ($data == "Planned" && !updateData.planned_begin_dt) {
                updateData.planned_begin_dt = new Date();
                installation.planned_begin_dt = new Date();
            } else if ($data == "InProgress" && !updateData.begin_dt) {
                updateData.begin_dt = new Date();
                installation.begin_dt = new Date();
            } else if ($data == "Done" && !updateData.end_dt) {
                updateData.end_dt = new Date();
                installation.end_dt = new Date();
            }
        }
        if (prop == "planned_begin_dt" && installation.status == "New") {
            updateData.status = "Planned";
            installation.status = "Planned"
        }
        if (prop == "begin_dt" && installation.status != "InProgress" && installation.status != "Done") {
            updateData.status = "InProgress";
            installation.status = "InProgress"
        }
        if (prop == "end_dt" && installation.status != "Done") {
            updateData.status = "Done";
            installation.status = "Done";
        }
        updateData[prop] = $data;
        if (prop == "asset") {
            updateData = {
                asset: $data._id,
                asset_name: $data.name
            };
            installation.asset_name = $data.name;
        }
        I3InstallationSrv.updateById(installation._id, updateData, function (res) {}, function (err) {})
    };
    $scope.remove = function ($index) {
        messageBox.confirm("DELETE_INSTALLATION_TITLE", "DELETE_INSTALLATION_MESSAGE").then(function (res) {
            if (res) {
                I3InstallationSrv.delete($scope.installations[$index]._id, function (res) {
                    if (res && res.success) {
                        Notification.success($translate.instant("INSTALLATION_DELETED"));
                        $scope.loadInstallations()
                    } else {
                        Notification.warn($translate.instant("INSTALLATION_DELETE_ERROR"));
                    }
                }, function (err) {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("INSTALLATION_DELETE_ERROR")
                    });
                })
            }
        }, function (reject) {})
    };
    $scope.opened = {};
    $scope.open = function ($event, elementOpened) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.opened[elementOpened] = !$scope.opened[elementOpened];
    };
    $scope.showDetails = function (installation) {
        $state.go("app.admin.installations.details", {
            id: installation._id
        });
    }
});