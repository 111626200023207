angular
  .module("app")
  .controller("dashCtrl", function ($scope, I3CompatibilitySrv, $rootScope, plateformHelperSrv, $window, messageBox, Notification, I3PlateformeSrv, dashboardSrv, constantSrv, $translate) {
    $scope.itemsByPage = 50;
    $scope.search = {};
    $scope.requestEditStatus = [];
    $scope.statusLoaded = false;
    delete $window.sessionStorage.plateformSession
    delete $window.sessionStorage.plateformToken
    delete $window.sessionStorage.plateform
    $rootScope.selectedPlatform = ($rootScope.SESSION.user._plateforme || $window.sessionStorage.plateform) ? $rootScope.SESSION.user._plateforme : undefined,

    $scope.dcount = ($window.sessionStorage.plateform || $rootScope.SESSION.user._plateforme) ? 7 : 5;
      $scope.module = {
        ec: true,
        de: true,
        rec: true,
        iv: true,
        /*  ser:false,
         gc:false, */
        ser: ($window.sessionStorage.plateform || $rootScope.SESSION.user._plateforme) ? true : false,
        gc: ($window.sessionStorage.plateform || $rootScope.SESSION.user._plateforme) ? true : false,
        st: true
      };

    $scope.mdl = [];
    $scope.selectedPlatform = ($rootScope.selectedPlatform) ? $rootScope.selectedPlatform : {
      name: "ALL_PLATEFORMES"
    };


    $scope.getgount = function () {
      return Object.values($scope.module).filter(function (item) {
        return item
      }).length;
    }
   
    dashboardSrv.getDataClients($rootScope.selectedPlatform).then(
      function (res) { // On success


        // console.log('res:', res)
        $scope.mdl = res;
      },
      function (err) { // On failure
        console.log(err);
      }
    );
    $scope.$on('$viewContentLoaded', function (event, viewName, viewContent) {
      if (viewName) {
        //console.log("view loaded")
        //? (1) loading platform
        I3PlateformeSrv.query({
          query: {
            type: "IAXEED"
          }
        }, 10000, 0, function (res) {
          if (res && res.success) {
            $scope.platformList = res.result;
            $scope.platformList.push({
              name: "ALL_PLATEFORMES"
            })

            if ($scope.platformList && $scope.platformList.length > 0) {
              var plateform = null;
              if ($window.sessionStorage.plateform) {
                try {
                  plateform = JSON.parse($window.sessionStorage.plateform)
                } catch (e) {
                  logger.warn("invalid plateform from  cookie select first from list");
                  plateform = null;
                }
              }
              if (!plateform && $rootScope.checkAccess("PLATEFORME", "R", "O")) {
                plateform = $scope.platformList[0]
              }
              if (plateform) {
                for (var i = 0; i < $scope.platformList.length; i++) {
                  if (plateform._id === $scope.platformList[i]._id) {
                    $rootScope.selectedPlatform = $scope.platformList[i];
                    $scope.selectedPlatform = $scope.platformList[i];
                    break;
                  }
                }
              }
              if (!$rootScope.selectedPlatform) {
                $rootScope.selectedPlatform = $scope.selectedPlatform;
                // $rootScope.selectedPlatform = $scope.platformList[0];
                //$scope.selectedPlatform = $scope.platformList[0];

              }

              if (iaxeedSocketSrv.closeListner && typeof iaxeedSocketSrv.closeListner === "function") {
                iaxeedSocketSrv.closeListner('newMsg');
              }

              plateformHelperSrv.setPlateFormAndLogin($rootScope.selectedPlatform, function (res) {
                if (res && res.success) {
                  $scope.init();
                  iaxeedSocketSrv.connect($rootScope.selectedPlatform);
                  iaxeedSocketSrv.on('server:connected', function () {
                    iaxeedSocketSrv.emit('user:connected', {
                      user: JSON.parse($window.sessionStorage.plateformSession).user
                    });

                    $timeout(function () {
                      $rootScope.$broadcast("platform:changed", $rootScope.selectedPlatform);

                      $rootScope.change_dt = new Date();
                    });
                  });
                } else {
                  console.error(res);
                  Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("LOADING_ERROR")
                  });
                }
              }, function (err) {
                console.log(err);
              })
            }
          }
        }, function (err) {
          console.log(err);
        });
        //createTimeline();

      }
    });

    $scope.selectPlatform = function (platform) {

      // console.log(platform)
      $rootScope.selectedPlatform = (platform && platform._id) ? platform : undefined

      if (platform && platform.name == "ALL_PLATEFORMES") {
        $scope.mdl = []
        $scope.dcount =5
        /*  $rootScope.selectedPlatform =platform */
        delete $window.sessionStorage.plateformSession
        delete $window.sessionStorage.plateformToken
        delete $window.sessionStorage.plateform

        dashboardSrv.getDataClients(undefined).then(
          function (res) { // On success


            $scope.mdl = []
            $scope.mdl = res;
          },
          function (err) { // On failure
            console.log(err);
          }
        );
        $scope.module.ser = false;
        $scope.module.gc = false;
        $scope.dcount =$scope.getgount()
        // dashboardSrv.getMonotoring().then(
        //   function (res) { // On success

        //   Object.assign($scope.mdl , res[0].Serve);
        //     //$scope.mdl = res;
        //   },
        //   function (err) { // On failure
        //     console.log(err);
        //   }
        // );

      } else {
        
        $scope.module.ser = true;
        $scope.module.gc = true;
        $scope.dcount =$scope.getgount()
        $rootScope.selectedPlatform = platform
        dashboardSrv.getDataClients(platform).then(
          function (res) { // On success

            $scope.mdl = []
            $scope.mdl = res;
          },
          function (err) { // On failure
            console.log(err);
          }
        );
      }



      /*   $scope.selectedFleet = null;
        $scope.selectedAsset = null;
        $scope.selectedCompany = null;
        $scope.filters._asset = null;
        $scope.filters._fleet = null;
        $scope.filters._client = null;
        $scope.filters.category = null;
        $scope.filters.status = null */
      if ($rootScope.selectedPlatform && $rootScope.selectedPlatform._id == platform._id) {

      } else {
        $rootScope.$broadcast("platform:selected");



      }
      /* $rootScope.selectedPlatform = platform;
        plateformHelperSrv.setPlateFormAndLogin(platform, function (res) {
         if (res && res.success) {
           if (iaxeedSocketSrv.closeListner && typeof iaxeedSocketSrv.closeListner === "function") {
             iaxeedSocketSrv.closeListner('newMsg');
           }
           iaxeedSocketSrv.connect(platform);
           iaxeedSocketSrv.on('server:connected', function () {
             iaxeedSocketSrv.emit('user:connected', {
               user: JSON.parse($window.sessionStorage.plateformSession).user
             });
           });
           $rootScope.$broadcast("platform:changed", $rootScope.selectedPlatform);
           $rootScope.change_dt = new Date();
         } else {
           Notification.error($translate.instant("CHECK_CNX_SETTINGS"));
         }
       }, function (err) {
         if (err.status === -1) {
           Notification.error($translate.instant("IMPOSSIBLE_CNX"));
         } else if (err.status === 401) {
           Notification.error({
             title: $translate.instant("ERROR"),
             message: $translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS")
           });
         } else {
           Notification.error($translate.instant("LOADING_ERROR_PLATFORM"));
         }
       }) */
    };
    $scope.actvEc = function () {
      $scope.module.ec = !$scope.module.ec;
      $scope.dcount = $scope.getgount();
    };
    $scope.actvDe = function () {
      $scope.module.de = !$scope.module.de;
      $scope.dcount = $scope.getgount();
    };
    $scope.actvRec = function () {
      $scope.module.rec = !$scope.module.rec;
      $scope.dcount = $scope.getgount();
    };
    $scope.actvIv = function () {
      $scope.module.iv = !$scope.module.iv;
      $scope.dcount = $scope.getgount();
    };
    $scope.actvSer = function () {

      $scope.module.ser = !$scope.module.ser;
      if ($scope.module.ser == true) {
        $scope.module.gc = true
        if (!$rootScope.selectedPlatform || $rootScope.selectedPlatform.name == "ALL_PLATEFORMES") {

          dashboardSrv.getMonotoring().then(
            function (res) { // On success

              Object.assign($scope.mdl, res[0].Serve);
              //$scope.mdl = res;
            },
            function (err) { // On failure
              console.log(err);
            }
          );
        }



      }

      $scope.dcount = $scope.getgount();
    };
    $scope.actvGc = function () {
      $scope.module.gc = !$scope.module.gc;
      if ($scope.module.gc == true) {
        $scope.module.ser = true;
        if (!$rootScope.selectedPlatform || $rootScope.selectedPlatform.name == "ALL_PLATEFORMES") {

          dashboardSrv.getMonotoring().then(
            function (res) { // On success

              Object.assign($scope.mdl, res[0].Serve);
              //$scope.mdl = res;
            },
            function (err) { // On failure
              console.log(err);
            }
          );
        }
      }
      $scope.dcount = $scope.getgount();
    };
    $scope.actvSt = function () {
      $scope.module.st = !$scope.module.st;
      $scope.dcount = $scope.getgount();
    };








  });