angular.module("app.directives").directive('stockStatus', function ($rootScope,$state, i3HelperSrv, ngDialog) {
    return {
        templateUrl: "app/commons/directives/stock/stockStatus.tpl.html",
        scope: {
            stock:"=",
            products:"="
        },
        link: function (scope, elem, attr) {


        }
    }
})