angular.module("app").controller('ActionLogDiffCtrl', function ($scope, $filter, I3ClaimSrv, I3RoleSrv, I3UserSrv, I3InstallationSrv, I3InstallationGroupSrv, I3InterventionSrv, I3ProductSrv, I3ActionLogSrv, I3PlateformeSrv, I3SettingSrv, I3DocumentSrv, ObjectDiff, action_log) {



    var serviceByModel = {
        "installation": I3InstallationSrv,
        "installationgroup": I3InstallationGroupSrv,
        "intervention": I3InterventionSrv,
        "claim": I3ClaimSrv,
        "product": I3ProductSrv,
        "document": I3DocumentSrv,
        "setting": I3SettingSrv,
        "plateforme": I3PlateformeSrv,
        "actionLog": I3ActionLogSrv,
        "country": null,
        "user": I3UserSrv,
        role: I3RoleSrv,
        svs_city: null,
        token: null,
        tt_site: null
    };


    $scope.action_log = action_log;
    if (serviceByModel[action_log._model.toLowerCase()] && action_log._object && action_log._object._id) {
        //getBy id

        if (action_log._action == "UPDATE") {
            //console.log('rrrrrrrrrrrrrrrrrrrrrr',serviceByModel[action_log._model.toLowerCase()])

            serviceByModel[action_log._model.toLowerCase()].getById(action_log._object._id, function (res) {


                if (res.success && res.result) {
                    $scope.currentObject = res.result;
                    var diff = ObjectDiff.diff(res.result, action_log._object);

                    $scope.changes = ObjectDiff.toJsonDiffView(diff);
                    //console.log("changes #" + $scope.changes + "#")


                } else {
                    var diff = ObjectDiff.diffOwnProperties({}, action_log._object);
                    $scope.changes = ObjectDiff.toJsonDiffView(diff);
                }
            }, function (err) {
                var diff = ObjectDiff.diffOwnProperties({}, action_log._object);
                $scope.changes = ObjectDiff.toJsonDiffView(diff);
            })


        } else {
            var diff = ObjectDiff.diffOwnProperties({}, action_log._object);
            $scope.changes = ObjectDiff.toJsonDiffView(diff);
        }
    } else {
        $scope.changes = ObjectDiff.toJsonView(action_log._object);
    }





});