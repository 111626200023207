angular.module('app.dialogs').controller('settingRoleDlgCtrl', function ($scope, constantSrv, $rootScope, ngDialog, I3RoleSrv, I3SettingSrv, Notification, $translate, i3HelperSrv, updateCfg, isRoleConfig, modelId, isNewRole) {

    try {
        $scope.res = [];
        var keys = '';
        var statusKeys = [];
        var modelName="";
        $scope.selectedStatus = { status: [], key: {} };
        $scope.listStatus = [];
        // console.log(modelId)
        if (modelId == "installation_group") {
            modelName = "installation_request"

        } else {

            modelName = modelId

        }



        constantSrv.getStatusbyModel(modelName).getStatus(function (err, status) {

            $scope.listStatus = status
        });
       

        if (isRoleConfig && isNewRole) {
            var roles = [];

            $scope.isNewRole = true;

            $scope.selectedStatus.key.value = false;
            $scope.selectedStatus.exception = false;
            $scope.all = false;
            $scope.nothing = false;
            $scope.roleConfig = {
                role: null,
                create: false,
                update: false
            };

            if ($scope.setting.rolesConfig && $scope.setting.rolesConfig.length > 0) {
                roles = $scope.setting.rolesConfig.map(function (e) {
                    return e.role;
                });
            }


            var query = {};
            if (roles && roles.length > 0) {
                query = {
                    _id: {
                        $nin: roles
                    }
                };
            }
            I3RoleSrv.query({
                query: query
            }, 10000, 0, function (data) {
                if (data.success) {
                    $scope.roles = data.result;
                } else {
                    console.error(data.error)
                }

            }, function (data) {
                // console.log(data)
            });
        }

        i3HelperSrv.getFieldsByCollectionName(modelName.toUpperCase(), function (res) {
            // console.log("res",res)
            if (res) {

                $scope.fields = [];
                //  var fieldKeys = Object.keys(res);
                var fieldKeys = Object.keys(res).filter(function (val) {

                    return val === 'status'
                });

                var res = "";
                if (typeof updateCfg != 'boolean' && updateCfg != undefined && updateCfg.status && updateCfg.status.length > 0) {
                    $scope.selectedStatus.key.value = true;
                    res = updateCfg.status;
                    $scope.selectedStatus.exception = true;
                    $scope.selectedStatus.status = res;
                    $scope.all = false;
                    // $scope.selectedStatus.all = false;
                    if (res.length > 0) {
                        $scope.all = false;
                        $scope.selectedStatus.key.value = true;
                        $scope.selectedStatus.all = false;
                        $scope.selectedStatus.exception = true;
                    }



                } else if (updateCfg == 'status') {

                    $scope.selectedStatus.key.value = true;
                    $scope.selectedStatus.all = true;
                    $scope.all = false;
                    $scope.selectedStatus.exception = false;


                }
                angular.forEach(fieldKeys, function (e) {

                    $scope.fields.push({
                        code: e,
                        value: $scope.selectedStatus.key.value || false
                    });

console.log("ssssssssssss",
$scope.fields)


                 
                    /* console.log(e, res.indexOf(e) >= 0)
                     if (res.indexOf(e) >= 0) {
                         $scope.fields.push({
                             code: e,
                             value: true
                         });
                     } else {
                         $scope.fields.push({
                             code: e,
                             value: false
                         });
                     }*/


                })
                if (updateCfg === true) {
                    $scope.all = true;
                    $scope.selectedStatus.key.value = false;
                    $scope.nothing = false;
                } if (updateCfg === false) {

                    $scope.nothing = true;
                    $scope.all = false;
                    $scope.selectedStatus.key.value = false;
                }


            } else {
                console.log('error')
            }
        }, function (err) {
            console.log(err)
        });

        $scope.getSelectedFields = function (status) {

            $scope.all = false;
            $scope.nothing = false;
            $scope.selectedStatus.key = status;
            if ($scope.selectedStatus.status.length > 0) {
                $scope.selectedStatus.all = false
            } else {
                $scope.selectedStatus.all = true
            }
            keys = '';
            angular.forEach($scope.fields, function (e, index) {

                if (e.value == true) {
                    if (keys === '') {
                        keys += e.code;
                    } else {
                        keys += " " + e.code;
                    }
                }

            })

            //console.log('$scope.fields', $scope.fields)
        };
        $scope.getSelectedStatus = function (statusList) {
            $scope.selectedStatus.exception = false



        }
        $scope.getSelectedStatusList = function (statusList) {


            statusKeys = statusList

        }
        $scope.getNothing = function () {

            $scope.all = false;
            $scope.selectedStatus.key.value = false;
            $scope.fields[0].value = false;
            $scope.selectedStatus.all = false;
            statusKeys = []
        }
        $scope.getAll = function () {
            $scope.nothing = false;
            $scope.selectedStatus.key.value = false;
            $scope.fields[0].value = false;
            $scope.selectedStatus.all = false;
            statusKeys = []
        }

        $scope.confirm = function () {

            if (isNewRole) {
                var roleConfig = angular.copy($scope.roleConfig);
                roleConfig.role = $scope.roleConfig.role._id;

                if ($scope.all) {
                    roleConfig.update = true;
                    $scope.setting.rolesConfig.push(roleConfig);
                    $scope.closeThisDialog();
                } else if ($scope.selectedStatus.all) {

                    roleConfig.update = 'status';
                    $scope.setting.rolesConfig.push(roleConfig);
                    $scope.closeThisDialog();

                } else if (statusKeys && statusKeys !== '' && statusKeys.length > 0 && $scope.selectedStatus.exception == true) {

                    roleConfig.update = { status: statusKeys };
                    $scope.setting.rolesConfig.push(roleConfig);
                    $scope.closeThisDialog();
                } else {

                    roleConfig.update = false;
                    $scope.setting.rolesConfig.push(roleConfig);

                    $scope.closeThisDialog();
                }
            } else {if ($scope.all) {

                    $scope.closeThisDialog(true);
                } else if ($scope.selectedStatus.all) {

                        var status = 'status'
                        $scope.closeThisDialog(status);

                    } else if (statusKeys && statusKeys !== '' && statusKeys.length > 0 && $scope.selectedStatus.exception == true) {
                        if (statusKeys.length > 0) {
                            $scope.closeThisDialog({ status: statusKeys });
                        }
                        $scope.closeThisDialog();
                    } else if ($scope.nothing) {

                        $scope.closeThisDialog(false);
                    } else {

                        $scope.closeThisDialog();
                    }
            }

        }
    } catch (e) {
        console.error(e);
    }



    $scope.selectStatusEx = function (exStatus) {

        $scope.selectedStatus.all = false;
        if (exStatus == true) {

            $scope.selectedStatus.status = []
        }

    }

});