angular.module("app").controller("articleEditCtrl", function ($scope, $stateParams,$rootScope, $filter, $state, $translate, ngDialog, Notification,I3ArticleSrv) {
    $scope.article={}
    I3ArticleSrv.query(
        {query:
            {_id:$stateParams.articleId},
            options: {
                populate: [{
                    path: "_product",
                    model: "product"
                },{
                    path: "_stock",
                    model: "stock" 
                }]
            }

        }, 10000, 1, function (res) {
        if (res.success) {
            $scope.article= res.result[0];
        
        }
    }, function (err) {
        console.log(err);
    });
    

    $scope.save = function () {
        var filterList= $scope.articles.filter(function(val){
            return val._id!=$scope.article._id
        })
        var filterImei=filterList.filter(function(val){
            return val.imei==$scope.article.imei
        })
        var filterSerial= filterList.filter(function(val){
            return val.serialNumber==$scope.article.serialNumber
        })
            if(filterImei && filterImei.length>0){
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("IMEI_ALREADY_EXIST")
                });
            }else if(filterSerial && filterSerial.length>0){
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("SERIAL_NUMBER_ALREADY_EXIST")
                });
            }else{
                $scope.article._plateforme = $scope.article._stock._plateforme
                I3ArticleSrv.updateById($scope.article._id, $scope.article, function (response) {

            if (response.success) {
                Notification.success({
                    title: $translate.instant("ARTICLE_UPDATED"),
                    message: $translate.instant("ARTICLE_UPDATED_SUCC")
                });
                $state.go('app.stocks.articles');
                $scope.article = {};
            } else {
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_UPDATE") + "!"
                });
                $scope.article = {};
            }
        }, function (err) {
            console.log(err);

            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("ERROR_OCCURED_UDPDATE") + "!"
            });
            $scope.article = {};

        })
    }
    };


    $scope.updateVirtualStock=function(stok,virtualStock){


          stock =$scope.article._stock
        virtualStock =$scope.article.virtual_stock
        if(stok && stok.virtual_stock &&  stok.virtual_stock.length>0){
            var filtred= stok.virtual_stock.filter(function(val){
                return val._id == virtualStock;
            })
            if(!filtred  || filtred.length<=0){
                $scope.article.virtual_stock=null;
            }
        }else if(stok){
            $scope.article.virtual_stock=null;
        }



    }


    $scope.cancel = function () {
        $scope.article = {};
        $state.go('app.stocks.articles');
    };
    
    })