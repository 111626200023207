angular.module("app").controller("receptionAddCtrl", function ($scope, $rootScope, $q, $filter, $state, $translate, blockUI, ngDialog, Notification, I3ArticleSrv, I3StockSrv, I3ProductSrv, I3ReceiptSlipSrv) {
    $scope.new_articles = [];
    $scope.products = [];
    //$scope.articles = [];
    $scope.old_articles = [];
    $scope.serials = [];
    $scope.imps_data = [];
    //$scope.article = {};
    $scope.receipt = {};
    $scope.step = 'step1';
    $scope.all_recepeits = [];
    $scope.tracked_products = [];
    $scope.used_products = [];
    $scope.used_products_ids = [];
    $scope.all_products = [];
    $scope.temp_articles = [];
    //$scope.val_warning = false;
    $scope.verify_articles = false;
    $scope.globalv = false;
    $scope.globalv2 = false;
    $scope.duplicate_imei = [];
    $scope.duplicate_serial = [];
    $scope.duplicate_ref = false;
    $scope.errors = [];
    $scope.vld_mdl = {
        total_count: 0,
        _products: [],
        ids: []
    }
    I3ProductSrv.query({}, 10000, 1, function (res) {
        if (res.success) {
            $scope.all_products = res.result.filter(function (val) {
                return val.type == "MATERIAL"
            });
            // console.log("TCL: $scope.all_products", $scope.all_products)
            $scope.unused_products = angular.copy($scope.all_products);
        }
    }, function (err) {
        console.log(err);
    });
    /**
     * Cancel creating/editing stockManagement
     */
    $scope.cancel = function () {
        $state.go('app.stocks.reception');
    };
    $scope.get_articles = function () {
        I3ArticleSrv.query({
            query: {},
            options: {
                populate: [{
                    path: "_product",
                    select: 'name'
                }]
            }
        }, 10000, 1, function (res) {
            if (res.success) {
                $scope.old_articles = res.result
                //console.log("$scope.old_articles", $scope.old_articles)
            }
        }, function (err) {
            console.log(err);
        });
    }();
    $scope.get_recepeits = function () {
        I3ReceiptSlipSrv.query({
            query: {},
            options: {}
        }, 10000, 1, function (res) {
            if (res.success) {
                $scope.all_recepeits = res.result
                //console.log("$scope.rece", $scope.all_recepeits)
            }
        }, function (err) {
            console.log(err);
        });
    }();
    $scope.recheck = function () {
        $scope.used_products_ids = [];
        $scope.used_products = $scope.products.filter(function (product) {
            if (product._product && product._product._id) {
                $scope.used_products_ids.push(product._product._id)
            }
            return product._product;
        })
        $scope.unused_products = $scope.all_products.filter(function (product) {
            if ($scope.used_products_ids.indexOf(product._id) == -1) {
                return true
            } else {
                return false
            }
        })
    };
    $scope.check_serial = function (serialNumber, index) {
        var filterSerial = $scope.old_articles.filter(function (val) {
            return val.serialNumber == serialNumber
        })
        var filterSerial2 = $scope.new_articles.filter(function (val) {
            return val.serialNumber == serialNumber
        })
        if ((filterSerial && filterSerial.length > 0) || (filterSerial2 && filterSerial2.length > 0)) {
            $scope.duplicate_serial[index] = true;
            return false
        } else {
            $scope.duplicate_serial[index] = false;
            return true
        }
    };
    $scope.check_serial2 = function (serialNumber) {
        var filterSerial = $scope.old_articles.filter(function (val) {
            return val.serialNumber == serialNumber
        })
        if (filterSerial && filterSerial.length > 0) {
            //$scope.duplicate_serial2[index] = true;
            return false
        } else {
            //$scope.duplicate_serial2[index] = false;
            return true
        }
    };
    $scope.check_iemi2 = function (imei) {
        var filterImei = $scope.old_articles.filter(function (val) {
            return val.imei == imei
        })
        if (filterImei && filterImei.length > 0) {
            //$scope.duplicate_serial2[index] = true;
            return false
        } else {
            //$scope.duplicate_serial2[index] = false;
            return true
        }
    };
    $scope.check_serials2 = function (serials) {
        var nodup = true;
        serials.forEach(function (serial) {
            if (!$scope.check_serial2(serial)) {
                nodup = false;
            }
        })
        return nodup;
    };
    $scope.check_iemis2 = function (iemis) {
        var nodup = true;
        iemis.forEach(function (iemi) {
            if (!$scope.check_iemi2(iemi)) {
                nodup = false;
            }
        })
        return nodup;
    };
    $scope.check_ref = function (ref) {
        var filterRef = $scope.all_recepeits.filter(function (val) {
            return val.ref == ref
        })
        if (filterRef && filterRef.length > 0) {
            $scope.duplicate_ref = true;
            return false
        } else {
            $scope.duplicate_ref = false;
            return true
        }
    };
    $scope.check_imei = function (imei, index) {
        var filterImei = $scope.old_articles.filter(function (val) {
            return ((val.imei == imei) && (imei != undefined))
        })
        var filterImei2 = $scope.new_articles.filter(function (val) {
            return ((val.imei == imei) && (imei != undefined))
        })
        if ((filterImei && filterImei.length > 0) || (filterImei2 && filterImei2.length > 0)) {
            $scope.duplicate_imei[index] = true;
            return false
        } else {
            $scope.duplicate_imei[index] = false;
            return true
        }
    };
    $scope.get_article_count_by_id = function (id) {
        var product_count = $scope.new_articles.filter(function (article) {
            if (article._product && article._product._id)
                return article._product._id == id;
            else
                return 0;
        })
        //console.log(product_count);
        return product_count.length;
    };
    $scope.get_all_articles_count_by_id = function () {
        $scope.errors = [];
        $scope.vld_mdl.ids.forEach(function (vld) {
            var countbyid = $scope.get_article_count_by_id(vld._id);
            if (countbyid != vld.qty) {
                //console.log(vld.name + 'countr should be' + vld.qty + 'but it is' + countbyid);
                $scope.errors.push({
                    id: vld._id,
                    name: vld.name,
                    current_count: countbyid,
                    vaild_count: vld.qty
                });
            }
        })
        if ($scope.errors.length > 0)
            return false;
        else
            return true;
    };
    $scope.recheck_articles_count = function () {
        //console.log($scope.get_all_articles_count_by_id());
        if ($scope.vld_mdl.total_count == $scope.new_articles.length) {
            return true
        } else {
            //console.log('total count count should be' + $scope.vld_mdl.total_count + 'but it is' + $scope.new_articles.length);
            return false
        }
    };
    $scope.recheck_articles = function () {
        if ($scope.get_all_articles_count_by_id() && $scope.recheck_articles_count()) {
            $scope.verify_articles = true;
            //console.log("TCL: $scope.recheck_articles -> $scope.verify_articles", $scope.verify_articles)
        } else {
            $scope.verify_articles = false;
            //console.log("TCL: $scope.recheck_articles -> $scope.verify_articles", $scope.verify_articles)
        }
    };
    $scope.change2 = function () {
        $scope.recheck_articles();
    };
    $scope.change = function () {
        $scope.recheck();
        //console.log($scope.products)
        //console.log($scope.receipt._stock)
    };
    $scope.add = function () {
        $scope.recheck();
        $scope.products.push({});
    };
    $scope.add();
    $scope.remove_product = function (index) {
        $scope.products.splice(index, 1);
        $scope.recheck();
    };
    $scope.validation_mdl = function () {
        $scope.vld_mdl = {
            total_count: 0,
            _products: [],
            ids: []
        };
        var product_v = angular.copy($scope.used_products.filter(function (used_p) {
            return used_p._product.is_trackSerial;
        }));
        var i = 0;
        //console.log("TCL: $scope.validation_mdl -> product_v", product_v)
        product_v.forEach(function (product) {
            $scope.vld_mdl._products.push(product._product);
            $scope.vld_mdl.total_count = $scope.vld_mdl.total_count + product.qty;
            $scope.vld_mdl.ids.push({
                _id: product._product._id,
                qty: product.qty,
                name: product._product.name,
                imported_articles: [],
                valid: false,
                index: i,
                imported_errors_rows: [],
                duplicate_rows: [],
                errors: [],
                success: [],
                serials: []
            });
            i = i + 1;
        });
        $scope.recheck_articles();
        //console.log("TCL: $scope.validation_mdl -> scope.vld_mdl", $scope.vld_mdl);
    };
    $scope.importOptions = {
        onUpdate: function (er, res) {
            console.log("TCL: er", er)
            var index = $scope.current_file_index;
            var num = index + 1;
            console.log(res);
            $scope.$apply(function () {
                console.log("TCL: index", index);
                console.log("TCL: index2", $scope.imps_data[index]);
                $scope.imps_data[index].imported_articles = res.articles;
                $scope.imps_data[index].imported_errors_rows = res.errors.rows;
                $scope.imps_data[index].duplicate_rows = res.errors.duplicate_rows;
                $scope.imps_data[index].duplicate_imeis_rows = res.errors.duplicate_imeis_rows;
                $scope.imps_data[index].serials = res.serials;
                $scope.imps_data[index].imeis = res.imeis;
                $scope.check_imported_file(index);
               /*  console.log($scope.imps_data);
                Notification.success({
                    title: $translate.instant('FILE_UPLOADED'),
                    message: $translate.instant('FILE_UPLOADED') + ": article " + num,
                }); */
            })
        }
    };
    $scope.check_imported_file = function (index) {
        $scope.imps_data[index].errors = [];
        $scope.imps_data[index].success = [];
        $scope.imps_data[index].valid = false;
        if ($scope.imps_data[index].imported_articles.length > 0) {
            $scope.imps_data[index].success.push("IMP_VALID_NO_ARTICLES");
            if ($scope.imps_data[index].imported_articles.length == $scope.imps_data[index].qty) {
                $scope.imps_data[index].success.push("IMP_VALID_QTY");
                if ($scope.imps_data[index].imported_errors_rows.length == 0) {
                    $scope.imps_data[index].success.push("IMP_NO_EMPTY_SERIAL_NUMBER");
                    if ($scope.imps_data[index].duplicate_rows.length == 0) {
                        $scope.imps_data[index].success.push("IMP_NO_DUPLICATE_SERIAL_NUMBER");
                        if ($scope.check_serials2($scope.imps_data[index].serials)) {
                            $scope.imps_data[index].success.push("IMP_NO_DUP_SERIAL_NUMBER_IN_DATABASE");
                        } else {
                            $scope.imps_data[index].errors.push("IMP_N_DUPLICATE_SERIAL_NUMBER_IN_DATABASE");
                        }
                    } else {
                        $scope.imps_data[index].errors.push("IMP_N_DUPLICATE_SERIAL_NUMBER");
                    }
                    if ($scope.imps_data[index].duplicate_imeis_rows.length == 0) {
                        $scope.imps_data[index].success.push("IMP_NO_DUPLICATE_IMEI_NUMBER");
                        if ($scope.check_iemis2($scope.imps_data[index].imeis)) {
                            $scope.imps_data[index].success.push("IMP_NO_DUP_IMEI_NUMBER_IN_DATABASE");
                        } else {
                            $scope.imps_data[index].errors.push("IMP_N_DUP_IMEI_NUMBER_IN_DATABASE");
                        }
                    } else {
                        $scope.imps_data[index].errors.push("IMP_N_DUPLICATE_IMEI_NUMBER");
                    }
                } else {
                    $scope.imps_data[index].errors.push("IMP_N_EMPTY_SERIAL_NUMBER");
                }
            } else {
                $scope.imps_data[index].errors.push("IMP_N_VALID_QTY");
            }
        } else {
            $scope.imps_data[index].errors.push("IMP_N_VALID_NO_ARTICLES")
        }
        if ($scope.imps_data[index].errors.length == 0) {
            $scope.imps_data[index].valid = true;
            $scope.generate_import();
            return true
        } else {
            $scope.imps_data[index].valid = false;
            return false
        }
    };
    $scope.hasDuplicates = function (array) {
        var valuesSoFar = Object.create(null);
        for (var i = 0; i < array.length; ++i) {
            var value = array[i];
            if (value in valuesSoFar) {
                return true;
            }
            valuesSoFar[value] = true;
        }
        return false;
    };
    $scope.generate_import = function () {
        $scope.serials = [];
        $scope.imeis = [];
        var toreduce = angular.copy($scope.imps_data);
        $scope.all_imported_articles = toreduce.reduce(function (r, a) {
            var product_id = a._id;
            console.log("TCL: $scope.generate_import -> product_id", product_id)
            if (a.imported_articles.length > 0) {
                a.imported_articles.forEach(function (imported_article) {
                    $scope.serials.push(imported_article.serialNumber);
                    if (imported_article.imei) {
                        $scope.imeis.push(imported_article.imei)
                    }
                    imported_article.virtual_stock = $scope.receipt.virtual_stock._id;
                    imported_article._stock = $scope.receipt._stock._id;
                    imported_article._receiptSlip = $scope.receipt.ref;
                    imported_article._product = product_id;
                    r.push(imported_article);
                });
            }
            return r;
        }, []);
        $scope.globalv = $scope.hasDuplicates($scope.serials);
        $scope.globalv2 = $scope.hasDuplicates($scope.imeis);
        console.log("TCL: $scope.generate_import -> $scope.globalv", $scope.globalv)
        console.log("TCL: $scope.generate_import -> $scope.all_imported_articles", $scope.all_imported_articles)
    };
    $scope.reset = function (imp_data, index) {
        $scope.current_file_index = imp_data.index;
        console.log("TCL: $scope.reset -> $scope.current_file_index", $scope.current_file_index)
        var file = $("#file").val("");
        file.value = ''
    };
    $scope.back = function () {
        $scope.new_articles = [];
        $scope.all_imported_articles = [];
        $scope.vld_mdl = {
            total_count: 0,
            _products: [],
            ids: []
        }
        $scope.step = 'step1';
    };
    $scope.continue = function () {
        var promise = $scope.asyncdata();
        promise.then(function () {
            $scope.step = 'step2';
        })
    };
    $scope.continue2 = function () {
        var promise = $scope.asyncdata();
        promise.then(function () {
            $scope.imps_data = angular.copy($scope.vld_mdl.ids);
            $scope.step = 'step3';
        })
    };
    $scope.mapdata = function () {
        //console.log($scope.products);
        //console.log($scope.receipt);
        if ($scope.products && $scope.products.length > 0) {
            $scope.new_articles = [];
            $scope.tracked_products = [];
            $scope.products.forEach(function (product) {
                //console.log("TCL: $scope.import -> product", product)
                if (product._product.is_trackSerial) {
                    /* product.article._product = product._product._id;
                    product.article.qty = product.qty;
                    product.article.virtual_stock = $scope.receipt.virtual_stock._id;
                    product.article._stock = $scope.receipt._stock._id;
                    product.article.receiptSlip = $scope.receipt.ref; */
                    //$scope.tracked_products.push(product);
                    for (var i = 0; i < product.qty; i++) {
                        $scope.new_articles.push({
                            virtual_stock: $scope.receipt.virtual_stock._id,
                            _stock: $scope.receipt._stock._id,
                            _receiptSlip: $scope.receipt.ref
                        });
                    }
                }
            });
            $scope.validation_mdl();
            $scope.receipt.products = $scope.products;
            return true;
        } else {
            return false;
        }
    };
    $scope.check_track = function () {
        var product_v = angular.copy($scope.used_products.filter(function (used_p) {
            return used_p._product.is_trackSerial;
        }));
        if (product_v.length > 0) {
            return true
        } else return false;
    };
    $scope.test = function () {
        console.log("$scope.new_articles", $scope.new_articles);
        //console.log("$scope.receipt", $scope.receipt);
        console.log("$scope.products", $scope.products);
        console.log("$scope.used_products", $scope.used_products);
        //console.log("$scope.old_articles", $scope.old_articles);
        console.log("$scope.vld_mdl", $scope.vld_mdl);
        console.log("$scope.used_products_ids", $scope.used_products_ids);
        console.log("$scope.imps_data", $scope.imps_data);
        console.log("$scope.all_imported_articles", $scope.all_imported_articles);
        /* $scope.recheck_articles_count();
        $scope.get_all_articles_count_by_id(); */
    };
    $scope.asyncdata = function () {
        return $q(function (resolve, reject) {
            setTimeout(function () {
                if ($scope.mapdata()) {
                    resolve("success");
                } else {
                    reject('not allowed');
                }
            }, 0);
        });
    };
    $scope.save = function () {
        if ($scope.step == 'step1') {
            var promise = $scope.asyncdata();
            promise.then(function () {
                $scope.receipt._plateforme = $scope.receipt._stock._plateforme
                I3ReceiptSlipSrv.add($scope.receipt, function (response) {
                    if (response.success) {
                        //$scope.add_articles();
                        Notification.success({
                            title: $translate.instant('RECEIPT_CREATED_TITLE'),
                            message: $translate.instant("RECEIPT_CREATED_SUC")
                        });
                        $state.go('app.stocks.reception');
                        $scope._plateforme = {};
                    } else {
                        Notification.error({
                            title: $translate.instant('ERROR'),
                            message: $translate.instant("ERROR_OCCURED_CREATE")
                        });
                        $scope.user = {};
                    }
                }, function (err) {
                    var msg = "";
                    if (err.error.errorString) {
                        msg = err.error.errorString;
                    }
                    Notification.error({
                        title: $translate.instant('ERROR'),
                        message: $translate.instant("ERROR_OCCURED_CREATE")
                    });
                })
            }, function () {
                console.log('Failed:');
            });
        } else {

            $scope.receipt._plateforme = $scope.receipt._stock._plateforme
            I3ReceiptSlipSrv.add($scope.receipt, function (response) {
                if (response.success) {

                    if ($scope.step == 'step2') {
                        $scope.add_articles();
                    } else if ($scope.step == 'step3') {
                        $scope.add_articles2();
                    }
                    Notification.success({
                        title: $translate.instant('RECEIPT_CREATED_TITLE'),
                        message: $translate.instant("RECEIPT_CREATED_SUC")
                    });
                    $state.go('app.stocks.reception');
                    $scope._plateforme = {};
                } else {
                    Notification.error({
                        title: $translate.instant('ERROR'),
                        message: $translate.instant("ERROR_OCCURED_CREATE")
                    });
                    $scope.user = {};
                }
            }, function (err) {
                var msg = "";
                if (err.error.errorString) {
                    msg = err.error.errorString;
                }
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_CREATE")
                });
            })
        }
    };

    $scope.add_articles2 = function () {
        console.log($scope.all_imported_articles);
        $scope.all_imported_articles.map(function(e){
            e._plateforme= $scope.receipt._plateforme;
            e.confirmed = false;
            return e ;
        })
        I3ArticleSrv.addMultiple($scope.all_imported_articles, function (res) {
            if (res.success) {
                Notification.success({
                    title: $translate.instant("ARTICLES_ADDED"),
                    message: $translate.instant("ARTICLES_ADDED_SUCCESSFULLY")
                });
            } else {
                Notification.error({
                    title: "ARTICLES_ERROR",
                    message: $translate.instant("ARTICLES_ADDED_ERROR")
                });
            }
        }, function (err) {
            Notification.error({
                title: "STOCK_MOVED",
                message: $translate.instant("ARTICLES_ADDED_ERROR")
            });
        })
    };
    $scope.add_articles = function () {
        $scope.new_articles.map(function(e){
            e.confirmed=false;
            e._plateforme= $scope.receipt._plateforme;
            return e ;
        })
        I3ArticleSrv.addMultiple($scope.new_articles, function (res) {
            if (res.success) {
                Notification.success({
                    title: $translate.instant("ARTICLES_ADDED"),
                    message: $translate.instant("ARTICLES_ADDED_SUCCESSFULLY")
                });
            } else {
                Notification.error({
                    title: "ARTICLES_ERROR",
                    message: $translate.instant("ARTICLES_ADDED_ERROR")
                });
            }
        }, function (err) {
            Notification.error({
                title: "STOCK_MOVED",
                message: $translate.instant("ARTICLES_ADDED_ERROR")
            });
        })
    };
})