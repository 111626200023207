angular.module('app').filter('productByProduct', function() {
    return function (product,productList) {


                var filteredProduct=productList;
                var productId=product;
                if(product && product._id){
                    productId=product._id;
                }
                if(filteredProduct && filteredProduct.length>0) {
                    filteredProduct = filteredProduct.filter(function (val) {
                        if (val._id == productId) {
                            return true;
                        } else {
                            return false;
                        }
                    })
                   if (filteredProduct.length > 0) {
                        if (filteredProduct[0]) {
                            return filteredProduct[0].name;
                        }
                    }
                }else{
                    return "" ;
                }

    };
})
