angular.module('app.services')
    .factory('iaxeedSocketSrv', function ($rootScope, $timeout, $window) {
        var service = {};
        service.isConnected = false;
        var eventListener = {};
        service.connect = function (plateform, clb) {

            if (!$window.sessionStorage.plateformSession || !$window.sessionStorage.plateformToken) {
                console.error('Cannot connect  to RealTime notification cause session invalid')
                if (!clb) clb = function () {};
                return clb("RT_SESSION_NOT_FOUND");
            }
            if (typeof io === "undefined") {
                service.on = function (eventName, callback) {
                    console.error("Can't listnen to event " + eventName + ', No RT Server');
                };
                service.emit = function (eventName, callback) {
                    console.error("Can't emit event " + eventName + ', No RT Server');
                };
                service.isConnected = false;

                if (clb) clb(false)
            } else {
                if ($rootScope.RT_CONNECTED && $rootScope.isConnected) {
                    console.log("already connected");
                    return;
                }
                var socket = io.connect(plateform.event_uri, {
                    'query': 'token=' + $window.sessionStorage.plateformToken
                });

                service.on = function (eventName, callback) {
                    eventListener[eventName + '_' + callback.name] = function () {

                        var args = arguments;
                        $rootScope.$emit(eventName, args);
                        $rootScope.$apply(function () {

                            callback.apply(socket, args);
                        });
                    };

                    socket.on(eventName, eventListener[eventName + '_' + callback.name]);
                    eventListener[eventName] = true;
                };
                service.emit = function (eventName, data, callback) {
                    socket.emit(eventName, data, function () {
                        var args = arguments;
                        $rootScope.$apply(function () {
                            if (callback) {
                                callback.apply(socket, args);
                            }
                        });
                    })
                };

                socket.on('newMsg', function (data) {
                    $rootScope.$emit('newMsg', data)

                });
                socket.on('newAlert', function (data) {
                    $rootScope.$emit('newAlert', data)

                });
                socket.on('updateAlert', function (data) {
                    $rootScope.$emit('updateAlert', data)

                });
                socket.on('newMonitor', function (data) {
                    $rootScope.$emit('newMonitor', data)

                });
                socket.on('updateMonitor', function (data) {
                    $rootScope.$emit('updateMonitor', data)

                });
                socket.on('changeDriver', function (data) {
                    $rootScope.$emit('changeDriver', data)
                });

                socket.on('server:connected', function (data) {
                    if (!$window.sessionStorage.plateformToken || !$window.sessionStorage.plateformSession) {
                        socket.disconnect();
                        service.closeListner("newMsg", function () {
                            console.log('close notification newMSg');
                        });
                        delete $window.sessionStorage.plateformToken;
                        delete $window.sessionStorage.plateformSession;
                        socket.emit('user:connected', {
                            user: JSON.parse($window.sessionStorage.plateformSession).user
                        });
                    }
                })
                service.closeListner = function (eventName, callback) {
                    socket.disconnect();
                    socket.removeAllListeners(eventName, function () {
                        var args = arguments;
                        $timeout(function () {
                            $rootScope.$apply(function () {
                                callback.apply(socket, args);
                            });
                        })

                    });

                };

                socket.on('error', function (data) {
                    if (data == "Not authorized") {
                        delete $window.sessionStorage.plateformToken;
                        delete $window.sessionStorage.plateformSession;
                        if (service.closeListner && typeof service.closeListner == "function")
                            service.closeListner();
                        delete $window.sessionStorage.plateformToken;
                        delete $window.sessionStorage.plateformSession;

                        //console.log(socket);
                        service.closeListner("newMsg", function () {
                            console.log('close notification newMSg');
                        });

                        // $state.go("login");

                        //TODO Notification and session timeout processing

                    }

                    console.log("connection error to RT", data)
                });

                socket.on('connect', function (data) {
                    $rootScope.RT_CONNECTED = true;
                    service.isConnected = true;
                });
                socket.on('disconnect', function (data) {
                    $rootScope.RT_CONNECTED = false;
                    service.isConnected = false;

                    $timeout(function () {
                        $rootScope.$apply(function () {});
                    });
                    console.log("disconnect from RT")
                });
                socket.on('error', function (data) {
                    console.log("connection error to RT")
                });
                service.off = function off(event, callback) {

                    if (typeof callback === 'function') {
                        var listener = eventListener[event + "_" + callback.name];
                        socket.removeListener(event, listener);
                    } else {
                        socket.removeAllListeners(event);
                    }

                };
                if (clb) clb(true)
            }
        };
        return service;
    });