angular.module('app.dialogs').controller('moveArticlesDlgCtrl', function ($scope, constantSrv,I3ArticleSrv,$translate,Notification) {
    try {
        $scope.virtual_stock = {};
        $scope.move={}
        $scope.movePatterns = constantSrv.getMoveStockPattern();
        $scope.updateVirtualStock = function (stock, virtualStock) {
            var  stock = $scope.move._stock
            var virtualStock = $scope.move.virtual_stock
            if (stock && stock.virtual_stock && stock.virtual_stock.length > 0) {
                var filtred = stock.virtual_stock.filter(function (val) {
                    return val._id == virtualStock;
                })
                if (!filtred || filtred.length <= 0) {
                    $scope.move.virtual_stock = null;
                }
            } else if (stok) {
                $scope.move.virtual_stock = null;
            }


        }
        $scope.moveStock = function (move) {
             if(move){
                 var stockId, vStockId , comment="";
                if(move._stock && move._stock._id){
                    stockId =move._stock._id
                }
                if(move.virtual_stock && move.virtual_stock._id){
                    vStockId =move.virtual_stock._id
                }
                if(move.comment && move.comment.length>0){
                    comment =move.comment
                }
                var data  ={
                    _stock:stockId,virtual_stock:vStockId,details:{comment:comment}
             }

                 var ids=$scope.moveArticles.map(function(article){
                     return article._id;
                 })
                 I3ArticleSrv.updateMultiple({_id:{$in:ids}},data,function(res){

                     if (res.success) {
                         Notification.success({
                             title: $translate.instant("STOCK_MOVED"),
                             message:$translate.instant("STOCK_MOVED_SUCCESSFULLY")
                         });
                         $scope.closeThisDialog();
                         $scope.stCtrl.pipe();
                         $scope.moveArticles = {};
                     } else {
                         Notification.error({
                             title: "STOCK_MOVED",
                             message: $translate.instant("STOCK_MOVED_ERROR")
                         });
                     }
                 },function(err){
                     Notification.error({
                         title: "STOCK_MOVED",
                         message: $translate.instant("STOCK_MOVED_ERROR")
                     });
                 })
             }else {
                 console.error("invalid stock move ")
             }
        };
    } catch (e) {
        console.error(e);
    }
});