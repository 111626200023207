/**
 * Created by Kais CHAIBI on 05/12/2017.
 */
angular.module("app.services").service('messageBox', ['$q', 'ngDialog', function ($q, ngDialog) {

    var service = {};

    service.confirm = function (title, message) {

        var defer = $q.defer();

        ngDialog.open({
            template: 'app/commons/dialogs/confirm/messageBox.configm.dlg.tpl.html',
            controller: ["$scope", function ($scope) {
                $scope.title = title;
                $scope.message = message;
                $scope.confirm = function () {
                    $scope.closeThisDialog();
                    defer.resolve(true);
                }
                $scope.cancel = function () {
                    $scope.closeThisDialog();
                    defer.resolve(false);
                }
            }]

        })

        return defer.promise;

    }

    service.showSpinnerPercent = function (title, message) {
        var defer = $q.defer();

        ngDialog.open({
            template: 'app/commons/dialogs/confirm/messageBox.spinner.dlg.tpl.html',
            showClose: false,
            overlay: true,
            appendClassName: "spinner-transparent",
            closeByDocument: false,
            closeByNavigation: true,
            closeByEscape: false,
            disableAnimation: true,
            controller: ['$scope', function ($scope) {
                $scope.percent = 0;
                $scope.title = title;
                $scope.message = message;
                $scope.ok = function () {
                    $scope.closeThisDialog();
                    defer.resolve(true);
                }
                $scope.cancel = function () {
                    $scope.closeThisDialog();
                    defer.resolve(false);
                }
                var progressListener = $scope.$on("progress", function (evt, args) {

                    $scope.percent = parseInt(100.0 * args.loaded / args.total);
                })
                $scope.$on("progressClose", function (evt, args) {
                    if (progressListener)
                        progressListener();

                    setTimeout(function () {
                        $scope.closeThisDialog();
                        defer.resolve(false);
                    });

                })

            }]

        })

        return defer.promise;
    }

    return service;
}])