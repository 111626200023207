angular.module('app').controller('homeClaimEditCtrl', function ($scope, $rootScope, $q, $state, $stateParams, $translate, iaxeedSocketSrv, geoServiceProxy, iaxeedSensorSrv, I3ClaimSrv, I3PlateformeSrv, messageBox, ngDialog, Notification, iaxeedAssetSrv, plateformHelperSrv, I3UserSrv, I3InterventionSrv, constantSrv, I3DocumentSrv, i3HelperSrv, openProjectSrv) {
    $scope.claim = {};
    $scope.disab_input_status = ["Done", "Rejected"];

    //$scope.status = constantSrv.getClaimsStatus();
    $scope.liststatusAvailClaim = []

    $scope.timeleft = 0;
    $scope.now = moment()
    $scope.redv = 0;

    $scope.getvalue = function (deadline, deadline_period) {
        if (deadline && deadline_period) {
            var deadline_start = moment(deadline).subtract(deadline_period, 'h');
            //console.log(deadline_start)
            var date2 = moment(deadline);
            var date1 = moment();
            $scope.date_limite = date2;
            var differenceInMs = date2.diff(date1); // diff yields milliseconds
            var duration = moment.duration(differenceInMs);
            var differenceInHours = Math.floor(duration.asHours());

            if (-differenceInHours >= 1000) {
                $scope.timeleft = 1000
            } else
                $scope.timeleft = -differenceInHours;
            var redv = Math.floor(100 - ((differenceInHours / deadline_period) * 100))
            if (redv >= 100) redv = 100
          //  console.log(redv)
            return redv
        }
    }

    // $scope.status = constantSrv.getClaimsStatus();
    $scope.levels = constantSrv.getClaimsLevels();
    constantSrv.getModel("CLAIM").getAllStatus(function (err, status) {
        $scope.status = status.map(function (val) {
            return val.name
        })
    })

    $scope.emailFollow = "";
    $scope.come = false;
    $scope.com = [];
    $scope.com3 = [];
    $scope.show_com = function (x) {
        $scope.com[x] = !$scope.com[x];
    }
    $scope.show_com3 = function (x) {
        $scope.com3[x] = !$scope.com3[x];
    }
    $scope.show_come = function () {
        $scope.come = !$scope.come;
    }
    $scope.show_come10 = function () {
        $scope.come10 = !$scope.come10;
    }
    $scope.show_come20 = function () {
        $scope.come20 = !$scope.come20;
    }
    $scope.addComment2 = function (msg) {
        if (msg) {
            var commentAdded = {};
            commentAdded.date = new Date();
            commentAdded.owner = $rootScope.SESSION.user;
            commentAdded.msg = msg;
            commentAdded.new = true;
            $scope.claim.comments.push(commentAdded);
        }
        $scope.content2 = "";
    };
    $scope.comp_d = function (comment) {
        if (comment.new) {
            return true
        } else return false
    }
    $scope.deleteComment = function (index) {
        $scope.claim.comments.splice(index, 1);
    };
    $scope.deleteFiles = function (index) {
        $scope.claim.docs.splice(index, 1);
    };
    $scope.save = function (claim) {
        var clm = angular.copy(claim);
        if (clm.comments[0].msg && clm.comments[0].msg.length > 0) {} else {
            delete clm.comments;
        }
        angular.forEach($scope.documents, function (file) {
            clm.docs.push(file._id);
        });
        I3ClaimSrv.updateById(claim._id, clm, function (res) {
            if (res && res.success) {
                $state.go("app.home.claim")
            } else {
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("CLAIM_UPDATE_ERROR")
                });
            }
        }, function (err) {})
        Notification.success({
            title: $translate.instant("UPDATE_SUCC"),
            message: $translate.instant("CLAIM_UPDATED_SUC")
        })
    }

    $scope.uploadFiles = function (files, errFiles) {
        $scope.files = files;
        $scope.errFiles = errFiles;
        i3HelperSrv.uploadFiles(files, {
            _plateforme: $scope.claim._plateforme && $scope.claim._plateforme._id ? $scope.claim._plateforme._id : $scope.claim._plateforme,
            related_model: [{
                model: "CLAIM",
                model_id: $scope.claim._id
            }]
        }, function (res) {
          //  console.log("res ", res)
            angular.forEach(res.result, function (file) {
           //     console.log("file ", file)
                $scope.claim.docs.push(file)
            });
            //file.result = res.data;
        }, function (response) {
            if (response.status)
                $scope.errorMsg = response.status + ': ' + response.data;
        }, function (evt) {});
    };
    $scope.downloadFile = function (file) {
        i3HelperSrv.downloadFile(file._id, file.original_name, function success(res) {}, function error(err) {
            Notification.error({
                title: $translate.instant("ERROR"),
                message: $translate.instant(err)
            });
        }, function progress(evt) {
          //  console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '%');
        })
    };
    $scope.getClaims = function () {
        var query = {
            query: {
                _id: $stateParams.claimId
            },
            options: {
                populate: [{
                        path: '_created_by'
                    },
                    {
                        path: '_plateforme'
                    },
                    {
                        path: 'docs'
                    },
                    {
                        path: 'followers.user'
                    },
                    {
                        path: 'comments.owner',
                        select: 'login'
                    }
                ]
            }
        };
        I3ClaimSrv.query(query, 1000, 0, function (response) {
                $scope.claim = response.result[0];
                $scope.redv = $scope.getvalue($scope.claim.dead_line, $scope.claim.dead_line_period);
                $scope.plateforme = $scope.claim._plateforme;
                if ($scope.plateforme.type == 'NGI_FLEET') {
                    return
                }
                constantSrv.getModel("CLAIM").getAvailableStatusByStatus($scope.claim.status, function (err, val) {
                    $scope.liststatusAvailClaim = val._availStatus.map(function (elem) {
                        return elem.name
                    })
                });

                plateformHelperSrv.setPlateFormAndLogin($scope.plateforme, function (res) {
                        if (res.success) {
                            $scope.platformeLogin = res;
                            var assetIds = [];
                            angular.forEach($scope.claim.asset, function (asset) {
                                assetIds.push(asset.asset_id);
                            });
                            if ($scope.platformeLogin && $scope.platformeLogin.result && $scope.platformeLogin.result.token) {
                                var query = {
                                    _id: {
                                        $in: assetIds
                                    }
                                };
                            }
                            var options = {};
                            iaxeedAssetSrv.query({
                                query: query,
                                options: options
                            }, 10000, 1, function (res1) {
                                if (res1.result) {
                                    $scope.assets = res1.result;
                                }
                            }, function (err) {
                                console.log(err);
                            });

                        } else {
                            Notification.error({
                                title: $translate.instant("ERROR"),
                                message: $translate.instant("PLATFORM_CNX_ERR")
                            });
                            $scope.claim = {};

                        }
                    },
                    function (err) {
                        console.log(err)
                    });

                if ($scope.claim.ticket && $rootScope.SESSION.user.oo_key) {
                    openProjectSrv.getTaskById($scope.claim.ticket, function (res) {
                        if (res) {
                          //  console.log(res);
                            $scope.ticket = res;
                        } else {
                            Notification.error({
                                title: $translate.instant("ERROR"),
                                message: $translate.instant("CANNOT_GET_OPEN_PROJECT_TICKET", {
                                    id: $scope.claim.ticket
                                })
                            });
                        }
                    }, function (err) {
                        Notification.error($translate.instant("CANNOT_GET_OPEN_PROJECT_TICKET_ERROR"));
                        Notification.error({
                            title: $translate.instant("ERROR"),
                            message: $translate.instant("ERROR")
                        });
                    })
                } else if ($scope.claim.ticket) {
                    $scope.ticketUrl = $rootScope.config.OPEN_PROJECT_URI + 'work_packages/' + $scope.claim.ticket + '/activity';
                }
                $scope.getInterventions($scope.claim._id);
            },
            function (err) {
                console.log(err);
            });
    };

    $scope.showRealtime = function (asset) {
        $scope.asset = asset;
        var dialog = ngDialog.open({
            controller: "realtimeDlgCtrl",
            templateUrl: "app/commons/dialogs/realtime/realtime.dlg.tpl.html",
            scope: $scope,
            className: 'ngdialog-theme-default custom-width'
        });
        dialog.closePromise.then(function () {
            iaxeedSocketSrv.closeListner('newMsg');
        });
    };

    $scope.follow = function () {
      //  console.log($scope.emailFollow)
        if ($scope.emailFollow && $scope.emailFollow !== "") {
        //    console.log("z")
            var follower = {};
            if (!$scope.claim.followers) {
                $scope.claim.followers = [];
              //  console.log("b")
            } else {
              //  console.log("c")
            }
            follower.email = $scope.emailFollow;
            var query = {
                "contact.mail": $scope.emailFollow
            };
            var options = {};
            var exist = false;
            angular.forEach($scope.claim.followers, function (follower) {
                if (follower.email.toLowerCase() === $scope.emailFollow.toLowerCase()) {
                    exist = true;
                }
            });
            if (!exist) {
                console.log("!exist")
                I3UserSrv.query({
                    query: query,
                    options: options
                }, 10000, 0, function (res) {
                    if (res.success) {
                        follower.user = res.result[0];
                    }
                    $scope.claim.followers.push(follower);
                    delete $scope.claim.__v;
                    $scope.emailFollow = "";
                });
            } else {
                console.log("exist")
                Notification.warning({
                    title: $translate.instant("ALREADY_FOLLOWER_TITLE"),
                    message: $translate.instant("ALREADY_FOLLOWER_INS")
                });
                $scope.emailFollow = "";
            }
        }
    };

    $scope.removeFollower = function (follower) {
        if ($scope.claim.followers.indexOf(follower) >= 0) {
            $scope.claim.followers.splice($scope.claim.followers.indexOf(follower), 1);
            delete $scope.claim.__v;
        }
    };

    $scope.add = function (content) {
        if (content) {
            var commentAdded = {};
            commentAdded.date = new Date();
            commentAdded.owner = $rootScope.SESSION.user._id;
            commentAdded.msg = content;
            $scope.claim.comments.push(commentAdded);
            I3ClaimSrv.updateById($scope.claim._id, $scope.claim, function (response) {
                if (response.success) {
                    Notification.success({
                            title: $translate.instant("UPDATE_SUCC"),
                            message: $translate.instant("ALERT_COMMENT_ADDED")
                        }

                    );
                    $scope.getClaims();
                }
            });
            $scope.content = "";
        }
    };
    /**
     * Save user
     */
    $scope.save = function () {
        angular.forEach($scope.documents, function (file) {
            if (!$scope.claim.docs) {
                $scope.claim.docs = [];
            }
            $scope.claim.docs.push(file._id);
        });
        I3ClaimSrv.updateById($scope.claim._id, $scope.claim, function (response) {
            if (response.success) {
                Notification.success({
                    title: $translate.instant("UPDATE_SUCC"),
                    message: $translate.instant("CLAIM_UPDATED_SUC")
                });
                $state.go('app.home.claims');
                $scope.claim = {};
            } else {
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("ERROR_OCCURED_UPDATE")
                });
                $scope.claim = {};
            }
        }, function (err) {
            var msg = "";
            if (err.error.errorString) {
                msg = err.error.errorString;
            }
            Notification.error({
                title: $translate.instant("ERROR"),
                message: msg
            });
        })
    };
    /**
     * Cancel editing mode
     */
    $scope.cancel = function () {
        $scope.claim = {};
        $state.go('app.home.claims');
    };
    /**
     * Add intervention
     * @param claim
     */
    $scope.addIntervention = function (claim) {
        $scope.claim = claim;
        //console.log("TCL: $scope.addIntervention -> claim", claim)
        var modal = ngDialog.open({
            templateUrl: "app/commons/dialogs/confirm/add.intervention.dlg.tpl.html",
            controller: "addInterventionDlgCtrl",
            scope: $scope
        })
        modal.closePromise.then(function (x) {
            if (x && x.value && x.value !== '$closeButton' && x.value !== '$document') {
                //console.log("TCL: $scope.addIntervention -> x", x)
                $scope.claim.status = "InIntervention";
                $scope.claim.level = "LEVEL_3";
                $scope.interventions.push(x.value);
                constantSrv.getModel("CLAIM").getAvailableStatusByStatus($scope.claim.status, function (err, val) {
                    liststatusAvailClaim = val._availStatus.map(function (elem) {
                        return elem.name
                    })
                    $scope.liststatusAvailClaim = liststatusAvailClaim
                })

                I3ClaimSrv.updateById($scope.claim._id, $scope.claim, function (response) {
                    if (response.success) {

                    } else {
                        Notification.error({
                            title: "Erreur",
                            message: $translate.instant("ERROR_OCCURED_UPDATE") + " " + $scope.claim._plateforme.name + "!"
                        });
                    }
                }, function (err) {
                    var msg = "";
                    if (err.error.errorString) {
                        msg = err.error.errorString;
                    }
                    Notification.error({
                        title: "Erreur",
                        message: msg
                    });
                })
            }
        });
    };
    $scope.addDevTicket = function () {
        var modal = ngDialog.open({
            template: 'app/commons/dialogs/openproject/task.add.dlg.tpl.html',
            controller: 'openProjectTaskAddDlgCtrl',
            scope: $scope
        });
        modal.closePromise.then(function (res) {
            console.log(res);
            if (res && res.value) {
                $scope.claim.ticket = res.value.id;
                $scope.ticket = res.value
            }
            $scope.claim.status = "InDevelopment";
            I3ClaimSrv.updateById($scope.claim._id, $scope.claim, function (response) {
                if (response.success) {} else {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("ERROR_OCCURED_UPDATE") + " " + $scope.claim._plateforme.name + "!"
                    });
                }
            }, function (err) {
                var msg = "";
                if (err.error.errorString) {
                    msg = err.error.errorString;
                }
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: msg
                });
            })
        });
    };
    $scope.getClaims();

    $scope.updateClaimStatus2 = function (claim, data) {

        var liststatusAvailClaim;
        constantSrv.getModel("CLAIM").getAvailableStatusByStatus($scope.claim.status, function (err, val) {
            liststatusAvailClaim = val._availStatus.map(function (elem) {
                return elem.name
            })
            $scope.liststatusAvailClaim = liststatusAvailClaim
        })

        if (liststatusAvailClaim.indexOf(data) != -1 && $scope.claim.status != data) {
            var dialog = ngDialog.open({
                template: "app/commons/dialogs/claims/level.dlg.tpl.html",
                className: 'ngdialog-theme-default custom-width-500',
                appendClassName: 'ngdialog-custom',
                controller: "ClaimLevelDlgCtrl",
                scope: $scope,
                data: {
                    prop: $scope.claim,
                    status: data
                }
            });
            dialog.closePromise.then(function (res) {
                console.log(res)
                if (res.value != false && res.value != "$closeButton" && res.value != "$document") {

                    if (res.value.level) {
                        $scope.claim.level = res.value.level;
                    }
                    if (res.value.comment) {
                        var commentLevel = {};
                        commentLevel.date = new Date();
                        commentLevel.owner = $rootScope.SESSION.user._id;
                        commentLevel.msg = res.value.comment;
                        $scope.claim.comments.push(commentLevel);
                    }

                    $scope.claim.status = data
                    I3ClaimSrv.updateById($scope.claim._id, $scope.claim, function (response) {

                        console.log("response", response)
                        if (response.success) {

                            Notification.success({
                                title: $translate.instant("CLAIM_UPDATED_TITLE"),
                                message: $translate.instant("CLAIM_UPDATED_SUC")
                            });
                        } else {
                            Notification.error({
                                title: $translate.instant('ERROR'),
                                message: $translate.instant("ERROR_OCCURED_UPDATE")
                            });
                        }
                    }, function (err) {
                        var msg = "";
                        if (err.error.errorString) {
                            msg = err.error.errorString;
                        }
                        Notification.error({
                            title: $translate.instant('ERROR'),
                            message: msg
                        });
                    })

                    constantSrv.getModel("CLAIM").getAvailableStatusByStatus($scope.claim.status, function (err, val) {
                        liststatusAvailClaim = val._availStatus.map(function (elem) {
                            return elem.name
                        })
                        $scope.liststatusAvailClaim = liststatusAvailClaim
                    })

                }

            }, function (reject) {})

        }
    }
    /*   messageBox.confirm("UPDATE", "UPDATE_CLAIM_STATUS").then(function (res) {
           if (res) {

               $scope.claim.status = data
               I3ClaimSrv.updateById($scope.claim._id, $scope.claim, function (response) {

                   console.log("response",response)
                   if (response.success) {

                       Notification.success({
                           title: $translate.instant("CLAIM_UPDATED_TITLE"),
                           message: $translate.instant("CLAIM_UPDATED_SUC")
                       });
                   } else {
                       Notification.error({
                           title: $translate.instant('ERROR'),
                           message: $translate.instant("ERROR_OCCURED_UPDATE")
                       });
                   }
               }, function (err) {
                   var msg = "";
                   if (err.error.errorString) {
                       msg = err.error.errorString;
                   }
                   Notification.error({
                       title: $translate.instant('ERROR'),
                       message: msg
                   });
               })
           }
       }, function (reject) { })*/

    // $scope.claim.status = data;
    /*  I3ClaimSrv.updateById(claim._id, {
          status: data
      }, function (res) {
          if (res.success) {
              Notification.success({
                  title: $translate.instant("UPDATE_SUCC"),
                  message: $translate.instant("CLAIM_UPDATED_SUC")
              });
          }
      }, function (err) {})*/

    $scope.getInterventions = function (claim) {
        I3InterventionSrv.query({
            query: {
                _claim: {
                    '$in': claim
                }
            }
        }, 10000, 1, function (res) {
            if (res.success) {
                $scope.interventions = res.result;
                //console.log("TCL: $scope.getInterventions -> $scope.interventions", $scope.interventions)
            }
        }, function (err) {
            console.log(err);
        });
    };

    $scope.isavailable = function (status) {

        if ($scope.liststatusAvailClaim.indexOf(status) != -1 && $scope.claim.status != status && $rootScope.checkPermission('CLAIM', 'R', 'X', 'HOME', 'CLAIM')) {

            return true
        } else
            return false
    };

    $scope.downloadZipGrouppedfile = function () {

        try {
            var params = {
                _ids: []
            }
            var comments = $translate.instant("CLAIMS") + "\r\n";
            comments += "\t" + $translate.instant("CLIENT") + " :" + $scope.claim.client_name + "\r\n";
            comments += "\t" + $translate.instant("REFERENCE") + " :" + $scope.claim.ref + "\r\n";
            comments += "-------------------------------------------------------------------------\r\n";
            comments += "\t- " + $translate.instant("DOCUMENTS") + " :\r\n";
            if ($scope.claim.docs && $scope.claim.docs.length > 0) {
                // params._ids = {claim: []}
                $scope.claim.docs.forEach(function (doc) {
                    if (doc._id)
                        params._ids.push(doc._id);
                })
                comments += "\t\t + Réclamation : " + $translate.instant("DOCUMENTS") + " " + $translate.instant("CLAIMS") + "\r\n";
            }

            /* $scope.claim.assets.forEach(function (asset) {
                 var asset_tag=asset.name;
                  if(asset.vin){
                      asset_tag=asset.vin
                  }
                 params.groups[asset_tag]=[];
                 if(asset.docs && asset.docs.length>0){
                     asset.docs.forEach(function (doc) {
                         if(doc._id)
                             params.groups[asset_tag].push(doc._id);
                     })
                     if(asset.gray_card_back && asset.gray_card_back.length>0){
                         params.groups[asset_tag].push(asset.gray_card_back);
                     }
                     if(asset.gray_card_front && asset.gray_card_front.length>0){
                         params.groups[asset_tag].push(asset.gray_card_front);
                     }

                 }

             })*/

            params.comments = comments
            i3HelperSrv.downloadZip(params, "Réclamation_" + $scope.claim.client_name + '_' + $scope.claim.ref + "_docs.zip", function (res) {
                console.log("res", res)

            }, function (err) {
                console.log(err)
            }, function (percent) {
                console.log(percent)
            })
        } catch (e) {

            console.error(e)
        }
    }
});