angular
    .module('app')
    .controller('planningCtrl', function ($scope,$rootScope, $cookieStore, $window, $translate, I3UserSrv, I3InstallationSrv, I3InterventionSrv, constantSrv,ColorSelectorSrv) {

        $scope.installers = [];
        $scope.clients = [];
        $scope.installs = [];
        $scope.selectedInstallers = [];
        $scope.selectedClients = [];
        $scope.Unassigned = true;
        $scope.filter = {};
        $scope.showFilters = false;
        $scope.installerColorSelector=new ColorSelectorSrv();
        $scope.clientColorSelector=new ColorSelectorSrv();
        $scope.assignedColorsInstallers = {};
        $scope.savedAssignedColorsInstallers = JSON.parse($window.localStorage.getItem('installersColors'));

        $scope.filter.group = "ALL";

        $scope.groups = [
          "ALL",
          "INSTALLS",
          "INTERVENTIONS"
        ];

        if( $scope.savedAssignedColorsInstallers){
            Object.keys( $scope.savedAssignedColorsInstallers).forEach(function(key){
                $scope.installerColorSelector.updateUsedColor($scope.savedAssignedColorsInstallers[key]);
            });
        }

        $scope.assignedColorsClients= {};
        $scope.savedAssignedColorsClients = JSON.parse($window.localStorage.getItem('clientsColors'));
       if($scope.savedAssignedColorsClients){
           Object.keys( $scope.savedAssignedColorsClients).forEach(function(key){
               $scope.clientColorSelector.updateUsedColor($scope.savedAssignedColorsClients[key]);
           });
       }

        $scope.filterByState = false;
        $scope.filterByDate = false;
        $scope.fullPage =false;
        $scope.fullScreen =false;

        $scope.filterBy = "INSTALLERS";

        $scope.status = constantSrv.getClaimsStatus();

        var now = moment().clone();

        $scope.dayRes = now;


        $scope.toggleFilters = function () {
            $scope.showFilters = !$scope.showFilters;
        };

        $scope.toggleFullPage = function () {
            $scope.fullPage  = !$scope.fullPage;
        };
        $scope.toggleFullScreen = function () {
            $scope.fullScreen  = !$scope.fullScreen;
        };

        $scope.pickerDay = {
            format : "DD/MM/YYYY HH:mm",
            position: "top left",
            init: now,
            autoTrans : false,
            enableTime : true,
            enableDate : true,
            enableMinutes : true,
            defaultView : "DATE",
            getDate : function (date) {
                $scope.dayRes = date;
                $scope.applyFilter();
            }
        };

        $scope.applyFilter = function () {
            $scope.loadInstallation();
        };


        /**
         * Installers / Clients view
         * @param obj
         */
        $scope.changeFilter = function (obj) {
            $scope.filterBy = obj;
        };


        $scope.isInArray = function (array,param, attr) {
            var exist = false;
            angular.forEach(array, function (item) {
                if(param !== ""){
                    if(item[param] === attr){
                        exist = true;
                    }
                }else{
                    if(item === attr){
                        exist = true;
                    }
                }
            });

            return exist;

        };

        $scope.selectInstaller = function (installer) {
            if($scope.selectedInstallers.length > 0){
                if(!$scope.isInArray($scope.selectedInstallers, "_id", installer._id)){
                    $scope.selectedInstallers.push(installer);
                }else{
                    for(var i in $scope.selectedInstallers){
                        if($scope.selectedInstallers[i]._id === installer._id){
                            $scope.selectedInstallers.splice(i, 1);
                        }
                    }
                }
            }else{
                $scope.selectedInstallers.push(installer);
            }
        };

        $scope.selectClient = function (client) {
            if($scope.selectedClients.length > 0){
                if(!$scope.isInArray($scope.selectedClients, "", client)){
                    $scope.selectedClients.push(client);
                }else{
                    for(var i in $scope.selectedClients){
                        if($scope.selectedClients[i] === client){
                            $scope.selectedClients.splice(i, 1);
                        }
                    }
                }
            }else{
                $scope.selectedClients.push(client);
            }
        };

        $scope.loadInstallation = function () {
            $scope.installs = [];
            var query = {
                begin_dt:{$gte:$scope.startDt,$lte:$scope.endDt},
                $or : [
                    {
                        $and : [
                            {begin_dt : {$exists : true}},
                          //  {end_dt : {$exists : true}}
                        ]
                    },
                    {
                        /*$and : [
                            {planned_begin_dt : {$exists : true}},
                            {planned_end_dt : {$exists : true}}
                        ]*/
                    }
                ]};

            if($scope.filterByState){
                query.status = $scope.filter.state
            }
            if($scope.filterByDate){
                query.$or = [
                    {
                        begin_dt : {
                            $gte : $scope.dayRes
                        }
                    }/*,
                    {
                        planned_begin_dt : {
                            $gte : $scope.dayRes
                        }
                    }*/

                ]
            }

            var options = {
                populate : [
                    "_assigned_to",
                    "_installation_group"
                ]
            };

            if($scope.filter.group === "INSTALLS"){
                I3InstallationSrv.query({query: query, options: options}, 10000,1, function (response) {
                    if(response.success){
                        console.log(response)
                        $scope.installs = response.result;

                        $scope.loadInstallers( response.result);
                    }
                }, function (err) {
                    console.log(err);
                })
            }else if($scope.filter.group === "INTERVENTIONS"){
                I3InterventionSrv.query({query: query, options: options}, 10000, 1, function (response) {
                    if(response.success){
                        var interventions = response.result;
                        angular.forEach(interventions, function (intervention) {
                           intervention._assigned_to =  intervention._assigned_to;
                           delete intervention._assigned_to;
                        });
                        $scope.installs = interventions;
                        $scope.loadInstallers(interventions);
                    }else{
                        console.log("error")
                    }
                }, function (err) {
                    console.log(err);
                })
            }else{
                I3InstallationSrv.query({query: query, options: options}, 10000,1, function (res) {
                    if(res.success){
                        $scope.installs = res.result;
                        I3InterventionSrv.query({query: query, options: options}, 10000, 1, function (response) {
                            if(response.success){
                                var interventions = response.result;
                                angular.forEach(interventions, function (intervention) {
                                    intervention._assigned_to =  intervention._assigned_to;
                                    delete intervention._assigned_to;
                                });
                                angular.forEach(interventions, function (el) {
                                    $scope.installs.push(el);
                                })
                                $scope.loadInstallers($scope.installs);
                            }else{
                                console.log("error")
                            }
                        }, function (err) {
                            console.log(err);
                        })
                    }

                }, function (err) {
                    console.log(err);
                })

            }


        };

        $scope.loadInstallers = function (installs) {
            $scope.installers = [];
            angular.forEach(installs, function (install) {
                if(install._assigned_to){
                    angular.forEach(install._assigned_to, function (installer) {

                        if($scope.installers.length <= 0){
                            if($scope.savedAssignedColorsInstallers && $scope.savedAssignedColorsInstallers[installer._id]){

                                $scope.assignedColorsInstallers[installer._id] = $scope.savedAssignedColorsInstallers[installer._id]
                            }else{
                                $scope.assignedColorsInstallers[installer._id] = $scope.installerColorSelector.getNextColor();
                                if($scope.savedAssignedColorsInstallers){
                                    $scope.savedAssignedColorsInstallers[installer._id] = $scope.assignedColorsInstallers[installer._id];
                                }else{
                                    $scope.savedAssignedColorsInstallers = {};
                                    $scope.savedAssignedColorsInstallers[installer._id] = $scope.assignedColorsInstallers[installer._id];
                                }
                                $window.localStorage.setItem('installersColors', JSON.stringify($scope.savedAssignedColorsInstallers));
                            }
                            $scope.installers.push(installer);
                        }else {
                            if(!$scope.isInArray($scope.installers, '_id', installer._id)){
                                if($scope.savedAssignedColorsInstallers && $scope.savedAssignedColorsInstallers[installer._id]){

                                    $scope.assignedColorsInstallers[installer._id] = $scope.savedAssignedColorsInstallers[installer._id]
                                }else{
                                    $scope.assignedColorsInstallers[installer._id] = $scope.installerColorSelector.getNextColor();
                                    if($scope.savedAssignedColorsInstallers){
                                        $scope.savedAssignedColorsInstallers[installer._id] = $scope.assignedColorsInstallers[installer._id];
                                    }else{
                                        $scope.savedAssignedColorsInstallers = {};
                                        $scope.savedAssignedColorsInstallers[installer._id] = $scope.assignedColorsInstallers[installer._id];
                                    }
                                    $window.localStorage.setItem('installersColors', JSON.stringify($scope.savedAssignedColorsInstallers));
                                }
                                $scope.installers.push(installer);
                            }
                        }
                    })
                }else{
                    angular.forEach(install._assigned_to, function (installer) {

                        if($scope.installers.length <= 0){
                            if($scope.savedAssignedColorsInstallers && $scope.savedAssignedColorsInstallers[installer._id]){

                                $scope.assignedColorsInstallers[installer._id] = $scope.savedAssignedColorsInstallers[installer._id]
                            }else{
                                $scope.assignedColorsInstallers[installer._id] = $scope.installerColorSelector.getNextColor();
                                if($scope.savedAssignedColorsInstallers){
                                    $scope.savedAssignedColorsInstallers[installer._id] = $scope.assignedColorsInstallers[installer._id];
                                }else{
                                    $scope.savedAssignedColorsInstallers = {};
                                    $scope.savedAssignedColorsInstallers[installer._id] = $scope.assignedColorsInstallers[installer._id];
                                }
                                $window.localStorage.setItem('installersColors', JSON.stringify($scope.savedAssignedColorsInstallers));
                            }
                            $scope.installers.push(installer);
                        }else {
                            if(!$scope.isInArray($scope.installers, '_id', installer._id)){
                                if($scope.savedAssignedColorsInstallers && $scope.savedAssignedColorsInstallers[installer._id]){

                                    $scope.assignedColorsInstallers[installer._id] = $scope.savedAssignedColorsInstallers[installer._id]
                                }else{
                                    $scope.assignedColorsInstallers[installer._id] = $scope.installerColorSelector.getNextColor();
                                    if($scope.savedAssignedColorsInstallers){
                                        $scope.savedAssignedColorsInstallers[installer._id] = $scope.assignedColorsInstallers[installer._id];
                                    }else{
                                        $scope.savedAssignedColorsInstallers = {};
                                        $scope.savedAssignedColorsInstallers[installer._id] = $scope.assignedColorsInstallers[installer._id];
                                    }
                                    $window.localStorage.setItem('installersColors', JSON.stringify($scope.savedAssignedColorsInstallers));
                                }
                                $scope.installers.push(installer);
                            }
                        }
                    })

                }
            });
            $scope.selectedInstallers = angular.copy($scope.installers);
            $scope.filterByInstallers(installs);
            $scope.prepareEvents();
        };
        $scope.loadClients = function (installs) {
            $scope.clients = [];

            angular.forEach(installs, function (install) {
                if(install._installation_group){
                    if($scope.clients.length <= 0){
                        $scope.clients.push(install._installation_group.client_name);

                        if($scope.savedAssignedColorsClients && $scope.savedAssignedColorsClients[install._installation_group.client_name]){

                            $scope.assignedColorsClients[install._installation_group.client_name] = $scope.savedAssignedColorsClients[install._installation_group.client_name];
                        }else{
                            $scope.assignedColorsClients[install._installation_group.client_name] = $scope.clientColorSelector.getNextColor();

                            if($scope.savedAssignedColorsClients){
                                $scope.savedAssignedColorsClients[install._installation_group.client_name] = $scope.assignedColorsClients[install._installation_group.client_name];
                            }else{
                                $scope.savedAssignedColorsClients = {};
                                $scope.savedAssignedColorsClients[install._installation_group.client_name] = $scope.assignedColorsClients[install._installation_group.client_name];
                            }
                            $window.localStorage.setItem('clientsColors', JSON.stringify($scope.savedAssignedColorsClients));
                        }
                    }else {
                        if(!$scope.isInArray($scope.clients, "", install._installation_group.client_name)){
                            $scope.clients.push(install._installation_group.client_name);
                            if($scope.savedAssignedColorsClients && $scope.savedAssignedColorsClients[install._installation_group.client_name]){

                                $scope.assignedColorsClients[install._installation_group.client_name] = $scope.savedAssignedColorsClients[install._installation_group.client_name];
                            }else{
                                $scope.assignedColorsClients[install._installation_group.client_name] = $scope.clientColorSelector.getNextColor();

                                if($scope.savedAssignedColorsClients){
                                    $scope.savedAssignedColorsClients[install._installation_group.client_name] = $scope.assignedColorsClients[install._installation_group.client_name];
                                }else{
                                    $scope.savedAssignedColorsClients = {};
                                    $scope.savedAssignedColorsClients[install._installation_group.client_name] = $scope.assignedColorsClients[install._installation_group.client_name];
                                }
                                $window.localStorage.setItem('clientsColors', JSON.stringify($scope.savedAssignedColorsClients));
                            }
                        }
                    }
                }
            });
            $scope.selectedClients = angular.copy($scope.clients);
        };

        $scope.$watch('Unassigned', function (newVal) {
            var installs = angular.copy($scope.installs);
            $scope.filterByInstallers(installs);
            $scope.prepareEvents();
        }, true);

        $scope.$watch('selectedInstallers', function (newVal) {
            var installs = angular.copy($scope.installs);
            $scope.filterByInstallers(installs);
            $scope.prepareEvents();
        }, true);

        $scope.$watch('selectedClients', function (newVal) {
            var installs = angular.copy($scope.installs);
            $scope.filterByClients(installs);
            $scope.prepareEvents();
        }, true);

        $scope.$watch('filterBy', function (newVal) {
            var installs = angular.copy($scope.installs);
            if($scope.filterBy === 'INSTALLERS'){
                $scope.loadInstallers(installs);
            }
            if($scope.filterBy === 'CLIENTS'){
                $scope.loadClients(installs);
            }
            $scope.prepareEvents();
        }, true);

        $scope.toggleUnassigned = function () {
            $scope.Unassigned = !$scope.Unassigned;
        };

        $scope.filterByInstallers = function (installs) {
            $scope.shownInstalls = [];
            angular.forEach(installs, function (install) {
                if(install._assigned_to && install._assigned_to.length > 0 && $scope.selectedInstallers.length > 0){
                    angular.forEach(install._assigned_to, function (installer) {
                        var inst = angular.copy(install);
                        inst.agent = {};
                        if($scope.isInArray($scope.selectedInstallers, '_id', installer._id)){
                            inst.agent =  installer;
                            $scope.shownInstalls.push(inst);
                        }
                    })
                }else{
                    if($scope.Unassigned){
                        $scope.shownInstalls.push(install);
                    }
                }
            });

        };


        $scope.filterByClients = function (installs) {
            $scope.shownInstalls = [];
            angular.forEach(installs, function (install) {
                if($scope.selectedClients.length > 0){
                    angular.forEach($scope.selectedClients, function (selected) {
                        if(install._installation_group && install._installation_group.client_name && install._installation_group.client_name === selected){
                            $scope.shownInstalls.push(install);
                        }
                    })
                }
            });
        };

        $scope.prepareEvents = function () {
            $scope.events = [];
            if($scope.filterBy === "INSTALLERS"){
                angular.forEach($scope.shownInstalls, function (install) {
                    if(!install.agent){
                        if(install.begin_dt){
                            var event = {};

                            if(install.begin_dt){
                                event.start = new moment(install.begin_dt);
                                event.end = new moment(install.begin_dt);
                            }
                            if(install.description){
                                event.title = install.description;
                                event.color = "#01DF3A";
                            }else{
                                event.title = "";
                                event.color = "#555";
                            }

                            if(install._installation_group){
                                if(install._installation_group.client_name){
                                    event.title += install._installation_group.client_name
                                }
                                if(install._installation_group.client_name && install.asset_name){
                                    event.title += " / "
                                }
                            }
                            if(install.asset_name){
                                event.title += install.asset_name;
                            }
                            event.description = event.title;
                            if(event.title && event.start){
                                event.description += "<br />";
                            }
                            if(event.start){
                                event.description += "Date: " + event.start.format('DD/MM/YYYY HH:mm');
                            }
                            if(event.start && event.end){
                                event.description += "<br />";

                            }/*
                            if(event.end){
                                event.description += "Fin: " + event.end.format('DD/MM/YYYY HH:mm');
                            }*/


                            $scope.events.push(event);
                        }

                    }else{
                        if(install.begin_dt){
                            var event = {};

                            if(install.begin_dt){
                                event.start = new moment(install.begin_dt);
                                event.end = new moment(install.begin_dt);
                            }
                            event.title = "";
                            if(install._installation_group){
                                if(install._installation_group.client_name){
                                    event.title += install._installation_group.client_name
                                }
                                if(install._installation_group.client_name && install.asset_name){
                                    event.title += " / "
                                }
                            }

                            if(install.asset_name){
                                event.title += install.asset_name;
                            }
                            if(install.agent.first_name && install.agent.last_name){
                                event.title += " assigné à " + install.agent.first_name+" "+install.agent.last_name;
                            }
                            event.description = event.title;
                            if(event.title && event.start){
                                event.description += "<br />";
                            }
                            if(event.start){
                                event.description += "Date: " + event.start.format('DD/MM/YYYY HH:mm');
                            }
                            if(event.start && event.end){
                                event.description += "<br />";
                            }
                            event.description += "<br />"+ install.agent.first_name + " "+install.agent.last_name;
                            event.color = $scope.assignedColorsInstallers[install.agent._id];
                            $scope.events.push(event);
                        }

                    }

                });

            }else{
                angular.forEach($scope.shownInstalls, function (install) {
                    if(install.begin_dt){
                        var event = {};

                        if(install.begin_dt){
                            event.start = new moment(install.begin_dt);
                            event.end = new moment(install.begin_dt);
                        }
                        event.title = "";
                        if(install._installation_group){
                            if(install._installation_group.client_name){
                                event.title += install._installation_group.client_name
                            }
                            if(install._installation_group.client_name && install.asset_name){
                                event.title += " / "
                            }
                        }

                        if(install.asset_name){
                            event.title += install.asset_name;
                        }
                        event.description = event.title;
                        if(event.title && event.start){
                            event.description += "<br />";
                        }
                        if(event.start){
                            event.description += "Date: " + event.start.format('DD/MM/YYYY HH:mm');
                        }
                        if(event.start && event.end){
                            event.description += "<br />";

                        }

                        if(install._installation_group){
                            event.color = $scope.assignedColorsClients[install._installation_group.client_name];
                        }
                        $scope.events.push(event);
                    }
                });
            }
        };

        // Calendar options
        $rootScope.$on('calendarViewChanged', function (event, view) {
            $scope.options.defaultView = view;
        });

        $scope.options =  {
            header: {
                left: 'prev,next today',
                center: 'title',
                right: 'month,agendaWeek,agendaDay,listWeek'
            },
            selectable : true,
            timeFormat: 'HH:mm',
            defaultView: 'month',
            eventMouseover : function( event, jsEvent, view ) {
                $(jsEvent.target).closest('a').tooltip({
                    title : event.description,
                    animation : false,
                    html : true,
                    toggle:"tooltip"
                });
            },
            viewRender: function (view) {

                $scope.startDt=view.start.toDate().toISOString();
                $scope.endDt=view.end.toDate().toISOString();
                $scope.loadInstallation();
                $rootScope.$broadcast('calendarViewChanged', view.name);
            }
        };
    });
