angular.module('app').controller("confirmAddGenerationPVCtrl", function ($scope, PvGen, $state, Notification, $translate, ngDialog, I3ClientSrv, I3PvSrv, I3ZoneSrv, I3deliverySlipSrv) {
    $scope.isUniquePVRef = false;
    $scope.pv = {};
    $scope.categorys = []
    $scope.category = {}
    $scope.travelCost = false;
    $scope.srch = false;
    $scope.docs = [];
    $scope.listClientInvoice = []
    $scope.subClient = null
    //$scope.gnPv = {} ;

    var query = {
        query: {
            code_client: $scope.installation.code_client
        },
        options: { populate: [{ path: "_subClients" }] }


    }
    I3ClientSrv.query(query, 1, 0, function (res) {
        if (res && res.success) {
            if (res.result.length > 0) {
                if (res.result[0]._subClients && res.result[0]._subClients.length > 0) {// client groupe ou client simple  exist dans tcfleet-sav 
                    $scope.listClientInvoice = res.result[0]._subClients



                }

            }
        }
    }, function (err) {
        console.log(err)
    })







    I3ZoneSrv.query({
        query: {
            _plateforme: $scope.installation._plateforme._id,

        }
    }, 10000, 1, function (response) {

        $scope.zones = response.result;

    }, function (err) {
        console.log(err);
    });
    $scope.pvNumber = function (pv_ref) {
       // console.log('pv_ref:', pv_ref)


        $scope.genertatePV()
    }
    $scope.genertatePV = function () {

        try {
            $scope.srch = true;


            var pvGen = new PvGen($scope.installation, $scope.asset._id, $scope.pv.pv_ref, "INSTALLATION");
            pvGen.prepare().then(function (res) {


                $scope.gnPv = res
                // console.log($scope.gnPv)
            }, function (err) {

                console.log(err);
            })
        } catch (e) { }
    }
    $scope.update_search = function () {
        $scope.srch = false;
        $scope.pvNumber2();
    }
    $scope.pvNumber2 = function () {

        $scope.isUniquePVRef = false;
        I3PvSrv.query({
            query: {
                pv_ref: $scope.pv.pv_ref,
                _plateforme: $scope.installation._plateforme
            }
        }, 10000, 1, function (res) {
            if (res.success) {




                if (res.result.length > 0 && $scope.pv._id != res.result[0]._id) {

                    $scope.isUniquePVRef = false;
                    // console.log("eeeeeeeeeeeeeeeeeeeeeeeee")
                } else {

                    $scope.isUniquePVRef = true;
                    // console.log("fffffffffffffffffffffffff")
                }


            }
        }, function (err) {
            console.log(err);
        });
    }
    $scope.confirm = function () {
        // console.log("$scope.gnPv", $scope.gnPv)
        if ($scope.asset && $scope.asset.docs && $scope.asset.docs.length > 0) {
            $scope.docs = $scope.asset.docs.map(function (val) {
                return {
                    _type: 'OTHER',
                    _document: val
                }
            });
        }
        var pv = {
            _plateforme: $scope.installation._plateforme,
            client_name: $scope.installation.client_name,
            client_id: $scope.installation.client_id || null,
            clientSav_id: ($scope.subClient != null && $scope.subClient._id) ? $scope.subClient._id : $scope.installation.clientSav_id,
            _asset: $scope.asset.name,
            _installation: $scope.installation._id,
            type: "INSTALLATION",
            client_filiale: ($scope.subClient != null && $scope.subClient.name) ? $scope.subClient.name : null,
            purchase_order: $scope.installation.purchase_order,
            _client_code: ($scope.subClient != null && $scope.subClient.code_client) ? $scope.subClient.code_client : $scope.installation.code_client,
            SAP: ($scope.subClient != null && $scope.subClient.SAP) ? $scope.subClient.SAP : $scope.installation.SAP,
            _documents: $scope.docs,
            pv_ref: $scope.pv.pv_ref,
            products: $scope.gnPv.products,
            asset_id: $scope.asset._id,
            asset_dev: ($scope.asset.vin && $scope.asset.vin.length > 0) ? $scope.asset.name + '/' + $scope.asset.vin : undefined,
            related_Model: {
                related_model_id: $scope.installation._id,
                related_asset_id: $scope.asset._id
            }
        }
        I3PvSrv.add(pv, function (res) {
            if (res && res.success) {
                // console.log(res)
                $scope.closeThisDialog(true);
            } else {
                Notification.error($translate.instant("PV_ADD_ERROR"));
                //TODO handle error
            }
        }, function (err) {
            console.log(err)
        })
    }
    $scope.cancel = function () {
        $scope.closeThisDialog();
    }
    $scope.getZone = function (asset, place_id) {



        I3ZoneSrv.query({
            query: {
                _plateforme: $scope.installation._plateforme._id,
                _id: place_id
            }
        }, 1, 0, function (response) {

            $scope.categorys = response.result[0].travel_costs;

        }, function (err) {
            console.log(err);
        });
    }
    $scope.editProduct = function (element, index) {
        $scope.pv.products = $scope.gnPv.products
        ngDialog.open({
            templateUrl: "app/commons/dialogs/pv/product.edit.dlg.html",
            className: 'ngdialog-theme-default custom-width-800',
            controller: "productEditDlgCtrl",
            appendClassName: 'ngdialog-custom',
            scope: $scope,
            resolve: {
                index: function () {
                    return index;
                }
            }
        })
    };
    $scope.removeProduct = function (element, index) {
        $scope.gnPv.products.splice(index, 1);
    };
    $scope.confirmTravel = function (zone, category) {
        $scope.gnPv.includeTravelCosts(zone, category)
        Notification.success($translate.instant("TRAVEL_COSTS_ADD_SUCC"));
    }
    $scope.removeTravel = function (travelCost, zone, category) {
        $scope.travelCost = travelCost
        $scope.gnPv.removeTravelCosts(zone, category)
    }




    $scope.getsubClient = function (clientInvoice) {

        $scope.subClient = clientInvoice
    }
});