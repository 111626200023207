angular.module('app').controller('adminPackagesAddCtrl', function ($scope, $rootScope, $state, $translate, $filter, Notification, ngDialog, I3PackageSrv, I3ProductSrv,constantSrv) {

    $scope.package = {};
    $scope.options_list = [];
    $scope.device_types_list = [];
    $scope.options = null;
    $scope.reverseTranslatedOptions = {};
    $scope.translatedOptions = null;

    $scope.searchOption = function ($select) {
        I3ProductSrv.query({
                query: {
                    _id: {
                        $nin: $scope.options_list.map(function (val) {
                            if (val) {
                                return {'_id': val._id};
                            }
                        })
                    }
                }
            }, 10000, 1,
            function (data) {
                if (data.success) {
                    $scope._ADMIN.products = data.result;
                } else {
                    console.error(data.error)
                }
            },
            function (data) {
                console.dir(data)

            })
        if ($scope.options == null) {

            $scope.options = constantSrv.getProductsOptions();

            var reverseTranslatedOptions = {};
            angular.forEach($scope.options, function (option) {
                var translation = $translate.instant(option);
                reverseTranslatedOptions[translation] = option;
            });
            $scope.reverseTranslatedOptions = reverseTranslatedOptions;
        }

        // Translate back
        $scope.translatedOptions = [];

        angular.forEach($scope.options, function (option) {
            var translation = $translate.instant(option);
            $scope.translatedOptions.push(translation);
        });

    };

    $scope.save = function () {
        // Save the key in database (non-translated)
        $scope.package._products = $scope.options_list.map(function (option) {
            return option._id;
        });

        $scope.package.device_types = $scope.device_types_list;

        // Creating a new package
        I3PackageSrv.add($scope.package, function (response) {
            // console.log(response);

            if (response.success) {
                $scope.packages.push(response.result);

                Notification.success({
                    title: $translate.instant("PRODUCT_CREATED"),
                    message: $translate.instant("PRODUCT_ADDED_SUC")
                });
                $state.go('app.admin.packages');

            } else {
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_CREATE") + " " + $scope.package.name + "!"
                });
            }

        }, function (err) {

            console.log(err);

            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("ERROR_OCCURED_CREATE") + " " + $scope.package.name + "!"
            });
            $scope.package = {};
        })

    };

    /**
     * Cancel creating/editing package
     */
    $scope.cancel = function () {
        $scope.package = {};
        $state.go('app.admin.packages');
    };

    /**
     * Add options
     */
    $scope.addOptions = function (opt) {

        if (opt) {

            if ($scope.options_list.indexOf(opt) >= 0) {
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("ALREADY_EXIST")
                });
            } else {

                //if($scope.reverseTranslatedOptions[opt] == $scope.options[$scope.translatedOptions.indexOf(opt)]){}

                $scope.options_list.push(opt);
                $scope.options.splice($scope.translatedOptions.indexOf(opt), 1);
                $scope.translatedOptions.splice($scope.translatedOptions.indexOf(opt), 1);

            }
        } else {
            Notification.error({
                title: $translate.instant("ERROR"),
                message: $translate.instant("SELECT_AN_OPTION")
            });
        }

        $scope.package.options = null;
    }

    /**
     * Remove options
     */
    $scope.removeOptions = function (index, opt) {
        $scope.options_list.splice(index, 1);
        $scope.options.push($scope.reverseTranslatedOptions[opt]);
        $scope.translatedOptions.push(opt);
    };

    /**
     * Add device type
     */
    $scope.addDeviceType = function (deviceType) {
        if (deviceType) {
            if ($scope.device_types_list.indexOf(deviceType) >= 0) {
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("ALREADY_EXIST")
                });
            } else {
                $scope.device_types_list.push(deviceType);
            }
        } else {
            Notification.error({
                title: $translate.instant("ERROR"),
                message: $translate.instant("NO_DEVICE_TYPE")
            });
        }
        $scope.device_types = '';
    }

    /**
     * Remove device type
     */
    $scope.removeDeviceType = function (index) {
        $scope.device_types_list.splice(index, 1);
    };

}).filter('translation', function () {
    return function (translatedObject) {

        var translatedOptions = [];

        for (key in translatedObject) {
            translatedOptions.push(translatedObject[key]);
        }
        return translatedOptions;

    }
});