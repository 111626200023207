

angular.module('app').controller('workflowEditCtrl', function ($scope, $stateParams, Notification, $translate, ngDialog, I3WorkflowSrv, I3StatusSrv, $state) {
    $scope.workflow = {};
    $scope.statusMatix = {}
    $scope.Models = ["COMPATIBILITY_STUDY","COMPATIBILITY","INSTALLATION", "INTERVENTION", "CLAIM", "INSTALLATION_REQUEST"]
    $scope.status = null;
    I3WorkflowSrv.query({
        query: {
            _id: $stateParams.workflowId
        },
        options: {}
    }, 1, 0, function (res) {
        if (res && res.result) {
            $scope.workflow = res.result[0];
            if ($scope.workflow.model) {
                I3StatusSrv.query({ query: { model: $scope.workflow.model } }, 10000, 0, function (response) {


                    if (response.success && response.result && response.result.length > 0) {

                        $scope.status = response.result[0];
                  
                        $scope.status.status.forEach(function (element) {
                            if (!$scope.statusMatix[element._id])
                                $scope.statusMatix[element._id] = [];
                            $scope.status.status.forEach(function (val) {

                                if (val._id == element._id) {
                                    $scope.statusMatix[element._id].push({ id: val._id, disabled: true, value: false });
                                } else {

                                    $scope.statusMatix[element._id].push({ id: val._id, disabled: false, value: false });

                                }

                            })

                        });

                        $scope.workflow.status.forEach(function (val) {
                            if (val) {
                                Object.keys($scope.statusMatix).forEach(function (statMax) {

                                    if (statMax == val._status) {
                                        val._availStatus.forEach(function (avail) {

                                            $scope.statusMatix[statMax].forEach(function (statMat) {

                                                if (statMat.id == avail) {
                                                    statMat.value = true;

                                                }

                                            })
                                        })
                                    }
                                })
                            }

                        })

                        /*   for (let index = 0; index < $scope.workflow.status.length; index++) {
                               const item = $scope.workflow.status[index];
                               if (item) {
   
                                   for (let statMat in $scope.statusMatix) {
                                       if (statMat == item._status) {
   
                                           for (let elem of item._availStatus) {
   
                                               for (let stats in $scope.statusMatix[statMat]) {
                                                   if ($scope.statusMatix[statMat][stats].id == elem) {
                                                       $scope.statusMatix[statMat][stats].value = true;
   
                                                   }
   
   
                                               }
   
   
                                           }
   
                                       }
                                   }
                               }
                           }*/
                    } else {
                        console.error(response.error)
                    }


                }, function (response) {
                    // console.log(response)

                })
            }


        }


        $scope.editWorkflow = function () {
            // console.log("$scope.statusMatix", $scope.statusMatix)
            Object.keys($scope.statusMatix).forEach(function (val) {
                var statusElement = { _status: val };
                statusElement._availStatus =
                    $scope.statusMatix[val].filter(function (elem) {

                        return elem.value == true;
                    });
                statusElement._availStatus = statusElement._availStatus.map(function (element) {

                    return element.id;

                })

                var alreadyExistInWorkflow=false;
                $scope.workflow.status.forEach(function (val2) {


                    if (val2) {
                        if (val2._status == statusElement._status) {
                            alreadyExistInWorkflow=true;
                            val2._availStatus = statusElement._availStatus;
                        }
                    }



                })
                if(!alreadyExistInWorkflow){
                    // console.log(statusElement)
                    $scope.workflow.status.push(statusElement)
                }

                /*  for (let stat of $scope.workflow.status) {
                      if (stat) {
                          if (stat._status == statusElement._status) {
                              stat._availStatus = statusElement._availStatus;
                          }
                      }
  
                  }*/

                //   $scope.workflow.status.push(statusElement)
            })
            // console.log("$scope.workflow", $scope.workflow)

            I3WorkflowSrv.updateById($scope.workflow._id, $scope.workflow, function (response) {

                if (response.success) {
                    // console.log("response", response)
                    Notification.success({
                        title: $translate.instant("WORKFLOW_UPDATED"),
                        message: $translate.instant("WORKFLOW_UPDATED_SUCC")
                    });
                    $state.go('app.admin.workflow');

                    $scope.workflow = {};
                } else {
                    Notification.error({
                        title: $translate.instant('ERROR'),
                        message: $translate.instant("ERROR_OCCURED_UPDATE") + "!"
                    });
                    $scope.workflow = {};
                }
            }, function (err) {
                // console.log(err);

                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_UDPDATE") + "!"
                });
                $scope.workflow = {};

            })
        };




    })






    $scope.cancel = function () {
        $scope.workflow = {};
        $state.go('app.admin.workflow');
    }


})