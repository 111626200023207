angular.module("app").controller("stockMovementDetailsCtrl", function ($scope, $rootScope, $filter,$stateParams, $state, $translate, ngDialog, Notification,I3StockMovementSrv, I3StockSrv, I3ProductSrv, I3ArticleSrv) {
    $scope.$state = $state;
$scope.stockMovement={}
$scope.HistoriqueMovement=[]
I3StockMovementSrv.query(
    {query:
        {_id:$stateParams.stockMovementId},
        options: {
            populate: [{
                path: "_product",
                model: "product"
            } , {
                path: 'old_stock',
                select: 'name'
            },
            {
                path: 'new_stock',
                select: 'name'
            },{
                path: '_article',
                select: 'imei serialNumber'
            },{
                path: '_user',
                select:'first_name last_name'

            }]
        }

    }, 10000, 1, function (res) {
    if (res.success) {
        $scope.stockMovement= res.result[0];
        if($scope.stockMovement._article) {
            I3StockMovementSrv.query(
                {
                    query:
                        {_article: $scope.stockMovement._article,movement_date:{$lt:$scope.stockMovement.movement_date}},
                    options: {
                        populate: [{
                            path: "_product",
                            model: "product"
                        }, {
                            path: 'old_stock',
                            select: 'name'
                        },
                            {
                                path: 'new_stock',
                                select: 'name'
                            }, {
                                path: '_user',
                                select: 'first_name last_name'

                            }]
                    }

                }, 10000, 1, function (response) {


                    if (response.success) {

                        $scope.HistoriqueMovement = response.result ;

                    }


                }, function (err) {
                    console.log(err);
                });
        }
    }
}, function (err) {
    console.log(err);
});

    
   



I3StockSrv.query({}, 10000, 1, function (res) {
    if (res.success) {
        $scope.ALLStocks = res.result;
        $scope.virtual_stocks = [];
        $scope.ALLStocks.forEach(function (val) {
            $scope.virtual_stocks = $scope.virtual_stocks.concat(val.virtual_stock.map(function (stock) {
                stock._stock = val.name;
                return stock
            }));
        })

     
    }
}, function (err) {
    console.log(err);
});
$scope.getVirtualStokById=function(virtualStockId){

    if(virtualStockId){
        var filtred = $scope.virtual_stocks.filter(function(val){
            return  val._id == virtualStockId;
        })
        if(filtred && filtred.length>0){
            return  filtred[0];
        }else{
            return undefined;
        }
    }else {
        return undefined;
    }
}



});