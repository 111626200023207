/**
 * Created by Kais CHAIBI on 07/12/2017.
 */
angular.module("app.services").factory('constantSrv', function (I3StatusSrv, I3WorkflowSrv, $q, $translate, I3ProductSrv, i3HelperSrv) {

    var service = { workflows: {}, status: {} };
    var listStatus = [];

    /************for monitoring Begin******/
    service.getSubscriptionTypes = function () {
        return ['ANNUAL', 'MONTHLY'];
    };
    service.getResponsabilite = function () {

        return ['CLIENT', 'NGI', "LOCAL_INSTALLER"];
    };
    service.getFileTypes = function () {
        return ['PURCHASE_ORDER', 'INSTALL_PICTURE', 'PV', 'OTHER'];
    };
    service.getFilterStatuses = function () {

        return [
            "New",
            "Disabled"

        ]
    }
    service.getMonitoringStatuses = function () {
        return [
            "New",
            "disabled"

        ]
    }
    service.getFilterPriority = function () {
        return [{
            code: "Haute",
            query: 2
        },
        {
            code: "Moyennne",
            query: 1
        },
        {
            code: "Faible",
            query: 0
        },
        {
            code: "Mode Erreur",
            query: {
                $gte: 1
            }
        }

        ]
    }
    service.getMonitoringPriority = function () {
        return [

            {
                code: "Haute",
                value: 2
            },
            {
                code: "Moyenne",
                value: 1
            },
            {
                code: "Faible",
                value: 0
            }
        ]
    }

    service.getRealSensorValue = function (voltage, minValueVolt, maxValueVolt, realMinValue, realMaxValue) {
        var realValue = voltage;
        if (Number.isFinite(minValueVolt) && Number.isFinite(maxValueVolt) && Number.isFinite(realMinValue) && Number.isFinite(realMaxValue)) {
            realValue = (voltage - minValueVolt) * (realMaxValue - realMinValue) / (maxValueVolt - minValueVolt) + realMinValue;
        }

        return realValue;
    };

    service.getOdoAbsoluteSRC = function () {
        return ["rt.io.CAN_ODO", "rt.io.odo_abs"];
    }
    service.getUnitList = function () {
        return [{
            "name": $translate.instant("NO_UNIT"),
            "code": " "
        },
        {
            "name": $translate.instant("LITRE"),
            "code": "L"
        },
        {
            "name": $translate.instant("CELSIUS_DEGREE"),
            "code": "°C"
        },
        {
            "name": $translate.instant("FARENHEIT_DEGREE"),
            "code": "°F"
        },
        {
            "name": $translate.instant("KILOMETRE"),
            "code": "Km"
        },
        {
            "name": $translate.instant("KILOGRAM"),
            "code": "Kg"
        },
        {
            "name": $translate.instant("KILOMETRE_PER_HOUR"),
            "code": "Km/h"
        },
        {
            "name": $translate.instant("MILE_PER_HOUR"),
            "code": "Mph"
        },
        {
            "name": $translate.instant("DEGREE"),
            "code": "°"
        },
        {
            "name": $translate.instant("METER"),
            "code": "M"
        },
        {
            "name": $translate.instant("MILLI_AMPERES"),
            "code": "mA"
        },
        {
            "name": $translate.instant("ROTARY_PER_MINUTE"),
            "code": "Rpm"
        },
        {
            "name": $translate.instant("VOLTS"),
            "code": "V"
        },
        {
            "name": $translate.instant("PERCENTAGE"),
            "code": "%"
        }
        ]
    };

    /**
     * Get Sensor list by family
     * @returns {{BINARY: [string,string,string,string], ANALOGIC: [string,string,string,string,string,string,string]}}
     */
    service.getSensorsTypes = function () {
        return {
            BINARY: ["TAXI_STATUS", "DOOR_STATUS", "REFRIGERATION_STATUS", "SPINNING_STATUS"],
            STATUS: {
                "TAXI_STATUS": {
                    enabled: "AVAILABLE",
                    disabled: "OCCUPIED"
                },
                "DOOR_STATUS": {
                    enabled: "OPENED",
                    disabled: "CLOSED"
                },
                "REFRIGERATION_STATUS": {
                    enabled: "ENABLED",
                    disabled: "DISABLED"
                },
                "SPINNING_STATUS": {
                    enabled: "SPINNING",
                    disabled: "STOP"
                }
            },
            ANALOGIC: ["TEMPERATURE", "FUEL_LEVEL", "GAS", "FUEL_USED", "PRESSURE", "ODOMETER", "RPM"],
            ICONS: {
                "DOOR_STATUS_ON": "door-opened",
                "DOOR_STATUS_OFF": "door-closed",
                "ODOMETER": "kilometrage",
                "TEMPERATURE": "temperature",
                "FUEL_LEVEL": "fuel",
                "SPINNING_STATUS_OFF": "rotation-stop",
                "SPINNING_STATUS_ON": "rotation",
                "TAXI_STATUS_ON": "taxi-available",
                "TAXI_STATUS_OFF": "taxi-unavailable",
                "FUEL_USED": "fuel-used",
                "GAS": "gas",
                "RPM": "rpm",
                "REFRIGERATION_STATUS_ON": "refrigerator-start",
                "REFRIGERATION_STATUS_OFF": "refrigerator-stop",
                "PRESSURE": "pressure"

            }
        }
    };

    /**
     * Sensor family from type
     * @param type
     * @returns {string}
     */
    service.getSensorFamily = function (type) {
        var family = "ANALOGIC";
        if (service.getSensorsTypes().BINARY.indexOf(type) > -1) {
            family = "BINARY";
        }
        if (service.getSensorsTypes().ANALOGIC.indexOf(type) > -1) {
            family = "ANALOGIC";
        }

        return family;
    };

    service.isAbsoluteValue = function (sensor) {

        //console.log("IS Absolute ", sensor)
        if (sensor.type == "ODOMETER") {

            if (service.getOdoAbsoluteSRC().indexOf(sensor.src) >= 0) {
                sensor.isAbsolute = true;
            } else {
                sensor.isAbsolute = false;
            }

        }
        //console.log("IS Absolute 2 ", sensor)
        return sensor;
    }
    /************for monitoring End******/
    service.getInstallationStatus = function () {

        return [
            "READY",
            "IN_PROGRESS",
            "ON_HOLD",
            "DONE"
        ]
        /* var installaStatus=[]
         I3StatusSrv.query({query:{model: "INSTALLATION" }}, 10000, 0, function (data) {
             if (data.success) {
                 data.result[0].status.forEach(function(elem){
                     installaStatus.push(elem)
                 })
         } else {
                 console.error(data.error)
             }
         });
         return installaStatus*/
    };

    service.getInstallRequestStatus = function () {
        return [
            "NEW",
            "READY",
            "IN_PROGRESS",
            "DONE",
            "ON_HOLD",
            "CLOSED",
            "CANCELED"

        ]
    };
    service.getInstallRequestEditStatus = function () {
        return [
            "NEW",
            "READY",
            "IN_PROGRESS",
            "DONE",
            "ON_HOLD",
            "CLOSED",
            "CANCELED"

        ]
    };

    service.getInstallRequestStatusByState = function (state) {
        if (state == "NEW")
            return ["NEW", "READY", "IN_PROGRESS", "DONE", "ON_HOLD", "CLOSED", "CANCELED"]
        if (state == "READY")
            return ["READY", "IN_PROGRESS", "DONE", "ON_HOLD", "CLOSED", "CANCELED"]
        if (state == "IN_PROGRESS")
            return ["IN_PROGRESS", "ON_HOLD", "DONE", "CLOSED", "CANCELED"]
        if (state == "DONE")
            return ["DONE", "CLOSED", "CANCELED"]
        if (state == "ON_HOLD")
            return ["READY", "IN_PROGRESS", "DONE", "ON_HOLD", "CLOSED", "CANCELED"]
        if (state == "CLOSED")
            return ["CLOSED"]
        if (state == "CANCELED")
            return ["NEW", "READY", "IN_PROGRESS", "DONE", "ON_HOLD", "CLOSED", "CANCELED"]
    };
    service.getAssetStatusInstall = function () {
        return [
            "NEW",
            "PLANNED",
            "INSTALLED",
            "COMMISSIONING",
            "SUSPENDED"

        ]
    };
    service.getAssetStatusInstallByState = function (state) {
        if (state == "NEW")
            return ["NEW", "PLANNED", "SUSPENDED", "INSTALLED", "COMMISSIONING"]
        if (state == "PLANNED")
            return ["PLANNED", "SUSPENDED", "INSTALLED", "COMMISSIONING"]
        if (state == "INSTALLED")
            return ["SUSPENDED", "INSTALLED", "COMMISSIONING"]
        if (state == "SUSPENDED")
            return ["SUSPENDED", "INSTALLED", "COMMISSIONING"]
        if (state == "INSTALLED")
            return ["INSTALLED", "COMMISSIONING"]
        if (state == "COMMISSIONING")
            return ["COMMISSIONING"]
    };
    service.getInterventionStatus = function () {
        return [
            "New",
            "Planned",
            "InProgress",
            "Done",
            "Rejected",
            "OnHold",
            "Postponed",
            "Cancel"
        ]
    };

    service.getClaimsStatus = function () {
        return [
            "New",
            "InProgress",
            "InDevelopment",
            "InIntervention",
            "Done",
            "Rejected",
            "OnHold"
        ]
    };

    service.getClaimsSource = function () {
        return [
            "Application",
            "phone",
            "Skype",
            "Mail",
            "autre"
        ]
    };
    service.getClaimsLevels = function () {
        return [
            "LEVEL_1",
            "LEVEL_2",
            "LEVEL_3"
        ]
    };

    service.getModel = function () {
        return [
            "Demande installations",
            "Installations",
            "Interventions",
            "Réclamation"
        ]
    };

    service.getPredefinedStatus = function () {
        return [
            "Done",
            "Rejected",
            "Cancel"
        ]
    };

    service.getProducts = function () {
        return [
            "Carburant",
            "Tempétature",
            "Dépassement vitess",
            "Portes Ouvertes",
            "Autre"
        ]
    };
    service.getClaimType = function () {
        return [
            "Désinstallation ",
            "Réinstallation",
            "Transfert"
        ]
    };
    service.getPhase = function () {
        return [
            "InProcpection",
            "InProcess",
            "InValidation"
        ]
    };
    service.getStatusProscpection = function () {
        return [
            "Created",
            "Sent",
            "Received"
        ]
    };
    service.getStatusValidation = function () {
        return [
            "InValidation",
            "Validated"
        ]
    };
    service.loadWorkflowAndStatus = function (callback) {
        var workflowpromise = $q.defer();
        var statuspromise = $q.defer();
        I3WorkflowSrv.query({}, 10000, 0, function (data) {
            if (data.success) {
                data.result.forEach(function (element) {

                    service.workflows[element.model] = element;

                })

                workflowpromise.resolve()
            } else {
                console.error(data.error);

            }

        });

        I3StatusSrv.query({}, 10000, 0, function (data) {
            if (data.success) {
                data.result.forEach(function (element) {

                    service.status[element.model] = element;

                })
                statuspromise.resolve()
            } else {
                console.error(data.error);
            }
        });
        $q.all([workflowpromise.promise, statuspromise.promise]).then(function () {

            Object.keys(service.workflows).forEach(function (model) {

                service.workflows[model].status.forEach(function (stat, index) {

                    service.status[model].status.forEach(function (statu) {

                        if (statu._id == stat._status) {

                            service.workflows[model].status[index].name = statu.name;
                        }
                    })

                    stat._availStatus.forEach(function (avstat, index2) {

                        service.status[model].status.forEach(function (statu) {

                            if (statu._id == avstat) {

                                service.workflows[model].status[index]._availStatus[index2] = {
                                    _id: avstat,
                                    name: statu.name
                                };
                            }
                        })

                    })

                })
                service.workflows[model].status.forEach(function (stat, index) {

                    service.status[model].status.forEach(function (statu) {

                        if (statu._id == stat._status) {

                            service.workflows[model].status[index].name = statu.name;
                        }
                    })

                })
            })

            callback()

        })
    }
    service.getModel = function (model) {
        return {
            getAllStatus: function (cb) {
                if (service.workflows && Object.keys(service.workflows).length > 0) {

                    cb(null, service.status[model].status)

                } else {
                    service.loadWorkflowAndStatus(function () {

                        cb(null, service.status[model].status)

                    })
                }

            },
            getAvailableStatusByStatus: function (statusname, cb) {
                if (service.workflows && Object.keys(service.workflows).length > 0) {
                    var statusFilter = service.workflows[model].status.filter(function (stats) {
                        return stats.name == statusname;
                    })
                    if (statusFilter && statusFilter.length > 0) {

                        cb(null, statusFilter[0])
                    } else {
                        cb(null, [])
                    }

                } else {
                    service.loadWorkflowAndStatus(function () {
                        var statusFilter = service.workflows[model].status.filter(function (stats) {
                            return stats.name == statusname;
                        })
                        if (statusFilter && statusFilter.length > 0) {
                            cb(null, statusFilter[0])
                        } else {
                            cb(null, [])
                        }
                    })
                }

            }

        }
    }
    service.getCompatibilityRequestStatus = function (cb) {
        /* return [
             "NEW",
             "READY",
             "IN_PROGRESS",
             "DONE",
             "PENDING",
             "CANCELED",
             "CLOSED",
             "DELETED"
 
         ]*/
        /*    service.loadWorkflowAndStatus(function () {
                console.log("resolve")
            })
            var requestStatus = [];
            I3WorkflowSrv.query({ query: { model: "COMPATIBILITY" } }, 10000, 0, function (data) {
                if (data.success) {
                    data.result[0].status.forEach(function (elem) {
                        if (elem) {
                            I3StatusSrv.query({ query: { model: "COMPATIBILITY" } }, 10000, 0, function (status) {
                                if (status.success) {
                                    status.result[0].status.forEach(function (state) {
                                        if (state && elem._status == state._id) {
                                            requestStatus.push(state.name)
                                        }
                                    })
                                } else {
                                    console.error(data.error)
                                }
                            })

                        }
                    })
                } else {
                    console.error(data.error)
                }

            });
            return requestStatus*/

    };

    service.getCompatibilityRequestByState = function (state) {
        if (state == "NEW")
            return ["READY", "DELETED"]
        if (state == "READY")
            return ["CANCELED"]
        if (state == "DONE")
            return ["PENDING", "CANCELED"]
        if (state == "PENDING")
            return ["CANCELED", "CLOSED"]
        if (state == "CANCELED")
            return ["PENDING", "CLOSED"]

    };

    service.getCompatibilityEditReqStatus = function () {
        return [
            "NEW",
            "READY"

        ]
    };
    service.getCompatibilityCheckStatus = function () {
        return [
            "READY",
            "IN_PROGRESS"

        ]
    };
    service.getCompatibilityAssetStatus = function () {
        return [
            "NEW",
            "VALIDATED"

        ]
    };

    service.getProductsOptions = function () {
        return [
            "device",
            "canbus",
            "direct_line",
            "temp_probe",
            "door_sensor",
            "ibutton_reader",
            "ibutton_tag",
            "group_cold_sensor",
            "immobilizer_12V",
            "immobilizer_24V",
            "sim_supplier_ngi"
        ]
    };

    service.getMoveStockPattern = function () {
        return [
            { code: "INSTALLATION", name: $translate.instant("MOVE_STOCK_PATTERN_INSTALLATION") },
            { code: "CONFIGURATION", name: $translate.instant("MOVE_STOCK_PATTERN_CONFIGURATION") },
            { code: "RECYCLING_ENTRY", name: $translate.instant("MOVE_STOCK_PATTERN_RECYCLING_ENTRY") },
            { code: "RECYCLED", name: $translate.instant("MOVE_STOCK_PATTERN_RECYCLED") },
            { code: "OUT_OF_ORDER", name: $translate.instant("MOVE_STOCK_PATTERN_OUT_OF_ORDER") },
            { code: "CUSTOMER_RENTAL", name: $translate.instant("MOVE_STOCK_PATTERN_CUSTOMER_RENTAL") },
            { code: "PURCHASE_CUSTOMER_RESERVE", name: $translate.instant("MOVE_STOCK_PATTERN_CUSTOMER_RESERVE") },
            { code: "CUSTOMER_TERMINATION", name: $translate.instant("MOVE_STOCK_PATTERN_CUSTOMER_TERMINATION") },
            { code: "OTHER", name: $translate.instant("MOVE_STOCK_PATTERN_OTHER") }

        ]
    };
    service.getAllModel = function () {
        return [

            { name: "PV", model: "pv" },
            { name: "PLATEFORME", model: "plateforme" },
            { name: "INTERVENTION", model: "intervention" },
            { name: "CLAIM", model: "claim" },
            { name: "INSTALLATIONGROUP", model: "installationgroup" },
            { name: "COMPATIBILITY", model: "compatibility" },
            { name: "DELIVERY_SLIP", model: "delivery_slip" },
            { name: "RECEIPT_SLIP", model: "receipt_slip" },
            { name: "DELIVERY_REQUEST", model: "delivery_request" },
            { name: "STOCK_MOVEMENT", model: "stock_movement" },
            { name: "GOODS_RETURN_SLIP", model: "goods_return_slip" },
            { name: "PENDING_PV_SYNC", model: "pending_pv_sync" }

        ]
    };

    service.getCompatibilityStatus = function () {

        return [
            "NEW",
            "READY",
            "IN_PROGRESS",
            "DONE",
            "PENDING",
            "CANCELED",
            "CLOSED",
            "DELETED"

        ]

    }
    service.getStatusbyModel = function (model) {
        if (model == 'compatibility') {

            return {
                getStatus: function (cb) {
                    I3StatusSrv.query({
                        query: {

                            model: { $in: ["COMPATIBILITY", "COMPATIBILITY_STUDY"] }
                        }
                    }, 10, 0, function (data) {

                        if (data.result.length > 0) {
                            listStatus1 = data.result[0].status.map(function (val) {
                                return val.name
                            });
                            listStatus2 = data.result[1].status.map(function (val) {
                                return val.name
                            });
                            var listStatusCM = service.merge(listStatus1, listStatus2);

                            cb(null, listStatusCM)
                        } else {

                            cb(null, [])
                        }

                    }, function (err) {

                        console.log(err)
                    })

                },
            }
        } else if (model == 'installation_group') {
            return {
                getStatus: function (cb) {
                    I3StatusSrv.query({
                        query: {

                            model: { $in: ["INSTALLATION", "INSTALLATION_REQUEST"] }
                        }
                    }, 10, 0, function (data) {

                        if (data.result.length > 0) {

                            listStatus1 = data.result[0].status.map(function (val) {
                                return val.name
                            });
                            listStatus2 = data.result[1].status.map(function (val) {
                                return val.name
                            });
                            var listStatusIN = service.merge(listStatus1, listStatus2);

                            cb(null, listStatusIN)
                        } else {

                            cb(null, [])
                        }

                    }, function (err) {

                        console.log(err)
                    })

                },
            }
        } else {
            return {
                getStatus: function (cb) {
                    I3StatusSrv.query({
                        query: {
                            model: model.toUpperCase()

                        }
                    }, 10, 0, function (data) {
                        console.log(data)
                        if (data.result.length > 0) {
                            listStatus = data.result[0].status.map(function (val) {
                                return val.name
                            });
                            cb(null, listStatus)
                        } else {

                            cb(null, [])
                        }

                    }, function (err) {

                        console.log(err)
                    })

                },
            }

        }

    }

    service.merge = function (tab1, tab2) {
        var result = [];
        var arr = tab1.concat(tab2);
        var len = arr.length;
        var assoc = {};

        while (len--) {
            var item = arr[len];

            if (!assoc[item]) {
                result.unshift(item);
                assoc[item] = true;
            }
        }

        return result;
    }

    service.convertProduct = function (products, cb) {

        productListInstallation = [];
        for (property in products) {
            productListInstallation[property] = { qty: products[property] }
        }

        i3HelperSrv.getEnums(function (enums) {
            console.log(enums)
            try {
                if (enums && enums.code_products) {
                    const invoiceable_id = ["GPS_DEVICE", "SIM_CARD", "CAN_BUS", "DIRECT_LINE", "RELAY_12V", "RELAY_24V", "IBUTTON_READER", "IBUTTON_TAG", "DOOR_SENSOR", "TEMPERATURE_PROBE", "GROUP_STATE"];
                    const invoiceableProductCode = [];
                    const consumablesProductCode = [];
                    invoiceable_id.forEach(function (id) {
                        if (enums.code_products[id]) {
                            invoiceableProductCode.push(enums.code_products[id])
                        }
                    })

                    Object.keys(enums.code_products).forEach(function (e) {
                        if (invoiceableProductCode.indexOf(enums.code_products[e]) < 0) {
                            consumablesProductCode.push(enums.code_products[e])
                        }
                    })

                    I3ProductSrv.query({ query: { type: "MATERIAL", is_stockable: true } }, 10000, 1, function (res) {
                        try {
                            if (res.success && res.result && res.result.length > 0) {

                                var invoicableProduct = [];
                                var consommablesProduct = [];
                                res.result.forEach(function (prod) {
                                    var isInvoicable = false;
                                    if (prod.code && prod.code.product_type) {
                                        if (invoiceableProductCode.indexOf(prod.code.product_type) >= 0) {
                                            invoicableProduct.push(prod);
                                        } else {
                                            // console.log(prod)
                                            consommablesProduct.push(prod);
                                        }
                                    } else {
                                        consommablesProduct.push(prod);
                                    }
                                })

                                // console.log(invoicableProduct)
                                function getProductByCodeId(codeId) {
                                    var productTypeCode = enums.code_products[codeId];

                                    var product = null;
                                    if (productTypeCode) {
                                        invoicableProduct.forEach(function (prod) {
                                           // if (codeId == "CAN_BUS" || codeId == "DIRECT_LINE")
                                                 
                                                //console.log(prod.code.product_type,codeId)


                                                if (prod && prod.code && prod.code.product_type == productTypeCode) {

                                                    product = prod;
                                                }
                                        })

                                        return product;
                                    } else {
                                        return null;
                                    }
                                }

                                productConverter = [];
                                Object.keys(products).forEach(function (key) {
                                  //  console.log(key)
                                    switch (key) {

                                        case "sim_supplier_ngi":
                                            productConverter.push({
                                                _product: getProductByCodeId("SIM_CARD"),
                                                qty: products[key]
                                            });
                                            break;
                                        case "immobilizer_24V":
                                            productConverter.push({
                                                _product: getProductByCodeId("RELAY_24V"),
                                                qty: products[key]
                                            });
                                            break;
                                        case "immobilizer_12V":
                                            productConverter.push({
                                                _product: getProductByCodeId("RELAY_12V"),
                                                qty: products[key]
                                            });
                                            break;
                                        case "group_cold_sensor":
                                            productConverter.push({
                                                _product: getProductByCodeId("GROUP_STATE"),
                                                qty: products[key]
                                            });
                                            break
                                        case "ibutton_tag":
                                            productConverter.push({
                                                _product: getProductByCodeId("IBUTTON_TAG"),
                                                qty: products[key]
                                            });
                                            break;
                                        case "ibutton_reader":
                                            productConverter.push({
                                                _product: getProductByCodeId("IBUTTON_READER"),
                                                qty: products[key]
                                            });
                                            break;
                                        case "door_sensor":
                                            productConverter.push({
                                                _product: getProductByCodeId("DOOR_SENSOR"),
                                                qty: products[key]
                                            });
                                            break;
                                        case "direct_line":
                                            productConverter.push({
                                                _product: getProductByCodeId("DIRECT_LINE"),
                                                qty: products[key]
                                            });
                                            break;
                                        case "canbus":
                                            productConverter.push({
                                                _product: getProductByCodeId("CAN_BUS"),
                                                qty: products[key]
                                            });
                                            break;
                                        case "temp_probe":
                                            productConverter.push({
                                                _product: getProductByCodeId("TEMPERATURE_PROBE"),
                                                qty: products[key]
                                            });
                                            break;
                                        case "device":
                                            productConverter.push({
                                                _product: getProductByCodeId("GPS_DEVICE"),
                                                qty: products[key]
                                            });
                                            break;

                                    }

                                })

                                console.log('productConverter:', productConverter)
                                cb(null, productConverter, invoicableProduct, consommablesProduct);
                            } else {
                                cb("NO_PRODUCT_FOUND");

                            }

                        } catch (e) {
                            console.error(e);
                        }
                    }, function (err) {

                        cb(err);
                    })
                } else {
                    cb("UNABLE_TO_GET_ENUMS");
                }
            } catch (e) {
                console.error(e);
            }
        }, function (err) {
            cb(err);
        })
    }
    service.getProfiles = function () {

        return {
            SUPERADMIN: [
                "DASHBOARD::DASH",
                "DASHBOARD::HISTORY",
                "HOME::COMPATIBILITY_REQUEST",
                "HOME::COMPATIBILITY_CHECK",
                "HOME::INSTALLATION_REQUEST",
                "HOME::INSTALLATION",
                "HOME::INSTALLATION_VALIDATION",
                "HOME::CLAIM",
                "HOME::INTERVENTION_REQUEST",
                "HOME::INTERVENTION",
                "HOME::INTERVENTION_VALIDATION",
                "HOME::PV",
                "STOCK_MANAGEMENT::RECEIPT_SLIP",
                "STOCK_MANAGEMENT::DELIVERY_SLIP",
                "STOCK_MANAGEMENT::GOODS_RETURN_SLIP",
                "STOCK_MANAGEMENT::STOCK",
                "STOCK_MANAGEMENT::ARTICLE",
                "STOCK_MANAGEMENT::STOCK_MOVEMENT",
                "STOCK_MANAGEMENT::DELIVERY_REQUEST",
                "ADMIN::PLATEFORME",
                "ADMIN::USER",
                "ADMIN::ROLE",
                "ADMIN::PRODUCT",
                "ADMIN::STOCK",
                "ADMIN::ZONE",
                "ADMIN::DOCUMENT",
                "ADMIN::SETTING",
                "ADMIN::STATUS",
                "ADMIN::PRODUCT_CODE",
                "ADMIN::WORKFLOW",
                "ADMIN::DASHBOARD",
                "ADMIN::INVOICE_CONNECTOR",
                "ACCOUNTS_SUBSCRIPTION::ACCOUNTS",
                "ACCOUNTS_SUBSCRIPTION::SUBSCRIPTION",
                "MONITORING::MONITORING",
                "MONITORING::STATISTIC_PLATFORM"
            ],
            admin_filiale: [
                "DASHBOARD::DASH",
                "DASHBOARD::HISTORY",
                "HOME::COMPATIBILITY_REQUEST",
                "HOME::INSTALLATION_REQUEST",
                "HOME::INSTALLATION",
                "HOME::CLAIM",
                "HOME::INTERVENTION_REQUEST",
                "HOME::INTERVENTION",
                "HOME::PV",
                "MONITORING::MONITORING",
                "MONITORING::STATISTIC_PLATFORM",
                "ACCOUNTS_SUBSCRIPTION::SUBSCRIPTION",
                "ACCOUNTS_SUBSCRIPTION::ACCOUNTS",
                "STOCK_MANAGEMENT::RECEIPT_SLIP",
                "STOCK_MANAGEMENT::DELIVERY_SLIP",
                "STOCK_MANAGEMENT::GOODS_RETURN_SLIP",
                "STOCK_MANAGEMENT::STOCK",
                "STOCK_MANAGEMENT::ARTICLE",
                "STOCK_MANAGEMENT::STOCK_MOVEMENT",
                "ADMIN::PLATEFORME",
                "ADMIN::USER",
                "ADMIN::PRODUCT",
                "ADMIN::DOCUMENT",
                "ADMIN::STOCK",
                "ADMIN::ZONE",
                "ADMIN::PRODUCT_CODE",
                "ADMIN::INVOICE_CONNECTOR"
            ],
            DEVICE_MANAGER: [
                "STOCK_MANAGEMENT::RECEIPT_SLIP",
                "STOCK_MANAGEMENT::DELIVERY_SLIP",
                "STOCK_MANAGEMENT::GOODS_RETURN_SLIP",
                "STOCK_MANAGEMENT::STOCK",
                "STOCK_MANAGEMENT::ARTICLE",
                "STOCK_MANAGEMENT::STOCK_MOVEMENT",
                "STOCK_MANAGEMENT::DELIVERY_REQUEST",
                "ADMIN::PRODUCT",
                "ADMIN::STOCK",

            ],
            INSTALLER: [
                "HOME::INSTALLATION",
                "HOME::INTERVENTION",
                "HOME::PV",
                "STOCK_MANAGEMENT::DELIVERY_REQUEST",

            ],
            SUPPORT: [
                "HOME::COMPATIBILITY_CHECK",
                "HOME::INSTALLATION_VALIDATION",
                "HOME::CLAIM",
                "HOME::INTERVENTION_VALIDATION",
                "HOME::PV",
                "MONITORING::MONITORING",
                "ACCOUNTS_SUBSCRIPTION::SUBSCRIPTION",
                "ACCOUNTS_SUBSCRIPTION::ACCOUNTS",

            ],
            COMMERCIAL: [
                "HOME::COMPATIBILITY_REQUEST",
                "HOME::INSTALLATION_REQUEST",
                "HOME::CLAIM",
                "HOME::INTERVENTION_REQUEST",
                "HOME::PV",
                "MONITORING::MONITORING",
                "MONITORING::STATISTIC_PLATFORM",
                "ACCOUNTS_SUBSCRIPTION::SUBSCRIPTION",
                "ACCOUNTS_SUBSCRIPTION::ACCOUNTS",
                "STOCK_MANAGEMENT::STOCK",
                "STOCK_MANAGEMENT::ARTICLE",
                "ADMIN::DOCUMENT",
                "ADMIN::ZONE",

            ]
        }

    }

    return service;
})