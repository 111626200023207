angular.module("app").controller("deliveryRequestConvertCtrl", function ($scope, $timeout, $stateParams, $rootScope, $filter, $state, $translate, ngDialog, Notification, I3deliverySlipSrv, I3StockSrv, I3DeliveryRequestSrv) {

    $scope.deliverySlip = {};
    $scope.valide = null
    $scope.pvValide = null;
    $scope.validQty = false
    I3DeliveryRequestSrv.query({
        query: {
            _id: $stateParams.deliveryRequestId
        },
        options: {
            populate: [
                {
                    path: "_plateforme",
                    select: 'name'
                }, {
                    path: "created_by",

                }
                , {
                    path: "products._product",

                }

            ]
        }
    }, 1, 0, function (res) {

        if (res && res.success && res.result.length > 0) {
            $scope.deliveryRequest = res.result[0];

            $scope.validProduct = []
            $scope.deliveryRequest.products.forEach(function (val) {
                if (val && val.qty !== 0) {
                    $scope.validProduct.push(val)
                }
            })
        } else
            Notification.error($translate.instant("FIND_BY_ID_ERROR"));
    }, function (err) {
        Notification.error($translate.instant("FIND_BY_ID_ERROR"))
    })
    $timeout(function () { }, 500).then(function () {
        if (($rootScope.checkAccess("PLATEFORME", "R", "O")) && $scope._STOCK && $scope._STOCK.ALLPlateformes && $scope._STOCK.ALLPlateformes[0])
            $scope.deliverySlip._plateforme = $scope._STOCK.ALLPlateformes[0];
    });

    $scope.save = function () {
        $scope.deliverySlip._plateforme = $scope.deliveryRequest._plateforme
        $scope.deliverySlip.client = $scope.deliveryRequest.client
        $scope.deliverySlip.asset = $scope.deliveryRequest.asset_name
        $scope.deliverySlip.delivred_to = $scope.deliveryRequest.created_by.first_name;
        $scope.deliverySlip.delivery_request_id = $scope.deliveryRequest._id
        // Creating a new deliverySlip

        I3deliverySlipSrv.add($scope.deliverySlip, function (response) {
            console.log(response)
            if (response.success) {
                Notification.success({
                    title: $translate.instant("DELIVERY_SLIP_CREATED"),
                    message: $translate.instant("DELIVERY_SLIP_CREATED_SUC")
                });
                $state.go('app.stocks.deliverySlip', {}, {
                    reload: true
                });

            } else {

                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("DELIVERY_SLIP_ERROR_OCCURED_CREATE") + "!"
                });

            }

        }, function (err) {
            console.log(err)
            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("DELIVERY_SLIP_ERROR_OCCURED_CREATE") + "!"
            });


        })
    };

    $scope.openDeliveryDialog = function (product, index) {



        var dialog = ngDialog.open({
            controller: "deliveryRequestConvertDlgCtrl",
            templateUrl: "app/commons/dialogs/deliveryRequest/deliveryRequest.convert.dlg.tpl.html",
            scope: $scope,
            className: 'ngdialog-theme-default custom-width-800'
        });
        dialog.closePromise.then(function (data) {

            if (data && data.value !== undefined && data.value !== '$closeButton' && data.value !== '$document') {

                if ($scope.somQty($scope.validProduct) != $scope.somQty(data.value.products)) {
                    $scope.validQty = false
                } else {
                    $scope.validQty = true
                }


                /*   if(data.value.listserial && data.value.listserial.length>0 ){
                       $scope.deliverySlip.products.splice(index,1)
                       data.value.listserial.forEach(function(val){
                           $scope.deliverySlip.products.push (val) 
                       })
                       
                   }else{
                       $scope.deliverySlip.products[index]= data.value
                   }
               
                   $scope.ListValidQty = [] ;
                   $scope.ListValidStock = [] ;
                   $scope.deliverySlip.products.forEach(function(val){
                       if(val && val.qty !==0 ){
                           $scope.ListValidQty.push(val)
                       }
                   })
                   $scope.ListValidQty.forEach(function(elem){
                       if(val._stock && val.virtual_stock){
                           $scope.ListValidStock.push(elem) 
                       }
                   })*/


            }

        })









    };

    I3StockSrv.query({}, 10000, 1, function (res) {
        if (res.success) {
            $scope.ListStocks = res.result;
            $scope.virtual_stocks = [];
            $scope.ListStocks.forEach(function (val) {
                $scope.virtual_stocks = $scope.virtual_stocks.concat(val.virtual_stock.map(function (stock) {
                    stock._stock = val.name;
                    return stock
                }));
            })

        }
    }, function (err) {
        console.log(err);
    });

    $scope.getVirtualStokById = function (virtualStockId) {
        if (virtualStockId) {
            var filtred = $scope.virtual_stocks.filter(function (val) {
                return val._id == virtualStockId;
            })
            if (filtred && filtred.length > 0) {
                return filtred[0];
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    }

    $scope.removeDeliveryProduct = function (element, index) {
        $scope.deliverySlip.products.splice(index, 1);

        if ($scope.somQty($scope.validProduct) != $scope.somQty($scope.deliverySlip.products)) {
            $scope.validQty = false
        } else {
            $scope.validQty = true
        }
    };
    /**
     * editing stock_virtual
     */
    /*$scope.editDeliveryProduct= function (prod, index) {

        ngDialog.open({
            templateUrl: "app/commons/dialogs/deliverySlip/deliverySlip.edit.dlg.html",
            controller: "editDeliveryProductDlgCtrl",
            scope: $scope,
            resolve: {
                index: function () {
                    return index;
                }
            }
        })
    };*/
    /**
     * Cancel creating/editing stock
     */
    $scope.cancel = function () {
        $scope.deliverySlip = {};
        $state.go('app.stocks.deliverySlip');
    };

    $scope.getRef = function (ref) {
        console.log("reference", ref)
        if (ref != undefined) {

            I3deliverySlipSrv.query({
                query: {
                    reference: ref
                }
            }, 10000, 1, function (res) {
                if (res.success) {
                    console.log(res.result);
                    if (res.result.length > 0) {

                        $scope.valide = true
                    } else {
                        $scope.valide = false
                    }

                }
            }, function (err) {
                console.log(err);
            });

        }
    }
    $scope.getPvNumber = function (pv) {

        if (pv != undefined) {

            I3deliverySlipSrv.query({
                query: {
                    pv_ref: pv
                }
            }, 10000, 1, function (res) {
                if (res.success) {
                    console.log(res.result);
                    if (res.result.length > 0) {

                        $scope.pvValide = true
                    } else {
                        $scope.pvValide = false
                    }

                }
            }, function (err) {
                console.log(err);
            });

        }
    }

    $scope.somQty = function (products) {
        var valeurInitiale = 0;
        var somme = products.reduce(function (accumulateur, valeurCourante) {
            return accumulateur + valeurCourante.qty;
        }, valeurInitiale);
        return somme
    }
    $scope.getStock = function (stock) {
        if (stock) {
            $scope.deliverySlip.virtual_stock = null
        }



    }
})