angular.module('app').controller('compatibilityCheckEditCtrl', function ($scope, $timeout, $state, $translate, $rootScope, $stateParams, messageBox, I3UserSrv, CompatibilityUtilsSrv, constantSrv, Notification, I3CompatibilitySrv) {
    //#region scopes
    //vars and scopes
    var compatibilityCheckSaved;
    $scope.compatibilityRequestStatus = [];
    $scope.compatibilityCheckStatus = [];
    $scope.compatibilityRequestStatus_available = [];
    $scope.com = [];
    $scope.loading = false;
    $scope.selected = null;
    $scope.previous = null;
    $scope.user = "";
    $scope.content = "";
    $scope.selectedIndex = 0;
    $scope.currentPage = 0;
    $scope.pageSize = 25;
    $scope.current_asset = {};
    $scope.numberOfPages = 1;
    $scope.filterParams = {
        NEW: true,
        VALIDATED: true
    };
    $scope.timeleft = 0;
    $scope.now = moment()
    $scope.redv = 0;
    $scope.getvalue = function (deadline, deadline_period) {
        if (deadline && deadline_period) {
            var deadline_start = moment(deadline).subtract(deadline_period, 'h');
          //  console.log(deadline_start)
            var date2 = moment(deadline);
            var date1 = moment();
            $scope.date_limite = date2;
            var differenceInMs = date2.diff(date1); // diff yields milliseconds
            var duration = moment.duration(differenceInMs);
            var differenceInHours = Math.floor(duration.asHours());

            if (-differenceInHours >= 1000) {
                $scope.timeleft = 1000
            } else
                $scope.timeleft = -differenceInHours;
            var redv = Math.floor(100 - ((differenceInHours / deadline_period) * 100))
            if (redv >= 100) redv = 100
            console.log(redv)
            return redv
        }
    }
    //#endregion
    //#region initial data
    //get user login
    I3UserSrv.getById($rootScope.SESSION.user._id, function (response) {
        $scope.user = response.result.login;
    }, function (err) {
        console.log(err);
    });
    //ui select get assets status constant
    $scope.compatibilityAssetStatus = constantSrv.getCompatibilityAssetStatus();
    //compatibility Request Status constant
    constantSrv.getModel("COMPATIBILITY").getAllStatus(function (err, status) {
        $scope.compatibilityRequestStatus = status.map(function (val) {
            return val.name
        });
    });
    //compatibility study Status constant
    constantSrv.getModel("COMPATIBILITY_STUDY").getAllStatus(function (err, stat) {
        $scope.compatibilityCheckStatus = stat.map(function (val) {
            return val.name
        });
    });
    //get the CompatibilityCheck data
    $scope.getCompatibilityCheck = function () {
        var query = {
            _id: $stateParams.compatibilityId
        };
        var options = {
            populate: [{
                path: '_modified_by',
                select: 'last_name first_name'
            }, {
                path: '_created_by',
                select: 'last_name first_name'
            }, {
                path: '_plateforme',
                select: 'name'
            }, {
                path: 'assets.place_id',
                select: 'name'
            }, {
                path: 'assets.comments._user',
                select: 'login'
            }]
        };
        I3CompatibilitySrv.query({
            query: query,
            options: options
        }, 1, 0, function (data) {
            if (data.success) {
                $scope.compatibility = data.result[0];
                $scope.redv = $scope.getvalue($scope.compatibility.dead_line, $scope.compatibility.dead_line_period);
                $scope.test_table();
                compatibilityCheckSaved = angular.copy($scope.compatibility);
                constantSrv.getModel("COMPATIBILITY_STUDY").getAvailableStatusByStatus(compatibilityCheckSaved.status, function (err, val) {
                    $scope.compatibilityRequestStatus_available = val._availStatus.map(function (elem) {
                        return elem.name
                    })
                });
                $scope.current_asset = angular.copy(compatibilityCheckSaved.assets[0]);
                $scope.reset_asset = angular.copy(compatibilityCheckSaved.assets[0]);
            } else {
                console.log(data.error)
            }
        }, function (error) {
            if (error) {
                console.log(error);
                $state.go("app.home.compatibilityCheck")
            }
        });
    }();
    //check if currentvalue < 1 (we test only the asset with req_options >= 1 )
    $scope.equal0 = function (currentValue) {
        return currentValue < 1;
    };
    //test if all request options < 1 to show a message ("nothing to show") with the asset boolean (is0)
    $scope.test_table = function () {
        var arr = [];
        angular.forEach($scope.compatibility.assets, function (asset) {
            asset.new_status = asset.status;
            angular.forEach(asset.req_options, function (value, key) {
                arr.push(value);
            });
            if (arr.every($scope.equal0))
                asset.is0 = true;
            else
                asset.is0 = false;
            arr = [];
        });
    };
    //#endregion
    //#region assetslide and filters
    //return number of pages or 1
    /* $scope.numberOfPages = function () {
        if ($scope.fname_fstatus_asset)
            return Math.floor($scope.fname_fstatus_asset.length / $scope.pageSize);
        else return 1
    }; */
    //set currentPage to next
    $scope.nextpage = function () {
        $scope.selectedIndex = 0;
        $scope.currentPage = $scope.currentPage + 1;
    };
    //set currentPage to previous
    $scope.previouspage = function () {
        $scope.selectedIndex = 0;
        $scope.currentPage = $scope.currentPage - 1;
    };
    //reset current page and selectedIndex after search
    $scope.resetindex = function () {
        if ($scope.namev && $scope.namev.trim() != '') {
            $scope.currentPage = 0;
            $scope.selectedIndex = 0;
        }
    };
    //watch for asset results change for asset copy
    $scope.$watch('limited_fname_fstatus_asset[selectedIndex]', function (newVal, oldVal) {
        if ($scope.limited_fname_fstatus_asset && $scope.limited_fname_fstatus_asset[$scope.selectedIndex]) {
            //console.log(newVal);
            $scope.current_asset = angular.copy(newVal);
            $scope.reset_asset = angular.copy(newVal);
        }
    });
    //watch for asset results change for showing the progress bar
    $scope.$watch('fname_fstatus_asset.length', function (newVal, oldVal) {
        //$scope.loading = true;
        if ($scope.fname_fstatus_asset) {
            $scope.numberOfPages = Math.floor(((newVal - 1) / $scope.pageSize));
            console.log("TCL: $scope.numberOfPages", $scope.numberOfPages)
        } else {
            $scope.numberOfPages = 1;
        }
        /* $timeout(function () {}, 2000).then(function () {
            $scope.loading = false;
        }); */
    });
    //can't disable all asset status and reset current page and selectedIndex after availables status change
    $(":checkbox").click(function () {
        $scope.currentPage = 0;
        $scope.selectedIndex = 0;
        if ($("input:checked").length == 1) {
            $(':checked').prop('disabled', true);
        } else {
            $(':checked').prop('disabled', false);
        }
    });
    //filter asset by name
    $scope.name_filter = function (asset) {
        if ($scope.namev && $scope.namev.trim() != '')
            return (asset.name.toLowerCase().indexOf($scope.namev.toLowerCase()) > -1)
        else
            return (true)
    };
    //filter assets (with a searched term) by status
    $scope.filterStatus = function (item, index, array) {
        var status = "NEW";
        if (item.status) {
            status = item.status;
        }
        return ($scope.filterParams.NEW && $scope.fname_asset[index].status == "NEW" || $scope.filterParams.VALIDATED && $scope.fname_asset[index].status == "VALIDATED");
    };
    // get the length of filtered assets (with a searched term) by the status NEW
    $scope.quertstatus_new = function (item, index, array) {
        return $scope.fname_asset[index].status == "NEW"
    };
    // get the length of filtered assets (with a searched term) by the status VALIDATED
    $scope.quertstatus_vld = function (item, index, array) {
        return $scope.fname_asset[index].status == "VALIDATED"
    };
    $scope.quertstatus_vld_2 = function (item, index, array) {
        return $scope.limited_fname_fstatus_asset[index].status == "VALIDATED"
    };
    $scope.quertstatus_new_2 = function (item, index, array) {
        return $scope.limited_fname_fstatus_asset[index].status == "NEW"
    };
    //#endregion
    //#region update data
    //changed  state to unsaved
    $scope.change_state = function (asset) {
        asset.save_state = 'unsaved';
        $scope.current_asset.save_state = 'unsaved';
    }
    //reset asset
    $scope.cancel = function (asset) {
        var index = $scope.compatibility.assets.indexOf(asset);
        $scope.compatibility.assets[index] = angular.copy($scope.reset_asset);
        $scope.current_asset = angular.copy($scope.reset_asset);
        $scope.compatibility.assets[index].new_status = asset.status;
        $scope.content = "";
    };
    //verify CompatibilityCheck Status before update (ceck all assets are vaalidated if done)
    $scope.changeCompatibilityCheckStatus = function (status) {
        if ($scope.compatibilityRequestStatus_available.indexOf(status) != -1 && compatibilityCheckSaved.status != status) {
            if (status == "DONE") {
                if (compatibilityCheckSaved.assets && compatibilityCheckSaved.assets.length > 0) {
                    var isValidAssets = compatibilityCheckSaved.assets.map(function (asset) {
                        return asset.status == "VALIDATED";
                    });
                    if (isValidAssets.indexOf(false) != -1) {
                        Notification.error({
                            title: $translate.instant('UPDATE_ERROR'),
                            message: $translate.instant("NO_ASSETS_VALIDATED")
                        });
                    } else {
                        $scope.updateCompatibilityCheckStatus(status);
                    }
                }
            } else {
                $scope.updateCompatibilityCheckStatus(status);
            }
        } else { }
    };
    //update CompatibilityCheck Status with confirmation
    $scope.updateCompatibilityCheckStatus = function (status) {
        messageBox.confirm("UPDATE", "UPDATE_COMPATIBILITY_CHECK").then(function (res) {
            if (res) {
                $scope.compatibility.status = status
                compatibilityCheckSaved.status = status;
                delete compatibilityCheckSaved.__v;
                I3CompatibilitySrv.updateById(compatibilityCheckSaved._id, compatibilityCheckSaved, function (response) {
                    if (response.success) {
                        delete compatibilityCheckSaved.__v;
                        constantSrv.getModel("COMPATIBILITY_STUDY").getAvailableStatusByStatus(compatibilityCheckSaved.status, function (err, val) {
                            $scope.compatibilityRequestStatus_available = val._availStatus.map(function (elem) {
                                return elem.name
                            })
                        });
                        Notification.success({
                            title: $translate.instant("UPDATE_SUCC"),
                            message: $translate.instant("COMPATIBILITY_CHECK_UPDATED_SUC")
                        });
                    } else {
                        Notification.error({
                            title: $translate.instant('UPDATE_ERROR'),
                            message: $translate.instant("ERROR_OCCURED_UPDATE")
                        });
                    }
                }, function (err) {
                    var msg = "";
                    if (err.error.errorString) {
                        msg = err.error.errorString;
                    }
                    Notification.error({
                        title: $translate.instant('ERROR'),
                        message: msg
                    });
                })
            }
        }, function (reject) { })
    };
    //update CompatibilityCheck Status without confirmation
    $scope.updateCompatibilityCheckStatus_no_confirm = function (status) {
        $scope.compatibility.status = status
        compatibilityCheckSaved.status = status;
        delete compatibilityCheckSaved.__v;
        I3CompatibilitySrv.updateById(compatibilityCheckSaved._id, compatibilityCheckSaved, function (response) {
            if (response.success) {
                delete compatibilityCheckSaved.__v;
                constantSrv.getModel("COMPATIBILITY_STUDY").getAvailableStatusByStatus(compatibilityCheckSaved.status, function (err, val) {
                    $scope.compatibilityRequestStatus_available = val._availStatus.map(function (elem) {
                        return elem.name
                    })
                });
                Notification.success({
                    title: $translate.instant("UPDATE_SUCC"),
                    message: $translate.instant("STATUS_UPDATED_SUC")
                });
            } else {
                Notification.error({
                    title: $translate.instant('UPDATE_ERROR'),
                    message: $translate.instant("ERROR_OCCURED_UPDATE")
                });
            }
        }, function (err) {
            var msg = "";
            if (err.error.errorString) {
                msg = err.error.errorString;
            }
            Notification.error({
                title: $translate.instant('ERROR'),
                message: msg
            });
        })
    };
    //copy req options to res options
    $scope.copyreq = function (asset) {
        asset.res_options = angular.copy(asset.req_options);
        asset.save_state = "unsaved"
    };

    //update asset of Compatibility Check
    $scope.updateCompatibilityCheck = function (asset) {
        var index = $scope.compatibility.assets.indexOf(asset);
        asset.status = asset.new_status;
        if (asset.place_id) {
            asset.place_zone = asset.place_id.name;
        }
        compatibilityCheckSaved.assets[index] = angular.copy(asset);
        delete compatibilityCheckSaved.__v;

        I3CompatibilitySrv.updateById(compatibilityCheckSaved._id, compatibilityCheckSaved, function (response) {
            if (response.success) {
                asset.save_state = 'saved';
                $scope.current_asset = angular.copy(asset);
                if ($scope.compatibility.status == 'READY') {
                    $scope.updateCompatibilityCheckStatus_no_confirm('IN_PROGRESS');
                }
                Notification.success({
                    title: $translate.instant("UPDATE_SUCC"),
                    message: $translate.instant("ASSET_UPDATED_SUCC")
                });
            } else {
                Notification.error({
                    title: $translate.instant('UPDATE_ERROR'),
                    message: $translate.instant("ASSET_UPDATED_ERR")
                });
                $scope.compatibility = {};
            }
        }, function (err) {
            var msg = "";
            if (err.error.errorString) {
                msg = err.error.errorString;
            }
            Notification.error({
                title: $translate.instant('ERROR'),
                message: msg
            });
        })
    };

    //verify Compatibility_request Status before update ngclass
    $scope.isavailable = function (status) {
        if ($scope.compatibilityRequestStatus_available.indexOf(status) != -1 && compatibilityCheckSaved.status != status) {
            return true
        } else
            return false
    };

    //#endregion
    //#region utility
    //limit max and min of input[type="number"]
    $(document).ready(function () {
        $('input[type="number"]').on('keyup', function () {
            v = parseInt($(this).val());
            min = parseInt($(this).attr('min'));
            max = parseInt($(this).attr('max'));
            if (v > max) {
                $(this).val(max);
            }
        })
    });
    //#endregion
    //#region comments
    //toggle show comment
    $scope.show_com = function (x, y) {
        if (y > 0) {
            $scope.com[x] = !$scope.com[x];
        }
    };
    //add asset Comment
    $scope.addComment = function (content, asset) {
        if (content) {
            var commentAdded = {};
            commentAdded.date = new Date();
            commentAdded._user = $rootScope.SESSION.user._id;
            commentAdded.comment = content;
            commentAdded.new = true;
            asset.comments.push(commentAdded);
            $scope.content = "";
            asset.save_state = 'unsaved';
            $scope.current_asset.save_state = 'unsaved';
        }
    };
    //delete asset Comment
    $scope.deleteAssetComment = function (index, asset) {
        asset.comments.splice(index, 1);
        asset.save_state = 'unsaved';
        $scope.current_asset.save_state = 'unsaved';
    };
    //check if asset comment is new
    $scope.comp_d = function (comment) {
        if (comment.new) {
            return true
        } else return false
    };
    //#endregion
    //#region photos
    //show front photo
    $scope.afficher_front = function (x) {
        var modal = document.getElementById('myModal');
        var img = document.getElementById('imga_' + x);
        var modalImg = document.getElementById("img01");
        var captionText = document.getElementById("caption");
        var span = document.getElementsByClassName("close")[0];
        modal.style.display = "block";
        // modalImg.src = x.dataURL;
        modalImg.src = $scope.getImageUrl(x)
        captionText.innerHTML = $translate.instant("FRONT_PIC");
    };
    //show back photo
    $scope.afficher_back = function (x) {
        var modal = document.getElementById('myModal');
        var img = document.getElementById('imgb_' + x);
        var modalImg = document.getElementById("img01");
        var captionText = document.getElementById("caption");
        var span = document.getElementsByClassName("close")[0];
        modal.style.display = "block";
        // modalImg.src = x.dataURL;
        modalImg.src = $scope.getImageUrl(x);
        captionText.innerHTML = $translate.instant("BACK_PIC");
    };
    //close photo modal
    $scope.close = function () {
        var modal = document.getElementById('myModal');
        modal.style.display = "none";
    };
    //#endregion

    $scope.getImageUrl = function (path) {
        if (path) {
            console.log(path)
            if (path.dataURL) {
                path = path.dataURL
            }
            if (path.indexOf("data:image") >= 0) {
                return path;
            } else {
                return $rootScope.config.API_URI + "/document/registration_card/" + path + "?token=" + window.sessionStorage.token;
            }
        }
    }





    $scope.importOptions = {
        onUpdate: function (er, res) {
            console.log('res:', res)


            $scope.$apply(function () {
                if (res && res.assets && res.assets.length > 0) {
                    for (let index = 0; index < $scope.compatibility.assets.length; index++) {
                        const assetIN = $scope.compatibility.assets[index];

                        for (let j = 0; j < res.assets.length; j++) {
                            const asset = res.assets[j];
                            if (assetIN.name == asset.name) {
                                assetIN.res_options = asset.res_options
                                assetIN.new_status = asset.status
                                assetIN.status = asset.status

                            }
                        }

                    }
                }
                I3CompatibilitySrv.updateById($scope.compatibility._id, $scope.compatibility, function (response) {
                    if (response.success) {

                        Notification.success({
                            title: $translate.instant("UPDATE_SUCC"),
                            message: $translate.instant("ASSET_UPDATED_SUCC")
                        });
                    } else {
                        Notification.error({
                            title: $translate.instant('UPDATE_ERROR'),
                            message: $translate.instant("ASSET_UPDATED_ERR")
                        });

                    }
                }, function (err) {
                    var msg = "";
                    if (err.error.errorString) {
                        msg = err.error.errorString;
                    }
                    Notification.error({
                        title: $translate.instant('ERROR'),
                        message: msg
                    });
                })


            })
        }
    };

});