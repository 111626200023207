angular.module("app.directives").directive('myHeader', function ($rootScope,$state, i3HelperSrv, ngDialog) {
    return{
        templateUrl: "app/commons/directives/header/header.tpl.html",
        scope: {

        },
        link:  function (scope, elem, attr) {
            //Check if state is active
            scope.isActive = function (state) {
                return $state.current.name.startsWith(state);
            };


            //Sidebar toggled (false when sidebar opened)
            $rootScope.sidebarToggled = false;

            // Sidebar toggling
            scope.toggleSidebar  = function () {
                $rootScope.sidebarToggled = !$rootScope.sidebarToggled;
            };

            // Sign out user
            scope.logout=function() {
                i3HelperSrv.logout( function(){
                    $rootScope.appData={};
                    $rootScope.maplayers={};
                    $state.go("login", {});
                })
            };


            //Editing profile
            scope.profileEdit = function () {
                ngDialog.open({
                    template: 'app/commons/dialogs/profile/profile.dlg.tpl.html',
                    controller: 'profileDlgCtrl',
                    className: 'ngdialog-theme-default custom-width',
                    scope: scope
                })
            }


        }
    }
});