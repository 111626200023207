angular.module('app').filter('invoiceBySav', function() {
    return function (invoice,invoiceList) {


                var filteredInvoice=invoiceList;
                var invoiceId=invoice;
                if(invoice && invoice._id){
                    invoiceId=invoice._id;
                }
                if(filteredInvoice && filteredInvoice.length>0) {
                    filteredInvoice = filteredInvoice.filter(function (val) {
                        if (val._id == invoiceId) {
                            return true;
                        } else {
                            return false;
                        }
                    })
                   if (filteredInvoice.length > 0) {
                        if (filteredInvoice[0]) {
                            return filteredInvoice[0].name;
                        }
                    }
                }else{
                    return "" ;
                }

    };
})