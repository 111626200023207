angular.module('app.directives').directive('validFile', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
            element.bind('change', function () {
                scope.$apply(function () {
                    //console.log(element)
                    ngModel.$setViewValue(element.val());
                    ngModel.$render();
                })
            })
        }
    }
})