angular.module("app.directives").directive('importCompatibilityXlsx', function ($rootScope, $state, i3HelperSrv, ngDialog, $translate) {
    return {
        scope: {
            opts: '='
        },
        link: function ($scope, $elm) {

            $elm.on('change', function (changeEvent) {
                const PARSE_CONFIG = {
                    case_number: "B1",
                    client: "B2",
                    code_client: "D1",
                    firstRow: 5,
                    brand_Column_index: "A",
                    model_Column_index: "B",
                    plate_number_column_index: "C",
                    vin_column_index: "D",
                    tank_max_column_index: "E",
                    total_card_column_index: "F",
                    device_column_index: "G",
                    can_bus_column_index: "H",
                    direct_line_column_index: "I",
                    temp_probe_column_index: "J",
                    door_sensor_column_index: "K",
                    ibutton_reader_column_index: "L",
                    ibutton_tag_column_index: "M",
                    group_cold_sensor_column_index: "N",
                    immobilizer_12V_column_index: "O",
                    immobilizer_24V_column_index: "P",
                    sim_supplier_ngi_column_index: "Q",
                    place_zone_column_index: "R",
                    place_name_column_index: "S"

                };
                PARSE_CONFIG.ERRORS = {
                    INVALID_CASE_NUMBER: PARSE_CONFIG.case_number,
                    INVALID_CLIENT: PARSE_CONFIG.client,
                    INVALID_BRAND: PARSE_CONFIG.brand_Column_index,
                    INVALID_MODEL: PARSE_CONFIG.model_Column_index,
                    INVALID_PLATE_NUMBER: PARSE_CONFIG.plate_number_column_index,
                    INVALID_VIN_CODE: PARSE_CONFIG.vin_column_index,
                    INVALID_TANK_SIZE: PARSE_CONFIG.tank_max_column_index,
                    INVALID_PLACE_ZONE: PARSE_CONFIG.plate_number_column_index,
                    INVALID_DEVICE_COUNT: PARSE_CONFIG.device_column_index,
                    INVALID_CAN_BUS_OPTION: PARSE_CONFIG.can_bus_column_index,
                    INVALID_DIRECT_LINE_OPTION: PARSE_CONFIG.direct_line_column_index,
                    INVALID_TEMP_PROBE_OPTION: PARSE_CONFIG.temp_probe_column_index,
                    INVALID_DOOR_SENSOR_OPTION: PARSE_CONFIG.door_sensor_column_index,
                    INVALID_IBUTTON_READER_OPTION: PARSE_CONFIG.ibutton_reader_column_index,
                    INVALID_IBUTTON_TAG_OPTION: PARSE_CONFIG.ibutton_tag_column_index,
                    INVALID_GROUP_COLD_SENSOR_OPTION: PARSE_CONFIG.group_cold_sensor_column_index,
                    INVALID_IMMOBILIZER_12_OPTION: PARSE_CONFIG.immobilizer_12V_column_index,
                    INVALID_IMMOBILIZER_24_OPTION: PARSE_CONFIG.immobilizer_24V_column_index,
                    INVALID_SIM_SUPPLIER_OPTION: PARSE_CONFIG.sim_supplier_ngi_column_index,

                };

                var reader = new FileReader();

                reader.onload = function (e) {
                    /* read workbook */

                    var bstr = e.target.result;
                    var workbook = _XLSX.read(bstr, {
                        type: 'binary'
                    });
                    if (workbook && workbook.Sheets && Object.keys(workbook.Sheets).length > 0) {
                        var sheet = workbook.Sheets[Object.keys(workbook.Sheets)[0]];

                        var rowCount = parseInt(sheet["!ref"].split(":")[1].replace(/^\D+/g, ''));

                        var comparibilityRequest = {
                            errors: {
                                count: 0
                            },
                            assets: []
                        };
                        if (sheet[PARSE_CONFIG.case_number] && sheet[PARSE_CONFIG.case_number].w && sheet[PARSE_CONFIG.case_number].w.length > 0) {
                            comparibilityRequest.case_number = sheet[PARSE_CONFIG.case_number].w;
                        } else {
                            comparibilityRequest.case_number = "";
                            comparibilityRequest.errors.case_number = $translate.instant("INVALID_CASE_NUMBER", {
                                row: PARSE_CONFIG.case_number
                            });
                            comparibilityRequest.errors.count++;

                        }

                        if (sheet[PARSE_CONFIG.client] && sheet[PARSE_CONFIG.client].w && sheet[PARSE_CONFIG.client].w.length > 0) {
                            comparibilityRequest.client = sheet[PARSE_CONFIG.client].w;
                        } else {
                            comparibilityRequest.client = "";
                            comparibilityRequest.errors.client = $translate.instant("INVALID_CLIENT", {
                                row: PARSE_CONFIG.client
                            });
                            comparibilityRequest.errors.count++;

                        }
                        if (sheet[PARSE_CONFIG.code_client] && sheet[PARSE_CONFIG.code_client].w && sheet[PARSE_CONFIG.code_client].w.length > 0) {
                            comparibilityRequest.code_client = sheet[PARSE_CONFIG.code_client].w;
                        } else {
                            comparibilityRequest.code_client = "";
                        }
                        var rowNumber = PARSE_CONFIG.firstRow;
                        for (var i = PARSE_CONFIG.firstRow; i <= rowCount; i++) {

                            var isEmpty = true;
                            var asset = {
                                errors: {
                                    count: 0
                                },
                                req_options: {}
                            };
                            if (sheet[PARSE_CONFIG.brand_Column_index + i] && sheet[PARSE_CONFIG.brand_Column_index + i].w && sheet[PARSE_CONFIG.brand_Column_index + i].w.length > 0) {
                                isEmpty = false;
                                asset.brand = sheet[PARSE_CONFIG.brand_Column_index + i].w;
                            } else {
                                asset.brand = "";
                                asset.errors.count++;
                                asset.errors.brand = $translate.instant("INVALID_BRAND", {
                                    row: rowNumber,
                                    column: PARSE_CONFIG.brand_Column_index
                                });
                            }
                            if (sheet[PARSE_CONFIG.model_Column_index + i] && sheet[PARSE_CONFIG.model_Column_index + i].w && sheet[PARSE_CONFIG.model_Column_index + i].w.length > 0) {
                                isEmpty = false;
                                asset.model = sheet[PARSE_CONFIG.model_Column_index + i].w;
                            } else {
                                asset.model = "";
                                asset.errors.count++;
                                asset.errors.model = $translate.instant("INVALID_MODEL", {
                                    row: rowNumber,
                                    column: PARSE_CONFIG.model_Column_index
                                });
                            }
                            if (sheet[PARSE_CONFIG.plate_number_column_index + i] && sheet[PARSE_CONFIG.plate_number_column_index + i].w && sheet[PARSE_CONFIG.plate_number_column_index + i].w.length > 0) {
                                isEmpty = false;
                                asset.name = sheet[PARSE_CONFIG.plate_number_column_index + i].w;
                            } else {
                                asset.name = "";
                                asset.errors.count++;
                                asset.errors.name = $translate.instant("INVALID_PLATE_NUMBER", {
                                    row: rowNumber,
                                    column: PARSE_CONFIG.plate_number_column_index
                                });
                            }

                            if (sheet[PARSE_CONFIG.vin_column_index + i] && sheet[PARSE_CONFIG.vin_column_index + i].w && sheet[PARSE_CONFIG.vin_column_index + i].w.length > 0) {
                                isEmpty = false;
                                asset.vin = sheet[PARSE_CONFIG.vin_column_index + i].w;
                            } else {
                                asset.vin = "";
                                asset.errors.count++;
                                asset.errors.vin = $translate.instant("INVALID_VIN_CODE", {
                                    row: rowNumber,
                                    column: PARSE_CONFIG.vin_column_index
                                });
                            }

                            if (sheet[PARSE_CONFIG.tank_max_column_index + i] && sheet[PARSE_CONFIG.tank_max_column_index + i].v && sheet[PARSE_CONFIG.tank_max_column_index + i].v > 0) {
                                isEmpty = false;
                                asset.tank_max = sheet[PARSE_CONFIG.tank_max_column_index + i].v;
                            }
                            /* else
                            {
                                asset.tank_max=0;
                                asset.errors.count++;
                                asset.errors.tank_max=$translate.instant("INVALID_TANK_SIZE",{row:rowNumber,column:PARSE_CONFIG.tank_max_column_index}) ;
                            } */
                            if (sheet[PARSE_CONFIG.total_card_column_index + i] && sheet[PARSE_CONFIG.total_card_column_index + i].v > 0) {
                                isEmpty = false;
                                asset.total_card_number = sheet[PARSE_CONFIG.total_card_column_index + i].v;
                            } else {
                                asset.total_card_number = null;
                                // is not required
                            }
                            if (sheet[PARSE_CONFIG.place_zone_column_index + i] && sheet[PARSE_CONFIG.place_zone_column_index + i].w && sheet[PARSE_CONFIG.place_zone_column_index + i].w.length > 0) {
                                isEmpty = false;
                                asset.place_zone = sheet[PARSE_CONFIG.place_zone_column_index + i].w;
                            } else {
                                asset.place_zone = "";
                                asset.errors.count++;
                                asset.errors.place_zone = $translate.instant("INVALID_PLACE_ZONE", {
                                    row: rowNumber,
                                    column: PARSE_CONFIG.plate_number_column_index
                                });

                            }
                            if (sheet[PARSE_CONFIG.place_name_column_index + i] && sheet[PARSE_CONFIG.place_name_column_index + i].w && sheet[PARSE_CONFIG.place_name_column_index + i].w.length > 0) {
                                isEmpty = false;
                                asset.place_name = sheet[PARSE_CONFIG.place_name_column_index + i].w;
                            } else {
                                asset.place_name = null;
                                // is not required
                            }
                            if (sheet[PARSE_CONFIG.device_column_index + i] && sheet[PARSE_CONFIG.device_column_index + i].v > 0) {
                                isEmpty = false;
                                asset.req_options.device = sheet[PARSE_CONFIG.device_column_index + i].v;
                            } else {
                                asset.req_options.device = null;
                                asset.errors.count++;
                                asset.errors.device = $translate.instant("INVALID_DEVICE_COUNT", {
                                    row: rowNumber,
                                    column: PARSE_CONFIG.device_column_index
                                });
                            }
                            if (sheet[PARSE_CONFIG.can_bus_column_index + i] && sheet[PARSE_CONFIG.can_bus_column_index + i].v >= 0) {
                                isEmpty = false;
                                asset.req_options.canbus = sheet[PARSE_CONFIG.can_bus_column_index + i].v;
                            } else {
                                asset.req_options.canbus = null;
                                asset.errors.count++;
                                asset.errors.canbus = $translate.instant("INVALID_CAN_BUS_OPTION", {
                                    row: rowNumber,
                                    column: PARSE_CONFIG.can_bus_column_index
                                });
                            }
                            if (sheet[PARSE_CONFIG.direct_line_column_index + i] && sheet[PARSE_CONFIG.direct_line_column_index + i].v >= 0) {
                                isEmpty = false;
                                asset.req_options.direct_line = sheet[PARSE_CONFIG.direct_line_column_index + i].v;
                            } else {
                                asset.req_options.direct_line = null;
                                asset.errors.count++;
                                asset.errors.direct_line = $translate.instant("INVALID_DIRECT_LINE_OPTION", {
                                    row: rowNumber,
                                    column: PARSE_CONFIG.direct_line_column_index
                                });
                            }
                            if (sheet[PARSE_CONFIG.temp_probe_column_index + i] && sheet[PARSE_CONFIG.temp_probe_column_index + i].v >= 0) {
                                isEmpty = false;
                                asset.req_options.temp_probe = sheet[PARSE_CONFIG.temp_probe_column_index + i].v;
                            } else {
                                asset.req_options.temp_probe = null;
                                asset.errors.count++;
                                asset.errors.temp_probe = $translate.instant("INVALID_TEMP_PROBE_OPTION", {
                                    row: rowNumber,
                                    column: PARSE_CONFIG.temp_probe_column_index
                                });
                            }
                            if (sheet[PARSE_CONFIG.door_sensor_column_index + i] && sheet[PARSE_CONFIG.door_sensor_column_index + i].v >= 0) {
                                isEmpty = false;
                                asset.req_options.door_sensor = sheet[PARSE_CONFIG.door_sensor_column_index + i].v;
                            } else {
                                asset.req_options.door_sensor = null;
                                asset.errors.count++;
                                asset.errors.door_sensor = $translate.instant("INVALID_DOOR_SENSOR_OPTION", {
                                    row: rowNumber,
                                    column: PARSE_CONFIG.door_sensor_column_index
                                });
                            }
                            if (sheet[PARSE_CONFIG.ibutton_reader_column_index + i] && sheet[PARSE_CONFIG.ibutton_reader_column_index + i].v >= 0) {
                                isEmpty = false;
                                asset.req_options.ibutton_reader = sheet[PARSE_CONFIG.ibutton_reader_column_index + i].v;
                            } else {
                                asset.req_options.ibutton_reader = null;
                                asset.errors.count++;
                                asset.errors.ibutton_reader = $translate.instant("INVALID_IBUTTON_READER_OPTION", {
                                    row: rowNumber,
                                    column: PARSE_CONFIG.ibutton_reader_column_index
                                });
                            }
                            if (sheet[PARSE_CONFIG.ibutton_tag_column_index + i] && sheet[PARSE_CONFIG.ibutton_tag_column_index + i].v >= 0) {
                                isEmpty = false;
                                asset.req_options.ibutton_tag = sheet[PARSE_CONFIG.ibutton_tag_column_index + i].v;
                            } else {
                                asset.req_options.ibutton_tag = null;
                                asset.errors.count++;
                                asset.errors.ibutton_tag = $translate.instant("INVALID_IBUTTON_TAG_OPTION", {
                                    row: rowNumber,
                                    column: PARSE_CONFIG.ibutton_tag_column_index
                                });
                            }
                            if (sheet[PARSE_CONFIG.group_cold_sensor_column_index + i] && sheet[PARSE_CONFIG.group_cold_sensor_column_index + i].v >= 0) {
                                isEmpty = false;
                                asset.req_options.group_cold_sensor = sheet[PARSE_CONFIG.group_cold_sensor_column_index + i].v;
                            } else {
                                asset.req_options.group_cold_sensor = null;
                                asset.errors.count++;
                                asset.errors.group_cold_sensor = $translate.instant("INVALID_GROUP_COLD_SENSOR_OPTION", {
                                    row: rowNumber,
                                    column: PARSE_CONFIG.group_cold_sensor_column_index
                                });
                            }
                            if (sheet[PARSE_CONFIG.immobilizer_12V_column_index + i] && sheet[PARSE_CONFIG.immobilizer_12V_column_index + i].v >= 0) {
                                isEmpty = false;
                                asset.req_options.immobilizer_12V = sheet[PARSE_CONFIG.immobilizer_12V_column_index + i].v;
                            } else {
                                asset.req_options.immobilizer_12V = null;
                                asset.errors.count++;
                                asset.errors.immobilizer_12V = $translate.instant("INVALID_IMMOBILIZER_12_OPTION", {
                                    row: rowNumber,
                                    column: PARSE_CONFIG.immobilizer_12V_column_index
                                });
                            }
                            if (sheet[PARSE_CONFIG.immobilizer_24V_column_index + i] && sheet[PARSE_CONFIG.immobilizer_24V_column_index + i].v >= 0) {
                                isEmpty = false;
                                asset.req_options.immobilizer_24V = sheet[PARSE_CONFIG.immobilizer_24V_column_index + i].v;
                            } else {
                                asset.req_options.immobilizer_24V = null;
                                asset.errors.count++;
                                asset.errors.immobilizer_24V = $translate.instant("INVALID_IMMOBILIZER_24_OPTION", {
                                    row: rowNumber,
                                    column: PARSE_CONFIG.immobilizer_24V_column_index
                                });
                            }
                            if (sheet[PARSE_CONFIG.sim_supplier_ngi_column_index + i] && sheet[PARSE_CONFIG.sim_supplier_ngi_column_index + i].v >= 0 && sheet[PARSE_CONFIG.sim_supplier_ngi_column_index + i].v <= 1) {
                                isEmpty = false;
                                asset.req_options.sim_supplier_ngi = sheet[PARSE_CONFIG.sim_supplier_ngi_column_index + i].v;
                            } else {
                                asset.req_options.sim_supplier_ngi = null;
                                asset.errors.count++;
                                asset.errors.sim_supplier_ngi = $translate.instant("INVALID_SIM_SUPPLIER_OPTION", {
                                    row: rowNumber,
                                    column: PARSE_CONFIG.sim_supplier_ngi_column_index
                                });
                            }

                            if (!isEmpty) {
                                if (asset.errors.count > 0) {
                                    comparibilityRequest.errors.count += asset.errors.count;
                                }
                                asset.rowNumber = rowNumber;
                                comparibilityRequest.assets.push(asset);
                            }
                            rowNumber++;
                        }

                        if ($scope.opts && $scope.opts.onUpdate) {

                            $scope.opts.onUpdate(null, comparibilityRequest);
                        }
                    } else {
                        if ($scope.opts && $scope.opts.onUpdate) {
                            $scope.opts.onUpdate("INVALID_FILE ", null);
                        }
                    }

                };

                $scope.opts.fileName = changeEvent.target.files[0];
                reader.readAsBinaryString(changeEvent.target.files[0]);
            });
        }
    };
})