angular.module("app").controller("stockMovementCtrl", function ($scope, $rootScope, $filter, $state, $translate, ngDialog, Notification,I3StockMovementSrv, I3StockSrv, I3ProductSrv, I3ArticleSrv) {
    $scope.$state = $state;

    $scope.search = {};
    $scope.itemsByPage = 50;
    $scope.stockMovements =[];
    $scope.listarticles=[]



    I3ArticleSrv.query({query:{}, options:{populate:[{path:"_product",select:'name'}]}}, 10000, 1, function (res) {
        if (res.success) {
            $scope.listarticles = res.result
        console.log("$scope.listarticles",$scope.listarticles)
        }
    }, function (err) {
        console.log(err);
    });

   
    $scope.loadStockMovement = function (tableState, ctrl) {

        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }
        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;
        var options = {
            populate: [
                {
                    path: '_product',
                    select: 'name'
                },
                {
                    path: '_article',
                    select: 'serialNumber'
                },
                {
                    path: 'old_stock',
                    select: 'name'
                },
                {
                    path: 'new_stock',
                    select: 'name'
                },
            ],
            sortBy: {creation_dt: -1}
        };

        var query = {options: options};
        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
        }


        var requery = {};
       /* if(tableState.search && tableState.search.predicateObject){
            if(tableState.search.predicateObject.qty){
                requery.qty={$regex:"^"+tableState.search.predicateObject.qty, $options: 'i'}
            }
           
        }*/
        if ($scope.search) {
            if ($scope.search.old_stock) {
                if( $scope.search.old_stock._id=="ALL"){
                    requery["old_stock"] = $scope.search.old_stock._stock_id;
                }else {
                    requery["old_virtual_stock"] = $scope.search.old_stock._id;
                }


            }
            if ($scope.search.new_stock) {
                if( $scope.search.new_stock._id=="ALL"){
                    requery["new_stock"] = $scope.search.new_stock._stock_id;
                }else {
                    requery["new_virtual_stock"] = $scope.search.new_stock._id;
                }
                //requery["new_stock"] = $scope.search.new_stock._id;

            }
           
            if ($scope.search._product) {
                requery["_product"] = $scope.search._product._id;

            }
            if ($scope.search.qty) {
                requery["qty"] =  parseInt($scope.search.qty,10) ;
                    
            }
            if ($scope.search._article) {
                requery["_article"] = $scope.search._article;
                    
            }
        }


        query.query = requery;

        I3StockMovementSrv.query(query, number, start,
            function (data) {

                if (data.success) {
                    $scope.stockMovements = data.result;
                    console.log("$scope.stockMovements",$scope.stockMovements)
                    $scope.stockMovementCount = data.total_count;
                    tableState.pagination.numberOfPages = Math.ceil(data.total_count / number);
                    tableState.pagination.totalItemCount = data.total_count;
                    $scope.tableState = tableState;
                } else {
                    console.error(data.error)
                }
            },
            function (err) {
                console.log("err", err)
            })


    };
    I3StockSrv.query({}, 10000, 1, function (res) {
        if (res.success) {
            $scope.ALLStocks = res.result;
            $scope.virtual_stocks = [];
            $scope.ALLStocks.forEach(function (val) {
                val.virtual_stock.splice(0,0,{
                    _id:"ALL",
                    _stock : val.name,
                    _stock_id : val._id,
                    name:$translate.instant("ALL")
                })
                $scope.virtual_stocks = $scope.virtual_stocks.concat(val.virtual_stock.map(function (stock) {
                    stock._stock = val.name;
                    return stock
                }));
                $scope.virtual_stocks.splice()
            })

         
        }
    }, function (err) {
        console.log(err);
    });
    $scope.getVirtualStokById=function(virtualStockId){
        if(virtualStockId){
            var filtred = $scope.virtual_stocks.filter(function(val){
                return  val._id == virtualStockId;
            })
            if(filtred && filtred.length>0){
                return  filtred[0];
            }else{
                return undefined;
            }
        }else {
            return undefined;
        }
    }

    $scope.addStockMvt=function(){
             ngDialog.open({
                templateUrl: "app/commons/dialogs/stocks/stocks.move.dlg.html",
                controller: "moveStocksDlgCtrl",
                className: "custom-width ngdialog-theme-default",
                scope: $scope
            })


    }

})