angular.module("app").controller("receptionDetailsCtrl", function ($scope, $stateParams, $rootScope, $q, $filter, $state, $translate, ngDialog, Notification, I3ArticleSrv, I3StockSrv, I3ProductSrv, I3ReceiptSlipSrv) {
    $scope.new_articles = [];
    $scope.products = [];
    $scope.grouped_articles = {};
    $scope.articles_ids = [];
    $scope.old_articles = [];
    $scope.receipt = {}; 
    I3ReceiptSlipSrv.query({
        query: {
            _id: $stateParams.receptionId
        },
        options: {
            populate: [{
                    path: 'products._product',
                    select: 'name'
                },
                {
                    path: '_stock',
                    select: 'name'
                }, {
                    path: "_product",
                    model: "product"
                }, {
                    path: "_stock",
                    model: "stock"
                }
            ]
        }
    }, 10000, 1, function (res) {
        if (res.success) {
            $scope.receipt = res.result[0];
            $scope.get_articles();
        }
    }, function (err) {
        console.log(err);
    });
    I3ProductSrv.query({}, 10000, 1, function (res) {
        if (res.success) {
            $scope.all_products = res.result.filter(function (val) {
                return val.type == "MATERIAL"
            });
            // console.log("TCL: $scope.all_products", $scope.all_products)
            $scope.unused_products = angular.copy($scope.all_products);
        }
    }, function (err) {
        console.log(err);
    });
    /**
     * Cancel creating/editing stockManagement
     */
    $scope.cancel = function () {
        $state.go('app.stocks.reception');
    };
    $scope.get_articles = function () {
        I3ArticleSrv.query({
            query: {
                "_receiptSlip": $scope.receipt.ref
            },
            options: {
                populate: [{
                    path: "_product",
                    select: 'name'
                }]
            }
        }, 10000, 1, function (res) {
            if (res.success) {
                $scope.new_articles = res.result
                $scope.grouped_articles = res.result.reduce(function (r, a) {
                    r[a._product._id] = [].concat((r[a._product._id] || []), [a]);
                    return r;
                }, {});
                $scope.articles_ids = res.result.reduce(function (r, a) {
                    if (r.indexOf(a._product._id) === -1) {
                        r.push(a._product._id);
                    }

                    return r;
                }, []);
                console.log("group", $scope.grouped_articles);
                console.log("ids", $scope.articles_ids);
                console.log("$scope.new_articles", $scope.new_articles)
            }
        });
    };

    $scope.get_product_name = function (id) {
        if ($scope.grouped_articles[id].length > 0) {
            return $scope.grouped_articles[id][0]._product.name;
        }

    }
    $scope.updateVirtualStock = function (stok, virtualStock) {
        console.log(stok, virtualStock)
        stok = $scope.receipt._stock
        virtualStock = $scope.receipt.virtual_stock
        if (stok && stok.virtual_stock && stok.virtual_stock.length > 0) {
            var filtred = stok.virtual_stock.filter(function (val) {
                return val._id == virtualStock;
            })
            if (!filtred || filtred.length <= 0) {
                $scope.receipt.virtual_stock = null;
            }
        } else if (stok) {
            $scope.receipt.virtual_stock = null;
        }
    }
    
})