angular.module('app').controller('homeCtrl', function ($rootScope, $scope, $state,I3PlateformeSrv,I3ProductSrv,I3ZoneSrv,constantSrv) {
    $scope.$state = $state;
    $scope._HOME = {};
    $scope._HOME.subscriptionTypes = constantSrv.getSubscriptionTypes();
    $scope._HOME.fileTypes = constantSrv.getFileTypes();
    $scope._HOME.pvTypes = ['INSTALLATION','INTERVENTION'];
    $scope._HOME.productConsomable = []
    I3PlateformeSrv.query({}, 10000, 1,
        function (data) {
            if (data.success) {
                $scope._HOME.platforms = data.result;
            } else {
                console.error(data.error)
            }
        }, function (data) {
            console.dir(data)

        })
    I3ZoneSrv.query({}, 10000, 1, function (response) {
        $scope._HOME.zones = response.result;
    }, function (err) {
        console.log(err);
    });
    I3ProductSrv.query({}, 10000, 1, function (response) {
        $scope._HOME.products = response.result;
    }, function (err) {
        console.log(err);
    });
    $scope._HOME.productTypes = ["MATERIAL", "SERVICE", "OPERATION","UNSUBSCRIBE"]
    $scope._HOME.load_filtred_products = function (term) {
        var req = {
            query: {
                name: {
                    $regex: term,
                    $options: "i"
                }
            }
        };

    }
    $scope._HOME.load_filtred_zones = function (term) {
        var req = {
            query: {
                name: {
                    $regex: term,
                    $options: "i"
                }
            }
        };
        I3ZoneSrv.query(req, 10, 1, function (data) {
            if (data.success) {
                $scope._HOME.filtered_zones = data.result;
            } else {
                console.error(response.error)
            }
        });
    }
    I3ProductSrv.query({ query: {
        type: {
            $regex: "MATERIAL",
            $options: "i"
        }
     
       
    },     options: {
        populate: [{
                path: 'code',

            }
          
        ]
    }}, 10000, 1, function (response) {
    
       response.result.forEach(function(value) {
            if(value.code && value.code.product_type && value.code.product_type == "OTHER_CONSOMMABLE"){
                $scope._HOME.productConsomable.push(value)
            }
    
        })
    
    }, function (err) {
        console.log(err);
    });
 
});