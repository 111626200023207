angular.module("app.directives").directive('importProductXlsx', function ($rootScope, $state, i3HelperSrv, ngDialog, $translate, blockUI) {
    return {
        scope: {
            opts: '=',
            // index: '='
        },
        link: function ($scope, $elm) {
            $scope.opts.fileName = null;
            $elm.on('change', function (changeEvent) {
                const PARSE_CONFIG = {
                    firstRow: 3,
                    serial_number_Column_index: "A",
                    imei_number_Column_index: "B",
                    sim_number_column_index: "C",
                    designation_column_index: "D",
                    status_column_index: "E",
                    category_column_index: "F",
                    model_column_index: "G",
                    brand_column_index: "H"
                };
                // console.log($scope.index);
                PARSE_CONFIG.ERRORS = {
                    INVALID_SERIAL_NUMBER: PARSE_CONFIG.serial_number_Column_index,
                    DUPLICATE_SERIAL_NUMBER: PARSE_CONFIG.serial_number_Column_index,
                    DUPLICATE_IEMI_NUMBER: PARSE_CONFIG.imei_number_Column_index
                };
                var reader = new FileReader();
                reader.onload = function (e) {
                    /* read workbook */
                    var bstr = e.target.result;
                    var workbook = _XLSX.read(bstr, {
                        type: 'binary'
                    });
                    if (workbook && workbook.Sheets && Object.keys(workbook.Sheets).length > 0) {
                        var sheet = workbook.Sheets[Object.keys(workbook.Sheets)[0]];
                        var rowCount = parseInt(sheet["!ref"].split(":")[1].replace(/^\D+/g, ''));
                        var temp_articles = {
                            errors: {
                                count: 0,
                                rows: [],
                                duplicate_rows: [],
                                duplicate_imeis_rows: []
                            },
                            serials: [],
                            imeis: [],
                            articles: []
                        };
                        var rowNumber = PARSE_CONFIG.firstRow;
                        for (var i = PARSE_CONFIG.firstRow; i <= rowCount; i++) {
                            var isEmpty = true;
                            var article = {
                                errors: {
                                    count: 0
                                }
                            };
                            if (sheet[PARSE_CONFIG.serial_number_Column_index + i] && sheet[PARSE_CONFIG.serial_number_Column_index + i].w && sheet[PARSE_CONFIG.serial_number_Column_index + i].w.length > 0) {
                                isEmpty = false;
                                article.serialNumber = sheet[PARSE_CONFIG.serial_number_Column_index + i].w;
                                temp_articles.serials.push(article.serialNumber);
                                if ($scope.hasDuplicates(temp_articles.serials)) {
                                    temp_articles.errors.duplicate_rows.push(i);
                                    article.errors.count++;
                                    article.errors.duplicateserialNumber = $translate.instant("DUPLICATE_SERIAL_NUMBER", {
                                        row: rowNumber,
                                        column: PARSE_CONFIG.serial_number_Column_index
                                    });
                                }
                            } else {
                                article.serialNumber = null;
                                article.errors.count++;
                                temp_articles.errors.rows.push(i);
                                article.errors.serialNumber = $translate.instant("INVALID_SERIAL_NUMBER", {
                                    row: rowNumber,
                                    column: PARSE_CONFIG.serial_number_Column_index
                                });
                            }
                            if (sheet[PARSE_CONFIG.imei_number_Column_index + i] && sheet[PARSE_CONFIG.imei_number_Column_index + i].w && sheet[PARSE_CONFIG.imei_number_Column_index + i].w.length > 0) {
                                isEmpty = false;
                                article.imei = sheet[PARSE_CONFIG.imei_number_Column_index + i].w;
                                temp_articles.imeis.push(article.imei);
                                if ($scope.hasDuplicates(temp_articles.imeis)) {
                                    temp_articles.errors.duplicate_imeis_rows.push(i);
                                    article.errors.duplicateimeiNumber = $translate.instant("DUPLICATE_IEMI_NUMBER", {
                                        row: rowNumber,
                                        column: PARSE_CONFIG.imei_number_Column_index
                                    });
                                }
                            } else {
                                article.imei = null;
                            }
                            if (sheet[PARSE_CONFIG.sim_number_column_index + i] && sheet[PARSE_CONFIG.sim_number_column_index + i].w && sheet[PARSE_CONFIG.sim_number_column_index + i].w.length > 0) {
                                isEmpty = false;
                                article.simNumber = sheet[PARSE_CONFIG.sim_number_column_index + i].w;
                            } else {
                                article.simNumber = null;
                            }
                            if (sheet[PARSE_CONFIG.designation_column_index + i] && sheet[PARSE_CONFIG.designation_column_index + i].v && sheet[PARSE_CONFIG.designation_column_index + i].v.length > 0) {
                                isEmpty = false;
                                article.designation = sheet[PARSE_CONFIG.designation_column_index + i].v;
                            } else {
                                article.designation = null;
                                // is not required
                            }
                            if (sheet[PARSE_CONFIG.status_column_index + i] && sheet[PARSE_CONFIG.status_column_index + i].v && sheet[PARSE_CONFIG.status_column_index + i].v.length > 0) {
                                isEmpty = false;
                                article.status = sheet[PARSE_CONFIG.status_column_index + i].v;
                            } else {
                                article.status = null;
                                // is not required
                            }
                            if (sheet[PARSE_CONFIG.category_column_index + i] && sheet[PARSE_CONFIG.category_column_index + i].v && sheet[PARSE_CONFIG.category_column_index + i].v.length > 0) {
                                isEmpty = false;
                                article.category = sheet[PARSE_CONFIG.category_column_index + i].v;
                            } else {
                                article.category = null;
                                // is not required
                            }
                            if (sheet[PARSE_CONFIG.model_column_index + i] && sheet[PARSE_CONFIG.model_column_index + i].v && sheet[PARSE_CONFIG.model_column_index + i].v.length > 0) {
                                isEmpty = false;
                                article.model = sheet[PARSE_CONFIG.model_column_index + i].v;
                            } else {
                                article.model = null;
                                // is not required
                            }
                            if (sheet[PARSE_CONFIG.brand_column_index + i] && sheet[PARSE_CONFIG.brand_column_index + i].v && sheet[PARSE_CONFIG.brand_column_index + i].v.length > 0) {
                                isEmpty = false;
                                article.brand = sheet[PARSE_CONFIG.brand_column_index + i].v;
                            } else {
                                article.brand = null;
                                // is not required
                            }
                            if (!isEmpty) {
                                if (article.errors.count > 0) {
                                    temp_articles.errors.count += article.errors.count;

                                }
                                article.rowNumber = rowNumber;
                                temp_articles.articles.push(article);
                            }
                            rowNumber++;
                        }
                        if ($scope.opts && $scope.opts.onUpdate) {
                            $scope.opts.onUpdate(null, temp_articles);
                        }
                    } else {
                        if ($scope.opts && $scope.opts.onUpdate) {
                            $scope.opts.onUpdate("INVALID_FILE ", null);
                        }
                    }
                };
                $scope.hasDuplicates = function (array) {
                    var valuesSoFar = Object.create(null);
                    for (var i = 0; i < array.length; ++i) {
                        var value = array[i];
                        if (value in valuesSoFar) {
                            return true;
                        }
                        valuesSoFar[value] = true;
                    }
                    return false;
                }
                // console.log("ssss", $scope.hasDuplicates([5, 5]))
                $scope.opts.fileName = changeEvent.target.files[0];
                reader.readAsBinaryString(changeEvent.target.files[0]);
            });
        }
    };
})