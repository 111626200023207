angular.module('app').controller("confirmDeletedeliverySlipDlgCtrl", function ($scope, $state,$translate, I3deliverySlipSrv, Notification) {

    $scope.confirm = function () {
        I3deliverySlipSrv.delete($scope.deliverySlip._id, function (response) {
            if(response.success){
                $scope.loadDeliverySlip()
                $scope.tableState.pagination.start = 0;

                Notification.success({
                    title: $translate.instant("DELIVERY_SLIP_DELETED_TITLE"),
                    message: $translate.instant("ALERT_DELIVERY_SLIP_DELETED"),

                });
                $scope.deliverySlip = {};
            }else{
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("DELIVERY_SLIP_DELETED_ERROR")
                });
                $scope.deliverySlip = {};
            }
        }, function (err) {
            console.log(err);

            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("DELIVERY_SLIP_DELETED_ERROR")
            });

            $scope.deliverySlip = {};
        });

        $scope.deliverySlip = {};
        $scope.closeThisDialog();
    }
});