
angular.module('app').controller('plateformeSynchroneCtrl', function ($scope, $rootScope, $state, $translate, $filter, Notification, ngDialog,I3PlateformeSrv,invoiceSubsidiarySrv) {


    invoiceSubsidiarySrv.query({}, 1000, 0, function (res) {
        if (res && res.result) {
           $scope.invoiceSubsidiary=res.result
           
    
        }
    })
    
$scope.loadPlatformes=function(){

    var start = 0;
    var number = 100;
    var options = {
        populate: [
            
           
         ],
         sortBy: {creation_dt: -1}
     };
     var query = {options: options};
    //var requery = {external_id:{ $exists: true }};
//query.query = requery;

I3PlateformeSrv.query(query, number, start, function (res) {
    // console.log(res)
        if (res && res.result) {
           $scope.plateformes=res.result;
    
    
        }
    })



}


    $scope.synchronePlateforme=function(){
 
        ngDialog.open({
            templateUrl: "app/commons/dialogs/invoiceSynchrone/synchronePlateforme.dlg.tpl.html",
            controller: "synchronePlateformeDlgCtrl",
            className: 'ngdialog-theme-default custom-width-500',
            appendClassName: 'ngdialog-custom',
            scope: $scope
        })
    }
    $scope.cancel = function () {

        $state.go('app.admin.invoiceConnector');
    };
})

