/**
 * Created by Kais CHAIBI on 05/04/2017.
 */

angular.module("app.directives").directive('reportViewer', function ($rootScope, $window, i3HelperSrv, $q,$translate) {
    return {
        restrict: 'AE',
        scope: {
            options: "="
        },
        link: function (scope, element, attrs) {



            scope.init = function () {

                //console.log("init")

                var options = new Stimulsoft.Viewer.StiViewerOptions;
                options.toolbar.zoom = Stimulsoft.Viewer.StiZoomMode.PageWidth;
                options.toolbar.showSendEmailButton = false;
                options.toolbar.showSendEmailButton = false;
                options.toolbar.showFullScreenButton = false
                options.toolbar.showZoomButton = false;
                options.toolbar.showParametersButton = false;
                options.toolbar.showAboutButton = false;
                options.toolbar.showBookmarksButton = false;










                options.appearance.openLinksTarget = "_blank";




                scope.viewer = new $window.Stimulsoft.Viewer.StiViewer(options, 'StiViewer', false);
                
                 if($translate.use()=="en"){
                    Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile("app/assets/utils/stimulsoft_flat/en.xml");
                }else{
                    Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile("app/assets/utils/stimulsoft_flat/fr.xml");
                }

                
                //console.log(scope.viewer );
                scope.report = new $window.Stimulsoft.Report.StiReport();
                








                scope.renderReport = function (tpl, dataSource,fileName) {

                    scope.report.load(tpl);
                    if (dataSource) {
                        scope.report.dictionary.databases.clear();

                        scope.report.regData(dataSource.name, dataSource.name, dataSource.data);
                        


                    }

                    setTimeout(function(){
                        if(fileName){
                        scope.report.reportName =fileName;
                         scope.report.ReportAlias  = fileName;
                        }
                        scope.viewer.report = scope.report;
                        
                        scope.viewer.renderHtml(element[0]);
                    })







                };



                scope.$on('$destroy', function () {
                    //console.log("$destroy")
                    listner();
                    /* if(cleanup!=undefined){
                     cleanup()
                     };*/
                });
            };


            if (typeof Stimulsoft !== 'undefined') {
                scope.init();

            } else {


                if ($rootScope.loadScriptPromise) {
                    $rootScope.loadScriptPromise.then(function (res) {
                        scope.init();
                        if (scope.pendingRender) {
                            scope.pendingRender.resolve();
                        }
                    })

                } else {
                    zip.installJS("app/assets/utils/stimulsoft_flat/cdn.zip", ["stimulsoft.reports.js", "stimulsoft.viewer.js"], function () {

                        //console.log("script loaded ");
                        scope.init();
                        if (scope.pendingRender) {
                            scope.pendingRender.resolve();
                        }

                    });
                }
            }
            var listner = $rootScope.$on("report:render", function (evt, args) {

                scope.pendingRender = $q.defer();
                scope.pendingRender.promise.then(function () {


                  //  console.log(args.ds.data.fileName)

                    if (args) {
                        var tpl = args.tpl;
                        var ds = args.ds;
                        var fileName=args.ds.data.fileName;
                        delete args.ds.data.fileName;

                        scope.renderReport(tpl, ds,fileName);
                    }


                });

                if (typeof Stimulsoft !== 'undefined') {
                    scope.pendingRender.resolve();
                }
            })
        }

    }
});