
angular.module("app.services").service('statusBox', ['$q', 'ngDialog', function ($q, ngDialog) {

    var service = {};

    service.confirm = function (title, products) {
        products.forEach(function(val){
            if(val){
    val.unavailable = val.total - val.available
            }


        })
        var defer = $q.defer();

        ngDialog.open({
            template: 'app/commons/dialogs/confirm/stock.status.dlg.tpl.html',
            controller: ["$scope", function ($scope) {
                $scope.title = title;
                $scope.products = products;
                $scope.confirm = function () {
                    $scope.closeThisDialog();
                    defer.resolve(true);
                }
               
            }],
            className: "custom-width ngdialog-theme-default",
            width: '900px',
           

        })

        return defer.promise;

    }


    return service;
}])