angular.module('app.dialogs').controller('confirmDeleteProductDlgCtrl', function ($scope, $rootScope, ngDialog, I3ProductSrv, Notification, $translate) {

    $scope.confirm = function () {
        I3ProductSrv.delete($scope.product._id, function (response) {

            if(response.success){
                //$scope.products.splice($scope.products.indexOf($scope.product), 1);
               
                Notification.success({
                    title: $translate.instant("PRODUCT_DELETED_TITLE"),
                    message: $translate.instant("PRODUCT_DELETED_SUC")
                });
                $scope.loadProducts()
                $scope.product = {};
            }else{
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("PRODUCT_DELETED_ERROR")
                });
                $scope.product = {};
            }
        }, function (err) {
            console.log(err);

            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("PRODUCT_DELETED_ERROR")
            });

            $scope.product = {};
        });

        $scope.product = {};
        $scope.closeThisDialog();
    }

});