angular.module('app').controller("returnSlipStockCtrl", function ($scope, I3StockSrv, Notification, index) {


    $scope.stock = angular.copy($scope.deliverySlips.products[index]);
    var stockInitial = $scope.deliverySlips.products[index].stock
    var vStockInitial = $scope.deliverySlips.products[index].virtual_stock

    if ($scope.deliverySlips.products[index]._article && $scope.deliverySlips.products[index]._article != null && $scope.deliverySlips.products[index]._article.serialNumber != "") {
        $scope.qty = 1


    } else {
        $scope.qty = ""
    }
    I3StockSrv.query({}, 10000, 1, function (res) {
        if (res.success) {
            $scope.ListStocks = res.result;
            $scope.virtual_stocks = [];
            $scope.ListStocks.forEach(function (val) {
                $scope.virtual_stocks = $scope.virtual_stocks.concat(val.virtual_stock.map(function (stock) {
                    stock._stock = val.name;
                    return stock
                }));
            })

        }
    }, function (err) {
        console.log(err);
    });

    $scope.getVirtualStokById = function (virtualStockId) {
        if (virtualStockId) {
            var filtred = $scope.virtual_stocks.filter(function (val) {
                return val._id == virtualStockId;
            })
            if (filtred && filtred.length > 0) {
                return filtred[0];
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    }


    $scope.addreturnSlipStock = function () {
        var qtyInt = parseInt($scope.deliverySlips.products[index].qty)
        var qtyOut = parseInt($scope.qty)
        var differenceQty = qtyInt - qtyOut;
        $scope.deliverySlips.products[index].qty = differenceQty;
        $scope.deliverySlips.products = $scope.deliverySlips.products.filter(function (prod) {
            return prod.qty > 0;
        });
        var stock = {
            _product: $scope.stock._product,
            _stock: $scope.stock._stock,
            virtual_stock: $scope.stock.virtual_stock,
            _id: $scope.stock._id,
            _article: $scope.stock._article || null,
            qty: qtyOut
        }
        if (!$scope.goodsReturnSlip.products_return) $scope.goodsReturnSlip.products_return = [];
        if (stock._article) {
            $scope.goodsReturnSlip.products_return.push(stock);
            $scope.closeThisDialog($scope.goodsReturnSlip)
        } else {

            var filterStock = $scope.goodsReturnSlip.products_return.filter(function (elem) {
                var isSame = true;
                if (elem.virtual_stock && stock.virtual_stock) {
                    isSame = (elem.virtual_stock._id ? elem.virtual_stock._id : elem.virtual_stock) == (stock.virtual_stock._id ? stock.virtual_stock._id : stock.virtual_stock);
                } else {
                    isSame=false;
                    return false;
                }
                if (isSame && elem._stock && stock._stock) {
                    isSame = (elem._stock._id ? elem._stock._id : elem._stock) == (stock._stock._id ? stock._stock._id : stock._stock);
                } else {
                    isSame=false;
                    return false;
                }
                if (isSame && elem._product && stock._product) {
                    isSame = (elem._product._id ? elem._product._id : elem._product) == (stock._product._id ? stock._product._id : stock._product);
                } else {
                    isSame=false;
                    return false;
                }
                console.log("isSame",isSame);
                return isSame;


            })
            if (filterStock.length > 0) {
                filterStock[0].qty = stock.qty + filterStock[0].qty;
                stock = filterStock[0]
                $scope.closeThisDialog($scope.goodsReturnSlip)
            } else {
                $scope.goodsReturnSlip.products_return.push(stock)
                $scope.closeThisDialog($scope.goodsReturnSlip)
            }

        }


    }
})