angular.module('app.directives').directive('uDatetime', function ($rootScope, $cookieStore) {
    return {
        require: '?^stTable',
        templateUrl : "app/commons/directives/uDatetimePicker/uDatetimePicker.tpl.html",
        scope: {
            options : "="
        },
        link : function (scope, elem, attr, table) {
            if(table){
                if(scope.options.stTable && scope.options.predicateName){
                    scope.$watch("selectedDate", function () {
                        table.search(scope.selectedDate, scope.options.predicateName);
                    });
                }
            }

            scope.calShown = false;
            scope.style = "";
            scope.trans = {
                en : "Choose a date",
                fr : "Choisir une date"
            };
            scope.placeholder = scope.trans[$cookieStore.get('lang')];
            if(scope.options && scope.options.init){
                scope.date = scope.options.init.clone();
                scope.selectedDate = scope.options.init.clone().format(scope.options.format);
            }else{
                scope.date = moment().clone();
                scope.selectedDate = null;
            }

            if(scope.options && !scope.options.format){
                scope.options.format = "DD/MM/YYYY HH:mm";
            }



            if(scope.options && scope.options.defaultView){
                scope.view = scope.options.defaultView;
            }else{
                scope.view = "DATE";
            }


            var buildCal = function () {
                var startOfMonth = scope.date.clone().startOf("month");
                var startOfMonthWeek = scope.date.clone().startOf("month").startOf("week");
                var endOfMonth = scope.date.clone().endOf("month");
                var endOfMonthWeek = scope.date.clone().endOf("month").endOf("week");

                var shownDaysCount = Math.abs(startOfMonthWeek.diff(endOfMonthWeek, "days"));
                var shownWeeksCount = Math.round(Math.abs(startOfMonthWeek.diff(endOfMonthWeek, "days")) / 7);
                var daysInMonth = scope.date.daysInMonth();


                scope.calendar = [];
                var curr = startOfMonthWeek;

                for(var i= 0; i< shownWeeksCount; i++){
                    var week = {
                        id : i
                    };
                    week.days = [];
                    for(var j=0; j < 7; j++){
                        var day = {
                            date : curr.clone(),
                            num : curr.format("DD"),
                            selected : isSelected(curr.clone().startOf('day')),
                            today: isToday(curr.clone().startOf('day')),
                            currentMonth: isCurrentMonth(curr.clone().startOf('day'))
                        };

                        if(scope.options && scope.options.minDate){
                            if(scope.options.minDate.clone().startOf('day').diff(curr.clone().startOf('day'), 'days') > 0){
                                day.disabled  = true
                            }
                        }

                        if(scope.options && scope.options.maxDate){
                            if(scope.options.maxDate.clone().endOf('day').diff(curr.clone().endOf('day'), 'days') < 0){
                                day.disabled  = true
                            }
                        }


                        week.days.push(day);
                        curr.add(1, 'days');
                    }
                    scope.calendar.push(week)
                }
            };

            $(window).on('click', function (e) {
                buildCal();
                scope.calShown = $(elem).has($(e.target)).length > 0;
                scope.$apply();
            });


            if(scope.options && scope.options.position){
                var pos = scope.options.position.split(" ");

                angular.forEach(pos, function (p) {
                    if(p === 'top'){
                        scope.style += p+": 38px;";
                    }else if(p === 'xtop'){
                        scope.style += 'top' +": -180px;";
                    }else{
                        scope.style += p+": 0;";
                    }
                });
            }else{
                scope.style = "top: 38px; left: 0;"
            }

            if(scope.options && scope.options.style){
                scope.style +=  scope.options.style;
            }





            if(scope.selectedDate !== null && (scope.options.format === "DD/MM/YYYY HH:mm" || scope.options.format === "HH:mm")){
                scope.minutes = scope.selectedDate.substr(-2);
                scope.hours = scope.selectedDate.slice(scope.selectedDate.length - 5, scope.selectedDate.length - 3)
            }


            scope.weekDays = moment.weekdaysShort(true);
            scope.months = moment.monthsShort();


            var isToday = function (date) {
                return moment().clone().startOf('day').diff(date, 'days') === 0;
            };
            var isSelected = function (date) {
                return scope.date.clone().startOf('day').diff(date, 'days') === 0;
            };

            var isCurrentMonth = function (date) {
                return scope.date.get("month") === date.get("month");
            };



            buildCal();


            scope.inputDate = function () {
                switch (scope.options.format){
                    case "DD/MM/YYYY":
                        if(scope.selectedDate.length === 2 || scope.selectedDate.length === 5){
                            scope.selectedDate = scope.selectedDate + "/";
                        }
                        break;
                    case "DD/MM/YYYY HH:mm":
                        if(scope.selectedDate.length === 2 || scope.selectedDate.length === 5){
                            if(scope.selectedDate.length === 2){
                                if(parseInt(scope.selectedDate) > scope.date.daysInMonth()){
                                    scope.selectedDate =  scope.date.daysInMonth()+"";
                                }
                            }
                            if(scope.selectedDate.length === 5){
                                if(parseInt(scope.selectedDate.substr(-2)) > 12){
                                    scope.selectedDate = scope.selectedDate.slice(0, scope.selectedDate.length - 2);
                                    scope.selectedDate +=  "12";
                                }
                            }
                            scope.selectedDate = scope.selectedDate + "/";
                        }
                        if(scope.selectedDate.length === 10){
                            scope.selectedDate = scope.selectedDate + " ";
                        }
                        if(scope.selectedDate.length === 13){
                            if(parseInt(scope.selectedDate.substr(-2)) > 23){
                                var d = Math.floor( parseInt(scope.selectedDate.substr(-2))/24);
                                var offset = parseInt( scope.selectedDate.substr(-2)) - (d * 24);
                                //console.log(Math.floor(parseInt(scope.selectedDate.substr(-2))/24));

                                if(offset <= 9){
                                    offset = "0"+offset;
                                }

                                scope.selectedDate = scope.selectedDate.slice(0, scope.selectedDate.length - 2);
                                var days = parseInt(scope.selectedDate.substr(0, 2)) + d;
                                if(days <= 9){
                                    days = "0"+days;
                                }
                                scope.selectedDate = scope.selectedDate.slice(2, scope.selectedDate.length);
                                scope.selectedDate =  days + scope.selectedDate + offset;
                            }
                            scope.selectedDate = scope.selectedDate + ":";
                        }
                        if(scope.selectedDate.length === 16){
                            //console.log(scope.options.enableMinutes);
                            if(scope.options && scope.options.enableMinutes === false){
                                scope.selectedDate = scope.selectedDate.slice(0, scope.selectedDate.length - 2);
                                scope.selectedDate += "00";
                            }else{
                                if(parseInt(scope.selectedDate.substr(-2)) > 59){
                                    var offset = parseInt(scope.selectedDate.substr(-2)) - 60;

                                    if(offset <= 9){
                                        offset = "0"+offset;
                                    }
                                    scope.selectedDate = scope.selectedDate.slice(0, scope.selectedDate.length - 3);
                                    var hours = parseInt(scope.selectedDate.substr(-2)) + 1;
                                    if(hours <= 9){
                                        hours = "0"+hours;
                                    }
                                    scope.selectedDate = scope.selectedDate.slice(0, scope.selectedDate.length - 2);
                                    scope.selectedDate += hours + ":" + offset;
                                }
                            }
                        }
                        break;
                    case "MM/YYYY":
                        if(scope.selectedDate.length === 2){
                            scope.selectedDate = scope.selectedDate + "/";
                        }
                        break;
                    case "HH:mm":
                        if(scope.selectedDate.length === 2){
                            scope.selectedDate = scope.selectedDate + ":";
                        }
                        break;
                }
                /**
                 * Accepting numeric values only
                 */
                if(["0","1","2","3","4","5","6","7","8","9"].indexOf(scope.selectedDate[scope.selectedDate.length - 1]) < 0){
                    if(scope.selectedDate.length !== 3 && scope.selectedDate.length !== 6 && scope.selectedDate.length !== 11 && scope.selectedDate.length !== 14){
                        scope.selectedDate = scope.selectedDate.slice(0, scope.selectedDate.length - 1);
                    }
                }

                /**
                 * Limit date length to format length
                 */
                if(scope.selectedDate.length > scope.options.format.length){
                    scope.selectedDate = scope.selectedDate.slice(0, scope.options.format.length);
                }

                if(moment(scope.selectedDate, scope.options.format).isValid()){
                    scope.date = moment(scope.selectedDate, scope.options.format);

                    if(scope.selectedDate.length  === scope.options.format.length){
                        if(scope.options.minDate){
                            //console.log("minDate",scope.options.minDate.clone().startOf('day').diff(scope.date.clone().startOf('day'), 'days'));
                            if(scope.options.minDate.clone().startOf('day').diff(scope.date.clone().startOf('day'), 'days') > 0){
                                scope.date = scope.options.minDate;
                                scope.selectedDate = scope.options.minDate.format(scope.options.format);
                            }
                        }

                        if(scope.options.maxDate){
                            //console.log("maxDate",scope.options.maxDate.clone().endOf('day').diff(scope.date.clone().endOf('day'), 'days'));
                            if(scope.options.maxDate.clone().endOf('day').diff(scope.date.clone().endOf('day'), 'days') < 0){
                                scope.date = scope.options.maxDate;
                                scope.selectedDate = scope.options.maxDate.format(scope.options.format);
                            }
                        }
                    }



                }else{
                    if(scope.selectedDate.length === scope.options.format.length){
                        scope.selectedDate = scope.options.init.format(scope.options.format);
                    }
                }

                if(scope.selectedDate !== null && (scope.options.format === "DD/MM/YYYY HH:mm" || scope.options.format === "HH:mm")){
                    scope.minutes = scope.selectedDate.substr(-2);
                    scope.hours = scope.selectedDate.slice(scope.selectedDate.length - 5, scope.selectedDate.length - 3);

                }
            };

            scope.month = function (action) {
                switch (action) {
                    case "plus":
                        scope.date.add(1, "month");


                        buildCal();
                        break;
                    case "minus":
                        scope.date.subtract(1, "month");
                        buildCal();
                        break;
                }
            };
            scope.year = function (action) {
                switch (action) {
                    case "plus":
                        scope.date.add(1, "year");


                        buildCal();
                        break;
                    case "minus":
                        scope.date.subtract(1, "year");
                        buildCal();
                        break;
                }
            };

            scope.selectDay = function (date) {
                scope.selectedDate = date.format(scope.options.format);
                scope.date = date;
            };

            scope.changeView = function (view) {
                scope.view = view;
                buildCal();
            };

            scope.changeTime = function (action, target) {
                if(action === 'plus'){
                    switch(target){
                        case 'hours' :
                            if(scope.hours < 23){
                                scope.hours = parseInt(scope.hours) + 1;
                                if(scope.hours <= 9){
                                    scope.hours = "0" +  scope.hours;
                                }
                            }else{
                                scope.hours = "00";
                            }
                            break;
                        case 'minutes' :
                            if(scope.minutes < 59){
                                scope.minutes = parseInt(scope.minutes) + 1;
                                if(scope.minutes <= 9){
                                    scope.minutes = "0" +  scope.minutes;
                                }
                            }else{
                                scope.minutes = "00";
                            }
                            break;
                    }

                    scope.date.set({hours : scope.hours, minutes : scope.minutes});
                    scope.selectedDate = scope.date.format(scope.options.format);
                }
                if(action === 'minus'){
                    switch(target){
                        case 'hours' :
                            if(scope.hours > 0){
                                scope.hours = parseInt(scope.hours) - 1;
                                if(scope.hours <= 9){
                                    scope.hours = "0" +  scope.hours;
                                }
                            }else{
                                scope.hours = "23";
                            }
                            break;
                        case 'minutes' :
                            if(scope.minutes > 0){
                                scope.minutes = parseInt(scope.minutes) - 1;
                                if(scope.minutes <= 9){
                                    scope.minutes = "0" +  scope.minutes;
                                }
                            }else{
                                scope.minutes = "59";
                            }
                            break;
                    }

                    scope.date.set({hours : scope.hours, minutes : scope.minutes});
                    scope.selectedDate = scope.date.format(scope.options.format);

                }

            };

            scope.setTime = function (target) {
                if(target === 'hours'){

                    scope.hours = scope.hours.substr(-2);

                    if(parseInt(scope.hours) > 23){
                        scope.hours = "00";
                    }

                    if(parseInt(scope.hours) < 0){
                        scope.hours = 23;
                    }


                }

                if(target === 'minutes'){

                    scope.minutes = scope.minutes.substr(-2);

                    if(parseInt(scope.minutes) > 59){
                        scope.minutes = "00";
                    }

                    if(parseInt(scope.minutes) < 0){
                        scope.minutes = 59;
                    }


                }


                scope.date.set({hours : scope.hours, minutes : scope.minutes});
                scope.selectedDate = scope.date.format(scope.options.format);

            };

            scope.selectMonth = function (month) {
                scope.date.set({months :month});
                scope.selectedDate = scope.date.format(scope.options.format);
            };

            $rootScope.$on('rebuild', function () {
                buildCal();
            });

            scope.$watch('options.init', function (newVal) {
                if(newVal){
                    scope.date = newVal.clone();
                    scope.selectedDate = newVal.clone().format(scope.options.format);
                }

                if(scope.options && scope.options.target){
                    //console.log("target ",scope.options.target);
                    if(scope.options.target === "hour"){

                        scope.options.enableTime = true;
                        scope.options.enableMinutes = false;
                        scope.options.format = "DD/MM/YYYY HH:mm";

                        scope.date = scope.date.set({minutes: 0, seconds:0, milliseconds:0});
                        scope.selectedDate =  scope.date.clone().format(scope.options.format);

                    }
                    if(scope.options.target === "day"){
                        scope.options.enableTime = false;
                        scope.options.enableMinutes = false;
                        scope.options.format = "DD/MM/YYYY";
                        scope.selectedDate =  scope.date.clone().format(scope.options.format);

                    }
                    if(scope.options.target === "minute"){
                        scope.options.enableTime = true;
                        scope.options.enableMinutes = true;
                        scope.options.format = "DD/MM/YYYY HH:mm";
                        scope.selectedDate =  scope.date.clone().format(scope.options.format);
                    }
                }

            });

            scope.$watch('selectedDate', function () {
                if(scope.options){
                    if(moment(scope.selectedDate, scope.options.format).isValid()){
                        scope.options.getDate(moment(scope.selectedDate, scope.options.format));
                    }else{
                        scope.options.getDate(null)
                    }
                    $rootScope.$emit('rebuild');
                }
            });

        }
    }
});