angular.module('app').controller('pvDetailCtrl', function ($scope, $rootScope, $state, $stateParams, I3InstallationGroupSrv, $translate, I3InterventionSrv, $filter, Notification, ngDialog, I3PvSrv, i3HelperSrv) {
    $scope.document = {};
    $scope.intervention = {};
    $scope.installation = {};
    $scope._InvalidPvEdit = false
    i3HelperSrv.checkEditPermissionPV($stateParams.pvId, function (res) {

        if (res && res.result.length > 0) {
            $scope._InvalidPvEdit = true
        }
    }, function (err) {

    })
    I3PvSrv.query({
        query: {
            _id: $stateParams.pvId
        },
        options: {
            populate: [{
                path: "products._product"
            },
            {
                path: "products.zones._zone",
                select: 'name'
            }, {
                path: "_client",
                select: 'name'
            },
            {
                path: "_plateforme",
                select: 'name'
            },
            {
                path: "_documents._document",
                select: 'original_name'
            }
            ]
        }
    }, 1, 0, function (res) {
        if (res && res.success && res.result.length > 0) {
            $scope.pv_sync = res.result[0];
            console.log("TCL: $scope.pv_sync ", $scope.pv_sync)
            console.log("TCL: $scope.pv_sync.type", $scope.pv_sync.type)

            if ($scope.pv_sync.type == "INSTALLATION")
                $scope.get_install();
            if ($scope.pv_sync.type == "INTERVENTION")
                $scope.get_intervention();

        } else
            Notification.error($translate.instant("FIND_BY_ID_ERROR"));
    }, function (err) {
        Notification.error($translate.instant("FIND_BY_ID_ERROR"))
    })
    $scope.openProductDialog = function () {
        ngDialog.open({
            templateUrl: "app/commons/dialogs/pv/product.dlg.tpl.html",
            className: 'ngdialog-theme-default custom-width-800',
            controller: "productDlgCtrl",
            appendClassName: 'ngdialog-custom',
            scope: $scope
        })
    }
    $scope.get_install = function () {
        I3InstallationGroupSrv.query({
            query: {
                _id: $scope.pv_sync._installation
            },
            options: {
                populate: [{
                    path: "_plateforme"
                }]
            }
        }, 1, 0, function (res) {
            if (res && res.success && res.result.length > 0) {
                $scope.installation = res.result[0];
                if (!$scope.pv_sync.client_id) {
                    $scope.verify();
                } else {
                    if (!$scope.pv_sync.asset_id) {
                        $scope.verify_asset();
                    }
                }
                if( !$scope.pv_sync.asset_dev){
                    $scope.installation.assets.forEach(function (elem) {
                        if (elem.name == $scope.pv_sync._asset) {
                            if (elem.vin && elem.vin.length > 0) {
    
                                $scope.pv_sync.asset_dev = $scope.pv_sync._asset + '/' + elem.vin;
    
                                I3PvSrv.updateById($scope.pv_sync._id, $scope.pv_sync, function (response) {
    
                                }, function (err) {
    
                                })
                            }
    
    
                        }
    
                    });
                   }







            } else
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("FIND_BY_ID_ERROR")
                });
        }, function (err) {
            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("FIND_BY_ID_ERROR")
            });
        })
    }
    $scope.get_intervention = function () {
        I3InterventionSrv.query({
            query: {
                _id: $scope.pv_sync._intervention
            },
            options: {
                populate: [{
                    path: "_plateforme"
                }]
            }
        }, 1, 0, function (res) {
            if (res && res.success && res.result.length > 0) {
                $scope.intervention = res.result[0];
                if (!$scope.pv_sync.client_id) {
                    $scope.verify2();
                } else {
                    if (!$scope.pv_sync.asset_id) {
                        $scope.verify_asset();
                    }
                }
            } else
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("FIND_BY_ID_ERROR")
                });
        }, function (err) {
            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("FIND_BY_ID_ERROR")
            });
        })
    }
    $scope.verify = function () {
        var modal = ngDialog.open({
            controller: "ClientEdSyncDlgCtrl",
            templateUrl: "app/commons/dialogs/client/client.ed.sync.dlg.tpl.html",
            className: 'ngdialog-theme-default custom-width',
            scope: $scope,
            width: '900px',
            showClose: false,
            closeByEscape: false,
            closeByNavigation: true,
            closeByDocument: false,
            data: {
                "pv": true
            },
            resolve: {
                installation: function () {
                    return $scope.installation;
                }
            }
        });
        modal.closePromise.then(function (data) {
            $scope.pv_sync.client_id = data.value._id;
            $scope.pv_sync.client_name = data.value.name;
            I3PvSrv.updateById($scope.pv_sync._id, $scope.pv_sync, function (response) {
                if (response.success) {
                    Notification.success({
                        title: $translate.instant("SYNCHRONIZED_CLIENT"),
                        message: $translate.instant("SYNCHRONIZED_CLIENT_SUCC")
                    });
                    $scope.verify_asset();
                } else {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("ERROR_OCCURED_UPDATE") + "!"
                    });
                }
            }, function (err) {
                var msg = "";
                if (err.error.errorString) {
                    msg = err.error.errorString;
                }
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: msg
                });
            })
        }, function () { });
    }
    $scope.verify2 = function () {
        var modal = ngDialog.open({
            controller: "ClientIntervSyncDlgCtrl",
            templateUrl: "app/commons/dialogs/client/client.interv.sync.dlg.tpl.html",
            className: 'ngdialog-theme-default custom-width',
            scope: $scope,
            width: '900px',
            showClose: false,
            closeByEscape: false,
            closeByNavigation: true,
            closeByDocument: false,
            resolve: {
                intervention: function () {
                    return $scope.intervention;
                }
            }
        });
        modal.closePromise.then(function (data) {
            $scope.pv_sync.client_id = data.value._id;
            $scope.pv_sync.client_name = data.value.name;
            I3PvSrv.updateById($scope.pv_sync._id, $scope.pv_sync, function (response) {
                if (response.success) {
                    Notification.success({
                        title: $translate.instant("SYNCHRONIZED_CLIENT"),
                        message: $translate.instant("SYNCHRONIZED_CLIENT_SUCC")
                    });
                    $scope.verify_asset();
                } else {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("ERROR_OCCURED_UPDATE") + "!"
                    });
                }
            }, function (err) {
                var msg = "";
                if (err.error.errorString) {
                    msg = err.error.errorString;
                }
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: msg
                });
            })
        }, function () { });
    }

    $scope.verify_asset = function () {
        console.log('verifassrt');
        var modal = ngDialog.open({
            controller: "assetSyncDlgCtrl",
            templateUrl: "app/commons/dialogs/asset/asset.sync.dlg.tpl.html",
            className: 'ngdialog-theme-default custom-width',
            scope: $scope,
            width: '900px',
            showClose: false,
            closeByEscape: false,
            closeByNavigation: true,
            closeByDocument: false,
            data: {
                "pv": true
            },
            resolve: {
                installation: function () {
                    return $scope.installation;
                },
                intervention: function () {
                    return $scope.intervention;
                }
            }
        });
        modal.closePromise.then(function (data) {
            if (data.value != false) {

                if ($scope.pv_sync.error) {
                    delete $scope.pv_sync.error
                }
                $scope.pv_sync.asset_id = data.value._id;
                $scope.pv_sync._asset = data.value.name;
                I3PvSrv.updateById($scope.pv_sync._id, $scope.pv_sync, function (response) {
                    if (response.success) {
                        Notification.success({
                            title: $translate.instant("SYNCHRONIZED_CLIENT"),
                            message: $translate.instant("SYNCHRONIZED_CLIENT_SUCC")
                        });

                    } else {
                        Notification.error({
                            title: $translate.instant("ERROR"),
                            message: $translate.instant("ERROR_OCCURED_UPDATE") + "!"
                        });
                    }
                }, function (err) {
                    var msg = "";
                    if (err.error.errorString) {
                        msg = err.error.errorString;
                    }
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: msg
                    });
                })

            } else {
                $scope.pv_sync.error = true
                I3PvSrv.updateById($scope.pv_sync._id, $scope.pv_sync, function (response) {
                    if (response.success) {
                        Notification.error({
                            title: $translate.instant("PROBLEM_SYNCHRONIZED_ASSET"),
                            message: $translate.instant("PROBLEM_SYNCHRONIZED_ASSET_ERROR")
                        });

                    } else {
                        Notification.error({
                            title: $translate.instant("ERROR"),
                            message: $translate.instant("ERROR_OCCURED_UPDATE") + "!"
                        });
                    }
                }, function (err) {
                    var msg = "";
                    if (err.error.errorString) {
                        msg = err.error.errorString;
                    }
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: msg
                    });
                })




            }
        }, function () { });
    }
    $scope.uploadFiles = function (files, errFiles) {
        if (!$scope.pv_sync._documents) {
            $scope.pv_sync._documents = [];
        }
        $scope.errFiles = errFiles;
        i3HelperSrv.uploadFiles(files, {
            _plateforme: $scope.pv_sync._plateforme && $scope.pv_sync._plateforme._id ? $scope.pv_sync._plateforme._id : $scope.pv_sync._plateforme,
            related_model: [{
                model: "PV",
                model_id: $stateParams.pvId
            }]
        }, function (res) {
            if (res && res.success) {
                angular.forEach(res.result, function (file) {
                    var elem = {
                        _document: file,
                        _type: $scope.document._type
                    };
                    $scope.pv_sync._documents.push(elem)
                });
                $scope.saveFiles();
                $scope.document = {};
            }
        },
            function (response) {
                if (response.status)
                    $scope.errorMsg = response.status + ': ' + response.data;
            },
            function (evt) { });
    };
    $scope.downloadFile = function (file) {
        i3HelperSrv.downloadFile(file._document._id, file._document.original_name, function success(res) { }, function error(err) {
            Notification.error($translate.instant(err));
        }, function progress(evt) {
            console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '%');
        })
    };
    $scope.saveFiles = function () {
        delete $scope.pv_sync.__v;
        I3PvSrv.updateById($stateParams.pvId, $scope.pv_sync, function (response) {
            if (response.success) {
                Notification.success({
                    title: $translate.instant("PV_UPDATED_TITLE"),
                    message: $translate.instant("PV_UPDATED_SUC")
                });
            } else {
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_UPDATE")
                });
            }
        }, function (err) {
            var msg = "";
            if (err.error.errorString) {
                msg = err.error.errorString;
            }
            Notification.error({
                title: $translate.instant('ERROR'),
                message: msg
            });
        })
    };
    /**
     * Removing document
     * @param document
     */
    $scope.remove = function (pv, index, file) {
        $scope.file = file;
        $scope.pv_sync = pv;
        $scope.index = index;
        ngDialog.open({
            templateUrl: "app/commons/dialogs/pv/document.delete.dlg.tpl.html",
            controller: "pvDeleteDocumentDlgCtrl",
            scope: $scope
        })
    };

    $scope.downloadZipGrouppedfile = function () {

        try {
            var params = { _ids: [] };
            var comments = $translate.instant("PVS") + "\r\n";
            comments += "\t" + $translate.instant("CLIENT") + " :" + $scope.pv_sync.client_name + "\r\n";
            comments += "\t" + $translate.instant("PV_NUMBER") + " :" + $scope.pv_sync.pv_ref + "\r\n";
            comments += "\t" + $translate.instant("purchase_order_nbr") + " :" + $scope.pv_sync.purchase_order + "\r\n";
            comments += "\t" + $translate.instant("ASSET") + " :" + $scope.pv_sync._asset + "\r\n";
            comments += "-------------------------------------------------------------------------\r\n";
            comments += "\t- " + $translate.instant("DOCUMENTS") + " :\r\n";
            if ($scope.pv_sync._documents && $scope.pv_sync._documents.length > 0) {
                //  params.ids = {pv: []}
                $scope.pv_sync._documents.forEach(function (doc) {
                    if (doc._document._id)
                        params._ids.push(doc._document._id);
                })
                comments += "\t\t + pv : " + $translate.instant("DOCUMENTS") + " " + $translate.instant("PVS") + "\r\n";
            }
            /* $scope.interventionRequest.assets.forEach(function (asset) {
                 var asset_tag=asset.name;
                  if(asset.vin){
                      asset_tag=asset.vin
                  }
                 params.groups[asset_tag]=[];
                 if(asset.docs && asset.docs.length>0){
                     asset.docs.forEach(function (doc) {
                         if(doc._id)
                             params.groups[asset_tag].push(doc._id);
                     })
                     if(asset.gray_card_back && asset.gray_card_back.length>0){
                         params.groups[asset_tag].push(asset.gray_card_back);
                     }
                     if(asset.gray_card_front && asset.gray_card_front.length>0){
                         params.groups[asset_tag].push(asset.gray_card_front);
                     }
 
                 }
 
             })*/
            params.comments = comments
            i3HelperSrv.downloadZip(params, "PV_" + $scope.pv_sync._asset + "_" + $scope.pv_sync.pv_ref + "_docs.zip", function (res) {

            }, function (err) {
                console.log(err)
            }, function (percent) {
                console.log(percent)
            })
        } catch (e) {

            console.error(e)
        }
    }
});