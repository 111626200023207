angular.module('app').controller('adminStockEditCtrl', function ($scope, $timeout, $rootScope, $state, $stateParams, $translate, $filter, Notification, ngDialog, I3ProductSrv, I3StockSrv, constantSrv) {

    $scope.stock = {};
    $scope.options_list = [];
    I3StockSrv.query({
        query: {
            _id: $stateParams.stockId
        },
        options: {
            populate: [{
                path: "_plateforme"
            }]
        }
    }, 1, 0, function (res) {
        if (res && res.result) {
            $scope.stock = res.result[0];

        }
    })
    $scope.options = null;
    $scope.reverseTranslatedOptions = {};
    $scope.translatedOptions = null;
    $timeout(function () {}, 500).then(function () {
        if (($rootScope.checkAccess("PLATEFORME","R","O")) && $scope._ADMIN && $scope._ADMIN.platforms && $scope._ADMIN.platforms[0])
            $scope.stock._plateforme = $scope._ADMIN.platforms[0];
    });
    $scope.searchOption = function ($select) {
        if ($scope.options == null) {
            $scope.options = constantSrv.getProductsOptions();

            var reverseTranslatedOptions = {};
            angular.forEach($scope.options, function (option) {
                var translation = $translate.instant(option);
                reverseTranslatedOptions[translation] = option;
            });
            $scope.reverseTranslatedOptions = reverseTranslatedOptions;
        }

        // Translate back
        $scope.translatedOptions = [];

        angular.forEach($scope.options, function (option) {
            var translation = $translate.instant(option);
            $scope.translatedOptions.push(translation);
        });

        $scope.options = $scope.options.filter(function (option) {
            return $scope.options_list.indexOf($translate.instant(option)) == -1;
        });

    };

    $scope.save = function () {
        I3StockSrv.updateById($scope.stock._id, $scope.stock, function (response) {

            if (response.success) {
                Notification.success({
                    title: $translate.instant("STOCK_UPDATED"),
                    message: $translate.instant("STOCK_UPDATED_SUCC")
                });
                $state.go('app.admin.stocks');
                $scope.stocks = {};
            } else {
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_UPDATE") + " " + $scope.stock.name + "!"
                });
                $scope.stock = {};
            }
        }, function (err) {
            console.log(err);

            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("ERROR_OCCURED_UDPDATE") + " " + $scope.stock.name + "!"
            });
            $scope.stock = {};

        })
    };

    /**
     * Cancel creating/editing product
     */
    $scope.cancel = function () {
        $scope.stock = {};
        $state.go('app.admin.stocks');
    };

    /**
     * Add options
     */
    $scope.addOptions = function (opt) {
        if (opt) {
            if ($scope.options_list.indexOf(opt) >= 0) {
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("ALREADY_EXIST")
                });
            } else {

                $scope.options_list.push(opt);
                $scope.options.splice($scope.translatedOptions.indexOf(opt), 1);
                $scope.translatedOptions.splice($scope.translatedOptions.indexOf(opt), 1);
            }
        } else {
            Notification.error({
                title: $translate.instant("ERROR"),
                message: $translate.instant("NO_OPTIONS")
            });
        }
        //$scope.options = '';
        $scope.product.options = null;
    };
    $scope.openAddStockDialogue = function () {

        var ModalStock = ngDialog.open({
            templateUrl: "app/commons/dialogs/stocks/stocks.add.dlg.html",
            controller: "addStockVirtDlgCtrl",
            scope: $scope
        });
        ModalStock.closePromise.then(function (data) {

            console.log(data)

        }, function () {

        });
    };

    /**
     * delet stock_virtual
     */
    $scope.removeVstock = function (element, index) {
        $scope.stock.virtual_stock.splice(index, 1);
    };
    /**
     * editing stock_virtual
     */
    $scope.editVstock = function (vstock, index) {

        ngDialog.open({
            templateUrl: "app/commons/dialogs/stocks/stocks.edit.dlg.html",
            controller: "editStockVirtDlgCtrl",
            scope: $scope,
            resolve: {
                index: function () {
                    return index;
                }
            }
        })

    };

    /**
     * Remove options
     */
    $scope.removeOptions = function (index, opt) {
        $scope.options_list.splice(index, 1);
        $scope.options.push($scope.reverseTranslatedOptions[opt]);
        $scope.translatedOptions.push(opt);
    };

}).filter('translation', function () {
    return function (translatedObject) {

        var translatedOptions = [];

        for (key in translatedObject) {
            translatedOptions.push(translatedObject[key]);
        }
        return translatedOptions;

    }
});