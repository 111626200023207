angular.module('app').controller("confirmDeleteClaimDlgCtrl", function ($scope, $state,$translate, I3ClaimSrv, Notification) {

    $scope.confirm = function () {
        I3ClaimSrv.delete($scope.claim._id, function (response) {
            if(response.success){
                $scope.loadClaims()
                $scope.tableState.pagination.start = 0;

                Notification.success({
                    title: $translate.instant("CLAIM_DELETED_TITLE"),
                    message: $translate.instant("ALERT_CLAIM_DELETED"),

                });
                $scope.claim = {};
            }else{
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("CLAIM_DELETED_ERROR")
                });
                $scope.claim = {};
            }
        }, function (err) {
            console.log(err);

            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("CLAIM_DELETED_ERROR")
            });

            $scope.claim = {};
        });

        $scope.claim = {};
        $scope.closeThisDialog();
    }
});