angular.module('app.directives').directive('bsPopover', function ($rootScope) {
    return {
        restrict: 'A',
        scope: {
            data: '='
        },
        link: function (scope, element, attrs) {
            scope.$on('$destroy', function() {
                 
                    $('[data-toggle="popover"]').popover("dispose")
            });
            $(function () {
                $('[data-toggle="popover"]').popover({
                    placement: 'bottom',
                    container: 'body',
                    trigger: 'hover focus',
                    animation: true,
                    delay: {
                        "show": 700,
                        "hide": 100
                    },
                    html: true
                })
            });

        }
    };
});