/**
 * Created by Kais CHAIBI on 18/12/2017.
 */
angular.module("app.services").factory('openProjectSrv',function ($http,$rootScope, $window,Notification,CONFIG) {


    var service = {};


    service.getTaskById=function(id,success,error){



        // console.log(id);

   $http.get(CONFIG.API_URI+"/openproject/"+'work_packages/'+id).then(function(response) {

       if(response && response.status<400){
           success(response.data)
       }else{
           error(response)
       }

          },function(data) {
              error(data)

          })
    }

    service.getProjects=function(success,error){

        $http.get(CONFIG.API_URI+"/openproject/"+'projects').then(function(response) {

            if(response.status==200){
                 success(response.data)
            }else{
                error(response);
            }

        },function(data) {
            error(data)

        })
    }
    service.getStatus=function(success,error){

        $http.get(CONFIG.API_URI+"/openproject/"+'statuses').then(function(response) {

            if(response.status==200){
                success(response.data)
            }else{
                error(response);
            }

        },function(data) {
            error(data)

        })
    }
    service.getTypes=function(projectid,success,error){

         var path ="types"

        if(projectid){
            path="projects/"+projectid+"/"+"types";
        }
        $http.get(CONFIG.API_URI+"/openproject/"+path).then(function(response) {

            if(response.status==200){
                success(response.data)
            }else{
                error(response);
            }

        },function(data) {
            error(data)

        })
    }

    service.createTask=function(task,success,error){


        $http.post(CONFIG.API_URI+"/openproject/"+"work_packages?notify=true",task).then(function(response) {

            if(response.status<400){
                success(response.data)
            }else{
                error(response);
            }

        },function(data) {
            error(data)

        })
    }


    return service;
});