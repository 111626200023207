angular.module('app').controller('adminStatusCtrl',function ($scope,$translate,ngDialog,I3StatusSrv) {
    $scope.itemsByPage = 50;
    $scope.search = {};
    $scope.status=[]
    

    $scope.loadStatus = function (tableState,ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }

        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;
        var options =  {
            populate: [
                {
                    path: 'status',
                    select: 'model'
                }
            ],
            sortBy: {creation_dt: -1}
        };

        var query = {options:options};

        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
   }

        var requery = {};
        if (tableState.search && tableState.search.predicateObject) {
            if (tableState.search.predicateObject.model) {
                requery["model"] = {"$regex": tableState.search.predicateObject.model, "$options": "i"};
            }

            /*if (tableState.search.predicateObject.description ) {
                requery["description"] = {'$regex' :tableState.search.predicateObject.description, '$options' : 'i'}
            }*/

        }
        if($scope.search) {
            if ( $scope.search.status) {
                requery["model"] = $scope.search.status.model;
            }

        }

        query.query=requery;

        I3StatusSrv.query(query , number, start, function (response) {
        
            if(response.success){
                $scope.status = response.result;
                $scope.stockCount = response.total_count;
                tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
                tableState.pagination.totalItemCount = response.total_count;


            } else {
                console.error(response.error)
              }


            }, function (response) {
            //   console.log(response)

            })
        
    };

    $scope.remove = function (statut) {
     
        $scope.statut = statut;

        ngDialog.open({
            templateUrl: "app/commons/dialogs/confirm/status.delete.dlg.tpl.html",
            controller: "confirmDeleteStatusDlgCtrl",
            scope: $scope
        })

    };


});