angular.module("app").controller("goodsReturnSlipAddCtrl", function ($scope, $rootScope, $filter, $state, $translate, ngDialog, Notification, I3deliverySlipSrv, I3GoodsReturnSlipSrv, I3StockSrv, I3PvSrv) {
    $scope.goodsReturnSlip = {}
    $scope.deliverySlips = []
    $scope.no_slip = true;
    $scope.pvex = false;
    $scope.getPvNumber = function (pv) {
        if (pv != undefined) {
            var options = {
                populate: [{
                        path: 'products._product',
                        select: 'name'
                    },
                    {
                        path: 'products._stock',
                        select: 'name virtual_stock'
                    },
                    {
                        path: '_plateforme',
                        select: 'name'
                    },
                    {
                        path: 'products._article',
                        select: 'serialNumber imei'
                    },
                    {
                        path: '_stock',
                        select: 'name '
                    }
                ],
                sortBy: {
                    creation_dt: -1
                }
            };
            I3deliverySlipSrv.query({
                query: {
                    pv_ref: pv
                },
                options: options
            }, 10000, 1, function (res) {
                if (res.success) {
                    console.log(res.result[0]);
                    if (res.result.length > 0) {
                        $scope.delivery_slips = res.result[0]
                        $scope.goodsReturnSlip.delivery_slip = res.result[0];
                        $scope.deliverySlips = res.result[0];
                        $scope.no_slip = false;
                        // $scope.pvValide=true
                    } else {
                        $scope.deliverySlips.products = []
                        // $scope.pvValide=false
                    }
                }
            }, function (err) {
                console.log(err);
                $scope.no_slip = true;
            });
            var pvoptions = {
                populate: [{
                        path: "products._product"
                    },
                    {
                        path: "products.zones._zone",
                        select: 'name'
                    }, {
                        path: "_client",
                        select: 'name'
                    },
                    {
                        path: "_plateforme",
                        select: 'name'
                    },
                    {
                        path: "_documents._document",
                        select: 'original_name'
                    }
                ],
                sortBy: {
                    creation_dt: -1
                }
            };
            I3PvSrv.query({
                query: {
                    pv_ref: pv
                },
                options: pvoptions
            }, 10000, 1, function (res) {
                if (res.success) {
                    $scope.pvex = true;
                    console.log(res.result);
                    $scope.goodsReturnSlip.products_return = []
                    if (res.result.length > 0) {
                        $scope.goodsReturnSlip.products_return = []
                        $scope.pv = res.result[0];
                        console.log("$scope.pv", $scope.pv)
                    } else {
                        $scope.pv = undefined;
                        $scope.pvex = false;
                    }
                }
            }, function (err) {
                console.log(err);
                $scope.pvex = false;
            });
        } else {
            $scope.goodsReturnSlip.products_return = []
        }
    }
    I3StockSrv.query({}, 10000, 1, function (res) {
        if (res.success) {
            $scope.ListStocks = res.result;
            $scope.virtual_stocks = [];
            $scope.ListStocks.forEach(function (val) {
                $scope.virtual_stocks = $scope.virtual_stocks.concat(val.virtual_stock.map(function (stock) {
                    stock._stock = val.name;
                    return stock
                }));
            })
        }
    }, function (err) {
        console.log(err);
    });
    $scope.getVirtualStokById = function (virtualStockId) {
        if (virtualStockId) {
            var filtred = $scope.virtual_stocks.filter(function (val) {
                return val._id == virtualStockId;
            })
            if (filtred && filtred.length > 0) {
                return filtred[0];
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    }
    $scope.getProduct = function (product) {
        console.log(product)
        /* if(product.selected==true){
             if($scope.goodsReturnSlip.products_return && $scope.goodsReturnSlip.products_return.length>0){
                 $scope.goodsReturnSlip.products_return.push(product)
             }else{
                 $scope.goodsReturnSlip.products_return=[]
                 $scope.goodsReturnSlip.products_return.push(product)
             }
         }else{
             $scope.goodsReturnSlip.products_return.splice(product,1)
         }*/
    }
    $scope.save = function () {

        if (!$scope.no_slip) {
            $scope.goodsReturnSlip.pv = $scope.pv || $scope.delivery_slips;
            $scope.goodsReturnSlip.delivery_slip = $scope.delivery_slips;
            $scope.goodsReturnSlip._plateforme = $scope.delivery_slips._plateforme;
            $scope.goodsReturnSlip.products_installed = []
            $scope.deliverySlips.products.forEach(function (val) {
                $scope.goodsReturnSlip.products_installed.push(val)
            })
            console.log("$scope.goodsReturnSlip", $scope.goodsReturnSlip)
            // Creating a new deliverySlip
            I3GoodsReturnSlipSrv.add($scope.goodsReturnSlip, function (response) {
                if (response.success) {
                    Notification.success({
                        title: $translate.instant("GOODS_RETURN_SLIP_CREATED"),
                        message: $translate.instant("GOODS_RETURN_SLIP_CREATED_SUC")
                    });
                    $state.go('app.stocks.goodsReturnSlip', {}, {
                        reload: true
                    });
                } else {
                    Notification.error({
                        title: $translate.instant('ERROR'),
                        message: $translate.instant("ERROR_OCCURED_CREATE") + "!"
                    });
                    $scope.goodsReturnSlip = {};
                    $state.go("app.stocks.goodsReturnSlip");
                }
            }, function (err) {
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_CREATE") + "!"
                });
                $scope.goodsReturnSlip = {};
                $state.go("app.stocks.goodsReturnSlip");
            })
        }
    };
    $scope.setStock = function (product, index) {
        ngDialog.open({
            templateUrl: "app/commons/dialogs/goodsReturnSlip/returnSlipStock.add.dlg.tpl.html",
            className: 'ngdialog-theme-default custom-width-800',
            appendClassName: 'ngdialog-custom',
            controller: "returnSlipStockCtrl",
            scope: $scope,
            resolve: {
                index: function () {
                    return index;
                }
            }
        })
    }
    /*
    $scope.setdescription=function(product,index){
        ngDialog.open({
            templateUrl: "app/commons/dialogs/goodsReturnSlip/returnSlipComment.add.dlg.tpl.html",
            className: 'ngdialog-theme-default custom-width-800',
            appendClassName: 'ngdialog-custom',
            controller: "returnSlipCommentCtrl",
            scope: $scope,
            resolve: {
                index: function () {
                    return index;
                }
            }
        })
    }*/
})