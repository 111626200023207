/**
 * Created by Kais CHAIBI on 29/11/2017.
 */
var  I3_SERVICES=[

    {name:"I3ProductSrv",path:"product"},
    {name:"I3PackageSrv",path:"package"},
    {name:"I3PvSrv",path:"pv"},
    {name:"I3PlateformeSrv",path:"plateforme"},
    {name:"I3ActionLogSrv",path:"actionLog"},
    {name:"I3InstallationSrv",path:"installation"},
    {name:"I3InterventionSrv",path:"intervention"},
    {name:"I3ClaimSrv",path:"claim"},
    {name:"I3InstallationGroupSrv",path:"installationgroup"},
    {name:"I3UserSrv",path:"user"},
    {name:"I3RoleSrv",path:"role"},
    {name:"I3DocumentSrv",path:"document"},
    {name:"I3SettingSrv",path:"setting"},
    {name:"I3ClientFormSrv",path:"clientform"},
    {name:"I3ZoneSrv",path:"zone"},
    {name:"I3CompatibilitySrv",path:"compatibility"},
    {name:"I3StockSrv",path:"stock"},
    {name:"I3StatusSrv",path:"status"},
    {name:"I3WorkflowSrv",path:"workflow"},
    {name:"I3SubscriptionSrv",path:"subscription"},
    {name:"I3StockStatusSrv",path:"stock_status"},
   
    {name:"I3deliverySlipSrv",path:"delivery_slip"},
    {name:"I3ReceiptSlipSrv",path:"receipt_slip"},
    {name:"I3ClientSrv",path:"client"},
    {name:"invoiceClientSrv",path:"invoice/client"},
    {name:"invoiceInventorySrv",path:"invoice/inventory"},
    {name:"invoiceProductSrv",path:"invoice/product"},
    {name:"invoiceSubsidiarySrv",path:"invoice/subsidiary"},
    {name:"invoiceZoneSrv",path:"invoice/zone"},
    {
        name: "I3ArticleSrv",
        path: "article",
        opts: {
            methods: ["updateMultiple", "addMultiple"]
        }
    },
    {name:"I3StockMovementSrv",path:"stock_movement"},
    {name:"I3GoodsReturnSlipSrv",path:"goods_return_slip"},
    {name:"I3PendingPvSyncSrv",path:"pending_pv_sync"},
    {name:"I3DeliveryRequestSrv",path:"delivery_request"},
    {name:"I3ProductCodeSrv",path:"product_code"}
];
/**
 * ad constant service for further use
 */
angular.module("app.services").constant("I3_SERVICES",I3_SERVICES)

// create services
    I3_SERVICES.forEach(function (val) {

        angular.module("app.services").factory(val.name, ["AbstractApiSrv","CONFIG","extrasMethodsSrv",function (AbstractApiSrv,CONFIG,extrasMethodsSrv) {
            var service=new AbstractApiSrv(val);
            if(val && val.opts && val.opts.methods && val.opts.methods.length>0){
                val.opts.methods.forEach(function(method){
                    service[method]=extrasMethodsSrv[method];
                })
            }
            service.setApiUri(CONFIG.API_URI);
            return service;
        }])
    })
