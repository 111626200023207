angular.module("app").controller("pvsCtrl", function ($scope, $state, $translate, Notification, I3PvSrv, ngDialog, $rootScope, i3HelperSrv) {
    $scope.$state = $state;

    $scope.user = $rootScope.SESSION.user;

    var today = moment().startOf("day").clone();
    var now = moment().clone();
    $scope.endRes = now;
    $scope.date_texte = "Date";
    $scope.loading = false;
    $scope.search = {};
    $scope.itemsByPage = 50;
    $scope.pvs = [];

    $scope.pickerStart = {
        format: "DD/MM/YYYY",
        init: today,
        position: "top left",
        autoTrans: false,
        enableTime: false,
        enableDate: true,
        enableMinutes: false,
        defaultView: "DATE",
        getDate: function (date) {
            if (date) {
                $scope.startRes = date.startOf("day");
                $scope.pickerEnd.minDate = $scope.startRes;
            } else {
                $scope.startRes = null;
                $scope.pickerEnd.minDate = null;
            }
            if (!first_load_start_date) {
                $scope.is_date_filter_active = true;
                $scope.loadPvs();
                $scope.verify_date_limit();
            } else {
                first_load_start_date = false;
            }
        }
    };
    $scope.pickerEnd = {
        format: "DD/MM/YYYY",
        position: "top left",
        init: now,
        autoTrans: false,
        enableTime: false,
        enableDate: true,
        enableMinutes: false,
        defaultView: "DATE",
        getDate: function (date) {
            if (date) {
                $scope.endRes = date.endOf('day');
                $scope.pickerStart.maxDate = date;
                $scope.pickerEnd.maxDate = moment();
            } else {
                $scope.endRes = null;
                $scope.pickerEnd.maxDate = moment();
            }

        }
    };
    $scope.remove = function (pv) {
        $scope.pv = pv;

        i3HelperSrv.checkEditPermissionPV(pv._id, function (res) {

            if (res && res.result.length > 0) {

                Notification.error({
                    title: $translate.instant("CANNOT_DELETE_PV"),
                    message: $translate.instant("PV_USED_IN_INVENTORY_DELETED_ERROR", {
                        ref: res.result[0].ref
                    })
                })
            } else {

                ngDialog.open({
                    templateUrl: "app/commons/dialogs/confirm/pv.delete.dlg.tpl.html",
                    controller: "confirmDeletePvDlgCtrl",
                    scope: $scope
                })
            }
        }, function (err) {
            Notification.error({
                title: $translate.instant("ERROR"),
                message: $translate.instant("CONNECTION_SERVER_ERROR")
            })

        })

    };

    $scope.loadPvs = function (tableState, ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }
        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;
        var options = {
            populate: [{
                path: '_plateforme',
                select: 'name'
            }],
            sortBy: {
                creation_dt: -1
            }
        };

        var query = {
            options: options
        };
        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
        }

        var requery = {};
        if ($scope.search) {
            if ($scope.search.purchase_order) {
                requery["purchase_order"] = {
                    '$regex': $scope.search.purchase_order,
                    '$options': 'i'
                };

            }
            if ($scope.search && $scope.search.platform) {
                requery["_plateforme"] = $scope.search.platform._id;

            }

            if ($scope.search.asset) {

                requery["_asset"] = {
                    '$regex': $scope.search.asset,
                    '$options': 'i'
                };
            }
            if ($scope.search.client_name) {
                requery["client_name"] = {
                    '$regex': $scope.search.client_name,
                    '$options': 'i'
                };

            }
            if ($scope.search._plateforme) {
                requery["_plateforme"] = $scope.search._plateforme._id;

            }
            if ($scope.search.pv_ref) {
                requery["pv_ref"] = {
                    '$regex': $scope.search.pv_ref,
                    '$options': 'i'
                };;

            }
        }
        if (tableState.sort && Object.keys(tableState.sort).length > 0) {
            if (tableState.sort.predicate == "creation_dt") {
                query.options.sortBy.creation_dt = tableState.sort.reverse ? -1 : 1;

            }
        }

        query.query = requery;

        I3PvSrv.query(query, number, start,
            function (data) {


                if (data.success) {
                    if ($scope.user._id == '587c9ab4363e8e2e5a22dd75') {
                        $scope.pvs = data.result;
                  
                        $scope.pvs.forEach(function (elem) {

                            elem.isLocked = false
                        });
                    }else{
                        $scope.pvs = data.result;
                    } 
                   // $scope.pvs = data.result;

                    $scope.pvCount = data.total_count;
                    tableState.pagination.numberOfPages = Math.ceil(data.total_count / number);
                    tableState.pagination.totalItemCount = data.total_count;
                    $scope.tableState = tableState;
                } else {
                    console.error(data.error)
                }
            },
            function (err) {
                console.log("err", err)
            })
    };
    $scope.controlAccessInvoice = function (pv) {
        /*   $state.go('app.home.pvs.edit', {
              pvId: pv._id
          }) */
        i3HelperSrv.checkEditPermissionPV(pv._id, function (res) {

            if (res && res.result.length > 0) {

                Notification.error({
                    title: $translate.instant("CANNOT_EDIT_PV"),
                    message: $translate.instant("PV_USED_IN_INVENTORY", {
                        ref: res.result[0].ref
                    })
                })
                $state.go('app.home.pvs.detail', {
                    pvId: pv._id

                })

            } else {

                $state.go('app.home.pvs.edit', {
                    pvId: pv._id
                })
            }
        }, function (err) {
            Notification.error({
                title: $translate.instant("ERROR"),
                message: $translate.instant("CONNECTION_SERVER_ERROR")
            })

        })

    }
    /*
    $scope.assetpv = function () {
        var options = {
            populate: [{
                path: '_installation',
               
            }],
            sortBy: {
                creation_dt: -1
            }
        };

        var query = {
            query:{"type":"INSTALLATION"},
            options: options
        };
        I3PvSrv.query(query, 10000, 0,
            function (data) {


                if (data.success) {
               
                    data.result.forEach(function(val){
            if(!val.asset_dev){
    val._installation.assets.forEach(function (elem) {
        if (elem.name == val._asset) {
            if (elem.vin && elem.vin.length > 0) {

                val.asset_dev = val._asset + '/' + elem.vin;

               I3PvSrv.updateById(val._id,val, function (response) {

                }, function (err) {

                })
            }


        }

    });
}
                    

                    })
                 
          
                   
              
                } else {
                    console.error(data.error)
                }
            },
            function (err) {
                console.log("err", err)
            })

    }*/
  // $scope.assetpv()

});