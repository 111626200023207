angular.module('app').controller('workflowAddCtrl', function ($scope, $state, ngDialog, I3WorkflowSrv, $translate, Notification, I3StatusSrv, $q) {

    $scope.Models = ["COMPATIBILITY_STUDY", "COMPATIBILITY", "INSTALLATION", "INTERVENTION", "CLAIM", "INSTALLATION_REQUEST", "DELIVERY_REQUEST"]
    $scope.workflow = {};
    $scope.statusMatix = {}
    $scope.status = null;
    $scope.listWorkflow = null
    $scope.workflowExist = false

    function loadWorkFlow(status, workFlow) {
        try {
            if (!workFlow || !status || status.length <= 0) {
                return;
            } else {
                status.status.forEach(function (element) {
                    if (!$scope.statusMatix[element._id])
                        $scope.statusMatix[element._id] = [];
                    status.status.forEach(function (val) {

                        if (val._id == element._id) {
                            $scope.statusMatix[element._id].push({
                                id: val._id,
                                disabled: true,
                                value: false
                            });
                        } else {

                            $scope.statusMatix[element._id].push({
                                id: val._id,
                                disabled: false,
                                value: false
                            });

                        }

                    })

                });

                $scope.workflow.status.forEach(function (val) {
                    if (val) {
                        Object.keys($scope.statusMatix).forEach(function (statMax) {

                            if (statMax == val._status) {
                                val._availStatus.forEach(function (avail) {

                                    $scope.statusMatix[statMax].forEach(function (statMat) {

                                        if (statMat.id == avail) {
                                            statMat.value = true;

                                        }

                                    })
                                })
                            }
                        })
                    }

                })
            }
            // console.log($scope.statusMatix, $scope.status)
        } catch (e) {
            console.error(e);
        }
    }

    $scope.OnClickSelect = function (item) {

        $scope.workflow.model = item
        var loadWorkFlowPromise = $q.defer();
        var loadStatusPromise = $q.defer();

        if ($scope.workflow.model) {
            I3WorkflowSrv.query({
                query: {
                    model: $scope.workflow.model
                }
            }, 10000, 0, function (data) {

                if (data.success) {
                    $scope.listWorkflow = data.result
                    // console.log($scope.listWorkflow)
                    if ($scope.listWorkflow.length > 0) {
                        loadWorkFlowPromise.resolve();
                        $scope.workflowExist = true
                        Notification.warning({
                            title: $translate.instant('ALERT_INFO'),
                            message: $translate.instant("WORKFLOW_EXISTS") + "!"
                        });
                    }

                } else {
                    loadWorkFlowPromise.resolve();
                    console.error(data.error)
                }

            }, function (data) {
                loadWorkFlowPromise.resolve();
                // console.log(data)
            })
            I3StatusSrv.query({
                query: {
                    model: $scope.workflow.model
                }
            }, 10000, 0, function (response) {

                if (response.success && response.result && response.result.length > 0) {

                    $scope.status = response.result[0];
                    $scope.status.status.forEach(function (element) {
                        if (!$scope.statusMatix[element._id])
                            $scope.statusMatix[element._id] = [];
                        $scope.status.status.forEach(function (val) {
                            if (val._id == element._id) {
                                $scope.statusMatix[element._id].push({
                                    id: val._id,
                                    disabled: true,
                                    value: false
                                });
                            } else {
                                $scope.statusMatix[element._id].push({
                                    id: val._id,
                                    disabled: false,
                                    value: false
                                });

                            }

                        })
                    });

                } else {
                    console.error(response.error)
                }
                loadStatusPromise.resolve();

            }, function (response) {
                loadStatusPromise.resolve();
                // console.log(response)

            })
        }

        $q.all([loadStatusPromise.promise, loadWorkFlowPromise]).then(function () {
            if ($scope.listWorkflow && $scope.listWorkflow.length > 0) {
                $scope.statusMatix = {};
                $scope.workflow = $scope.listWorkflow[0];

            } else {
                delete $scope.workflow._id;
            }

            if ($scope.workflow._id)
                loadWorkFlow($scope.status, $scope.workflow)
        })

    }

    $scope.addWorkflow = function () {

        $scope.workflow.status = [];
        Object.keys($scope.statusMatix).forEach(function (val) {
            var statusElement = {
                _status: val
            };
            statusElement._availStatus =
                $scope.statusMatix[val].filter(function (elem) {

                    return elem.value == true;
                });
            statusElement._availStatus = statusElement._availStatus.map(function (element) {

                return element.id;

            })

            $scope.workflow.status.push(statusElement)
        })
        // console.log($scope.workflow)
        if ($scope.workflow._id) {
            I3WorkflowSrv.updateById($scope.workflow._id, $scope.workflow, function (response) {

                if (response.success) {
                    // console.log("response", response)
                    Notification.success({
                        title: $translate.instant("WORKFLOW_UPDATED"),
                        message: $translate.instant("WORKFLOW_UPDATED_SUCC")
                    });
                    $state.go('app.admin.workflow');

                    $scope.workflow = {};
                } else {
                    Notification.error({
                        title: $translate.instant('ERROR'),
                        message: $translate.instant("ERROR_OCCURED_UPDATE") + "!"
                    });
                    $scope.workflow = {};
                }
            }, function (err) {
                console.log(err);

                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_UDPDATE") + "!"
                });
                $scope.workflow = {};

            })
        } else {

            I3WorkflowSrv.add($scope.workflow, function (response) {
                if (response.success) {
                    Notification.success({
                        title: $translate.instant("WORKFLOW_CREATED"),
                        message: $translate.instant("WORKFLOW_ADDED_SUC")
                    });
                    $state.go('app.admin.workflow');
                    $scope.workflow = {};
                } else {
                    Notification.error({
                        title: $translate.instant('ERROR'),
                        message: $translate.instant("ERROR_OCCURED_CREATE") + "!"
                    });
                }
            }, function (err) {
                console.log(err);
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_CREATE") + "!"
                });
                $scope.workflow = {};
            })
        }
    };

    $scope.cancel = function () {
        $scope.workflow = {};
        $state.go('app.admin.workflow');
    }

});