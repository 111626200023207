angular.module('app').controller('pvSynchroneCtrl', function ($scope, $rootScope, $state, $translate, $filter, Notification, ngDialog,I3PendingPvSyncSrv) {


    $scope.itemsByPage = 50;

$scope.LoadPendingPvs=function(tableState, ctrl){
    if (!$scope.stCtrl && ctrl) {
        $scope.stCtrl = ctrl;
    }
    if (!tableState && $scope.stCtrl) {
        $scope.stCtrl.pipe();
        return;
    }
    var start = 0;
    var number = $scope.itemsByPage;

    var options = {
        populate: [
            {
                path: '_plateforme',
                select: 'name'
            }
            ,{
                path: '_pv_id'
               
             
            }
        ]
    };
    var query = {options:options};
    I3PendingPvSyncSrv.query(query, number, start, function (response) {
        if (response.success) {
            $scope.PendingPvs = response.result;
     
            $scope.PendingPvsCount = response.total_count;
            tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
            tableState.pagination.totalItemCount = response.total_count;
            $scope.tableState = tableState;
        }
    });


}
$scope.pendingPvSync=function(pendingPvSync,index){
 
    ngDialog.open({
        templateUrl: "app/commons/dialogs/pendingPvSync/pendingPvSync.dlg.tpl.html",
        controller: "pendingPvSynchroneDlgCtrl",
    
        appendClassName: 'ngdialog-custom',
        scope: $scope,
        resolve: {
            index: function () {
                return index;
            }
        }
    })
}


$scope.removePvSync=function(pending,index){



    ngDialog.open({
        templateUrl: "app/commons/dialogs/confirm/pendingPvSync.delete.dlg.tpl.html",
        controller: "deletePendingPvSynchroneDlgCtrl",
        appendClassName: 'ngdialog-custom',
        scope: $scope,
        resolve: {
            index: function () {
                return index;
            }
        }
    })

}

    $scope.cancel = function () {

        $state.go('app.admin.invoiceConnector');
    };

})