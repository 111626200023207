angular.module('app.dialogs').controller('editIPaddressDlgCtrl', function ($scope,NGIFleetHelperSrv,iaxeedCompanySrv,NGIFleetClientSrv,plateformHelperSrv) {
   
   
   

  
  $scope.editIpAdress = function () {
     
    
      $scope.closeThisDialog();
  };

  $scope.cancel = function () {
    $scope.closeThisDialog(false);
}

});