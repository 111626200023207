angular.module('app.dialogs').controller('addIPaddressDlgCtrl', function ($scope, NGIFleetHelperSrv, iaxeedCompanySrv, NGIFleetClientSrv, plateformHelperSrv) {



  $scope.ip_address = {}


  $scope.addIpAddress = function () {


    $scope.closeThisDialog($scope.ip_address);
  };

  $scope.cancel = function () {
    $scope.closeThisDialog(false);
  }

});