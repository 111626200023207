angular.module('app.dialogs').controller('pendingPvSynchroneDlgCtrl', function ($scope, $rootScope, ngDialog,index, Notification, $translate) {
// console.log("fffffffffffff")
try {
    $scope.PendingPvsError=[];
    $scope.PendingPvsErrorList=[]
$scope.PendingPvsError=JSON.parse(JSON.stringify($scope.PendingPvs[index].sync_errors)) ;

 $scope.PendingPvsError.forEach(function(val){
 if(typeof val == "string"){
    var x = val.split(":");
    $scope.PendingPvsErrorList.push( {
        error: x[0],
        description :x[1]

    })
 } else if(Array.isArray(val)){
    val.forEach(function(elem){
        var y = elem.split(":");
        $scope.PendingPvsErrorList.push( {
            error: y[0],
            description :y[1]
    
        })
    })
 } else if(typeof val == "object"){

Object.keys(val).forEach(function(err){
console.log(err)
    $scope.PendingPvsErrorList.push( {
        error: err,
        description :val[err]

    })

})


 }

})
} catch (error) {
    console.log(error)
}



})