angular.module("app").controller("clientDetailsCtrl", function ($scope, $rootScope, $filter,i3HelperSrv, $state, I3ProductSrv, constantSrv, I3ClaimSrv, I3PvSrv, iaxeedMonitoringSrv, iaxeedFleetSrv, I3SubscriptionSrv, I3InstallationGroupSrv, I3InterventionSrv, $translate, ngDialog, Notification, I3PlateformeSrv, plateformHelperSrv, iaxeedAssetSrv, iaxeedSocketSrv, $window, $stateParams) {

    $scope.$state = $state;
    $scope.tab = "1";
    $scope.first_load = true;
    $scope.search = $scope.search = { asset: "", _dev: "", brand: "", model: "", assetStatus: "ALL",idCard:""  }
    $scope.itemsByPage = 50;
    $scope.filters = {};
    $scope.MonitorMajorTotal = 0;
    $scope.MonitorNormalTotal = 0;
    $scope.MonitorMinorTotal = 0;
   $scope.tapinter=false;




    $scope.assetStatusChoices = [
        "ALL", "ACTIVE_ASSET", "DISABLED_ASSET"
    ]


    $scope.loadClientById = function (tableState, ctrl) {
        console.log($stateParams.id)
        var agg = [
            {
                $match: {
                    _company_owner: "#OBJID#" + $stateParams.id + "#OBJIDEND#"
                }
            },
            {
                $project: {
                    _company_owner: "$_company_owner",
                    asset: { _id: "$_id", name: "$name", gps_dt: "$rt.gps_dt", _dev: "$_dev", brand: "$brand", model: "$model",idCard:"$idCard"  }
                }
            },
            {

                $group: {
                    _id: "$_company_owner",
                    assets: { $push: "$asset" }
                }

            },
            {
                $lookup: {
                    from: "company",
                    localField: "_id",
                    foreignField: "_id",
                    as: "_company_owner"
                }
            },
            {
                $project: {
                    "_company_owner": { "$arrayElemAt": ["$_company_owner", 0] },
                    assets: "$assets"
                }
            },
            {
                $project: {
                    "_company_owner": {
                        "_id": "$_company_owner._id",
                        name: "$_company_owner.name",
                        contact: "$_company_owner.contact",
                        expiration_dt: "$_company_owner.expiration_dt"
                    },
                    "assets": "$assets"
                }
            }
        ]
        iaxeedAssetSrv.agg({
            aggregate: agg
        }, 10000, 0, function (res) {

            $scope.assetCount = 0;
            if (res && res.success) {
                var result = res.result[0];

                $scope.assetCount += result.assets.length;
                result.enabledAsset = [];
                result.disabledAsset = [];
                result.assets.forEach(function (asset) {
                    if (asset && asset.gps_dt) {

                        if (moment().add(-2, "days").diff(asset.gps_dt, "days") >= 0) {
                            result.disabledAsset.push(asset);
                            asset.isDisabled = true;

                        } else {
                            result.enabledAsset.push(asset);
                        }
                    } else {
                        result.disabledAsset.push(asset);
                    }
                })


                $scope.client = result;
                console.log($scope.client)
                $scope.clientCount = res.result.length;
            } else {

            }
        }, function (err) {
            if (err.status === -1) {
                Notification.error($translate.instant("IMPOSSIBLE_CNX"));
            } else if (err.status === 401) {
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS")
                });
            } else {
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("LOADING_ERROR")
                });
            }
        })



    }

    $scope.change_tab = function (tab) {
        $scope.tab = tab;

        if (tab == "2") {
            console.log("tab", tab, $scope.subscriptionLoaded)
            if (!$scope.subscriptionLoaded) {
                $scope.loadSubscriptionsDetails()
            }
        }
        if (tab == "3") {
           
            $scope.loadPvs()
        }
        if (tab == "4") {
          
            $scope.loadInterventions()
        }
        if (tab == "5") {
       
            $scope.loadInstallations()
        }
        if (tab == "6") {
           0
            $scope.loadClaims()
        }
        if (tab == "7") {
            $scope.loadMonitorings()

        }
    }
    $scope.resetFilter = function () {
        $scope.search = { asset: "", _dev: "", brand: "", model: "", assetStatus: "ALL",idCard:"" }
    }

    $scope.showRealtime = function (asset) {
        var assetRt = angular.copy(asset)


        try {
            iaxeedAssetSrv.query({query:{ _id: assetRt._id }}, 1, 0, function (res) {
                if (res && res.success) {
                    assetRt = res.result[0];
                }


                $scope.asset = assetRt;
                var dialog = ngDialog.open({
                    controller: "realtimeDlgCtrl",
                    templateUrl: "app/commons/dialogs/realtime/realtime.dlg.tpl.html",
                    scope: $scope,
                    className: 'ngdialog-theme-default custom-width'
                });

                dialog.closePromise.then(function () {
                    iaxeedSocketSrv.closeListner('newMsg');
                });
            }, function (err) {
                console.error(err);
            })
        } catch (e) {
            console.error(e);
        }
    };
    $scope.loadSubscriptionsDetails = function () {
        I3ProductSrv.query({}, 10000, 1, function (response) {
            $scope.products = response.result;
        }, function (err) {
            console.log(err);
        });

        var query = {
            "_client._id": $scope.client._company_owner._id
        }
        var options = { populate: "_plateforme _product" };
        I3SubscriptionSrv.query({ query: query, options: options }, 10000, 0,
            function (data) {

                if (data.success) {


                    if (data.result && data.result.length > 0) {
                        try {

                            $scope.clientName = data.result[0]._client ? data.result[0]._client.name : "";
                            $scope.subscriptions = data.result.filter(function (val) {

                                return val.isUnsubscribe == false || val.isUnsubscribe == undefined || val.isUnsubscribe == null
                            });

                            $scope.unSubscriptions = data.result.filter(function (val) {
                                return val.isUnsubscribe == true
                            });


                            $scope.subscriptionDetailsCount = 0,
                                $scope.subscriptions.forEach(function (val) {

                                    if (val.quantity > 0) {
                                        $scope.subscriptionDetailsCount += val.quantity
                                    }
                                })
                            $scope.unSubscriptionDetailsCount = 0,
                                $scope.unSubscriptions.forEach(function (val) {

                                    if (val.quantity > 0) {
                                        $scope.unSubscriptionDetailsCount += val.quantity
                                    }

                                    var _unsubscribe_products = $scope.products.filter(function (prod) {

                                        return val._unsubscribe_product == prod._id
                                    });

                                    if (_unsubscribe_products && _unsubscribe_products.length > 0) {
                                        val._unsubscribe_product = _unsubscribe_products[0];
                                    } else {
                                        val._unsubscribe_product = null;
                                    }
                                })


                       

                        } catch (e) {
                            console.log(e);
                        }
                    }


                    tableState.pagination.numberOfPages = Math.ceil(data.total_count / number);
                    tableState.pagination.totalItemCount = data.total_count;
                    $scope.tableState = tableState;
                } else {
                    console.error(data.error)
                }
            },
            function (data) {
                console.log(data)
            })

    };



    $scope.filterList = function (item) {
        var isNotFiltred = true;


        if ($scope.search && $scope.search.name && $scope.search.name.length > 0) {

            if (item && item.name) {
                if (item.name.toLowerCase().search($scope.search.name.toLowerCase()) < 0) {
                    isNotFiltred = false;
                }
            } else {
                isNotFiltred = true;
            }
        } else {
            isNotFiltred = true;
        }
        if (isNotFiltred) {
            if ($scope.search && $scope.search._dev && $scope.search._dev.length > 0) {

                if (item && item._dev) {
                    if (item._dev.toLowerCase().search($scope.search._dev.toLowerCase()) < 0) {
                        isNotFiltred = false;
                    }
                } else {
                    isNotFiltred = true;
                }
            } else {
                isNotFiltred = true;
            }
        }
        if (isNotFiltred) {
            if ($scope.search && $scope.search.brand && $scope.search.brand.length > 0) {

                if (item && item.brand) {
                    if (item.brand.toLowerCase().search($scope.search.brand.toLowerCase()) < 0) {
                        isNotFiltred = false;
                    }
                } else {
                    isNotFiltred = true;
                }
            } else {
                isNotFiltred = true;
            }
        }
        if (isNotFiltred) {
            if ($scope.search && $scope.search.model && $scope.search.model.length > 0) {

                if (item && item.model) {
                    if (item.model.toLowerCase().search($scope.search.model.toLowerCase()) < 0) {
                        isNotFiltred = false;
                    }
                } else {
                    isNotFiltred = true;
                }
            } else {
                isNotFiltred = true;
            }
        }
        if (isNotFiltred) {
            if ($scope.search && $scope.search.assetStatus && $scope.search.assetStatus != "ALL") {
                if ($scope.search.assetStatus == "ACTIVE_ASSET") {
                    isNotFiltred = item.isDisabled == false || item.isDisabled == undefined;
                } else {
                    isNotFiltred = item.isDisabled == true;
                }

            } else {
                isNotFiltred = true;
            }
        }
        if (isNotFiltred) {
            if ($scope.search && $scope.search.idCard && $scope.search.idCard.length>0) {

                 if(!item.idCard ){
                     isNotFiltred =false;
                 }else if((""+item.idCard).indexOf($scope.search.idCard)==0){
                     isNotFiltred=true ;
                 }else{
                     isNotFiltred=false;
                 }

            }
        }
        return isNotFiltred;
    }


    if ($window.sessionStorage.plateform) {
        try {
            plateform = JSON.parse($window.sessionStorage.plateform);
            $scope.plateforme = plateform
            $scope.loadClientById()
        } catch (e) {
            console.error("invalid plateform from  cookie select first from list", e);
            plateform = null;
        }
    } else {
        $state.go("app.accounts.clients");
    }
    ///////////////////////////Load Pvs//////////////////////////////////
    $scope.loadPvs = function (tableState, ctrl) {
       /* if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }
        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }*/
        var start = 0;
        var number = $scope.itemsByPage;


        I3PvSrv.query(
            {
                query:
                    { client_id: $scope.client._company_owner._id },
                options: {
                    populate: [{
                        path: "_plateforme",
                        select: "name"
                    },{
                        path: "_documents._document",
                        select: "original_name"
                    }]

                }
            }, number, start,
            function (data) {

                if (data.success) {
                    $scope.pvs = data.result;
                   
                    $scope.pvCount = data.total_count;
                    tableState.pagination.numberOfPages = Math.ceil(data.total_count / number);
                    tableState.pagination.totalItemCount = data.total_count;
                    $scope.tableState = tableState;
                } else {
                    console.error(data.error)
                }
            },
            function (err) {
                console.log("err", err)
            })
    };

    /////// Load INTERVENTIONS///////

    $scope.loadInterventions = function (tableState, ctrl) {

        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }
        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;
       

        I3InterventionSrv.query(
            { query: { client_id: $scope.client._company_owner._id } }
            , number, start, function (res) {
               
                if (res.success) {

                    $scope.interventions = res.result;
                 
                    $scope.interventionsCount = res.total_count;
                    tableState.pagination.numberOfPages = Math.ceil(res.total_count / number);
                    tableState.pagination.totalItemCount = res.total_count;
                    $scope.tableState = tableState;
                }
            }, function (err) {
                console.log(err);
            })
    };


    /////// Load INSTALLATION///////


    $scope.loadInstallations = function (tableState, ctrl) {
   
       /* if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }

        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }*/
        var start = 0;
        var number = $scope.itemsByPage;

        I3InstallationGroupSrv.query(
            { query: { client_id: $scope.client._company_owner._id }, options: { populate: [{ path: "_plateforme", select: "name" }] } }
            , number, start, function (response) {
               
                if (response.success) {


                    $scope.installsCount = response.total_count;
                    $scope.installations = response.result.map(function (e) {

                        e.installCount = 0;
                        e.totalAsset = 0;
                        if (e.assets && e.assets.length > 0) {
                            e.totalAsset = e.assets.length;
                            e.assets.forEach(function (val) {
                                if (val.status == "INSTALLED" || val.status == "COMMISSIONING")
                                    e.installCount++;
                            })
                        }
                        e.progress = e.totalAsset ? e.installCount * 100 / e.totalAsset : 0;

                        return e;
                    });

                    console.log("$scope.installations", $scope.installations)

                }
            });
    };
    /////// Load CLAIMS///////

    $scope.loadClaims = function (tableState, ctrl) {

      /*  if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }
        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }*/
        var start = 0;
        var number = $scope.itemsByPage;

        I3ClaimSrv.query(
            { query: { client_id: $scope.client._company_owner._id }, options: { populate: [{ path: "_plateforme", select: "name" }] } }
            , number, start, function (res) {

                if (res.success) {

                    $scope.claims = res.result;

                    $scope.claimCount = res.total_count;
                    tableState.pagination.numberOfPages = Math.ceil(res.total_count / number);
                    tableState.pagination.totalItemCount = res.total_count;
                    $scope.tableState = tableState;
                }
            }, function (err) {
                console.log(err);
            })
    };
    // load Serveillance
    $scope.loadpriority2 = function () {
        iaxeedMonitoringSrv.query({
            query: {
                category: $scope.selectedCategory ? $scope.selectedCategory._id : undefined,
                priority: 2,
                status: "New",
                _company_owner: $scope.client._company_owner._id
            }
        }, 10000, 1, function (res) {

            $scope.MonitorMajorTotal = res.total_count
        })



    }
    $scope.loadpriority1 = function () {
        iaxeedMonitoringSrv.query({
            query: {
                category: $scope.selectedCategory ? $scope.selectedCategory._id : undefined,
                priority: 1,

                status: "New",
                _company_owner: $scope.client._company_owner._id
            }
        }, 10000, 1, function (res) {
            $scope.MonitorNormalTotal = res.total_count
        })



    }
    $scope.loadpriority0 = function () {
        iaxeedMonitoringSrv.query({
            query: {
                category: $scope.selectedCategory ? $scope.selectedCategory._id : undefined,
                priority: 0,
                status: "New",
                _company_owner: $scope.client._company_owner._id
            }
        }, 10000, 1, function (res) {
            $scope.MonitorMinorTotal = res.total_count
        })



    }

    $scope.loadMonitorings = function (tableState, ctrl) {
        if (!$scope.first_load) {
            $scope.loadMonitorings2(tableState, ctrl);

        } else {
            $scope.first_load = false;
        }
    };

    //
    $scope.loadMonitorings2 = function (tableState, ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }
        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;
        var query = {
            category: $scope.selectedCategory ? $scope.selectedCategory._id : undefined,
            status: "New",
            _company_owner: $scope.client._company_owner._id
        };
        var options = {
            populate: '_company_owner _asset _rule'
        };
        if ($scope.currentPage) {
            start = $scope.currentPage;
        }
        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
        }
        if (tableState.sort && tableState.sort.predicate) {
            options.sortBy = {};
            var predicate = tableState.sort.predicate;
            options.sortBy[predicate] = ((tableState.sort.reverse) ? -1 : 1);
        }
        if ($scope.selectedCategory) {
            query.category = $scope.selectedCategory ? $scope.selectedCategory._id : undefined;
        }
        if ($scope.selectedAsset) {
            query._asset = $scope.selectedAsset._id;
        } else if ($scope.selectedFleet && $scope.selectedFleet._assets && $scope.selectedFleet._assets.length > 0) {
            query._asset = {
                $in: $scope.selectedFleet._assets
            };
        } else if ($scope.selectedCompany) {
            query._company_owner = $scope.selectedCompany._id;
        }
        var prioQuery = getPriorityQuery($scope.lowDisabled, $scope.mediumDisabled, $scope.highDisabled);
        if (prioQuery != null && prioQuery != undefined) {
            query.priority = prioQuery;
        }
        iaxeedMonitoringSrv.query({
            query: query,
            options: options
        }, number, start, function (response) {

            if (response.success) {

                $scope.monitorings = response.result;

                if ($scope.selectedFleet) {

                    $scope.loadMonitoringData($scope.client._company_owner, $scope.selectedFleet, $scope.selectedAsset)
                } else {

                    $scope.loadpriority2()
                    $scope.loadpriority1()
                    $scope.loadpriority0()
                }


                $scope.monitoringCount = response.total_count;
                tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
                tableState.pagination.totalItemCount = response.total_count;
            } else {
                Notification.error($translate.instant("LOADING_ERROR"));
            }
        });
    };
    function getPriorityQuery(lowDisabled, mediumDisabled, highDisabled) {
        if (lowDisabled && mediumDisabled && highDisabled) {
            return null;
        } else {
            var enabledPrio = [];
            if (!lowDisabled) {
                enabledPrio.push(0);
            }
            if (!mediumDisabled) {
                enabledPrio.push(1);
            }
            if (!highDisabled) {
                enabledPrio.push(2);
            }
            if (enabledPrio && enabledPrio.length > 0) {
                if (enabledPrio.length == 1) {
                    return enabledPrio[0];
                } else if (enabledPrio.length == 3) {
                    return null // no need for filter get all
                } else {
                    return {
                        $in: enabledPrio
                    };
                }
            } else {
                return null;
            }
        }
    }




    $scope.applyPriorityFilter = function (type) {
        if (type == 'low') {
            $scope.lowDisabled = !$scope.lowDisabled;
        } else if (type == 'medium') {
            $scope.mediumDisabled = !$scope.mediumDisabled;
        } else {
            $scope.highDisabled = !$scope.highDisabled;
        }
        if ($scope.stCtrl) {
            $scope.stCtrl.tableState().pagination.start = 0;
            $scope.stCtrl.pipe();
        }
    }
    $scope.processMonitoring = function (result) {

        if (result && result.length > 0) {
            result.forEach(function (val) {

                if (val._id.priority >= 2) {
                    $scope.MonitorMajorTotal = (val.cnt || 0);
                } else if (val._id.priority == 1) {
                    $scope.MonitorNormalTotal = (val.cnt || 0);
                } else if (val._id.priority == 0) {
                    $scope.MonitorMinorTotal = (val.cnt || 0);
                }
            })
        }



    }




    //search fleet with ui-select refrech
    $scope.searchFleet = function ($select) {
        if (!$select.open) return;
        iaxeedFleetSrv.query({
            query: {
                _company_owner: $scope.client._company_owner._id ? $scope.client._company_owner._id : undefined
            }
        }, 10, 0, function (res) {
            if (res && res.success) {
                $scope.fleets = res.result;

            } else {
                console.log($translate.instant("LOADING_ERROR_CLIENT"))

            }
        }, function (err) {
            if (err.status === -1) {
                console.log($translate.instant("IMPOSSIBLE_CNX"))

            } else if (err.status === 401) {
                console.log($translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS"))

            } else {
                console.log($translate.instant("LOADING_ERROR_FLEET"))

            }
        })
    };
    //search asset with ui-select refrech
    $scope.searchAsset = function ($select) {

        if (!$select.open) return;
        iaxeedAssetSrv.query({
            query: {
                _company_owner: $scope.client._company_owner._id ? $scope.client._company_owner._id : undefined,
                _id: $scope.selectedFleet ? {
                    $in: $scope.selectedFleet._assets
                } : undefined,
                name: {
                    '$regex': $select.search,
                    '$options': 'i'
                }
            }
        }, 10, 0, function (res) {
            if (res && res.success) {
                $scope.assets = res.result;
            } else {
                console.log($translate.instant("SERVER_UNAVAILABLE"))

            }
        }, function (err) {
            if (err.status === -1) {
                console.log($translate.instant("IMPOSSIBLE_CNX"))

            } else if (err.status === 401) {
                console.log($translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS"))

            } else {
                console.log($translate.instant("LOADING_ERROR_ASSET"))

            }
        })
    };
    //search category with ui-select refrech
    $scope.searchCategory = function ($select) {
        if (!$select.open) return;
        var query = [{
            $match: {}
        }, {
            $group: {
                _id: "$category"
            }
        }]

        iaxeedMonitoringSrv.agg({
            aggregate: query
        }, 10, 0, function (res) {
            if (res && res.success) {
                $scope.categories = res.result

            } else {
                $scope.categories = [];
            }
        },
            function (err) {
                if (err.status === -1) {
                    console.log($translate.instant("IMPOSSIBLE_CNX"))

                } else if (err.status === 401) {
                    console.log($translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS"))


                } else {
                    console.log($translate.instant("LOADING_ERROR_CATEGORY"))

                }
            })
    };



    $scope.selectFleet = function (fleet) {
        $scope.selectedAsset = null;
        $scope.filters._asset = null;
        $scope.selectedFleet = fleet
        $scope.reloadData()
    }
    //select asset
    $scope.selectAsset = function (asset) {
        $scope.selectedAsset = asset
        $scope.reloadData()
    }
    //select category
    $scope.selectCategory = function (category) {
        $scope.selectedCategory = category
        $scope.reloadData()
    }


    $scope.reloadData = function () {
        //console.log("Reloading data ...");

        $scope.MonitorMajorTotal = 0;
        $scope.MonitorNormalTotal = 0;
        $scope.MonitorMinorTotal = 0;

        $scope.loadMonitoringData($scope.client._company_owner, $scope.selectedFleet, $scope.selectedAsset);
        if ($scope.stCtrl) {
            $scope.stCtrl.tableState().pagination.start = 0;
            $scope.stCtrl.pipe();
        }
    }


    $scope.loadMonitoringData = function (company, fleet, asset) {
        console.log(asset)
        var agg = [];
        if (asset) {
            agg = [{
                $match: {
                    status: "New",
                    _asset: asset ? "#OBJID#" + asset._id + "#OBJIDEND#" : undefined,
                    end_dt: null
                }
            },
            {
                $group: {
                    _id: {
                        priority: "$priority"
                    },
                    cnt: {
                        $sum: 1
                    }
                }
            }
            ];
        } else if (fleet) {
            agg = [{
                $match: {
                    status: "New",
                    //_asset:fleet?{$in : fleet._assets}:undefined,
                    _asset: fleet._assets ? {
                        $in: fleet._assets.map(function (val) {
                            return "#OBJID#" + val + "#OBJIDEND#"
                        })
                    } : undefined,
                    end_dt: null
                }
            },
            {
                $group: {
                    _id: {
                        priority: "$priority"
                    },
                    cnt: {
                        $sum: 1
                    }
                }
            }
            ];
        } else if (company) {
            agg = [{
                $match: {
                    status: "New",
                    _company_owner: "#OBJID#" + $scope.client._company_owner._id + "#OBJIDEND#",
                    end_dt: null
                }
            },
            {
                $group: {
                    _id: {
                        priority: "$priority"
                    },
                    cnt: {
                        $sum: 1
                    }
                }
            }
            ];
        } else {
            $scope.selectedAsset = null;
            $scope.selectedFleet = null;
            $scope.selectedCompany = null;
            agg = [{
                $match: {
                    category: $scope.selectedCategory ? $scope.selectedCategory._id : undefined,
                    status: "New",
                    end_dt: null
                }
            },
            {
                $group: {
                    _id: {
                        priority: "$priority"
                    },
                    //data: {$push: "$$ROOT"},
                    cnt: {
                        $sum: 1
                    }
                }
            }
            ];
        }

        iaxeedMonitoringSrv.agg({
            aggregate: agg
        }, 10000, 0, function (res) {
            if (res && res.success) {


                $scope.processMonitoring(res.result)
            } else {
                Notification.error($translate.instant("SERVER_UNAVAILABLE"));
            }
        }, function (err) {
            if (err.status === -1) {
                Notification.error($translate.instant("IMPOSSIBLE_CNX"));
            } else if (err.status === 401) {
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS")
                });
            } else {
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("LOADING_ERROR")
                });
            }
        })
    };
    $scope.showDetails = function (installation) {
        $state.go("app.home.install.details", {
            id: installation._id
        });
    }
    $scope.downloadFile = function (file) {
  
        i3HelperSrv.downloadFile(file._document._id, file._document.original_name, function success(res) {

        }, function error(err) {

            Notification.error($translate.instant(err));
        }, function progress(evt) {
            console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '%');
        })
    };
    $scope.downloadZipGrouppedfile=function(pv){

        try{
        var params={_ids:[]};
        var comments=$translate.instant("PVS") +"\r\n";
        comments +="\t"+$translate.instant("CLIENT")+ " :" +pv.client_name+"\r\n";
        comments+="\t"+$translate.instant("PV_NUMBER")+ " :" +pv.pv_ref+"\r\n";
        comments+="\t"+$translate.instant("purchase_order_nbr")+ " :" +pv.purchase_order+"\r\n";
        comments +="\t"+$translate.instant("ASSET")+ " :" +pv._asset+"\r\n";
        comments+="-------------------------------------------------------------------------\r\n"; 
        comments+="\t- "+$translate.instant("DOCUMENTS")+ " :\r\n"; 
        if(pv._documents && pv._documents.length>0) {
          //  params.ids = {intervention: []}
          pv._documents.forEach(function (doc) {
              console.log(doc)
                if (doc._id)
                    params._ids.push(doc._id);
            })
            comments+="\t\t + pv : "+$translate.instant("DOCUMENTS")+" " +$translate.instant("PVS") +"\r\n";
        }
        
        params.comments=comments

            i3HelperSrv.downloadZip(params,"PV_"+pv._asset+"_"+pv.pv_ref+"_docs.zip",function(res){
   

            },function(err){
                console.log(err)
            },function(percent){
                console.log(percent)
            })
        }catch (e) {

            console.error(e)
        }
    }


});
