angular.module('app').controller('adminZonesCtrl', function ($scope, $rootScope, $state, $translate, $filter, Notification, ngDialog, I3PlateformeSrv,I3ZoneSrv) {

    $scope.zone = {};
    $scope.itemsByPage = 50;
    $scope.search = {};

    I3PlateformeSrv.query({}, 10000, 1, function (res) {
        if(res.success){
            $scope.platforms = res.result;
        }
    }, function (err) {
        console.log(err);
    });
    /**
     * Loading products
     */
    I3ZoneSrv.query({}, 10000, 1, function (response) {
        $scope.zones = response.result;
    }, function (err) {
        console.log(err);
    });

    /**
     * Getting users
     */
    $scope.loadZones  = function (tableState,ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }

        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;

        var options =  {
            populate: [
                {
                    path: '_plateforme',
                    select: 'name'
                }
            ],
            sortBy: {creation_dt: -1}
        };

        var query = {options:options};


        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
        }
        var requery = {};


        if (tableState.search && tableState.search.predicateObject) {
            if (tableState.search.predicateObject.name) {
                requery["name"] = {"$regex": tableState.search.predicateObject.name, "$options": "i"};
            }

              if (tableState.search.predicateObject.description ) {
                requery["description"] = {'$regex' :tableState.search.predicateObject.description, '$options' : 'i'}
            }
        }
        if($scope.search) {
            if ( $scope.search._plateforme) {
                requery["_plateforme"] = $scope.search._plateforme._id;
            }

        }

        query.query=requery;


        I3ZoneSrv.query(query , number, start, function (response) {
            if(response.success){
                $scope.zones = response.result;
                $scope.zoneCount = response.total_count;
                tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
                tableState.pagination.totalItemCount = response.total_count;
                $scope.tableState = tableState;
            }
        });
    };



    /**
     * Removing product
     * @param product
     */
    $scope.remove = function (zone) {
        $scope.zone = zone;

        ngDialog.open({
            templateUrl : "app/commons/dialogs/confirm/zone.delete.dlg.tpl.html",
            controller : "confirmDeleteZoneDlgCtrl",
            scope: $scope
        })

    };

});