angular.module('app').controller('adminStockAddCtrl', function ($scope, $timeout, $rootScope, $state, $translate, $filter, Notification, ngDialog, I3StockSrv, constantSrv) {

    $scope.stock = {};

    $scope.options = null;
    $scope.reverseTranslatedOptions = {};
    $scope.translatedOptions = null;
    $timeout(function () {}, 500).then(function () {
        if (($rootScope.checkAccess("PLATEFORME","R","O")) && $scope._ADMIN && $scope._ADMIN.platforms && $scope._ADMIN.platforms[0])
            $scope.stock._plateforme = $scope._ADMIN.platforms[0];
    });

    $scope.searchOption = function ($select) {

        if ($scope.options == null) {

            $scope.options = constantSrv.getProductsOptions();

            var reverseTranslatedOptions = {};
            angular.forEach($scope.options, function (option) {
                var translation = $translate.instant(option);
                reverseTranslatedOptions[translation] = option;
            });
            $scope.reverseTranslatedOptions = reverseTranslatedOptions;
        }

        // Translate back
        $scope.translatedOptions = [];

        angular.forEach($scope.options, function (option) {
            var translation = $translate.instant(option);
            $scope.translatedOptions.push(translation);
        });

    };

    $scope.save = function () {

        // Creating a new stock
        I3StockSrv.add($scope.stock, function (response) {
            // console.log(response);

            if (response.success) {
                Notification.success({
                    title: $translate.instant("STOCK_CREATED"),
                    message: $translate.instant("STOCK_ADDED_SUC")
                });
                $state.go('app.admin.stocks');

            } else {
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_CREATE") + " " + $scope.stock.name + "!"
                });
            }

        }, function (err) {

            console.log(err);

            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("ERROR_OCCURED_CREATE") + " " + $scope.stock.name + "!"
            });
            $scope.stock = {};
        })

    };
    /**
     * ADD stock_virtual
     */

    $scope.openAddStockDialogue = function () {

        ngDialog.open({
            templateUrl: "app/commons/dialogs/stocks/stocks.add.dlg.html",
            controller: "addStockVirtDlgCtrl",
            scope: $scope
        })
    };
    /**
     * delet stock_virtual
     */
    $scope.removeVstock = function (element, index) {
        $scope.stock.virtual_stock.splice(index, 1);
    };
    /**
     * editing stock_virtual
     */
    $scope.editVstock = function (vstock, index) {

        ngDialog.open({
            templateUrl: "app/commons/dialogs/stocks/stocks.edit.dlg.html",
            controller: "editStockVirtDlgCtrl",
            scope: $scope,
            resolve: {
                index: function () {
                    return index;
                }
            }
        })
    };
    /**
     * Cancel creating/editing stock
     */
    $scope.cancel = function () {
        $scope.stock = {};
        $state.go('app.admin.stocks');
    };

    /**
     * Add options
     */
    $scope.addOptions = function (opt) {

        if (opt) {

            if ($scope.options_list.indexOf(opt) >= 0) {
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("ALREADY_EXIST")
                });
            } else {

                //if($scope.reverseTranslatedOptions[opt] == $scope.options[$scope.translatedOptions.indexOf(opt)]){}

                $scope.options_list.push(opt);
                $scope.options.splice($scope.translatedOptions.indexOf(opt), 1);
                $scope.translatedOptions.splice($scope.translatedOptions.indexOf(opt), 1);

            }
        } else {
            Notification.error({
                title: $translate.instant("ERROR"),
                message: $translate.instant("SELECT_AN_OPTION")
            });
        }

        $scope.product.options = null;
    }

}).filter('translation', function () {
    return function (translatedObject) {

        var translatedOptions = [];

        for (key in translatedObject) {
            translatedOptions.push(translatedObject[key]);
        }
        return translatedOptions;

    }
});