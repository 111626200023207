

angular.module('app').controller('interventionRequestAddCtrl', function ($rootScope, $timeout, $scope,constantSrv, $translate, $state, $stateParams, I3UserSrv, I3ClaimSrv, I3InterventionSrv, Notification, I3DocumentSrv, iaxeedCompanySrv, i3HelperSrv, NGIFleetClientSrv, NGIFleetHelperSrv, plateformHelperSrv, NGIFleetAssetSrv, iaxeedAssetSrv) {
    $scope.dataToAdd = {}
    $scope.assets_interv = []
    $scope.emailFollow = "";
    $scope.com = [];
    $scope.content = "";
    $scope.files = [];
    $scope.comments = [];
    $scope.docs = [];
    $scope.followers = [];
    $scope.come = false;
    $scope.com = [];
    $scope.com3 = [];
    $scope.ResList=constantSrv.getResponsabilite();
 
    $scope.show_com = function (x) {
        $scope.com[x] = !$scope.com[x];
    };
    $scope.show_com3 = function (x) {
        $scope.com3[x] = !$scope.com3[x];
    };
    $scope.show_come = function () {
        $scope.come = !$scope.come;
    };
    $scope.show_come10 = function () {
        $scope.come10 = !$scope.come10;
    };
    $scope.show_come20 = function () {
        $scope.come20 = !$scope.come20;
    };

    $scope.users = [];
    $scope.assets = [];
    $scope.interventionRequest = {};

    $timeout(function () {}, 500).then(function () {
        if (($rootScope.checkAccess("PLATEFORME","R","O")) && $scope._HOME && $scope._HOME.platforms && $scope._HOME.platforms[0]){
            $scope.interventionRequest._plateforme = $scope._HOME.platforms[0];
            $scope.  selectPlateform($scope._HOME.platforms[0])
        }


    });
    i3HelperSrv.getEnums(function (res) {
        $scope.interventionRequest_type = res.INTERVENTION_TYPE.LIST ;

    }, function (err) {
        console.error(err)
    })
    $scope.getAssets = function (claim) {
        $scope.assets_list = [];
        $scope.assets_list_claim = [];
        if (claim) {
            $scope.assets_interv = [];
            $scope.assets_list = claim.asset;
            $scope.assets_list_claim = claim.asset
        } else {
            $scope.assets_interv = [];
        }
    }

    $scope.save = function (interventionR) {
        var interv = angular.copy(interventionR);
        interv.asset = []
        if (interventionR._claim && interventionR._claim._id) {
            interv._claim = interventionR._claim._id;
            interv._plateforme = interventionR._claim._plateforme
            interv.client_name = interventionR._claim.client_name
            interv._client_code = interventionR._claim._client_code
            interv.asset = $scope.assets_interv
        }

        interv._plateforme = interventionR._plateforme;
        interv.client_name = $scope.dataToAdd.client.name;
        interv.client_id = $scope.dataToAdd.client._id;
        interv._client_code = $scope.dataToAdd.client.client_code;
        interv.asset = $scope.assets_interv

        if (interventionR._assigned_to) {
            if (interventionR._assigned_to.length > 0 && interventionR._assigned_to[0]._id) {
                interv._assigned_to = [];
                angular.forEach(interventionR._assigned_to, function (user) {
                    interv._assigned_to.push(user._id);
                });
            } else {
                interv._assigned_to = []
            }
        }

        if ($scope.followers && $scope.followers.length > 0) {
            interv.followers = $scope.followers
        } else {
            interv.followers = []
        }

        if ($scope.comments && $scope.comments.length > 0) {
            interv.comments = $scope.comments
        } else {
            interv.comments = []
        }
        if ($scope.docs && $scope.docs.length > 0) {
            interv.docs = $scope.docs
        } else {
            interv.docs = []
        }
        I3InterventionSrv.add(interv, function (res) {
            if (res && res.success) {
                Notification.success({
                    message: $translate.instant("INTERVENTION_REQUEST_ADDED")
                });
                $state.go('app.home.interventionRequest');
            } else {
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("INTERVENTION_REQUEST_FAILED")
                });
            }
        }, function (err) {
            console.log(err);
        });
    };

    $scope.uploadFiles = function (files, errFiles) {
        $scope.files = files;
        $scope.errFiles = errFiles;
        i3HelperSrv.uploadFiles(files, {
            _plateforme: $scope.interventionRequest._plateforme && $scope.interventionRequest._plateforme._id ? $scope.interventionRequest._plateforme._id : $scope.interventionRequest._plateforme,
            related_model: [{
                model: "INTERVENTION",
                model_id: $scope.interventionRequest._id
            }]
        }, function (res) {
            angular.forEach(res.result, function (file) {
                $scope.docs.push(file)
            });
            //file.result = res.data;
        }, function (response) {
            if (response.status)
                $scope.errorMsg = response.status + ': ' + response.data;
        }, function (evt) {

        });
    }

    $scope.downloadFile = function (file) {
        i3HelperSrv.downloadFile(file._id, file.original_name, function success(res) {

        }, function error(err) {
            Notification.error($translate.instant(err));
        }, function progress(evt) {
            file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
            console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '%');
        })
    };
    $scope.show_com = function (x) {
        $scope.com[x] = !$scope.com[x];
    }

    $scope.addComment2 = function (msg) {
        if (msg) {
            var commentAdded = {};
            commentAdded.date = new Date();
            commentAdded.owner = $rootScope.SESSION.user;
            console.log("TCL: $rootScope.SESSION.user", $rootScope.SESSION.user)
            commentAdded.msg = msg;
            commentAdded.new = true;
            $scope.comments.push(commentAdded);
        }
        $scope.content2 = "";

    };

    $scope.addFollower = function () {
        if ($scope.emailFollow && $scope.emailFollow !== "") {
            var follower = {};
            follower.email = $scope.emailFollow;

            var query = {
                "contact.mail": $scope.emailFollow
            };
            var options = {};
            var exist = false;
            angular.forEach($scope.followers, function (elem) {
                if (elem.email.toLowerCase() === $scope.emailFollow.toLowerCase()) {
                    exist = true;
                }
            });
            if (!exist) {
                console.log($scope.followers)
                I3UserSrv.query({
                    query: query,
                    options: options
                }, 10000, 0, function (res) {
                    if (res.success) {
                        follower.user = res.result[0];
                    }
                    $scope.followers.push(follower);
                    $scope.emailFollow = "";
                });
            } else {
                Notification.warning({
                    title: "Already follower",
                    message: "This email is already subscribed as a follower here"
                });
                $scope.emailFollow = "";
            }
        }

    };
    $scope.addAssets = function (asset) {
        if (asset) {
            if ($scope.assets_interv.indexOf(asset) >= 0) {
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("ALREADY_EXIST")
                });
            } else {
                $scope.assets_interv.push(asset);
                angular.forEach($scope.assets_list, function (item, index) {
                    console.log(index, item)
                    if (item.asset_id === asset.asset_id) {
                        $scope.assets_list.splice(index, 1)
                    }
                })
            }
        } else {
            Notification.error({
                title: $translate.instant("ERROR"),
                message: $translate.instant("SELECT_AN_ASSET")
            });
        }
        $scope.dataToAdd.assets_list = null;
    };

    $scope.removeAssets = function (asset, index) {
        $scope.assets_interv.splice(index, 1);
        $scope.assets_list.push(asset);
    };

    $scope.follow = function () {
        if ($scope.emailFollow && $scope.emailFollow !== "") {
            var follower = {};
            if (!$scope.intervention.followers) {
                $scope.intervention.followers = [];
            }

            follower.email = $scope.emailFollow;

            var query = {
                "contact.mail": $scope.emailFollow
            };
            var options = {};

            var exist = false;

            angular.forEach($scope.interventionRequest.followers, function (follower) {
                if (follower.email.toLowerCase() === $scope.emailFollow.toLowerCase()) {
                    exist = true;
                }
            });

            if (!exist) {
                I3UserSrv.query({
                    query: query,
                    options: options
                }, 10000, 0, function (res) {
                    if (res.success) {
                        follower.user = res.result[0];
                    }
                    $scope.followers.push(follower);
                    $scope.emailFollow = "";

                });
            } else {
                Notification.warning({
                    title: "Already follower",
                    message: "This email is already subscribed as a follower here"
                });
                $scope.emailFollow = "";
            }

        }

    };

    $scope.selectPlateform = function (platform) {

        $scope.clients = [];
        $scope.dataToAdd.client = null;
        if ($scope.interventionRequest._plateforme && $scope.interventionRequest._plateforme.type) {
            if ($scope.interventionRequest._plateforme.type === 'NGI_FLEET') {

                NGIFleetHelperSrv.setPlateFormAndLogin(platform, function (res) {

                }, function (err) {
                    console.log(err);
                })
            } else {
                plateformHelperSrv.setPlateFormAndLogin(platform, function (res) {
                    if (res && res.result) {
                        $scope.loginPlateform = res.result
                    } else {
                        Notification.error({
                            title: $translate.instant("ERROR"),
                            message: $translate.instant("PLATFORM_CNX_ERROR")
                        });

                    }

                }, function (err) {
                    console.log(err);
                })
            }

        }
        $scope.assets_interv = [];
        $scope.interventionRequest.client_name = null;
        $scope.interventionRequest.asset = null;
        $scope.interventionRequest._claim = null
    }
    $scope.searchClient = function ($select) {

        if ($scope.interventionRequest._plateforme && $scope.interventionRequest._plateforme.type) {
            if ($scope.interventionRequest._plateforme.type === 'NGI_FLEET') {
                NGIFleetClientSrv.getClient($select.search, 10, 0, function (res) {
                    $scope.clients = res.result;

                }, function (err) {
                    console.log("err", err)
                })
            } else {
                if ($scope.loginPlateform && $scope.loginPlateform.token) {
                    iaxeedCompanySrv.query({
                        query: {
                            name: {
                                '$regex': $select.search,
                                '$options': 'i'
                            }
                        }
                    }, 10, 0, function (res) {
                        $scope.clients = res.result;

                    }, function (err) {
                        console.log("err", err)
                    })
                }
            }
        }

    };
    $scope.searchClaim = function ($select) {

        if ($scope.interventionRequest._plateforme && $scope.interventionRequest._plateforme.type && $scope.dataToAdd.client && $scope.dataToAdd.client.name) {
            I3ClaimSrv.query({
                query: {
                    client_name: $scope.dataToAdd.client.name,
                    object: {
                        '$regex': $select.search,
                        '$options': 'i'
                    }
                }
            }, 10, 0, function (res) {
                if (res.success) {
                    $scope._INTERVENTION_REQUEST.filteredClaims = res.result;
                }
            }, function (err) {
                console.log(err);
            });
        }
    };

    $scope.removeFollower = function (follower) {
        if ($scope.followers.indexOf(follower) >= 0) {
            $scope.followers.splice($scope.followers.indexOf(follower), 1);

        }
    };
    $scope.searchAsset = function ($select) {
        if ($scope.interventionRequest._claim) {
            $scope.assets_list = $scope.assets_list_claim
        } else {
            if ($scope.interventionRequest._plateforme && $scope.interventionRequest._plateforme.type && $scope.dataToAdd.client) {
                if ($scope.interventionRequest._plateforme.type === 'NGI_FLEET') {
                    NGIFleetAssetSrv.getAssetByClient($scope.dataToAdd.client._id, $select.search, 10, 0, function (res) {
                        $scope.assets_list = res.result;
                    }, function (err) {
                        console.log("err", err)
                    })
                } else {
                    iaxeedAssetSrv.query({
                        query: {
                            _company_owner: $scope.dataToAdd.client._id,
                            name: {
                                '$regex': $select.search,
                                '$options': 'i'
                            },
                            _id: {
                                $nin: $scope.assets_interv.map(function (val) {
                                    if (val) {
                                        return {
                                            '_id': val.asset_id
                                        };
                                    }
                                })
                            }
                        }
                    }, 10, 0, function (res) {
                        $scope.assets_list = res.result.map(function (asset) {
                            return {
                                'asset_name': asset.name,
                                'asset_id': asset._id
                            }
                        });
                    }, function (err) {

                    })
                }
            }

        }
    };
    $scope.selectClient = function () {

        $scope.assets_list = [];
        $scope.interventionRequest.asset_name = null;

        I3ClaimSrv.query({
            query: {
                client_name: $scope.dataToAdd.client.name
            }
        }, 10, 0, function (res) {
            if (res.success) {
                $scope._INTERVENTION_REQUEST.filteredClaims = res.result;
            }
        }, function (err) {
            console.log(err);
        });


            $scope.interventionRequest._claim = null;
          

    }

});