angular.module('app').controller('adminClientAddCtrl', function ($scope, $rootScope, accessSrv, I3ClientSrv, $state, $translate, I3UserSrv, i3HelperSrv, I3RoleSrv, ngDialog, Notification, constantSrv) {

    $scope.client = {}
    $scope.subclient = false




    $scope.save = function (client) {
        client.client_data = { contact: client.contact }
        client.SUB_CLIENT = $scope.subclient
        if (client.SUB_CLIENT == true && client._subClients) {
            delete client._subClients
        }
        I3ClientSrv.add(client, function (response) {
            if (response.success) {
                Notification.success({
                    title: $translate.instant("CLIENT_CREATED"),
                    message: $translate.instant("CLIENT_ADDED_SUC")
                });
                if (client._subClients && client._subClients.length > 0) {

                    for (let index = 0; index < client._subClients.length; index++) {
                        const element = client._subClients[index];
                        element.client_group = client.name
                        I3ClientSrv.updateById(element._id, element, function (res) {
                            if (res.success) {

                            }

                        })
                    }

                    $state.go('app.admin.clients');




                } else {
                    $state.go('app.admin.clients');


                }
                //    $state.go('app.admin.clients');

            } else {
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_CREATE") + " " + client.name + "!"
                });
            }

        }, function (err) {

            console.log(err);

            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("ERROR_OCCURED_CREATE") + " " + client.name + "!"
            });
            $scope.client = {};
        })



    }

    $scope.cancel = function () {

        $state.go('app.admin.clients');
    };


    $scope.openStatusDialogue = function () {
        ngDialog.open({
            templateUrl: "app/commons/dialogs/subClient/subClient.add.dlg.html",
            controller: "addSubClientDlgCtrl",
            scope: $scope,

        })


    }
    $scope.removeCustomer = function (cli, index) {

        $scope.client._subClients.splice(index, 1);

    }
    $scope.verifSubCLient = function (subClient) {


        if (subClient && subClient == false) {
            if ($scope.client._subClients) {
                $scope.client._subClients = []
            }
        }

    }

})