angular
    .module('app')
    .controller('interventionCtrl', function ($scope, $translate, $stateParams, I3InterventionSrv, I3UserSrv, I3ClaimSrv, Notification, messageBox, constantSrv, iaxeedCompanySrv, ngDialog) {
        $scope.interventions = [];
        $scope.users = [];
        $scope._INTERVENTION = {};
        $scope.statusLoaded = false;
        var i = 0;
        $scope.disab_input_status = ["VALIDATED"];
        $scope.statusInstallFront = ["READY", "Planned", "InProgress", "Done", "Postponed", "VALIDATED", "Cancel"],
            $scope.deniedStatusTransition = {
                New: ["New", "READY", "Planned", "InProgress", "Done", "Postponed", "VALIDATED", "Cancel"],
                READY: ["New", "VALIDATED", "Cancel"],
                Planned: ["New", "READY", "Planned", "VALIDATED", "Cancel"],
                InProgress: ["New", "READY", "Planned", "VALIDATED", "Cancel"],
                Done: ["New", "READY", "Planned", "InProgress", "Done", "Postponed", "VALIDATED", "Cancel"],
                Postponed: ["New", "READY", "Postponed", "VALIDATED", "Cancel"],

                VALIDATED: ["New", "READY", "Planned", "InProgress", "Done", "Postponed", "VALIDATED", "Cancel"],
                Cancel: ["New", "VALIDATED", "Cancel"],
            }
        constantSrv.getModel("INTERVENTION").getAllStatus(function (err, status) {

            $scope._INTERVENTION.statusList = status.map(function (val) {
                return val.name
            })
        })

        constantSrv.getModel("INTERVENTION").getAllStatus(function (err, status) {
            $scope.interventionStatus = status.map(function (val) {
                return val.name
            })

            $scope.interventionStatus.splice('New', 1)
            //$scope.interventionStatus.push('NotClosed');
            $scope.statusLoaded = true;
            console.log('$scope.interventionStatus', $scope.interventionStatus)
        })
        //  $scope._INTERVENTION.statusList = constantSrv.getInterventionStatus();
        $scope.search = {};
        $scope.claims = [];
        $scope.itemsByPage = 50;
        if ($stateParams.filter)
            $scope.search.interventionStatus = $stateParams.filter;
        $scope.predefinedClaimStatus = constantSrv.getPredefinedStatus();
        //  $scope.states = constantSrv.getInterventionStatus();
        //   $scope.interventionStatus = constantSrv.getInterventionStatus();
        //$scope.interventionStatus.push('NotClosed');
        $scope.moment = moment;
        var now = moment().clone();
        $scope.dayRes = now;
        $scope.pickerDay = {
            format: "DD/MM/YYYY",
            position: "top left",
            init: null,
            autoTrans: false,
            enableTime: false,
            enableDate: true,
            enableMinutes: false,
            defaultView: "DATE",
            maxDate: moment("2020/05/22", "YYYY/MM/DD"),
            minDate: now,
            getDate: function (date) {
                $scope.dayRes = date;
                $scope.installationRequest.preferred_dt = date;
            }
        };
        $scope.open2 = function () {
            $scope.popup2.opened = true;
        };
        $scope.popup2 = {
            opened: false
        };
        $scope.opened = {};
        $scope.open = function ($event, elementOpened) {
            $event.preventDefault();
            $event.stopPropagation();
            $scope.opened[elementOpened] = !$scope.opened[elementOpened];
        };
        $scope.openStatusDialog = function (intervention) {
            console.log(intervention)
            console.log(intervention._assigned_to)

            constantSrv.getModel("INTERVENTION").getAvailableStatusByStatus(intervention.status, function (err, val) {
                $scope.states = val._availStatus.map(function (elem) {
                    return elem.name
                }).filter(function (e) {
                    return ($scope.deniedStatusTransition[intervention.status] || []).indexOf(e) < 0;
                })
                $scope.states.unshift(intervention.status)
                console.log("  $scope.states", $scope.states)
            })

            var dialog = ngDialog.open({
                template: "app/commons/dialogs/intervention/status.dlg.tpl.html",
                className: 'ngdialog-theme-default custom-width-500',
                appendClassName: 'ngdialog-custom',
                controller: "statusDlgCtrl",
                scope: $scope,
                data: {
                    prop: intervention
                }
            });
            dialog.closePromise.then(function (datax) {
                console.log(datax.value[0]);
                console.log(datax.value[1]);
                if (datax && datax.value) {
                    if ($scope.states.indexOf(datax.value[0]) != -1) {
                        intervention.status = datax.value[0];
                        intervention._assigned_to = datax.value[1];
                        $scope.updateInterventionProp('status', intervention, datax.value);
                    }
                }
            });
        }
        $scope.openBeginDateDialog = function (intervention) {
            var dialog = ngDialog.open({
                template: "app/commons/dialogs/intervention/begin_date.dlg.tpl.html",
                className: 'ngdialog-theme-default custom-width-500',
                appendClassName: 'ngdialog-custom',
                controller: "beginDateDlgCtrl",
                scope: $scope,
                data: {
                    prop: intervention
                }
            });
            dialog.closePromise.then(function (datax) {
                if (datax && datax.value) {
                    if (datax.value._isValid) {
                        //intervention.begin_dt = datax.value;
                        $scope.updateInterventionProp('begin_dt', intervention, datax.value);
                    }
                }
            });
        }

        $scope.openEndDateDialog = function (intervention) {

            var dialog = ngDialog.open({
                template: "app/commons/dialogs/intervention/end_date.dlg.tpl.html",
                className: 'ngdialog-theme-default custom-width-500',
                appendClassName: 'ngdialog-custom',
                controller: "endDateDlgCtrl",
                scope: $scope,
                data: {
                    prop: intervention
                }
            });
            dialog.closePromise.then(function (datax) {
                if (datax && datax.value) {
                    if (datax.value._isValid) {
                        //intervention.begin_dt = datax.value;
                        $scope.updateInterventionProp('end_dt', intervention, datax.value);
                    }
                }
            });
        }
        /**
         * Removing intervention
         * @param intervention
         */
        $scope.remove = function (intervention) {
            messageBox.confirm("DELETE_INTERVENTION_TITLE", "DELETE_INTERVENTION_MESSAGE").then(function (res) {
                if (res) {
                    I3InterventionSrv.delete(intervention._id, function (res) {
                        if (res && res.success) {
                            Notification.success({
                                title: $translate.instant("DELETE_SUCC"),
                                message: $translate.instant("INTERVENTION_DELETED")
                            });
                            if ($scope.stCtrl) {
                                $scope.stCtrl.pipe();
                            }
                        } else {
                            Notification.warn({
                                title: $translate.instant("DELETE_ERROR"),
                                message: $translate.instant("INTERVENTION_DELETE_ERROR")
                            });
                        }
                    }, function (err) {
                        Notification.error({
                            title: $translate.instant("DELETE_ERROR"),
                            message: $translate.instant("INTERVENTION_DELETE_ERROR")
                        });
                    })
                }
            }, function (reject) {
                //Notification.error($translate.instant("INSTALLATION_REQUEST_DELETE_ERROR"));
            })
        };
        /**
         * Loading intervention list
         */
        $scope.loadInterventions = function (tableState, ctrl) {
            if (!$scope.stCtrl && ctrl) {
                $scope.stCtrl = ctrl;
            }
            if (!tableState && $scope.stCtrl) {
                $scope.stCtrl.pipe();
                return;
            }
            var start = 0;
            var number = $scope.itemsByPage;
            if (tableState.pagination) {
                var pagination = tableState.pagination;
                number = pagination.number || $scope.itemsByPage;
                start = pagination.start / number + 1 || 1;
            }
            var options = {
                populate: [
                    "_assigned_to",
                    "_claim",
                    "_created_by", "_plateforme",
                    {
                        path: '_claim',
                        populate: ['_created_by']
                    }
                ],
                sortBy: {
                    creation_dt: -1
                }
            };
            //  var query = {};
            var query = {
                options: options
            };
            var requery = {};
            requery['status'] = {
                "$in": $scope.statusInstallFront
            };
            if ($scope.search) {
                if ($scope.search._plateforme) {
                    requery["_plateforme"] = $scope.search._plateforme._id;
                }
                if ($scope.search.client) {
                    requery["client_name"] = $scope.search.client.client_name;
                }

                if ($scope.search.asset) {

                    requery["asset.asset_name"] = {
                        '$regex': $scope.search.asset,
                        '$options': 'i'
                    };
                }

                if ($scope.search.purchase_order) {

                    requery["purchase_order"] = {
                        '$regex': $scope.search.purchase_order,
                        '$options': 'i'
                    };
                }

                if ($scope.search.interventionStatus) {

                    requery["status"] = $scope.search.interventionStatus;

                }

            }
            if (tableState.sort && Object.keys(tableState.sort).length > 0) {
                if (tableState.sort.predicate == "creation_dt") {
                    query.options.sortBy.creation_dt = tableState.sort.reverse ? -1 : 1;

                }
            }
            query.query = requery;
            I3InterventionSrv.query(query, number, start, function (res) {
                if (res.success) {
                    $scope.interventions = res.result;
                    console.log("$scope.loadInterventions -> $scope.interventions", $scope.interventions)
                    $scope.interventionsCount = res.total_count;
                    tableState.pagination.numberOfPages = Math.ceil(res.total_count / number);
                    tableState.pagination.totalItemCount = res.total_count;
                    $scope.tableState = tableState;
                    if (($stateParams.platform) && (i == 0)) {
                        var elementPos = $scope._HOME.platforms.map(function (plat) {
                            return plat._id;
                        }).indexOf($stateParams.platform);

                        $scope.search._plateforme = $scope._HOME.platforms[elementPos];
                        i = 1;
                    }
                }
            }, function (err) {
                console.log(err);
            })
        };
        /**
         * Loading users list
         */
        I3UserSrv.query({}, 10000, 1, function (res) {
            if (res.success) {
                $scope._INTERVENTION.users = res.result;
            }
        }, function (err) {
            console.log(err);
        });
        I3ClaimSrv.query({}, 10000, 1, function (res) {
            if (res.success) {
                $scope.claims = res.result;
                $scope._INTERVENTION.claims = res.result;
            }
        }, function (err) {
            console.log(err);
        });
        //client_name:{$first:'$client_name'}
        $scope.searchClientId = function ($select) {
            var query = [{
                    $match: {
                        client_name: {
                            '$regex': $select.search,
                            '$options': 'i'
                        }
                    }
                },
                {
                    $group: {
                        _id: '$client_id',

                        client_name: {
                            $first: '$client_name'
                        }
                    }
                }
            ];
            I3InterventionSrv.agg({
                aggregate: query
            }, 1000, 0, function (res) {
                $scope.clients = res.result;
                //console.log(res)
            }, function (err) {})
        };
        $scope.searchAssetName = function ($select) {
            var query = [{
                    $match: {
                        "asset.asset_name": {
                            '$regex': $select.search,
                            '$options': 'i'
                        }
                    }
                },
                {
                    $unwind: "$asset"
                },
                {
                    $group: {
                        _id: "$asset.asset_name",
                        intervention: {
                            $push: '$_id'
                        }
                    }
                }
            ];
            I3InterventionSrv.agg({
                aggregate: query
            }, 1000, 0, function (res) {
                $scope.assets = res.result;
                console.log("$scope.searchAssetName -> $scope.assets", $scope.assets)
                //console.log(res)
            }, function (err) {})
        };
        $scope.updateInterventionProp = function (prop, intervention, $data) {
            console.log("TCL: $scope.updateInterventionProp -> $data", $data)
            var updateData = {};
            if (prop === "status") {
                if ($data[0] === "Planned" && !updateData.planned_begin_dt) {
                    updateData.planned_begin_dt = new Date();
                    intervention.planned_begin_dt = new Date();
                } else if ($data[0] === "InProgress" && !updateData.begin_dt) {
                    updateData.begin_dt = new Date();
                    intervention.begin_dt = new Date();
                } else if ($data[0] === "Done" && !updateData.end_dt) {
                    updateData.end_dt = new Date();
                    intervention.end_dt = new Date();

                }
            }
            if (prop === "planned_begin_dt" && intervention.status === "New") {
                updateData.status = "Planned";
                intervention.status = "Planned"
            }
            if (prop === "begin_dt" && intervention.status !== "InProgress" && intervention.status !== "Done") {
                updateData.status = "InProgress";
                intervention.status = "InProgress"
            }
            if (prop === "end_dt" && intervention.status !== "Done") {
                updateData.status = "Done";
                intervention.status = "Done";
            }

            if (prop !== "status") {
                updateData[prop] = $data;
            } else {
                updateData["status"] = $data[0];
                if ($data[0] === "Done") {
                    updateData["_assigned_to"] = $data[1];
                }
            }

            console.log(updateData);

            if (prop === "asset") {
                updateData = {
                    asset: $data._id,
                    asset_name: $data.name
                };
                intervention.asset_name = $data.name;
            }
            I3InterventionSrv.updateById(intervention._id, updateData, function (res) {
                if (res && res.success) {
                    $scope.loadInterventions();
                    Notification.success({
                        title: $translate.instant("UPDATE_SUCC"),
                        message: $translate.instant("INTERVENTION_UPDATED")
                    })
                } else {
                    Notification.warn({
                        title: $translate.instant("UPDATE_ERROR"),
                        message: $translate.instant("INTERVENTION_UPDATE_ERROR")
                    })
                }
            }, function (err) {
                Notification.error({
                    title: $translate.instant("UPDATE_ERROR"),
                    message: $translate.instant("INTERVENTION_UPDATE_ERROR")
                });
            })
        };
        $scope.removeStatus = function (predefined) {
            var index = $scope.predefinedClaimStatus.indexOf(predefined);
            if (index > -1) {
                $scope.predefinedClaimStatus.splice(index, 1)
                $scope.loadInterventions();
            }
        };
        /* if (!$scope.search.interventionStatus && $scope.search.interventionStatus !== null) {
             $scope.search.interventionStatus = "NotClosed"
         }*/
    });