angular.module("app").controller("deliveryRequestCtrl", function ($scope, $rootScope, $filter, $state, $translate, ngDialog, Notification,constantSrv,I3DeliveryRequestSrv,I3UserSrv) {

    $scope.deliveryRequests=[];  
   // $scope.statusList=["NEW","READY_TO_DELIVER","DELIVRE","STOCK_UNAVAILABLE","CANCELED"];
    $scope.search = {};
    $scope.itemsByPage = 50;
    constantSrv.getModel("DELIVERY_REQUEST").getAllStatus(function (err, stat) {

        $scope.statusList = stat.map(function (val) {
            return val.name
        })
    })
    $scope.find_user=function($select){
        var query={
            query: {$or:[
                {
                    last_name: {
                    '$regex': $select.search,
                    '$options': 'i'
                 }
                },
                {
                    first_name: {
                    '$regex': $select.search,
                    '$options': 'i'
                  }
                }
             ]
        }
    }
    I3UserSrv.query(query,10,0,function(res){
            if(res && res.success){
                $scope.users=res.result;
            }else{
                $scope.users=[];
            }
        },function(err){
            console.log(err);
        })
    }
$scope.loadDeliveryRequest =  function (tableState, ctrl){
    if (!$scope.stCtrl && ctrl) {
        $scope.stCtrl = ctrl;
    }
    if (!tableState && $scope.stCtrl) {
        $scope.stCtrl.pipe();
        return;
    }
    var start = 0;
    var number = $scope.itemsByPage;
    var options = {
        populate: [{
            path: '_plateforme',
        select: 'name'
    }, {
        path: "products._product",
  
    },{
        path: "created_by",
        select: 'first_name last_name'
    }],
        sortBy: {creation_dt: -1}
    };
 
             
     

    
    var query = {options: options};
    if (tableState.pagination) {
        var pagination = tableState.pagination;
        number = pagination.number || $scope.itemsByPage;
        start = pagination.start / number + 1 || 1;
    }
 
 
    var requery = {};
  
    if ($scope.search) {
      
      
        if ($scope.search._product) {
          
            requery["products._product"] = $scope.search._product._id;
 
        }
        if ($scope.search._plateforme) {
        
            requery["_plateforme"] = $scope.search._plateforme._id;
 
        }
        if ($scope.search.client) {
        
            requery["client"] = {'$regex' :$scope.search.client, '$options' : 'i'};
 
        }
        if ($scope.search.asset_name) {
        
            requery["asset_name"] = {'$regex' :$scope.search.asset_name, '$options' : 'i'};
 
        }
        if ( $scope.search.status) {
            requery["status"] ={'$regex' :$scope.search.status, '$options' : 'i'};

        }
        if ( $scope.search._created_by) {
            requery["created_by"] =$scope.search._created_by._id?$scope.search._created_by._id:$scope.search._created_by;

        }
    }
    if(tableState.sort && Object.keys(tableState.sort).length>0){
        if(tableState.sort.predicate =="creation_dt"){
            query. options.sortBy.creation_dt=tableState.sort.reverse?-1:1;

        }
    }
 
    query.query = requery;
  
 
    I3DeliveryRequestSrv.query(query, number, start,
        function (data) {
 
            if (data.success) {
                $scope.deliveryRequests = data.result;
      console.log( $scope.deliveryRequests )
                $scope.deliveryRequestsCount = data.total_count;
                tableState.pagination.numberOfPages = Math.ceil(data.total_count / number);
                tableState.pagination.totalItemCount = data.total_count;
                $scope.tableState = tableState;
            } else {
                console.error(data.error)
            }
        },
        function (err) {
            console.log("err", err)
        })





}



})