angular.module('app.dialogs').controller('addStatusDlgCtrl', function ($scope) {
    $scope.statu={}
   
    if(!$scope.status.status)
        $scope.status.status=[];
    $scope.addstatus = function () {
        $scope.status.status.push($scope.statu);
        // console.log( $scope.status.status)
        $scope.closeThisDialog();
    };
});