angular.module('app.directives').directive('dateField', function ($rootScope, $state) {
    return {
        templateUrl: "app/commons/directives/date/date.tpl.html",
        scope: {
            default: "=",
            type: "@"
        },
        link: function (scope, elem, attr) {
            //  scope.dayRes = null;
            var required= false;


            if("required" in attr ){
                required=true;
            }
            var format = "DD/MM/YYYY"
            var enableDate = true
            var defaultView = "DATE"
            var maxDate = moment();
            if (scope.type == "billing") {
                format = "MM/YYYY"
                enableDate = false
                defaultView = "MONTH"
                maxDate = null;
            }
            if (scope.type == "planned_Date") {
                maxDate = null;
            }

            if (scope.type == "intended_date") {
                maxDate = null;
            }

            scope.pickerDay = {
                required:required,
                format: format,
                position: "top",
                autoTrans: false,
                enableTime: false,
                enableDate: enableDate,
                enableMinutes: false,
                defaultView: defaultView,
                getDate: function (date) {
                    scope.dayRes = date;
                    scope.default = date;
                    scope.pickerDay.maxDate = maxDate;
                    if(scope.required){
                         if(date==null){
                             ($(elem).find(".udatetime input")).addClass("error")
                            // console.log(elem)
                         }else{
                            //  console.log("",elem)
                         }
                    }
                }
            };
            if (scope.default) {
                scope.pickerDay.init = moment(scope.default);
            } else {
                if (scope.type == "billing")
                    scope.pickerDay.init = moment().add(1, 'M').startOf('month');
                else if (scope.type == "intended_date") {
                    scope.pickerDay.init = null;
                } else
                    scope.pickerDay.init = moment();
            }


        }

    }
});