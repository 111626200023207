angular.module("app.directives").directive('uniqueValidation', function ($rootScope,$state, i3HelperSrv, ngDialog) {
    return {
        require: 'ngModel',
        link: function (scope, elem, attr,ngModel) {

            scope.$watch(attr.uniqueValidation,function(){
                ngModel.$setValidity('unique',scope[attr.uniqueValidation]);
            })

        }
    }
})