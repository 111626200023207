angular.module('app').controller('compatibilityRequestEditCtrl', function ($scope, $state, $timeout, $translate, $rootScope, $stateParams, messageBox, I3ClientSrv, I3UserSrv, Notification, I3CompatibilitySrv, constantSrv, $location, $anchorScroll, ngDialog) {
    //#region scopes
    //vars and scopes
    var compatibilityRequestSaved;
    $scope.compatibilityRequestStatus = [];
    $scope.compatibilityRequestStatus_available = [];
    $scope.com = [];
    $scope.loading = false;
    $scope.auto = false;
    $scope.selected = null;
    $scope.previous = null;
    $scope.user = "";
    $scope.content = "";
    $scope.filename = "";
    $scope.selectedIndex = 0;
    $scope.currentPage = 0;
    $scope.pageSize = 25;
    $scope.current_asset = {};
    $scope.numberOfPages = 1;
    $scope.disab_input_status = ["CANCELED", "CLOSED", "DELETED"];
    $scope.input_active = true;
    $scope.filterParams = {
        NEW: true,
        VALIDATED: true
    };
    $scope.clients=[]
    //#endregion
    //#region initial data
    //get user login
    I3UserSrv.getById($rootScope.SESSION.user._id, function (response) {
        $scope.user = response.result.login;
    }, function (err) {
        console.log(err);
    });
    //$scope.statusFilter = constantSrv.getCompatibilityAssetStatus();
    //compatibility Request Status constant
    constantSrv.getModel("COMPATIBILITY").getAllStatus(function (err, status) {
        $scope.compatibilityRequestStatus = status.map(function (val) {
            return val.name
        });
    });
    

    //compatibility_request Available Status constant
    //get the Compatibility_requeest data
    $scope.getCompatibility = function () {
        var query = {
            _id: $stateParams.compatibilityId
        };
        var options = {
            populate: [{
                path: '_plateforme',
                select: 'name'
            },
            {
                path: 'clientSav_id'

            },
            {
                path: 'assets.place_id',
                select: 'name'
            }, {
                path: 'assets.comments._user',
                select: 'login'
            }]
        };
        I3CompatibilitySrv.query({
            query: query,
            options: options
        }, 1, 0, function (data) {
            $scope.compatibilityRequest = {};
            if (data.success) {
                $scope.compatibilityRequest = data.result[0];
                I3ClientSrv.query({_plateforme: $scope.compatibilityRequest._plateforme._id},10000,1,function(response){
                        if (response && response.result.length>0){
                        $scope.clients=response.result
                        }

                })

                $scope.compatibilityRequest.client = $scope.compatibilityRequest.clientSav_id || $scope.compatibilityRequest.client
                if ($scope.disab_input_status.indexOf($scope.compatibilityRequest.status) == -1) {
                    $scope.input_active = true;
                } else {
                    $scope.input_active = false;
                }
                $scope.compatibilityRequest.save_state = 'saved';
                compatibilityRequestSaved = angular.copy($scope.compatibilityRequest);
                $scope.reset_comp = angular.copy($scope.compatibilityRequest);
                constantSrv.getModel("COMPATIBILITY").getAvailableStatusByStatus(compatibilityRequestSaved.status, function (err, val) {
                    $scope.compatibilityRequestStatus_available = val._availStatus.map(function (elem) {
                        return elem.name
                    })
                });
                $scope.current_asset = angular.copy(compatibilityRequestSaved.assets[0]);
                $scope.reset_asset = angular.copy(compatibilityRequestSaved.assets[0]);
            } else {
                console.log(data.error)
            }
        }, function (error) {
            if (error) {
                console.log(error);
                $state.go("app.home.compatibilityRequest")
            }
        });
    }();
    //#endregion
    //#region assetslide and filters
    /* $scope.numberOfPages = function () {
        if ($scope.fname_fstatus_asset)
            return Math.floor((($scope.fname_fstatus_asset.length - 1) / $scope.pageSize));
        else return 1
    }; */
    //set currentPage to next
    $scope.nextpage = function () {
        //$scope.auto = false;
        $scope.selectedIndex = 0;
        $scope.currentPage = $scope.currentPage + 1;
    };
    //set currentPage to previous
    $scope.previouspage = function () {
        //$scope.auto = false;
        $scope.selectedIndex = 0;
        $scope.currentPage = $scope.currentPage - 1;
    };
    //reset current page and selectedIndex after search
    $scope.resetindex = function () {
        if ($scope.namev && $scope.namev.trim() != '') {
            $scope.currentPage = 0;
            $scope.selectedIndex = 0;
        }
    };
    //watch for asset results change for showing the progress bar
    $scope.$watch('limited_fname_fstatus_asset[selectedIndex]', function (newVal, oldVal) {
        if ($scope.limited_fname_fstatus_asset && $scope.limited_fname_fstatus_asset[$scope.selectedIndex]) {
            //console.log(newVal);
            $scope.current_asset = angular.copy(newVal);
            $scope.reset_asset = angular.copy(newVal);
        }
    });
    //watch for asset results change for showing the progress bar
    $scope.$watch('fname_fstatus_asset.length', function (newVal, oldVal) {
        console.log(newVal, oldVal)
        //$scope.loading = true;
        if ($scope.fname_fstatus_asset) {
            $scope.numberOfPages = Math.floor(((newVal - 1) / $scope.pageSize));
            console.log("TCL: $scope.numberOfPages", $scope.numberOfPages)
        } else {
            $scope.numberOfPages = 1;
        }
        /*  $timeout(function () {}, 0).then(function () {
             $scope.loading = false;
         }); */
    });
    //can't disable all asset status and reset current page and selectedIndex after availables status change
    $(":checkbox").click(function () {
        $scope.currentPage = 0;
        $scope.selectedIndex = 0;
        if ($("input:checked").length == 1) {
            $(':checked').prop('disabled', true);
        } else {
            $(':checked').prop('disabled', false);
        }
    });
    //filter asset by name
    $scope.name_filter = function (asset) {
        if ($scope.namev && $scope.namev.trim() != '')
            return (asset.name.toLowerCase().indexOf($scope.namev.toLowerCase()) > -1)
        else
            return (true)
    };
    //filter assets (with a searched term) by status
    $scope.filterStatus = function (item, index, array) {
        var status = "NEW";
        if (item.status) {
            status = item.status;
        }
        return ($scope.filterParams.NEW && $scope.fname_asset[index].status == "NEW" || $scope.filterParams.VALIDATED && $scope.fname_asset[index].status == "VALIDATED");
    };
    $scope.quertstatus_new = function (item, index, array) {
        return $scope.fname_asset[index].status == "NEW"
    };
    // get the length of filtered assets (with a searched term) by the status VALIDATED
    $scope.quertstatus_vld = function (item, index, array) {
        return $scope.fname_asset[index].status == "VALIDATED"
    };
    $scope.quertstatus_vld_2 = function (item, index, array) {
        return $scope.limited_fname_fstatus_asset[index].status == "VALIDATED"
    };
    $scope.quertstatus_new_2 = function (item, index, array) {
        return $scope.limited_fname_fstatus_asset[index].status == "NEW"
    };
    //#endregion assetslide and filters
    //#region update data
    //changed  asset state to unsaved
    $scope.change_state = function (asset) {
        asset.save_state = 'unsaved';
        $scope.current_asset.save_state = 'unsaved';
    };
    //check zone
    $scope.verify_zone = function () {
        var k = $scope.compatibilityRequest.assets.length;
        var asset_no_zone = false;
        if ($scope.current_asset.save_state == 'unsaved') {
            return (asset_no_zone);
        } else {
            for (var i = 0; i < k; i++) {
                if (!$scope.compatibilityRequest.assets[i].place_id) {
                    asset_no_zone = true;
                    break;
                }
            }
            console.log(i);
            return (!asset_no_zone);
        }

    };
    //reset asset
    $scope.cancel = function (asset) {
        var index = $scope.compatibilityRequest.assets.indexOf(asset);
        $scope.compatibilityRequest.assets[index] = angular.copy($scope.reset_asset);
        $scope.current_asset = angular.copy($scope.reset_asset);
        $scope.content = "";
    };
    //add asset of Compatibility request
    $scope.add = function () {
        //$scope.auto = true;
        $scope.compatibilityRequest.assets.push({
            name: '',
            brand: '',
            model: '',
            vin: '',
            tank_max: '',
            place_name: '',
            place_zone: '',
            total_card_number: '',
            gray_card_front: null,
            gray_card_back: null,
            status: 'NEW',
            isnew: true,
            wasnew: false,
            save_state: 'unsaved',
            req_options: {
                canbus: 0,
                device: 0,
                direct_line: 0,
                door_sensor: 0,
                group_cold_sensor: 0,
                ibutton_reader: 0,
                ibutton_tag: 0,
                immobilizer_12V: 0,
                immobilizer_24V: 0,
                sim_supplier_ngi: 0,
                temp_probe: 0
            },
            comments: []
        });
        //$scope.currentPage = $scope.numberOfPages;
        if ($scope.fname_fstatus_asset.length % $scope.pageSize != 0)
            $scope.currentPage = Math.floor((($scope.fname_fstatus_asset.length - 1) / $scope.pageSize));
        else
            $scope.currentPage = Math.floor((($scope.fname_fstatus_asset.length - 1) / $scope.pageSize)) + 1;
        $scope.selectedIndex = $scope.limited_fname_fstatus_asset.length;
    };
    //remove asset of Compatibility request
    $scope.remove_asset = function (asset) {
        $scope.auto = false;
        if (asset.isnew && !asset.wasnew) {
            $scope.compatibilityRequest.assets.splice($scope.compatibilityRequest.assets.indexOf(asset), 1);
            if ($scope.limited_fname_fstatus_asset && $scope.limited_fname_fstatus_asset.length == 1) {
                $scope.previouspage();
            }
        } else {
            var dialog = ngDialog.open({
                templateUrl: "app/commons/dialogs/confirm/asset.delete.dlg.tpl.html",
                controller: "confirmDeleteAssetDlgCtrl",
                scope: $scope
            });
            dialog.closePromise.then(function (data) {
                if (data && data.value) {
                    if (asset.isnew) {
                        if (asset.wasnew) {
                            $scope.compatibilityRequest.assets.splice($scope.compatibilityRequest.assets.indexOf(asset), 1);
                            compatibilityRequestSaved.assets.splice($scope.compatibilityRequest.assets.indexOf(asset), 1);
                            if ($scope.limited_fname_fstatus_asset && $scope.limited_fname_fstatus_asset.length == 1) {
                                $scope.previouspage();
                            }
                            I3CompatibilitySrv.updateById($scope.compatibilityRequest._id, compatibilityRequestSaved, function (response) {
                                if (response.success) {
                                    Notification.success({
                                        title: $translate.instant("COMPATIBILITY_REQUEST_UPDATED_TITLE"),
                                        message: $translate.instant("COMPATIBILITY_REQUEST_UPDATED_SUC")
                                    });
                                } else {
                                    Notification.error({
                                        title: $translate.instant('ERROR'),
                                        message: $translate.instant("ERROR_OCCURED_UPDATE") + " " + compatibilityRequestSaved.case_number + "!"
                                    });
                                }
                            }, function (err) {
                                var msg = "";
                                if (err.error.errorString) {
                                    msg = err.error.errorString;
                                }
                                Notification.error({
                                    title: $translate.instant('ERROR'),
                                    message: msg
                                });
                            });
                        }
                    } else {
                        $scope.compatibilityRequest.assets.splice($scope.compatibilityRequest.assets.indexOf(asset), 1);
                        compatibilityRequestSaved.assets.splice($scope.compatibilityRequest.assets.indexOf(asset), 1);
                        if ($scope.limited_fname_fstatus_asset && $scope.limited_fname_fstatus_asset.length == 1) {
                            $scope.previouspage();
                        }
                        I3CompatibilitySrv.updateById($scope.compatibilityRequest._id, compatibilityRequestSaved, function (response) {
                            if (response.success) {
                                Notification.success({
                                    title: $translate.instant("COMPATIBILITY_REQUEST_UPDATED_TITLE"),
                                    message: $translate.instant("COMPATIBILITY_REQUEST_UPDATED_SUC")
                                });
                            } else {
                                Notification.error({
                                    title: $translate.instant('ERROR'),
                                    message: $translate.instant("ERROR_OCCURED_UPDATE") + " " + compatibilityRequestSaved.case_number + "!"
                                });
                            }
                        }, function (err) {
                            var msg = "";
                            if (err.error.errorString) {
                                msg = err.error.errorString;
                            }
                            Notification.error({
                                title: $translate.instant('ERROR'),
                                message: msg
                            });
                        });
                    }
                }
            });
        }
    };
    //update asset of Compatibility request
    $scope.updateCompatibilityRequestAsset = function (asset) {
        if (asset.place_id) {
            asset.place_zone = asset.place_id.name;
        }
        var index = $scope.compatibilityRequest.assets.indexOf(asset);
        compatibilityRequestSaved.assets[index] = angular.copy(asset);
        console.log(asset)
        delete compatibilityRequestSaved.__v;
        if (asset.gray_card_front && asset.gray_card_front.dataURL && asset.gray_card_front.dataURL.length) {
            compatibilityRequestSaved.assets[index].gray_card_front = asset.gray_card_front.dataURL;
        }
        /*  delete  compatibilityRequestSaved.assets[index].gray_card_front;*/
        if (asset.gray_card_back && asset.gray_card_back.dataURL && asset.gray_card_back.dataURL.length) {
            compatibilityRequestSaved.assets[index].gray_card_back = asset.gray_card_back.dataURL;
        }
        /*delete  compatibilityRequestSaved.assets[index].gray_card_back;*/
        compatibilityRequestSaved._plateforme = angular.copy($scope.compatibilityRequest._plateforme);

        compatibilityRequestSaved.code_client = angular.copy($scope.compatibilityRequest.client.code_client);
        compatibilityRequestSaved.clientSav_id = ($scope.compatibilityRequest.client && $scope.compatibilityRequest.client._id) ? angular.copy($scope.compatibilityRequest.client) : null;
        compatibilityRequestSaved.client = angular.copy($scope.compatibilityRequest.client.name);
        I3CompatibilitySrv.updateById(compatibilityRequestSaved._id, compatibilityRequestSaved, function (response) {
            if (response.success) {
                asset.save_state = 'saved';
                asset.wasnew = true;
                $scope.auto = false;
                $scope.current_asset = angular.copy(asset);
                delete compatibilityRequestSaved.__v;
                Notification.success({
                    title: $translate.instant("UPDATE_SUCC"),
                    message: $translate.instant("ASSET_UPDATED_SUCC")
                });
            } else {
                Notification.error({
                    title: $translate.instant('UPDATE_ERROR'),
                    message: $translate.instant("ASSET_UPDATED_ERR")
                });
            }
        }, function (err) {
            var msg = "";
            if (err.error.errorString) {
                msg = err.error.errorString;
            }
            Notification.error({
                title: $translate.instant('ERROR'),
                message: msg
            });
        })
    };
    //changed comp state to unsaved
    $scope.change_comp_state = function () {
        $scope.compatibilityRequest.save_state = 'unsaved';
        compatibilityRequestSaved.save_state = 'unsaved';
    };
    //verify Compatibility_request Status before update ngclass
    $scope.isavailable = function (status) {
        if ($scope.compatibilityRequestStatus_available.indexOf(status) != -1 && compatibilityRequestSaved.status != status) {
            return true
        } else
            return false
    };
    //verify Compatibility_request Status before update
    $scope.changeCompatibilityRequestStatus = function (status) {
        if ($scope.compatibilityRequestStatus_available.indexOf(status) != -1 && compatibilityRequestSaved.status != status) {
            if (status == "READY") {
                if ($scope.verify_zone()) {
                    $scope.updateCompatibilityRequestStatus(status);
                } else {
                    Notification.error({
                        title: $translate.instant('UPDATE_ERROR'),
                        message: $translate.instant("NO_ASSETS_ZONE")
                    });
                }
            } else {
                $scope.updateCompatibilityRequestStatus(status);
            }

        }
    };
    //update Compatibility_request Status
    $scope.updateCompatibilityRequestStatus = function (status) {
        messageBox.confirm("UPDATE", "UPDATE_COMPATIBILITY_REQUEST").then(function (res) {
            if (res) {
                $scope.compatibilityRequest.status = status;
                compatibilityRequestSaved.status = status;
                delete compatibilityRequestSaved.__v;
                compatibilityRequestSaved._plateforme = angular.copy($scope.compatibilityRequest._plateforme);
                compatibilityRequestSaved.code_client = angular.copy($scope.compatibilityRequest.client.code_client);
                compatibilityRequestSaved.clientSav_id = ($scope.compatibilityRequest.client && $scope.compatibilityRequest.client._id) ? angular.copy($scope.compatibilityRequest.client) : null;;
                compatibilityRequestSaved.client = angular.copy($scope.compatibilityRequest.client.name);




                I3CompatibilitySrv.updateById(compatibilityRequestSaved._id, compatibilityRequestSaved, function (response) {
                    if (response.success) {
                        delete compatibilityRequestSaved.__v;
                        constantSrv.getModel("COMPATIBILITY").getAvailableStatusByStatus(compatibilityRequestSaved.status, function (err, val) {
                            $scope.compatibilityRequestStatus_available = val._availStatus.map(function (elem) {
                                return elem.name
                            })
                        });
                        if ($scope.disab_input_status.indexOf($scope.compatibilityRequest.status) == -1) {
                            $scope.input_active = true;
                        } else {
                            $scope.input_active = false;
                        }

                        Notification.success({
                            title: $translate.instant("COMPATIBILITY_REQUEST_UPDATED_TITLE"),
                            message: $translate.instant("COMPATIBILITY_REQUEST_UPDATED_SUC")
                        });
                    } else {
                        Notification.error({
                            title: $translate.instant('ERROR'),
                            message: $translate.instant("ERROR_OCCURED_UPDATE")
                        });
                    }
                }, function (err) {
                    var msg = "";
                    if (err.error.errorString) {
                        msg = err.error.errorString;
                    }
                    Notification.error({
                        title: $translate.instant('ERROR'),
                        message: msg
                    });
                })
            }
        }, function (reject) { })
    };
    //only update info of Compatibility Request
    $scope.updateCompatibilityRequest = function () {

        compatibilityRequestSaved._plateforme = angular.copy($scope.compatibilityRequest._plateforme);
        compatibilityRequestSaved.case_number = angular.copy($scope.compatibilityRequest.case_number);
        compatibilityRequestSaved.code_client = angular.copy($scope.compatibilityRequest.client.code_client);
        compatibilityRequestSaved.clientSav_id = ($scope.compatibilityRequest.client && $scope.compatibilityRequest.client._id) ? angular.copy($scope.compatibilityRequest.client) : null;;
        compatibilityRequestSaved.client = angular.copy($scope.compatibilityRequest.client.name);
        $scope.compatibilityRequest.save_state = 'saved';
        compatibilityRequestSaved.save_state = 'saved';
        delete $scope.compatibilityRequest.__v;
        delete compatibilityRequestSaved.__v;
        $scope.reset_comp = angular.copy(compatibilityRequestSaved);
        console.log("TCL: $scope.updateCompatibilityRequest -> reset_comp", $scope.reset_comp)
        I3CompatibilitySrv.updateById($scope.compatibilityRequest._id, compatibilityRequestSaved, function (response) {
            if (response.success) {
                Notification.success({
                    title: $translate.instant("COMPATIBILITY_REQUEST_UPDATED_TITLE"),
                    message: $translate.instant("COMPATIBILITY_REQUEST_UPDATED_SUC")
                });
            } else {
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_UPDATE") + " " + compatibilityRequestSaved.case_number + "!"
                });
                //$scope.compatibilityRequest  = {};
            }
        }, function (err) {
            var msg = "";
            if (err.error.errorString) {
                msg = err.error.errorString;
            }
            Notification.error({
                title: $translate.instant('ERROR'),
                message: msg
            });
        })
    };
    //only reset info of Compatibility Request
    $scope.resetCompatibilityRequest = function () {
        $scope.compatibilityRequest.client = angular.copy($scope.reset_comp.client);
        $scope.compatibilityRequest._plateforme = angular.copy($scope.reset_comp._plateforme);
        $scope.compatibilityRequest.case_number = angular.copy($scope.reset_comp.case_number);
        $scope.compatibilityRequest.save_state = 'saved';
    }
    //#endregion update data
    //#region utility
    $scope.makeid = function (length) {
        var result = '';
        var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
    $scope.addmulti = function () {
        for (var i = 0; i < 1200; i++) {
            var rand_name = $scope.makeid(10);
            var rand_vin = $scope.makeid(12);
            $scope.compatibilityRequest.assets.push({
                name: rand_name,
                brand: 'IVECO',
                model: 'PowerStar',
                vin: rand_vin,
                tank_max: '420',
                place_name: 'Carthage',
                place_zone: 'Tunis',
                total_card_number: '5458485457',
                place_id: "5c7e5382ec3e6737c48f4fcf",
                gray_card_front: null,
                gray_card_back: null,
                status: 'NEW',
                isnew: true,
                req_options: {
                    canbus: 0,
                    device: 0,
                    direct_line: 0,
                    door_sensor: 0,
                    group_cold_sensor: 0,
                    ibutton_reader: 0,
                    ibutton_tag: 0,
                    immobilizer_12V: 0,
                    immobilizer_24V: 0,
                    sim_supplier_ngi: 0,
                    temp_probe: 0
                },
                comments: []
            });
        }
        I3CompatibilitySrv.updateById($scope.compatibilityRequest._id, $scope.compatibilityRequest, function (response) {
            if (response.success) {
                Notification.success({
                    title: $translate.instant("COMPATIBILITY_REQUEST_UPDATED_TITLE"),
                    message: $translate.instant("COMPATIBILITY_REQUEST_UPDATED_SUC")
                });
            } else {
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_UPDATE") + " " + compatibilityRequestSaved.case_number + "!"
                });
                //$scope.compatibilityRequest  = {};
            }
        }, function (err) {
            var msg = "";
            if (err.error.errorString) {
                msg = err.error.errorString;
            }
            Notification.error({
                title: $translate.instant('ERROR'),
                message: msg
            });
        })
    };
    //#endregion utility
    //#region comments
    $scope.show_com = function (x, y) {
        if (y > 0) {
            $scope.com[x] = !$scope.com[x];
        }
    };
    $scope.addComment = function (content, asset) {
        if (content) {
            var commentAdded = {};
            commentAdded.date = new Date();
            commentAdded._user = $rootScope.SESSION.user._id;
            commentAdded.comment = content;
            commentAdded.new = true;
            asset.comments.push(commentAdded);
            $scope.content = "";
            asset.save_state = 'unsaved';
            $scope.current_asset.save_state = 'unsaved';
        }
    };
    $scope.deleteAssetComment = function (index, asset) {
        //console.log(index_asset, index)
        asset.comments.splice(index, 1);
        asset.save_state = 'unsaved';
        $scope.current_asset.save_state = 'unsaved';
    };
    $scope.comp_d = function (comment) {
        if (comment.new) {
            return true
        } else return false
    };
    //#endregion comments
    //#region photos
    $scope.afficher_front = function (x) {
        var modal = document.getElementById('myModal');
        var img = document.getElementById("assetGray_card_front");
        var modalImg = document.getElementById("img01");
        var captionText = document.getElementById("caption");
        var span = document.getElementsByClassName("close")[0];
        modal.style.display = "block";
        modalImg.src = $scope.getImageUrl(x);
        captionText.innerHTML = $translate.instant("FRONT_PIC");
    };
    $scope.afficher_back = function (x) {
        var modal = document.getElementById('myModal');
        var img = document.getElementById("assetGray_card_back");
        var modalImg = document.getElementById("img01");
        var captionText = document.getElementById("caption");
        var span = document.getElementsByClassName("close")[0];
        modal.style.display = "block";
        modalImg.src = $scope.getImageUrl(x);
        captionText.innerHTML = $translate.instant("BACK_PIC");
    };
    $scope.close = function () {
        var modal = document.getElementById('myModal');
        modal.style.display = "none";
    };
    $scope.getImageUrl = function (path) {
        if (path) {

            if (path.dataURL) {
                path = path.dataURL
            }
            if (path.indexOf("data:image") >= 0) {
                return path;
            } else {
                return $rootScope.config.API_URI + "/document/registration_card/" + path + "?token=" + window.sessionStorage.token;
            }
        }
    }
    //#endregion photos




    $scope.searchClient = function ($select) {
 

        if ($scope.compatibilityRequest._plateforme && $scope.compatibilityRequest._plateforme._id) {
            I3ClientSrv.query({
                query: {

                    _plateforme: $scope.compatibilityRequest._plateforme._id,
                    name: {
                        '$regex': $select.search,
                        '$options': 'i'
                    }
                }
            }, 10, 0, function (res) {

                $scope.clients = res.result;
            }, function (err) {

            })
        }


    };

    $scope.selectPlateform = function (platform) {
        if(!$scope.compatibilityRequest.clientSav_id || $scope.compatibilityRequest.clientSav_id == null ){
            $scope.compatibilityRequest.save_state = 'unsaved';
            compatibilityRequestSaved.save_state = 'unsaved';
        }else{
            $scope.compatibilityRequest.client = null
        }
    

        I3ClientSrv.query({
            query: {
                _plateforme: platform._id
            },
            options: { populate: '_plateforme' }
        }, 10, 1, function (res) {
            if (res && res.result) {
                $scope.clients = res.result;

            }
        })




    }



});