/**
 * Created by Abdelmajid Medini on 01/06/2020.
 */
angular.module('app').controller('installValidationCtrl', function ($rootScope, $scope, I3InstallationSrv, I3InstallationGroupSrv, I3PlateformeSrv, plateformHelperSrv, iaxeedCompanySrv, I3ProductSrv, ngDialog, $stateParams, $state, messageBox, $translate, constantSrv, I3StatusSrv) {

    $scope.search = {}
    $scope.tableStyle = 'list';
    $scope.itemsByPage = 50;
    $scope.statusLoaded = false;
    $scope.statusInstallFront = ["IN_PROGRESS", "ON_HOLD", "COMMISSIONING", "READY"]
    if ($stateParams.filter)
        $scope.search.statusInstall = $stateParams.filter;
    $scope.changeTableStyle = function (style) {
        $scope.tableStyle = style;
    };

    $scope.toggleRequest = function (request) {
        request.expanded = !request.expanded;
    };

    constantSrv.getModel("INSTALLATION_REQUEST").getAllStatus(function (err, status) {
        $scope.statusInstall = status.map(function (val) {
            return val.name
        }).filter(function (params) {
            return params != 'NEW'
        })

        $scope.statusLoaded = true;
    })

    $scope.loadInstallationsValidation = function (tableState, ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }

        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;

        var query = {
            options: {
                columns: 'purchase_order client_name _plateforme  assets.status status creation_dt place_id assets.name',
                populate: '_plateforme assets.place_id',
                sortBy: {
                    creation_dt: -1
                }
            }
        };
        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
        }
        var requery = {};
        /* requery['status'] = {
             "$in": $scope.statusInstall
         };*/
        /*requery['status'] =
           {
               '$regex': 'ON_HOLD',
               '$options': 'i'
           };*/
        requery['status'] = {
            "$in": $scope.statusInstallFront
        };
        if ($scope.search) {
            if ($scope.search.client) {
                requery["client_name"] = {
                    '$regex': $scope.search.client,
                    '$options': 'i'
                };
            }
            if ($scope.search.purchase_order) {
                requery["purchase_order"] = {
                    '$regex': $scope.search.purchase_order,
                    '$options': 'i'
                };
            }
            if ($scope.search.platform) {
                requery["_plateforme"] = $scope.search.platform._id;
            }
            if ($scope.search.statusInstall) {
                requery["status"] = $scope.search.statusInstall;
            }
            if ($scope.search.asset) {
                requery["assets.name"] = {
                    '$regex': $scope.search.asset,
                    '$options': 'i'
                };
            }
        }
        if (tableState.sort && Object.keys(tableState.sort).length > 0) {
            if (tableState.sort.predicate == "creation_dt") {
                query.options.sortBy.creation_dt = tableState.sort.reverse ? -1 : 1;

            }
        }
        query.query = requery;

        I3InstallationGroupSrv.query(query, number, start, function (response) {
            console.log('response', response)
            if (response.success) {
                $scope.installsCount = response.total_count;
                tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
                tableState.pagination.totalItemCount = response.total_count;
                $scope.tableState = tableState;
                $scope.installations = response.result.map(function (e) {
                    e.installCount = 0;
                    e.totalAsset = 0;
                    if (e.assets && e.assets.length > 0) {
                        e.totalAsset = e.assets.length;
                        e.assets.forEach(function (val) {
                            if (val.status == "INSTALLED" || val.status == "COMMISSIONING")
                                e.installCount++;
                        })
                    }
                    e.progress = e.totalAsset ? e.installCount * 100 / e.totalAsset : 0;
                    return e;
                });

            }
        });
    };

    $scope.cancel = function () {
        $scope.mode = "LIST";
    };

    $scope.showDetails = function (installation) {
        $state.go("app.home.installValidation.details", {
            installValidationId: installation._id
        });
    }

})