angular.module('app.dialogs').controller('confirmDeleteProductCodeDlgCtrl', function ($scope, $rootScope, ngDialog, I3ProductCodeSrv, Notification, $translate) {

    $scope.confirm = function () {
        I3ProductCodeSrv.delete($scope.codeProduct._id, function (response) {

            if(response.success){
                //$scope.products.splice($scope.products.indexOf($scope.product), 1);
               
                Notification.success({
                    title: $translate.instant("PRODUCT_CODE_DELETED_TITLE"),
                    message: $translate.instant("PRODUCT_CODE_DELETED_SUC")
                });
                $scope.loadProductCodes()
                $scope.codeProduct = {};
            }else{
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("PRODUCT_CODE_DELETED_ERROR")
                });
                $scope.codeProduct = {};
            }
        }, function (err) {
            console.log(err);

            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("PRODUCT_CODE_DELETED_ERROR")
            });

            $scope.codeProduct = {};
        });

        $scope.codeProduct = {};
        $scope.closeThisDialog();
    }

});