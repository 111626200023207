angular.module('app.dialogs').controller('confirmDeleteStockDlgCtrl', function ($scope, $rootScope, ngDialog, I3StockSrv, Notification,  $state,$translate) {

    $scope.confirm = function () {
        I3StockSrv.delete($scope.stock._id, function (response) {
            if(response.success){
                //$scope.roles.splice($scope.roles.indexOf($scope.role), 1);
                //$scope.loadStatus();
                $scope.loadStocks()
                Notification.success({
                    title: $translate.instant("STOCK_DELETED_TITLE"),
                    message : $translate.instant("STOCK_DELETED_SUC")
                });
             
                $scope.stock = {};
            }else{
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("STOCK_DELETED_ERROR")
                });
                $scope.stock = {};
            }
        }, function (err) {
            console.log(err);

            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("STOCK_DELETED_ERROR")
            });

            $scope.stock = {};
        });

       $scope.stock = {};
        $scope.closeThisDialog();
       
    }

});