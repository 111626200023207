angular.module('app').controller('compatibilityCheckCtrl', function ($scope, $state, $stateParams, I3CompatibilitySrv, constantSrv) {
    $scope.itemsByPage = 50;
    $scope.statusLoaded = false;
    $scope.search = {};
    $scope.checkStatus = [];
    var i = 0;
    if ($stateParams.filter)
        $scope.search.checkStatus = $stateParams.filter;
    // console.log($stateParams);
    //  $scope.checkStatus = constantSrv.getCompatibilityCheckStatus();
    constantSrv.getModel("COMPATIBILITY_STUDY").getAllStatus(function (err, status) {

        var compatibilityCheckStatus = status.map(function (val) {
            return val.name
        })

        compatibilityCheckStatus.forEach(function (elem) {
            if (elem && elem != "DONE") {

                $scope.checkStatus.push(elem)
            }
        })

        $scope.statusLoaded = true;
    })

    //Check if state is active
    $scope.isActive = function (state) {
        return $state.current.name.startsWith(state);
    };
    $scope.loadCompatibilitiesCheck = function (tableState, ctrl) {

        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }
        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;
        var query = {
            options: {
                columns: "_plateforme client status case_number assets.name creation_dt",
                populate: '_plateforme',
                sortBy: {
                    creation_dt: -1
                }
            }
        };

        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
        }
        var requery = {};
        requery['status'] = {
            "$in": $scope.checkStatus
        };
        if ($scope.search && $scope.search.client) {
            requery["client"] = {
                '$regex': $scope.search.client,
                '$options': 'i'
            };

        }
        if ($scope.search && $scope.search.platform) {
            requery["_plateforme"] = $scope.search.platform._id;

        }
        if ($scope.search && $scope.search.case_number) {
            requery["case_number"] = {
                '$regex': $scope.search.case_number,
                '$options': 'i'
            };

        }
        if ($scope.search.checkStatus) {
            requery["status"] = $scope.search.checkStatus;

        }
        if (tableState.sort && Object.keys(tableState.sort).length > 0) {
            if (tableState.sort.predicate == "creation_dt") {
                query.options.sortBy.creation_dt = tableState.sort.reverse ? -1 : 1;

            }
        }
        query.query = requery;

        I3CompatibilitySrv.query(query, number, start, function (response) {
            if (response.success) {

                $scope.compatibilitiesCheck = response.result;
                $scope.compatibilitiesCheckCount = response.total_count;
                tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
                tableState.pagination.totalItemCount = response.total_count;
                $scope.tableState = tableState;
               // console.log($stateParams);
                if (($stateParams.platform) && (i == 0)) {
                    console.log('rrrrr');
                    var elementPos = $scope._HOME.platforms.map(function (plat) {
                        return plat._id;
                    }).indexOf($stateParams.platform);

                    $scope.search.platform = $scope._HOME.platforms[elementPos];
                    i = 1;
                }

            }
        });
    };

});