angular.module('app').controller('interventionDetailsCtrl', function ($scope, $rootScope, $window, $state, $stateParams, $translate, ngDialog, Notification, I3InterventionSrv, I3UserSrv, I3DocumentSrv, i3HelperSrv, plateformHelperSrv, constantSrv, iaxeedAssetSrv, reportingSrv) {
    $scope.intervention = {};
    $scope.liststatusAvailIntervention = []
    // $scope.status = constantSrv.getInterventionStatus();
    constantSrv.getModel("INTERVENTION").getAllStatus(function (err, stat) {

        $scope.status = stat.map(function (val) {
            return val.name
        })
    })
    $scope.timeleft = 0;
    $scope.now = moment()
    $scope.redv = 0;

    $scope.getvalue = function (deadline, deadline_period) {
        if (deadline && deadline_period) {
            var deadline_start = moment(deadline).subtract(deadline_period, 'h');
            console.log(deadline_start)
            var date2 = moment(deadline);
            var date1 = moment();
            $scope.date_limite = date2;
            var differenceInMs = date2.diff(date1); // diff yields milliseconds
            var duration = moment.duration(differenceInMs);
            var differenceInHours = Math.floor(duration.asHours());

            if (-differenceInHours >= 1000) {
                $scope.timeleft = 1000
            } else
                $scope.timeleft = -differenceInHours;
            var redv = Math.floor(100 - ((differenceInHours / deadline_period) * 100))
            if (redv >= 100) redv = 100
            console.log(redv)
            return redv
        }
    }

    var now = moment().clone();
    $scope.dayRes = now;
    $scope.com = [];
    $scope.content = "";
    $scope.come = false;
    $scope.com = [];
    $scope.com3 = [];
    $scope.show_com = function (x) {
        $scope.com[x] = !$scope.com[x];
    }
    $scope.show_com3 = function (x) {
        $scope.com3[x] = !$scope.com3[x];
    }
    $scope.show_come = function () {
        $scope.come = !$scope.come;
    }
    $scope.show_come10 = function () {
        $scope.come10 = !$scope.come10;
    }
    $scope.show_come20 = function () {
        $scope.come20 = !$scope.come20;
    }

    $scope.open2 = function () {
        $scope.popup2.opened = true;
    };
    $scope.popup2 = {
        opened: false
    };
    $scope.opened = {};
    $scope.open = function ($event, elementOpened) {
        $event.preventDefault();
        $event.stopPropagation();

        $scope.opened[elementOpened] = !$scope.opened[elementOpened];
    };

    $scope.emailFollow = "";

    $scope.uploadFiles = function (files, errFiles) {
        $scope.files = files;
        $scope.errFiles = errFiles;
        i3HelperSrv.uploadFiles(files, {
            _plateforme: $scope.intervention._plateforme && $scope.intervention._plateforme._id ? $scope.intervention._plateforme._id : $scope.intervention._plateforme,
            related_model: [{
                model: "INTERVENTION",
                model_id: $scope.intervention._id
            }]
        }, function (res) {
            angular.forEach(res.result, function (file) {
                $scope.intervention.docs.push(file)
            });
            file.result = res.data;
        }, function (response) {
            if (response.status)
                $scope.errorMsg = response.status + ': ' + response.data;
        }, function (evt) {

        });
    };

    $scope.downloadFile = function (file) {
        i3HelperSrv.downloadFile(file._id, file.original_name, function success(res) {}, function error(err) {
            Notification.error($translate.instant(err));
        }, function progress(evt) {
            console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '%');
        })
    };

    /*    $scope.show_com = function (x) {
            $scope.com[x] = !$scope.com[x];
        }*/
    $scope.getInterventions = function () {
        if ($stateParams.id && $stateParams.id.length > 0) {
            I3InterventionSrv.query({
                query: {
                    _id: $stateParams.id
                },
                options: {
                    populate: ["_assigned_to", "_claim", "_created_by", "docs", "_plateforme", {
                            path: '_claim',
                            populate: {
                                path: '_plateforme'
                            }
                        }, {
                            path: 'followers.user'
                        }, {
                            path: 'comments.owner',
                            select: 'login'
                        }, {
                            path: '_claim',
                            populate: ['_created_by']
                        },
                        {
                            path: 'status_histo._modified_by',
                            select: "first_name  last_name login"
                        }
                    ]
                }
            }, 1, 0, function (res) {
                if (res && res.success && res.result.length > 0) {
                    $scope.intervention = res.result[0];
                    $scope.redv = $scope.getvalue($scope.intervention.dead_line, $scope.intervention.dead_line_period);
                    console.log("   $scope.intervention", $scope.intervention)
                    constantSrv.getModel("INTERVENTION").getAvailableStatusByStatus($scope.intervention.status, function (err, val) {
                        $scope.liststatusAvailIntervention = val._availStatus.map(function (elem) {
                            return elem.name
                        })
                        console.log($scope.liststatusAvailIntervention)
                    });
                    if ($scope.intervention._claim && $scope.intervention._claim._plateforme) {
                        $scope.plateforme = $scope.intervention._claim._plateforme
                    } else {
                        $scope.plateforme = $scope.intervention._plateforme;
                    }

                    if (!$scope.intervention.client_id && $scope._plateforme) {
                        $scope.verify();
                    }
                    /* if ($scope.plateforme.type == 'NGI_FLEET') {

                        return
                    }*/

                    plateformHelperSrv.setPlateFormAndLogin($scope.plateforme, function (res) {

                            if (res.success) {
                                $scope.platformeLogin = res;

                                var assetIds = [];
                                if ($scope.intervention._claim && $scope.intervention._claim.asset && $scope.intervention._claim.asset.length > 0) {

                                    angular.forEach($scope.intervention._claim.asset, function (asset) {
                                        assetIds.push(asset.asset_id);
                                    });
                                } else {
                                    angular.forEach($scope.intervention.asset, function (asset) {
                                        assetIds.push(asset.asset_id);
                                    });
                                }
                                var options = {};
                                if ($scope.platformeLogin && $scope.platformeLogin.result && $scope.platformeLogin.result.token) {

                                    var query = {
                                        _id: {
                                            $in: assetIds
                                        }
                                    };

                                }

                                iaxeedAssetSrv.query({
                                    query: query,
                                    options: options
                                }, 10000, 1, function (res1) {
                                    if (res1.result) {
                                        $scope.assets = res1.result;
                                    }
                                }, function (err) {
                                    console.log(err);
                                });
                            } else {
                                Notification.error({
                                    title: $translate.instant("ERROR"),
                                    message: $translate.instant("PLATFORM_CNX_ERROR")
                                });
                            }

                        },
                        function (err) {
                            console.log(err)
                        });

                    $scope.getFiles($scope.intervention.docs);
                    delete $scope.intervention._created_by;

                    if ($scope.intervention.comments || $scope.intervention.comments.length <= 0)
                        $scope.intervention.comments = [{
                            owner: $rootScope.SESSION.user._id
                        }];

                } else
                    Notification.error("FIND_BY_ID_ERROR");
            }, function (err) {
                Notification.error("FIND_BY_ID_ERROR")
            })
        }
    };

    $scope.verify = function () {
        console.log("verify")
        var modal = ngDialog.open({
            controller: "ClientIntervSyncDlgCtrl",
            templateUrl: "app/commons/dialogs/client/client.interv.sync.dlg.tpl.html",
            className: 'ngdialog-theme-default custom-width',
            scope: $scope,
            width: '900px',
            showClose: false,
            closeByEscape: false,
            closeByNavigation: true,
            closeByDocument: false,
            resolve: {
                intervention: function () {
                    return $scope.intervention;
                }
            }
        });
        modal.closePromise.then(function (data) {
            $scope.intervention.client_id = data.value._id;
            $scope.intervention.client_name = data.value.name;
            I3InterventionSrv.updateById($scope.intervention._id, $scope.intervention, function (response) {
                if (response.success) {
                    Notification.success({
                        title: $translate.instant("SYNCHRONIZED_CLIENT"),
                        message: $translate.instant("SYNCHRONIZED_CLIENT_SUCC")
                    });
                } else {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("ERROR_OCCURED_UPDATE") + "!"
                    });
                }
            }, function (err) {
                var msg = "";
                if (err.error.errorString) {
                    msg = err.error.errorString;
                }
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: msg
                });
            })
        }, function () {});
    }
    $scope.showRealtime = function (asset) {

        var asset2 = null
        if ($scope.assets && $scope.assets.length > 0) {
            var filterded = $scope.assets.filter(function (val) {
                if (val._id == asset.asset_id) {

                    asset2 = val;
                }
            })
        }
        $scope.asset = asset2 || asset;
        var dialog = ngDialog.open({
            controller: "realtimeDlgCtrl",
            templateUrl: "app/commons/dialogs/realtime/realtime.dlg.tpl.html",
            scope: $scope,
            className: 'ngdialog-theme-default custom-width'
        });

        dialog.closePromise.then(function () {
            iaxeedSocketSrv.closeListner('newMsg');
        });
    }

    $scope.cancel = function () {
        $state.go('app.home.intervention');
    };

    $scope.getInterventions();
    $scope.isavailable = function (status) {
        if ($scope.liststatusAvailIntervention.indexOf(status) != -1 && $scope.intervention.status != status) {

            return true
        } else
            return false
    };

    $scope.downloadZipGrouppedfile = function () {

        try {
            var params = {
                _ids: []
            };
            var comments = $translate.instant("INTERVENTION") + "\r\n";
            comments += "\t" + $translate.instant("CLIENT") + " :" + $scope.intervention.client_name + "\r\n";
            comments += "\t" + $translate.instant("REFERENCE") + " :" + $scope.intervention.ref + "\r\n";
            comments += "\t" + $translate.instant("purchase_order_nbr") + " :" + $scope.intervention.purchase_order + "\r\n";
            comments += "-------------------------------------------------------------------------\r\n";
            comments += "\t- " + $translate.instant("DOCUMENTS") + " :\r\n";
            if ($scope.intervention.docs && $scope.intervention.docs.length > 0) {
                //  params.ids = {intervention: []}
                $scope.intervention.docs.forEach(function (doc) {
                    if (doc._id)
                        params._ids.push(doc._id);
                })
                comments += "\t\t + intervention : " + $translate.instant("DOCUMENTS") + " " + $translate.instant("Intervention") + "\r\n";
            }
            /* $scope.interventionRequest.assets.forEach(function (asset) {
                 var asset_tag=asset.name;
                  if(asset.vin){
                      asset_tag=asset.vin
                  }
                 params.groups[asset_tag]=[];
                 if(asset.docs && asset.docs.length>0){
                     asset.docs.forEach(function (doc) {
                         if(doc._id)
                             params.groups[asset_tag].push(doc._id);
                     })
                     if(asset.gray_card_back && asset.gray_card_back.length>0){
                         params.groups[asset_tag].push(asset.gray_card_back);
                     }
                     if(asset.gray_card_front && asset.gray_card_front.length>0){
                         params.groups[asset_tag].push(asset.gray_card_front);
                     }

                 }

             })*/

            params.comments = comments
            i3HelperSrv.downloadZip(params, "Intervention_" + $scope.intervention.client_name + "_" + $scope.intervention.purchase_order + "_docs.zip", function (res) {

            }, function (err) {
                console.log(err)
            }, function (percent) {
                console.log(percent)
            })
        } catch (e) {

            console.error(e)
        }
    }

    function showPV(seq, asset) {
        $scope.template = 'pv_interv.mrt';
        var fileType = "preview"
        try {
            info = {
                npv: seq,
                nbc: $scope.intervention.purchase_order ? $scope.intervention.purchase_order : "",

                client: $scope.intervention.client_name ? $scope.intervention.client_name : "",
                contact: ($scope.intervention.contact && $scope.intervention.contact.name ? $scope.intervention.contact.contact.name : ""),
                tel: ($scope.intervention.contact && $scope.intervention.contact.phone ? $scope.intervention.contact.phone : ""),

                date: moment().toDate(),
                adress: ($scope.intervention.adress ? $scope.intervention.adress : ""),
                asset_type: (asset && asset.type ? $translate.instant(asset.type) : ""),
                asset_model: (asset && asset.model ? asset.model : ""),
                asset_brand: (asset && asset.brand ? asset.brand : ""),
                asset_name: (asset && asset.name ? asset.name : ""),
                data: true
            }
            var ds = {
                name: "pvSingleInstalll",
                data: {
                    info: info,
                    data: {},
                    fileName: "PV_interv " + (asset.name || '') + " " + moment().format("YYY-MM-DD")
                }
            }
            console.log(info)
            if (fileType == "XLSX") {
                reportingSrv.generateExcel($scope.template, ds, function () {}, function () {
                    Notification.error("L'export a échoué ");
                })
            } else if (fileType == "DOCX") {
                reportingSrv.generateWord($scope.template, ds, function () {}, function () {
                    Notification.error("L'export a échoué ");
                })
            } else if (fileType == "preview") {
                //console.log("preview")
                reportingSrv.preview($scope.template, ds, function () {}, function () {
                    Notification.error("La prévisualisation  a échoué ");
                })
            } else {
                reportingSrv.generatePdf($scope.template, ds, function () {}, function () {
                    Notification.error("L'export a échoué ");
                })
            }
        } catch (e) {
            console.error(e)
        }
    }
    $scope.printPV = function (asset) {
        var asset2 = null
        if ($scope.assets && $scope.assets.length > 0) {
            var filterded = $scope.assets.filter(function (val) {
                if (val._id == asset.asset_id) {

                    asset2 = val;
                }
            })
        }
        if (!asset2) asset2 = asset;

        var dialog = ngDialog.open({
            controller: "confirmPrintPV",
            templateUrl: "app/commons/dialogs/confirm/pv.print.prompt.html",
            scope: $scope,
            className: 'ngdialog-theme-default '
        });

        dialog.closePromise.then(function (data) {
            console.log($scope.assets)
            if (data && data.value !== undefined && data.value !== '$closeButton' && data.value !== '$document') {
                i3HelperSrv.getPVSeqNextVal($scope.intervention._plateforme, function (seq) {
                    var seq = seq;
                    showPV(seq.result, asset2);

                }, function (err) {
                    if (err == "GENERATE_PV_NUMBER_ERROR") {
                        console.log(err);
                    } else {
                        console.log(err);
                        Notification.error($translate.instant("ERROR_GENERATE_SEQUENCE"));
                    }

                });

            } else {

            }

        })

    }
});