angular.module('app.dialogs').controller('ClaimLevelDlgCtrl', function ($scope) {
    //console.log($scope.ngDialogData.prop)
    // console.log($scope.ngDialogData)
    $scope.dialog ={}
   
    if($scope.ngDialogData.status == 'InDevelopment' || $scope.ngDialogData.status == 'InIntervention'){

        $scope.dialog.level ="LEVEL_3"
    }else{
        $scope.init = $scope.ngDialogData.prop.level;
        $scope.dialog.level = $scope.init;
    }
    
    $scope.dialog.comment = "";
    $scope.update = function () {
        //console.log($scope.dialog)
    }

    $scope.EditStatus = function () {
        $scope.closeThisDialog($scope.dialog);
    }
    $scope.cancel = function () {
        $scope.closeThisDialog(false);
    }
});