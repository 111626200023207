angular.module("app.directives").directive('exportCompatibilityXlsx2', function ($rootScope, $translate) {
    return {
        scope: {
            comp: '='
        },
        templateUrl: "app/commons/directives/export/exportCompatibility2.drv.html",
        link: function ($scope, $elm) {
            $elm.on('click', function () {
                var htmltable = document.getElementById('mytable_' + $scope.comp._id);
                var html = htmltable.outerHTML;
               // window.open('data:application/vnd.ms-excel,' + escape(html));
               var element = document.createElement('a');
               element.setAttribute('href', 'data:application/vnd.ms-excel,' +escape(html));
               element.setAttribute('download', $scope.comp.client+'_'+$scope.comp.case_number+'_'+moment($scope.comp.creation_dt).format('DD-MM-YYYY'));
               element.style.display = 'none';
               document.body.appendChild(element);
               element.click();
               document.body.removeChild(element);

            })
        }

    }
})