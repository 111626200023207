/**
 * Created by Kais CHAIBI on 14/03/2018.
 */
angular.module("app.services").factory("reportingSrv",function( $http,$rootScope,ngDialog ){

    service = {};

    service.generatePdf=function(tpl,dataSource,success,error){
       service.getTplByName(tpl,function(res){

           if(res){
           try {
               Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("./app/assets/utils/stimulsoft_flat/fonts/Roboto-Black.ttf");

               var report = new Stimulsoft.Report.StiReport();
               report.load(res);
               var settings = new Stimulsoft.Report.Export.StiPdfExportSettings();
               settings.ImageQuality = 1.0;
               var dataSet = new Stimulsoft.System.Data.DataSet(dataSource.name);
               dataSet.readJson(dataSource.data);
               report.dictionary.databases.clear();
               report.regData(dataSource.name, dataSource.data, dataSet);
                var service = new Stimulsoft.Report.Export.StiPdfExportService();
               report.render();
               var stream = new Stimulsoft.System.IO.MemoryStream();
               service.exportTo(report, stream, settings);
               var data = stream.toArray();
               Object.saveAs(data, "PVDelivery" + (moment().format("YYYYMMDDHHmm")) + ".pdf", "application/pdf");
               success(true);
           }catch(e){
               console.log(e);
               error(e);
           }}
           else {
               error("INVALID_TEMPLATE")
           }
       },function(err){
                error(err);
       })

    }

    service.generateExcel=function(tpl,dataSource,success,error){

        service.getTplByName(tpl,function(res){

            if(res){
                try {
                    Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("./app/assets/utils/stimulsoft_flat/fonts/Roboto-Black.ttf");

                    var report = new Stimulsoft.Report.StiReport();
                    report.load(res);

                    var dataSet = new Stimulsoft.System.Data.DataSet(dataSource.name);
                    dataSet.readJson(dataSource.data);
                    report.dictionary.databases.clear();
                    report.regData(dataSource.name, dataSource.data, dataSet);
                    var settings = new Stimulsoft.Report.Export.StiExcelExportSettings();
                    settings .usePageHeadersAndFooters=true;
                    settings.removeEmptySpaceAtBottom=true;
                    var service = new Stimulsoft.Report.Export.StiExcel2007ExportService()
                    report.render();
                    var stream = new Stimulsoft.System.IO.MemoryStream();
                    service.exportTo(report, stream, settings);
                    var data = stream.toArray();
                    Object.saveAs(data, "PVDelivery" + (moment().format("YYYYMMDDHHmm")) + ".xlsx", "application/pdf");
                    success(true);
                }catch(e){
                    console.log(e);
                    error(e);
                }}
            else {
                error("INVALID_TEMPLATE")
            }
        },function(err){
            error(err);
        })
    }
    service.generateWord=function(tpl,dataSource,success,error){
        service.getTplByName(tpl,function(res){

            if(res){
                try {
                    Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("./app/assets/utils/stimulsoft_flat/fonts/Roboto-Black.ttf");

                    var report = new Stimulsoft.Report.StiReport();
                    report.load(res)
                    var dataSet = new Stimulsoft.System.Data.DataSet(dataSource.name);
                    dataSet.readJson(dataSource.data);
                    report.dictionary.databases.clear();
                    report.regData(dataSource.name, dataSource.data, dataSet);
                    var settings = new Stimulsoft.Report.Export.StiWord2007ExportSettings();
                      settings .usePageHeadersAndFooters=true;
                    settings.RemoveEmptySpaceAtBottom=true;
                    var service = new Stimulsoft.Report.Export.StiWord2007ExportService()
                    report.render();
                    var stream = new Stimulsoft.System.IO.MemoryStream();
                    service.exportTo(report, stream, settings);
                    var data = stream.toArray();
                    Object.saveAs(data, "PVDelivery" + (moment().format("YYYYMMDDHHmm")) + ".docx", "application/pdf");
                    success(true);
                }catch(e){
                    console.log(e);
                    error(e);
                }}
            else {
                error("INVALID_TEMPLATE")
            }
        },function(err){
            error(err);
        })
    }

    service.getTplByName=function(tpl,success,error){
        $http.get( $rootScope.config.API_URI + '/report/getReportTpl/'+tpl).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data  ){

                success(response.data);
            }
            else{

                error(data);
            }

        },function(data) {
            error(data)

        });
    };
    service.preview=function(tpl,dataSource,success,error){
        service.getTplByName(tpl,function(res){
            if(res) {
                ngDialog.open({
                    template: 'app/commons/dialogs/report/report.preview.tpl.html',

                    controller: 'previewReportCtrl',
                    className:"ngdialog-theme-default flat-dialog",
                    resolve: {
                        mrt: function () {
                            return res
                        },
                        ds: function () {
                            return dataSource
                        }
                    }
                })
            }  else{

                error(data);
            }

        },function(data) {
            error(data)

        });
    }

    return service;
})