angular.module('app').controller('homeClaimAddCtrl', function ($scope, $rootScope, $state, $translate, I3ClaimSrv, I3PlateformeSrv, plateformHelperSrv, iaxeedCompanySrv, iaxeedAssetSrv, i3HelperSrv, ngDialog, Notification, constantSrv, NGIFleetClientSrv, NGIFleetHelperSrv, NGIFleetAssetSrv) {

    $scope.claim = {};
    $scope.claim.docs = []
    $scope.files = [];
    $scope.asset_list = [];
    $scope.sources = constantSrv.getClaimsSource();
    /**
     * Loading platforms
     */
    I3PlateformeSrv.query({}, 10000, 1, function (response) {
        $scope.platforms = response.result;


        if($rootScope.checkAccess("PLATEFORME","R","O")){
            $scope.claim._plateforme = $scope._HOME.platforms[0];
            $scope.selectPlateform($scope.claim._plateforme)
        }


    }, function (err) {
        console.log(err);
    });

    /**
     * Loading claim type
     */

    i3HelperSrv.getEnums(function (res) {
        $scope.claim_type = res.CLAIM_TYPE.LIST
    }, function (err) {

    })

    /**
     * select and authenticate plateforme
     * @param platform
     */
    $scope.selectPlateform = function (platform) {

        if ($scope.claim._plateforme && $scope.claim._plateforme.type) {
            if ($scope.claim._plateforme.type === 'NGI_FLEET') {
                NGIFleetHelperSrv.setPlateFormAndLogin(platform, function (res) {

                }, function (err) {
                    console.log(err);
                })
            } else {
                plateformHelperSrv.setPlateFormAndLogin(platform, function (res) {

                    if (res && res.result) {
                        $scope.loginPlateform = res.result
                    } else {

                        Notification.error({
                            title: $translate.instant("ERROR"),
                            message: $translate.instant("PLATFORM_CNX_ERROR")
                        });
                    }

                }, function (err) {

                    console.log(err);

                })
            }
        }
        $scope.asset_list = [];
        $scope.claim.client_id = null;
        $scope.claim.asset = null;
    }

    $scope.searchClient = function ($select) {
        if(!$rootScope.plateform) return;
        if ($scope.claim._plateforme && $scope.claim._plateforme.type) {
            if ($scope.claim._plateforme.type === 'NGI_FLEET') {
                NGIFleetClientSrv.getClient($select.search, 10, 0, function (res) {
                    $scope.clients = res.result;
                }, function (err) {
                    console.log("err", err)
                })
            } else {
                if ($scope.loginPlateform && $scope.loginPlateform.token) {
                    iaxeedCompanySrv.query({
                        query: {
                            name: {
                                '$regex': $select.search,
                                '$options': 'i'
                            }
                        }
                    }, 10, 0, function (res) {
                        $scope.clients = res.result;
                    }, function (err) {
                        console.log("err", err)
                    })
                } else {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("PLATFORM_CNX_ERR")
                    });

                }
            }
        }
    };

    $scope.searchAsset = function ($select) {
        if ($scope.claim._plateforme && $scope.claim._plateforme.type && $scope.claim.client_id) {
            if ($scope.claim._plateforme.type === 'NGI_FLEET') {
                NGIFleetAssetSrv.getAssetByClient($scope.claim.client_id._id, $select.search, 10, 0, function (res) {
                    $scope.assets = res.result;
                }, function (err) {
                    console.log("err", err)
                })
            } else {
                iaxeedAssetSrv.query({
                    query: {
                        _company_owner: $scope.claim.client_id._id,
                        name: {
                            '$regex': $select.search,
                            '$options': 'i'
                        },
                        _id: {
                            $nin: $scope.asset_list.map(function (val) {
                                if (val._id) return val._id;
                                else return val;
                            })
                        }
                    }
                }, 10, 0, function (res) {
                    $scope.assets = res.result;
                }, function (err) {

                })
            }
        }
    };
    $scope.selectClient = function () {
        $scope.asset_list = [];
        $scope.claim.asset = null;
    }

    /**
     * Save user
     */
    $scope.save = function (claim) {
        var claim = angular.copy(claim);
        claim.client_name = claim.client_id.name;
        claim._client_code = claim.client_id.client_code;
        claim.client_id = claim.client_id._id;
        claim._plateforme = claim._plateforme._id;
        claim.status = 'New';
        claim.level = "LEVEL_1"
        claim.asset = $scope.asset_list.map(function (val) {
            return {
                asset_id: val._id,
                asset_name: val.name
            };
        });

        I3ClaimSrv.add(claim, function (response) {
            if (response.success) {
                Notification.success({
                    title: $translate.instant("CLAIM_CREATED")  ,
                    message: $translate.instant("CLAIM")  + $scope.claim._plateforme.name + $translate.instant("CLAIM_CREATED_SUC")
                });
                $state.go('app.home.claims');
                $scope._plateforme = {};
            } else {
                Notification.success({
                    title: "Erreur",
                    message: $translate.instant("ERROR_OCCURED_CREATE") + " " + $scope.claim._plateforme.name + "!"
                });
                $scope.user = {};
            }
        }, function (err) {
            var msg = "";
            if (err.error.errorString) {
                msg = err.error.errorString;
            }
            Notification.error({
                title: $translate.instant("ERROR"),
                message: msg
            });
        })
    };

    $scope.addAssets = function (asset) {
        if (asset) {
            if ($scope.asset_list.indexOf(asset) >= 0) {
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("ALREADY_EXIST")
                });
            } else {
                $scope.asset_list.push(asset);
            }
        } else {
            Notification.error({
                title: $translate.instant("ERROR"),
                message: $translate.instant("SELECT_AN_ASSET")
            });
        }
        $scope.claim.asset = null;
    };

    $scope.removeAssets = function (index) {
        $scope.asset_list.splice(index, 1);
    };

    /**
     * Cancel editing mode
     */
    $scope.cancel = function () {
        $scope.claim = {};
        $state.go('app.home.claims');
    }

    $scope.uploadFiles = function (files, errFiles) {
        $scope.files = files;
        $scope.errFiles = errFiles;
        i3HelperSrv.uploadFiles(files, {
            _plateforme:$scope.claim._plateforme && $scope.claim._plateforme._id ?$scope.claim._plateforme._id:$scope.claim._plateforme,
            related_model: [{
                model: "CLAIM",
                model_id: $scope.claim._id
            }]
        }, function (res) {
            angular.forEach(res.result, function (file) {
                $scope.claim.docs.push(file)
            });
            file.result = res.data;
        }, function (response) {
            if (response.status)
                $scope.errorMsg = response.status + ': ' + response.data;
        }, function (evt) {

        });
    }

});