angular.module('app').controller('adminClaimAddCtrl', function ($scope, $rootScope, $state, $translate, I3ClaimSrv, I3PlateformeSrv, plateformHelperSrv, iaxeedCompanySrv, iaxeedAssetSrv, i3HelperSrv, ngDialog, Notification, constantSrv, NGIFleetClientSrv, NGIFleetHelperSrv, NGIFleetAssetSrv) {

    $scope.claim = {};
    $scope.asset_list = [];
    $scope.sources = constantSrv.getClaimsSource();
    /**
     * Loading platforms
     */
    I3PlateformeSrv.query({}, 10000, 1, function (response) {
        $scope.platforms = response.result;
    }, function (err) {
        console.log(err);
    });

    /**
     * Loading claim type
     */

    i3HelperSrv.getEnums(function (res) {
        $scope.claim_type = res.CLAIM_TYPE.LIST
    }, function (err) {

    })

    /**
     * select and authenticate plateforme
     * @param platform
     */
    $scope.selectPlateform = function (platform) {
        if ($scope.claim._plateforme && $scope.claim._plateforme.type) {
            if ($scope.claim._plateforme.type === 'NGI_FLEET') {
                NGIFleetHelperSrv.setPlateFormAndLogin(platform, function (res) {

                }, function (err) {
                    console.log(err);
                })
            } else {
                plateformHelperSrv.setPlateFormAndLogin(platform, function (res) {

                }, function (err) {
                    console.log(err);
                })
            }
        }
        $scope.asset_list = [];
        $scope.claim.client_id = null;
        $scope.claim.asset = null;
    }

    $scope.searchClient = function ($select) {
        if ($scope.claim._plateforme && $scope.claim._plateforme.type) {
            if ($scope.claim._plateforme.type === 'NGI_FLEET') {
                NGIFleetClientSrv.getClient($select.search, 10, 0, function (res) {
                    $scope.clients = res.result;
                }, function (err) {
                    console.log("err", err)
                })
            } else {
                iaxeedCompanySrv.query({
                    query: {
                        name: {
                            '$regex': $select.search,
                            '$options': 'i'
                        }
                    }
                }, 10, 0, function (res) {
                    $scope.clients = res.result;
                }, function (err) {
                    console.log("err", err)
                })
            }
        }
    };

    $scope.searchAsset = function ($select) {
        if ($scope.claim._plateforme && $scope.claim._plateforme.type && $scope.claim.client_id) {
            if ($scope.claim._plateforme.type === 'NGI_FLEET') {
                NGIFleetAssetSrv.getAssetByClient($scope.claim.client_id._id, $select.search, 10, 0, function (res) {
                    $scope.assets = res.result;
                }, function (err) {
                    console.log("err", err)
                })
            } else {
                iaxeedAssetSrv.query({
                    query: {
                        _company_owner: $scope.claim.client_id._id,
                        name: {
                            '$regex': $select.search,
                            '$options': 'i'
                        }
                    }
                }, 10, 0, function (res) {
                    $scope.assets = res.result;
                }, function (err) {

                })
            }
        }
    };
    $scope.selectClient = function () {
        $scope.asset_list = [];
        $scope.claim.asset = null;
    }

    /**
     * Save user
     */
    $scope.save = function (claim) {
        var claim = angular.copy(claim);
        claim.client_name = claim.client_id.name;
        claim.client_id = claim.client_id._id;
        claim._plateforme = claim._plateforme._id;
        claim.status = 'NEW';
        claim.asset = $scope.asset_list.map(function (val) {
            return {
                asset_id: val._id,
                asset_name: val.name
            };
        });
        I3ClaimSrv.add(claim, function (response) {
            if (response.success) {
                Notification.success({
                    title: $translate.instant('CLAIM_CREATED_TITLE'),
                    message: $translate.instant("CLAIM_CREATED_SUC") + " : " + $scope.claim._plateforme.name + "!"
                });
                $state.go('app.admin.claims');
                $scope._plateforme = {};
            } else {
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_CREATE") + " " + $scope.claim._plateforme.name + "!"
                });
                $scope.user = {};
            }
        }, function (err) {
            var msg = "";
            if (err.error.errorString) {
                msg = err.error.errorString;
            }
            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("ERROR_OCCURED_CREATE") + " " + $scope.claim._plateforme.name + "!"
            });
        })
    };

    $scope.addAssets = function (asset) {
        if (asset) {
            if ($scope.asset_list.indexOf(asset) >= 0) {
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("ALREADY_EXIST")
                });
            } else {
                $scope.asset_list.push(asset);
            }
        } else {
            Notification.error({
                title: $translate.instant("ERROR"),
                message: $translate.instant("SELECT_AN_ASSET")
            });
        }
        $scope.claim.asset = null;
    };

    $scope.removeAssets = function (index) {
        $scope.asset_list.splice(index, 1);
    };

    /**
     * Cancel editing mode
     */
    $scope.cancel = function () {
        $scope.claim = {};
        $state.go('app.admin.claims');
    }

});