angular.module('app').controller("previewReportCtrl", function ($scope, $rootScope, Notification, $translate, $timeout, mrt, ds) {


    if (mrt && ds) {
        try {
            $timeout(function () {

                //console.log("emit")
                $rootScope.$emit("report:render", {
                    tpl: mrt,
                    ds: ds
                });


            }, 500)
        } catch (e) {
            console.log(e);
        }
        // console.log("ctrl", mrt, ds);
    }



});