/**
 * Created by Kais CHAIBI on 29/11/2017.
 */


angular.module("app.services").service('i3HelperSrv', function ($http, $rootScope, $window, AbstractApiSrv, I3_SERVICES, $injector,Notification,$translate,I3StatusSrv, Upload, $timeout, FileSaver, Blob, messageBox) {


    function I3HelperSrv() {

    }

    I3HelperSrv.prototype.setLoggedInStatus = function (status) {
        this.loggedIn = status;
    }
    I3HelperSrv.prototype.login = function (user, success, error) {


        var self = this;
        $http.post($rootScope.config.API_URI + '/' + "login", user).then(function (response) {
            if (response && response.data && response.data.success) {
                $window.sessionStorage.token = response.data.result.token;
                $rootScope.SESSION = response.data.result;
                $window.sessionStorage.SESSION = JSON.stringify(response.data.result);
                self.loggedIn = true;
                success(response.data);

            }

            else {

                delete $window.sessionStorage.token;
                delete  $window.sessionStorage.SESSION;
                self.loggedIn = false;

                error(response.data);
            }



        },function (data) {
            error(data);
            delete $window.sessionStorage.token;
            self.loggedIn = false;
        });
    }


    I3HelperSrv.prototype.logout = function (success) {
        delete $window.sessionStorage.token;
        delete $window.sessionStorage.SESSION;
        delete $window.sessionStorage.plateformToken;
        delete $window.sessionStorage.plateformSession;
        delete $window.sessionStorage.plateform;
        if (success) success()
    }
    I3HelperSrv.prototype.getEnums = function (success, error) {
        var self=this;
        if(this.ENUMS){

            // get it from cache and avoir ws call
            success(this.ENUMS);

        }else {
            // get it from api
            $http.get($rootScope.config.API_URI + '/enums').then(function (response) {
                // this callback will be called asynchronously
                // when the response is available
                if (response && response.data && response.data.success) {

                    success(response.data.result);
                    self.ENUMS= response.data.result;

                }
                else {

                    error(data);
                }


            }, function (data) {
                error(data)

            });
        }
  };

    I3HelperSrv.prototype.getFieldsByCollectionName = function (collectionName, success, error) {

    /*    // check if already loaded,
        if(service.ENUMS){

            // get it from cache and avoir ws call
            success(service.ENUMS);

        }else {
            // get it from api
            $http.get($rootScope.config.API_URI + '/enums').then(function (response) {
                // this callback will be called asynchronously
                // when the response is available
                if (response && response.data && response.data.success) {

                    success(response.data);
                    service.ENUMS= response.data;

                }
                else {

                    error(data);
                }


            }), function (data) {
                error(data)

            };
        }*/

      
            I3StatusSrv.query({
                query: {
                    model:collectionName
                },
                options: {}
            }, 1, 0, function (res) {
                if (res && res.result) {
                    success(res.result[0]);
                }
            })
    };


    I3HelperSrv.prototype.uploadFiles=function(files,data ,success,error,progress){
        //open progress bar
        try {
            var datas=data ? data : {};
            datas.file=files;
            if (files && files.length) {
                messageBox.showSpinnerPercent("UPLOAD", files);
                Upload.upload({
                    url: $rootScope.config.API_URI + '/' + "document",
                    data: datas
                }).then(function (response) {

                    try{
                        $rootScope.$broadcast("progressClose");
                        success(response.data);
                    }catch(e){
                        console.error(e);
                    }

                }, function (response) {

                    $rootScope.$broadcast("progressClose");


                    if (response.status > 0) {

                        error(response);
                    } else {
                        success(response);
                    }


                }, function (evt) {


                    $rootScope.$broadcast("progress", evt);
                    progress(evt);

                });
            } else {
                error("NO_FILES");
            }
        }catch(e){
            console.log("error",e);
        }


    }

    I3HelperSrv.prototype.downloadFile = function (id, fileName, success, error, progress) {
        //open progress bar
        messageBox.showSpinnerPercent("DOWNLOAD", fileName);
        $http({
            method: "GET",
            url: $rootScope.config.API_URI + '/' + "document/download/" + id,
            responseType: "blob",
            onProgress: function (event) {
                $timeout(function () {

                    progress(event);
                    $rootScope.$broadcast("progress", event)
                });
            }
        }).then(function (data, status, headers) {
            try {

                if(data && data.status==200){
                    // console.log(data , typeof data);
                    var data = new Blob([data.data]);
                    data.text().then(function (txt) {
                            try{
                                  var   jsonData=JSON.parse(txt);
                                    if(jsonData.success==false){
                                        $rootScope.$broadcast("progressClose");
                                        error("FILE_NOT_FOUND");
                                    }else {
                                        FileSaver.saveAs(data, fileName);
                                        $rootScope.$broadcast("progressClose");
                                        success("FILE_LOADED");
                                    }
                            }catch (e) {

                                FileSaver.saveAs(data, fileName);
                                $rootScope.$broadcast("progressClose");
                                success("FILE_LOADED");
                            }

                    },function(error){
                        $rootScope.$broadcast("progressClose");
                        error("FILE_NOT_FOUND");
                        console.log(error)
                    });

                }else{
                    $rootScope.$broadcast("progressClose");
                    if(data && data.status==404){
                        error("FILE_NOT_FOUND");
                    }else{
                        error("DOWNLOAD_FILE_ERROR");
                    }
                }

            } catch (e) {
                console.error(e);
            }

        }, function (data) {
            // console.log("",data)
            $rootScope.$broadcast("progressClose");

            error(data)
        })/*, function notify(evt) {
            console.log('notified', evt);
        });
*/
    }

    I3HelperSrv.prototype.getInstallationTempKey = function (id, success, error) {

        $http.get($rootScope.config.API_URI + '/installationgroup/temp_token/' + id).then(function (response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success) {
                success(response.data);
            }
            else if (!response.data.success && response.data.error) {
                success(response.data)
            }
            else {

                error(response);
            }


        }, function (data) {
            error(data)

        });
    };
    I3HelperSrv.prototype.getTplByName = function (tpl, success, error) {
        $http.get($rootScope.config.API_URI + '/report/getReportTpl/' + tpl).then(function (response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success !== false) {

                success(response.data);
            }
            else {

                error(data);
            }


        }, function (data) {
            error(data)

        });
    };
    I3HelperSrv.prototype.generatePassword = function () {
        String.prototype.pick = function (min, max) {
            var n, chars = '';

            if (typeof max === 'undefined') {
                n = min;
            } else {
                n = min + Math.floor(Math.random() * (max - min + 1));
            }

            for (var i = 0; i < n; i++) {
                chars += this.charAt(Math.floor(Math.random() * this.length));
            }

            return chars;
        };
        String.prototype.shuffle = function () {
            var array = this.split('');
            var tmp, current, top = array.length;

            if (top) while (--top) {
                current = Math.floor(Math.random() * (top + 1));
                tmp = array[current];
                array[current] = array[top];
                array[top] = tmp;
            }

            return array.join('');
        };
        var specials = '!@#$%&*_+?.-';
        var lowercase = 'abcdefghijklmnopqrstuvwxyz';
        var uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var numbers = '0123456789';

        var all = specials + lowercase + uppercase + numbers;

        var password = '';
        password += lowercase.pick(3);
        password += uppercase.pick(3);
        password += numbers.pick(1);
        password += specials.pick(1);
        password = password.shuffle();
        return password;
    }
    I3HelperSrv.prototype.downloadZip=function(params,fileName,success,error,progress){
        if(!progress) progress=function(){}
        messageBox.showSpinnerPercent("DOWNLOAD", fileName);
        $http({
            method: "POST",
            url: $rootScope.config.API_URI + '/' + "document/zip/",
            data: params,
            responseType: "blob",
            onProgress: function (event) {
                $timeout(function () {

                    progress(event);
                    $rootScope.$broadcast("progress", event)
                });
            }
        }).then(function (data, status, headers) {


            // console.log(">>>>>",data)
            try {

                if(data && data.status==200){
                    var data = new Blob([data.data]);
                    FileSaver.saveAs(data, fileName);
                    $rootScope.$broadcast("progressClose");
                    success("FILE_LOADED");
                }else{
                    $rootScope.$broadcast("progressClose");
                    if(data && data.status==501){
                        var data1 = new Blob([data.data]);
                       data1.text().then(function(text){
                        data.data =text;




                        if(data.data == "BAD_PARAMS"){
                            Notification.error($translate.instant("BAD_PARAMS_GROUP_ID"))
                        }
                            if(data.data == "EMPTY_FILE"){
                                Notification.error($translate.instant("EMPTY_FILE"))
                        }
                        if(data.data == "NO_FILE_FOUND_ACCESS"){
                            Notification.error($translate.instant("NO_FILE_FOUND_ACCESS"))
                        }
                        if(data.data == "BAD_PARAMS_GROUP_ID"){
                            Notification.error($translate.instant("BAD_PARAMS_GROUP_ID"))
                        }
                    })
                    //    error("FILE_NOT_FOUND");
                    }else{
                        Notification.error($translate.instant("DOWNLOAD_FILE_ERROR"))
                        error("DOWNLOAD_FILE_ERROR");
                    }
                }

            } catch (e) {
                console.error(e);
            }

        }, function (data) {
            // console.log("ffffffffff")
            // console.log("",data)
            $rootScope.$broadcast("progressClose");

            error(data)
        })/*, function notify(evt) {
            console.log('notified', evt);
        });
*/
    }

    I3HelperSrv.prototype.getPVSeqNextVal=function(platform,success,error){

        if(!platform){
            return Notification.error({title:$translate.instant("GENERATE_PV_NUMBER_ERROR"),message:$translate.instant("NO_PLATFORM_SET_GEN_SEQUENCE_PV_ERROR")});
        error("GENERATE_PV_NUMBER_ERROR");
        }
        var body={
            _plateforme:platform._id?platform._id:platform
        }
        $http.post($rootScope.config.API_URI + '/sequence/pv/getNextVal',body).then(function (response) {
            
            if (response && response.data && response.data.success !== false) {

                success(response.data);
            }
            else {

                error(data);
            }


        }, function (data) {
            error(data)

        });
    }
    I3HelperSrv.prototype.checkEditPermissionPV=function(pvId,success,error){
        $http.get($rootScope.config.API_URI + '/pv/'+pvId+"/check_edit_perm_inventory").then(function (response) {

            if (response && response.data && response.data.success !== false) {

                success(response.data);
            }
            else {

                error(response.data);
            }


        }, function (data) {
        
            error(data)
         
        });

    }
    return new I3HelperSrv();
});