angular.module("app").controller("receptionEditCtrl", function ($scope, $stateParams, $rootScope, $q, $filter, $state, $translate, ngDialog, Notification, I3ArticleSrv, I3StockSrv, I3ReceiptSlipSrv) {

    $scope.receipt = {};
    $scope.all_recepeits = [];
    $scope.duplicate_ref = false;

    I3ReceiptSlipSrv.query({
        query: {
            _id: $stateParams.receptionId
        },
        options: {
            populate: [{
                path: "_product",
                model: "product"
            }, {
                path: "_stock",
                model: "stock"
            }]
        }

    }, 10000, 1, function (res) {
        if (res.success) {
            $scope.receipt = res.result[0];
            console.log("TCL: $scope.receipt", $scope.receipt)
        }
    }, function (err) {
        console.log(err);
    });

    $scope.cancel = function () {
        $state.go('app.stocks.reception');
    };
    $scope.get_recepeits = function () {
        I3ReceiptSlipSrv.query({
            query: {},
            options: {}
        }, 10000, 1, function (res) {
            if (res.success) {
                $scope.all_recepeits = res.result
                console.log("$scope.rece", $scope.all_recepeits)
            }
        }, function (err) {
            console.log(err);
        });
    }();
    $scope.check_ref = function (ref) {
        var filterRef = $scope.all_recepeits.filter(function (val) {
            return ((val.ref == ref) && (val._id != $stateParams.receptionId))
        })
        if (filterRef && filterRef.length > 0 ) {
            $scope.duplicate_ref = true;
            return false
        } else {
            $scope.duplicate_ref = false;
            return true
        }
    }

    $scope.updateVirtualStock = function (stok, virtualStock) {
        console.log(stok, virtualStock)
        stok = $scope.receipt._stock
        virtualStock = $scope.receipt.virtual_stock
        if (stok && stok.virtual_stock && stok.virtual_stock.length > 0) {
            var filtred = stok.virtual_stock.filter(function (val) {
                return val._id == virtualStock;
            })
            if (!filtred || filtred.length <= 0) {
                $scope.receipt.virtual_stock = null;
            }
        } else if (stok) {
            $scope.receipt.virtual_stock = null;
        }

    }
    $scope.save = function () {
        $scope.receipt._plateforme = $scope.receipt._stock._plateforme ;
            I3ReceiptSlipSrv.updateById($scope.receipt._id, $scope.receipt, function (response) {
                if (response.success) {
                    //$scope.add_articles();
                    Notification.success({
                        title: $translate.instant('RECEIPT_EDITED_TITLE'),
                        message: $translate.instant("RECEIPT_EDITED_SUC")
                    });
                    $state.go('app.stocks.reception');
                    $scope._plateforme = {};
                } else {
                    Notification.error({
                        title: $translate.instant('ERROR'),
                        message: $translate.instant("ERROR_OCCURED_CREATE")
                    });
                    $scope.user = {};
                }
            }, function (err) {
                var msg = "";
                if (err.error.errorString) {
                    msg = err.error.errorString;
                }
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_CREATE")
                });
            })
        },
        function () {
            console.log('Failed:');
        };
})