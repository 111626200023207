angular.module('app').controller('adminPackagesCtrl', function ($scope, $rootScope, $state, $translate, $filter, Notification, ngDialog, I3PackageSrv,I3ProductSrv) {

    $scope.package = {};
    $scope.itemsByPage = 50;
    $scope.search = {};
    I3ProductSrv.query({}, 10000, 1,
        function (data) {
            if (data.success) {
                $scope._ADMIN.products = data.result;
                //console.log($scope._ADMIN.products)
            } else {
                console.error(data.error)
            }
        },
        function (data) {
            console.dir(data)

        })

    /**
     * Loading packages
     */
    I3PackageSrv.query({}, 10000, 1, function (response) {
        $scope.packages = response.result;
    }, function (err) {
        console.log(err);
    });

    /**
     * Getting users
     */
    $scope.loadProducts  = function (tableState,ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }

        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;

        var options =  {populate:[{
                path: '_products',
                select: 'name'
            }]};

        var query = {};

        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
   }


        if(!$scope.search.name) {
            if (tableState.search && tableState.search.predicateObject) {
                if (tableState.search.predicateObject.name) {
                    $scope.search.name = tableState.search.predicateObject.name
                    query["name"] = {"$regex": tableState.search.predicateObject.name, "$options": "i"};
                }
            }else{
                query.name = {'$regex' :'', '$options' : 'i'}
            }
        }else{
            if (tableState.search && tableState.search.predicateObject) {
                tableState.search.predicateObject.name = $scope.search.name
            }
            query.name = {'$regex' :$scope.search.name, '$options' : 'i'}
        }

        if (tableState.search && tableState.search.predicateObject) {
            $scope.search.name = tableState.search.predicateObject.name
        }


        I3PackageSrv.query({query: query,options : options} , number, start, function (response) {
            if(response.success){
                $scope.packages = response.result;
                $scope.packageCount = response.total_count;
                tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
                tableState.pagination.totalItemCount = response.total_count;
                $scope.tableState = tableState;
            } else {
                console.error(response.error)
              }


            }, function (response) {
            //   console.log(response)

            })
        
    };


    /**
     * Removing package
     * @param package
     */
    $scope.remove = function (element) {
        $scope.package = element;

        ngDialog.open({
            templateUrl : "app/commons/dialogs/confirm/package.delete.dlg.tpl.html",
            controller : "confirmDeletePackageDlgCtrl",
            scope: $scope
        })

    };

});