angular.module('app.dialogs').controller('beginDateDlgCtrl', function ($scope) {
    $scope.moment = moment();
    var now = moment().clone();
    var inventory = $scope.ngDialogData.prop;
    var creation_date_start = moment(new Date(inventory.creation_dt));
    var end_date = moment(new Date(inventory.end_dt));
    $scope.dayRes = now;
    $scope.pickerDay = {
        format: "DD/MM/YYYY",
        position: "top left",
        init: moment(inventory.begin_dt),
        autoTrans: false,
        enableTime: false,
        enableDate: true,
        enableMinutes: false,
        defaultView: "DATE",
        maxDate: end_date,
        minDate: creation_date_start,
        getDate: function (date) {
            $scope.dayRes = date;
        }
    };
    $scope.EditDate = function () {
        $scope.closeThisDialog($scope.dayRes);
    }
    $scope.cancel = function () {
        $scope.closeThisDialog(false);
    }
});