angular.module('app').controller('adminDashboardCtrl', function ($scope, $rootScope, $state, $translate, $filter, Notification, ngDialog, I3PlateformeSrv, plateformHelperSrv, i3HelperSrv) {


    /**
     * Loading platform type
     */
    $scope.search = {};
    $scope.itemsByPage = 50;
    I3PlateformeSrv.query({}, 10000, 1, function (response) {
        $scope.platforms = response.result;
    }, function (err) {
        console.log(err);
    });

    /**
     * Removing dashboard
     * 
     */
    $scope.loadPlatforms = function (tableState, ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }

        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;

        var options = {
            populate: [
                {
                    path: 'plateforme',
                    select: 'name'
                }


            ],
            sortBy: { creation_dt: -1 }
        };

        var query = { options: options };


        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
        }
        var requery = {};


      
       
        if ($scope.search) {
           
            if ($scope.search._plateforme) {
                requery["name"] = $scope.search._plateforme.name;
               
            }

        }
    
        query.query = requery;
      
        I3PlateformeSrv.query(query, number, start, function (response) {
        
            if (response.success) {
                $scope.listPlatforms = response.result;
                $scope.PlatformCount = response.total_count;
                tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
                tableState.pagination.totalItemCount = response.total_count;
                $scope.tableState = tableState;
            }
        });
    };
 

});