/* eslint-disable angular/angularelement */
/* eslint-disable angular/document-service */
/* eslint-disable no-unused-vars */
angular.module('app').controller('compatibilityRequestAddCtrl', function ($scope, $timeout, $rootScope, $state, $filter, ngDialog, CompatibilityUtilsSrv, I3CompatibilitySrv, $translate, constantSrv, Notification, I3ClientSrv, ExceptionUtilsSrv) {
    //#region scopes
    //vars and scopes
   
    $scope.com = [];
    $scope.front = [];
    $scope.loading = false;
    $scope.auto = false;
    $scope.selected = null;
    $scope.previous = null;
    $scope.content = "";
    $scope.filename = "";
    $scope.mode = 0;
    $scope.selectedIndex = 0;
    $scope.currentPage = 0;
    $scope.pageSize = 25;
    $scope.current_asset = {};
    $scope.numberOfPages = 1;
    $scope.errorCount = 0
    $scope.Object = Object;
    //#endregion
    //#region initial data
    $scope.optionsList = CompatibilityUtilsSrv.accessoriesList;
    constantSrv.getModel("COMPATIBILITY").getAllStatus(function (err, status) {
        $scope.statusCompatibility = status.map(function (val) {
            return val.name
        });
    });
    //#endregion
    //#region assetslide and filters
    //set currentPage to next
    $scope.nextpage = function () {
        $scope.auto = false;
        $scope.selectedIndex = 0;
        $scope.currentPage = $scope.currentPage + 1;
    };
    //set currentPage to previous
    $scope.previouspage = function () {
        $scope.auto = false;
        $scope.selectedIndex = 0;
        $scope.currentPage = $scope.currentPage - 1;
    };
    //reset current page and selectedIndex after search
    $scope.resetindex = function () {
        if ($scope.namev && $scope.namev.trim() != '') {
            $scope.currentPage = 0;
            $scope.selectedIndex = 0;
        }
    };
    //watch for asset results change for showing the progress bar
    $scope.$watch('limited_fname_fstatus_asset[selectedIndex]', function (newVal, oldVal) {
        if ($scope.limited_fname_fstatus_asset && $scope.limited_fname_fstatus_asset[$scope.selectedIndex]) {
            $scope.current_asset = angular.copy(newVal);
            $scope.reset_asset = angular.copy(newVal);
        }
    });
    $scope.$watch(function () {
        $scope.limited_fname_fstatus_asset = $scope.$eval("compatibilityRequest.assets | limitTo:pageSize : currentPage*pageSize");
    });
    //watch for asset results change for showing the progress bar
    $scope.$watch('compatibilityRequest.assets.length', function (newVal, oldVal) {
        //$scope.loading = true;
        if ($scope.compatibilityRequest) {
            $scope.numberOfPages = Math.floor(((newVal - 1) / $scope.pageSize));
        } else {
            $scope.numberOfPages = 1;
        }
        /*  $timeout(function () {}, 0).then(function () {
             $scope.loading = false;
         }); */
    });
    //can't disable all asset status and reset current page and selectedIndex after availables status change
    $(":checkbox").click(function () {
        $scope.currentPage = 0;
        $scope.selectedIndex = 0;
        if ($("input:checked").length == 1) {
            $(':checked').prop('disabled', true);
        } else {
            $(':checked').prop('disabled', false);
        }
    });
    //filter asset by name
    $scope.name_filter = function (asset) {
        return true;
    };
    //filter assets (with a searched term) by status
    $scope.filterStatus = function (item, index, array) {
        return true;
    };
    $scope.quertstatus_new = function (item, index, array) {
        return $scope.fname_asset[index].status == "NEW"
    };
    // get the length of filtered assets (with a searched term) by the status VALIDATED
    $scope.quertstatus_vld = function (item, index, array) {
        return $scope.fname_asset[index].status == "VALIDATED"
    };
    $scope.check_photo_or_vin = function (ref, value) {
        if (!ref && !value) {
            return false
        } else {
            return true
        }
    };
    //#endregion
    //#region import data
    $scope.importOptions = {
        onUpdate: function (er, res) {
            $scope.$apply(function () {
                $scope.compatibilityRequest = res;
             
             //   console.log('$scope.compatibilityRequest:', $scope.compatibilityRequest)


                if (res && res.errors.count == 0) {

                    var groupedByZone = CompatibilityUtilsSrv.groupByZone($scope.compatibilityRequest);
                    $scope.zoneStats = {};
                    $scope.globalStats = CompatibilityUtilsSrv.getOptionsStats($scope.compatibilityRequest.assets);

                    Object.keys(groupedByZone).forEach(function (zone) {
                        $scope.zoneStats[zone] = CompatibilityUtilsSrv.getOptionsStats(groupedByZone[zone]);
                    })

                    var query ={};

                            if($scope.compatibilityRequest && $scope.compatibilityRequest.code_client &&  $scope.compatibilityRequest.code_client.length>0 ){
                              
                                query.code_client=$scope.compatibilityRequest.code_client
                            }else{
                               
                                query.name =  $scope.compatibilityRequest.client
                            }





                    I3ClientSrv.query({
                        query: query,
                        options: { populate: '_plateforme' }
                    }, 10000, 1, function (res) {
                   
                

                        if (res.success && res.result.length > 0) {
                            $scope.compatibilityRequest.client = res.result[0]
                            $scope.compatibilityRequest.code_client = res.result[0].code_client || null;
                            $scope.compatibilityRequest.clientSav_id = res.result[0]._id;
                            $scope.compatibilityRequest._plateforme = res.result[0]._plateforme

                        } else {
                            $scope.compatibilityRequest.client = null
                            $scope.compatibilityRequest.code_client = null
                            I3ClientSrv.query({
                                query: {
                                    _plateforme: $scope.compatibilityRequest._plateforme._id,


                                },

                            }, 10000, 1, function (res) {

                                if (res.success && res.result.length > 0) {
                                    $scope.clients = res.result
                                }

                            }, function (err) {
                               
                               

                            })
                        }

                    }, function (err) {
                       
                        

                    })





                }
            })
        }
    };
    $scope.reset = function ($event) {
        $scope.zoneStats = null;
        $scope.globalStats = null;
        $event.stopPropagation();
        var file = $("#file").val("");
        file.value = '';
        $scope.mode = 2;

        //$scope.compatibilityRequest = {};
    };
    $scope.save = function (compatibilityRequest) {


        var compatibility = angular.copy(compatibilityRequest);
        delete compatibility.errors;
        compatibility.assets = compatibility.assets.map(function (asset) {

            delete asset.errors;
            return asset;
        });
        if (compatibility._plateforme && compatibility._plateforme._id) {
            I3ClientSrv.query({
                query: {

                    _plateforme: compatibility._plateforme._id,
                    name: compatibility.client,


                }
            }, 1000, 1, function (res) {
               // console.log('res:', res)
                if (res.result.length > 0) {
                    compatibility.clientSav_id = res.result[0];
                    $scope.save_req(compatibility);
                } else {
                    $scope.save_req(compatibility);
                }


            }, function (err) {

            })
        }



    };
    //#endregion
    //#region update data
    $scope.change_state = function (asset, valid) {
        if (valid) {
            asset.save_state = 'saved';
            $scope.current_asset.save_state = 'saved';
        } else {
            asset.save_state = 'unsaved';
            $scope.current_asset.save_state = 'unsaved';
        }
    };
    $scope.change_state2 = function (asset, form) {
        if (form.assetBrand_4.$valid && form.assetModel_7.$valid && form.assetName_1.$valid && form.assetPlace_name_6.$valid && form.assetPlace_zone_3.$valid && form.assetTank_max_8.$valid && form.assetTotal_card_number_5.$valid) {
            asset.save_state = 'saved';
            $scope.current_asset.save_state = 'saved';
        } else {
            asset.save_state = 'unsaved';
            $scope.current_asset.save_state = 'unsaved';
        }
    };

    $scope.manuelle = function () {
        $scope.mode = 1;
        $scope.compatibilityRequest = {};
        $scope.compatibilityRequest.status = "NEW";
        if ($rootScope.checkAccess("PLATEFORME", "R", "O"))
            $scope.compatibilityRequest._plateforme = $scope._HOME.platforms[0];
        //$scope.compatibilityRequest.status = $scope.statusCompatibility[0].name
        $scope.compatibilityRequest.assets = [{
            name: '',
            brand: '',
            model: '',
            vin: '',
            tank_max: '',
            place_name: '',
            place_zone: '',
            total_card_number: '',
            gray_card_front: null,
            gray_card_back: null,
            status: 'NEW',
            isnew: true,
            wasnew: false,
            save_state: 'unsaved',
            req_options: {
                canbus: 0,
                device: 0,
                direct_line: 0,
                door_sensor: 0,
                group_cold_sensor: 0,
                ibutton_reader: 0,
                ibutton_tag: 0,
                immobilizer_12V: 0,
                immobilizer_24V: 0,
                sim_supplier_ngi: 0,
                temp_probe: 0
            },
            comments: []
        }];
        $scope.zoneStats = null;
        $scope.globalStats = null;
    };
    $scope.add = function () {
        $scope.auto = true;
        $scope.compatibilityRequest.assets.push({
            name: '',
            brand: '',
            model: '',
            vin: '',
            tank_max: '',
            place_name: '',
            place_zone: '',
            total_card_number: '',
            gray_card_front: null,
            gray_card_back: null,
            status: 'NEW',
            isnew: true,
            wasnew: false,
            save_state: 'unsaved',
            req_options: {
                canbus: 0,
                device: 0,
                direct_line: 0,
                door_sensor: 0,
                group_cold_sensor: 0,
                ibutton_reader: 0,
                ibutton_tag: 0,
                immobilizer_12V: 0,
                immobilizer_24V: 0,
                sim_supplier_ngi: 0,
                temp_probe: 0
            },
            comments: []
        });

        //$scope.currentPage = $scope.numberOfPages;
        if ($scope.compatibilityRequest.assets.length % $scope.pageSize != 1) {
            $scope.currentPage = Math.floor((($scope.compatibilityRequest.assets.length - 1) / $scope.pageSize));
            $scope.selectedIndex = 0;
        } else {
            $scope.currentPage = Math.floor((($scope.compatibilityRequest.assets.length - 1) / $scope.pageSize));
        }
    };
    $scope.remove_asset = function (asset) {
        $scope.auto = true;
        if (asset.isnew && !asset.wasnew) {
            $scope.compatibilityRequest.assets.splice($scope.compatibilityRequest.assets.indexOf(asset), 1);
            if ($scope.limited_fname_fstatus_asset && $scope.limited_fname_fstatus_asset.length == 1) {
                $scope.previouspage();
            }
        }
    };
    $scope.save2 = function (compatibilityRequest) {

        angular.forEach(compatibilityRequest.assets, function (asset, key) {
            if (asset.place_id) {
                asset.place_zone = asset.place_id.name;

            }

        })
        var compatibility = angular.copy(compatibilityRequest);
        $scope.save_req(compatibility);
    };
    // final save
    $scope.save_req = function (compatibility) {

        if (compatibility.assets && compatibility.assets.length > 0) {
            var zones = $scope._HOME.zones.filter(function (zz) {
                return zz._plateforme == compatibility._plateforme && compatibility._plateforme._id ? compatibility._plateforme._id : compatibility._plateforme;
            });
            compatibility.assets.forEach(function (val) {
                if (val.gray_card_front && val.gray_card_front.dataURL && val.gray_card_front.dataURL.length) {
                    val.gray_card_front = val.gray_card_front.dataURL
                }
                if (val.gray_card_back && val.gray_card_back.dataURL && val.gray_card_back.dataURL.length) {
                    val.gray_card_back = val.gray_card_back.dataURL
                }
                zones.forEach(function (zone) {
                    if (zone.name.toLowerCase().split(" ").join("") == val.place_zone.toLowerCase().split(" ").join("")) {
                        val.place_id = zone._id;
                    }
                })
            })
        }

        compatibility.code_client = (compatibility.client && compatibility.client.code_client) ? compatibility.client.code_client : compatibility.code_client;
        compatibility.clientSav_id = (compatibility.client && compatibility.client._id) ? compatibility.client : compatibility.clientSav_id;
        compatibility.client = (compatibility.client && compatibility.client.name) ? compatibility.client.name : compatibility.client;


        I3CompatibilitySrv.add(compatibility, function (res) {
            try {
                if (res && res.success) {
                    Notification.success({
                        title: $translate.instant("CREATE_SUCC"),
                        message: $translate.instant("ADD_COMPATIBILITY_REQUEST_SUCCESS")
                    });
                    $state.go('app.home.compatibilityRequest.edit', {
                        compatibilityId: res.result._id
                    });
                } else {
                    if (res.error && res.error && res.error.details) {
                        if (res.error.details.errors) {
                            var exception = ExceptionUtilsSrv.processDBError(res.error.details);
                            if (exception) {
                                Notification.error({
                                    message: exception.msg,
                                    title: exception.title
                                })
                            } else {
                                Notification.error({
                                    title: $translate.instant("ERROR"),
                                    message: $translate.instant("COMPATIBILITY_REQUEST_FAILED")
                                });
                            }
                        } else {
                            Notification.error({
                                title: $translate.instant("ERROR"),
                                message: $translate.instant("COMPATIBILITY_REQUEST_FAILED")
                            });
                        }
                    } else {
                        Notification.error({
                            title: $translate.instant("ERROR"),
                            message: $translate.instant("COMPATIBILITY_REQUEST_FAILED")
                        });
                    }
                }
            } catch (e) {
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("COMPATIBILITY_REQUEST_FAILED")
                });
            }
        }, function (err) {
         //   console.log(err);
            Notification.error({
                title: $translate.instant("ERROR"),
                message: $translate.instant("COMPATIBILITY_REQUEST_FAILED")
            });
        });
    };
    //#endregion
    //#region comments
    $scope.show_com = function (x, y) {
        if (y > 0) {
            $scope.com[x] = !$scope.com[x];
        }
    };
    $scope.addComment = function (content, asset) {
        if (content) {
            var commentAdded = {};
            commentAdded.date = new Date();
            commentAdded._user = $rootScope.SESSION.user._id;
            commentAdded.comment = content;
            commentAdded.new = true;
            asset.comments.push(commentAdded);
            content = "";
            $scope.content = "";
            //asset.save_state = 'unsaved';
            //$scope.current_asset.save_state = 'unsaved';
        }
    };
    $scope.deleteAssetComment = function (index, asset) {
        asset.comments.splice(index, 1);
        //asset.save_state = 'unsaved';
        //$scope.current_asset.save_state = 'unsaved';
    };
    $scope.comp_d = function (comment) {
        if (comment.new) {
            return true
        } else return false
    };
    //#endregion comments
    //#region photos
    $scope.afficher_front = function (x) {
        var modal = document.getElementById('myModal');
        var img = document.getElementById("assetGray_card_front");
        var modalImg = document.getElementById("img01");
        var captionText = document.getElementById("caption");
        var span = document.getElementsByClassName("close")[0];
        modal.style.display = "block";
        modalImg.src = $scope.getImageUrl(x);
        captionText.innerHTML = $translate.instant("FRONT_PIC");
    };
    $scope.afficher_back = function (x) {
        var modal = document.getElementById('myModal');
        var img = document.getElementById("assetGray_card_back");
        var modalImg = document.getElementById("img01");
        var captionText = document.getElementById("caption");
        var span = document.getElementsByClassName("close")[0];
        modal.style.display = "block";
        //   modalImg.src = x.dataURL;
        modalImg.src = $scope.getImageUrl(x);
        captionText.innerHTML = $translate.instant("BACK_PIC");
    };
    $scope.close = function () {
        var modal = document.getElementById('myModal');
        modal.style.display = "none";
    };
    //#endregion photos

    $scope.getImageUrl = function (path) {
        if (path) {

            if (path.dataURL) {
                path = path.dataURL
            }
            if (path.indexOf("data:image") >= 0) {
                return path;
            }
        }
    }
    $scope.uploadFile = function () {

        ngDialog.open({
            templateUrl: "app/commons/dialogs/compatibilty/compatibilty.dlg.tpl.html",
            className: 'ngdialog-theme-default custom-width-500',
            controller: "importCompatibiliteDlgCtrl",
            appendClassName: 'ngdialog-custom',
            scope: $scope,

        })

    }



    $scope.searchClient = function ($select) {

        if ($scope.compatibilityRequest._plateforme && $scope.compatibilityRequest._plateforme._id) {
            I3ClientSrv.query({
                query: {

                    _plateforme: $scope.compatibilityRequest._plateforme._id,
                    name: {
                        '$regex': $select.search,
                        '$options': 'i'
                    }
                }
            }, 10, 0, function (res) {

                $scope.clients = res.result;
            }, function (err) {

            })
        }

    };

    $scope.selectPlateform = function (platform) {


        I3ClientSrv.query({
            query: {
                _plateforme: platform._id
            },
            options: { populate: '_plateforme' }
        }, 10, 1, function (res) {
            if (res && res.result) {
                $scope.clients = res.result;

            }
        })




    }



    $scope.addClient = function () {

        ngDialog.open({
            templateUrl: "app/commons/dialogs/compatibilty/client.add.dlg.tpl.html",
            className: 'ngdialog-theme-default custom-width-500',
            controller: "addClientCompatibiltyDlgCtrl",
            appendClassName: 'ngdialog-custom',
            scope: $scope,


        })

    }

    $scope.selectPlateforme = function (plateforme) {

        $scope.compatibilityRequest.client = null
        $scope.compatibilityRequest.code_client = null

        I3ClientSrv.query({
            query: {
                _plateforme: plateforme._id
            },
            options: { populate: '_plateforme' }
        }, 10, 1, function (res) {
            if (res && res.result) {
                $scope.clients = res.result;

            }
        })



    }

    $scope.selectClient = function (client) {

        $scope.compatibilityRequest.code_client = client.code_client

    }

});