angular.module('app').controller('adminProductCodeCtrl',function ($scope,$translate,ngDialog,I3ProductCodeSrv,i3HelperSrv,$state) {
    $scope.itemsByPage = 50;
    $scope.search = {};
    $scope.status=[] ;
    $scope.codeProduct = {}
    $scope.cancel=function(){
        $state.go('app.admin.product_code');
    }
    i3HelperSrv.getEnums(function(res){
        try {
            if (res) {

                console.log(res)
                $scope.PRODUCT_TYPES = [];
                Object.keys(res.code_products).forEach(function (e) {
                    $scope.PRODUCT_TYPES.push({code: e, value: res.code_products[e]});
                })
            }

        }catch (e){
            console.error(e);
        }
    },function(err){
        Notification.error("GET_PRODUCT_TYPE_ERROR");
    })

    $scope.loadProductCodes = function (tableState,ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }

        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;

        var query ={};
        query.query={};

        I3ProductCodeSrv.query(query , number, start, function (response) {
        
            if(response.success){
                $scope.productCodes = response.result;
                $scope.productCodesCount = response.total_count;
                tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
                tableState.pagination.totalItemCount = response.total_count;


            } else {
                console.error(response.error)
              }


            }, function (response) {
            //   console.log(response)

            })
        
    };

    $scope.remove = function (productCode) {
     
        $scope.codeProduct = productCode;

        ngDialog.open({
            templateUrl: "app/commons/dialogs/confirm/productCode.delete.dlg.tpl.html",
            controller: "confirmDeleteProductCodeDlgCtrl",
            scope: $scope
        })

    };


});