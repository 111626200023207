/**
 * Project: ngiTracking
 * Created by NGI team 15/02/2017.
 */

/**
 * name space ngiWebMap
 * @type {{}}
 */
var ngiWebMap = {};


/**
 * simple point object
 * @param lng longitude
 * @param lat latitude
 * @param name name of point
 * @param description description of point
 * @param icon point icon path
 * @param addressDetails the point    address details. object contains address Element (country, countrycode, city , postalcode,...   )
 * @constructor
 */

ngiWebMap.Point = function (lng, lat, name, description, icon, addressDetails) {

    this.lng = lng;
    this.lat = lat;
    this.name = name;
    this.description = description;
    this.icon = icon;
    this.addressDetails = addressDetails;
    /**
     * build address
     * @param country
     * @param city
     * @param postalCode
     * @param street
     * @param streetNumber
     * @param CountryCode
     */
    this.buildAddress = function (country, city, postalCode, street, streetNumber, district, CountryCode) {
        this.addressDetails = {};
        this.addressDetails.country = country;
        this.addressDetails.city = city;
        this.addressDetails.district = district;
        this.addressDetails.postalCode = postalCode;
        this.addressDetails.street = street;
        this.addressDetails.countryCode = CountryCode;
        this.addressDetails.streetNumber = streetNumber;
        var address = "";
        if (streetNumber != null && streetNumber != undefined) {
            address = streetNumber
        }
        if (street != null && street != undefined && street != "") {
            if(address=="")
                address += street;
             else
            address += " " + street;
        }
        if (postalCode != null && postalCode != undefined && postalCode != "") {
            address += ", " + postalCode;
        }
        if (district != null && district != undefined && district != "") {
            address += ", " + district;
        }
        if (city != null && city != undefined  && city != "") {
            if(address=="")
                address = city;
            else
                address += ", " + city;
        }
        if (country != null && country != undefined && country != "") {
            if(address=="")
                address = country;
            else
                address += " " + country;
        }
        this.displayAddress = (address.length > 0 ? address : null);
    }


    if (addressDetails != undefined && addressDetails != null) {
        this.buildAddress(addressDetails.country, addressDetails.city, addressDetails.postalCode, addressDetails.street, addressDetails.streetNumber, addressDetails.district, addressDetails.CountryCode);
    }

    /**
     * get   country of the point
     * @returns country name
     */

    this.getCountry = function () {
        return (this.addressDetails != undefined && this.addressDetails != null && this.addressDetails.country != undefined) ? this.addressDetails.country : null;
    }
    /**
     * get point city
     * @returns  city name
     */
    this.getCity = function () {
        return (this.addressDetails != undefined && this.addressDetails != null && this.addressDetails.city != undefined) ? this.addressDetails.city : null;
    }
    /**
     *get postal code of the point
     * @returns {point.addressDetails.postalCode}
     */
    this.getPostalCode = function () {
        return (this.addressDetails != undefined && this.addressDetails != null && this.addressDetails.postalCode != undefined) ? this.addressDetails.postalCode : null;
    }
    /**
     * return the street name of the point
     * @returns {point.addressDetails.street}
     */
    this.getStreet = function () {
        return (this.addressDetails != undefined && this.addressDetails != null && this.addressDetails.street != undefined) ? this.addressDetails.street : null;
    }
    this.getStreetNumber = function () {
        return (this.addressDetails != undefined && this.addressDetails != null && this.addressDetails.streetNumber != undefined) ? this.addressDetails.streetNumber : null;
    }

    /**
     * get point coordonate
     * @returns an array contains [longitude,latitude] of the point. [0,0] if the one of the parameters (longitude, latitude) is not significant
     *
     */
    this.getCoordonate = function () {
        if (!(this.lng) || !(this.lat)) {
            return [lng, lat];
        } else {
            return [0, 0];
        }
    }




    return this;
}


function zeroPad(num, places) {

    var zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
}
function processTemplate(template) {
    var style = "";
    if (template.mainColor) {
        style += ".mainMenu {background-color: " + template.mainColor + ";}";
        style += ".left-menu {background-color: " + template.mainColor + "; border-left: 1px solid " + template.mainColor + "; border-right: 1px solid" + template.mainColor + ";}";
        // style+=".logo {background-color: "+template.mainColor+";}";
        style += ".menu {background-color: " + template.mainColor + ";}";


        style += ".menu-container ul {background-color: " + template.mainColor + ";}";
        style += ".nav-tabs  li    a {background-color: " + template.mainColor + " !important;}";
        style += ".nav-tabs   li  a:hover {background-color: " + template.mainColor + "!important;border-color:" + template.mainColor + ";}";

        style += ".nav-tabs    .active         a {background-color: " + template.mainColor + " !important;}";
        /*  style+=".menu-container table tr {color: "+template.mainColor+" !important;}";
         style+=".boutonItineraire {color: "+template.mainColor+" !important;}";
         style+=".menu-header-label {color: "+template.mainColor+";}";
         style+=".menu-container p {color: "+template.mainColor+";}";*/


    }


    return style;
}
