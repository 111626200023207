angular.module('app').controller('statusEditCtrl',function ($scope,$stateParams,$translate,ngDialog,I3StatusSrv, Notification,$state) {
    $scope.status = {};
    $scope.Models =["COMPATIBILITY","COMPATIBILITY_STUDY","INSTALLATION_REQUEST","INSTALLATION","INTERVENTION","CLAIM"]

    I3StatusSrv.query({
        query: {
            _id: $stateParams.statusId
        },
        options: {}
    }, 1, 0, function (res) {
        if (res && res.result) {
            $scope.status = res.result[0];
        }
    })

    $scope.addStatus = function () {
        I3StatusSrv.updateById($scope.status._id, $scope.status, function (response) {
         //  console.log("$scope.status",$scope.status)
            if (response.success) {
                Notification.success({
                    title: $translate.instant("STATUS_UPDATED"),
                    message: $translate.instant("STATUS_UPDATED_SUCC")
                });
                $state.go('app.admin.status');

                $scope.status = {};
            } else {
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_UPDATE") + "!"
                });
                $scope.status = {};
            }
        }, function (err) {
            console.log(err);

            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("ERROR_OCCURED_UDPDATE") + "!"
            });
            $scope.status = {};

        })
    };
    /**
     * Cancel creating/editing status
     */
    $scope.cancel = function () {
        $scope.status = {};
        $state.go('app.admin.status');
    };

    $scope.openStatusDialogue = function () {

        ngDialog.open({
            templateUrl: "app/commons/dialogs/status/status.dlg.html",
            controller: "addStatusDlgCtrl",
            scope: $scope
        })
    };

    
    $scope.editstatus= function (element, index) {
        ngDialog.open({
            templateUrl: "app/commons/dialogs/status/status.edit.dlg.html",
            controller: "editStatusDlgCtrl",
            scope: $scope,
            resolve: {
                index: function () {
                    return index;
                }
            }
        })
    };

    $scope.removeStatus = function (element, index) {
        $scope.status.status.splice(index, 1);
    };


});