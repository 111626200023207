
angular.module('app').controller('zoneSynchroneCtrl', function ($scope, $rootScope, $state, $translate, $filter, Notification, ngDialog,I3ZoneSrv,invoiceZoneSrv) {


    invoiceZoneSrv.query({}, 1000, 0, function (res) {
        if (res && res.result) {
           $scope.invoiceZones=res.result
      
        }
    })
    
$scope.loadZones=function(){

    var start = 0;
    var number = 100;
    var options = {
        populate: [
           
           
         ],
         sortBy: {creation_dt: -1}
     };
     var query = {options: options};
  //  var requery = {external_id:{ $exists: true }};
//query.query = requery;

I3ZoneSrv.query(query, number, start, function (res) {
        if (res && res.result) {
           $scope.zones=res.result;
    
    
        }
    })



}


    $scope.synchroneZone=function(){
 
        ngDialog.open({
            templateUrl: "app/commons/dialogs/invoiceSynchrone/synchroneZone.dlg.tpl.html",
            controller: "synchroneZoneDlgCtrl",
            className: 'ngdialog-theme-default custom-width-800',
            appendClassName: 'ngdialog-custom',
            scope: $scope
        })
    }
    $scope.cancel = function () {

        $state.go('app.admin.invoiceConnector');
    };
})

