angular.module('app.dialogs').controller('confirmDeletereturnSlipDlgCtrl', function ($scope, $rootScope, ngDialog, I3GoodsReturnSlipSrv, Notification, $translate) {

    $scope.confirm = function () {
        I3GoodsReturnSlipSrv.delete($scope.returnSlip._id, function (response) {

            if(response.success){
                //$scope.products.splice($scope.products.indexOf($scope.product), 1);

                Notification.success({
                    title: $translate.instant("GOODS_RETURN_SLIP_DELETED_TITLE"),
                    message: $translate.instant("GOODS_RETURN_SLIP_DELETED_SUC")
                });
                $scope.loadGoodsReturnSlip()
                $scope.returnSlip = {};
            }else{
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("GOODS_RETURN_SLIP_DELETED_ERROR")
                });
                $scope.returnSlip = {};
            }
        }, function (err) {
            console.log(err);

            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("GOODS_RETURN_SLIP_DELETED_ERROR")
            });

            $scope.returnSlip = {};
        });

        $scope.returnSlip = {};
        $scope.closeThisDialog();
    }

});