angular.module('app').controller('installationRequestEditCtrl', function ($rootScope, $scope, I3InstallationGroupSrv, constantSrv, messageBox, i3HelperSrv, ngDialog, Notification, $state, $stateParams, $translate, ExceptionUtilsSrv) {
    $scope.liststatusavailInstallation = [];
    $scope.installationsStatus = [];
    $scope.deniedStatusTransition = {
        //NEW,READY,IN_PROGRESS,ON_HOLD,COMMISSIONING,DONE,CLOSED,CANCELED
        NEW: ["IN_PROGRESS", "ON_HOLD", "COMMISSIONING", "DONE", "CLOSED"],
        READY: ["IN_PROGRESS", "ON_HOLD", "COMMISSIONING", "DONE", "CLOSED"],
        IN_PROGRESS: ["NEW", "READY", "ON_HOLD", "COMMISSIONING", "DONE", "CLOSED", "CANCELED"],
        ON_HOLD: ["NEW", "READY", "ON_HOLD", "COMMISSIONING", "DONE", "CLOSED", "CANCELED", "IN_PROGRESS"],
        COMMISSIONING: ["NEW", "READY", "ON_HOLD", "COMMISSIONING", "DONE", "CLOSED", "CANCELED", "IN_PROGRESS"],
        DONE: ["NEW", "READY", "ON_HOLD", "COMMISSIONING", "DONE", "CANCELED", "IN_PROGRESS"],
        CLOSED: ["NEW", "READY", "ON_HOLD", "COMMISSIONING", "DONE", "CLOSED", "CANCELED", "IN_PROGRESS"],
        CANCELED: ["NEW", "ON_HOLD", "COMMISSIONING", "DONE", "CLOSED", "CANCELED", "IN_PROGRESS"],

    }

    $scope.disab_input_status = ["CANCELED", "CLOSED"];
    $scope.input_active = true;
    $scope.timeleft = 0;
    $scope.now = moment()
    $scope.redv = 0;

    $scope.getvalue = function (deadline, deadline_period) {
        if (deadline && deadline_period) {
            var deadline_start = moment(deadline).subtract(deadline_period, 'h');
            console.log(deadline_start)
            var date2 = moment(deadline);
            var date1 = moment();
            $scope.date_limite = date2;
            var differenceInMs = date2.diff(date1); // diff yields milliseconds
            var duration = moment.duration(differenceInMs);
            var differenceInHours = Math.floor(duration.asHours());

            if (-differenceInHours >= 1000) {
                $scope.timeleft = 1000
            } else
                $scope.timeleft = -differenceInHours;
            var redv = Math.floor(100 - ((differenceInHours / deadline_period) * 100))
            if (redv >= 100) redv = 100
            console.log(redv)
            return redv
        }
    }

    // $scope.installRequestEditStatus = constantSrv.getInstallRequestEditStatus();
    constantSrv.getModel("INSTALLATION_REQUEST").getAllStatus(function (err, stat) {

        $scope.installationsStatus = stat.map(function (val) {
            return val.name
        })
    })
    var installationRequestSaved;
    var installationRequestToedit;
    I3InstallationGroupSrv.query({
        query: {
            _id: $stateParams.id
        },
        options: {
            populate: [{
                path: "_plateforme"
            }, {
                path: "_created_by",
                select: 'login'
            }, {
                path: "docs assets.docs"
            }, ]
        }
    }, 1, 0, function (res) {

        if (res && res.success && res.result.length > 0) {

            $scope.installationRequest = res.result[0];
            $scope.redv = $scope.getvalue($scope.installationRequest.dead_line, $scope.installationRequest.dead_line_period);
            if ($scope.disab_input_status.indexOf($scope.installationRequest.status) == -1) {
                $scope.input_active = true;
            } else {
                $scope.input_active = false;
            }
            installationRequestSaved = angular.copy(res.result[0]);
            installationRequestToedit = angular.copy(res.result[0]);
            constantSrv.getModel("INSTALLATION_REQUEST").getAvailableStatusByStatus(installationRequestSaved.status, function (err, val) {

                try {
                    $scope.liststatusavailInstallation = val._availStatus.map(function (elem) {
                        return elem.name
                    }).filter(function (e) {

                        return ($scope.deniedStatusTransition[installationRequestSaved.status] || []).indexOf(e) < 0;
                    })

                } catch (e) {
                    console.error(e);
                }
            })

        } else
            Notification.error("FIND_BY_ID_ERROR");
    }, function (err) {
        Notification.error("FIND_BY_ID_ERROR")
    })

    $scope.cancel = function () {
        $state.go("app.home.installRequest");
    };

    /*  $scope.getInstallRequestStatus = function (state) {
          return constantSrv.getInstallRequestStatusByState(state)
      }*/

    $scope.changeInstallationRequestStatus = function (status) {

        if ($scope.liststatusavailInstallation.indexOf(status) != -1 && installationRequestSaved.status != status) {
            messageBox.confirm("UPDATE", "UPDATE_INSTALLATION_REQUEST_STATUS").then(function (res) {
                if (res) {
                    $scope.installationRequest.status = status
                    installationRequestSaved.status = status;

                    delete installationRequestSaved.__v;

                    I3InstallationGroupSrv.updateById(installationRequestSaved._id, installationRequestSaved, function (response) {
                        if (response.success) {
                            constantSrv.getModel("INSTALLATION_REQUEST").getAvailableStatusByStatus(installationRequestSaved.status, function (err, val) {

                                try {
                                    $scope.liststatusavailInstallation = val._availStatus.map(function (elem) {
                                        return elem.name
                                    }).filter(function (e) {

                                        return ($scope.deniedStatusTransition[installationRequestSaved.status] || []).indexOf(e) < 0;
                                    })

                                } catch (e) {
                                    console.error(e);
                                }
                            })
                            delete installationRequestSaved.__v;
                            if ($scope.disab_input_status.indexOf($scope.installationRequest.status) == -1) {
                                $scope.input_active = true;
                            } else {
                                $scope.input_active = false;
                            }
                            Notification.success({
                                title: $translate.instant("UPDATE_SUCC"),
                                message: $translate.instant("INSTALLATION_REQUEST_UPDATED_SUC")
                            });
                        } else {
                            Notification.error({
                                title: $translate.instant('UPDATE_ERROR'),
                                message: $translate.instant("ERROR_OCCURED_UPDATE")
                            });
                        }
                    }, function (err) {
                        var msg = "";
                        if (err.error.errorString) {
                            msg = err.error.errorString;
                        }
                        Notification.error({
                            title: $translate.instant('ERROR'),
                            message: msg
                        });
                    })
                }
            }, function (reject) {})

        }
    }
    $scope.isavailable = function (status) {
        //   console.log($scope.liststatusavailInstallation)
        if ($scope.liststatusavailInstallation.indexOf(status) != -1 && installationRequestSaved.status != status && status !== "COMMISSIONING") {
            return true
        } else {
            return false
        }
    };
    $scope.save = function (installRequest) {

        I3InstallationGroupSrv.updateById(installRequest._id, installRequest, function (res) {

            if (res && res.success) {
                Notification.success({
                    title: $translate.instant("UPDATE_SUCC"),
                    message: $translate.instant("INSTALLATION_GROUP_UPDATED")
                });

                $state.go("app.home.installRequest")
            } else {

                if (res.error && res.error && res.error.details) {
                    if (res.error.details.errors) {
                        var exception = ExceptionUtilsSrv.processDBError(res.error.details);
                        if (exception) {
                            Notification.error({
                                message: exception.msg,
                                title: exception.title
                            })
                        } else {
                            Notification.error({
                                title: $translate.instant("ERROR"),
                                message: $translate.instant("INSTALLATION_GROUP_ADD_EEROR")
                            });
                        }

                    } else {
                        Notification.error({
                            title: $translate.instant("ERROR"),
                            message: $translate.instant("INSTALLATION_GROUP_ADD_EEROR")
                        });
                    }

                } else {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("INSTALLATION_GROUP_ADD_EEROR")
                    });
                }

            }
        }, function (err) {
            console.log(err);
            Notification.error({
                title: $translate.instant("ERROR"),
                message: $translate.instant("INSTALLATION_GROUP_ADD_EEROR")
            });
        })

    }
    $scope.downloadZipGrouppedfile = function () {

        try {
            var params = {
                groups: {}
            };
            var comments = $translate.instant("INTERVENTION") + "\r\n";
            comments += "\t" + $translate.instant("CLIENT") + " :" + $scope.installationRequest.client_name + "\r\n";
            comments += "\t" + $translate.instant("CASE_NUMBER") + " :" + $scope.installationRequest.case_number + "\r\n";
            comments += "\t" + $translate.instant("purchase_order_nbr") + " :" + $scope.installationRequest.purchase_order + "\r\n";
            comments += "-------------------------------------------------------------------------\r\n";
            comments += "\t- " + $translate.instant("DOCUMENTS") + " :\r\n";
            if ($scope.installationRequest.docs && $scope.installationRequest.docs.length > 0) {
                params.groups = {
                    installation: []
                }
                $scope.installationRequest.docs.forEach(function (doc) {
                    if (doc._id)
                        params.groups.installation.push(doc._id);
                })
                comments += "\t\t + intervention : " + $translate.instant("DOCUMENTS") + " " + $translate.instant("Intervention") + "\r\n";
            }
            $scope.installationRequest.assets.forEach(function (asset) {
                var asset_tag = asset.name;
                if (asset.vin) {
                    asset_tag = asset.vin
                }
                params.groups[asset_tag] = [];
                comments += "\t\t + " + asset_tag + " : " + $translate.instant("DOCUMENTS") + " " + $translate.instant("ASSET") + " " + asset.name + "\r\n";
                if (asset.docs && asset.docs.length > 0) {
                    asset.docs.forEach(function (doc) {
                        if (doc._id)
                            params.groups[asset_tag].push(doc._id);
                    })

                }
                if (asset.gray_card_back && asset.gray_card_back.length > 0) {
                    params.groups[asset_tag].push(asset.gray_card_back);
                }
                if (asset.gray_card_front && asset.gray_card_front.length > 0) {
                    params.groups[asset_tag].push(asset.gray_card_front);
                }

            })

            params.comments = comments
            i3HelperSrv.downloadZip(params, $scope.installationRequest.case_number + "docs.zip", function (res) {
                console.log(res)

            }, function (err) {
                console.log(err)
            }, function (percent) {
                console.log(percent)
            })
        } catch (e) {

            console.error(e)
        }
    }

});