angular.module('app.dialogs').controller('travelCostEditDlgCtrl', function ($rootScope,$scope,index, $state,Notification,ngDialog) {
    $scope.travelCost={};
    if(!$scope.zone.travel_costs)
        $scope.zone.travel_costs=[];
    $scope.travelCost.category = $scope.zone.travel_costs[index].category;
    $scope.travelCost.capacity = $scope.zone.travel_costs[index].capacity;
    $scope.addTravelCost = function (travelCost) {
        $scope.zone.travel_costs[index] = travelCost;
        $scope.closeThisDialog();
    };
});