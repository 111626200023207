angular.module('app').controller("confirmDeleteClientDlgCtrl", function ($scope, $state, $translate, I3ClientSrv, Notification) {


    console.log("test")
    $scope.confirm = function () {
        I3ClientSrv.delete($scope.client._id, function (response) {
            if (response.success) {
                $scope.loadClients()
                $scope.tableState.pagination.start = 0;

                Notification.success({
                    title: $translate.instant("CLIENT_DELETED_TITLE"),
                    message: $translate.instant("CLIENT_DELETED_SUC"),

                });
                $scope.client = {};
            } else {
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("CLIENT_DELETED_ERROR")
                });
                $scope.client = {};
            }
        }, function (err) {
            console.log(err);

            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("CLIENT_DELETED_ERROR")
            });

            $scope.client = {};
        });

        $scope.client = {};
        $scope.closeThisDialog();
    }
});