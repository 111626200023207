angular.module('app').controller('interventionRequestCtrl', function ($scope, $stateParams, $translate, I3InterventionSrv, I3UserSrv, I3ClaimSrv, Notification, messageBox, constantSrv, iaxeedCompanySrv, ngDialog) {
    $scope.interventionsRequest = [];
    $scope.users = [];
    $scope._INTERVENTION_REQUEST = {};
    $scope.statusLoaded = false;
    $scope.search = {};
    $scope.claims = [];
    $scope.itemsByPage = 50;
    var i = 0;
    if ($stateParams.filter)
        $scope.search.interventionStatus = $stateParams.filter;
    $scope.predefinedClaimStatus = constantSrv.getPredefinedStatus();
    $scope.disab_input_status = ["VALIDATED"];
    $scope.deniedStatusTransition = {
        New: ["New", "Planned", "InProgress", "Done", "Postponed", "VALIDATED"],
        READY: ["New", "READY", "Planned", "InProgress", "Done", "Postponed", "VALIDATED"],
        Planned: ["New", "READY", "Planned", "InProgress", "Done", "Postponed", "VALIDATED", "Cancel"],
        InProgress: ["New", "READY", "Planned", "InProgress", "Done", "Postponed", "VALIDATED", "Cancel"],
        Done: ["New", "READY", "Planned", "InProgress", "Done", "Postponed", "VALIDATED", "Cancel"],
        Postponed: ["New", "READY", "Planned", "InProgress", "Done", "Postponed", "VALIDATED", "Cancel"],

        VALIDATED: ["New", "READY", "Planned", "InProgress", "Done", "Postponed", "VALIDATED", "Cancel"],
        Cancel: ["New", "READY", "Planned", "InProgress", "Done", "Postponed", "VALIDATED", "Cancel"],
    }
    constantSrv.getModel("INTERVENTION").getAllStatus(function (err, status) {

        $scope._INTERVENTION_REQUEST.statusList = status.map(function (val) {
            return val.name
        })
    })
    constantSrv.getModel("INTERVENTION").getAllStatus(function (err, status) {
        $scope.interventionStatus = status.map(function (val) {
            return val.name
        })
        $scope.interventionStatus.push('NotClosed');
        $scope.statusLoaded = true;
    })
    $scope.loadInterventionsRequest = function (tableState, ctrl) {

        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }
        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;
        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
        }
        var options = {
            populate: [
                "_assigned_to",
                "_claim",
                "_created_by", "_plateforme",
                {
                    path: '_claim',
                    populate: ['_created_by']
                }
            ],
            sortBy: {
                creation_dt: -1
            }
        };

        var query = {
            options: options
        };
        var requery = {};

        if ($scope.search) {
            if ($scope.search._plateforme) {
                requery["_plateforme"] = $scope.search._plateforme._id;
            }
            if ($scope.search.client) {
                requery["client_name"] = $scope.search.client.client_name;
            }

            if ($scope.search.asset) {

                requery["asset.asset_name"] = {
                    '$regex': $scope.search.asset,
                    '$options': 'i'
                };
            }

            if ($scope.search.purchase_order) {
                requery["purchase_order"] = {
                    '$regex': $scope.search.purchase_order,
                    '$options': 'i'
                };
            }
            if ($scope.search._created_by) {
                requery["_claim"] = $scope.search._created_by._id;
            }
            if ($scope.search.interventionStatus) {
                if ($scope.search.interventionStatus === "NotClosed") {
                    requery["status"] = {
                        $nin: constantSrv.getPredefinedStatus()
                    };
                } else {
                    requery["status"] = $scope.search.interventionStatus;
                }
            }
            if ($scope.search._assigned_to) {
                requery["_assigned_to"] = $scope.search._assigned_to._id;
            }
        }
        if (tableState.sort && Object.keys(tableState.sort).length > 0) {
            if (tableState.sort.predicate == "creation_dt") {
                query.options.sortBy.creation_dt = tableState.sort.reverse ? -1 : 1;

            }
        }
        query.query = requery;
        I3InterventionSrv.query(query, number, start, function (res) {
            if (res.success) {
                $scope.interventionsRequest = res.result;
                $scope.interventionsRequestCount = res.total_count;
                tableState.pagination.numberOfPages = Math.ceil(res.total_count / number);
                tableState.pagination.totalItemCount = res.total_count;
                $scope.tableState = tableState;
                if (($stateParams.platform) && (i == 0)) {
                    var elementPos = $scope._HOME.platforms.map(function (plat) {
                        return plat._id;
                    }).indexOf($stateParams.platform);

                    $scope.search._plateforme = $scope._HOME.platforms[elementPos];
                    i = 1;
                }
            }
        }, function (err) {
            console.log(err);
        })
    };
    $scope.searchClientId = function ($select) {
        var query = [{
                $match: {
                    client_name: {
                        '$regex': $select.search,
                        '$options': 'i'
                    }
                }
            },
            {
                $group: {
                    _id: '$client_id',

                    client_name: {
                        $first: '$client_name'
                    }
                }
            }
        ];
        I3InterventionSrv.agg({
            aggregate: query
        }, 1000, 0, function (res) {
            $scope.clients = res.result;
            //console.log(res)
        }, function (err) {})
    };
    I3UserSrv.query({}, 10000, 1, function (res) {
        if (res.success) {
            $scope._INTERVENTION_REQUEST.users = res.result;
        }
    }, function (err) {
        console.log(err);
    });
    $scope.searchAssetName = function ($select) {
        var query = [{
                $match: {
                    "asset.asset_name": {
                        '$regex': $select.search,
                        '$options': 'i'
                    }
                }
            },
            {
                $unwind: "$asset"
            },
            {
                $group: {
                    _id: "$asset.asset_name",
                    intervention: {
                        $push: '$_id'
                    }
                }
            }
        ];
        I3InterventionSrv.agg({
            aggregate: query
        }, 1000, 0, function (res) {
            $scope.assets = res.result;
            console.log("$scope.searchAssetName -> $scope.assets", $scope.assets)
            //console.log(res)
        }, function (err) {})
    };
    $scope.moment = moment;
    var now = moment().clone();
    $scope.dayRes = now;
    $scope.pickerDay = {
        format: "DD/MM/YYYY",
        position: "top left",
        init: null,
        autoTrans: false,
        enableTime: false,
        enableDate: true,
        enableMinutes: false,
        defaultView: "DATE",
        maxDate: moment("2020/05/22", "YYYY/MM/DD"),
        minDate: now,
        getDate: function (date) {
            $scope.dayRes = date;
            $scope.installationRequest.preferred_dt = date;
        }
    };
    $scope.open2 = function () {
        $scope.popup2.opened = true;
    };
    $scope.popup2 = {
        opened: false
    };
    $scope.opened = {};
    $scope.open = function ($event, elementOpened) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.opened[elementOpened] = !$scope.opened[elementOpened];
    };
    $scope.openStatusDialog = function (interventionR) {
        console.log(interventionR)
        console.log(interventionR._assigned_to)

        constantSrv.getModel("INTERVENTION").getAvailableStatusByStatus(interventionR.status, function (err, val) {
            $scope.states = val._availStatus.map(function (elem) {
                return elem.name
            }).filter(function (e) {
                return ($scope.deniedStatusTransition[interventionR.status] || []).indexOf(e) < 0;
            })
            $scope.states.unshift(interventionR.status)
            console.log("  $scope.states", $scope.states)
        })

        var dialog = ngDialog.open({
            template: "app/commons/dialogs/intervention/status.dlg.tpl.html",
            className: 'ngdialog-theme-default custom-width-500',
            appendClassName: 'ngdialog-custom',
            controller: "statusDlgCtrl",
            scope: $scope,
            data: {
                prop: interventionR
            }
        });
        dialog.closePromise.then(function (dataR) {

            if (dataR && dataR.value) {
                if ($scope.states.indexOf(dataR.value[0]) != -1) {
                    interventionR.status = dataR.value[0];
                    interventionR._assigned_to = dataR.value[1];
                    $scope.updateInterventionRProp('status', interventionR, dataR.value);
                }
            }
        });
    }
    $scope.openBeginDateDialog = function (interventionR) {
        var dialog = ngDialog.open({
            template: "app/commons/dialogs/intervention/begin_date.dlg.tpl.html",
            className: 'ngdialog-theme-default custom-width-500',
            appendClassName: 'ngdialog-custom',
            controller: "beginDateDlgCtrl",
            scope: $scope,
            data: {
                prop: interventionR
            }
        });
        dialog.closePromise.then(function (dataR) {
            if (dataR && dataR.value) {
                if (dataR.value._isValid) {

                    $scope.updateInterventionRProp('begin_dt', interventionR, dataR.value);
                }
            }
        });
    }

    $scope.openEndDateDialog = function (interventionR) {

        var dialog = ngDialog.open({
            template: "app/commons/dialogs/intervention/end_date.dlg.tpl.html",
            className: 'ngdialog-theme-default custom-width-500',
            appendClassName: 'ngdialog-custom',
            controller: "endDateDlgCtrl",
            scope: $scope,
            data: {
                prop: interventionR
            }
        });
        dialog.closePromise.then(function (dataR) {
            if (dataR && dataR.value) {
                if (dataR.value._isValid) {

                    $scope.updateInterventionRProp('end_dt', interventionR, dataR.value);
                }
            }
        });
    }
    $scope.updateInterventionRProp = function (prop, interventionR, $data) {
        console.log("TCL: $scope.updateInterventionRProp -> $data", $data)
        var updateData = {};
        if (prop === "status") {
            if ($data[0] === "Planned" && !updateData.planned_begin_dt) {
                updateData.planned_begin_dt = new Date();
                interventionR.planned_begin_dt = new Date();
            } else if ($data[0] === "InProgress" && !updateData.begin_dt) {
                updateData.begin_dt = new Date();
                interventionR.begin_dt = new Date();
            } else if ($data[0] === "Done" && !updateData.end_dt) {
                updateData.end_dt = new Date();
                interventionR.end_dt = new Date();

            }
        }
        if (prop === "planned_begin_dt" && interventionR.status === "New") {
            updateData.status = "Planned";
            interventionR.status = "Planned"
        }
        if (prop === "begin_dt" && interventionR.status !== "InProgress" && interventionR.status !== "Done") {
            updateData.status = "InProgress";
            interventionR.status = "InProgress"
        }
        if (prop === "end_dt" && interventionR.status !== "Done") {
            updateData.status = "Done";
            interventionR.status = "Done";
        }

        if (prop !== "status") {
            updateData[prop] = $data;
        } else {
            updateData["status"] = $data[0];
            if ($data[0] === "Done") {
                updateData["_assigned_to"] = $data[1];
            }
        }

        console.log(updateData);

        if (prop === "asset") {
            updateData = {
                asset: $data._id,
                asset_name: $data.name
            };
            interventionR.asset_name = $data.name;
        }
        I3InterventionSrv.updateById(interventionR._id, updateData, function (res) {
            if (res && res.success) {
                $scope.loadInterventions();
                Notification.success({
                    title: $translate.instant("UPDATE_SUCC"),
                    message: $translate.instant("INTERVENTION_REQUEST_UPDATED")
                })
            } else {
                Notification.warn({
                    title: $translate.instant("UPDATE_ERROR"),
                    message: $translate.instant("INTERVENTION_REQUEST_UPDATE_ERROR")
                })
            }
        }, function (err) {
            Notification.error({
                title: $translate.instant("UPDATE_ERROR"),
                message: $translate.instant("INTERVENTION_REQUEST_UPDATE_ERROR")
            });
        })
    };

    /**
     * Removing interventionRequest
     * @param interventionR
     */
    $scope.remove = function (interventionR) {
        messageBox.confirm("DELETE_INTERVENTION_REQUEST_TITLE", "DELETE_INTERVENTION_REQUEST_MESSAGE").then(function (res) {
            if (res) {
                I3InterventionSrv.delete(interventionR._id, function (res) {
                    if (res && res.success) {
                        Notification.success({
                            title: $translate.instant("DELETE_SUCC"),
                            message: $translate.instant("INTERVENTION_REQUEST_DELETED")
                        });
                        if ($scope.stCtrl) {
                            $scope.stCtrl.pipe();
                        }
                    } else {
                        Notification.warn({
                            title: $translate.instant("DELETE_ERROR"),
                            message: $translate.instant("INTERVENTION_REQUEST_DELETE_ERROR")
                        });
                    }
                }, function (err) {
                    Notification.error({
                        title: $translate.instant("DELETE_ERROR"),
                        message: $translate.instant("INTERVENTION_REQUEST_DELETE_ERROR")
                    });
                })
            }
        }, function (reject) {

        })
    };

})