angular.module('app').controller('adminProductsAddCtrl', function ($scope, $rootScope, $state, $translate, $filter, Notification, ngDialog, I3ProductSrv) {
    $scope.product = {};







   


    $scope.save = function () {

        I3ProductSrv.add($scope.product, function (response) {
            if (response.success) {
                Notification.success({
                    title: $translate.instant("PRODUCT_CREATED"),
                    message: $translate.instant("PRODUCT_ADDED_SUC")
                });
                $state.go('app.admin.products');
                $scope.product = {};
            } else {
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_CREATE") + " " + $scope.product.name + "!"
                });
            }
        }, function (err) {
            console.log(err);
            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("ERROR_OCCURED_CREATE") + " " + $scope.product.name + "!"
            });
            $scope.product = {};
        })
    };

    /**
     * Cancel creating/editing product
     */
    $scope.cancel = function () {
        $scope.product = {};
        $state.go('app.admin.products');
    };

});