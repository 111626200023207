angular.module("app").controller("deleteDeliveryProductDlgCtrl", function ($scope,index, $rootScope, $filter, $state, $translate, ngDialog, Notification, I3ArticleSrv,I3StockSrv) {

    $scope.deliverystock = {};
   
 



 if($scope.deliverySlip.products && $scope.deliverySlip.products.length>0){
    $scope.deliverystock._stock=$scope.deliverySlip.products[index]._stock;
    $scope.deliverystock.virtual_stock=$scope.deliverySlip.products[index].virtual_stock
 }


 
I3StockSrv.query({}, 10000, 1, function (res) {
    if (res.success) {
        $scope.ListStocks = res.result;
        $scope.virtual_stocks = [];
        $scope.ListStocks.forEach(function (val) {
            $scope.virtual_stocks = $scope.virtual_stocks.concat(val.virtual_stock.map(function (stock) {
                stock._stock = val.name;
                return stock
            }));
        })
 
    }
 }, function (err) {
    console.log(err);
 });


 $scope.getVirtualStokById=function(virtualStockId){
    if(virtualStockId){
        var filtred = $scope.virtual_stocks.filter(function(val){
            return  val._id == virtualStockId;
        })
        if(filtred && filtred.length>0){
            return  filtred[0];
        }else{
            return undefined;
        }
    }else {
        return undefined;
    }
 }
    $scope.choixProduct = function (stock) {
if(stock._id != $scope.deliverySlip.products[index]._stock._id){
    $scope.deliverystock.virtual_stock=null
}else{
    $scope.deliverystock.virtual_stock=$scope.deliverySlip.products[index].virtual_stock
}
 
    }
 
 
 $scope.deletDeliveryProduct=function(){
     if($scope.deliverySlip.products[index]._article){
        $scope.deliverystock._article=$scope.deliverySlip.products[index]._article;
        $scope.deliverystock._product=$scope.deliverySlip.products[index]._product;
        $scope.deliverystock.qty=$scope.deliverySlip.products[index].qty;
     }else{

        $scope.deliverystock._product=$scope.deliverySlip.products[index]._product;
        $scope.deliverystock.qty=$scope.deliverySlip.products[index].qty;
     }
   
  
    $scope.closeThisDialog($scope.deliverystock)


 
 
 }
 
 
 
 
 
 })