/**
 * this function is used to convert a standard Array of poi(lng,lat) to well formatted BND xy attribute
 * @param pois an array of point object . each point must have an lat, lng attribute
 * @returns {Array}
 */
function prepareSteps(pois) {

    var bndPois = [];
    if (pois.length > 0) {
        for (var i = 0; i < pois.length; i++) {
            bndPois.push(pois[i].lng + "," + pois[i].lat);
        }
    } else {
        return null
    }

    return bndPois;
}

/**
 * get BND transport type by name
 * @param transportTypeName
 * @returns {*}
 */
function getBndtransportType(transportTypeName) {
    if (transportTypeName == availableTransportType.BICYCLE.value) {
        return transportTypeConst.BICYCLE;
    }
    else if (transportTypeName == availableTransportType.PEDESTRIAN.value) {
        return transportTypeConst.PEDESTRIAN;
    }
    else if (transportTypeName == availableTransportType.CAR.value) {
        return transportTypeConst.CAR;
    } else {
        return "CAR";
        console.log("unavailable transport type " + transportTypeName);
    }
}
/**
 * Get the criteria BND name by criteria name
 * @param CriteriaNames an array of creteria names
 */
function getBndCriteriaByName(CriteriaNames) {

    var criteria = [];

    if (CriteriaNames != null && CriteriaNames != undefined && isArray(CriteriaNames)) {
        CriteriaNames.forEach(function (criter, index) {
            if (criter == availableCriterias.SHORTEST) {

                criteria.push(routingConst.criterias.SHORTEST);
            }
            else if (criter == availableCriterias.FASTER) {

                criteria.push(routingConst.criterias.FASTER);
            } else if (criter == availableCriterias.AVOID_MOTORWAYS) {

                criteria.push(routingConst.criterias.AVOID_MOTORWAYS);
            } else if (criter == availableCriterias.AVOID_TOLLS) {

                criteria.push(routingConst.criterias.AVOID_TOLLS);
            }
        })
        return criteria;
    } else {
        return null;
    }

}
/**
 * get available option for BND service by names
 * @param an array of options
 * @returns {*}
 */
function getBndRoutingOptions(Options) {

    //return null; // not used yet

    var option = [];
   // console.log("option " + isArray(Options));
    if (isArray(Options)) {
        Options.forEach(function (value, index) {
            if (value == availableOptions.ROUTESHEET) {

                option.push(routingConst.option.ROUTESHEET);
            }
            else if (value == availableOptions.POLYLINE) {

                option.push(routingConst.option.POLYLINE);
            } else if (value == availableOptions.POLYLINE_INDEX) {

                option.push(routingConst.option.POLYLINE_INDEX);
            } else if (value == availableOptions.SEGMENTIDS) {

                option.push(routingConst.option.SEGMENTIDS);
            } else if (value == availableOptions.JUNCTION_NODES) {

                option.push(routingConst.option.JUNCTION_NODES);
            } else if (value == availableOptions.TRAFFIC) {

                option.push(routingConst.option.TRAFFIC);
            } else if (value == availableOptions.TRAFFIC_PATTERNS) {

                option.push(routingConst.option.TRAFFIC_PATTERNS);
            } else if (value == availableOptions.OPTIMIZED_TRIP) {

                option.push(routingConst.option.OPTIMIZED_TRIP);
            } else if (value == availableOptions.OPTIMIZED_TRIP_ROUND) {

                option.push(routingConst.option.OPTIMIZED_TRIP_ROUND);
            } else if (value == availableOptions.OPTIMIZED_TRIP_UNDEFSTOP) {

                option.push(routingConst.option.OPTIMIZED_TRIP_UNDEFSTOP);
            } else if (value == availableOptions.TOLL_COST) {

                option.push(routingConst.option.TOLL_COST);
            } else if (value == availableOptions.TAX_COST) {

                option.push(routingConst.option.TAX_COST);
            } else if (value == availableOptions.TOLL_COST) {

                option.push(routingConst.option.TOLL_COST);
            } else if (value == availableOptions.ECO_TAX) {

                option.push(routingConst.option.ECO_TAX);
            } else if (value == availableOptions.SORTBY_USED_ORDER) {

                option.push(routingConst.option.SORTBY_USED_ORDER);
            } else if (value == availableOptions.REVGEO_STRICT_DISABLE) {

                option.push(routingConst.option.REVGEO_STRICT_DISABLE);
            }


        })
       // console.log(option);
        return option;
    } else {

        return null;
    }

}
/*
 {
 "type": "FOLLOW"
 ,"geoElementType": "ROAD"
 ,"Coordinate": { "x": 10.17819, "y": 36.79523 }
 ,"Duration": { "unity": "second", "value": 28 }
 ,"Manoeuvre": "Rue de Russie"
 ,"Length": { "unity": "m", "value": 186 }
 ,"Manoeuvre": "LEFT"
 ,"ToName": "Rue Charles de Gaulle"
 ,"ToOn": "Rue Charles de Gaulle"
 ,"ToRn": ""
 ,"ToSi": ""
 }
 */
function buildBNDInstruction(instruction) {
    var htmlContent = "";
    if (instruction.type == 'ENTER_ROUNDABOUT') {
        htmlContent = '<a> <img src="app/commons/directives/map/images/Round' + (instruction.RoundAboutExitNumber || '') + '.png" />';
        htmlContent += 'À ' + instruction.Length.value + ' ' + instruction.Length.unity + '';
        if (instruction.RoundAboutExitNumber != undefined) {
            if (instruction.ToName == '') {
                htmlContent += ' Prenez';
            } else {
                htmlContent += ' À ' + instruction.ToName + ' ' + 'Prenez'
            }

            htmlContent += ' la <b> ' + instruction.RoundAboutExitNumber + '</b><sup>' + (instruction.RoundAboutExitNumber < 2 && 'iere ' || "ième " ) + '</sup>sortie'
            if (instruction.ToName != '') {
                htmlContent += ' sur ' + instruction.ToName;
            }


        } else {
            htmlContent += " Au rond-point "
            htmlContent += (instruction.Manoeuvre == 'BEAR_LEFT' && 'Serrez à  gauche  ' )
            || (instruction.Manoeuvre == 'BEAR_RIGHT' && 'Serrez à  droite  ' )
            || (instruction.Manoeuvre == 'RIGHT' && 'Tournez à droite  ' )
            || (instruction.Manoeuvre == 'LEFT' && 'Tournez à gauche  ' )
            || (instruction.Manoeuvre == 'SHARP_LEFT' && 'Tournez à  gauche  ' )
            || (instruction.Manoeuvre == 'SHARP_RIGHT' && 'Tournez à droite ' )
            || (instruction.Manoeuvre == 'SLIGHT_LEFT' && 'Tournez  légèrement à gauche ' )
            || (instruction.Manoeuvre == 'SLIGHT_RIGHT' && 'Tournez  légèrement à droite ' )
            || (instruction.Manoeuvre == 'STRAIGHT' && 'Continuez' )
            || (instruction.Manoeuvre == 'U_TURN' && 'Faites demi-tour  ' ) || '';

        }
        if (instruction.Duration != undefined)
            htmlContent += '<span class="time-label"> (' + formatDuration(instruction.Duration.value) + ')</span>'+'</a>'
        else
            htmlContent += '</a>'


    } else if (instruction.type == 'ENTER_MOTORWAY') {

        htmlContent = '<a> <img src="app/commons/directives/map/images/' + (instruction.Manoeuvre) + '.png" />';
        htmlContent += 'À ' + instruction.Length.value + ' ' + instruction.Length.unity + ' ';
        htmlContent += 'Entrez l\'autoroute.';
        htmlContent += (instruction.Manoeuvre == 'BEAR_LEFT' && 'Serrez à  gauche  ' )
        || (instruction.Manoeuvre == 'BEAR_RIGHT' && 'Serrez à  droite  ' )
        || (instruction.Manoeuvre == 'RIGHT' && 'Tournez à droite  ' )
        || (instruction.Manoeuvre == 'LEFT' && 'Tournez à gauche  ' )
        || (instruction.Manoeuvre == 'SHARP_LEFT' && 'Tournez à  gauche  ' )
        || (instruction.Manoeuvre == 'SHARP_RIGHT' && 'Tournez à droite ' )
        || (instruction.Manoeuvre == 'SLIGHT_LEFT' && 'Tournez  légèrement à gauche ' )
        || (instruction.Manoeuvre == 'SLIGHT_RIGHT' && 'Tournez  légèrement à droite ' )
        || (instruction.Manoeuvre == 'STRAIGHT' && 'Continuez' )
        || (instruction.Manoeuvre == 'U_TURN' && 'Faites demi-tour  ' ) || '';
        if (instruction.ToName != '') {
            htmlContent += ' sur ' + instruction.ToName;
        }
        if (instruction.Duration != undefined)
            htmlContent += '<span class="time-label"> (' + formatDuration(instruction.Duration.value) + ')</span>'+'</a>'
        else
            htmlContent += '</a>'
    } else if (instruction.type == 'EXIT_MOTORWAY') {

        htmlContent = '<a> <img src="app/commons/directives/map/images/' + (instruction.Manoeuvre) + '.png" />';
        htmlContent += 'À ' + instruction.Length.value + ' ' + instruction.Length.unity + ' ';
        htmlContent += 'Prenez la sortie ';
        if (instruction.ToName != '') {
            htmlContent += '' + instruction.ToName + ' ';
        }
        htmlContent += ((instruction.Manoeuvre == 'BEAR_LEFT' && 'Serrez à  gauche  ' )
        || (instruction.Manoeuvre == 'BEAR_RIGHT' && 'Serrez à  droite  ' )
        || (instruction.Manoeuvre == 'RIGHT' && 'Tournez à droite  ' )
        || (instruction.Manoeuvre == 'LEFT' && 'Tournez à gauche  ' )
        || (instruction.Manoeuvre == 'SHARP_LEFT' && 'Tournez à  gauche  ' )
        || (instruction.Manoeuvre == 'SHARP_RIGHT' && 'Tournez à droite ' )
        || (instruction.Manoeuvre == 'SLIGHT_LEFT' && 'Tournez  légèrement à gauche ' )
        || (instruction.Manoeuvre == 'SLIGHT_RIGHT' && 'Tournez  légèrement à droite ' )
        || (instruction.Manoeuvre == 'STRAIGHT' && 'Continuer' )
        || (instruction.Manoeuvre == 'U_TURN' && 'Faites demi-tour  ' ) || '');
        if (instruction.ToName != '') {
            htmlContent += ' sur ' + instruction.ToName;
        }
        if (instruction.Duration != undefined)
            htmlContent += '<span class="time-label"> (' + formatDuration(instruction.Duration.value) + ')</span>'+'</a>'
        else
            htmlContent += '</a>'

    } else if (instruction.type == 'LEAVE_FERRY') {
        htmlContent = '<a> <img src="app/commons/directives/map/images/' + (instruction.Manoeuvre) + '.png" />';
        htmlContent += 'À ' + instruction.Length.value + ' ' + instruction.Length.unity + ' ';
        htmlContent += 'Quitter le ferry. ';

        htmlContent += ((instruction.Manoeuvre == 'BEAR_LEFT' && 'Serrez à  gauche  ' )
        || (instruction.Manoeuvre == 'BEAR_RIGHT' && 'Serrez à  droite  ' )
        || (instruction.Manoeuvre == 'RIGHT' && 'Tournez à droite  ' )
        || (instruction.Manoeuvre == 'LEFT' && 'Tournez à gauche  ' )
        || (instruction.Manoeuvre == 'SHARP_LEFT' && 'Tournez à  gauche  ' )
        || (instruction.Manoeuvre == 'SHARP_RIGHT' && 'Tournez à droite ' )
        || (instruction.Manoeuvre == 'SLIGHT_LEFT' && 'Tournez  légèrement à gauche ' )
        || (instruction.Manoeuvre == 'SLIGHT_RIGHT' && 'Tournez  légèrement à droite ' )
        || (instruction.Manoeuvre == 'STRAIGHT' && 'Continuer' )
        || (instruction.Manoeuvre == 'U_TURN' && 'Faites demi-tour  ' ) || '');
        if (instruction.ToName != '') {
            htmlContent += ' sur ' + instruction.ToName;
        }
        if (instruction.Duration != undefined)
            htmlContent += '<span class="time-label"> (' + formatDuration(instruction.Duration.value) + ')</span>'+'</a>'
        else
            htmlContent += '</a>'


    } else if (instruction.type == 'TAKE_FERRY') {
        htmlContent = '<a> <img src="app/commons/directives/map/images/' + (instruction.Manoeuvre) + '.png" />';
        htmlContent += 'À ' + instruction.Length.value + ' ' + instruction.Length.unity + ' ';
        htmlContent += 'Prenez le ferry. ';

        htmlContent += ((instruction.Manoeuvre == 'BEAR_LEFT' && 'Serrez à  gauche  ' )
        || (instruction.Manoeuvre == 'BEAR_RIGHT' && 'Serrez à  droite  ' )
        || (instruction.Manoeuvre == 'RIGHT' && 'Tournez à droite  ' )
        || (instruction.Manoeuvre == 'LEFT' && 'Tournez à gauche  ' )
        || (instruction.Manoeuvre == 'SHARP_LEFT' && 'Tournez à  gauche  ' )
        || (instruction.Manoeuvre == 'SHARP_RIGHT' && 'Tournez à droite ' )
        || (instruction.Manoeuvre == 'SLIGHT_LEFT' && 'Tournez  légèrement à gauche ' )
        || (instruction.Manoeuvre == 'SLIGHT_RIGHT' && 'Tournez  légèrement à droite ' )
        || (instruction.Manoeuvre == 'STRAIGHT' && 'Continuer' )
        || (instruction.Manoeuvre == 'U_TURN' && 'Faites demi-tour  ' ) || '');
        if (instruction.ToName != '') {
            htmlContent += ' sur ' + instruction.ToName;
        }
        if (instruction.Duration != undefined)
            htmlContent += '<span class="time-label"> (' + formatDuration(instruction.Duration.value) + ')</span>'+'</a>'
        else
            htmlContent += '</a>'

    } else if (instruction.type == 'TAKE_RAMP') {
        htmlContent = '<a> <img src="app/commons/directives/map/images/' + (instruction.Manoeuvre) + '.png" />';
        htmlContent += 'À ' + instruction.Length.value + ' ' + instruction.Length.unity + ' ';
        htmlContent += 'Prenez la bretelle. ';

        htmlContent += ((instruction.Manoeuvre == 'BEAR_LEFT' && 'Serrez à  gauche  ' )
        || (instruction.Manoeuvre == 'BEAR_RIGHT' && 'Serrez à  droite  ' )
        || (instruction.Manoeuvre == 'RIGHT' && 'Tournez à droite  ' )
        || (instruction.Manoeuvre == 'LEFT' && 'Tournez à gauche  ' )
        || (instruction.Manoeuvre == 'SHARP_LEFT' && 'Tournez à  gauche  ' )
        || (instruction.Manoeuvre == 'SHARP_RIGHT' && 'Tournez à droite ' )
        || (instruction.Manoeuvre == 'SLIGHT_LEFT' && 'Tournez  légèrement à gauche ' )
        || (instruction.Manoeuvre == 'SLIGHT_RIGHT' && 'Tournez  légèrement à droite ' )
        || (instruction.Manoeuvre == 'STRAIGHT' && 'Continuer' )
        || (instruction.Manoeuvre == 'U_TURN' && 'Faites demi-tour  ' ) || '');
        if (instruction.ToName != '') {
            htmlContent += ' sur ' + instruction.ToName;
        }
        if (instruction.Duration != undefined)
            htmlContent += '<span class="time-label"> (' + formatDuration(instruction.Duration.value) + ')</span>'+'</a>'
        else
            htmlContent += '</a>'

    }

else if (instruction.type == 'EXIT_ROUNDABOUT'){
        htmlContent = '<a> <img src="app/commons/directives/map/images/' + (instruction.Manoeuvre) + '.png" />';
        htmlContent += 'À ' + instruction.Length.value + ' ' + instruction.Length.unity + ' ';


        htmlContent += ((instruction.Manoeuvre == 'BEAR_LEFT' && 'Serrez à  gauche  ' )
        || (instruction.Manoeuvre == 'BEAR_RIGHT' && 'Serrez à  droite  ' )
        || (instruction.Manoeuvre == 'RIGHT' && 'Tournez à droite  ' )
        || (instruction.Manoeuvre == 'LEFT' && 'Tournez à gauche  ' )
        || (instruction.Manoeuvre == 'SHARP_LEFT' && 'Tournez à  gauche  ' )
        || (instruction.Manoeuvre == 'SHARP_RIGHT' && 'Tournez à droite ' )
        || (instruction.Manoeuvre == 'SLIGHT_LEFT' && 'Tournez  légèrement à gauche ' )
        || (instruction.Manoeuvre == 'SLIGHT_RIGHT' && 'Tournez  légèrement à droite ' )
        || (instruction.Manoeuvre == 'STRAIGHT' && 'Continuer' )
        || (instruction.Manoeuvre == 'U_TURN' && 'Faites demi-tour  ' ) || '');
        if (instruction.ToName != '') {
            htmlContent += ' sur ' + instruction.ToName;
        }
        if (instruction.Duration != undefined)
            htmlContent += '<span class="time-label"> (' + formatDuration(instruction.Duration.value) + ')</span>'+'</a>'
        else
            htmlContent += '</a>';

    }







    else if (instruction.type == 'FOLLOW_SIGN') {
        htmlContent = '<a> <img src="app/commons/directives/map/images/' + (instruction.Manoeuvre) + '.png" />';
        htmlContent += 'À ' + instruction.Length.value + ' ' + instruction.Length.unity + ' ';


        htmlContent += ((instruction.Manoeuvre == 'BEAR_LEFT' && 'Serrez à  gauche  ' )
        || (instruction.Manoeuvre == 'BEAR_RIGHT' && 'Serrez à  droite  ' )
        || (instruction.Manoeuvre == 'RIGHT' && 'Tournez à droite  ' )
        || (instruction.Manoeuvre == 'LEFT' && 'Tournez à gauche  ' )
        || (instruction.Manoeuvre == 'SHARP_LEFT' && 'Tournez à  gauche  ' )
        || (instruction.Manoeuvre == 'SHARP_RIGHT' && 'Tournez à droite ' )
        || (instruction.Manoeuvre == 'SLIGHT_LEFT' && 'Tournez  légèrement à gauche ' )
        || (instruction.Manoeuvre == 'SLIGHT_RIGHT' && 'Tournez  légèrement à droite ' )
        || (instruction.Manoeuvre == 'STRAIGHT' && 'Continuer' )
        || (instruction.Manoeuvre == 'U_TURN' && 'Faites demi-tour  ' ) || '');
        if (instruction.ToName != '') {
            htmlContent += ' et suivez la direction pour ' + instruction.ToName;
        }
        if (instruction.Duration != undefined)
            htmlContent += '<span class="time-label"> (' + formatDuration(instruction.Duration.value) + ')</span>'+'</a>'
            else
            htmlContent += '</a>';


    } else if (instruction.type == 'STOP') {
        htmlContent = '<a> <img src="app/commons/directives/map/images/' + (instruction.Manoeuvre) + '.png" />';
        htmlContent += 'À ' + instruction.Length.value + ' ' + instruction.Length.unity + ' ';


        htmlContent += ((instruction.Manoeuvre == 'BEAR_LEFT' && 'Serrez à  gauche  ' )
        || (instruction.Manoeuvre == 'BEAR_RIGHT' && 'Serrez à  droite  ' )
        || (instruction.Manoeuvre == 'RIGHT' && 'Tournez à droite  ' )
        || (instruction.Manoeuvre == 'LEFT' && 'Tournez à gauche  ' )
        || (instruction.Manoeuvre == 'SHARP_LEFT' && 'Tournez à  gauche  ' )
        || (instruction.Manoeuvre == 'SHARP_RIGHT' && 'Tournez à droite ' )
        || (instruction.Manoeuvre == 'SLIGHT_LEFT' && 'Tournez  légèrement à gauche ' )
        || (instruction.Manoeuvre == 'SLIGHT_RIGHT' && 'Tournez  légèrement à droite ' )
        || (instruction.Manoeuvre == 'STRAIGHT' && 'Continuer' )
        || (instruction.Manoeuvre == 'U_TURN' && 'Faites demi-tour  ' ) || '');
        if (instruction.ToName != '') {
            htmlContent += ' sur ' + instruction.ToName;
        }
        htmlContent += ' et  Arrêtez. ';
        if (instruction.Duration != undefined)
            htmlContent += '<span class="time-label"> (' + formatDuration(instruction.Duration.value) + ')</span>'+'</a>'
        else
            htmlContent += '</a>';

    } else if (instruction.type == 'FOLLOW') {
        htmlContent = '<a> <img src="app/commons/directives/map/images/' + (instruction.Manoeuvre) + '.png" />';
        htmlContent += 'À ' + instruction.Length.value + ' ' + instruction.Length.unity + ' ';


        htmlContent += ((instruction.Manoeuvre == 'BEAR_LEFT' && 'Serrez à  gauche  ' )
        || (instruction.Manoeuvre == 'BEAR_RIGHT' && 'Serrez à  droite  ' )
        || (instruction.Manoeuvre == 'RIGHT' && 'Tournez à droite  ' )
        || (instruction.Manoeuvre == 'LEFT' && 'Tournez à gauche  ' )

        || (instruction.Manoeuvre == 'SHARP_LEFT' && 'Tournez à  gauche  ' )
        || (instruction.Manoeuvre == 'SHARP_RIGHT' && 'Tournez à droite ' )
        || (instruction.Manoeuvre == 'SLIGHT_LEFT' && 'Tournez  légèrement à gauche ' )
        || (instruction.Manoeuvre == 'SLIGHT_RIGHT' && 'Tournez  légèrement à droite ' )


        || (instruction.Manoeuvre == 'STRAIGHT' && 'Continuer' )
        || (instruction.Manoeuvre == 'U_TURN' && 'Faites demi-tour  ' ) || '');
        if (instruction.ToName != '') {
            htmlContent += ' sur ' + instruction.ToName;
        }
        if (instruction.Duration != undefined)
            htmlContent += '<span class="time-label"> (' + formatDuration(instruction.Duration.value) + ')</span>'+'</a>'
        else
            htmlContent += '</a>'

    }

    else {


        console.log("Unknow instruction type " + instruction.type);
    }
    return htmlContent;

}


function isArray(obj) {
    return Object.prototype.toString.call(obj) === '[object Array]';
}

function formatDuration(duration) {

    if (duration > 0) {
        var hours = ~~(duration / 3600);
        var min = ~~((duration - hours * 3600) / 60);
        var seconds = duration - hours * 3600 - min * 60
        hours = (hours >= 1) ? zeroPad(hours, 2) : '00';
        min = (min >= 1) ? zeroPad(min, 2) : '00';
        seconds = (seconds >= 1) ? zeroPad(seconds, 2) : '00';
       // console.log(seconds);
        return hours + ':' + min + ':' + seconds;
    } else {
        return ''
    }

}
function loadXMLDoc(filename) {
    if (window.ActiveXObject) {

        xhttp = new ActiveXObject("Msxml2.XMLHTTP");
    }
    else {
        xhttp = new XMLHttpRequest();
    }
    xhttp.open("GET", filename, false);
    try {
        xhttp.responseType = "msxml-document"
    } catch (err) {
    } // Helping IE11
    xhttp.send("");
    return xhttp.responseXML;
}
function loadXMLString(txt) {
    var xmlDoc;
    if (window.DOMParser) {
        parser = new DOMParser();
        xmlDoc = parser.parseFromString(txt, "text/xml");
    }
    else // code for IE
    {
        xmlDoc = new ActiveXObject("Microsoft.XMLDOM");
        xmlDoc.async = false;
        xmlDoc.loadXML(txt);
    }
   // console.log(xmlDoc);
    return xmlDoc;
}
function processXMLData(xmlString, xslFileName) {
    var xml = loadXMLString(xmlString);
    var xsl = loadXMLDoc(xslFileName);

    if (window.ActiveXObject || xhttp.responseType == "msxml-document") {
        var ex = xmltransformNode(xsl);
        return ex;
    }
    else if (document.implementation && document.implementation.createDocument) {
        var xsltProcessor = new XSLTProcessor();
        xsltProcessor.importStylesheet(xsl);

        var resultDocument = xsltProcessor.transformToFragment(xml, document);
        return resultDocument;
    }
}



