angular.module("app.directives")
    .factory("getResizeArea", function () {
        return function () {
            var resizeAreaId = "fileupload-resize-area";

            var resizeArea = document.getElementById(resizeAreaId);

            if (!resizeArea) {
                resizeArea = document.createElement("canvas");
                resizeArea.id = resizeAreaId;
                resizeArea.style.display = "none";
                document.body.appendChild(resizeArea);
            }
            return resizeArea;
        };
    })
    .factory("resizeImage", ['getResizeArea', function (getResizeArea) {

        function getAdjustedDimension(width, height, maxWidth, maxHeight) {
            var height;
            var width;

            if (width > height) {
                if (width > maxWidth) {
                    height = Math.round(height *= maxWidth / width);
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width = Math.round(width *= maxHeight / height);
                    height = maxHeight;
                }
            }
            return {
                width: width,
                height: height
            };
        }

        function fixOrientation(image, orientation, canvas, ctx, width, height, maxWidth, maxHeight) {
            // Nice description on EXIF orientation handling
            //http://www.daveperrett.com/articles/2012/07/28/exif-orientation-handling-is-a-ghetto/

            var x = 0;
            var y = 0;
            //reset the context tranformation matrix
            ctx.setTransform(1, 0, 0, 1, 0, 0);

            if (orientation == 1 || orientation == 3) {
                var dimension = getAdjustedDimension(width, height, maxWidth, maxHeight);
                var w = dimension.width;
                var h = dimension.height;

                canvas.width = w;
                canvas.height = h;

                if (orientation == 3) {
                    ctx.translate(w, h);
                    ctx.rotate(180 * Math.PI / 180);
                }

                ctx.drawImage(image, x, y, w, h);
            }

            if (orientation == 2 || orientation == 4) {
                var dimension = getAdjustedDimension(width, height, maxWidth, maxHeight);
                var w = dimension.width;
                var h = dimension.height;

                canvas.width = w;
                canvas.height = h;

                if (orientation == 2) {
                    ctx.translate(w, 0);
                    ctx.scale(-1, 1);
                    ctx.rotate(0 * Math.PI / 180);
                }

                if (orientation == 4) {
                    ctx.translate(0, h);
                    ctx.scale(1, -1);
                    ctx.rotate(0 * Math.PI / 180);
                }

                ctx.drawImage(image, x, y, w, h);
            }

            if (orientation == 5 || orientation == 7) {
                var dimension = getAdjustedDimension(height, width, maxWidth, maxHeight);
                var w = dimension.width;
                var h = dimension.height;

                canvas.width = w;
                canvas.height = h;

                if (orientation == 5) {
                    ctx.translate(0, 0);
                    ctx.scale(-1, 1);
                    ctx.rotate(90 * Math.PI / 180);
                }

                if (orientation == 7) {
                    ctx.translate(w, h);
                    ctx.scale(-1, 1);
                    ctx.rotate(-90 * Math.PI / 180);
                }

                ctx.drawImage(image, x, y, h, w);
            }

            if (orientation == 6 || orientation == 8) {

                var dimension = getAdjustedDimension(height, width, maxWidth, maxHeight);
                var w = dimension.width;
                var h = dimension.height;

                canvas.width = w;
                canvas.height = h;

                if (orientation == 6) {
                    ctx.translate(w, 0);
                    ctx.rotate(90 * Math.PI / 180);
                }

                if (orientation == 8) {
                    ctx.translate(0, h);
                    ctx.rotate(-90 * Math.PI / 180);
                }

                ctx.drawImage(image, x, y, h, w);

            }

        }

        return function (origImage, options, orientation) {
            var maxHeight = options.resizeMaxHeight || 300;
            var maxWidth = options.resizeMaxWidth || 250;
            var quality = options.resizeQuality || 0.7;
            var cover = options.cover || options.cover === "" || false;
            var coverHeight = options.coverHeight || 300;
            var coverWidth = options.coverWidth || 250;
            var coverX = options.coverX || "left";
            var coverY = options.coverY || "top";
            var type = options.resizeType || "image/jpg";

            var canvas = getResizeArea();

            var height = origImage.height;
            var width = origImage.width;

            var imgX = 0;
            var imgY = 0;

            if (!cover) {
                // calculate the width and height, constraining the proportions
                if (width > height) {
                    if (width > maxWidth) {
                        height = Math.round(height *= maxWidth / width);
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width = Math.round(width *= maxHeight / height);
                        height = maxHeight;
                    }
                }

                canvas.width = width;
                canvas.height = height;

            } else {
                // Logic for calculating size when in cover-mode
                canvas.width = coverHeight;
                canvas.height = coverWidth;
                // Resize image to fit canvas and keep original proportions
                var ratio = 1;
                if (height < canvas.height) {
                    ratio = canvas.height / height;
                    height = height * ratio;
                    width = width * ratio;
                }
                if (width < canvas.width) {
                    ratio = canvas.width / width;
                    height = height * ratio;
                    width = width * ratio;
                }

                // Check if both are too big -> downsize
                if (width > canvas.width && height > canvas.height) {
                    ratio = Math.max(canvas.width / width, canvas.height / height);
                    height = height * ratio;
                    width = width * ratio;
                }

                // place img according to coverX and coverY values
                if (width > canvas.width) {
                    if (coverX === "right") {
                        imgX = canvas.width - width;
                    } else if (coverX === "center") {
                        imgX = (canvas.width - width) / 2;
                    }
                } else if (height > canvas.height) {
                    if (coverY === "bottom") {
                        imgY = canvas.height - height;
                    } else if (coverY === "center") {
                        imgY = (canvas.height - height) / 2;
                    }
                }

            }

            //draw image on canvas
            var ctx = canvas.getContext("2d");
            if (!cover && orientation >= 1 && orientation <= 8) {
                fixOrientation(origImage, orientation, canvas, ctx, width, height, maxWidth, maxHeight);
            } else {
                ctx.drawImage(origImage, imgX, imgY, width, height);
            }

            // get the data from canvas as 70% jpg (or specified type).
            return canvas.toDataURL(type, quality);
        };
    }])
    .factory("fileToDataURL", ['$q', function ($q) {

        function detectOrientation(imageData, callback) {
            try {
                var view = new DataView(imageData);
                if (view.getUint16(0, false) != 0xFFD8) return callback(-2);
                var length = view.byteLength,
                    offset = 2;
                while (offset < length) {
                    var marker = view.getUint16(offset, false);
                    offset += 2;
                    if (marker == 0xFFE1) {
                        var little = view.getUint16(offset += 8, false) == 0x4949;
                        offset += view.getUint32(offset + 4, little);
                        var tags = view.getUint16(offset, little);
                        offset += 2;
                        for (var i = 0; i < tags; i++)
                            if (view.getUint16(offset + (i * 12), little) == 0x0112)
                                return callback(view.getUint16(offset + (i * 12) + 8, little));
                    } else if ((marker & 0xFF00) != 0xFF00) break;
                    else offset += view.getUint16(offset, false);
                }
                callback(-100);
            } catch (e) {
                console.error(e);
                callback(-100);
            }
        }

        return function (file) {
            var deferred = $q.defer();
            var reader = new FileReader();
            var binaryReader = new FileReader();

            reader.readAsDataURL(file);

            reader.onload = function (e) {
                binaryReader.readAsArrayBuffer(file);
                binaryReader.onload = function (buffer) {
                    detectOrientation(buffer.target.result, function (orientation) {
                        var response = {};
                        response.dataURL = e.target.result;
                        response.orientation = orientation;
                        deferred.resolve(response);
                    });
                }
            };
            reader.onerror = function (e) {
                deferred.reject(e);
            };
            reader.onabort = function (e) {
                deferred.reject(e);
            };

            binaryReader.onerror = reader.onerror;
            binaryReader.onabort = reader.onabort;

            return deferred.promise;
        };
    }])
    .factory("createImage", ['$q', function ($q) {
        return function (url) {
            var deferred = $q.defer();
            var image = new Image();
            image.src = url;
            image.onload = function () {
                deferred.resolve(image);
            };
            image.onerror = function (e) {
                deferred.reject(e);
            };
            return deferred.promise;
        };
    }])
    .factory("map", function () {
        return function (list, fn) {
            return Array.prototype.map.call(list, fn);
        };
    })
    .factory("appendDataUri", ['fileToDataURL', 'map', '$q', function (fileToDataURL, map, $q) {

        function appendDataUri(model) {
            return fileToDataURL(model.file)
                .then(function (response) {
                    model.dataURL = response.dataURL;
                    model.orientation = response.orientation;
                    return model;
                });
        }

        return function (model) {
            // If the viewValue is invalid (say required but empty) it will be `undefined`
            if (angular.isUndefined(model)) {
                return;
            }

            if (angular.isArray(model)) {
                var model_update_promises = map(model, appendDataUri);
                return $q.all(model_update_promises);
            } else {
                return appendDataUri(model);
            }
        };
    }])
    .factory("create_model_from_files", ['map', function (map) {
        return function (files) {
            var model = map(files, function (imageFile) {
                var file_obj = {
                    file: imageFile
                };
                return file_obj;
            });
            return model;
        };
    }])
    .factory("add_data_uris", ['map', 'appendDataUri', '$q', function (map, appendDataUri, $q) {
        return function (options) {
            var should_append_data_uris = angular.isDefined(options.appendDataUri);
            return function (model) {
                if (should_append_data_uris) {
                    return $q.all(map(model, appendDataUri));
                } else {
                    return model;
                }
            };
        };
    }])
    .factory("doResizing", ['$q', 'createImage', 'resizeImage', function ($q, createImage, resizeImage) {
        return function (options) {
            return function (model) {
                model.url = URL.createObjectURL(model.file); //this is used to generate images/resize
                return createImage(model.url)
                    .then(function (image) {

                        var dataURL = resizeImage(image, options, model.orientation);
                        // console.log("resized carte grise size : " + dataURL.length);

                        var imageType = dataURL.substring(5, dataURL.indexOf(";"));
                        model.resized = {
                            dataURL: dataURL,
                            type: imageType
                        };
                        return model;
                    });
            };
        };
    }])
    .factory("resize", ['$q', 'doResizing', 'map', function ($q, doResizing, map) {
        return function (options) {
            var should_do_resizing = angular.isDefined(options.resize) &&
                angular.isDefined(options.resizeMaxHeight) &&
                angular.isDefined(options.resizeMaxWidth);

            return function (model) {
                if (should_do_resizing) {
                    return $q.all(map(model, doResizing(options)));
                } else {
                    return model;
                }
            };
        };
    }])
    .factory("generic_image_processing_functions", ['$q', 'create_model_from_files', 'add_data_uris', 'resize', function ($q, create_model_from_files, add_data_uris, resize) {
        return function (files, options) {
            return $q.when(create_model_from_files(files))
                .then(add_data_uris(options))
                .then(resize(options));
        };
    }])

    .factory("multi_image_model_updater", function () {
        return function update_model(ngModel, options) {
            return function (model) {
                var old_model = ngModel.$modelValue;
                var model_to_update;
                if (angular.isDefined(options.append) && angular.isArray(old_model)) {
                    model_to_update = old_model.concat(model);
                } else {
                    model_to_update = model;
                }
                ngModel.$setViewValue(model_to_update);

            };
        };
    })
    .directive("inputImages", ['generic_image_processing_functions', 'multi_image_model_updater', function (generic_image_processing_functions,
        multi_image_model_updater) {

        return {
            require: "ngModel",
            link: function (scope, element, attrs, ngModel) {

                var file_upload_element = new angular.element("<input type='file' accept='image/*' multiple>");

                element.on("click", function () {
                    file_upload_element[0].click();
                });

                file_upload_element.bind("change", function (evt) {
                    var files = evt.target.files;

                    generic_image_processing_functions(files, attrs)
                        .then(multi_image_model_updater(ngModel, attrs));
                });
            }
        };
    }])
    .directive("inputImage", ['generic_image_processing_functions', function (generic_image_processing_functions) {
        return {
            require: "ngModel",
            link: function (scope, element, attrs, ngModel) {

                var file_upload_element = new angular.element("<input type='file' accept='image/*'>");
                // if the consumer of the directive updates the model to be null, we should reset the file upload field
                // TODO : same should be applied for "file upload multiple case
                scope.$watch(attrs['ngModel'], function (v) {
                    if (v === null) {
                        //console.log(file_upload_element[0].value);
                        file_upload_element[0].value = "";
                        //console.log(file_upload_element);
                        /* file_upload_element.wrap('<form>').closest('form').get(0).reset();
                        file_upload_element.unwrap(); */
                    }
                });

                element.on("click", function () {
                    file_upload_element[0].click();
                });

                file_upload_element.bind("change", function (evt) {
                    var files = evt.target.files;

                    generic_image_processing_functions(files, attrs)
                        .then(update_model);
                });

                function update_model(model) {
                    ngModel.$setViewValue(model[0]);
                }

            }
        };
    }])
    .factory("image_drop_linker_common", ['$document', '$log', function ($document, $log) {
        return function (scope, element, attrs) {

            var decoration = attrs.decoration || "drag-over";

            function decorate_dragged_element(element) {
                element.addClass(decoration);
            }

            function undecorate_dragged_element(element) {
                element.removeClass(decoration);
            }

            //When an item is dragged over the document, add .dragOver to the body
            function onDragOver(e) {
                e.preventDefault();
                decorate_dragged_element(element);
            }

            //When the user leaves the window, cancels the drag or drops the item
            function onDragLeave(e) {
                e.preventDefault();
                undecorate_dragged_element(element);
            }

            function onDragOverDoc(e) {
                e.preventDefault();
            }

            function onDragLeaveDoc(e) {
                e.preventDefault();
            }

            function onDropDoc(e) {
                e.preventDefault();
            }

            $document.bind("dragover", onDragOverDoc);
            $document.bind("dragleave", onDragLeaveDoc);
            $document.bind("drop", onDropDoc);

            //Dragging begins on the document (shows the overlay)
            element.bind("dragover", onDragOver);

            //Dragging ends on the overlay, which takes the whole window
            element.bind("dragleave", onDragLeave);

            element.bind("drop", function (e) {
                e.preventDefault();
                $log.debug("drop", e);
                undecorate_dragged_element(element);
            });
        };
    }])
    .factory("find_data_transfer", function () {
        return function (e) {
            if (e.dataTransfer) {
                return e.dataTransfer;
            }
            if (e.originalEvent && e.originalEvent.dataTransfer) {
                return e.originalEvent.dataTransfer;
            }
            return undefined;
        };
    })
    .directive("imageDrop", ['find_data_transfer', 'image_drop_linker_common', 'generic_image_processing_functions', function (
        find_data_transfer,
        image_drop_linker_common,
        generic_image_processing_functions) {
        return {
            restrict: "EA",
            require: "ngModel",
            link: function (scope, element, attrs, ngModel) {
                image_drop_linker_common(scope, element, attrs, ngModel);

                function update_model(model) {
                    ngModel.$setViewValue(model[0]);
                }

                element.bind("drop", function (e) {
                    var files = find_data_transfer(e).files;

                    generic_image_processing_functions(files, attrs)
                        .then(update_model);
                });
            }
        };
    }])
    .directive("imagesDrop", ['find_data_transfer', 'image_drop_linker_common', 'generic_image_processing_functions', 'multi_image_model_updater', function (
        find_data_transfer,
        image_drop_linker_common,
        generic_image_processing_functions,
        multi_image_model_updater) {
        return {
            restrict: "EA",
            require: "ngModel",
            link: function (scope, element, attrs, ngModel) {

                image_drop_linker_common(scope, element, attrs, ngModel);

                element.bind("drop", function (e) {
                    var files = find_data_transfer(e).files;

                    generic_image_processing_functions(files, attrs)
                        .then(multi_image_model_updater(ngModel, attrs));
                });
            }
        };
    }]);