angular.module('app').controller("installationAddDlgCtrl", function ($scope, I3ProductSrv, Notification, iaxeedAssetSrv, I3InstallationSrv, constantSrv, plateformHelperSrv, I3UserSrv, $translate, NGIFleetHelperSrv, NGIFleetAssetSrv, $rootScope, index, plateforme, installations, installationRequest) {

    /**
     * init and check if edit or  add mode
     */

    if (installations === null) {
        $scope.mode = 'create';

    } else {
        $scope.mode = 'edit';
        /**
         * set the current asset if exist
         */
        if (installations.asset) {
            $scope.asset = {
                _id: installations.asset,
                name: installations.asset_name
            }
        }
    }

    /**
     * if add Mode set the default sim included to the option installationRequest
     */
    if (installations === null) {
        $scope.sim_included = $scope.installationRequest.sim_included
    } else {
        if (!angular.isDefined(installations.sim_included)) {
            $scope.sim_included = $scope.installationRequest.sim_included
        } else {
            $scope.sim_included = installations.sim_included
        }
    }

    /**
     * get staus list
     */
    $scope.statusList = constantSrv.getInstallationStatus();
    /***
     * select plateform  adn login to choosen plateforme
     * @param platform
     * @param cb
     */
    $scope.selectPlateform = function (platform, cb) {

        // $scope.assets = [];
        if (platform.type == "NGI_FLEET") {
            NGIFleetHelperSrv.setPlateFormAndLogin(platform, function (res) {

                if (res.result) {
                    cb(null, true);
                } else {
                    cb(true, false);
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("PLATFORM_CNX_ERROR")
                    });
                }
                //console.log("NGIFleetHelperSrv", res);
            }, function (err) {
                cb(true, false);
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("PLATFORM_CNX_ERR")
                });
            })
        } else {
            plateformHelperSrv.setPlateFormAndLogin(platform, function (res) {

                if (res && res.success) {
                    cb(false, true);
                } else {
                    cb(true, false);
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("PLATFORM_CNX_ERROR")
                    });
                }

            }, function (err) {
                cb(true, false);
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("PLATFORM_CNX_ERR")
                });
            })
        }

    }
    /**
     * init
     */

    if (installations) {
        if (!($rootScope.plateform && $rootScope.plateform._id == plateforme._id)) {
            $scope.selectPlateform(plateforme, function (err, res) {

            })
        }
        $scope.installation = installations;
        $scope.product = installations._product;

    } else {
        $scope.installation = {}
    }

    /**
     * search for product autocomplete function call
     * @param $select
     */
    $scope.searchProduct = function ($select) {

        I3ProductSrv.query({
            query: {
                name: {
                    '$regex': $select.search,
                    '$options': 'i'
                }
            }
        }, 10, 0, function (res) {

            $scope.products = res.result;
        }, function (err) {

        })
    }

    /**
     * search for user autocomplete
     * @param $select
     */
    $scope.searchUser = function ($select) {

        I3UserSrv.query({
            query: {
                $or: [{
                        login: {
                            '$regex': $select.search,
                            '$options': 'i'
                        }
                    },
                    {
                        first_name: {
                            '$regex': $select.search,
                            '$options': 'i'
                        }
                    },
                    {
                        last_name: {
                            '$regex': $select.search,
                            '$options': 'i'
                        }
                    }
                ]
            }
        }, 10, 0, function (res) {

            $scope.users = res.result;
        }, function (err) {

        })
    }
    /**
     * if product is selected callback
     * @param product
     */
    $scope.selectProduct = function (product) {
        $scope.product = product;

    }

    /**
     * function add or update depends on  id
     * @param installation
     */
    $scope.add = function (installation) {
        installation.sim_included = $scope.sim_included;

        if (Number.isFinite(index)) {
            $scope.installationRequest._installationIds[index] = installation;
            I3InstallationSrv.updateById(installation._id, installation, function (res) {
                if (res && res.success) {
                    Notification.success($translate.instant("SAVE_INSTALLATION_SUCCESS"));
                    $scope.closeThisDialog()
                } else {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("SAVE_INSTALLATION_ERROR")
                    });

                }

            }, function (err) {
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("SAVE_INSTALLATION_ERROR")
                });
            })

        } else {
            if (!$scope.installationRequest._installationIds || !Array.isArray($scope.installationRequest._installationIds)) $scope.installationRequest._installationIds = []
            //   $scope.installationRequest._installationIds.push(installation);
            if (!installation._installation_group) {
                installation._installation_group = $scope.installationRequest._id;
            }
            I3InstallationSrv.add(installation, function (res) {
                if (res && res.success) {
                    Notification.success("SAVE_INSTALLATION_SUCCESS");
                    $scope.closeThisDialog()
                    I3InstallationSrv.query({
                        query: {
                            _id: res.result._id
                        },
                        options: {
                            populate: "_assigned_to _product"
                        }
                    }, 1, 0, function (install) {
                        //console.log(" console.log(install) console.log(install) console.log(install)", install)
                        if (install && install.success && install.result && install.result.length > 0) {
                            //console.log(install)
                            $scope.installationRequest._installationIds.push(install.result[0]);
                        }

                    }, function (err) {
                        console.error(err);
                    })
                } else {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("SAVE_INSTALLATION_ERROR")
                    });
                }

            }, function (err) {
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("SAVE_INSTALLATION_ERROR")
                });
            })
        }

    }

    /**
     * On asset selected  update installation property
     * @param asset
     */
    $scope.selectAsset = function (asset) {
        $scope.installation.asset_name = asset.name;
        $scope.installation.asset = asset._id;

    }
    /**
     * used to load only one time from  NGI Fleet
     * @type {boolean}
     */
    var loadOnlyOneTime = false; // for NGI_FLEET we cannot find by name
    /**
     * search for asset  from plateforme
     * @param $select
     */
    $scope.searchAsset = function ($select) {
        if (!installationRequest.client_id) return;

        //console.log(" $rootScope.plateform._id == plateforme._id", $rootScope.plateform && $rootScope.plateform._id == plateforme._id)
        if ($rootScope.plateform && $rootScope.plateform._id == plateforme._id) {

            if (plateforme.type == "NGI_FLEET") {

                if (loadOnlyOneTime) return;
                NGIFleetAssetSrv.getAssetByClient(installationRequest.client_id, $select.search, 1000, 0, function (res) {
                    loadOnlyOneTime = true;

                    $scope.assets = res.result;
                }, function (err) {
                    console.log(err);
                })
            } else {
                $scope.assets = [];
                iaxeedAssetSrv.query({
                    query: {
                        name: {
                            '$regex': $select.search,
                            '$options': 'i'
                        },
                        _company_owner: installationRequest.client_id
                    }
                }, 1000, 0, function (res) {
                    $scope.assets = res.result;
                }, function (err) {
                    console.error(err);
                })
            }
        } else {

            $scope.selectPlateform(plateforme, function (err, res) {
                if (plateforme.type == "NGI_FLEET") {

                    NGIFleetAssetSrv.getAssetByClient($scope.installation._installation_group.client_id, $select.search, 1000, 0, function (res) {

                        $scope.assets = res.result;
                    }, function (err) {
                        console.log(err);
                    })
                } else {
                    iaxeedAssetSrv.query({
                        query: {
                            name: {
                                '$regex': $select.search,
                                '$options': 'i'
                            },
                            _company_owner: $scope.installation._installation_group.client_id
                        }
                    }, 10, 0, function (res) {
                        $scope.assets = res.result;
                    }, function (err) {
                        console.error(err);
                    })
                }

            });
        }

    }

});