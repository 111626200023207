angular.module('app').controller('interventionEditCtrl', function ($rootScope, $scope, $translate, $state, $stateParams, I3UserSrv, I3ClaimSrv, ngDialog, I3DeliveryRequestSrv, I3PvSrv, I3InterventionSrv, Notification, I3DocumentSrv, i3HelperSrv, messageBox, constantSrv) {
    $scope.emailFollow = "";
    $scope.com = [];
    $scope.content = "";
    $scope.files = [];
    $scope.comments = [];
    $scope.docs = [];
    $scope.followers = [];
    $scope.come = false;
    $scope.com = [];
    $scope.com3 = [];
    $scope.inter = {}
    $scope.liststatusAvailIntervention = [];
    $scope.disab_input_status = ["VALIDATED"];
    $scope.disab_input_status_PV = ["Planned", "InProgress", "Done", "Postponed", "VALIDATED"];
    $scope.deniedStatusTransition = {
        New: ["New", "READY", "Planned", "InProgress", "Done", "Postponed", "VALIDATED", "Cancel"],
        READY: ["New", "VALIDATED", "Cancel"],
        Planned: ["New", "READY", "Planned", "VALIDATED", "Cancel"],
        InProgress: ["New", "READY", "Planned", "VALIDATED", "Cancel"],
        Done: ["New", "READY", "Planned", "InProgress", "Done", "Postponed", "VALIDATED", "Cancel"],
        Postponed: ["New", "READY", "Postponed", "VALIDATED", "Cancel"],

        VALIDATED: ["New", "READY", "Planned", "InProgress", "Done", "Postponed", "VALIDATED", "Cancel"],
        Cancel: ["New", "VALIDATED", "Cancel"],
    }
    $scope.timeleft = 0;
    $scope.now = moment()
    $scope.redv = 0;

    $scope.getvalue = function (deadline, deadline_period) {
        if (deadline && deadline_period) {
            var deadline_start = moment(deadline).subtract(deadline_period, 'h');
            console.log(deadline_start)
            var date2 = moment(deadline);
            var date1 = moment();
            $scope.date_limite = date2;
            var differenceInMs = date2.diff(date1); // diff yields milliseconds
            var duration = moment.duration(differenceInMs);
            var differenceInHours = Math.floor(duration.asHours());

            if (-differenceInHours >= 1000) {
                $scope.timeleft = 1000
            } else
                $scope.timeleft = -differenceInHours;
            var redv = Math.floor(100 - ((differenceInHours / deadline_period) * 100))
            if (redv >= 100) redv = 100
            console.log(redv)
            return redv
        }
    }

    $scope.show_com = function (x) {
        $scope.com[x] = !$scope.com[x];
    }
    $scope.show_com3 = function (x) {
        $scope.com3[x] = !$scope.com3[x];
    }
    $scope.show_come = function () {
        $scope.come = !$scope.come;
    }
    $scope.show_come10 = function () {
        $scope.come10 = !$scope.come10;
    }
    $scope.show_come20 = function () {
        $scope.come20 = !$scope.come20;
    }

    i3HelperSrv.getEnums(function (res) {
        $scope.intervention_type = res.INTERVENTION_TYPE.LIST;

    }, function (err) {
        console.error(err)
    })
    I3InterventionSrv.query({
        query: {
            _id: $stateParams.id
        },
        options: {
            populate: ["_assigned_to", "_claim", "_created_by", "docs", "comments.owner", '_plateforme', {
                path: '_claim',
                populate: ['_created_by']
            }]
        }
    }, 1, 0, function (res) {
        if (res && res.success && res.result.length > 0) {
            $scope.intervention = res.result[0];
            $scope.redv = $scope.getvalue($scope.intervention.dead_line, $scope.intervention.dead_line_period);
            console.log($scope.intervention);

            $scope.getdeliveryrequests();
            $scope.getpvs();
            constantSrv.getModel("INTERVENTION").getAvailableStatusByStatus($scope.intervention.status, function (err, val) {
                $scope.liststatusAvailIntervention = val._availStatus.map(function (elem) {
                    return elem.name
                }).filter(function (e) {
                    return ($scope.deniedStatusTransition[$scope.intervention.status] || []).indexOf(e) < 0;
                })
            });
            if (!$scope.intervention.client_id && $scope.intervention._plateforme) {
                $scope.verify();
            }
            $scope.getFiles($scope.intervention.docs);
            if ($scope.intervention.comments && $scope.intervention.comments.length > 0)
                $scope.comments = $scope.intervention.comments;
            console.log("TCL: $scope.intervention.comments", $scope.intervention.comments)
            if ($scope.intervention.docs && $scope.intervention.docs.length > 0)
                $scope.docs = $scope.intervention.docs;
            if ($scope.intervention.followers && $scope.intervention.followers.length > 0)
                $scope.followers = $scope.intervention.followers;
        } else
            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("FIND_BY_ID_ERROR")
            });
    }, function (err) {
        Notification.error({
            title: $translate.instant('ERROR'),
            message: $translate.instant("FIND_BY_ID_ERROR")
        })
    })
    $scope.save = function (intervention) {
        var interv = angular.copy(intervention);
        console.log(intervention)
        interv._claim = intervention._claim && intervention._claim._id ? intervention._claim._id : intervention._claim

        if (intervention._assigned_to) {
            if (intervention._assigned_to.length > 0 && intervention._assigned_to[0]._id) {
                interv._assigned_to = [];
                angular.forEach(intervention._assigned_to, function (user) {
                    interv._assigned_to.push(user._id);
                });
            } else {
                interv._assigned_to = []
            }
        }
        if ($scope.followers && $scope.followers.length > 0) {
            interv.followers = $scope.followers
        } else {
            interv.followers = []
        }
        if ($scope.comments && $scope.comments.length > 0) {
            interv.comments = $scope.comments
        } else {
            interv.comments = []
        }
        if ($scope.docs && $scope.docs.length > 0) {
            interv.docs = $scope.docs
        } else {
            interv.docs = []
        }
        I3InterventionSrv.updateById(interv._id, interv, function (res) {
            if (res.success) {
                Notification.success({
                    title: $translate.instant("DELETE_SUCC"),
                    message: $translate.instant('INTERVENTION_UPDATED')
                });
                $state.go('app.home.intervention');
            } else {
                Notification.warning({
                    title: $translate.instant("UPDATE_ERROR"),
                    message: $translate.instant('INTERVENTION_UPDATE_FAILED')
                });
            }
        }, function (err) {
            Notification.error({
                title: $translate.instant("UPDATE_ERROR"),
                message: $translate.instant('INTERVENTION_UPDATE_FAILED')
            });
            console.log(err);
        })
    };
    /**
     * Getting files
     */
    $scope.getFiles = function (files) {
        I3DocumentSrv.query({
            query: {
                _id: {
                    '$in': files
                }
            }
        }, 10000, 1, function (res) {
            if (res.success) {
                $scope.intervention.docs = res.result;
            }
        }, function (err) {
            console.log(err);
        });
    };
    $scope.selectStatus = function (status) {
        // $scope.intervention.status = status;
        var liststatusavailIntervention;
        constantSrv.getModel("INTERVENTION").getAvailableStatusByStatus($scope.intervention.status, function (err, val) {
            liststatusavailIntervention = val._availStatus.map(function (elem) {
                return elem.name
            }).filter(function (e) {
                return ($scope.deniedStatusTransition[$scope.intervention.status] || []).indexOf(e) < 0;
            })
            $scope.liststatusAvailIntervention = liststatusavailIntervention
        })

        if (liststatusavailIntervention.indexOf(status) != -1 && $scope.intervention.status != status) {

            messageBox.confirm("UPDATE", "UPDATE_INTERVENTION_STATUS").then(function (res) {
                if (res) {

                    $scope.intervention.status = status
                    I3InterventionSrv.updateById($scope.intervention._id, $scope.intervention, function (response) {

                        console.log("response", response)
                        if (response.success) {

                            Notification.success({
                                title: $translate.instant("INTERVENTION_UPDATED_TITLE"),
                                message: $translate.instant("INTERVENTION_UPDATED_SUC")
                            });
                        } else {
                            Notification.error({
                                title: $translate.instant('ERROR'),
                                message: $translate.instant("ERROR_OCCURED_UPDATE")
                            });
                        }
                    }, function (err) {
                        var msg = "";
                        if (err.error.errorString) {
                            msg = err.error.errorString;
                        }
                        Notification.error({
                            title: $translate.instant('ERROR'),
                            message: msg
                        });
                    })
                }
            }, function (reject) {})
        }

        console.log("TCL: $scope.selectStatus -> status", status)
        if (status === "Planned" && !$scope.intervention.planned_begin_dt) {
            $scope.intervention.planned_begin_dt = new Date();
        } else if (status === "InProgress" && !$scope.intervention.begin_dt) {
            $scope.intervention.begin_dt = new Date();
        } else if (status === "Done" && !$scope.intervention.end_dt) {
            $scope.intervention.end_dt = new Date();
        }
    }
    $scope.uploadFiles = function (files, errFiles) {
        console.log("$scope.intervention._plateforme ", $scope.intervention._plateforme)
        $scope.files = files;
        $scope.errFiles = errFiles;
        i3HelperSrv.uploadFiles(files, {
            _plateforme: $scope.intervention._plateforme && $scope.intervention._plateforme._id ? $scope.intervention._plateforme._id : $scope.intervention._plateforme,
            related_model: [{
                model: "INTERVENTION",
                model_id: $scope.intervention._id
            }]
        }, function (res) {

            angular.forEach(res.result, function (file) {
                $scope.docs.push(file)
            });
        }, function (response) {
            if (response.status)
                $scope.errorMsg = response.status + ': ' + response.data;
        }, function (evt) {
            console.log("TCL: $scope.intervention", $scope.intervention)
        });
    }
    $scope.downloadFile = function (file) {
        i3HelperSrv.downloadFile(file._id, file.original_name, function success(res) {}, function error(err) {
            Notification.error({
                title: $translate.instant("ERROR"),
                message: $translate.instant(err)
            });
        }, function progress(evt) {
            file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
            console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '%');
        })
    };
    $scope.addComment2 = function (msg) {
        if (msg) {
            var commentAdded = {};
            commentAdded.date = new Date();
            commentAdded.owner = $rootScope.SESSION.user;
            console.log("TCL: $rootScope.SESSION.user", $rootScope.SESSION.user)
            commentAdded.msg = msg;
            commentAdded.new = true;
            $scope.comments.push(commentAdded);
        }
        $scope.content2 = "";
    };
    /**
     * Following the current intervention
     */
    $scope.follow = function () {
        if ($scope.emailFollow && $scope.emailFollow !== "") {
            var follower = {};
            if (!$scope.intervention.followers) {
                $scope.intervention.followers = [];
            }
            follower.email = $scope.emailFollow;
            var query = {
                "contact.mail": $scope.emailFollow
            };
            var options = {};
            var exist = false;
            angular.forEach($scope.followers, function (follower) {
                if (follower.email.toLowerCase() === $scope.emailFollow.toLowerCase()) {
                    exist = true;
                }
            });
            if (!exist) {
                I3UserSrv.query({
                    query: query,
                    options: options
                }, 10000, 0, function (res) {
                    if (res.success) {
                        follower.user = res.result[0];
                    }
                    $scope.followers.push(follower);
                    delete $scope.intervention.__v;
                    $scope.emailFollow = "";
                });
            } else {
                Notification.warning({
                    title: $translate.instant("ALREADY_FOLLOWER_TITLE"),
                    message: $translate.instant("EMAIL_ALREADY_EXIST")
                });
                $scope.emailFollow = "";
            }
        }
    };
    $scope.show_com = function (x) {
        $scope.com[x] = !$scope.com[x];
    }

    $scope.verify = function () {
        var modal = ngDialog.open({
            controller: "ClientIntervSyncDlgCtrl",
            templateUrl: "app/commons/dialogs/client/client.interv.sync.dlg.tpl.html",
            className: 'ngdialog-theme-default custom-width',
            scope: $scope,
            width: '900px',
            showClose: false,
            closeByEscape: false,
            closeByNavigation: true,
            closeByDocument: false,
            resolve: {
                intervention: function () {
                    return $scope.intervention;
                }
            }
        });
        modal.closePromise.then(function (data) {
            $scope.intervention.client_id = data.value._id;
            $scope.intervention.client_name = data.value.name;
            I3InterventionSrv.updateById($scope.intervention._id, $scope.intervention, function (response) {
                if (response.success) {
                    Notification.success({
                        title: $translate.instant("SYNCHRONIZED_CLIENT"),
                        message: $translate.instant("SYNCHRONIZED_CLIENT_SUCC")
                    });
                } else {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("ERROR_OCCURED_UPDATE") + "!"
                    });
                }
            }, function (err) {
                var msg = "";
                if (err.error.errorString) {
                    msg = err.error.errorString;
                }
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: msg
                });
            })
        }, function () {});
    }

    $scope.verify = function () {
        console.log("verify")
        var modal = ngDialog.open({
            controller: "ClientIntervSyncDlgCtrl",
            templateUrl: "app/commons/dialogs/client/client.interv.sync.dlg.tpl.html",
            className: 'ngdialog-theme-default custom-width',
            scope: $scope,
            width: '900px',
            showClose: false,
            closeByEscape: false,
            closeByNavigation: true,
            closeByDocument: false,
            resolve: {
                intervention: function () {
                    return $scope.intervention;
                }
            }
        });
        modal.closePromise.then(function (data) {
            $scope.intervention.client_id = data.value._id;
            $scope.intervention.client_name = data.value.name;
            I3InterventionSrv.updateById($scope.intervention._id, $scope.intervention, function (response) {
                if (response.success) {
                    Notification.success({
                        title: $translate.instant("SYNCHRONIZED_CLIENT"),
                        message: $translate.instant("SYNCHRONIZED_CLIENT_SUCC")
                    });
                } else {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("ERROR_OCCURED_UPDATE") + "!"
                    });
                }
            }, function (err) {
                var msg = "";
                if (err.error.errorString) {
                    msg = err.error.errorString;
                }
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: msg
                });
            })
        }, function () {});
    }
    /**
     * Remove follower
     */
    $scope.showRealtime = function (asset) {

        var asset2 = null
        if ($scope.assets && $scope.assets.length > 0) {
            var filterded = $scope.assets.filter(function (val) {
                if (val._id == asset.asset_id) {

                    asset2 = val;
                }
            })
        }
        $scope.asset = asset2 || asset;
        var dialog = ngDialog.open({
            controller: "realtimeDlgCtrl",
            templateUrl: "app/commons/dialogs/realtime/realtime.dlg.tpl.html",
            scope: $scope,
            className: 'ngdialog-theme-default custom-width'
        });

        dialog.closePromise.then(function () {
            iaxeedSocketSrv.closeListner('newMsg');
        });
    }
    $scope.removeFollower = function (follower) {
        if ($scope.followers.indexOf(follower) >= 0) {
            $scope.followers.splice($scope.followers.indexOf(follower), 1);
        }
    };

    $scope.addDeliveryRequest = function (asset) {

        var dialog = ngDialog.open({
            controller: "deliveryRequestDlgCtrl",
            templateUrl: "app/commons/dialogs/deliveryRequest/deliveryRequest.dlg.tpl.html",
            scope: $scope,
            className: 'ngdialog-theme-default custom-width-800'
        });
        dialog.closePromise.then(function (data) {

            if (data && data.value !== undefined && data.value !== '$closeButton' && data.value !== '$document') {

                var listProd = []
                data.value.req_options.forEach(function (val) {
                    listProd.push({
                        _product: val._product,
                        qty: val.qty
                    })
                })

                var deliveryRequest = {
                    client: $scope.intervention.client_name,
                    _plateforme: $scope.intervention._plateforme,
                    asset_name: asset.asset_name,
                    products: listProd,
                    intervention_id: $scope.intervention._id,
                    comment: data.value.comment,
                    asset_id: asset._id
                }

                I3DeliveryRequestSrv.add(deliveryRequest, function (res) {

                    if (res && res.success) {

                        Notification.success($translate.instant("DELIVERY_REQUEST_ADDED_SUC"));
                        $scope.getdeliveryrequests();
                        $scope.getpvs();

                    } else {
                        Notification.error($translate.instant("DELIVERY_REQUEST_ADD_EEROR"));

                    }

                }, function (err) {
                    console.log(err)
                })

            }

        });

    }

    $scope.addPv = function (asset, $index) {
        $scope.asset = angular.copy(asset)

        var dialog = ngDialog.open({
            controller: "interventionGenerationPVCtrl",
            templateUrl: "app/commons/dialogs/pv/generationPvAddIntervention.dlg.tpl.html",
            scope: $scope,
            className: 'ngdialog-theme-default dialog-lg'
        });

        dialog.closePromise.then(function (data) {

            if (data && data.value !== undefined && data.value !== '$closeButton' && data.value !== '$document') {

                $scope.getdeliveryrequests();
                $scope.getpvs();
            }
        })

    }

    $scope.isavailable = function (status) {
        if ($scope.liststatusAvailIntervention.indexOf(status) != -1 && $scope.intervention.status != status) {

            return true
        } else
            return false
    };

    $scope.downloadZipGrouppedfile = function () {
        console.log(" $scope.intervention", $scope.intervention)
        try {
            var params = {
                _ids: []
            };
            var comments = $translate.instant("INTERVENTION") + "\r\n";
            comments += "\t" + $translate.instant("CLIENT") + " :" + $scope.intervention.client_name + "\r\n";
            comments += "\t" + $translate.instant("REFERENCE") + " :" + $scope.intervention.ref + "\r\n";
            comments += "\t" + $translate.instant("purchase_order_nbr") + " :" + $scope.intervention.purchase_order + "\r\n";
            comments += "-------------------------------------------------------------------------\r\n";
            comments += "\t- " + $translate.instant("DOCUMENTS") + " :\r\n";
            if ($scope.intervention.docs && $scope.intervention.docs.length > 0) {
                //params.ids = {intervention: []}
                $scope.intervention.docs.forEach(function (doc) {
                    if (doc._id)
                        params._ids.push(doc._id);
                })
                comments += "\t\t + intervention : " + $translate.instant("DOCUMENTS") + " " + $translate.instant("Intervention") + "\r\n";
            }
            /* $scope.interventionRequest.assets.forEach(function (asset) {
                 var asset_tag=asset.name;
                  if(asset.vin){
                      asset_tag=asset.vin
                  }
                 params.groups[asset_tag]=[];
                 if(asset.docs && asset.docs.length>0){
                     asset.docs.forEach(function (doc) {
                         if(doc._id)
                             params.groups[asset_tag].push(doc._id);
                     })
                     if(asset.gray_card_back && asset.gray_card_back.length>0){
                         params.groups[asset_tag].push(asset.gray_card_back);
                     }
                     if(asset.gray_card_front && asset.gray_card_front.length>0){
                         params.groups[asset_tag].push(asset.gray_card_front);
                     }

                 }

             })*/
            params.comments = comments

            i3HelperSrv.downloadZip(params, "Intervention_" + $scope.intervention.client_name + "_" + $scope.intervention.purchase_order + "_docs.zip", function (res) {

            }, function (err) {
                console.log(err)
            }, function (percent) {
                console.log(percent)
            })
        } catch (e) {

            console.error(e)
        }
    }

    $scope.show_delivery = function (y) {
        if (y > 0) {
            $scope.inter.show_del = !$scope.inter.show_del;
        }
    };
    $scope.show_pv = function (y) {
        if (y > 0) {
            $scope.inter.show_pv = !$scope.inter.show_pv;
        }
    };

    $scope.getdeliveryrequests = function () {
        I3DeliveryRequestSrv.query({
            query: {
                intervention_id: $scope.intervention._id,

            },
            options: {
                populate: "created_by"
            }
        }, 10000, 0, function (res) {
            if (res && res.success && res.result.length > 0) {
                console.log("res.result:deliveryRequest", res.result);
                $scope.inter.deliveries = res.result;
            } else {
                console.log("error")
            }
        }, function (err) {
            console.log(err)
        })
    }
    $scope.getpvs = function () {
        I3PvSrv.query({
            query: {
                _intervention: $scope.intervention._id,

            }
        }, 10000, 0, function (res) {
            if (res && res.success && res.result.length > 0) {
                console.log("pvs", res.result);
                $scope.inter.pvs = res.result;
            } else {
                console.log("error")
            }
        }, function (err) {
            console.log(err)
        })
    }
    $scope.deleteFiles = function (index) {
        messageBox.confirm("DELETE_FILE_CONFIRM_TITLE", "DELETE_FILE_CONFIRM_MESSSAGE").then(function (res) {
            console.log(res)
            if (res == true) {
                $scope.intervention.docs.splice(index, 1);
                $scope.docs = $scope.intervention.docs

                I3InterventionSrv.updateById($scope.intervention._id, $scope.intervention, function (response) {
                    if (response.success) {
                        console.log(response)

                    }
                })

            }

        })
    }
});