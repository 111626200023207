angular.module('app').controller('platformEditCtrl', function ($scope, $rootScope, $state, $stateParams, $translate, $filter, Notification, ngDialog, I3PlateformeSrv, plateformHelperSrv) {

    I3PlateformeSrv.query({ query: { _id: $stateParams.platformId } }, 10000, 0, function (data) {
    
        if (data.success) {
            $scope.platform = data.result[0];

        } else {
            console.log(data.error)
        }
    })

    $scope.test = function () {
        plateformHelperSrv.testLogin($scope.platform, function (res) {
            if (res.success) {
                Notification.success({
                    title: $translate.instant("AUTH_SUCCESS"),
                    message: $translate.instant("AUTH_SUCCESS_MSG")
                });
            } else {
                Notification.error({
                    title: $translate.instant("AUTH_ERROR"),
                    message: $translate.instant("AUTH_ERROR_MSG")
                });
            }
        }, function (err) {
            Notification.error({
                title: $translate.instant("AUTH_ERROR"),
                message: $translate.instant("AUTH_ERROR_MSG")
            });
        });
    };

    $scope.save = function () {

        // Update platform
        I3PlateformeSrv.updateById($stateParams.platformId, $scope.platform, function (response) {
            if (response.success) {
                $state.go('app.admin.platform', {}, { reload: true });
                Notification.success({
                    title: $translate.instant("PLATFORM_UPDATED"),
                    message: $translate.instant("PLATFORM_UPDATED_SUC")
                });

            } else {
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_UPDATE") + " " + $scope.platform.name + "!"
                });
            }
        }, function (err) {
            console.log(err);

            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("ERROR_OCCURED_UDPDATE") + " " + $scope.platform.name + "!"
            });

        })

    };

    $scope.pickerDay = {
        format: "DD/MM/YYYY hh:mm",
        position: "top right",
        init: moment(),
        autoTrans: true,
        enableTime: true,
        enableDate: true,
        enableMinutes: true,
        defaultView: "DATE",

        getDate: function (date) {
            console.log("date", date)
            $scope.platform.command_date = moment(date).toISOString()
        }
    };
    /**
    * Cancel creating/editing platform
    */
    $scope.cancel = function () {
        $state.go("app.admin.platform");
    }

});