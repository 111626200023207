angular.module('app').controller('interventionRequestEditCtrl', function ($scope, $rootScope, $window, $state, $stateParams, $translate, messageBox, ngDialog, Notification, I3InterventionSrv, I3UserSrv, I3DocumentSrv, plateformHelperSrv, i3HelperSrv, constantSrv, iaxeedAssetSrv) {
    $scope.interventionRequest = {}
    $scope.liststatusAvailIntervention = [];
    $scope.disab_input_status = ["VALIDATED"];
    constantSrv.getModel("INTERVENTION").getAllStatus(function (err, stat) {

        $scope.status = stat.map(function (val) {
            return val.name
        })
    })
    i3HelperSrv.getEnums(function (res) {
        $scope.interventionRequest_type = res.INTERVENTION_TYPE.LIST;

    }, function (err) {
        console.error(err)
    })

    $scope.deniedStatusTransition = {
        New: ["New", "Planned", "InProgress", "Done", "Postponed", "VALIDATED"],
        READY: ["New", "READY", "Planned", "InProgress", "Done", "Postponed", "VALIDATED"],
        Planned: ["New", "READY", "Planned", "InProgress", "Done", "Postponed", "VALIDATED", "Cancel"],
        InProgress: ["New", "READY", "Planned", "InProgress", "Done", "Postponed", "VALIDATED", "Cancel"],
        Done: ["New", "READY", "Planned", "InProgress", "Done", "Postponed", "VALIDATED", "Cancel"],
        Postponed: ["New", "READY", "Planned", "InProgress", "Done", "Postponed", "VALIDATED", "Cancel"],

        VALIDATED: ["New", "READY", "Planned", "InProgress", "Done", "Postponed", "VALIDATED", "Cancel"],
        Cancel: ["New", "READY", "Planned", "InProgress", "Done", "Postponed", "VALIDATED", "Cancel"],
    }
    var now = moment().clone();
    $scope.dayRes = now;
    $scope.com = [];
    $scope.content = "";
    $scope.come = false;
    $scope.com = [];
    $scope.com3 = [];
    $scope.timeleft = 0;
    $scope.now = moment()
    $scope.redv = 0;

    $scope.getvalue = function (deadline, deadline_period) {
        if (deadline && deadline_period) {
            var deadline_start = moment(deadline).subtract(deadline_period, 'h');
            console.log(deadline_start)
            var date2 = moment(deadline);
            var date1 = moment();
            $scope.date_limite = date2;
            var differenceInMs = date2.diff(date1); // diff yields milliseconds
            var duration = moment.duration(differenceInMs);
            var differenceInHours = Math.floor(duration.asHours());

            if (-differenceInHours >= 1000) {
                $scope.timeleft = 1000
            } else
                $scope.timeleft = -differenceInHours;
            var redv = Math.floor(100 - ((differenceInHours / deadline_period) * 100))
            if (redv >= 100) redv = 100
            console.log(redv)
            return redv
        }
    }
    $scope.show_com = function (x) {
        $scope.com[x] = !$scope.com[x];
    }
    $scope.show_com3 = function (x) {
        $scope.com3[x] = !$scope.com3[x];
    }
    $scope.show_come = function () {
        $scope.come = !$scope.come;
    }
    $scope.show_come10 = function () {
        $scope.come10 = !$scope.come10;
    }
    $scope.show_come20 = function () {
        $scope.come20 = !$scope.come20;
    }

    $scope.open2 = function () {
        $scope.popup2.opened = true;
    };
    $scope.popup2 = {
        opened: false
    };
    $scope.opened = {};
    $scope.open = function ($event, elementOpened) {
        $event.preventDefault();
        $event.stopPropagation();

        $scope.opened[elementOpened] = !$scope.opened[elementOpened];
    };

    $scope.emailFollow = "";

    $scope.uploadFiles = function (files, errFiles) {
        $scope.files = files;
        $scope.errFiles = errFiles;
        i3HelperSrv.uploadFiles(files, {
            _plateforme: $scope.interventionRequest._plateforme && $scope.interventionRequest._plateforme._id ? $scope.interventionRequest._plateforme._id : $scope.interventionRequest._plateforme,
            related_model: [{
                model: "INTERVENTION",
                model_id: $scope.interventionRequest._id
            }]
        }, function (res) {
            angular.forEach(res.result, function (file) {
                $scope.interventionRequest.docs.push(file)
            });
            file.result = res.data;
        }, function (response) {
            if (response.status)
                $scope.errorMsg = response.status + ': ' + response.data;
        }, function (evt) {

        });
    };

    $scope.downloadFile = function (file) {
        i3HelperSrv.downloadFile(file._id, file.original_name, function success(res) {}, function error(err) {
            Notification.error($translate.instant(err));
        }, function progress(evt) {
            console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '%');
        })
    };

    $scope.showRealtime = function (asset) {

        var asset2 = null
        if ($scope.assets && $scope.assets.length > 0) {
            var filterded = $scope.assets.filter(function (val) {
                if (val._id == asset.asset_id) {

                    asset2 = val;
                }
            })
        }
        $scope.asset = asset2 || asset;
        var dialog = ngDialog.open({
            controller: "realtimeDlgCtrl",
            templateUrl: "app/commons/dialogs/realtime/realtime.dlg.tpl.html",
            scope: $scope,
            className: 'ngdialog-theme-default custom-width'
        });

        dialog.closePromise.then(function () {
            iaxeedSocketSrv.closeListner('newMsg');
        });
    }
    $scope.getInterventionsRequest = function () {
        if ($stateParams.id && $stateParams.id.length > 0) {
            I3InterventionSrv.query({
                    query: {
                        _id: $stateParams.id
                    },
                    options: {
                        populate: ["_assigned_to", "_claim", "_created_by", "docs", "_plateforme", {
                                path: '_claim',
                                populate: {
                                    path: '_plateforme'
                                }
                            }, {
                                path: 'followers.user'
                            }, {
                                path: 'comments.owner',
                                select: 'login'
                            }, {
                                path: '_claim',
                                populate: ['_created_by']
                            },
                            {
                                path: 'status_histo._modified_by',
                                select: "first_name  last_name login"
                            }
                        ]
                    }
                }, 1, 0, function (res) {
                    if (res && res.success && res.result.length > 0) {
                        $scope.interventionRequest = res.result[0];
                        $scope.redv = $scope.getvalue($scope.interventionRequest.dead_line, $scope.interventionRequest.dead_line_period);

                        if ($scope.interventionRequest._claim && $scope.interventionRequest._claim._plateforme) {
                            $scope.plateforme = $scope.interventionRequest._claim._plateforme
                        } else {
                            $scope.plateforme = $scope.interventionRequest._plateforme;
                        }

                        if (!$scope.interventionRequest.client_id && $scope._plateforme) {

                            $scope.verify();
                        }
                        /* if ($scope.plateforme.type == 'NGI_FLEET') {

                            return
                        } */
                        constantSrv.getModel("INTERVENTION").getAvailableStatusByStatus($scope.interventionRequest.status, function (err, val) {
                            $scope.liststatusAvailIntervention = val._availStatus.map(function (elem) {
                                return elem.name
                            }).filter(function (e) {
                                return ($scope.deniedStatusTransition[$scope.interventionRequest.status] || []).indexOf(e) < 0;
                            })
                        });
                        plateformHelperSrv.setPlateFormAndLogin($scope.plateforme, function (res) {

                                if (res.success) {
                                    $scope.platformeLogin = res;

                                    var assetIds = [];
                                    if ($scope.interventionRequest._claim && $scope.interventionRequest._claim.asset && $scope.interventionRequest._claim.asset.length > 0) {

                                        angular.forEach($scope.interventionRequest._claim.asset, function (asset) {
                                            assetIds.push(asset.asset_id);
                                        });
                                    } else {
                                        angular.forEach($scope.interventionRequest.asset, function (asset) {
                                            assetIds.push(asset.asset_id);
                                        });
                                    }
                                    var options = {};
                                    if ($scope.platformeLogin && $scope.platformeLogin.result && $scope.platformeLogin.result.token) {

                                        var query = {
                                            _id: {
                                                $in: assetIds
                                            }
                                        };

                                    }

                                    iaxeedAssetSrv.query({
                                        query: query,
                                        options: options
                                    }, 10000, 1, function (res1) {
                                        if (res1.result) {
                                            $scope.assets = res1.result;
                                        }
                                    }, function (err) {
                                        console.log(err);
                                    });
                                } else {
                                    Notification.error({
                                        title: $translate.instant("ERROR"),
                                        message: $translate.instant("PLATFORM_CNX_ERROR")
                                    });
                                }

                            },
                            function (err) {
                                console.log(err)
                            });

                        $scope.getFiles($scope.interventionRequest.docs);
                        delete $scope.interventionRequest._created_by;

                        if ($scope.interventionRequest.comments || $scope.interventionRequest.comments.length <= 0)
                            $scope.interventionRequest.comments = [{
                                owner: $rootScope.SESSION.user._id
                            }];

                    } else
                        Notification.error("FIND_BY_ID_ERROR");
                },
                function (err) {
                    Notification.error("FIND_BY_ID_ERROR")
                })
        }
    };

    $scope.verify = function () {
        console.log("verify")
        var modal = ngDialog.open({
            controller: "ClientIntervSyncDlgCtrl",
            templateUrl: "app/commons/dialogs/client/client.interv.sync.dlg.tpl.html",
            className: 'ngdialog-theme-default custom-width',
            scope: $scope,
            width: '900px',
            showClose: false,
            closeByEscape: false,
            closeByNavigation: true,
            closeByDocument: false,
            resolve: {
                intervention: function () {
                    return $scope.interventionRequest;
                }
            }
        });
        modal.closePromise.then(function (data) {
            $scope.interventionRequest.client_id = data.value._id;
            $scope.interventionRequest.client_name = data.value.name;
            I3InterventionSrv.updateById($scope.interventionRequest._id, $scope.interventionRequest, function (response) {
                if (response.success) {
                    Notification.success({
                        title: $translate.instant("SYNCHRONIZED_CLIENT"),
                        message: $translate.instant("SYNCHRONIZED_CLIENT_SUCC")
                    });
                } else {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("ERROR_OCCURED_UPDATE") + "!"
                    });
                }
            }, function (err) {
                var msg = "";
                if (err.error.errorString) {
                    msg = err.error.errorString;
                }
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: msg
                });
            })
        }, function () {});
    }

    $scope.cancel = function () {
        $state.go('app.home.interventionRequest');
    };

    $scope.getInterventionsRequest();

    $scope.isavailable = function (status) {
        if ($scope.liststatusAvailIntervention.indexOf(status) != -1 && $scope.interventionRequest.status != status) {

            return true
        } else
            return false
    };

    $scope.downloadZipGrouppedfile = function () {
        console.log("$scope.interventionRequest", $scope.interventionRequest)
        try {
            var params = {
                _ids: []
            };
            var comments = $translate.instant("INTERVENTION") + "\r\n";
            comments += "\t" + $translate.instant("CLIENT") + " :" + $scope.interventionRequest.client_name + "\r\n";
            comments += "\t" + $translate.instant("REFERENCE") + " :" + $scope.interventionRequest.ref + "\r\n";
            comments += "\t" + $translate.instant("purchase_order_nbr") + " :" + $scope.interventionRequest.purchase_order + "\r\n";
            comments += "-------------------------------------------------------------------------\r\n";
            comments += "\t- " + $translate.instant("DOCUMENTS") + " :\r\n";
            if ($scope.interventionRequest.docs && $scope.interventionRequest.docs.length > 0) {
                //params.ids = {interventionRequest: []}
                $scope.interventionRequest.docs.forEach(function (doc) {
                    if (doc._id)
                        params._ids.push(doc._id);
                })

                comments += "\t\t + intervention : " + $translate.instant("DOCUMENTS") + " " + $translate.instant("Intervention") + "\r\n";
            }
            /* $scope.interventionRequest.assets.forEach(function (asset) {
                 var asset_tag=asset.name;
                  if(asset.vin){
                      asset_tag=asset.vin
                  }
                 params.groups[asset_tag]=[];
                 if(asset.docs && asset.docs.length>0){
                     asset.docs.forEach(function (doc) {
                         if(doc._id)
                             params.groups[asset_tag].push(doc._id);
                     })
                     if(asset.gray_card_back && asset.gray_card_back.length>0){
                         params.groups[asset_tag].push(asset.gray_card_back);
                     }
                     if(asset.gray_card_front && asset.gray_card_front.length>0){
                         params.groups[asset_tag].push(asset.gray_card_front);
                     }

                 }

             })*/

            params.comments = comments
            i3HelperSrv.downloadZip(params, "Intervention_" + $scope.interventionRequest.client_name + "_" + $scope.interventionRequest.purchase_order + "_docs.zip", function (res) {

            }, function (err) {
                console.log(err)
            }, function (percent) {
                console.log(percent)
            })
        } catch (e) {

            console.error(e)
        }
    }
    $scope.selectStatus = function (status) {

        if ($scope.interventionRequest.purchase_order == undefined || $scope.interventionRequest.purchase_order == "" && $scope.interventionRequest.status == "NEW" && status == "READY") {

            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("ADD_PURCHASE_ORDER_NBR")
            });

        } else {

            // $scope.intervention.status = status;
            var liststatusavailIntervention;
            constantSrv.getModel("INTERVENTION").getAvailableStatusByStatus($scope.interventionRequest.status, function (err, val) {
                liststatusavailIntervention = val._availStatus.map(function (elem) {
                    return elem.name
                }).filter(function (e) {
                    return ($scope.deniedStatusTransition[$scope.interventionRequest.status] || []).indexOf(e) < 0;
                })
                $scope.liststatusAvailIntervention = liststatusavailIntervention
            })

            if (liststatusavailIntervention.indexOf(status) != -1 && $scope.interventionRequest.status != status) {

                messageBox.confirm("UPDATE", "UPDATE_INTERVENTION_STATUS").then(function (res) {
                    if (res) {

                        $scope.interventionRequest.status = status
                        I3InterventionSrv.updateById($scope.interventionRequest._id, $scope.interventionRequest, function (response) {

                            console.log("response", response)
                            if (response.success) {

                                Notification.success({
                                    title: $translate.instant("INTERVENTION_UPDATED_TITLE"),
                                    message: $translate.instant("INTERVENTION_UPDATED_SUC")
                                });
                            } else {
                                Notification.error({
                                    title: $translate.instant('ERROR'),
                                    message: $translate.instant("ERROR_OCCURED_UPDATE")
                                });
                            }
                        }, function (err) {
                            var msg = "";
                            if (err.error.errorString) {
                                msg = err.error.errorString;
                            }
                            Notification.error({
                                title: $translate.instant('ERROR'),
                                message: msg
                            });
                        })
                    }
                }, function (reject) {})
            }

            console.log("TCL: $scope.selectStatus -> status", status)
            if (status === "Planned" && !$scope.interventionRequest.planned_begin_dt) {
                $scope.interventionRequest.planned_begin_dt = new Date();
            } else if (status === "InProgress" && !$scope.interventionRequest.begin_dt) {
                $scope.interventionRequest.begin_dt = new Date();
            } else if (status === "Done" && !$scope.interventionRequest.end_dt) {
                $scope.interventionRequest.end_dt = new Date();
            }
        }
    }
    $scope.save = function (intervention) {

        I3InterventionSrv.updateById(intervention._id, intervention, function (res) {
            if (res.success) {
                Notification.success({
                    title: $translate.instant("DELETE_SUCC"),
                    message: $translate.instant('INTERVENTION_UPDATED')
                });
                $state.go('app.home.interventionRequest');
            } else {
                Notification.warning({
                    title: $translate.instant("UPDATE_ERROR"),
                    message: $translate.instant('INTERVENTION_UPDATE_FAILED')
                });
            }
        }, function (err) {
            Notification.error({
                title: $translate.instant("UPDATE_ERROR"),
                message: $translate.instant('INTERVENTION_UPDATE_FAILED')
            });
            console.log(err);
        })
    };
});