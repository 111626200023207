angular.module("app").controller("subscriptionsCtrl", function ($scope, $rootScope, $stateParams, $filter, $state, I3ProductSrv, I3SubscriptionSrv, $translate, ngDialog, Notification, I3PlateformeSrv) {
   // console.log($stateParams);
    $scope.$state = $state;
    $scope.search = {}
    $scope.itemsByPage = 50;
    $scope.products = [];
    var i = 0;
    // $scope.subscriptions=[]

    I3PlateformeSrv.query({}, 10000, 1, function (res) {
        console.log(res)
        if (res.success) {
            $scope.plateformes = res.result;
            if (($stateParams.platform) && (i == 0)) {
                var elementPos = $scope.plateformes.map(function (plat) {
                    return plat._id;
                }).indexOf($stateParams.platform);

                $scope.search._plateforme = $scope.plateformes[elementPos];
                i = 1;
            }
        }
    }, function (err) {
        console.log(err);
    });
    I3ProductSrv.query({}, 10000, 1, function (res) {
        if (res.success) {
            $scope.products = res.result;
        }
    }, function (err) {
        console.log(err);
    });

    function processSubscription(subscriptions) {
        if (subscriptions && subscriptions.length > 0) {
            var clientSubscriptions = {};

            subscriptions.forEach(function (sub) {

                if (!clientSubscriptions[sub._id]) clientSubscriptions[sub._id] = {
                    _client: sub._client,
                    _plateforme: sub._plateforme,
                    total: 0,
                    accessoryCnt: 0,
                    asset: 0,
                    total_boit: 0,
                    unsubscribe: 0
                };
                if (sub.isUnsubscribe) {

                    var product = null;
                    var unsubscribeProduct = $scope.products.filter(function (val) {

                        return sub._product && sub._product._unsubscribe_product && sub._product._unsubscribe_product == val._id
                    });

                    if (unsubscribeProduct && unsubscribeProduct.length > 0) {
                        product = unsubscribeProduct[0];

                    }
                    if (product && product.is_accessory) {
                        clientSubscriptions[sub._id].unsubscribe += sub.cnt;
                        clientSubscriptions[sub._id].accessoryCnt -= sub.cnt;

                    } else {
                        clientSubscriptions[sub._id].unsubscribe += sub.cnt;
                        clientSubscriptions[sub._id].asset -= sub.cnt;

                    }
                    clientSubscriptions[sub._id].total -= sub.cnt;

                } else {
                    if (sub._product && sub._product.is_accessory) {


                        clientSubscriptions[sub._id].accessoryCnt += sub.cnt;
                    } else {
                        if (sub._product._id == "5e8c518919e6da33eeda884b") {
                            clientSubscriptions[sub._id].total_boit += sub.cnt;
                        } else {
                            clientSubscriptions[sub._id].asset += sub.cnt;
                        }


                    }
                    clientSubscriptions[sub._id].total += sub.cnt;
                }
            })


            console.log('clientSubscriptions:', clientSubscriptions)
            return Object.values(clientSubscriptions);
        } else {
            return [];
        }

    }
    $scope.loadSubscriptions = function (tableState, ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }
        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;
        var query = [{
            $match: {
                firstBillingDate: {
                    $lte: "#ISODATE#" + (moment().endOf("month").toDate().toISOString()) + "#ISODATEEND#"
                },

                $or: [{
                    status: {
                        $exists: false
                    }
                }, {
                    status: "ENABLED"
                }],
                "_client.name": $scope.search._client ? {
                    '$regex': "^" + $scope.search._client,
                    '$options': 'i'
                } : undefined,
                _plateforme: $scope.search._plateforme ? "#OBJID#" + $scope.search._plateforme._id + "#OBJIDEND#" : undefined
            }
        },
        {
            $group: {
                _id: {
                    client: "$_client._id",
                    _product: "$_product"
                },
                cnt: {
                    $sum: "$quantity"
                },
                _plateforme: {
                    $first: "$_plateforme"
                },
                _client: {
                    $first: "$_client"
                },
                isUnsubscribe: {
                    $first: "$isUnsubscribe"
                }

            }
        },

        {
            $lookup: {
                from: "plateforme",
                localField: "_plateforme",
                foreignField: "_id",
                as: "_plateforme"
            }

        },
        {
            $lookup: {
                from: "product",
                localField: "_id._product",
                foreignField: "_id",
                as: "product"
            }

        },
        {

            $project: {

                _id: "$_client._id",
                _product: {
                    $arrayElemAt: ["$product", 0]
                },
                _plateforme: {
                    $arrayElemAt: ["$_plateforme", 0]
                },
                isUnsubscribe: "$isUnsubscribe",
                cnt: "$cnt",
                _client: "$_client"
            }
        }
        ];
        I3SubscriptionSrv.agg({
            aggregate: query
        }, number, start,
            function (data) {

                if (data.success) {

                    $scope.subscriptionCount = 0
                    $scope.subscriptions = processSubscription(angular.copy(data.result))
                    for (var i in $scope.subscriptions) {
                        $scope.subscriptionCount += $scope.subscriptions[i].total

                    }
                    // $scope.subscriptionCount= data.total_count;

                    tableState.pagination.numberOfPages = Math.ceil(data.total_count / number);
                    tableState.pagination.totalItemCount = data.total_count;
                    $scope.tableState = tableState;

                } else {
                    console.error(data.error)
                }
            },
            function (data) {
                console.log(data)
            })
    };

});