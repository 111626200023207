angular.module('app').controller('platformAddCtrl', function ($scope, $rootScope, $state, $translate, $filter, Notification, ngDialog, I3PlateformeSrv, plateformHelperSrv) {

    $scope.platform = {};

    /**
     * Test authentication params
     */
    $scope.test = function () {
        plateformHelperSrv.testLogin($scope.platform, function (res) {
            if (res.success) {
                Notification.success({
                    title: $translate.instant("AUTH_SUCCESS"),
                    message: $translate.instant("AUTH_SUCCESS_MSG")
                });
            } else {
                Notification.error({
                    title: $translate.instant("AUTH_ERROR"),
                    message: $translate.instant("AUTH_ERROR_MSG")
                });
            }
        }, function (err) {
            Notification.error({
                title: $translate.instant("AUTH_ERROR"),
                message: $translate.instant("AUTH_ERROR_MSG")
            });
        });
    };

    $scope.save = function () {

        // Creating a new platform
        I3PlateformeSrv.add($scope.platform, function (response) {

            if (response.success) {
                Notification.success({
                    title: $translate.instant("PLATFORM_CREATED"),
                    message: $translate.instant("PLATFORM_CREATED_SUC")
                });
                $state.go('app.admin.platform' ,{},{reload: true});

            } else {
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_CREATE") + " " + $scope.platform.name + "!"
                });
                $scope.platform = {};
                $state.go("app.admin.platform");
            }

        }, function (err) {

            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("ERROR_OCCURED_CREATE") + " " + $scope.platform.name + "!"
            });
            $scope.platform = {};
            $state.go("app.admin.platform");
        })
    };


    /**
     * Cancel creating/editing platform
     */
    $scope.cancel = function () {
        $scope.platform = {};
        $state.go("app.admin.platform");
    }

});