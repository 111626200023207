angular.module("app").controller("deliverySlipDetailCtrl", function ($scope, $state, $stateParams, $rootScope, $filter, $translate, ngDialog, Notification, I3deliverySlipSrv, I3StockSrv, I3ArticleSrv) {

    $scope.deliverySlip = {};
    $scope.ListArticleMov = [];
    I3deliverySlipSrv.query({
        query: {
            _id: $stateParams.deliverySlipId
        },
        options: {
            populate: [{
                path: "products._stock",
                select: "name"
            },
            {
                path: "products._article",
                select: "serialNumber"
            },
            {
                path: "products._product",
                select: 'name'

            },
            {
                path: "_plateforme",
                select: 'name'
            }, {
                path: "_lastModifiedBy",
                select: 'first_name last_name'
            },
            {
                path: "_stock",
                select: 'name virtual_stock'

            }

            ]
        }
    }, 1, 0, function (res) {

        if (res && res.success && res.result.length > 0) {
            $scope.deliverySlip = res.result[0];
            console.log("$scope.deliverySlip", $scope.deliverySlip)
        } else
            Notification.error($translate.instant("FIND_BY_ID_ERROR"));
    }, function (err) {
        Notification.error($translate.instant("FIND_BY_ID_ERROR"))
    })


    I3StockSrv.query({}, 10000, 1, function (res) {
        if (res.success) {
            $scope.ListStocks = res.result;
            $scope.virtual_stocks = [];
            $scope.ListStocks.forEach(function (val) {
                $scope.virtual_stocks = $scope.virtual_stocks.concat(val.virtual_stock.map(function (stock) {
                    stock._stock = val.name;
                    return stock
                }));
            })

        }
    }, function (err) {
        console.log(err);
    });





   


    $scope.getVirtualStokById = function (virtualStockId) {
        if (virtualStockId) {
            var filtred = $scope.virtual_stocks.filter(function (val) {
                return val._id == virtualStockId;
            })
            if (filtred && filtred.length > 0) {
                return filtred[0];
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    }





    $scope.cancel = function () {

        $state.go('app.stocks.deliverySlip');
    };










})