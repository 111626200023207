angular.module('app').controller("returnSlipStockEditCtrl", function ($scope,I3StockSrv, Notification,index) {

 
    $scope.stock=angular.copy($scope.returnSlip.products_installed[index]);
    var stockInitial=$scope.returnSlip.products_installed[index].stock
    var vStockInitial=$scope.returnSlip.products_installed[index].virtual_stock
    $scope.qty=""

    if( $scope.returnSlip.products_installed[index]._article  && $scope.returnSlip.products_installed[index]._article !=null && $scope.returnSlip.products_installed[index]._article .serialNumber !="" ){
       
        $scope.qty=1
    }else{
        $scope.qty=""
    }
        I3StockSrv.query({}, 10000, 1, function (res) {
            if (res.success) {
                $scope.ListStocks = res.result;
                $scope.virtual_stocks = [];
                $scope.ListStocks.forEach(function (val) {
                    $scope.virtual_stocks = $scope.virtual_stocks.concat(val.virtual_stock.map(function (stock) {
                        stock._stock = val.name;
                        return stock
                    }));
                })
         
            }
         }, function (err) {
            console.log(err);
         });
        
        $scope.getVirtualStokById=function(virtualStockId){
          if(virtualStockId){
              var filtred = $scope.virtual_stocks.filter(function(val){
                  return  val._id == virtualStockId;
              })
              if(filtred && filtred.length>0){
                  return  filtred[0];
              }else{
                  return undefined;
              }
          }else {
              return undefined;
          }
        }



        $scope.addreturnSlipStock=function(){
            var qtyInt = parseInt($scope.returnSlip.products_installed[index].qty)
            var qtyOut = parseInt($scope.qty)
            var differenceQty = qtyInt - qtyOut;
            $scope.returnSlip.products_installed[index].qty=differenceQty;
            $scope.returnSlip.products_installed= $scope.returnSlip.products_installed.filter(function(prod){
                return prod.qty>0;
            });
            var stock = {
                _product: $scope.stock._product,
                _stock: $scope.stock._stock,
                virtual_stock: $scope.stock.virtual_stock,
                _id: $scope.stock._id,
                _article: $scope.stock._article || null,
                qty: qtyOut
            }
            if(!$scope.returnSlip.products_return) $scope.returnSlip.products_return=[];
            if(stock._article){
                $scope.returnSlip.products_return.push(stock);
                $scope.closeThisDialog($scope.returnSlip)
            }else{

                var filterStock =$scope.returnSlip.products_return.filter(function (elem) {
                    var isSame = true;
                    if (elem.virtual_stock && stock.virtual_stock) {
                        isSame = (elem.virtual_stock._id ? elem.virtual_stock._id : elem.virtual_stock) == (stock.virtual_stock._id ? stock.virtual_stock._id : stock.virtual_stock);
                    } else {
                        isSame=false;
                        return false;
                    }
                    if (isSame && elem._stock && stock._stock) {
                        isSame = (elem._stock._id ? elem._stock._id : elem._stock) == (stock._stock._id ? stock._stock._id : stock._stock);
                    } else {
                        isSame=false;
                        return false;
                    }
                    if (isSame && elem._product && stock._product) {
                        isSame = (elem._product._id ? elem._product._id : elem._product) == (stock._product._id ? stock._product._id : stock._product);
                    } else {
                        isSame=false;
                        return false;
                    }

                    return isSame;


                })
                if(filterStock.length>0){
                    filterStock[0].qty=stock.qty+  filterStock[0].qty;
                    stock=filterStock[0]
                    $scope.closeThisDialog($scope.returnSlip)
                }else{
                    $scope.returnSlip.products_return.push(stock)
                    $scope.closeThisDialog($scope.returnSlip)
                }

            }



        }
})