angular.module('app.dialogs').controller('settingUserDlgCtrl', function ($scope, $rootScope, ngDialog, I3UserSrv, constantSrv, I3SettingSrv, Notification, $translate, i3HelperSrv, updateCfg, isUserConfig, modelId, isNewUser) {

    try {
        $scope.res = [];
        var keys = '';
        var statusKeys = '';
        var modelName="";
        $scope.selectedStatus = { status: [], key: {} };
        $scope.listStatus = [];

        if (modelId == "installation_group") {
            modelName = "installation_request"

        } else {

            modelName = modelId

        }

        constantSrv.getStatusbyModel(modelName).getStatus(function (err, status) {
            // console.log(status)
            $scope.listStatus = status
        });


        if (isUserConfig && isNewUser) {
            var users = [];

            $scope.isNewUser = true;
            $scope.selectedStatus.key.value = false;
            $scope.selectedStatus.exception = false;
            $scope.all = false;
            $scope.nothing = false;
            $scope.userConfig = {
                user: null,
                create: false,
                update: false
            };

            if ($scope.setting.usersConfig && $scope.setting.usersConfig.length > 0) {
                users = $scope.setting.usersConfig.map(function (e) {
                    return e.user;
                });
            }


            var query = {};
            if (users && users.length > 0) {
                query = {
                    _id: {
                        $nin: users
                    }
                };
            }
            I3UserSrv.query({
                query: query
            }, 10000, 0, function (data) {
                if (data.success) {
                    $scope.users = data.result;
                } else {
                    console.error(data.error)
                }

            }, function (data) {
                // console.log(data)
            });





        }

        i3HelperSrv.getFieldsByCollectionName(modelName.toUpperCase(), function (res) {
            if (res) {

                $scope.fields = [];
                //var fieldKeys   = Object.keys(res);
                var fieldKeys = Object.keys(res).filter(function (val) {

                    return val === 'status'
                });

                var res = "";
                if (typeof updateCfg != 'boolean' && updateCfg != undefined && updateCfg.status && updateCfg.status.length > 0) {

                    $scope.selectedStatus.key.value = true;
                    res = updateCfg.status;
                    $scope.selectedStatus.exception = true;
                    $scope.selectedStatus.status = res;
                    console.log(res)
                    if (res.length > 0) {
                        $scope.all = false;
                        $scope.selectedStatus.key.value = true;
                        $scope.selectedStatus.all = false;
                        $scope.selectedStatus.exception = true;
                    }

                } else if (updateCfg == 'status') {

                    $scope.selectedStatus.key.value = true;
                    $scope.selectedStatus.all = true;
                    $scope.all = false;
                    $scope.selectedStatus.exception = false;



                }
                angular.forEach(fieldKeys, function (e) {


                    $scope.fields.push({
                        code: e,
                        value: $scope.selectedStatus.key.value || false
                    });

                })


                /*     angular.forEach(fieldKeys, function (e) {
     
                         //  if (res.indexOf(e) >= 0) {
     
                         $scope.fields.push({
                             code: e,
                             value: true
                         });
                         /*     } else {
                                  $scope.fields.push({
                                      code: e,
                                      value: false
                                  });
                              }
                             }
                             
                         
     
                         })*/

                if (updateCfg === true) {
                    $scope.all = true;
                    $scope.selectedStatus.key.value = false;
                    $scope.nothing = false;
                } else if (updateCfg === false) {
                    $scope.nothing = true;
                    $scope.all = false;

                    $scope.selectedStatus.key.value = false;
                }
            } else {
                console.log('error')
            }
        }, function (err) {
            console.log(err)
        });

        $scope.getSelectedFields = function (status) {


            $scope.selectedStatus.key = status;
            $scope.all = false;
            $scope.nothing = false;
            if ($scope.selectedStatus.status.length > 0) {
                $scope.selectedStatus.all = false
            } else {
                $scope.selectedStatus.all = true
            }
            keys = '';
            angular.forEach($scope.fields, function (e, index) {

                if (e.value == true) {
                    if (keys === '') {
                        keys += e.code;
                    } else {
                        keys += " " + e.code;
                    }
                }

            })

            //console.log('$scope.fields', $scope.fields)
        };

        $scope.getNothing = function () {

            $scope.all = false;
            $scope.selectedStatus.key.value = false;
            $scope.fields[0].value = false;
            statusKeys = [];
            $scope.selectedStatus.all = false;
        }
        $scope.getAll = function () {
            $scope.nothing = false;

            $scope.selectedStatus.key.value = false;
            $scope.fields[0].value = false;
            $scope.selectedStatus.all = false;
            statusKeys = []
        }
        $scope.getSelectedStatus = function (statusList) {
            $scope.selectedStatus.exception = false;


        }
        $scope.getSelectedStatusList = function (statusList) {


            statusKeys = statusList

        }

        $scope.confirm = function () {

            if (isNewUser) {
                var userConfig = angular.copy($scope.userConfig);
                userConfig.user = $scope.userConfig.user;

                if ($scope.all) {
                    userConfig.update = true;
                    $scope.setting.usersConfig.push(userConfig);
                    $scope.closeThisDialog();

                } else  if ($scope.selectedStatus.all) {

                        userConfig.update = 'status';
                        $scope.setting.usersConfig.push(userConfig);
                        $scope.closeThisDialog();

                    } else  if (statusKeys && statusKeys !== '' && statusKeys.length > 0 && $scope.selectedStatus.exception == true) {

                            userConfig.update = { status: statusKeys };
                            $scope.setting.usersConfig.push(userConfig);
                            $scope.closeThisDialog();
                        } else {
                            userConfig.update = false;
                            $scope.setting.usersConfig.push(userConfig);

                            $scope.closeThisDialog(false);
                        }
            } else {


                if ($scope.all) {
                    $scope.closeThisDialog(true);

                } else if ($scope.selectedStatus.all) {
                        var status = 'status'
                        $scope.closeThisDialog(status);

                    } else  if (statusKeys && statusKeys !== '' && $scope.selectedStatus.exception == true) {
                               if (statusKeys.length > 0) {
                                $scope.closeThisDialog({ status: statusKeys });
                            }
                            $scope.closeThisDialog();
                        } else if ($scope.nothing) {

                                $scope.closeThisDialog(false);
                            }
                            else {

                                $scope.closeThisDialog();
                            }
            }

        }
    } catch (e) {
        console.error(e);
    }



    $scope.selectStatusEx = function (exStatus) {

        $scope.selectedStatus.all = false;
        if (exStatus == true) {

            $scope.selectedStatus.status = []
        }

    }

    $scope.getNothing = function () {

        $scope.all = false;
        $scope.selectedStatus.key.value = false;
        $scope.fields[0].value = false
    }
    $scope.getAll = function () {
        $scope.nothing = false;

        $scope.selectedStatus.key.value = false;
        $scope.fields[0].value = false
    }
});