angular.module('app.dialogs').controller('moveStocksDlgCtrl', function ($scope, constantSrv, I3ArticleSrv, $translate, Notification, I3StockStatusSrv, I3StockSrv, I3StockMovementSrv) {
    $scope.search = {};
    $scope.itemsByPage = 50;
    //$scope.stockArticles = [];
    $scope.moveArticles = []

    try {
        $scope.virtual_stock = {};
        $scope.move = {}
        $scope.movePatterns = constantSrv.getMoveStockPattern();
        $scope.checkStockAvailable = function () {
            $scope.isOutOfStock = true;
            $scope.showOutOfSotck = false;
            $scope.availableInStock = 0;

            if ($scope.move.old_stock && $scope.move.old_virtual_stock && $scope.move.qty) {

                var query = {
                    "_product": $scope.move._product._id,
                    _stock: $scope.move.old_stock._id,
                    virtual_stock: $scope.move.old_virtual_stock
                };

                I3StockStatusSrv.query({
                    query: query
                }, 1, 0, function (res) {
                    if (res && res.result && res.result.length > 0) {
                        $scope.availableInStock = res.result[0].qty;

                        $scope.isOutOfStock = res.result[0].qty < $scope.move.qty;
                        if ($scope.isOutOfStock) {
                            $scope.showOutOfSotck = true;
                        } else {
                            $scope.showOutOfSotck = false;
                        }
                    } else {
                        $scope.showOutOfSotck = true;
                        console.error("No stock found  ")
                    }
                }, function (err) {

                    console.error("errrrrrrrrrrrrrrrrror", err)
                })

            }

        }

        I3StockSrv.query({}, 10000, 1, function (res) {
            if (res.success) {
                $scope.ListStocks = res.result;
                $scope.virtual_stocks = [];
                $scope.ListStocks.forEach(function (val) {
                    $scope.virtual_stocks = $scope.virtual_stocks.concat(val.virtual_stock.map(function (stock) {
                        stock._stock = val.name;
                        return stock
                    }));
                })
                var agg_query = [{
                    $group: {
                        _id: {
                            _stock: "$_stock",
                            virtual_stock: "$virtual_stock"
                        },
                        "_products": {
                            $push: {
                                "_product": "$_product",
                                qty: "$qty"
                            }
                        }

                    }
                }]
                I3StockStatusSrv.agg({
                    aggregate: agg_query
                }, 10000, 0, function (res) {
                    if (res && res.success && res.result.length > 0) {
                        $scope._STOCK.ALLStocks.forEach(function (stock) {
                            stock.virtual_stock.forEach(function (vstock) {
                                res.result.forEach(function (stockStatus) {
                                    if (stockStatus._id && stockStatus._id.virtual_stock && vstock._id == stockStatus._id.virtual_stock) {
                                        if (!vstock._available_products) vstock._available_products = {}
                                        stockStatus._products.forEach(function (prod) {
                                            vstock._available_products[prod._product] = prod.qty
                                        })

                                    }
                                })

                            })
                        })
                        console.log($scope.ListStocks)
                    }

                }, function (err) {
                    console.log("enable to get stock status");
                })
            }
        }, function (err) {
            console.log(err);
        });
        $scope.getVirtualStokById = function (virtualStockId) {
            if (virtualStockId) {
                var filtred = $scope.virtual_stocks.filter(function (val) {
                    return val._id == virtualStockId;
                })
                if (filtred && filtred.length > 0) {
                    return filtred[0];
                } else {
                    return undefined;
                }
            } else {
                return undefined;
            }
        }

        $scope.moveStock = function () {

            if ($scope.move && $scope.move._product && $scope.move._product.is_trackSerial) {

                $scope.goToNext();

            } else {
                console.log("ssssssssssss")
                $scope.moveStockWithoutSerialNumber();
            }

        }
        $scope.moveStockWithoutSerialNumber = function () {
            var mvt = angular.copy($scope.move);
            if (mvt._product && mvt._product._id)
                mvt._product = mvt._product._id;
            if (mvt.old_stock && mvt.old_stock._id)
                mvt.old_stock = mvt.old_stock._id
            if (mvt.old_virtual_stock && mvt.old_virtual_stock._id)
                mvt.old_virtual_stock = mvt.old_virtual_stock._id
            if (mvt.new_stock && mvt.new_stock._id)
                mvt.new_stock = mvt.new_stock._id
            if (mvt.new_virtual_stock && mvt.new_virtual_stock._id)
                mvt.new_virtual_stock = mvt.new_virtual_stock._id
            mvt.details = {
                comment: mvt.comment
            }
            if (mvt.move_pattern && mvt.move_pattern.code) {
                mvt.operation_desc = mvt.move_pattern.name;
                mvt.operation_type = mvt.move_pattern.code;
            }
            delete mvt.move_pattern
            I3StockMovementSrv.add(mvt, function (res) {
                if (res && res.success) {
                    Notification.success({
                        title: $translate.instant("STOCK_MOVED"),
                        message: $translate.instant("STOCK_MOVED_SUCCESSFULLY")
                    });
                    $scope.closeThisDialog();
                    $scope.stCtrl.pipe();
                } else {
                    Notification.error({
                        title: "STOCK_MOVED",
                        message: $translate.instant("STOCK_MOVED_ERROR")
                    });
                }

            }, function (err) {
                Notification.error({
                    title: "STOCK_MOVED",
                    message: $translate.instant("STOCK_MOVED_ERROR")
                });
            })

        }
        $scope.goToNext = function () {
            console.log("go to Next")

            $scope.next = true;
            if ($scope.moveArticles.length > 0 && $scope.next == true) {

                $scope.moveStockWithSerialNumber()

            }
        }
        $scope.goToPrevious = function () {
            $scope.next = false;
            $scope.moveArticles = []
        }
        $scope.moveStockWithSerialNumber = function () {

            if ($scope.move) {
                var stockId, vStockId, comment = "";
                if ($scope.move.new_stock && $scope.move.new_stock._id) {
                    stockId = $scope.move.new_stock._id
                }
                if ($scope.move.new_virtual_stock && $scope.move.new_virtual_stock._id) {
                    vStockId = $scope.move.new_virtual_stock._id
                }
                if ($scope.move.comment && $scope.move.comment.length > 0) {
                    comment = $scope.move.comment
                }
                var data = {
                    _stock: stockId,
                    virtual_stock: vStockId,
                    details: {
                        comment: comment
                    }
                }

                var ids = $scope.moveArticles.map(function (article) {
                    return article._id;
                })

                I3ArticleSrv.updateMultiple({
                    _id: {
                        $in: ids
                    }
                }, data, function (res) {

                    if (res.success) {
                        Notification.success({
                            title: $translate.instant("STOCK_MOVED"),
                            message: $translate.instant("STOCK_MOVED_SUCCESSFULLY")
                        });
                        $scope.closeThisDialog();
                        $scope.stCtrl.pipe();
                        $scope.moveArticles = {};
                    } else {
                        Notification.error({
                            title: "STOCK_MOVED",
                            message: $translate.instant("STOCK_MOVED_ERROR")
                        });
                    }
                }, function (err) {
                    Notification.error({
                        title: "STOCK_MOVED",
                        message: $translate.instant("STOCK_MOVED_ERROR")
                    });
                })
            } else {
                console.error("invalid stock move ")
            }
        };

    } catch (e) {
        console.error(e);
    }

    $scope.loadStockArticles = function ($select) {
        if (!$scope.move || !$scope.move._product || !$scope.move.old_stock._id || !$scope.move.old_virtual_stock._id)
            return;
        console.log("mmm", $scope.move)

        var start = 0;
        var number = 10;

        var options = {
            populate: [{
                path: '_product',
                select: 'name'
            },
            {
                path: '_stock',
                select: 'name'
            },
            ],
            sortBy: {
                creation_dt: -1
            }
        };

        var query = {
            options: options
        };
        //$select.search

        var requery = {

            _stock: $scope.move.old_stock._id,
            virtual_stock: $scope.move.old_virtual_stock._id,
            _product: $scope.move._product._id,
            $or: [{
                serialNumber: {
                    $regex: "^" + $select.search,
                    $options: "i"
                }
            },
            {
                imei: {
                    $regex: "^" + $select.search,
                    $options: "i"
                }
            }
            ],
            _id: {
                $nin: $scope.moveArticles.map(function (art) {
                    return art._id;
                })
            }

        };

        query.query = requery;

        /*if($scope.move._product && $scope.move._product._id){
            query.query._product=$scope.move._product._id;
            query.query._stock=$scope.move.old_stock._id;
            query.query.virtual_stock=$scope.move.old_virtual_stock;*/

        I3ArticleSrv.query(query, number, start,
            function (data) {

                if (data.success && data.result.length > 0) {
                    $scope.stockArticles = data.result;

                    //$scope.stockArticlesCount = data.total_count;
                    //     tableState.pagination.numberOfPages = Math.ceil(data.total_count / number);
                    //   tableState.pagination.totalItemCount = data.total_count;
                    // $scope.tableState = tableState;
                } else {
                    $scope.stockArticles = []
                    console.error(data.error)
                }
            },
            function (err) {
                console.log("err", err)
            })

    }

    $scope.setStock = function (article) {
        console.log("article", article)
        article._stock = $scope.move.new_stock._id
        article.virtual_stock = $scope.move.new_virtual_stock._id
        var qty = parseInt($scope.move.qty, 10)

        if ($scope.moveArticles.length < qty) {

            $scope.moveArticles.push(article);
            $scope.search.article = null
        } else {

            Notification.error({
                title: "QUANTITY",
                message: $translate.instant("STOCK_MOVED_QUANTITY_ERROR")
            });

        }

    }

    $scope.remove = function (moveArticle, index) {

        $scope.moveArticles.splice(index, 1)
        $scope.search.serialNumber = null
    }

});