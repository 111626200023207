angular.module('app').controller('adminPlatformCtrl', function ($scope, $rootScope, $state, $translate, $filter, Notification, ngDialog, I3PlateformeSrv, plateformHelperSrv, i3HelperSrv) {

    $scope.platform = {};

    /**
     * Loading platform type
     */

    i3HelperSrv.getEnums(function (res) {
        $scope.platform_type = res.PLATFORM_TYPE.LIST
    }, function (err) {

    })
    I3PlateformeSrv.query({}, 10000, 1, function (response) {
        $scope.platforms = response.result;
    }, function (err) {
        console.log(err);
    });

    /**
     * Removing platform
     * @param platform
     */
    $scope.remove = function (platform) {

        $scope.platform = platform;

        ngDialog.open({
            templateUrl: "app/commons/dialogs/confirm/platform.delete.dlg.tpl.html",
            controller: "confirmDeletePlatformDlgCtrl",
            scope: $scope
        })

    };

});