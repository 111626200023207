/**
 * Created by Mohamed Saad on 18/12/2017.
 */

angular.module("app").controller("adminRoleEditCtrl",function ($scope, $state, I3RoleSrv,$stateParams,$filter,$translate,Notification,constantSrv) {
    $scope.toDo = "PERMISSION";
    $scope.accessTypes=[
        {code:null,label:"SELECT_ACCESS_TYPE"},
        {code:"X", label:"NO_LIMIT_ACCESS"},
     //   {code:"A", label:"ATTACHED_ACCESS"},
        {code:"O", label:"OWNER_ACCESS"},
       // {code:"U", label:"USER_ACCESS"}
    ];
    $scope.profiles = constantSrv.getProfiles();
    $scope.listProfiles = Object.keys($scope.profiles)
    $scope.allRoles = function(){
        I3RoleSrv.query({},10000,0,function (data) {
            if (data.success) {
                $scope.roleChildsList = data.result;
                $scope.roleChildsList = $scope.roleChildsList.map(function (el) {
                    return el._id
                });
                $scope.roleChildsList.count = data.total_count;

            } else {
                console.error(data.error)
            }

        }, function (data) {
            console.log(data)
        })
    }
    $scope.userRole={};
    $scope.sessionRolePermissions = $scope.SESSION.user._role.permissions;
    I3RoleSrv.getById($stateParams.roleId, function (data) {
        if(data.success && data.result){
            $scope.role=data.result;
            $scope.sessionRolePermissions= $scope.sessionRolePermissions.map(function(allPerm){
                var allCrud=   allPerm.substring(0, allPerm.indexOf("_"));
                var  allRes = allPerm.substring(allPerm.indexOf("_")+1, allPerm.length);
                var allAccessType= allRes.substring(0,allRes.indexOf("_"));
                allRes = allPerm.substring(allPerm.indexOf("_")+1, allPerm.length);
                var allModelName = allRes.substring(allRes.indexOf("_")+1, allRes.length);
                return {
                    allModel:allModelName,
                    r:(allCrud.indexOf("R")>=0),
                    c:(allCrud.indexOf("C")>=0),
                    u:(allCrud.indexOf("U")>=0),
                    d:(allCrud.indexOf("D")>=0),
                    accessType:allAccessType
                }
            });
            $scope.role.permissions= $scope.role.permissions.map(function(perm){
                var crud=   perm.substring(0, perm.indexOf("_"));
                var  res = perm.substring(perm.indexOf("_")+1, perm.length);
                var accessType= res.substring(0,res.indexOf("_"));
                res = perm.substring(perm.indexOf("_")+1, perm.length);
                var modelName = res.substring(res.indexOf("_")+1, res.length);
                return {
                    model:modelName,
                    r:(crud.indexOf("R")>=0),
                    c:(crud.indexOf("C")>=0),
                    u:(crud.indexOf("U")>=0),
                    d:(crud.indexOf("D")>=0),
                    accessType:accessType
                }
            });
            if($scope.sessionRolePermissions.length != $scope.role.permissions.length){
                for(var i = 0; i<$scope.sessionRolePermissions.length; i++){
                    var sessionModel = $scope.sessionRolePermissions[i].allModel;
                    for(var j = 0; j<$scope.role.permissions.length; j++) {
                        var currentModel = $scope.role.permissions[j].model;
                        if(sessionModel == currentModel){
                             //i++;
                            break

                        }
                        else if(j == $scope.role.permissions.length-1 &&(sessionModel != currentModel) ){
                                $scope.role.permissions.push(
                                    {model:sessionModel,
                                    r:false,
                                    c:false,
                                    u:false,
                                    d:false,
                                    accessType:null
                                })
                            }

                    }

                }
            }

            if($scope.role.description){
                $scope.config=JSON.stringify($scope.role.description);
            }
        }else {
            console.log(data.error)
        }
        $scope.allRoles();

    }, function (error) {
        if (error) {
            console.log(error);
            $state.go("app.admin.roles")
        }
    });
    $scope.editRole=function() {
        var permissions = [];
        var final = [];
        if ($scope.role) {
            permissions = $scope.role.permissions.map(function (perm) {
                var permString = (perm.c && 'C' || '') + (perm.r && 'R' || '') + (perm.u && 'U' || '') + (perm.d && 'D' || '') + "_" + (perm.accessType || 'U') + "_" + perm.model;
                return permString;
            });
        }
        //for(var i=0; i < permissions.length; i++){
        //    if(permissions[i].charAt(0)!== "_"){
        //        final.push(permissions[i])
        //    }
        //}
        $scope.role.permissions = permissions;
        I3RoleSrv.updateById($scope.role._id,$scope.role, function (data) {
            if(data.success) {
                $state.go('app.admin.roles');
                Notification($translate.instant("ALERT_ROLE_UPDATED", {name: $translate.instant($scope.role._id)}));
                $scope.loadRoles()
            }else {
                console.log(data.error)
            }
        }, function () {

        })
    };
    $scope.permForm = function(){
        $scope.toDo = "PERMISSION";
    };
    $scope.childForm = function(){
        $scope.toDo = "CHILDS";
    };

});