angular.module('app').controller('adminZonesEditCtrl', function ($scope, $timeout, $rootScope, $state, $stateParams, $translate, $filter, Notification, ngDialog, I3ZoneSrv) {
    $scope.zone = {};
    I3ZoneSrv.query({query:{_id:$stateParams.zoneId},options:{populate:[{path:"_plateforme"}]}},1,0,function(res) {
        if(res && res.result){
            $scope.zone = res.result[0];

        }
    })
    	$timeout(function () {}, 500).then(function () {
    	    if (($rootScope.checkAccess("PLATEFORME","R","O")) && $scope._ADMIN && $scope._ADMIN.platforms && $scope._ADMIN.platforms[0])
    	        $scope.zone._plateforme = $scope._ADMIN.platforms[0];
    	});

    $scope.save = function () {
        I3ZoneSrv.updateById($scope.zone._id, $scope.zone, function (response) {
                if(response.success){
                    Notification.success({
                        title: $translate.instant("ZONE_UPDATED"),
                        message : $translate.instant("ZONE_UPDATED_SUCC")
                    });
                    $state.go('app.admin.zones');
                    $scope.zone = {};
                }else{
                    Notification.error({
                        title: $translate.instant('ERROR'),
                        message : $translate.instant("ERROR_OCCURED_UPDATE") +" "+ $scope.zone.name +"!"
                    });
                    $scope.zone = {};
                }
            }, function (err) {
                console.log(err);

                Notification.error({
                    title: $translate.instant('ERROR'),
                    message : $translate.instant("ERROR_OCCURED_UDPDATE") +" "+ $scope.zone.name +"!"
                });
                $scope.zone = {};

            })
    };

    $scope.removeCost = function (element, index) {
        $scope.zone.travel_costs.splice(index, 1);
    };
    $scope.editCost = function (element, index) {
        ngDialog.open({
            templateUrl: "app/commons/dialogs/zone/travel_costs.edit.dlg.html",
            controller: "travelCostEditDlgCtrl",
            scope: $scope,
            resolve: {
                index: function () {
                    return index;
                }
            }
        })
    };
    $scope.openTravelCostDialog = function () {
        ngDialog.open({
            templateUrl: "app/commons/dialogs/zone/travel_costs.dlg.html",
            controller: "travelCostDlgCtrl",
            scope: $scope
        })
    };
    /**
     * Cancel creating/editing zone
     */
    $scope.cancel = function () {
        $scope.zone = {};
        $state.go('app.admin.zones');
    };

});