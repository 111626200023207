/**
 * Created by abdelmajid medini on 17/04/2020.
 */
angular.module("app.services").factory('accessSrv', function ($rootScope, $state, Notification, $translate, $location, i3HelperSrv) {

    var service = {}

    service.getAllModule = function () {

        return [
            "DASHBOARD",
            "HOME",
            "MONITORING",
            "ACCOUNTS_SUBSCRIPTION",
            "STOCK_MANAGEMENT",
            "ADMIN"

        ]
    }
    service.getHomeMenu = function () {

        return [{
                name: "COMPATIBILITY_REQUEST",
                value: false
            },
            {
                name: "COMPATIBILITY_CHECK",
                value: false
            },
            {
                name: "INSTALLATION_REQUEST",
                value: false
            },
            {
                name: "INSTALLATION",
                value: false
            },
            {
                name: "INSTALLATION_VALIDATION",
                value: false
            },
            {
                name: "CLAIM",
                value: false
            },
            {
                name: "INTERVENTION_REQUEST",
                value: false
            },
            {
                name: "INTERVENTION",
                value: false
            },
            {
                name: "INTERVENTION_VALIDATION",
                value: false
            },
            {
                name: "PV",
                value: false
            },

        ]
    }

    service.getMonitoringMenu = function () {

        return [{
                name: "MONITORING",
                value: false
            },
            {
                name: "STATISTIC_PLATFORM",
                value: false
            }

        ]
    }

    service.getDashboardMenu = function () {

        return [{
                name: "DASH",
                value: false
            },
            {
                name: "HISTORY",
                value: false
            }

        ]
    }

    service.getSubscriptionMenu = function () {

        return [{
                name: "SUBSCRIPTION",
                value: false
            },
            {
                name: "ACCOUNTS",
                value: false
            }

        ]
    }

    service.getStockManageMenu = function () {

        return [{
                name: "RECEIPT_SLIP",
                value: false
            },
            {
                name: "DELIVERY_REQUEST",
                value: false
            },
            {
                name: "DELIVERY_SLIP",
                value: false
            },
            {
                name: "GOODS_RETURN_SLIP",
                value: false
            },
            {
                name: "STOCK",
                value: false
            },
            {
                name: "ARTICLE",
                value: false
            },
            {
                name: "STOCK_MOVEMENT",
                value: false
            }

        ]
    }

    service.getAdminMenu = function () {

        return [{
                name: "PLATEFORME",
                value: false
            },
            {
                name: "CLIENT",
                value: false
            },
            {
                name: "USER",
                value: false
            },
            {
                name: "PRODUCT",
                value: false
            },
            {
                name: "PRODUCT_CODE",
                value: false
            },
            {
                name: "STOCK",
                value: false
            },
            {
                name: "DOCUMENT",
                value: false
            },
            {
                name: "ZONE",
                value: false
            },
            {
                name: "ROLE",
                value: false
            },
            {
                name: "SETTING",
                value: false
            },
            {
                name: "STATUS",
                value: false
            },
            {
                name: "WORKFLOW",
                value: false
            },
            {
                name: "DASHBOARD",
                value: false
            },
            {
                name: "INVOICE_CONNECTOR",
                value: false
            }

        ]
    }

    service.getAllAccessMenu = function () {
        var menus = []
        menus['DASHBOARD'] = service.getDashboardMenu()
        menus['HOME'] = service.getHomeMenu()
        menus['MONITORING'] = service.getMonitoringMenu()
        menus['ACCOUNTS_SUBSCRIPTION'] = service.getSubscriptionMenu()
        menus['STOCK_MANAGEMENT'] = service.getStockManageMenu()
        menus['ADMIN'] = service.getAdminMenu()
        return menus
    }
    service.getAllMenuByModel = function (model) {
        if (model == "DASHBOARD")
            return service.getDashboardMenu()
        if (model == "HOME")
            return service.getHomeMenu()
        if (model == "MONITORING")
            return service.getMonitoringMenu()
        if (model == "ACCOUNTS_SUBSCRIPTION")
            return service.getSubscriptionMenu()
        if (model == "STOCK_MANAGEMENT")
            return service.getStockManageMenu()
        if (model == "ADMIN")
            return service.getAdminMenu()

    }

    service.getALLMenuAccess = function (menus) {
        var menu = []
        var menuList = menus.map(function (elem) {

            var accessResult = elem.split("::");
            var modelUser = accessResult[0];
            var menuUser = accessResult.slice(1, accessResult.length).join("_");

            return {
                model: modelUser,
                menu: {
                    name: menuUser,
                    value: true
                }
            }

        })
        menu['DASHBOARD'] = []
        menu['HOME'] = []
        menu['MONITORING'] = []
        menu['ACCOUNTS_SUBSCRIPTION'] = []
        menu['STOCK_MANAGEMENT'] = []
        menu['ADMIN'] = []
        menuList.forEach(function (value) {
            if (value && value.model == 'DASHBOARD') {
                menu['DASHBOARD'].push(value.menu)
            }
            if (value && value.model == 'HOME') {
                menu['HOME'].push(value.menu)
            }
            if (value && value.model == 'MONITORING') {
                menu['MONITORING'].push(value.menu)
            }
            if (value && value.model == 'ACCOUNTS_SUBSCRIPTION') {
                menu['ACCOUNTS_SUBSCRIPTION'].push(value.menu)
            }
            if (value && value.model == 'STOCK_MANAGEMENT') {
                menu['STOCK_MANAGEMENT'].push(value.menu)
            }
            if (value && value.model == 'ADMIN') {
                menu['ADMIN'].push(value.menu)
            }

        })
        var initAccess = service.getAllAccessMenu()
        for (key in initAccess) {
            initAccess[key].forEach(function (elem1) {
                menu[key].forEach(function (elem2) {
                    if (elem1.name == elem2.name)
                        elem1.value = true
                })
            })
        }

        return initAccess
    }

    service.getGroupMenubyModel = function (group) {
        var menuList = []
        var menus = []

        for (key in group) {

            menuList[key] = group[key].filter(function (val) {

                if (val && val.value == true) {
                    return val
                }
            })
        }
        for (key in menuList) {
            menuList[key].forEach(function (elem) {
                menus.push(service.addAccessUser(key, elem.name))
            })
        }
        return menus

    }
    service.addAccessUser = function (model, menu) {

        var accessUser = model + '::' + menu;

        return accessUser

    }

    service.getAccessUser = function (model, menu) {
        var access = false

        if ($rootScope.SESSION.user.menus && $rootScope.SESSION.user.menus.length > 0) {
            $rootScope.SESSION.user.menus.forEach(function (elem) {

                var accessResult = elem.split("::");
                var modelUser = accessResult[0];
                var menuUser = accessResult.slice(1, accessResult.length).join("_");

                if (modelUser === model && menuUser === menu) {

                    access = true

                }
            })
        }
        return access
    }
    service.loadMenus = function () {
        return [{
                model: 'DASHBOARD',
                name: "DASH",
                permission: $rootScope.checkPermission('DASHBOARD', 'R', null, 'DASHBOARD', 'DASH'),
                route: 'app.dashboard.dash',

            }, {
                model: 'DASHBOARD',
                name: "HISTORY",
                permission: $rootScope.checkPermission('DASHBOARD', 'R', null, 'DASHBOARD', 'HISTORY'),
                route: 'app.dashboard.history',

            }, {
                model: 'HOME',
                name: "COMPATIBILITY_REQUEST",
                permission: $rootScope.checkPermission('COMPATIBILITY_REQUEST', 'R', null, 'HOME', 'COMPATIBILITY_REQUEST'),
                route: 'app.home.compatibilityRequest',

            },

            {
                model: 'HOME',
                name: "COMPATIBILITY_CHECK",
                permission: $rootScope.checkPermission('COMPATIBILITY_CHECK', 'R', null, 'HOME', 'COMPATIBILITY_CHECK'),
                route: 'app.home.compatibilityCheck',

            },

            {
                model: 'HOME',
                name: "INSTALLATION_REQUEST",
                permission: $rootScope.checkPermission('INSTALLATION_REQUEST', 'R', null, 'HOME', 'INSTALLATION_REQUEST'),
                route: 'app.home.installRequest',

            },
            {
                model: 'HOME',
                name: "INSTALLATION",
                permission: $rootScope.checkPermission('INSTALLATION', 'R', null, 'HOME', 'INSTALLATION'),
                route: 'app.home.install',

            }, {
                model: 'HOME',
                name: "INSTALLATION_VALIDATION",
                permission: $rootScope.checkPermission('INSTALLATION_VALIDATION', 'R', null, 'HOME', 'INSTALLATION_VALIDATION'),
                route: 'app.home.installValidation',

            }, {
                model: 'HOME',
                name: "CLAIM",
                permission: $rootScope.checkPermission('CLAIM', 'R', null, 'HOME', 'CLAIM'),
                route: 'app.home.claims',

            },
            {
                model: 'HOME',
                name: "INTERVENTION_REQUEST",
                permission: $rootScope.checkPermission('INTERVENTION_REQUEST', 'R', null, 'HOME', 'INTERVENTION_REQUEST'),
                route: 'app.home.interventionRequest',

            },
            {
                model: 'HOME',
                name: "INTERVENTION",
                permission: $rootScope.checkPermission('INTERVENTION', 'R', null, 'HOME', 'INTERVENTION'),
                route: 'app.home.intervention',

            },
            {
                model: 'HOME',
                name: "INTERVENTION_VALIDATION",
                permission: $rootScope.checkPermission('INTERVENTION_VALIDATION', 'R', null, 'HOME', 'INTERVENTION_VALIDATION'),
                route: 'app.home.interventionValidation',

            },

            {
                model: 'HOME',
                name: "PV",
                permission: $rootScope.checkPermission('PV', 'R', null, 'HOME', 'PV'),
                route: 'app.home.pvs',

            },

            {
                model: 'ADMIN',
                name: "PLATEFORME",
                permission: $rootScope.checkPermission('PLATEFORME', 'R', null, 'ADMIN', 'PLATEFORME'),
                route: 'app.admin.platform',

            },
            {
                model: 'ADMIN',
                name: "CLIENT",
                permission: $rootScope.checkPermission('CLIENT', 'R', null, 'ADMIN', 'CLIENT'),
                route: 'app.admin.clients',

            },
            {
                model: 'ADMIN',
                name: "USER",
                permission: $rootScope.checkPermission('USER', 'R', null, 'ADMIN', 'USER'),
                route: 'app.admin.users',

            },

            {
                model: 'ADMIN',
                name: "PRODUCT",
                permission: $rootScope.checkPermission('PRODUCT', 'R', null, 'ADMIN', 'PRODUCT'),
                route: 'app.admin.products',

            },
            {
                model: 'ADMIN',
                name: "PRODUCT_CODE",
                permission: $rootScope.checkPermission('PRODUCT_CODE', 'R', null, 'ADMIN', 'PRODUCT_CODE'),
                route: 'app.admin.product_code',

            },
            {
                model: 'ADMIN',
                name: "STOCK",
                permission: $rootScope.checkPermission('STOCK', 'R', null, 'ADMIN', 'STOCK'),
                route: 'app.admin.stocks',

            },

            {
                model: 'ADMIN',
                name: "DOCUMENT",
                permission: $rootScope.checkPermission('DOCUMENT', 'R', null, 'ADMIN', 'DOCUMENT'),
                route: 'app.admin.documents',

            },
            {
                model: 'ADMIN',
                name: "ZONE",
                permission: $rootScope.checkPermission('ZONE', 'R', null, 'ADMIN', 'ZONE'),
                route: 'app.admin.zones',

            }, {
                model: 'ADMIN',
                name: "ROLE",
                permission: $rootScope.checkPermission('ROLE', 'R', 'X', 'ADMIN', 'ROLE'),
                route: 'app.admin.roles',

            },
            {
                model: 'ADMIN',
                name: "SETTING",
                permission: $rootScope.checkPermission('SETTING', 'R', null, 'ADMIN', 'SETTING'),
                route: 'app.admin.settings',

            },
            {
                model: 'ADMIN',
                name: "ACTIONS_LOG",
                permission: $rootScope.checkPermission('ACTION_LOG', 'R', null, 'ADMIN', 'ACTION_LOG'),
                route: 'app.admin.actionlogs',
                icon: 'fa fa-history',

            }, {
                model: 'ADMIN',
                name: "STATUS",
                permission: $rootScope.checkPermission('STATUS', 'R', 'X', 'ADMIN', 'STATUS'),
                route: 'app.admin.status',
                icon: 'fa fa-check',

            }, {
                model: 'ADMIN',
                name: "WORKFLOW",
                permission: $rootScope.checkPermission('WORKFLOW', 'R', 'X', 'ADMIN', 'WORKFLOW'),
                route: 'app.admin.workflow',

            }, {
                model: 'ADMIN',
                name: "DASHBOARD",
                permission: $rootScope.checkPermission('DASHBOARD', 'R', 'X', 'ADMIN', 'DASHBOARD'),
                route: 'app.admin.dashboard',

                access: service.getAccessUser('ADMIN', 'DASHBOARD')
            }, {
                model: 'ADMIN',
                name: "INVOICE_CONNECTOR",
                permission: $rootScope.checkPermission('INVOICE_CONNECTOR', 'R', 'X', 'ADMIN', 'INVOICE_CONNECTOR'),
                route: 'app.admin.invoiceConnector',

            },

            {
                model: 'MONITORING',
                name: "MONITORING",
                permission: $rootScope.checkPermission('MONITORING', 'R', null, 'MONITORING', 'MONITORING'),
                route: 'app.monitoring.anomaly',

            }, {
                model: 'MONITORING',
                name: "STATISTIC_PLATFORM",
                permission: $rootScope.checkPermission('STATISTIC_PLATFORM', 'R', null, 'MONITORING', 'STATISTIC_PLATFORM'),
                route: 'app.monitoring.statistic',

            },
            {
                model: 'ACCOUNTS_SUBSCRIPTION',
                name: "SUBSCRIPTION",
                permission: $rootScope.checkPermission('SUBSCRIPTION', 'R', null, 'ACCOUNTS_SUBSCRIPTION', 'SUBSCRIPTION'),
                route: 'app.accounts.subscriptions',

            }, {
                model: 'ACCOUNTS_SUBSCRIPTION',
                name: "ACCOUNTS",
                permission: $rootScope.checkPermission('SUBSCRIPTION', 'R', null, 'ACCOUNTS_SUBSCRIPTION', 'ACCOUNTS'),
                route: 'app.accounts.clients',

            },

            {
                model: 'STOCK_MANAGEMENT',
                name: "RECEIPT_SLIP",
                permission: $rootScope.checkPermission('RECEIPT_SLIP', 'R', null, 'STOCK_MANAGEMENT', 'RECEIPT_SLIP'),
                route: 'app.stocks.reception',

            }, {
                model: 'STOCK_MANAGEMENT',
                name: "DELIVERY_REQUEST",
                permission: $rootScope.checkPermission('DELIVERY_REQUEST', 'R', null, 'STOCK_MANAGEMENT', 'DELIVERY_REQUEST'),
                route: 'app.stocks.deliveryRequest',

            }, {
                model: 'STOCK_MANAGEMENT',
                name: "DELIVERY_SLIP",
                permission: $rootScope.checkPermission('DELIVERY_SLIP', 'R', null, 'STOCK_MANAGEMENT', 'DELIVERY_SLIP'),
                route: 'app.stocks.deliverySlip',

            }, {
                model: 'STOCK_MANAGEMENT',
                name: "GOODS_RETURN_SLIP",
                permission: $rootScope.checkPermission('GOODS_RETURN_SLIP', 'R', null, 'STOCK_MANAGEMENT', 'GOODS_RETURN_SLIP'),
                route: 'app.stocks.goodsReturnSlip',

            }, {
                model: 'STOCK_MANAGEMENT',
                name: "STOCK_STATUS",
                permission: $rootScope.checkPermission('STOCK', 'R', null, 'STOCK_MANAGEMENT', 'STOCK'),
                route: 'app.stocks.status',

            }, {
                model: 'STOCK_MANAGEMENT',
                name: "ARTICLE",
                permission: $rootScope.checkPermission('ARTICLE', 'R', null, 'STOCK_MANAGEMENT', 'ARTICLE'),
                route: 'app.stocks.articles',

            },
            {
                model: 'STOCK_MANAGEMENT',
                name: "STOCK_MOVEMENT",
                permission: $rootScope.checkPermission('STOCK_MOVEMENT', 'R', null, 'STOCK_MANAGEMENT', 'STOCK_MOVEMENT'),
                route: 'app.stocks.stockMovement',

            }
        ]

    }

    service.getInitialPage = function (login) {
        var permisionList = [];
        var loadMenus = service.loadMenus()

        var menu = [];
        if (login.user.menus && login.user.menus.length > 0) {

            var menuList = login.user.menus.map(function (elem) {

                var accessResult = elem.split("::");
                var modelUser = accessResult[0];
                var menuUser = accessResult.slice(1, accessResult.length).join("_");

                return {
                    model: modelUser,
                    menu: {
                        name: menuUser,
                        value: true
                    }
                }

            })
            menu['DASHBOARD'] = []
            menu['HOME'] = []
            menu['MONITORING'] = []
            menu['ACCOUNTS_SUBSCRIPTION'] = []
            menu['STOCK_MANAGEMENT'] = []
            menu['ADMIN'] = []
            menuList.forEach(function (value) {
                if (value && value.model == 'DASHBOARD') {
                    menu['DASHBOARD'].push(value.menu)
                }
                if (value && value.model == 'HOME') {
                    menu['HOME'].push(value.menu)
                }
                if (value && value.model == 'MONITORING') {
                    menu['MONITORING'].push(value.menu)
                }
                if (value && value.model == 'ACCOUNTS_SUBSCRIPTION') {
                    menu['ACCOUNTS_SUBSCRIPTION'].push(value.menu)
                }
                if (value && value.model == 'STOCK_MANAGEMENT') {
                    menu['STOCK_MANAGEMENT'].push(value.menu)
                }
                if (value && value.model == 'ADMIN') {
                    menu['ADMIN'].push(value.menu)
                }

            })
            if (menu['DASHBOARD'].length > 0) {

                loadMenus.forEach(function (val) {

                    if (val && val.name == menu['DASHBOARD'][0].name && menu['DASHBOARD'][0].value == val.permission) {
                    
                        permisionList.push(val)
                        $state.go('app.dashboard')
                    }
                })

            } else
            if (menu['HOME'].length > 0) {

                loadMenus.forEach(function (val) {

                    if (val && val.name == menu['HOME'][0].name && menu['HOME'][0].value == val.permission) {
                        permisionList.push(val)
                        $state.go('app.home')
                    }
                })
            
            } else if (menu['MONITORING'].length > 0) {

                loadMenus.forEach(function (val) {

                    if (val && val.name == menu['MONITORING'][0].name && menu['MONITORING'][0].value == val.permission) {
                        permisionList.push(val)
                        $state.go(val.route)
                    }
                })

            } else if (menu['ACCOUNTS_SUBSCRIPTION'].length > 0) {

                loadMenus.forEach(function (val) {

                    if (val && val.name == menu['ACCOUNTS_SUBSCRIPTION'][0].name && menu['ACCOUNTS_SUBSCRIPTION'][0].value == val.permission) {
                        permisionList.push(val)

                        $state.go(val.route)
                    }
                })

            } else if (menu['STOCK_MANAGEMENT'].length > 0) {

                loadMenus.forEach(function (val) {
                    if (val && val.name == menu['STOCK_MANAGEMENT'][0].name && menu['STOCK_MANAGEMENT'][0].value == val.permission) {
                        permisionList.push(val)
                        $state.go(val.route)
                    }
                })

            } else if (menu['ADMIN'].length > 0) {

                loadMenus.forEach(function (val) {
                    if (val && val.name == menu['ADMIN'][0].name && menu['ADMIN'][0].value == val.permission) {
                        permisionList.push(val)
                        $state.go(val.route)
                    }
                })

            }
            if (permisionList.length == 0) {

                Notification.warning({
                    title: $translate.instant("ACCESS_DENIDED"),
                    message: $translate.instant("ACCESS_DENIDED_MESSAGE")
                });
                i3HelperSrv.logout(function () {
                    $rootScope.appData = {};
                    $rootScope.maplayers = {};
                    $rootScope.selectedPlatform = {};

                    console.debug("logout success");
                });
                $location.path("/login");
                //$state.go('login')
            }

        } else {

            $state.go('app.dashboard')
        }

    }

    return service;
})