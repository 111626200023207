angular.module("app").controller('adminCtrl', function ($scope, $rootScope, $state, I3PlateformeSrv, I3ProductSrv, I3ProductCodeSrv) {
    $scope.$state = $state;
    $scope._ADMIN = {};
    $scope._ADMIN.productTypes = ["MATERIAL", "SERVICE", "OPERATION","UNSUBSCRIBE"]
    I3PlateformeSrv.query({}, 10000, 1,
        function (data) {
            if (data.success) {
                $scope._ADMIN.platforms = data.result;
              
            } else {
                console.error(data.error)
            }
        },
        function (data) {
            console.dir(data)

        })
        I3ProductCodeSrv.query({}, 10000, 1,
            function (data) {
                if (data.success) {
                    $scope._ADMIN.productCodes = data.result;
                  
                } else {
                    console.error(data.error)
                }
            },
            function (data) {
                console.dir(data)
    
            })
    I3ProductSrv.query({}, 10000, 1,
        function (data) {
            if (data.success) {
                $scope._ADMIN.products = data.result;
                $scope._ADMIN.operationProducts=[];
                $scope._ADMIN.serviceProducts=[];

                $scope._ADMIN.products.forEach(function(prod){
                    if(prod.type=="SERVICE"){
                        $scope._ADMIN.serviceProducts.push(prod);
                    }else if(prod.type=="OPERATION"){
                        $scope._ADMIN.operationProducts.push(prod);
                    }
                })
                //console.log($scope._ADMIN.products)
            } else {
                console.error(data.error)
            }
        },
        function (data) {
            console.dir(data)

        })
        I3ProductSrv.query({
            query: {
                type: 'SERVICE'
            },
            options: {}
        } , 10000, 1, function (response) {
            if(response.success){
                $scope._ADMIN._unsubscribe_products = response.result;
           
            }
            else {
                console.error(response.error)
            }
    
    
        }, function (response) {
            // console.log(response)
    
        })
});