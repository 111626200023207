angular.module("app.services").factory('extrasMethodsSrv', function ($q, $translate, $http) {
    var service = {};
    service.updateMultiple = function (query, updata, success, error) {
        $http.post(this.API_URI + '/' + this.path + '/updatemultiple', {
            query: query,
            data: updata
        }).then(function (response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success) {
                success(response.data);
            } else if (!response.data.success && response.data.error) {
                success(response.data)
            } else {
                error(response);
            }
        }, function (data) {
            error(data)
        });
    };
    service.addMultiple = function (query, success, error) {
        $http.post(this.API_URI + '/' + this.path + '/addmultiple', {
            elems: query
        }).then(function (response) {
            if (response && response.data && response.data.success) {
                success(response.data);
            } else if (!response.data.success && response.data.error) {
                success(response.data)
            } else {
                error(response);
            }
        }, function (data) {
            error(data)
        });
    };
    return service;
});