angular.module("app").controller("receptionCtrl", function ($scope, $rootScope, $filter, $state, $translate, ngDialog, Notification, I3ReceiptSlipSrv, I3StockSrv, I3ProductSrv) {
    $scope.$state = $state;
    $scope._STOCK = {};
    $scope.search = {};
    $scope.itemsByPage = 50;
    $scope.receipts = [];
    I3ProductSrv.query({}, 10000, 1, function (res) {
        if (res.success) {
            $scope._STOCK.ALLProducts = res.result.filter(function (val) {
                return val.type == "MATERIAL"
            });

        }
    }, function (err) {
        console.log(err);
    });

    I3StockSrv.query({}, 10000, 1, function (res) {
        if (res.success) {
            $scope._STOCK.ALLStocks = res.result;
            $scope.virtual_stocks = [];
            $scope._STOCK.ALLStocks.forEach(function (val) {
                $scope.virtual_stocks = $scope.virtual_stocks.concat(val.virtual_stock.map(function (stock) {
                    stock._stock = val.name;
                    return stock
                }));
            })

            console.log($scope.virtual_stocks)
        }
    }, function (err) {
        console.log(err);
    });
    $scope.loadReceipts = function (tableState, ctrl) {

        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }
        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;
        var options = {
            populate: [{
                    path: '_product',
                    select: 'name'
                },
                {
                    path: '_stock',
                    select: 'name'
                },
            ],
            sortBy: {
                creation_dt: -1
            }
        };

        var query = {
            options: options
        };
        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
        }

        var requery = {};
        if (tableState.search && tableState.search.predicateObject) {
            if (tableState.search.predicateObject.ref) {
                requery.ref = {
                    $regex: "^" + tableState.search.predicateObject.ref,
                    $options: 'i'
                }
            }
        }
        if ($scope.search) {
            if ($scope.search._stock) {
                requery["_stock"] = $scope.search._stock._id;

            }
            if ($scope.search._virtualStock) {
                requery["virtual_stock"] = $scope.search._virtualStock._id;

            }
            if ($scope.search._product) {
                requery["_product"] = $scope.search._product._id;

            }

        }

        query.query = requery;

        I3ReceiptSlipSrv.query(query, number, start,
            function (data) {

                if (data.success) {
                    $scope.receipts = data.result;
                    $scope.receiptsCount = data.total_count;
                    tableState.pagination.numberOfPages = Math.ceil(data.total_count / number);
                    tableState.pagination.totalItemCount = data.total_count;
                    $scope.tableState = tableState;
                } else {
                    console.error(data.error)
                }
            },
            function (err) {
                console.log("err", err)
            })

    };
    $scope.getVirtualStokById = function (virtualStockId) {
        if (virtualStockId) {
            var filtred = $scope.virtual_stocks.filter(function (val) {
                return val._id == virtualStockId;
            })
            if (filtred && filtred.length > 0) {
                return filtred[0];
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    }

    $scope.moveStock = function (receipts) {
        $scope.moveReceipts = angular.copy(receipts);
        ngDialog.open({
            templateUrl: "app/commons/dialogs/stocks/stocks.move.dlg.html",
            controller: "moveStockDlgCtrl",
            className: "custom-width ngdialog-theme-default",
            scope: $scope
        })

    }
    $scope.moveStockMultiple = function (receipts) {

        $scope.moveReceipts = receipts.filter(function (e) {
            return e.selected == true
        });
        console.log(receipts, $scope.moveReceipts)
        if ($scope.moveReceipts && $scope.moveReceipts.length > 0) {

            $scope.moveStock($scope.moveReceipts);
        } else {
            Notification.warning({
                title: $translate.instant("STOCK_MOVED"),
                message: $translate.instant("STOCK_MOVED_NO_ITEM_FOUND")
            });
        }
    }
    $scope.remove = function (receipt) {
        $scope.receipt = receipt;
        ngDialog.open({
            templateUrl: "app/commons/dialogs/confirm/receipt.delete.dlg.tpl.html",
            controller: "confirmDeleteReceiptDlgCtrl",
            scope: $scope
        })
    };

})