angular.module("app").controller("addDeliveryProductDlgCtrl", function ($scope, $rootScope, $filter, $state, $translate, ngDialog, Notification, I3ArticleSrv, I3StockSrv, I3StockStatusSrv) {

    $scope.deliveryProduct = {};
    $scope.Listserial = [];
    $scope.search = {};
    $scope.itemsByPage = 10;
    $scope.listProduct = []
    $scope._serials = []
    $scope.listSerial = []
    $scope.articles = []
    $scope.isOutOfStock = true;
    $scope.showOutOfSotck = false;

    $scope.checkStockAvailable = function () {
        $scope.isOutOfStock = true;
        $scope.showOutOfSotck = false;
        $scope.availableInStock = 0;
        var countInThisDelivery = 0;
            // console.log($scope.deliveryProduct)
        if ($scope.deliveryProduct && $scope.deliveryProduct._product) {
            if ($scope.deliveryProduct._product.is_trackSerial) {
                // console.log("track by serial no stock status check");
                $scope.isOutOfStock = false;
            } else {
                if ($scope.deliveryProduct._stock && $scope.deliveryProduct.virtual_stock && $scope.deliveryProduct.qty) {
                    if ($scope.deliverySlip.products && $scope.deliverySlip.products.length > 0) {
                        $scope.deliverySlip.products.forEach(function (dproduct) {
                            // console.log(dproduct._stock._id, $scope.deliveryProduct._stock._id);
                            // console.log(dproduct.virtual_stock, $scope.deliveryProduct.virtual_stock);
                            if ((dproduct._stock._id == $scope.deliveryProduct._stock._id) && (dproduct.virtual_stock == $scope.deliveryProduct.virtual_stock || dproduct.virtual_stock == $scope.deliveryProduct.virtual_stock._id) && (dproduct._product._id == $scope.deliveryProduct._product._id)) {
                                countInThisDelivery += dproduct.qty;
                            }

                        })
                    }
                    var query = {
                        "_product": $scope.deliveryProduct._product._id,
                        _stock: $scope.deliveryProduct._stock._id,
                        virtual_stock: $scope.deliveryProduct.virtual_stock._id
                    };
                    I3StockStatusSrv.query({
                        query: query
                    }, 1, 0, function (res) {
                        if (res && res.result && res.result.length > 0) {
                            $scope.availableInStock = res.result[0].qty - countInThisDelivery;
                            $scope.isOutOfStock = $scope.availableInStock < $scope.deliveryProduct.qty;
                            if ($scope.isOutOfStock) {
                                $scope.showOutOfSotck = true;
                            } else {
                                $scope.showOutOfSotck = false;
                            }
                        } else {
                            $scope.showOutOfSotck = true;
                            console.error("No stock found  ")
                        }

                        console.error("errrrrrrrrrrrrrrrrror", res)
                    }, function (err) {

                        console.error("errrrrrrrrrrrrrrrrror", err)
                    })

                }
            }
        }
    }
    $scope.choixProduct = function (produit) {
        // console.log($scope.deliveryProduct)
        if (produit && produit.is_trackSerial)
            $scope.deliveryProduct.qty = 1;
        I3ArticleSrv.query({
            query: {
                _product: $scope.deliveryProduct._product,
                confirmed: true
            }
        }, 10000, 1, function (res) {
            if (res.success) {
                $scope.articles = res.result;
                $scope.listarticles = $scope.articles
                if ($scope.deliverySlip.products && $scope.deliverySlip.products.length > 0) {
                    $scope.deliverySlip.products.forEach(function (elem) {
                        // console.log("elem", elem._article)
                        if (elem) {

                            $scope.listarticles.forEach(function (key) {
                              //  console.log(key)
                                if (elem._article && elem._article._id == key._id) {

                                    key.selected = true
                                }
                            })
                        }
                    })
                    // console.log("dddddddddddddddddd", $scope.listarticles)
                }
            }
        }, function (err) {
            console.log(err);
        });

    }

    $scope.addDeliveryProduct = function () {
        if ($scope.deliverySlip.products) {
            if (!$scope.deliveryProduct._product) {
                $scope.closeThisDialog()
            } else {
                if ($scope.deliveryProduct._product.is_trackSerial == false) {
                    // console.log($scope.deliveryProduct)
                    $scope.deliverySlip.products.push($scope.deliveryProduct)
                    $scope.closeThisDialog($scope.deliverySlip)
                } else if ($scope.articles.length > 0) {

                    $scope.Listserial.forEach(function (val) {
                        $scope.deliverySlip.products.push(val)
                    })
                    $scope.closeThisDialog($scope.deliverySlip)
                } else {
                    /*  Notification.error({
                          title: $translate.instant("STOCK_UNDEFINED"),
                          message: $translate.instant("STOCK_UNDEFINED")
                      });*/

                    $scope.closeThisDialog()
                }

            }
        } else {

            $scope.deliverySlip.products = []

            if (!$scope.deliveryProduct._product) {
                $scope.closeThisDialog()
            } else {
                if ($scope.deliveryProduct._product.is_trackSerial == false) {
                    $scope.deliverySlip.products.push($scope.deliveryProduct)
                    $scope.closeThisDialog($scope.deliverySlip)
                } else if ($scope.articles.length > 0) {

                    $scope.Listserial.forEach(function (val) {
                        $scope.deliverySlip.products.push(val)
                    })
                    $scope.closeThisDialog($scope.deliverySlip)
                } else {

                    // $scope.closeThisDialog()
                }

            }

        }

        // console.log($scope.deliverySlip)

    }

    I3StockSrv.query({}, 10000, 1, function (res) {
        if (res.success) {
            $scope.ListStocks = res.result;
            $scope.virtual_stocks = [];
            $scope.ListStocks.forEach(function (val) {
                $scope.virtual_stocks = $scope.virtual_stocks.concat(val.virtual_stock.map(function (stock) {
                    stock._stock = val.name;
                    return stock
                }));
            })
            var agg_query=[{$group:{
                _id:{_stock:"$_stock",virtual_stock:"$virtual_stock"},
                "_products":{$push:{"_product":"$_product",qty:"$qty"}}

            }}]
            I3StockStatusSrv.agg({aggregate:agg_query},10000,0,function(res){
                    if(res && res.success && res.result.length>0){
                    $scope._STOCK.ALLStocks.forEach(function(stock){
                        stock.virtual_stock.forEach(function(vstock){
                            res.result.forEach(function(stockStatus){
                                if(stockStatus._id && stockStatus._id.virtual_stock && vstock._id ==stockStatus._id.virtual_stock){
                                    if(!vstock._available_products) vstock._available_products={}
                                    stockStatus._products.forEach(function(prod){
                                        vstock._available_products[prod._product]=prod.qty
                                    })
                                    
                                }
                            })
                           
                        })
                    })
                        // console.log($scope.ListStocks)
                    }

            },function(err){
                console.log("enable to get stock status");
            })

        }
    }, function (err) {
        console.log(err);
    });

    $scope.loadArticles = function (tableState, ctrl) {

        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }
        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;
        var options = {
            populate: [{
                    path: '_product',
                    select: 'name'
                },
                {
                    path: '_stock',
                    select: 'name'
                }, {
                    path: '_article',
                    select: 'serialNumber'
                }
            ],
            sortBy: {
                creation_dt: -1
            }
        };

        var query = {
            options: options
        };
        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
        }

        var requery = {};
        if (tableState.search && tableState.search.predicateObject) {
            if (tableState.search.predicateObject.serialNumber) {
                requery.serialNumber = {
                    $regex: "^" + tableState.search.predicateObject.serialNumber,
                    $options: 'i'
                }
            }
            if (tableState.search.predicateObject.imei) {
                requery.imei = {
                    $regex: "^" + tableState.search.predicateObject.imei,
                    $options: 'i'
                }
            }
        }
        if ($scope.search) {
            if ($scope.search._stock) {
                requery["_stock"] = $scope.search._stock._id;

            }
            if ($scope.search._virtualStock) {
                requery["virtual_stock"] = $scope.search._virtualStock._id;

            }
            if ($scope.search._product) {
                requery["_product"] = $scope.search._product._product._id;

            }

        }
        requery.confirmed=true;
        query.query = requery;
        query.query._product = $scope.deliveryProduct._product._id;

        I3ArticleSrv.query(query, number, start,
            function (data) {

                if (data.success) {
                    $scope.listarticles = data.result;
                    if ($scope.deliverySlip.products && $scope.deliverySlip.products.length > 0) {
                        $scope.deliverySlip.products.forEach(function (elem) {

                            if (elem) {

                                $scope.listarticles.forEach(function (key) {
                                    console.log(key)
                                    if (elem._article && elem._article._id == key._id) {

                                        key.selected = true
                                    }
                                })
                            }
                        })
                        // console.log("dddddddddddddddddd", $scope.listarticles)
                    }
                    $scope.articlesCount = data.total_count;
                    tableState.pagination.numberOfPages = Math.ceil(data.total_count / number);
                    tableState.pagination.totalItemCount = data.total_count;
                    $scope.tableState = tableState;
                } else {
                    console.error(data.error)
                }
            },
            function (err) {
                console.log("err", err)
            })

    };

    $scope.getVirtualStokById = function (virtualStockId) {
        if (virtualStockId) {
            var filtred = $scope.virtual_stocks.filter(function (val) {
                return val._id == virtualStockId;
            })
            if (filtred && filtred.length > 0) {
                return filtred[0];
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    }

    $scope.setStock = function (article) {
        // console.log(article)
        if (article.selected == true) {

            var product = {
                _product: article._product,
                qty: article.qty,
                _article: {
                    _id: article._id,
                    serialNumber: article.serialNumber
                },
                virtual_stock: article.virtual_stock,
                _stock: article._stock

            }

            $scope.Listserial.push(product)

        } else {

            $scope.Listserial.splice(product, 1)

        }
    }
})