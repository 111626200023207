angular.module('app').controller('adminUserEditCtrl', function ($scope, $rootScope, accessSrv, $state, $stateParams, i3HelperSrv, $translate, I3UserSrv, I3RoleSrv, ngDialog, Notification, constantSrv) {

    $scope.user = {};
    $scope.passwordEdit = '';
    $scope.accessModels = accessSrv.getAllModule()
    $scope.modelAccess = 'DASHBOARD';
    $scope.newPassword = null;
    $scope.visiblePwd = false
    $scope.menus = []


    I3UserSrv.query({
        query: {
            _id: $stateParams.userId
        },
        options: {
            populate: [{
                path: "_plateforme _role"
            }]
        }
    }, 1, 0, function (res) {
        if (res && res.result) {
            $scope.user = res.result[0];
            $scope.userProfile = angular.copy($scope.user)
            $scope.user._role = res.result[0]._role._id;

            // console.log("dffghfgh", $scope.user)
            $scope.password = $scope.user.password;
            if ($scope.user.menus) {
                $scope.modelMenus = accessSrv.getALLMenuAccess($scope.user.menus);
            } else {
                $scope.user.menus = [];
                $scope.modelMenus = accessSrv.getALLMenuAccess($scope.user.menus);
            }



            $scope.getRolePermissions();
        }
        if ($rootScope.checkAccess("PLATEFORME", "R", "O"))
            $scope.user._plateforme = $scope._ADMIN.platforms[0];
    }, function (err) {
        console.log(err);
    })
    /*
        I3UserSrv.getById($stateParams.userId, function (response) {
            console.log("user",response)
            $scope.user = response.result;
            $scope.password = $scope.user.password;

            $scope.modelMenus=accessSrv.getALLMenuAccess( $scope.user.menus);

            $scope.getRolePermissions();
        }, function (err) {
            console.log(err);
        });*/

    $scope.getRolePermissions = function () {


        I3RoleSrv.getById($scope.user._role, function (data) {
            console.log('data', data)
            if (data.success) {

                $scope.roleSelected = data.result;
                $scope.userProfile._role.profil = $scope.roleSelected._id;
            } else {
                console.log(data.error)
            }
        })







    }
    /**
     * Save user
     */
    $scope.save = function () {
        if ($scope.newPassword) {
            $scope.user.password = $scope.newPassword;
        }
        $scope.user.menus = accessSrv.getGroupMenubyModel($scope.modelMenus);

        /* if(!$scope.passwordEdit){
             $scope.user.password = $scope.password
         }else{
             $scope.user.password = $scope.passwordEdit
         }*/
        I3UserSrv.updateById($scope.user._id, $scope.user, function (response) {
            if (response.success) {
                Notification.success({
                    title: $translate.instant("USER_UPDATED_TITLE"),
                    message: $translate.instant("USER_UPDATED_SUC")
                });
                $state.go('app.admin.users');
                $scope.user = {};
            } else {

                if (response.error && response.error.errorCode == "error_model_validation_type" && response.error.details && response.error.details.errors && response.error.details.errors.login && response.error.details.errors.login.kind == "unique") {

                    Notification.error({
                        title: $translate.instant('ERROR'),
                        message: $translate.instant("DUPLICATED_LOGIN_USER", { login: $scope.user.login })
                    });
                } else {
                    Notification.error({
                        title: $translate.instant('ERROR'),
                        message: $translate.instant("ERROR_OCCURED_UPDATE") + " " + $scope.user.login + "!"
                    });
                }



            }
        }, function (err) {
            var msg = "";
            if (err.error.errorString) {
                msg = err.error.errorString;
            }
            Notification.error({
                title: $translate.instant('ERROR'),
                message: msg
            });
        })
    };

    /**
     * Removing user
     * @param user
     */
    $scope.remove = function (user) {
        $scope.user = user;

        ngDialog.open({
            templateUrl: "app/commons/dialogs/confirm/user.delete.dlg.tpl.html",
            controller: "confirmDeleteUserDlgCtrl",
            scope: $scope
        })
    };

    /**
     * Cancel editing mode
     */
    $scope.cancel = function () {
        $scope.user = {};
        $state.go('app.admin.users');
    }

    $scope.show_access = function () {
        $scope.perm = !$scope.perm;
    }
    $scope.getModel = function (model) {
        $scope.modelAccess = model;

    }

    $scope.getMenubyModel = function (model, menu) {

        console.log("$scope.modelMenus", $scope.modelMenus)

        //accessSrv.getGroupMenubyModel( $scope.modelMenus)
    }

    $scope.generate = function () {
        $scope.newPassword = i3HelperSrv.generatePassword();
    }
    $scope.showPassword = function () {
        var x = document.getElementById("password");
        if (x.type === "password") {
            $scope.visiblePwd = true;
            x.type = "text";
        } else {
            $scope.visiblePwd = false;
            x.type = "password";
        }
    };
    $scope.getRole = function (role) {


        $scope.userProfile._role.profil = role;

        $scope.profiles = constantSrv.getProfiles()
        $scope.ListMenu = $scope.profiles[role];

        $scope.modelMenus = accessSrv.getALLMenuAccess($scope.ListMenu);
    }

    $scope.resetAccess = function () {
        if ($scope.roleSelected) {
            $scope.profiles = constantSrv.getProfiles()
            $scope.ListMenu = $scope.profiles[$scope.roleSelected._id];

            $scope.modelMenus = accessSrv.getALLMenuAccess($scope.ListMenu);
        }


    }
});