angular.module("app").controller("deliveryRequestInstallationDlgCtrl", function ($scope, $rootScope, $filter, $state, $translate, ngDialog, Notification, constantSrv) {
    $scope.product_consomable = {}
    $scope.assetEdite = angular.copy($scope.asset)
    $scope.comment = null;
    $scope.listProductC = [];
    $scope.oderProduct = [];
    $scope.listProductcopy = [];
    $scope.checkKey = function (keyEvent) {

        keyEvent.preventDefault();

    }
    constantSrv.convertProduct($scope.assetEdite.req_options, function (err, listProd, invoicableProduct, consommableProduct) {
        if (err) {
            console.error(err, listProd)
        } else {
            $scope.invoicableProduct = invoicableProduct;
            $scope.consommableProduct = consommableProduct;
            try {
                $scope.oderProduct = listProd.map(function (prod) {
                    prod.isInvoicable = true;
                    return prod;
                })
                $scope.listProductcopy = angular.copy($scope.oderProduct)
            } catch (e) {
                console.error(e);
            }

        }
    })

    $scope.addreturnSlipComment = (function () {

        $scope.oderProduct.forEach(function (element) {
            if (element.qty != 0) {
                $scope.listProductC.push(element)
            }

        });
        if ($scope.listProductC.length > 0) {
            var delivery = {
                comment: $scope.comment,
                res_options: $scope.oderProduct
                //  productC : $scope.listProductC
            }
            $scope.closeThisDialog(delivery)

        } else {
            Notification.error($translate.instant("SELECT_PRODUCT_ERROR"));
        }
    })
    $scope.getProductConsomable = function (productC) {
        productC.qty = 0
        var prod = {
            _product: productC,
            qty: 0
        }

        $scope.oderProduct.push(prod)
        //$scope.listProductC.push(productC)
        $scope.listProductcopy = angular.copy($scope.oderProduct)
        $scope.product_consomable = {}
    }

    $scope.remove = function (prodC, index) {

        $scope.oderProduct.splice(index, 1)
    }
})