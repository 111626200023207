angular.module("app").controller("articleAddCtrl", function ($scope, $rootScope, $filter, $state, $translate, ngDialog, Notification,I3ArticleSrv,I3StockSrv,I3ProductSrv) {

   
    
    $scope.article = {};


    I3ProductSrv.query({}, 10000, 1, function (res) {
        if (res.success) {
            $scope.ALLProductSerial =  res.result.filter(function (elem) {
                return elem.is_trackSerial == true
            });
     
         
        }
    }, function (err) {
        console.log(err);
    });
    $scope.save = function () {
        var filterImei= $scope.articles.filter(function(val){
            return val.imei==$scope.article.imei
        })

        var filterSerial= $scope.articles.filter(function(val){
            return val.serialNumber==$scope.article.serialNumber
        })
            if(filterImei && filterImei.length>0){
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("IMEI_ALREADY_EXIST")
                });
            }else if(filterSerial && filterSerial.length>0){
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("SERIAL_NUMBER_ALREADY_EXIST")
                });
            }else{
                $scope.article._plateforme = $scope.article._stock._plateforme

                I3ArticleSrv.add($scope.article, function (response) {
            if (response.success) {
                Notification.success({
                    title: $translate.instant("ARTICLE_CREATED"),
                    message: $translate.instant("ARTICLE_ADDED_SUC")
                });
              $state.go('app.stocks.articles');
               $scope.article = {};
            } else {
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_CREATE") + "!"
                });
            }
        }, function (err) {
            console.log(err);
            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("ERROR_OCCURED_CREATE") + "!"
            });
            $scope.article = {};
        })   }
    };


    /**
     * Cancel creating/editing stockManagement
     */
    $scope.cancel = function () {
        $scope.article = {};
        $state.go('app.stocks.articles');
    };

    $scope.updateVirtualStock=function(stok,virtualStock){


        stock =$scope.article._stock
        virtualStock =$scope.article.virtual_stock
        if(stok && stok.virtual_stock &&  stok.virtual_stock.length>0){
            var filtred= stok.virtual_stock.filter(function(val){
                return val._id == virtualStock;
            })
            if(!filtred  || filtred.length<=0){
                $scope.article.virtual_stock=null;
            }
        }else if(stok){
            $scope.article.virtual_stock=null;
        }



    }
    
    })