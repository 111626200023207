angular.module('app').controller("addClientDlgCtrl", function ($scope, Notification, iaxeedCompanySrv, iaxeedCountrySrv, countrySrv, installationRequest, NGIFleetHelperSrv, plateformHelperSrv, I3PlateformeSrv) {

    /*    $scope.searchCountry=function($select) {
            iaxeedCountrySrv.query({query: {name: {'$regex': $select.search, '$options': 'i'}}}, 100, 0, function (res) {
                $scope.countries = res.result;
            }, function (err) {
            })
        };*/
    $scope.showPlatforme = false;
    $scope.client = {};
    $scope.client.contact = {};
    $scope.countries = countrySrv.getCountryList();

    if (installationRequest) {
        $scope.showPlatforme = true;
        if (installationRequest.client_name) {
            $scope.client.client_name = installationRequest.client_name;
        }
        if (installationRequest.contact && installationRequest.contact.fax) {
            $scope.client.contact.fax = installationRequest.contact.fax;
        }
        if (installationRequest.contact && installationRequest.contact.email) {
            $scope.client.contact.email = installationRequest.contact.email;
        }
        if (installationRequest.contact && installationRequest.contact.phone) {
            $scope.client.contact.phone = installationRequest.contact.phone;
        }

        I3PlateformeSrv.query({}, 10000, 1, function (response) {
            $scope.platforms = response.result;

        }, function (err) {
            console.log(err);
        });
        /**
         * select and authenticate plateforme
         * @param platform
         */
        $scope.selectPlateform = function (platform) {

            $scope.assets = [];
            if (platform.type == "NGI_FLEET") {
                NGIFleetHelperSrv.setPlateFormAndLogin(platform, function (res) {

                    if (res.result) {

                    } else {

                        Notification.error({
                            title: $translate.instant("ERROR"),
                            message: $translate.instant("PLATFORM_CNX_ERROR")
                        });
                    }
                    //console.log("NGIFleetHelperSrv", res);
                }, function (err) {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("PLATFORM_CNX_ERR")
                    });
                })
            } else {
                plateformHelperSrv.setPlateFormAndLogin(platform, function (res) {

                    if (res && res.success) {

                    } else {

                        Notification.error({
                            title: $translate.instant("ERROR"),
                            message: $translate.instant("PLATFORM_CNX_ERROR")
                        });
                    }

                }, function (err) {
                    console.log(err);
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("PLATFORM_CNX_ERR")
                    });
                })
            }

        };
    }

    $scope.add = function (client) {
        client._ctry = client._ctry._id;
        iaxeedCompanySrv.add(client, function (res) {
            if (res && res.success) {
                Notification.success({
                    title: "Client created",
                    message: "Client " + $scope.client.name + " was created successfully!"
                });
                $scope.closeThisDialog(res.result)
            }
        }, function (err) {})
    }

});