angular.module('app.dialogs').controller('deletePendingPvSynchroneDlgCtrl', function ($scope, $rootScope, ngDialog,index, Notification, $translate,I3PendingPvSyncSrv) {

    $scope.confirm = function () {
        I3PendingPvSyncSrv.delete( $scope.PendingPvs[index]._id, function (response) {
            if(response.success){
  //   console.log("response",response)
                Notification.success({
                    title: $translate.instant("PENDING_PV_SYNC_DELETED_TITLE"),
                    message: $translate.instant("PENDING_PV_SYNC_DELETED_SUC")
                });
                $scope.LoadPendingPvs()
            }else{
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("PENDING_PV_SYNC_DELETED_ERROR")
                });
       
            }
        }, function (err) {
            console.log(err);

            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("PENDING_PV_SYNC_DELETED_ERROR")
            });


        });

    
        $scope.closeThisDialog();
    }

});