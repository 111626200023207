angular.module("app").controller("goodsReturnSlipEditCtrl", function ($scope, $stateParams, $rootScope, $filter, $state, $translate, ngDialog, Notification, I3deliverySlipSrv, I3GoodsReturnSlipSrv, I3StockSrv, I3PvSrv, reportingSrv) {
    $scope.returnSlip = {};
    var now = moment().toDate();
    if($translate.use()=="fr"){
        $scope.template2 = 'deliveryret.mrt';
    }else{
        $scope.template2 = 'deliveryret-en.mrt';
    }
  
    I3GoodsReturnSlipSrv.query({
        query: {
            _id: $stateParams.goodsReturnSlipId
        },
        options: {
            populate: [{
                path: "_plateforme",
                select: 'name'
            }, {
                path: "products_return._product",
                select: 'name'
            }, {
                path: "products_return._stock",
                select: 'name'
            }, {
                path: "products_installed._product",
                select: 'name'
            }, {
                path: "products_installed._stock",
                select: 'name'
            }, {
                path: "products_return._article",
                select: 'serialNumber imei'
            }, {
                path: "products_installed._article",
                select: 'serialNumber imei'
            }]
        }
    }, 1, 0, function (res) {
        if (res && res.success && res.result.length > 0) {
            $scope.returnSlip = res.result[0];
            console.log("$scope.returnSlip", $scope.returnSlip)
            if ($scope.returnSlip.pv.pv_ref) {
                I3PvSrv.query({
                    query: {
                        pv_ref: $scope.returnSlip.pv.pv_ref
                    },
                    options: {
                        populate: [{
                                path: "products._product"
                            },
                            {
                                path: "products.zones._zone",
                                select: 'name'
                            }, {
                                path: "_client",
                                select: 'name'
                            },
                            {
                                path: "_plateforme",
                                select: 'name'
                            },
                            {
                                path: "_documents._document",
                                select: 'original_name'
                            }
                        ],
                        sortBy: {
                            creation_dt: -1
                        }
                    }
                }, 10000, 1, function (res) {
                    if (res.success) {
                        if (res.result.length > 0) {
                            $scope.pv = res.result[0];
                        }
                    }
                }, function (err) {
                    console.log(err);
                });
            }
            if ($scope.returnSlip.delivery_slip) {
                I3deliverySlipSrv.query({
                    query: {
                        _id: $scope.returnSlip.delivery_slip._id
                    },
                    options: {
                        populate: [{
                                path: "_plateforme",
                                select: 'name'
                            },
                            {
                                path: "_stock",
                                select: 'name'
                            }
                        ],
                        sortBy: {
                            creation_dt: -1
                        }
                    }
                }, 10000, 1, function (res) {
                    if (res.success) {
                        if (res.result.length > 0) {
                            $scope.deliverySlip = res.result[0];
                            console.log("$scope.deliverySlip", $scope.deliverySlip)
                        }
                    }
                }, function (err) {
                    console.log(err);
                });
            }
        } else
            Notification.error($translate.instant("FIND_BY_ID_ERROR"));
    }, function (err) {
        Notification.error($translate.instant("FIND_BY_ID_ERROR"))
    })
    I3StockSrv.query({}, 10000, 1, function (res) {
        if (res.success) {
            $scope.ListStocks = res.result;
            $scope.virtual_stocks = [];
            $scope.ListStocks.forEach(function (val) {
                $scope.virtual_stocks = $scope.virtual_stocks.concat(val.virtual_stock.map(function (stock) {
                    stock._stock = val.name;
                    return stock
                }));
            })
        }
    }, function (err) {
        console.log(err);
    });
    $scope.getVirtualStokById = function (virtualStockId) {
        if (virtualStockId) {
            var filtred = $scope.virtual_stocks.filter(function (val) {
                return val._id == virtualStockId;
            })
            if (filtred && filtred.length > 0) {
                return filtred[0];
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    }
    $scope.setStock = function (product, index) {
        ngDialog.open({
            templateUrl: "app/commons/dialogs/goodsReturnSlip/returnSlipStock.edit.dlg.tpl.html",
            className: 'ngdialog-theme-default custom-width-800',
            appendClassName: 'ngdialog-custom',
            controller: "returnSlipStockEditCtrl",
            scope: $scope,
            resolve: {
                index: function () {
                    return index;
                }
            }
        })
    }
    $scope.exportGRS = function (fileType) {

        var info = {
            nbc: ($scope.returnSlip && $scope.returnSlip.pv && $scope.returnSlip.pv.pv_ref ? $scope.returnSlip.pv.pv_ref : ""),
            npv: ($scope.returnSlip && $scope.returnSlip.reference ? $scope.returnSlip.reference : ""),

            client: ($scope.deliverySlip && $scope.deliverySlip.client ? $scope.deliverySlip.client : ""),
            contact: ($scope.returnSlip && $scope.returnSlip.delivred_by ? $scope.returnSlip.delivred_by : ""),
            /*  tel: ($scope.returnSlip && $scope.returnSlip.installation_id && $scope.returnSlip.installation_id.contact && $scope.returnSlip.installation_id.contact.phone ? $scope.returnSlip.installation_id.contact.phone : ""),  */

            date: now,
            /* asset_type: ($scope.asset && $scope.asset.type ? $scope.asset.type : ""),
            asset_model: ($scope.asset && $scope.asset.model ? $scope.asset.model : ""),
            asset_brand: ($scope.asset && $scope.asset.brand ? $scope.asset.brand : ""),
            asset_name: ($scope.asset && $scope.asset.name ? $scope.asset.name : ""), */
            data: true,
            data1: false,
            data2: false

        }

        var data = [];
        var data1 = [];
        /* var reverseEnumsProductCode = {};
        Object.keys($scope.codeProducts).forEach(function (key) {
            data[key] = 0;
            reverseEnumsProductCode[$scope.codeProducts[key]] = key
        }) */

        $scope.returnSlip.products_return.forEach(function (product) {
            if (product._product && product._product.code && product._product.code.product_type && product.qty) {

                data.push({
                    "name": product._product && product._product.name ?  $translate.instant(product._product.name) : '',
                    "qte": product.qty,
                    "IME": product && product._article && product._article.imei ? product._article.imei : '',
                    "SN": product && product._article && product._article.serialNumber ? product._article.serialNumber : '',
                });

            }
        })

        $scope.returnSlip.products_installed.forEach(function (product) {
            if (product._product && product._product.code && product._product.code.product_type && product.qty) {

                data1.push({
                    "name": product._product && product._product.name ? $translate.instant(product._product.name) : '',
                    "qte": product.qty,
                    "IME": product && product._article && product._article.imei ? product._article.imei : '',
                    "SN": product && product._article && product._article.serialNumber ? product._article.serialNumber : '',
                });

            }
        })
        if (data.length > 0) {
            info.data1 = true
        }
        if (data1.length > 0) {
            info.data2 = true
        }

        var ds = {
            name: "pvSingleInstalll",
            data: {
                info: info,
                data: data,
                data1: data1,
                fileName:"PV_RETURN "+($scope.returnSlip.reference || '')+" "+moment().format("YYY-MM-dd")
                
            }
        }
        console.log(ds)
        if (fileType == "XLSX") {
            reportingSrv.generateExcel($scope.template2, ds, function () {}, function () {
                Notification.error("L'export a échoué ");
            })
        } else if (fileType == "DOCX") {
            reportingSrv.generateWord($scope.template2, ds, function () {}, function () {
                Notification.error("L'export a échoué ");
            })
        } else if (fileType == "preview") {
            //console.log("preview")
            reportingSrv.preview($scope.template2, ds, function () {}, function () {
                Notification.error("La prévisualisation  a échoué ");
            })
        } else {
            reportingSrv.generatePdf($scope.template2, ds, function () {}, function () {
                Notification.error("L'export a échoué ");
            })
        }
    }
    $scope.save = function () {
        I3GoodsReturnSlipSrv.updateById($scope.returnSlip._id, $scope.returnSlip, function (response) {
            if (response.success) {
                console.log(response)
                Notification.success({
                    title: $translate.instant("GOODS_RETURN_SLIP_UPDATED"),
                    message: $translate.instant("GOODS_RETURN_SLIP_UPDATED_SUCC")
                });
                $state.go('app.stocks.goodsReturnSlip');
                $scope.returnSlip = {};
            } else {
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_UPDATE") + "!"
                });
                $scope.returnSlip = {};
            }
        }, function (err) {
            console.log(err);
            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("ERROR_OCCURED_UDPDATE") + "!"
            });
            $scope.returnSlip = {};
        })
    };
    $scope.getProduct = function (product) {
        console.log(product)
        console.log($scope.returnSlip)
    }
})