angular.module('app').filter('articlebyproduct', function () {
    return function (Listarticle,Listproduct) {
  

        var filteredarticles = Listarticle;
        var filter=[]
       for (var index = 0; index < Listproduct.length; index++) {
           const element = Listproduct[index];
        for (var index = 0; index < filteredarticles.length; index++) {
            const val = filteredarticles[index];

            if(val._product._id==element._product._id) {
                filter.push(val)  
        }
  
    }    
    };
    return filter;
}
})