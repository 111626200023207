angular.module('app').controller('homeClaimsCtrl', function ($scope, $rootScope, $stateParams, $translate, I3ClaimSrv, I3RoleSrv, ngDialog, iaxeedAssetSrv, Notification, constantSrv) {
    $scope.claims = [];
    $scope.claim = {};
    $scope.statusLoaded = false;
    var i = 0;
    $scope.disab_input_status = ["Done", "Rejected"];
    $scope.levels = constantSrv.getClaimsLevels();
    constantSrv.getModel("CLAIM").getAllStatus(function (err, status) {
        $scope.claimStatus = status.map(function (val) {
            return val.name
        })
        $scope.claimStatus.push('NotClosed')
        $scope.statusLoaded = true;
    })

    $scope.itemsByPage = 50
    $scope.search = {};
    if ($stateParams.filter)
        $scope.search.claimStatus = $stateParams.filter;
    /*  $(function () {
         console.log("dddddddddddddd")
         $('[data-toggle="popover"]').popover({
             container: 'body'
         })
     }) */

    $scope.loadClaims = function (tableState, ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }

        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;

        var options = {
            populate: [{
                path: '_plateforme',
                select: 'name'
            }, {
                path: '_created_by'
            }],
            sortBy: {
                creation_dt: -1
            }
        };

        var query = {
            options: options
        };

        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
        }
        var requery = {};

        if (tableState.search && tableState.search.predicateObject) {
            if (tableState.search.predicateObject.object) {
                requery["object"] = {
                    "$regex": tableState.search.predicateObject.object,
                    "$options": "i"
                };
            }
            if (tableState.search.predicateObject.asset_name) {
                requery["asset.asset_name"] = {
                    '$regex': tableState.search.predicateObject.asset_name,
                    '$options': 'i'
                }
            }
            if (tableState.search.predicateObject.client_name) {
                requery["client_name"] = {
                    '$regex': tableState.search.predicateObject.client_name,
                    '$options': 'i'
                }
            }
            if (tableState.search.predicateObject.level) {
                requery["level"] = {
                    '$regex': tableState.search.predicateObject.level,
                    '$options': 'i'
                }
            }

        }
        if ($scope.search) {
            if ($scope.search._plateforme) {
                requery["_plateforme"] = $scope.search._plateforme._id;
            }
            if ($scope.search.claimStatus) {
                if ($scope.search.claimStatus === "NotClosed") {
                    requery["status"] = {
                        $nin: constantSrv.getPredefinedStatus()
                    };
                } else {
                    requery["status"] = $scope.search.claimStatus;
                }
            }
            if ($scope.search.level) {
                requery["level"] = $scope.search.level;
            }
        }

        if (tableState.sort && Object.keys(tableState.sort).length > 0) {
            if (tableState.sort.predicate == "creation_dt") {
                options.sortBy.creation_dt = tableState.sort.reverse ? -1 : 1;

            }
        }
        query.query = requery;

        I3ClaimSrv.query(query, number, start, function (response) {
            if (response.success) {
                $scope.claims = response.result;
                $scope.claimCount = response.total_count;
                tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
                tableState.pagination.totalItemCount = response.total_count;
                $scope.tableState = tableState;
                if (($stateParams.platform) && (i == 0)) {
                    var elementPos = $scope._HOME.platforms.map(function (plat) {
                        return plat._id;
                    }).indexOf($stateParams.platform);

                    $scope.search._plateforme = $scope._HOME.platforms[elementPos];
                    i = 1;
                }

            }
        });
    };

    /**
     * Removing claim
     * @param claim
     */
    $scope.remove = function (claim) {
        $scope.claim = claim;

        ngDialog.open({
            templateUrl: "app/commons/dialogs/confirm/claim.delete.dlg.tpl.html",
            controller: "confirmDeleteClaimDlgCtrl",
            scope: $scope
        })

    };

    $scope.openClaimStatusDialog = function (reclamation) {
        constantSrv.getModel("CLAIM").getAvailableStatusByStatus(reclamation.status, function (err, val) {
            $scope.states2 = val._availStatus.map(function (elem) {
                return elem.name
            })
            $scope.states2.unshift(reclamation.status)

        })
        var dialog = ngDialog.open({
            template: "app/commons/dialogs/claims/status.dlg.tpl.html",
            className: 'ngdialog-theme-default custom-width-500',
            appendClassName: 'ngdialog-custom',
            controller: "ClaimStatusDlgCtrl",
            scope: $scope,
            data: {
                prop: reclamation,
                status: reclamation.status
            }
        });
        dialog.closePromise.then(function (datax) {
            console.log(datax)
            if (datax.value != "$closeButton" && datax.value != "$document" && datax.value != false && datax.value.level && datax.value.status) {

                if ($scope.states2.indexOf(datax.value.status) != -1) {
                    reclamation.status = datax.value.status;
                    reclamation.level = datax.value.level;
                    if (datax.value.comment && datax.value.comment != "") {
                        var commentAdded = {};
                        commentAdded.date = new Date();
                        commentAdded.owner = $rootScope.SESSION.user._id;
                        commentAdded.msg = datax.value.comment;
                        reclamation.comments.push(commentAdded)
                    }

                    $scope.updateClaimStatus(reclamation, reclamation.status, reclamation.level, reclamation.comments);
                    console.log(reclamation)

                }

            }
        });

    }

    $scope.updateClaimStatus = function (claim, $data, level, comment) {

        I3ClaimSrv.updateById(claim._id, {
            status: $data,
            level: level,
            comments: comment

        }, function (res) {
            console.log("res", res)
            if (res.success) {
                $scope.loadClaims();
                Notification.success($translate.instant("CLAIM_UPDATED"));
            }
        }, function (err) {})
    }

    $scope.removeStatus = function (predefined) {
        var index = $scope.predefinedClaimStatus.indexOf(predefined);
        if (index > -1) {
            $scope.predefinedClaimStatus.splice(index, 1)
            $scope.loadClaims();
        }
    }
    if (!$scope.search.claimStatus && $scope.search.claimStatus !== null) {
        $scope.search.claimStatus = "NotClosed"
    }

});