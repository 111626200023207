angular.module('app.dialogs').controller('clientInvoiceDlgCtrl', function ($scope) {

    $scope.subClient = null;

  
 



    $scope.getsubClient = function (subClient) {
        $scope.subClient =subClient


    }

    $scope.addSubClient = function () {
       

        $scope.closeThisDialog($scope.subClient);
    }
    $scope.cancel = function () {
        $scope.closeThisDialog(false);
    }
})
