angular.module('app').controller('pvAddCtrl', function ($scope, $rootScope, $state, $translate, $filter, Notification, ngDialog, I3InstallationGroupSrv, I3InterventionSrv, I3PvSrv,constantSrv) {
    $scope.pv = null;
    $scope.installation = null;
    $scope.assets = null;
    $scope.isUniquePVRef = false;
    $scope.ListPvNumber = [];
    $scope.insta_asset = '';
    $scope.chargedClient ="CLIENT";
    var today = moment().startOf("day").clone();
    var now = moment();
    $scope.ResList=constantSrv.getResponsabilite();
    $scope.addPv = function () {

        if ($scope.pv) {
            $scope.pv.related_Model = {
                related_model_id: '',
                related_asset_id: ''
            }
            $scope.pv_sync = angular.copy($scope.pv);

            if ($scope.pv_sync._asset) {
                $scope.pv_sync._asset = $scope.pv_sync._asset.name;

                if ($scope.pv_sync.type == "INSTALLATION")

                    $scope.get_install();
                if ($scope.pv_sync.type == "INTERVENTION")

                    $scope.get_intervention();
                  

            } else {}
        }
    };
    $scope.get_install = function () {
        console.log('ssssss');
        I3InstallationGroupSrv.query({
            query: {
                _id: $scope.pv._installation
            },
            options: {
                populate: [{
                    path: "_plateforme"
                }]
            }
        }, 1, 0, function (res) {
            if (res && res.success && res.result.length > 0) {
                $scope.installation = res.result[0];

                $scope.verify();

            } else
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("FIND_BY_ID_ERROR")
                });
        }, function (err) {
            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("FIND_BY_ID_ERROR")
            });
        })
    };
    $scope.get_intervention = function () {
        console.log('vvvvvvvvvv');
        I3InterventionSrv.query({
            query: {
                _id: $scope.pv._intervention
            },
            options: {
                populate: [{
                    path: "_plateforme"
                }]
            }
        }, 1, 0, function (res) {
            if (res && res.success && res.result.length > 0) {
                $scope.intervention = res.result[0];
                
                $scope.verify2();

            } else
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("FIND_BY_ID_ERROR")
                });
        }, function (err) {
            Notification.error({
                title: $translate.instant('ERROR'),
                message: $translate.instant("FIND_BY_ID_ERROR")
            });
        })
    };
    $scope.verify = function () {
        var modal = ngDialog.open({
            controller: "ClientEdSyncDlgCtrl",
            templateUrl: "app/commons/dialogs/client/client.ed.sync.dlg.tpl.html",
            className: 'ngdialog-theme-default custom-width',
            scope: $scope,
            width: '900px',
            showClose: false,
            closeByEscape: false,
            closeByNavigation: true,
            closeByDocument: false,
            data: {
                "pv": true
            },
            resolve: {
                installation: function () {
                    return $scope.installation;
                }
            }
        })
        modal.closePromise.then(function (data) {
            $scope.pv_sync.client_id = data.value._id;
            $scope.pv_sync.client_name = data.value.name;
            $scope.pv_sync._client_code = data.value.client_code;
            $scope.pv_sync.clientSav_id=$scope.installation.clientSav_id || null

            $scope.verify_asset();

        })
    };
    $scope.verify2 = function () {
        var modal = ngDialog.open({
            controller: "ClientIntervSyncDlgCtrl",
            templateUrl: "app/commons/dialogs/client/client.interv.sync.dlg.tpl.html",
            className: 'ngdialog-theme-default custom-width',
            scope: $scope,
            width: '900px',
            showClose: false,
            closeByEscape: false,
            closeByNavigation: true,
            closeByDocument: false,
            resolve: {
                intervention: function () {
                    return $scope.intervention;
                }
            }
        });
        modal.closePromise.then(function (data) {
            $scope.pv_sync.client_id = data.value._id;
            $scope.pv_sync.client_name = data.value.name;
            $scope.pv_sync._client_code = data.value.client_code;
            $scope.verify_asset();

        })
    };

    $scope.get_insta_asset_id = function (asset) {
        console.log("ttttttttttttttttttttttté", asset)
        if (asset._id || asset.asset_id)
            $scope.insta_asset = asset._id || asset.asset_id;
    }

    $scope.verify_asset = function () {
        console.log('verifassrt');
        var modal = ngDialog.open({
            controller: "assetSyncDlgCtrl",
            templateUrl: "app/commons/dialogs/asset/asset.sync.dlg.tpl.html",
            className: 'ngdialog-theme-default custom-width',
            scope: $scope,
            width: '900px',
            showClose: false,
            closeByEscape: false,
            closeByNavigation: true,
            closeByDocument: false,
            data: {
                "pv": true
            },
            resolve: {
                installation: function () {
                    return $scope.installation;
                },
                intervention: function () {
                    return $scope.intervention;
                }
            }
        })
        modal.closePromise.then(function (data) {
            console.log("dddddddddddddddd");
            console.log("1", $scope.pv_sync);
            $scope.pv_sync.related_Model.related_asset_id = $scope.insta_asset;
            console.log("2", $scope.pv_sync);
            $scope.pv_sync.asset_id = data.value._id;
            console.log("3", $scope.pv_sync);
            $scope.pv_sync._asset = data.value.name;
            console.log("4", $scope.pv_sync);

            if ($scope.pv_sync.pv_ref != undefined) {
                console.log("fffffffffffffff")
                $scope.pv_sync.related_Model.related_model_id = $scope.pv_sync._installation || $scope.pv_sync._intervention;

                if ($scope.ListPvNumber.length <= 0) {
                    console.log("final", $scope.pv_sync);
                    I3PvSrv.add($scope.pv_sync, function (res) {
                        if (res && res.success) {
                            Notification.success({
                                title: $translate.instant("PV_ADDED_TITLE"),
                                message: $translate.instant("PV_ADDED_SUC")
                            });
                            $state.go('app.home.pvs');
                        } else {
                            Notification.error($translate.instant("PV_ADD_ERROR"));
                            //TODO handle error
                        }
                    }, function (err) {
                        console.log(err)
                    })
                } else {
                    Notification.error($translate.instant("PV_NUMBER_EXIST"));

                }
            } else {
                Notification.error($translate.instant("PV_ADD_NUMBER"));
            }

        })
    };

    $scope.searchInstallation = function () {
        I3InstallationGroupSrv.query({
            query: {
                'purchase_order': $scope.purchase_order,
                '_plateforme': $scope._plateforme
            },
            options: {
                populate: [{
                    path: "_plateforme"
                }, {
                    path: "_created_by",
                    select: 'login'
                }]
            }
        }, 1, 0, function (res) {
            if (res && res.success && res.result.length > 0) {
                $scope.installation = res.result[0];
                $scope.assets = res.result[0].assets;
                $scope.pv = {
                    'purchase_order': $scope.installation.purchase_order
                };
                $scope.pv.client_name = $scope.installation.client_name;
                $scope.pv._installation = $scope.installation._id;
                $scope.pv._plateforme = $scope.installation._plateforme._id;
                $scope.pv.SAP = $scope.installation.SAP;
            } else {
                $scope.installation = null;
                $scope.pv = null;
            }
        }, function (err) {
            $scope.installation = null;
            $scope.pv = null;
        })
    };

    $scope.selectType = function (type) {
        $scope.pv = null;
        $scope.assets = null;

        if (type === 'INSTALLATION' && $scope.purchase_order) {
            I3InstallationGroupSrv.query({
                query: {
                    'purchase_order': $scope.purchase_order,
                    '_plateforme': $scope._plateforme
                },
                options: {
                    populate: [{
                        path: "_plateforme"
                    }, {
                        path: "_created_by",
                        select: 'login'
                    }]
                }
            }, 1, 0, function (res) {
                if (res && res.success && res.result.length > 0) {
                    $scope.installation = res.result[0];
                    $scope.assets = res.result[0].assets;
                    $scope.pv = {
                        'purchase_order': $scope.installation.purchase_order
                    };
                    $scope.pv.client_name = $scope.installation.client_name;
                    $scope.pv._installation = $scope.installation._id;
                    $scope.pv._plateforme = $scope.installation._plateforme._id;
                    $scope.pv.type = 'INSTALLATION';
                    $scope.pv.SAP = $scope.installation.SAP;
                    //$scope.pv.charged_to_client = true
                    $scope.pv.charged_to_client="CLIENT"
                    console.log($scope.assets);
                    console.log($scope.pv);
                } else {
                    $scope.installation = null;
                    $scope.pv = null;
                }
            }, function (err) {
                $scope.installation = null;
                $scope.pv = null;
            })
        } else if (type === 'INTERVENTION' && $scope.purchase_order) {

            console.log($scope.pv)
            I3InterventionSrv.query({
                query: {
                    'purchase_order': $scope.purchase_order
                },
                options: {
                    populate: [{
                        path: "_plateforme"
                    }, {
                        path: "_created_by",
                        select: 'login'
                    }]
                }
            }, 1, 0, function (res) {
                if (res.success) {
                    $scope.assets = []
                    $scope.intervention = res.result[0];
                    $scope.assets = res.result[0].asset.map(function (val) {
                        return {
                            name: val.asset_name,
                            asset_id: val.asset_id
                        }
                    })
                    $scope.pv = {
                        'purchase_order': $scope.intervention.purchase_order
                    };
                    $scope.pv.type = 'INTERVENTION';
                    $scope.pv.client_name = $scope.intervention.client_name;
                    $scope.pv._intervention = $scope.intervention._id;
                    $scope.pv._plateforme = $scope.intervention._plateforme._id;
                    $scope.pv.charged_to_client = $scope.intervention.charged_to_client
                    console.log($scope.assets);
                    console.log($scope.pv);
                } else {
                    $scope.intervention = null;
                    $scope.pv = null;
                }
            }, function (err) {
                $scope.intervention = null;
                $scope.pv = null;
            })
        }
    }

    $scope.openProductDialog = function () {
        ngDialog.open({
            template: "app/commons/dialogs/pv/product.dlg.tpl.html",
            className: 'ngdialog-theme-default custom-width-800',
            appendClassName: 'ngdialog-custom',
            controller: "productDlgCtrl",
            scope: $scope
        })
    };
    $scope.cancelPv = function () {
        if ($state.current.name === "app.home.pvs.add") {
            $state.go('app.home.pvs');
        } else {
            ngDialog.closeAll();
        }
    };

    $scope.pvNumber = function () {
        $scope.isUniquePVRef = false;

        I3PvSrv.query({
            query: {
                pv_ref: $scope.pv.pv_ref,
                _plateforme: $scope.pv._plateforme
            }
        }, 10000, 1, function (res) {

            if (res.success) {
                if (res.result.length > 0 && $scope.pv._id != res.result[0]._id) {
                    $scope.isUniquePVRef = false;
                } else {
                    $scope.isUniquePVRef = true;
                }

            }
        }, function (err) {
            console.log(err);
        });

    }

    $scope.clientCharger = function (chargedClient) {

        $scope.chargedClient = chargedClient
        $scope.pv.charged_to_client = chargedClient;

    }

});