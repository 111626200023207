angular.module('app').controller('adminWorkflowCtrl',function ($scope,$translate,ngDialog,I3WorkflowSrv) {
    $scope.itemsByPage = 50;
    $scope.search = {};
    $scope.workflows=[]
   
    $scope.loadWorkflow = function (tableState,ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }

        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;
        var options =  {
            populate: [
                {
                    path: '_workflow',
                    select: 'model'
                }
            ],
            sortBy: {creation_dt: -1}
        };

        var query = {options:options};

        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
   }

        var requery = {};
        if (tableState.search && tableState.search.predicateObject) {
            if (tableState.search.predicateObject.name) {
                requery["model"] = {"$regex": tableState.search.predicateObject.name, "$options": "i"};
            }

           

        }
        if($scope.search) {
            if ( $scope.search.workflow) {
                requery["_workflow"] = $scope.search.workflow._id;
            }

        }

        query.query=requery;

        I3WorkflowSrv.query(query , number, start, function (response) {
            // console.log(response)
            if(response.success){
                $scope.workflows = response.result;
                $scope.stockCount = response.total_count;
                tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
                tableState.pagination.totalItemCount = response.total_count;


            } else {
                console.error(response.error)
              }


            }, function (response) {
              console.log(response)

            })
        
    };

    $scope.remove = function (workflow) {
     
        $scope.workflow = workflow;

        ngDialog.open({
            templateUrl: "app/commons/dialogs/confirm/workflow.delete.dlg.tpl.html",
            controller: "confirmDeleteWorkflowDlgCtrl",
            scope: $scope
        })

    };
})