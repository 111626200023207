angular.module("app").controller("clientCtrl", function ($scope, $rootScope, $filter, $state, I3ProductSrv, I3SubscriptionSrv, $translate, ngDialog, Notification, I3PlateformeSrv, plateformHelperSrv, iaxeedAssetSrv, iaxeedSocketSrv, $window) {

    $scope.$state = $state;
    $scope.first_load = true;
    $scope.search = {
        _client: "",
        disabledAssetCount: "ALL",
        activeAssetCount: "ALL",
        asset_count: {
            code: "ALL",
            value: 0
        },
        expired_account: "ALL"
    }
    $scope.itemsByPage = 50;
    $scope.accountStatusChoices = [
        "ALL", "EXPIRED_ACCOUNT", "ACTIVE_ACCOUNT"
    ]
    $scope.disabledAssetCountStatus = [
        "ALL", "ONLY_DISABLED_ALL_ASSET", "ONLY_HAS_DISABLED_ASSET"
    ]
    $scope.activeAssetCountStatus = [
        "ALL", "ONLY_ENABLED_ALL_ASSET", "ONLY_HAS_ENABLED_ASSET"
    ]
    if (!$scope.assetCountStatusRanges || $scope.assetCountStatusRanges.length <= 0) {
        $scope.assetCountStatusRanges = [{
            code: "ALL",
            value: 0
        }]

        for (var i = 10; i <= 100; i = i + 10) {

            $scope.assetCountStatusRanges.push({
                code: "LESS_THEN",
                value: i
            });
            $scope.assetCountStatusRanges.push({
                code: "GREETER_THEN",
                value: i
            });
        }
    }
    $scope.assetCountStatusRanges.sort(function (a, b) {
        if (a.code == "ALL") return 1;

        else if (a.code == "GREETER_THEN" && b.code == "LESS_THEN") {
            return -1;
        } else if (b.code == "GREETER_THEN" && a.code == "LESS_THEN") {
            return 1;
        } else {
            return a.value - b.value;
        }
    })

    //$scope.subscriptions=[]
    I3PlateformeSrv.query({}, 10000, 1, function (res) {

        if (res.success) {

            $scope.platformList = res.result;
            if ($scope.platformList && $scope.platformList.length > 0) {
                var plateform = null;
                if ($window.sessionStorage.plateform) {
                    try {
                        plateform = JSON.parse($window.sessionStorage.plateform)
                    } catch (e) {
                        logger.warn("invalid plateform from  cookie select first from list");
                        plateform = null;
                    }
                }
                if(!plateform && $rootScope.checkAccess("PLATEFORME","R","O")){
                    plateform=$scope.platformList[0]
                    $scope.selectPlatform( plateform);
                }else if (plateform) {
                    for (var i = 0; i < $scope.platformList.length; i++) {
                        if (plateform._id === $scope.platformList[i]._id) {
                            $rootScope.selectedPlatform = $scope.platformList[i];
                            break;
                        }
                    }

                    $scope.loadClient();
                } else {
                   //$rootScope.selectedPlatform = $scope.platformList[0];
                   if(!$rootScope.selectedPlatform)
                     $rootScope.selectedPlatform = null
                    //$scope.selectPlatform($rootScope.selectedPlatform );

                }
            }

        }
    }, function (err) {
        console.log(err);
    });

    $scope.selectPlatform = function (platform) {

        if ($rootScope.selectedPlatform && $rootScope.selectedPlatform._id == platform._id) {} else {
            $rootScope.$broadcast("platform:selected");
        }
        $rootScope.selectedPlatform = platform;
        plateformHelperSrv.setPlateFormAndLogin(platform, function (res) {

            if (res && res.success) {
                if (iaxeedSocketSrv.closeListner && typeof iaxeedSocketSrv.closeListner === "function") {
                    iaxeedSocketSrv.closeListner('newMsg');
                }
                iaxeedSocketSrv.connect(platform);
                iaxeedSocketSrv.on('server:connected', function () {
                    iaxeedSocketSrv.emit('user:connected', {
                        user: JSON.parse($window.sessionStorage.plateformSession).user
                    });
                });
                $rootScope.$broadcast("platform:changed", $rootScope.selectedPlatform);
                $rootScope.change_dt = new Date();
            } else {
                Notification.error($translate.instant("CHECK_CNX_SETTINGS"));
            }
        }, function (err) {
            if (err.status === -1) {
                Notification.error($translate.instant("IMPOSSIBLE_CNX"));
            } else if (err.status === 401) {
                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS")
                });
            } else {
                Notification.error($translate.instant("LOADING_ERROR_PLATFORM"));
            }
        })
    };
    var platformeSelected = $scope.$on("platform:selected", function (evt, args) {
        /*if ($scope.stCtrl) {
            $scope.stCtrl.tableState().pagination.start = 0;
            $scope.stCtrl.pipe();
        }*/
    })
    var changePlatformListener = $scope.$on("platform:changed", function (evt, args) {

        $scope.loadClient();
    });

    $scope.filter_array_from_array = function (array1, array2) {
        var vm = this;
        var filtered = array1.filter(
            function (e) {
                return vm.indexOf(e) < 0;
            },
            array2
        );
        console.log(filtered)
        return filtered
    }

    $scope.fdoun = function (array1, length, client) {
        $scope.idx = -1;
        for (var i = 0; i < length; i++) {
            if (array1[i] == client._company_owner._id) {
                $scope.idx = i;
                break
            }

        }
        if ($scope.idx == -1)
            return true
        else
            return false
    }
    $scope.loadClient = function (tableState, ctrl) {
        var dash_ids = $rootScope.selectedPlatform.dash._clients.map(function (val) {
            return val._id
        })

        var agg = [{
                $project: {
                    _company_owner: "$_company_owner",
                    asset: {
                        name: "$name",
                        gps_dt: "$rt.gps_dt"
                    }
                }
            },
            {

                $group: {
                    _id: "$_company_owner",
                    assets: {
                        $push: "$asset"
                    }
                }

            },
            {
                $lookup: {
                    from: "company",
                    localField: "_id",
                    foreignField: "_id",
                    as: "_company_owner"
                }
            },
            {
                $project: {
                    "_company_owner": {
                        "$arrayElemAt": ["$_company_owner", 0]
                    },
                    assets: "$assets"
                }
            },
            {
                $project: {
                    "_company_owner": {
                        "_id": "$_company_owner._id",
                        name: "$_company_owner.name",
                        expiration_dt: "$_company_owner.expiration_dt"
                    },
                    "assets": "$assets"
                }
            }
        ]
        iaxeedAssetSrv.agg({
                aggregate: agg
            }, 10000, 0, function (res) {
                console.log("res.result", res.result)
                $scope.assetCount = 0;
                if (res && res.success) {
                    var result = res.result.sort(function (a, b) {
                        return b.assets.length - a.assets.length
                    });
                    console.log(dash_ids)
                    var result2 = result.filter(function (client) {
                        return $scope.fdoun(dash_ids, result.length, client)

                    })
                    result2 = result2.map(function (val) {
                        $scope.assetCount += val.assets.length;
                        val.enabledAsset = [];
                        val.disabledAsset = [];
                        val.assets.forEach(function (asset) {
                            if (asset && asset.gps_dt) {

                                if (moment().add(-2, "days").diff(asset.gps_dt, "days") >= 0) {

                                    val.disabledAsset.push(asset);
                                } else {
                                    val.enabledAsset.push(asset);
                                }
                            } else {
                                val.disabledAsset.push(asset);
                            }
                        })
                        return val;
                    })
                    $scope.clients = result2;
                    $scope.clientCount = result2.length;
                } else {

                }
            },
            function (err) {
                if (err.status === -1) {
                    Notification.error($translate.instant("IMPOSSIBLE_CNX"));
                } else if (err.status === 401) {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS")
                    });
                } else {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("LOADING_ERROR")
                    });
                }
            })
    }

    $scope.filterList = function (item) {
        var isNotFiltred = true;

        if ($scope.search && $scope.search._client && $scope.search._client.length > 0) {

            if (item && item._company_owner && item._company_owner.name) {
                if (item._company_owner.name.toLowerCase().search($scope.search._client.toLowerCase()) < 0) {
                    isNotFiltred = false;
                }
            } else {
                isNotFiltred = true;
            }
        } else {
            isNotFiltred = true;
        }
        if (isNotFiltred) {
            if ($scope.search && $scope.search.asset_count && $scope.search.asset_count && $scope.search.asset_count.code != "ALL") {
                if ($scope.search.asset_count.code == "GREETER_THEN") {
                    if (item.assets.length < $scope.search.asset_count.value) {
                        isNotFiltred = false;
                    }
                } else {
                    if (item.assets.length > $scope.search.asset_count.value) {
                        isNotFiltred = false;
                    }
                }
            }
        }
        if (isNotFiltred) {
            if ($scope.search && $scope.search.activeAssetCount && $scope.search.activeAssetCount != "ALL") {
                if ($scope.search.activeAssetCount == "ONLY_ENABLED_ALL_ASSET") {
                    if (!item.assets || item.assets.length == 0) {
                        isNotFiltred = false;
                    } else if (item.assets.length != item.enabledAsset.length) {
                        isNotFiltred = false;
                    }
                } else if ($scope.search.activeAssetCount == "ONLY_HAS_ENABLED_ASSET") {
                    if (item.enabledAsset.length <= 0) {
                        isNotFiltred = false;
                    }
                }
            }
        }
        if (isNotFiltred) {
            if ($scope.search && $scope.search.disabledAssetCount && $scope.search.disabledAssetCount != "ALL") {
                if ($scope.search.disabledAssetCount == "ONLY_DISABLED_ALL_ASSET") {
                    if (!item.assets || item.assets.length == 0) {
                        isNotFiltred = false;
                    } else if (item.assets.length != item.disabledAsset.length) {
                        isNotFiltred = false;
                    }
                } else if ($scope.search.disabledAssetCount == "ONLY_HAS_DISABLED_ASSET") {
                    if (item.disabledAsset.length <= 0) {
                        isNotFiltred = false;
                    }
                }
            }
        }
        if (isNotFiltred) {
            if ($scope.search && $scope.search.expired_account && $scope.search.expired_account != "ALL") {
                if ($scope.search.expired_account == "EXPIRED_ACCOUNT") {
                    if (!item._company_owner.expiration_dt || moment().diff(item._company_owner.expiration_dt, "minutes") < 0) {
                        isNotFiltred = false;
                    }
                } else if ($scope.search.expired_account == "ACTIVE_ACCOUNT") {
                    if (item._company_owner.expiration_dt && moment().diff(item._company_owner.expiration_dt, "minutes") > 0) {
                        isNotFiltred = false;
                    }
                }
            }
        }
        return isNotFiltred;
    }

    $scope.resetFilter = function () {
        $scope.search = {
            _client: "",
            disabledAssetCount: "ALL",
            activeAssetCount: "ALL",
            asset_count: {
                code: "ALL",
                value: 0
            },
            expired_account: "ALL"
        }
    }

    $scope.$on('destroy', function (event) {
        //console.log("destroying ...")
        changePlatformListener();
        platformeSelected();
    })
});