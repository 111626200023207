angular.module('app').controller('adminClientsCtrl', function ($scope, $rootScope, $state, $translate, $filter, Notification, ngDialog, I3PlateformeSrv, I3ClientSrv) {

    $scope.clients = {};
    $scope.itemsByPage = 50;
    $scope.search = {};

    I3PlateformeSrv.query({}, 10000, 1, function (res) {
        if (res.success) {
            $scope.platforms = res.result;
        }
    }, function (err) {
        console.log(err);
    });


    /**
     * Getting clients
     */
    $scope.loadClients = function (tableState, ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }

        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;

        var options = {
            populate: [
                {
                    path: '_plateforme',
                    select: 'name'
                }
            ],
            sortBy: { creation_dt: -1 }
        };

        var query = { options: options };


        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
        }
        var requery = {};


        if (tableState.search && tableState.search.predicateObject) {
            if (tableState.search.predicateObject.name) {
                requery["name"] = { "$regex": tableState.search.predicateObject.name, "$options": "i" };
            }


        }
        if ($scope.search) {
            if ($scope.search._plateforme) {
                requery["_plateforme"] = $scope.search._plateforme._id;
            }

        }

        query.query = requery;


        I3ClientSrv.query(query, number, start, function (response) {
            if (response.success) {
                $scope.clients = response.result;

                $scope.clientsCount = response.total_count;
                tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
                tableState.pagination.totalItemCount = response.total_count;
                $scope.tableState = tableState;
            }
        });
    };



    /**
     * Removing client
     * @param client
     */
    $scope.remove = function (client) {
        $scope.client = client;

        ngDialog.open({
            templateUrl: "app/commons/dialogs/confirm/client.delete.dlg.tpl.html",
            controller: "confirmDeleteClientDlgCtrl",
            scope: $scope
        })

    };

});