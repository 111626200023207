/**
 * Created by Kais CHAIBI on 29/11/2017.
 */

var PLATFORM_SERVICES=[

    {name:"iaxeedAssetSrv",path:"asset"},
    {name:"iaxeedCompanySrv",path:"company" ,filter:"getCompanyFilter"},
    {name:"iaxeedActionLogSrv",path:"actionLog"},
    {name:"iaxeedAlertConfigSrv",path:"alertconfig"},
    {name:"iaxeedAlertModelSrv",path:"alertmodel"},
    {name:"iaxeedAssettypeSrv",path:"assettype"},
    {name:"iaxeedAssetdriverSrv",path:"assetdriver"},
    {name:"iaxeedChargeSrv",path:"charge"},
    {name:"iaxeedClaimSrv",path:"claim"},
    {name:"iaxeedCountrySrv",path:"country"},
    {name:"iaxeedDeviceMsgSrv",path:"device_msg"},
    {name:"iaxeedDeviceSrv",path:"device"},
    {name:"iaxeedDeviceTypeSrv",path:"devicetype"},
    {name:"iaxeedDeviceConfigSrv",path:"deviceConfig"},
    {name:"iaxeedDriverSrv",path:"driver"},
    {name:"iaxeedEnumsSrv",path:"enums"},
    {name:"iaxeedFleetSrv",path:"fleet"},
    {name:"iaxeedGeodatatypeSrv",path:"geodatatype"},
    {name:"iaxeedGeodataSrv",path:"geodata"},
    {name:"iaxeedReportSrv",path:"report"},
    {name:"iaxeedRoleSrv",path:"role"},
    {name:"iaxeedUserSrv",path:"user"},
    {name:"iaxeedMonitoringSrv",path:"monitoring"},
    {name:"iaxeedTokenSrv",path:"token"}

];
angular.module("app.services").constant("PLATFORM_SERVICES",PLATFORM_SERVICES)


PLATFORM_SERVICES.forEach(function (val) {

    angular.module("app.services").factory(val.name, ["AbstractApiSrv",function (AbstractApiSrv) {
        return new AbstractApiSrv(val);
    }])
})


