angular.module('app.dialogs').controller('productDlgCtrl', function ($scope, constantSrv, I3StockStatusSrv, I3StockSrv) {
    //$scope._HOME.subscriptionTypes = constantSrv.getSubscriptionTypes();
    var today = moment().startOf("day").clone();
    $scope.pvProduct = {}
    $scope.categoryZone = {}
    $scope.zonesList = []
    $scope.ListStocks = [];
    $scope.virtual_stocks = {};
    $scope.stock = {}
    var now = moment().clone();
    $scope.dayRes = now;
    $scope.isOutOfStock = true;
    $scope.showOutOfSotck = false;
    $scope.checkStock = false
    $scope.pv = $scope.pv || $scope.pv_sync
    if (!$scope.pv.products)
        $scope.pv.products = []
    $scope.addPvProduct = function () {
        $scope.pvProduct.zones = $scope.zonesList;
       /*  $scope.pv.products.push($scope.pvProduct) */
        //console.log($scope.pv)

        $scope.checkStockAvailable()
        if ($scope.checkStock == false && $scope.pvProduct._product.type == "MATERIAL") {
            $scope.pv.products.push($scope.pvProduct)
            $scope.closeThisDialog();
        } else if ($scope.pvProduct._product.type != "MATERIAL") {

        
           
            
            $scope.pv.products.push($scope.pvProduct)
           
            $scope.closeThisDialog();
        }

    };

    $scope.getProductsByType = function (type) {
        $scope.pvProduct.quantity = null
        $scope.selectedProductType = type;
        $scope.pvProduct._product = null;
        $scope.products = $scope._HOME.products.filter(function (e) {

            return e.type == type;
        })
        if (type !== "MATERIAL") {

            $scope.checkStock = false
        }
    }

    $scope.getCategoryByZone = function (zone) {
        $scope.categoryZone.category = null;
        $scope.zoneCategories = zone.travel_costs.map(function (e) {
            return e.category;
        });

    }

    $scope.removeCategory = function (index) {
        $scope.zonesList.splice(index, 1);

    }

    $scope.addCategoryZone = function () {
        if ($scope.categoryZone._zone && $scope.categoryZone.category && $scope.categoryZone.quantity) {
            $scope.zonesList.push($scope.categoryZone)
            $scope.categoryZone = {}
        }

    }





    ////////////////////controle Products Stocks////////////////////////////

    $scope.checkStockAvailable = function () {

        $scope.isOutOfStock = true;
        $scope.showOutOfSotck = false;
        $scope.availableInStock = 0;


      //  console.log('$scope.pv._plateforme:', $scope.pv._plateforme)

        if ($scope.pvProduct && $scope.pvProduct._product && $scope.pvProduct._product.type == "MATERIAL") {




            I3StockSrv.query({ query:{_plateforme: $scope.pv._plateforme} }, 10000, 1, function (res) {
                if (res.success) {
                    $scope.ListStocks = res.result;
                    $scope.virtual_stocks = [];
                    $scope.ListStocks.forEach(function (val) {
                  
                        $scope.stock = val
                        val.virtual_stock.forEach(function (stock) {
                            if (stock.type == "REEL") {
                                $scope.virtual_stocks = stock

                            }
                        });
                    })


                    var query = {
                        _product: $scope.pvProduct._product._id,
                        _stock: $scope.stock._id,
                        virtual_stock: $scope.virtual_stocks._id

                    }
                    I3StockStatusSrv.query({ query: query }, 10000, 0, function (res) {

                        if (res && res.success && res.result.length > 0) {
                            $scope.availableInStock = res.result[0].qty
                            $scope.availableInStockDiff = res.result[0].qty - $scope.pvProduct.quantity

                            $scope.isOutOfStock = $scope.availableInStockDiff < $scope.pvProduct.quantity;
                            if ($scope.isOutOfStock) {
                                $scope.showOutOfSotck = true;
                                $scope.checkStock = true
                            } else {
                                $scope.showOutOfSotck = false;
                                $scope.checkStock = false
                            }


                        } else {
                            $scope.showOutOfSotck = true;
                            $scope.checkStock = true
                        }

                    }, function (err) {
                        console.log("enable to get stock status");
                    })

                }
            }, function (err) {
                console.log(err);
            });
        }

    }


    $scope.selectProd = function (prod) {
        $scope.checkStock = false;
        $scope.pvProduct.quantity = null
    }

});
