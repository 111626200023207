angular.module("app").controller('monitoringDetailsCtrl', function ($scope, $rootScope, $state, $stateParams, Notification, dUtilsSrv, iaxeedAssetSrv, iaxeedMonitoringSrv, constantSrv, ngDialog, $translate) {
  $scope.toDo = "sensorInfo";
  $scope.com=false
    $scope.priorities = constantSrv.getMonitoringPriority();
    $scope.monitoringStates = ["New", 'Processed'];
    var now = moment().clone();
   
  $scope.loadDetail = function () {
    iaxeedMonitoringSrv.query({
      query: {
        _id: $stateParams.monitoringId
      },
      options: {
        populate: ' _company_owner _asset _rule'
      }
    }, 1, 1, function (response) {
      if (response.success) {
        $scope.monitoring = response.result[0];
       console.log(" $scope.monitoring", $scope.monitoring.validation_dt)
       
  
        if($scope.monitoring.validation_dt ==undefined ){
    $scope.monitoring.validation_dt = now;
  }else{
    now=$scope.monitoring.validation_dt
  }
  $scope.pickerDay = {
    format: "DD/MM/YYYY",
    position: "top right",
    init:  moment(now),
    autoTrans: false,
    enableTime: false,
    enableDate: true,
    enableMinutes: false,
    defaultView: "DATE",

    getDate: function (date) {
       console.log("date",date)  
       $scope.validation_date=moment(date).toISOString() 
    }
};
        var asset = $scope.monitoring.asset_data;
        var sensors = asset.sensors;
        asset.sensorData = [];
        if (asset.rt && asset.rt.io) {
          if (sensors.length > 0) {
            angular.forEach(sensors, function (sensor) {
              var sensorName = sensor.name;
              var src = sensor.src.slice(sensor.src.lastIndexOf('.') + 1);
              var srcDt = src + "_dt";
              var realVal = asset.rt.io[src];
              if (sensor.type && sensor.src && Number.isFinite(asset.rt.io[src])) {
                if (constantSrv.getSensorFamily(sensor.type) === "ANALOGIC") {
                  if (Number.isFinite(sensor.minSrc) && Number.isFinite(sensor.maxSrc) && Number.isFinite(sensor.min) && Number.isFinite(sensor.max)) {
                    realVal = constantSrv.getRealSensorValue(asset.rt.io[src], sensor.minSrc, sensor.maxSrc, sensor.min, sensor.max);
                  }
                  if (Number.isFinite(realVal)) {
                    asset.sensorData.push({
                      name: sensorName,
                      type: sensor.type,
                      family: sensor.family,
                      unit: sensor.unit,
                      date: asset.rt.io[srcDt],
                      value: parseFloat(realVal.toFixed(2)),
                      visible: sensor.visible
                    });
                  }
                }
                if (constantSrv.getSensorFamily(sensor.type) === "BINARY") {
                  asset.sensorData.push({
                    name: sensorName,
                    type: sensor.type,
                    family: sensor.family,
                    date: asset.rt.io[srcDt],
                    ON: sensor.ON,
                    OFF: sensor.OFF,
                    value: parseFloat(realVal.toFixed(2)),
                    visible: sensor.visible
                  });
                }
              }
            });
          }
        }
        dUtilsSrv.setAllAddress([asset]);
        $scope.assetDetails = asset;
        angular.forEach($scope.priorities, function (val) {
          if (val.value === $scope.monitoring.priority) {
            $scope.monitoring.priority = val;
          }
        });
        var prepareDetails = Object.keys($scope.monitoring.details).map(function (key) {
          var error = {
            src: key,
            detail: $scope.monitoring.details[key]
          };
          return error;
        });
        angular.forEach(prepareDetails, function (val, key) {
          if (Array.isArray(val.detail) === false) {
            prepareDetails[key].detail = [val.detail];
          }
        });
        $scope.monitoringDetails = prepareDetails;
        var ioDetails = $scope.assetDetails;
        if (ioDetails && ioDetails.rt && ioDetails.rt.io) {
          var io = angular.copy(ioDetails.rt.io);
          var io_dt = [];
          for (var key in io) {
            var value = io[key];
            if (key.endsWith("_dt")) {
              var elem = {
                src: key,
                val: value
              };
              io_dt.push(elem);
            }
          }
          $scope.newIO = [];
          for (var key in io) {
            var value = io[key];
            angular.forEach(io_dt, function (elem) {
              if (typeof (value) === 'number' && elem.src.includes(key)) {
                var newElem = {
                  src: key,
                  val: value,
                  dt: elem.val
                };
                $scope.newIO.push(newElem);
              }
            });
          }
          if (ioDetails.rt.gps_dt) {
            var newElem = {
              src: 'srv_dt',
              val: null,
              dt: data._asset[0].rt.srv_dt
            };
            $scope.newIO.push(newElem);
          }
          if (ioDetails.rt.loc && ioDetails.loc.coordinates.length > 0) {
            var newElem = {
              src: 'loc',
              val: 'lng: ' + ioDetails.rt.loc.coordinates[0] + ', lat: ' + ioDetails.rt.loc.coordinates[1],
              dt: null
            };
            $scope.newIO.push(newElem);
          }
        } else {
          $scope.newIO = [];
          $scope.asset = {};
        }
      } else {
        Notification.error('SERVER_UNAVAILABLE');
      }
    }, function (err) {
      if (err.status === -1) {
        Notification.error($translate.instant("IMPOSSIBLE_CNX"));
      } else if (err.status === 401) {
        Notification.error($translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS"));
      } else {
        Notification.error($translate.instant("LOADING_ERROR"));
      }
    });
  };
  $scope.loadDetail();
  $scope.remove = function (monitoring) {
    ngDialog.openConfirm({
      template: 'app/commons/dialogs/confirm/confirm.dlg.html',
      overlay: true,
      showClose: false,
      controller: ['$scope', function ($scope) {
        // controller logic
        $scope.title = $translate.instant("MONITORING_DELETE_CONFIRM_TITLE");
        $scope.message = $translate.instant("MONITORING_DELETE_CONFIRM_MESSAGE");
      }]
    }).then(function () {
      iaxeedMonitoringSrv.delete(monitoring._id, function (data) {
        if (data && data.success) {
          Notification($translate.instant("MONITORING_DELETED"));
          $state.go('app.monitoring.anomaly');
        } else {
          Notification.error('SERVER_UNAVAILABLE');
        }
      }, function (err) {
        if (err.status === -1) {
          Notification.error($translate.instant("IMPOSSIBLE_CNX"));
        } else if (err.status === 401) {
          Notification.error($translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS"));
        } else {
          Notification.error($translate.instant("LOADING_ERROR"));
        }
      });
    }, function (reason) {});
  };
    $scope.changeStatus = function (monitoring) {
      Notification($translate.instant("MONITORING_ACQUITTAL_UPDATED"));
    
    };
  $scope.MonitoringStatusOn = function (asset) {
    $scope.monitoring._asset.notMonitor = asset.notMonitor;
    iaxeedAssetSrv.updateById(asset._id, asset, function (response) {
      if (response && response.success) {
        if (asset.notMonitor) {
          Notification.error($translate.instant("MONITORING_DEACTIVATED"));
          $scope.loadDetail();
        } else {
          Notification.success("MONITORING_ACTIVATED");
          $scope.loadDetail();
        }
      } else {
        Notification.error('SERVER_UNAVAILABLE');
      }
    }, function (err) {
      if (err.status === -1) {
        Notification.error($translate.instant("IMPOSSIBLE_CNX"));
      } else if (err.status === 401) {
        Notification.error($translate.instant("EXPIRED_SESSION/UNAUTHORIZED_ACCESS"));
      } else {
        Notification.error($translate.instant("LOADING_ERROR"));
      }
    });
  };
  $scope.cancelDetails = function () {
      $state.go('app.monitoring.anomaly');
  };
  $scope.compareDate = function (date1, date2) {
    var dt1 = new Date(date1).getTime();
    var dt2 = new Date(date2).getTime();
    return dt1 !== dt2;
  };
  $scope.getSensorIcon = function (sensor, status) {
    var key = sensor.type;
    if (status) {
      if (sensor.ON === sensor.value) {
        key += "_ON";
      } else {
        key += "_OFF";
      }
    }
    return constantSrv.getSensorsTypes().ICONS[key];
  };
  $scope.getSensorStatus = function (sensor, status) {
    if (status) {
      if (sensor.ON === sensor.value) {
        return constantSrv.getSensorsTypes().STATUS[sensor.type].enabled;
      }
      return constantSrv.getSensorsTypes().STATUS[sensor.type].disabled;
    }
  };
  $scope.hasOdometer = function () {
    var valid = false;
    angular.forEach($scope.monitoring.asset_data.sensors, function (sensor) {
      if (sensor.type === "ODOMETER") {
        valid = true;
      }
    });
    return valid;
  };
  $scope.ioForm = function () {
    $scope.toDo = "ioDetails";
  };
  $scope.sensorForm = function () {
    $scope.toDo = "sensorInfo";
  };


  

 

  
$scope.validationDate=function(monitoring){
  if(monitoring.ack=='Processed'){
    
    monitoring.validation_dt=$scope.validation_date
  }else{
    monitoring.validation_dt=null
  }
  var mont = angular.copy(monitoring);
  mont._asset = monitoring._asset._id;
  mont._company_onwer = monitoring._company_owner._id;
  mont._rule = monitoring._rule._id;
  mont.priority = monitoring.priority.value;
  console.log(mont)
      iaxeedMonitoringSrv.updateById(monitoring._id, mont, function (data) {
          if (data && data.success) {
            console.log(data)
            Notification($translate.instant("MONITORING_UPDATED"));
            $scope.loadDetail()
            
          } else {
              Notification.error({
                  title: $translate.instant('ERROR'),
                  message : $translate.instant("ERROR_OCCURED_UPDATE")
              });
          }
      }, function (err) {
          var msg = "";
          if( err.error.errorString){
              msg = err.error.errorString;
          }
          Notification.error({
              title: $translate.instant('ERROR'),
              message : msg
          });
      });
  
 
 
}
$scope.show_com = function () {
  
      $scope.com = !$scope.com;
  
};

});