angular.module("app").config(routesConfig);
/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise("/login");
    $stateProvider
        .state("login", {
            url: "/login",
            templateUrl: "app/pages/login/login.tpl.html",
            controller: "loginCtrl",
        })
        .state("client", {
            url: "/client",
            templateUrl: "app/pages/clients/installationClient.tpl.html",
            controller: "installationClientCtrl",
        })
        .state("app", {
            url: "/app",
            templateUrl: "app/pages/main.tpl.html",
            controller: "mainCtrl",
            abstract: true,
        })
        .state("app.dashboard", {
            url: "/dashboard",
            views: {
                content: {
                    templateUrl: "app/pages/dashboard/dashboard.tpl.html",
                    controller: "dashboardCtrl",
                },
            },
            data: {
                access: ["DASHBOARD", "DASHBOARD"],
            },
        })
        .state("app.dashboard.dash", {
            url: "/dash",
            templateUrl: "app/pages/dashboard/dash/dash.tpl.html",
            controller: "dashCtrl",
            data: {
                access: ["DASHBOARD", "DASH"],
            },
        })
        .state("app.dashboard.history", {
            url: "/history",
            templateUrl: "app/pages/dashboard/history/history.tpl.html",
            controller: "historyCtrl",
            data: {
                access: ["DASHBOARD", "HISTORY"],
            },
        })
        .state("app.dashboard.stock", {
            url: "/stock",
            templateUrl: "app/pages/dashboard/stock/stock.tpl.html",
            controller: "stockCtrl",
            data: {
                access: ["HOME", "COMPATIBILITY_REQUEST"],
            },
        })
        .state("app.home", {
            url: "/home",
            views: {
                content: {
                    templateUrl: "app/pages/home/home.tpl.html",
                    controller: "homeCtrl",
                },
            },
            data: {
                access: ["HOME", "HOME"],
            },
        })
        .state("app.home.compatibilityRequest", {
            url: "/compatibilityRequest",
            templateUrl: "app/pages/home/compatibility-request/compatibility.request.tpl.html",
            controller: "compatibilityRequestCtrl",
            params: {
                filter: null,
                platform: null,
            },
            data: {
                access: ["HOME", "COMPATIBILITY_REQUEST"],
            },
        })
        .state("app.home.compatibilityRequest.add", {
            url: "/add",
            templateUrl: "app/pages/home/compatibility-request/compatibility.request.add.tpl.html",
            controller: "compatibilityRequestAddCtrl",
        })
        .state("app.home.compatibilityRequest.edit", {
            url: "/edit/:compatibilityId",
            templateUrl: "app/pages/home/compatibility-request/compatibility.request.edit.tpl.html",
            controller: "compatibilityRequestEditCtrl",
        })
        .state("app.home.compatibilityRequest.details", {
            url: "/details/:id",
            templateUrl: "app/pages/home/compatibility-request/compatibility.request.details.tpl.html",
            controller: "compatibilityRequestDetailsCtrl",
        })
        .state("app.home.compatibilityRequest.transform", {
            url: "/transform/:compatibilityId",
            templateUrl: "app/pages/home/installations-request/installation.request.add.tpl.html",
            controller: "installationRequestAddCtrl",
        })
        .state("app.home.compatibilityCheck", {
            url: "/compatibilityCheck",
            templateUrl: "app/pages/home/compatibility-check/compatibility.check.tpl.html",
            controller: "compatibilityCheckCtrl",
            params: {
                filter: null,
                platform: null,
            },
            data: {
                access: ["HOME", "COMPATIBILITY_CHECK"],
            },
        })
        .state("app.home.compatibilityCheck.edit", {
            url: "/edit/:compatibilityId",
            templateUrl: "app/pages/home/compatibility-check/compatibility.check.edit.tpl.html",
            controller: "compatibilityCheckEditCtrl",
        })
        .state("app.home.compatibilityCheck.details", {
            url: "/details/:id",
            templateUrl: "app/pages/home/compatibility-check/compatibility.check.details.tpl.html",
            controller: "compatibilityCheckDetailsCtrl",
        })
        .state("app.home.installRequest", {
            url: "/installRequest",
            templateUrl: "app/pages/home/installations-request/installations.request.tpl.html",
            controller: "installationRequestCtrl",
            params: {
                filter: null,
                platform: null,
            },
            data: {
                access: ["HOME", "INSTALLATION_REQUEST"],
            },
        })
        .state("app.home.installRequest.edit", {
            url: "/edit/:id",
            templateUrl: "app/pages/home/installations-request/installation.request.edit.tpl.html",
            controller: "installationRequestEditCtrl",
        })
        .state("app.home.installRequest.details", {
            url: "/details/:id",
            templateUrl: "app/pages/home/installations-request/installation.request.details.tpl.html",
            controller: "installationRequestDetailsCtrl",
        })
        .state("app.home.install", {
            url: "/install",
            templateUrl: "app/pages/home/installation/installations.tpl.html",
            controller: "installationCtrl",
            params: {
                filter: null,
                platform: null,
            },
            data: {
                access: ["HOME", "INSTALLATION"],
            },
        })
        .state("app.home.install.edit", {
            url: "/edit/:id",
            templateUrl: "app/pages/home/installation/installation.edit.tpl.html",
            controller: "installationEditCtrl",
        })
        .state("app.home.install.details", {
            url: "/details/:id",
            templateUrl: "app/pages/home/installation/installation.details.tpl.html",
            controller: "installationDetailsCtrl",
        })
        .state("app.home.clientForm", {
            url: "/clientform",
            templateUrl: "app/pages/home/clients-form/clients.forms.tpl.html",
            controller: "clientFormListCtrl",
        })
        .state("app.home.clientForm.details", {
            url: "/details/:id",
            templateUrl: "app/pages/home/clients-form/clientFormDetails.tpl.html",
            controller: "clientFormDetailsCtrl",
        })
        .state("app.home.interventionRequest", {
            url: "/interventionRequest",
            templateUrl: "app/pages/home/intervention-request/intervention.request.tpl.html",
            controller: "interventionRequestCtrl",
            params: {
                filter: null,
                platform: null,
            },
            data: {
                access: ["HOME", "INTERVENTION_REQUEST"],
            },
        })
        .state("app.home.interventionRequest.details", {
            url: "/details/:id",
            templateUrl: "app/pages/home/intervention-request/intervention.request.details.tpl.html",
            controller: "interventionRequestDetailsCtrl",
        })
        .state("app.home.interventionRequest.edit", {
            url: "/edit/:id",
            templateUrl: "app/pages/home/intervention-request/intervention.request.edit.tpl.html",
            controller: "interventionRequestEditCtrl",
        })
        .state("app.home.interventionRequest.add", {
            url: "/add",
            templateUrl: "app/pages/home/intervention-request/intervention.request.add.tpl.html",
            controller: "interventionRequestAddCtrl",
        })
        .state("app.home.intervention", {
            url: "/intervention",
            templateUrl: "app/pages/home/intervention/intervention.tpl.html",
            controller: "interventionCtrl",
            params: {
                filter: null,
                platform: null,
            },
            data: {
                access: ["HOME", "INTERVENTION"],
            },
        })
        .state("app.home.intervention.add", {
            url: "/add",
            templateUrl: "app/pages/home/intervention/intervention.add.tpl.html",
            controller: "interventionAddCtrl",
        })
        .state("app.home.intervention.edit", {
            url: "/edit/:id",
            templateUrl: "app/pages/home/intervention/intervention.edit.tpl.html",
            controller: "interventionEditCtrl",
        })
        .state("app.home.intervention.details", {
            url: "/details/:id",
            templateUrl: "app/pages/home/intervention/interventionDetails.tpl.html",
            controller: "interventionDetailsCtrl",
        })
        .state("app.home.pvs", {
            url: "/pvs",
            templateUrl: "app/pages/home/pvs/pvs.tpl.html",
            controller: "pvsCtrl",
            params: {
                filter: null,
                platform: null,
            },
            data: {
                access: ["HOME", "PV"],
            },
        })
        .state("app.home.pvs.add", {
            url: "/add",
            templateUrl: "app/pages/home/pvs/pv.add.tpl.html",
            controller: "pvAddCtrl",
        })
        .state("app.home.pvs.edit", {
            url: "/edit/:pvId",
            templateUrl: "app/pages/home/pvs/pv.edit.tpl.html",
            controller: "pvEditCtrl",
        })
        .state("app.home.pvs.detail", {
            url: "/detail/:pvId",
            templateUrl: "app/pages/home/pvs/pv.detail.tpl.html",
            controller: "pvDetailCtrl",
        })
        .state("app.home.planning", {
            url: "/planning",
            templateUrl: "app/pages/home/planning/planning.tpl.html",
            controller: "planningCtrl",
        })
        .state("app.monitoring", {
            url: "/monitoring",
            views: {
                content: {
                    templateUrl: "app/pages/monitoring/monitor.tpl.html",
                    controller: "monitorCtrl",
                },
            },
            data: {
                access: ["MONITORING", "MONITORING"],
            },
        })
        .state("app.monitoring.anomaly", {
            url: "/anomaly",
            templateUrl: "app/pages/monitoring/monitoring.tpl.html",
            controller: "monitoringCtrl",
            params: {
                filter: null,
                platform: null,
            },
            data: {
                access: ["MONITORING", "MONITORING"],
            },
        })
        .state("app.monitoring.anomaly.details", {
            url: "/:monitoringId",
            templateUrl: "app/pages/monitoring/monitoring.details.tpl.html",
            controller: "monitoringDetailsCtrl",
        })
        .state("app.admin", {
            url: "/admin",
            views: {
                content: {
                    templateUrl: "app/pages/admin/admin.tpl.html",
                    controller: "adminCtrl",
                },
            },
            data: {
                access: ["ADMIN", "ADMIN"],
            },
        })
        .state("app.admin.platform", {
            url: "/platforms",
            templateUrl: "app/pages/admin/platforms/platforms.tpl.html",
            controller: "adminPlatformCtrl",
            data: {
                access: ["ADMIN", "PLATEFORME"],
            },
        })
        .state("app.admin.platform.add", {
            url: "/add",
            templateUrl: "app/pages/admin/platforms/platform.add.tpl.html",
            controller: "platformAddCtrl",
        })
        .state("app.admin.platform.edit", {
            url: "/edit/:platformId",
            templateUrl: "app/pages/admin/platforms/platform.edit.tpl.html",
            controller: "platformEditCtrl",
        })
        .state("app.admin.clients", {
            url: "/clients",
            templateUrl: "app/pages/admin/clients/clients.tpl.html",
            controller: "adminClientsCtrl",
            data: {
                access: ["ADMIN", "CLIENT"],
            },
        })
        .state("app.admin.clients.add", {
            url: "/add",
            templateUrl: "app/pages/admin/clients/client.add.tpl.html",
            controller: "adminClientAddCtrl",

        })

        .state("app.admin.clients.edit", {
            url: "/edit/:ClientId",
            templateUrl: "app/pages/admin/clients/client.edit.tpl.html",
            controller: "adminClientEditCtrl",

        })
        .state("app.admin.users", {
            url: "/users",
            templateUrl: "app/pages/admin/users/users.tpl.html",
            controller: "adminUserCtrl",
            data: {
                access: ["ADMIN", "USER"],
            },
        })
        .state("app.admin.users.add", {
            url: "/add",
            templateUrl: "app/pages/admin/users/user.add.tpl.html",
            controller: "adminUserAddCtrl",
        })
        .state("app.admin.users.edit", {
            url: "/edit/:userId",
            templateUrl: "app/pages/admin/users/user.edit.tpl.html",
            controller: "adminUserEditCtrl",
        })
        .state("app.admin.products", {
            url: "/products",
            templateUrl: "app/pages/admin/products/products.tpl.html",
            controller: "adminProductsCtrl",
            data: {
                access: ["ADMIN", "PRODUCT"],
            },
        })
        .state("app.admin.products.add", {
            url: "/add",
            templateUrl: "app/pages/admin/products/product.add.tpl.html",
            controller: "adminProductsAddCtrl",
        })
        .state("app.admin.products.edit", {
            url: "/edit/:productId",
            templateUrl: "app/pages/admin/products/product.edit.tpl.html",
            controller: "adminProductsEditCtrl",
        })
        .state("app.admin.stocks", {
            url: "/stocks",
            templateUrl: "app/pages/admin/stocks/stocks.tpl.html",
            controller: "adminStocksCtrl",
            data: {
                access: ["ADMIN", "STOCK"],
            },
        })
        .state("app.admin.stocks.add", {
            url: "/add",
            templateUrl: "app/pages/admin/stocks/stock.add.tpl.html",
            controller: "adminStockAddCtrl",
        })
        .state("app.admin.stocks.edit", {
            url: "/edit/:stockId",
            templateUrl: "app/pages/admin/stocks/stock.edit.tpl.html",
            controller: "adminStockEditCtrl",
        })
        .state("app.admin.packages", {
            url: "/packages",
            templateUrl: "app/pages/admin/packages/packages.tpl.html",
            controller: "adminPackagesCtrl",
            data: {
                access: ["ADMIN", "PACKAGES"],
            },
        })
        .state("app.admin.packages.add", {
            url: "/add",
            templateUrl: "app/pages/admin/packages/package.add.tpl.html",
            controller: "adminPackagesAddCtrl",
        })
        .state("app.admin.packages.edit", {
            url: "/edit/:packageId",
            templateUrl: "app/pages/admin/packages/package.edit.tpl.html",
            controller: "adminPackagesEditCtrl",
        })
        .state("app.admin.zones", {
            url: "/zones",
            templateUrl: "app/pages/admin/zones/zones.tpl.html",
            controller: "adminZonesCtrl",
            data: {
                access: ["ADMIN", "ZONE"],
            },
        })
        .state("app.admin.zones.add", {
            url: "/add",
            templateUrl: "app/pages/admin/zones/zone.add.tpl.html",
            controller: "adminZonesAddCtrl",
        })
        .state("app.admin.zones.edit", {
            url: "/edit/:zoneId",
            templateUrl: "app/pages/admin/zones/zone.edit.tpl.html",
            controller: "adminZonesEditCtrl",
        })
        .state("app.admin.claims", {
            url: "/claims",
            templateUrl: "app/pages/admin/claims/claims.tpl.html",
            controller: "adminClaimsCtrl",
        })
        .state("app.admin.claims.add", {
            url: "/add",
            templateUrl: "app/pages/admin/claims/claim.add.tpl.html",
            controller: "adminClaimAddCtrl",
        })
        .state("app.admin.claims.detail", {
            url: "/detail/:claimId",
            templateUrl: "app/pages/admin/claims/claim.detail.tpl.html",
            controller: "adminClaimDetailCtrl",
        })
        .state("app.admin.documents", {
            url: "/documents",
            templateUrl: "app/pages/admin/documents/document.tpl.html",
            controller: "adminDocumentsCtrl",
            data: {
                access: ["ADMIN", "DOCUMENT"],
            },
        })
        .state("app.admin.roles", {
            url: "/roles",
            templateUrl: "app/pages/admin/roles/role.tpl.html",
            controller: "adminRoleCtrl",
            data: {
                access: ["ADMIN", "ROLE"],
            },
        })
        .state("app.admin.roles.add", {
            url: "/add",
            templateUrl: "app/pages/admin/roles/role.add.tpl.html",
            controller: "adminRoleAddCtrl",
        })
        .state("app.admin.roles.edit", {
            url: "/edit/:roleId",
            templateUrl: "app/pages/admin/roles/role.edit.tpl.html",
            controller: "adminRoleEditCtrl",
        })
        .state("app.admin.actionlogs", {
            url: "/actionlogs",
            templateUrl: "app/pages/admin/actionlogs/actionlogs.tpl.html",
            controller: "actionLogsCtrl",
            data: {
                access: ["ADMIN", "ACTIONS_LOG"],
            },
        })
        .state("app.admin.invoiceConnector", {
            url: "/invoiceConnector",
            templateUrl: "app/pages/admin/invoiceConnector/invoiceConnector.tpl.html",
            controller: "invoiceConnectorCtrl",
            data: {
                access: ["ADMIN", "INVOICE_CONNECTOR"],
            },
        })
        .state("app.admin.invoiceConnector.products", {
            url: "/productSynchrone",
            templateUrl: "app/pages/admin/invoiceConnector/productSynchrone.tpl.html",
            controller: "productSynchroneCtrl",
        })
        .state("app.admin.invoiceConnector.plateforme", {
            url: "/plateformeSynchrone",
            templateUrl: "app/pages/admin/invoiceConnector/plateformeSynchrone.tpl.html",
            controller: "plateformeSynchroneCtrl",
        })
        .state("app.admin.invoiceConnector.zone", {
            url: "/zoneSynchrone",
            templateUrl: "app/pages/admin/invoiceConnector/zoneSynchrone.tpl.html",
            controller: "zoneSynchroneCtrl",
        })
        .state("app.admin.invoiceConnector.pv", {
            url: "/pvSynchrone",
            templateUrl: "app/pages/admin/invoiceConnector/pvSynchrone.tpl.html",
            controller: "pvSynchroneCtrl",
        })
        .state("app.admin.invoiceConnector.client", {
            url: "/clientSynchrone",
            templateUrl: "app/pages/admin/invoiceConnector/clientSynchrone.tpl.html",
            controller: "clientSynchroneCtrl",
        })
        .state("app.home.claims", {
            url: "/claims",
            templateUrl: "app/pages/home/claims/claims.tpl.html",
            controller: "homeClaimsCtrl",
            data: {
                access: ["HOME", "CLAIM"],
            },
            params: {
                filter: null,
                platform: null,
            },
        })
        .state("app.home.claims.add", {
            url: "/add",
            templateUrl: "app/pages/home/claims/claim.add.tpl.html",
            controller: "homeClaimAddCtrl",
        })
        .state("app.home.claims.detail", {
            url: "/detail/:claimId",
            templateUrl: "app/pages/home/claims/claim.detail.tpl.html",
            controller: "homeClaimDetailCtrl",
        })
        .state("app.home.claims.edit", {
            url: "/edit/:claimId",
            templateUrl: "app/pages/home/claims/claim.edit.tpl.html",
            controller: "homeClaimEditCtrl",
        })
        .state("app.admin.installations", {
            url: "/installations",
            templateUrl: "app/pages/admin/installations/installations.tpl.html",
            controller: "adminInstallationsCtrl",
        })
        .state("app.admin.installations.details", {
            url: "/detail/:id",
            templateUrl: "app/pages/admin/installations/installationDetailsAdmin.tpl.html",
            controller: "installationDetailsAdminCtrl",
        })
        .state("app.admin.settings", {
            url: "/settings",
            templateUrl: "app/pages/admin/settings/settings.tpl.html",
            controller: "adminSettingCtrl",
            data: {
                access: ["ADMIN", "SETTING"],
            },
        })
        .state("app.admin.settings.add", {
            url: "/add",
            templateUrl: "app/pages/admin/settings/settings.add.tpl.html",
            controller: "settingsAddCtrl",
        })
        .state("app.admin.settings.edit", {
            url: "/edit/:id",
            templateUrl: "app/pages/admin/settings/settings.edit.tpl.html",
            controller: "settingsEditCtrl",
        })
        .state("app.admin.status", {
            url: "/status",
            templateUrl: "app/pages/admin/status/status.tpl.html",
            controller: "adminStatusCtrl",
            data: {
                access: ["ADMIN", "STATUS"],
            },
        })
        .state("app.admin.status.add", {
            url: "/add",
            templateUrl: "app/pages/admin/status/status.add.tpl.html",
            controller: "statusAddCtrl",
        })
        .state("app.admin.status.edit", {
            url: "/edit/:statusId",
            templateUrl: "app/pages/admin/status/status.edit.tpl.html",
            controller: "statusEditCtrl",
        })
        .state("app.admin.workflow", {
            url: "/workflow",
            templateUrl: "app/pages/admin/workflow/workflow.tpl.html",
            controller: "adminWorkflowCtrl",
            data: {
                access: ["ADMIN", "WORKFLOW"],
            },
        })
        .state("app.admin.workflow.add", {
            url: "/add",
            templateUrl: "app/pages/admin/workflow/workflow.add.tpl.html",
            controller: "workflowAddCtrl",
        })
        .state("app.admin.workflow.edit", {
            url: "/edit/:workflowId",
            templateUrl: "app/pages/admin/workflow/workflow.edit.tpl.html",
            controller: "workflowEditCtrl",
        })
        .state("app.admin.dashboard", {
            url: "/dashboard",
            templateUrl: "app/pages/admin/dashboard/dashboard.tpl.html",
            controller: "adminDashboardCtrl",
            data: {
                access: ["ADMIN", "DASHBOARD"],
            },
        })
        .state("app.admin.dashboard.edit", {
            url: "/edit/:platformId",
            templateUrl: "app/pages/admin/dashboard/dashboard.edit.tpl.html",
            controller: "dashboardEditCtrl",
        })
        .state("app.monitoring.statistic", {
            url: "/stats",
            templateUrl: "app/pages/stats/statistic.tpl.html",
            controller: "statisticCtrl",
            data: {
                access: ["MONITORING", "STATISTIC_PLATFORM"],
            },
        })
        .state("app.accounts", {
            url: "/accounts",
            views: {
                content: {
                    templateUrl: "app/pages/subscriptions/accounts.tpl.html",
                    controller: "accountsCtrl",
                },
            },
            data: {
                access: ["ACCOUNTS_SUBSCRIPTION", "ACCOUNTS_SUBSCRIPTION"],
            },
        })
        .state("app.accounts.subscriptions", {
            url: "/subscription",
            templateUrl: "app/pages/subscriptions/subscription.tpl.html",
            controller: "subscriptionsCtrl",
            data: {
                access: ["ACCOUNTS_SUBSCRIPTION", "SUBSCRIPTION"],
            },
            params: {
                filter: null,
                platform: null,
            },
        })
        .state("app.accounts.subscriptions.detail", {
            url: "/detail/:clientId",
            templateUrl: "app/pages/subscriptions/subscription.details.tpl.html",
            controller: "subscriptionsDetailCtrl",
        })
        .state("app.accounts.clients", {
            url: "/clients",
            templateUrl: "app/pages/subscriptions/client.tpl.html",
            controller: "clientCtrl",
            data: {
                access: ["ACCOUNTS_SUBSCRIPTION", "ACCOUNTS"],
            },
        })
        .state("app.accounts.clients.details", {
            url: "/:id",
            templateUrl: "app/pages/subscriptions/client.details.tpl.html",
            controller: "clientDetailsCtrl",
        })
        .state("app.stocks", {
            url: "/stock",
            views: {
                content: {
                    templateUrl: "app/pages/stocks/stocks.tpl.html",
                    controller: "stocksCtrl",
                },
            },
            data: {
                access: ["STOCK_MANAGEMENT", "STOCK_MANAGEMENT"],
            },
        })
        .state("app.stocks.articles", {
            url: "/articles",
            templateUrl: "app/pages/stocks/articles.tpl.html",
            controller: "articlesCtrl",
            data: {
                access: ["STOCK_MANAGEMENT", "ARTICLE"],
            },
        })
        .state("app.stocks.articles.add", {
            url: "/add",
            templateUrl: "app/pages/stocks/article.add.tpl.html",
            controller: "articleAddCtrl",
        })
        .state("app.stocks.articles.edit", {
            url: "/edit/:articleId",
            templateUrl: "app/pages/stocks/article.edit.tpl.html",
            controller: "articleEditCtrl",
        })
        .state("app.stocks.stockMovement", {
            url: "/stockMovement",
            templateUrl: "app/pages/stocks/stockMovement.tpl.html",
            controller: "stockMovementCtrl",
            data: {
                access: ["STOCK_MANAGEMENT", "STOCK_MOVEMENT"],
            },
        })
        .state("app.stocks.stockMovement.detail", {
            url: "/detail/:stockMovementId",
            templateUrl: "app/pages/stocks/stockMovement.detail.tpl.html",
            controller: "stockMovementDetailsCtrl",
        })
        .state("app.stocks.status", {
            url: "/status",
            templateUrl: "app/pages/stocks/stockStatus.tpl.html",
            controller: "stockStatusCtrl",
            data: {
                access: ["STOCK_MANAGEMENT", "STOCK"],
            },
        })
        .state("app.stocks.reception", {
            url: "/reception",
            templateUrl: "app/pages/stocks/reception.tpl.html",
            controller: "receptionCtrl",
            data: {
                access: ["STOCK_MANAGEMENT", "RECEIPT_SLIP"],
            },
        })
        .state("app.stocks.reception.add", {
            url: "/add",
            templateUrl: "app/pages/stocks/reception.add.tpl.html",
            controller: "receptionAddCtrl",
        })
        .state("app.stocks.reception.edit", {
            url: "/edit/:receptionId",
            templateUrl: "app/pages/stocks/reception.edit.tpl.html",
            controller: "receptionEditCtrl",
        })
        .state("app.stocks.reception.details", {
            url: "/details/:receptionId",
            templateUrl: "app/pages/stocks/reception.details.tpl.html",
            controller: "receptionDetailsCtrl",
        })
        .state("app.stocks.deliverySlip", {
            url: "/deliverySlip",
            templateUrl: "app/pages/stocks/deliverySlip.tpl.html",
            controller: "deliverySlipCtrl",
            data: {
                access: ["STOCK_MANAGEMENT", "DELIVERY_SLIP"],
            },
        })
        .state("app.stocks.deliverySlip.add", {
            url: "/add",
            templateUrl: "app/pages/stocks/deliverySlip.add.tpl.html",
            controller: "deliverySlipAddCtrl",
        })
        .state("app.stocks.deliverySlip.edit", {
            url: "/edit/:deliverySlipId",
            templateUrl: "app/pages/stocks/deliverySlip.edit.tpl.html",
            controller: "deliverySlipEditCtrl",
        })
        .state("app.stocks.deliverySlip.details", {
            url: "/details/:deliverySlipId",
            templateUrl: "app/pages/stocks/deliverySlip.detail.tpl.html",
            controller: "deliverySlipDetailCtrl",
        })
        .state("app.stocks.goodsReturnSlip", {
            url: "/goodsReturnSlip",
            templateUrl: "app/pages/stocks/goodsReturnSlip.tpl.html",
            controller: "goodsReturnSlipCtrl",
            data: {
                access: ["STOCK_MANAGEMENT", "GOODS_RETURN_SLIP"],
            },
        })
        .state("app.stocks.goodsReturnSlip.add", {
            url: "/add",
            templateUrl: "app/pages/stocks/goodsReturnSlip.add.tpl.html",
            controller: "goodsReturnSlipAddCtrl",
        })
        .state("app.stocks.goodsReturnSlip.edit", {
            url: "/edit/:goodsReturnSlipId",
            templateUrl: "app/pages/stocks/goodsReturnSlip.edit.tpl.html",
            controller: "goodsReturnSlipEditCtrl",
        })
        .state("app.home.installValidation", {
            url: "/installValidation",
            templateUrl: "app/pages/home/installation-validation/installation.validation.tpl.html",
            controller: "installValidationCtrl",
            params: {
                filter: null,
                platform: null,
            },
            data: {
                access: ["HOME", "INSTALLATION_VALIDATION"],
            },
        })
        .state("app.home.installValidation.edit", {
            url: "/edit/:installValidationId",
            templateUrl: "app/pages/home/installation-validation/installation.validation.edit.tpl.html",
            controller: "installValidationEditCtrl",
        })
        .state("app.home.installValidation.details", {
            url: "/details/:installValidationId",
            templateUrl: "app/pages/home/installation-validation/installation.validation.details.tpl.html",
            controller: "installValidationDetailsCtrl",
        })
        .state("app.home.interventionValidation", {
            url: "/interventionValidation",
            templateUrl: "app/pages/home/intervention-validation/intervention.validation.tpl.html",
            controller: "interventionValidationCtrl",
            params: {
                filter: null,
                platform: null,
            },
            data: {
                access: ["HOME", "INTERVENTION_VALIDATION"],
            },
        })
        .state("app.home.interventionValidation.edit", {
            url: "/edit/:interventionValidationId",
            templateUrl: "app/pages/home/intervention-validation/intervention.validation.edit.tpl.html",
            controller: "interventionValidationEditCtrl",
        })
        .state("app.home.interventionValidation.details", {
            url: "/details/:interventionValidationId",
            templateUrl: "app/pages/home/intervention-validation/intervention.validation.details.tpl.html",
            controller: "interventionValidationDetailsCtrl",
        })
        .state("app.stocks.deliveryRequest", {
            url: "/deliveryRequest",
            templateUrl: "app/pages/stocks/deliveryRequest.tpl.html",
            controller: "deliveryRequestCtrl",
            data: {
                access: ["STOCK_MANAGEMENT", "DELIVERY_REQUEST"],
            },
        })
        .state("app.stocks.deliveryRequest.details", {
            url: "/details/:deliveryRequestId",
            templateUrl: "app/pages/stocks/deliveryRequest.details.tpl.html",
            controller: "deliveryRequestDetailsCtrl",
        })
        .state("app.stocks.deliveryRequest.infos", {
            url: "/infos/:deliveryRequestId",
            templateUrl: "app/pages/stocks/deliveryRequest.infos.tpl.html",
            controller: "deliveryRequestInfosCtrl",
        })
        .state("app.stocks.deliveryRequest.convert", {
            url: "/convert/:deliveryRequestId",
            templateUrl: "app/pages/stocks/deliveryRequest.convert.tpl.html",
            controller: "deliveryRequestConvertCtrl",
        })
        .state("app.admin.product_code", {
            url: "/product_code",
            templateUrl: "app/pages/admin/productCode/productCode.tpl.html",
            controller: "adminProductCodeCtrl",
            data: {
                access: ["ADMIN", "PRODUCT_CODE"],
            },
        })
        .state("app.admin.product_code.add", {
            url: "/add",
            templateUrl: "app/pages/admin/productCode/productCode.add.tpl.html",
            controller: "productCodeAddCtrl",
        })
        .state("app.admin.product_code.edit", {
            url: "/edit/:productCodeId",
            templateUrl: "app/pages/admin/productCode/productCode.edit.tpl.html",
            controller: "productCodeEditCtrl",
        });
}