angular.module('app').controller('adminDocumentsCtrl', function ($scope, $rootScope, $state, $translate, $filter, Notification, ngDialog, I3DocumentSrv, i3HelperSrv, messageBox, I3UserSrv) {

    $scope.itemsByPage = 50;
    $scope.search = {};

    /**
     * Getting users
     */
    $scope.loadDocuments = function (tableState, ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }

        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;

        var query = {};
        var options = {
            populate: "_created_by"
        }
        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            start = pagination.start / number + 1 || 1;
        }

        if (!$scope.search.original_name) {
            if (tableState.search && tableState.search.predicateObject) {
                if (tableState.search.predicateObject.original_name) {
                    $scope.search.original_name = tableState.search.predicateObject.original_name
                    query["original_name"] = {
                        "$regex": tableState.search.predicateObject.original_name,
                        "$options": "i"
                    };
                }
            } else {
                query.original_name = {
                    '$regex': '',
                    '$options': 'i'
                }
            }
        } else {
            if (tableState.search && tableState.search.predicateObject) {
                tableState.search.predicateObject.original_name = $scope.search.original_name
            }
            query.original_name = {
                '$regex': $scope.search.original_name,
                '$options': 'i'
            }
        }
        if (!$scope.search.fileName) {
            if (tableState.search && tableState.search.predicateObject) {
                if (tableState.search.predicateObject.fileName) {
                    $scope.search.fileName = tableState.search.predicateObject.fileName
                    query.fileName = {
                        '$regex': tableState.search.predicateObject.fileName,
                        '$options': 'i'
                    }
                }
            } else {
                query.fileName = {
                    '$regex': '',
                    '$options': 'i'
                }
            }
        } else {
            if (tableState.search && tableState.search.predicateObject) {
                tableState.search.predicateObject.fileName = $scope.search.fileName
            }
            query.fileName = {
                '$regex': $scope.search.fileName,
                '$options': 'i'
            }
        }

        if (tableState.search && tableState.search.predicateObject) {
            $scope.search.original_name = tableState.search.predicateObject.original_name
        }
        if (tableState.search && tableState.search.predicateObject) {
            $scope.search.fileName = tableState.search.predicateObject.fileName
        }

        if ($scope.search && $scope.search._created_by) {
            query["_created_by"] = $scope.search._created_by._id;
        }

        I3DocumentSrv.query({
            query: query,
            options: options
        }, number, start, function (response) {
            if (response.success) {
                $scope.documents = response.result;
                $scope.documentCount = response.total_count;
                tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
                tableState.pagination.totalItemCount = response.total_count;
                tableState.pagination.start = start * number - 1 >= 0 ? start * number - 1 : 0;
                $scope.tableState = tableState;
            }
        });
    };

    /**
     * Removing product
     * @param product
     */
    $scope.remove = function (doc) {

        messageBox.confirm("DELETE", "DELETE_DOCUMENT_MESSAGE").then(function (res) {

            if (res) {
                I3DocumentSrv.delete(doc._id, function (res) {
                    if (res && res.success) {
                        Notification.success($translate.instant("DOCUMENT_DELETED"));
                        if ($scope.stCtrl) {
                            $scope.stCtrl.pipe();
                        }
                    } else {
                        Notification.warn($translate.instant("DOCUMENT_DELETE_ERROR"));
                    }

                }, function (err) {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("DOCUMENT_DELETE_ERROR")
                    });
                })

            }

        }, function (reject) {

        })

    };

    $scope.searchUser = function ($select) {

        I3UserSrv.query({
            query: {
                $or: [{
                        login: {
                            '$regex': $select.search,
                            '$options': 'i'
                        }
                    },
                    {
                        first_name: {
                            '$regex': $select.search,
                            '$options': 'i'
                        }
                    },
                    {
                        last_name: {
                            '$regex': $select.search,
                            '$options': 'i'
                        }
                    }
                ]
            }
        }, 10, 0, function (res) {

            $scope.users = res.result;
        }, function (err) {

        })
    }
    $scope.downloadFile = function (file) {
        i3HelperSrv.downloadFile(file._id, file.original_name, function success(res) {

        }, function error(err) {
            Notification.error({
                title: $translate.instant("ERROR"),
                message: $translate.instant(err)
            });
        }, function progress(evt) {
            //console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '%');
        })
    }

});