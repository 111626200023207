angular.module('app').controller("importCompatibiliteDlgCtrl", function ($scope,$window, $state, I3ClientSrv, $rootScope,ngDialog, $stateParams, CompatibilityUtilsSrv, Notification, $translate) {
    $scope.language=($window.sessionStorage&& $window.sessionStorage.lang!=='undefined' )? $window.sessionStorage.lang:'fr';
   

    $scope.compatibility = {}
    $scope.date = new Date
    $scope.compatibility._plateforme=($rootScope.SESSION.user._plateforme) ? $rootScope.SESSION.user._plateforme:null



    $scope.selectPlateform = function (platform) {


        I3ClientSrv.query({
            query: {
                _plateforme: platform._id
            },
            options: { populate: '_plateforme' }
        }, 10, 1, function (res) {
            if (res && res.result) {
                $scope.clients = res.result;

            }
        })




    }
    $scope.searchClient = function ($select) {


        if ($scope.compatibility._plateforme && $scope.compatibility._plateforme._id) {
            I3ClientSrv.query({
                query: {

                    _plateforme: $scope.compatibility._plateforme._id,
                    name: {
                        '$regex': $select.search,
                        '$options': 'i'
                    }
                }
            }, 10, 0, function (res) {
                if (res.result && res.result.length > 0) {
                    $scope.clients = res.result;
                }

            }, function (err) {

            })
        }

    };





    $scope.save = function (compatibility) {

        compatibility.clientSav_id = compatibility.client._id;
        compatibility.code_client = compatibility.client.code_client || null;
        compatibility.client = compatibility.client.name;
        $scope.closeThisDialog(compatibility);


    }

    $scope.addClient = function () {

        ngDialog.open({
            templateUrl: "app/commons/dialogs/compatibilty/client.add.dlg.tpl.html",
            className: 'ngdialog-theme-default custom-width-500',
            controller: "addClientCompatibiltyDlgCtrl",
            appendClassName: 'ngdialog-custom',
            scope: $scope,


        })

    }

})