angular.module('app').controller('installationRequestDashboardCtrl', function ($rootScope, $scope, I3InstallationGroupSrv, $translate, Notification) {

    $scope.selectedPeriod = 'month';
    var now = moment().clone();

    $scope.startRes = moment().startOf("day").clone();
    $scope.endRes = now;
    $scope.dayRes = now;
    $scope.monthRes = now;
    $scope.pickerStart = {
        format: "DD/MM/YYYY",
        init: moment().startOf("day").clone(),
        position: "top left",
        autoTrans: false,
        enableTime: false,
        enableDate: true,
        enableMinutes: false,
        defaultView: "DATE",
        getDate: function (date) {
            $scope.startRes = date;
            $scope.pickerEnd.minDate = date;
            getInstallationsData();
        }
    };

    $scope.pickerEnd = {
        format: "DD/MM/YYYY",
        position: "top left",
        init: now,
        autoTrans: false,
        enableTime: false,
        enableDate: true,
        enableMinutes: false,
        defaultView: "DATE",
        getDate: function (date) {
            $scope.endRes = date;
            $scope.pickerStart.maxDate = date;
            getInstallationsData();
        }
    };

    $scope.pickerDay = {
        format: "DD/MM/YYYY",
        position: "top left",
        init: now,
        autoTrans: false,
        enableTime: false,
        enableDate: true,
        enableMinutes: false,
        defaultView: "DATE",
        getDate: function (date) {
            $scope.dayRes = date;
            getInstallationsData();
        }
    };

    $scope.pickerMonth = {
        name: "pickerMonth",
        format: "MM/YYYY",
        position: "top left",
        init: now,
        autoTrans: false,
        enableTime: false,
        enableDate: true,
        enableMinutes: false,
        defaultView: "MONTH",
        getDate: function (date) {
            $scope.monthRes = date;
            getInstallationsData();
        }
    };

    $scope.openCalendar = function (e, picker) {
        $scope[picker].open = true;
    };

    $scope.selectPeriod = function (period) {
        $scope.selectedPeriod = period;
        if ($scope.selectedPeriod === "month") {
            getInstallationsData();
        } else if ($scope.selectedPeriod === "day") {
            getInstallationsData();
        } else if ($scope.selectedPeriod === "week") {
            getInstallationsData();
        } else if ($scope.selectedPeriod === "free") {
            getInstallationsData();
        }
    };

    var getQuery = function () {

        var match = {};
        var agg = getDailyQuery();

        if ($scope.selectedPeriod === 'day') {
            match = {

                "creation_dt": {
                    "$gt": '#ISODATE#' + $scope.dayRes.clone().startOf('day').format('YYYY-MM-DD') + "T00:00:00.000Z" + '#ISODATEEND#',
                    "$lt": '#ISODATE#' + $scope.dayRes.clone().add('1', 'days').format('YYYY-MM-DD') + "T00:00:00.000Z" + '#ISODATEEND#'
                }

            }

        } else if ($scope.selectedPeriod === 'week') {
            match = {

                "creation_dt": {
                    "$gt": '#ISODATE#' + $scope.dayRes.clone().subtract(7, 'd').format('YYYY-MM-DD') + "T00:00:00.000Z" + '#ISODATEEND#',
                    "$lt": '#ISODATE#' + $scope.dayRes.clone().format('YYYY-MM-DD') + "T00:00:00.000Z" + '#ISODATEEND#'
                }

            };
        } else if ($scope.selectedPeriod === 'month') {
            match = {

                "creation_dt": {
                    "$gt": '#ISODATE#' + $scope.monthRes.clone().startOf('month').format('YYYY-MM-DD') + "T00:00:00.000Z" + '#ISODATEEND#',
                    "$lt": '#ISODATE#' + $scope.monthRes.clone().endOf('month').format('YYYY-MM-DD') + "T00:00:00.000Z" + '#ISODATEEND#'
                }

            };
        } else if ($scope.selectedPeriod === 'free') {
            match = {

                "creation_dt": {
                    "$gt": '#ISODATE#' + $scope.startRes.clone().format('YYYY-MM-DD') + "T00:00:00.000Z" + '#ISODATEEND#',
                    "$lt": '#ISODATE#' + $scope.endRes.clone().add('1', 'days').format('YYYY-MM-DD') + "T00:00:00.000Z" + '#ISODATEEND#'
                }

            };
        } else {
            match = {

                "creation_dt": {
                    "$gt": '#ISODATE#' + $scope.startRes.clone().format('YYYY-MM-DD') + "T00:00:00.000Z" + '#ISODATEEND#',
                    "$lt": '#ISODATE#' + $scope.endRes.clone().add('1', 'days').format('YYYY-MM-DD') + "T00:00:00.000Z" + '#ISODATEEND#'
                }

            };
        }

        var query = [{
                "$match": match
            },
            {
                "$project": {
                    "client_name": "$client_name",
                    "status": "$status",
                    "_installationIds": "$_installationIds",
                    'date': agg.project
                }
            },

            {
                $lookup: {
                    from: "installation",
                    "localField": "_installationIds",
                    "foreignField": "_id",
                    as: "_installationIds"
                }
            },
            {
                $group: {
                    _id: {
                        'status': '$status',
                        'client_name': '$client_name'
                    },

                    "count": {
                        "$sum": 1
                    },

                    "data": {
                        $push: {
                            "status": "$status",
                            "client_name": "$client_name",
                            "_installationIds": "$_installationIds",
                            "count": {
                                "$sum": 1
                            }

                        }
                    }

                }
            },
            {
                "$sort": {
                    "_id": 1
                }
            }

        ]

        return query
    };

    var getDailyQuery = function () {
        return {

            "project": {

                "y": {
                    "$year": {
                        "$add": ["$creation_dt", moment().utcOffset() * 60 * 1000]
                    }
                },
                "m": {
                    "$month": {
                        "$add": ["$creation_dt", moment().utcOffset() * 60 * 1000]
                    }
                },
                "d": {
                    "$dayOfMonth": {
                        "$add": ["$creation_dt", moment().utcOffset() * 60 * 1000]
                    }
                }

            },
            "group": {
                "y": "$date.y",
                "m": "$date.m",
                "d": "$date.d"
            }

        }
    };

    var getInstallationsData = function () {
        var claims = [];
        var histoData = [];
        I3InstallationGroupSrv.agg({
            aggregate: getQuery()
        }, 100000, 0, function (res) {

            $scope.inProgress = [];
            $scope.installationsPerDay = [];
            $scope.statusNew = [];
            $scope.statusPlanned = [];
            $scope.statusInProgress = [];
            $scope.statusDone = [];
            $scope.installationRequestInProgres = 0
            $scope.installationRequestDone = 0
            $scope.installationRequestCreated = 0
            $scope.progress = 0
            $scope.seriesPl = [];
            try {
                if (res && res.success && res.result && res.result.length > 0) {
                    claims = angular.copy(res.result);
                    var statusGroupped = {};
                    var clientGroup = {};
                    claims.forEach(function (value) {

                        if (!statusGroupped[value._id.status]) statusGroupped[value._id.status] = 0
                        statusGroupped[value._id.status] += value.count;

                        if (!clientGroup[value._id.client_name]) clientGroup[value._id.client_name] = {
                            Done: 0,
                            total: 0,
                            InProgress: 0,
                            "New": 0,
                            nbInstallDone: 0,
                            nbInstallTotal: 0
                        };

                        clientGroup[value._id.client_name].total += value.count;
                        //console.log(value._id.status)
                        if (value._id.status == "New") {
                            clientGroup[value._id.client_name].New += value.count;
                        } else if (value._id.status == "InProgress") {
                            clientGroup[value._id.client_name].InProgress += value.count;
                        } else if (value._id.status == "Done") {
                            clientGroup[value._id.client_name].Done += value.count;
                        }

                        if (value.data && value.data) {

                            value.data.forEach(function (data) {
                                if (data && data._installationIds && data._installationIds.length > 0) {
                                    clientGroup[value._id.client_name].nbInstallTotal += data._installationIds.length;
                                    data._installationIds.forEach(function (inst) {
                                        if (inst && inst.status === "Done") {
                                            clientGroup[value._id.client_name].nbInstallDone++;
                                        }
                                    })
                                }
                            })
                        }

                    });

                  //  console.log(clientGroup)
                    var installationsRequest = [];
                    Object.keys(clientGroup).map(function (key) {

                        var elem = clientGroup[key];
                        elem.client_name = key;
                        if (elem.nbInstallTotal > 0) {
                            elem.progress = 100 * elem.nbInstallDone / elem.nbInstallTotal;
                        } else {
                            elem.progress = 0;
                        }
                        installationsRequest.push(elem);
                    })
                    $scope.installationsRequests = installationsRequest;

                    var counter1 = 0,
                        counter2 = 0,
                        counter3 = 0,
                        counter4 = 0;

                    Object.keys(statusGroupped).forEach(function (key) {

                        if (key == 'New') {
                            counter1 = statusGroupped[key];
                        }
                        if (key == 'Planned') {
                            counter2 = statusGroupped[key];;
                        }
                        if (key == 'InProgress') {
                            counter3 = statusGroupped[key];
                        }
                        if (key == 'Done') {
                            counter4 = statusGroupped[key];
                        }
                        $scope.installationRequestInProgres = counter3 + counter1
                        $scope.installationRequestDone = counter4
                        $scope.installationRequestCreated = counter1 + counter2 + counter4
                        var elem = {};

                        if (key) {
                            elem.name = $translate.instant(key);

                            if (Number.isFinite(statusGroupped[key])) {
                                elem.y = statusGroupped[key]
                            }
                            $scope.seriesPl.push(elem)
                        }
                    });

                    $scope.showChart();

                } else if (res && res.success) {
                    $scope.showChart();
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("NO_DATA_PERIOD")
                    });
                } else {
                    Notification.error({
                        title: $translate.instant("ERROR"),
                        message: $translate.instant("LOADING_ERROR")
                    });
                }
            } catch (e) {
                console.log(e);
            }
        }, function (err) {});

    };

    $scope.showChart = function () {
        Highcharts.chart('chart', {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: 'Demandes d' / 'installations'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                        style: {
                            color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                        }
                    }
                }
            },

            series: [{
                name: "demande d'installation",
                colorByPoint: true,
                data: $scope.seriesPl
            }],
            credits: {
                enabled: false
            }
        });

    }

});