angular.module('app').controller('productCodeEditCtrl',function ($scope,$stateParams,$translate,ngDialog,I3ProductCodeSrv, Notification,$state) {
    $scope.status = {};
    $scope.Models =["COMPATIBILITY","COMPATIBILITY_STUDY","INSTALLATION_REQUEST","INSTALLATION","INTERVENTION","CLAIM"]

    I3ProductCodeSrv.query({
        query: {
            _id: $stateParams.productCodeId
        },
        options: {}
    }, 1, 0, function (res) {
        if (res && res.result) {
            $scope.productCode = res.result[0];
            var productType={}
            $scope.PRODUCT_TYPES.forEach(function(e){
                if(e.code==$scope.productCode.product_type){
                    productType=e
                }

            })
            // console.log($scope.productCode)
            $scope.productCode.product_type=productType;

        }
    })

    $scope.update=function(){

            var productCode = angular.copy($scope.productCode);
            productCode.product_type=productCode.product_type.code;
            I3ProductCodeSrv.updateById(productCode._id,productCode,function(res){
                if(res.success){
                    Notification.success({
                        title: $translate.instant("PRODUCT_CODE_UPDATED"),
                        message: $translate.instant("PRODUCT_CODE_UPDATED_SUCCESS")
                    });
                    $state.go('app.admin.product_code');

                }else{
                    if(res.error && res.error.details && res.error.details.code==11000){
                        Notification.error({
                            title: $translate.instant('ERROR'),
                            message: $translate.instant("PRODUCT_CODE_DUPPLICATED") + " " + $scope.productCode._id + "!"
                        });
                    }else{
                        Notification.error({
                            title: $translate.instant('ERROR'),
                            message: $translate.instant("ERROR_OCCURED_UPDATE") + " " + $scope.productCode._id + "!"
                        });
                    }


                }
            },function(er){
                Notification.error({
                    title: $translate.instant('ERROR'),
                    message: $translate.instant("ERROR_OCCURED_UPDATE") + " " + $scope.productCode._id + "!"
                });
            })


    }
});