angular.module('app').controller("publicLinkDlgCtrl", function ($scope, I3ProductSrv, access, installationRequest, CONFIG, Notification) {

    try {
        if (access && access.token && access.key && installationRequest._id) {
            $scope.link = (CONFIG.PUBLIC_LINK_URL + "?id=" + encodeURIComponent(installationRequest._id) + "&key=" + encodeURIComponent(access.key) + "&token=" + encodeURIComponent(access.token));

        } else {
            Notification.error({
                title: $translate.instant("ERROR"),
                message: $translate.instant("ERROR")
            });
        }
    } catch (e) {
        console.log(e)
    }

});