angular.module('app').controller('installationClientCtrl', function ($rootScope, $scope, $translate, I3ClientFormSrv, ngDialog, Notification, $state, $stateParams, messageBox, i3HelperSrv, I3DocumentSrv, countrySrv) {

    $scope.isValid = false
    $scope.countries = countrySrv.getCountryList();
    $scope.installations = [];
    var now = moment().clone();
    $scope.dayRes = now;

    $scope.pickerDay = {
        format: "DD/MM/YYYY",
        position: "top left",
        init: null,
        autoTrans: false,
        enableTime: false,
        enableDate: true,
        enableMinutes: false,
        defaultView: "DATE",
        maxDate: moment("2020/05/22", "YYYY/MM/DD"),
        minDate: now,
        getDate: function (date) {
            $scope.dayRes = date;
            $scope.installationRequest.preferred_dt = date;
        }
    };
    $scope.open2 = function () {
        $scope.popup2.opened = true;
    };
    $scope.popup2 = {
        opened: false
    };

    $scope.mode = "CREATE";
    $scope.installationRequest = {
        comments: [{
            owner: $rootScope.SESSION.user._id
        }]
    };

    $scope.addClient = function () {
        var dialog = ngDialog.open({
            template: 'app/commons/dialogs/installation/add.client.dlg.tpl.html',
            controller: 'addClientDlgCtrl',
            width: '800px',
            scope: $scope
        });
        dialog.closePromise.then(function (data) {
            if (data && data.value && data.value._id) {
                $scope.installationRequest.client_id = data.value;
                if (data.value.contact) {

                    if (!$scope.installationRequest.contact) $scope.installationRequest.contact = {};
                    $scope.installationRequest.contact.fax = data.value.contact.fax;
                    $scope.installationRequest.contact.email = data.value.contact.mail;
                    $scope.installationRequest.contact.phone = data.value.contact.phone;

                }
            }
        });
    };

    $scope.cancel = function () {
        $scope.installationRequest = {}
    };

    /**
     * Getting files
     */
    $scope.getFiles = function (files) {
        I3DocumentSrv.query({
            query: {
                _id: {
                    '$in': files
                }
            }
        }, 10000, 1, function (res) {
            if (res.success) {
                $scope.docs = res.result;
            }
        }, function (err) {
            console.log(err);
        });
    };
    if ($scope.mode === 'CREATE') {
        $scope.installationRequest.docs = [];
    }
    $scope.uploadFiles = function (files, errFiles) {
        $scope.files = files;
        $scope.errFiles = errFiles;
        i3HelperSrv.uploadFiles(files, {related_model:[{model:"INSTALLATION_REQUEST",model_id:$scope.installationRequest._id}]}, function (res) {
            angular.forEach(res.result, function (file) {
                $scope.installationRequest.docs.push(file)
            });
            file.result = res.data;
        }, function (response) {
            if (response.status)
                $scope.errorMsg = response.status + ': ' + response.data;
        }, function (evt) {

        });
    };

    $scope.downloadFile = function (file) {
        i3HelperSrv.downloadFile(file._id, file.original_name, function success(res) {

        }, function error(err) {
            Notification.error({
                title: $translate.instant("ERROR"),
                message: $translate.instant(err)
            });
        }, function progress(evt) {
            console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '%');
        })
    };

    $scope.save = function (installRequest) {
        var install = angular.copy(installRequest);
        install.installationIds = $scope.installations;
        if (install.installationIds._product) {
            install.installationIds.forEach(function (el) {
                el._product = el._product._id
            })
        }
        if (installRequest.client_data && installRequest.client_data._ctry) {
            install.client_data._ctry = $scope.installationRequest.client_data._ctry.code;
        }

        install.installations = [];
        var installation = {};
        for (var i = 0; i < install.installationIds.length; i++) {
            if (install.installationIds[i]._product) {
                if (!installation[install.installationIds[i]._product._id]) {
                    installation[install.installationIds[i]._product._id] = {
                        _product: install.installationIds[i]._product._id,
                        product_options: install.installationIds[i].product_options,
                        asset_count: 0
                    }
                }
                installation[install.installationIds[i]._product._id].asset_count += 1;
            }
        }
        install.installations = Object.values(installation)
        if (install.comments[0].msg && install.comments[0].msg.length > 0) {} else {
            delete install.comments;
        }

        if ($scope.documents) {
            install.docs = $scope.documents;
        }
        install.status = 'Filled';
        I3ClientFormSrv.add(install, function (res) {
            if (res && res.success) {
                $scope.isValid = true;
                Notification.success({
                    title: "Succès",
                    message: "La Demande est enregistrée"
                });
                $scope.installationRequest = {}
                $scope.installations = []
            } else {

                Notification.error({
                    title: $translate.instant("ERROR"),
                    message: $translate.instant("CHECK_DATA")
                });
            }
        })
    };

    $scope.addInstallation = function () {
        var dialog = ngDialog.open({
            template: 'app/commons/dialogs/client/client.installation.dlg.tpl.html',
            controller: 'clientInstallationDlgCtrl',
            scope: $scope
        });
        dialog.closePromise.then(function (data) {
            if (data.value !== '$document') {
                $scope.installations.push(data.value);
            }
        });
    };

    $scope.removeInstallation = function (install) {
        $scope.installations.splice($scope.installations.indexOf(install), 1)
    }

});