angular
  .module("app")
  .controller("historyCtrl", function ($scope, $rootScope, dashboardSrv, plateformHelperSrv, I3PlateformeSrv, $window, uiGridConstants, i18nService,$translate) {
    $scope.itemsByPage = 50;
    $scope.search = {};
    $scope.statusLoaded = false;
    $scope.year = moment().year();
    var refresh = function () {
      $scope.refresh = true;
      $timeout(function () {
        $scope.refresh = false;
      }, 0);
    };

    delete $window.sessionStorage.plateformSession
    delete $window.sessionStorage.plateformToken
    delete $window.sessionStorage.plateform
    $rootScope.selectedPlatform = ($rootScope.SESSION.user._plateforme || $window.sessionStorage.plateform) ? $rootScope.SESSION.user._plateforme : undefined;
    $scope.selectedPlatform = ($rootScope.selectedPlatform) ? $rootScope.selectedPlatform : {
      name: "ALL_PLATEFORMES"
    };

    /* var k = i18nService.getCurrentLang();
    console.log(k) */
    if($translate.use()=="fr")
    {
        i18nService.setCurrentLang('fr');
    }
    if($translate.use()=="en")
    {
        i18nService.setCurrentLang('en');
    }
    else
    {
        i18nService.setCurrentLang('fr');
    }
    $scope.$on('$viewContentLoaded', function (event, viewName, viewContent) {
      if (viewName) {
        //console.log("view loaded")
        //? (1) loading platform
        I3PlateformeSrv.query({
          query: {
            type: "IAXEED"
          }
        }, 10000, 0, function (res) {
          if (res && res.success) {
            $scope.platformList = res.result;
            $scope.platformList.push({
              name: "ALL_PLATEFORMES"
            })

            if ($scope.platformList && $scope.platformList.length > 0) {
              var plateform = null;
              if ($window.sessionStorage.plateform) {
                try {
                  plateform = JSON.parse($window.sessionStorage.plateform)
                } catch (e) {
                  logger.warn("invalid plateform from  cookie select first from list");
                  plateform = null;
                }
              }
              if (!plateform && $rootScope.checkAccess("PLATEFORME", "R", "O")) {
                plateform = $scope.platformList[0]
              }
              if (plateform) {
                for (var i = 0; i < $scope.platformList.length; i++) {
                  if (plateform._id === $scope.platformList[i]._id) {
                    $rootScope.selectedPlatform = $scope.platformList[i];
                    $scope.selectedPlatform = $scope.platformList[i];
                    break;
                  }
                }
              }
              if (!$rootScope.selectedPlatform) {
                $rootScope.selectedPlatform = $scope.selectedPlatform;
                // $rootScope.selectedPlatform = $scope.platformList[0];
                //$scope.selectedPlatform = $scope.platformList[0];

              }

              // if (iaxeedSocketSrv.closeListner && typeof iaxeedSocketSrv.closeListner === "function") {
              //   iaxeedSocketSrv.closeListner('newMsg');
              // }

              plateformHelperSrv.setPlateFormAndLogin($rootScope.selectedPlatform, function (res) {
                // if (res && res.success) {
                //   $scope.init();
                //   iaxeedSocketSrv.connect($rootScope.selectedPlatform);
                //   iaxeedSocketSrv.on('server:connected', function () {
                //     iaxeedSocketSrv.emit('user:connected', {
                //       user: JSON.parse($window.sessionStorage.plateformSession).user
                //     });

                //     $timeout(function () {
                //       $rootScope.$broadcast("platform:changed", $rootScope.selectedPlatform);

                //       $rootScope.change_dt = new Date();
                //     });
                //   });
                // } else {
                //   console.error(res);
                //   Notification.error({
                //     title: $translate.instant("ERROR"),
                //     message: $translate.instant("LOADING_ERROR")
                //   });
                // }
              }, function (err) {
                console.log(err);
              })
            }
          }
        }, function (err) {
          console.log(err);
        });
        //createTimeline();

      }
    });

    $scope.selectPlatform = function (platform) {

      //console.log(platform)
      $rootScope.selectedPlatform = (platform && platform._id) ? platform : undefined

      if (platform && platform.name == "ALL_PLATEFORMES") {
        $scope.mdl = []
        /*  $rootScope.selectedPlatform =platform */
        delete $window.sessionStorage.plateformSession
        delete $window.sessionStorage.plateformToken
        delete $window.sessionStorage.plateform

        dashboardSrv.getDataClients(undefined).then(
          function (res) { // On success

            $scope.gridOptions1.data = []
            dashboardSrv.getDataClients($rootScope.selectedPlatform).then(
              function (res) {
                $scope.hsCount = res.historique.length
                //       res.historique[2].client_status = 'Test';
                //res.historique[4].res.historique[3]
                // res.historique.length = 1;
                //$scope.histos = res.historique;
                $scope.gridOptions1.data = res.historique;
                $scope.cols = res.histocol.reverse();
                $scope.initcol($scope.cols);
                $scope.gridOptions1.columnDefs = $scope.colsdef,
                  console.log(res.historique);
              },
              function (err) {
                console.log(err);
              }
            );
          },
          function (err) { // On failure
            console.log(err);
          }
        );

      } else {

        $rootScope.selectedPlatform = platform
        $scope.gridOptions1.data = []
        dashboardSrv.getDataClients($rootScope.selectedPlatform).then(
          function (res) {
            $scope.hsCount = res.historique.length
            //res.historique[2].client_status = 'Test';
            //res.historique[4].res.historique[3]
            // res.historique.length = 1;
            //$scope.histos = res.historique;
            $scope.gridOptions1.data = res.historique;
            $scope.cols = res.histocol.reverse();
            $scope.initcol($scope.cols);
            $scope.gridOptions1.columnDefs = $scope.colsdef,
              console.log(res.historique);
          },
          function (err) {
            console.log(err);
          }
        );
      }
      if ($rootScope.selectedPlatform && $rootScope.selectedPlatform._id == platform._id) {

      } else {
        $rootScope.$broadcast("platform:selected");
      }
    };
    $scope.colsdef = [];
    $scope.initcol = function (array) {
      //console.log(array);
      $scope.colsdef = [];
      const years = array.length / 4;

      for (let i = 0; i < array.length; i++) {
        var group = '';
        for (let j = 0; j < years; j++) {
          if ((i > array.length - 5 - j * 4) && (i < array.length - j * 4)) group = $scope.year - j;
        }
        const element = array[i];

        var cold = {
          field: element,
          enableSorting: false,
          enableFiltering: false,
          width: '6%',
          visible: i > array.length - 13 ? true : false,
          //displayName: element,
          displayName: group.toString().substring(2, 4) + '  ' + 'TR' + element.substring(3, 4),
          group: group,
          cellClass: function (grid, row, col, rowRenderIndex, colRenderIndex) {
            if (grid.getCellValue(row, grid.columns[2]) == 'Test') {
              return 'ttest';
            } else if (grid.getCellValue(row, col) > grid.getCellValue(row, grid.columns[i + 2])) {
              return 'tmore';
            } else if (grid.getCellValue(row, col) == grid.getCellValue(row, grid.columns[i + 2])) {
              return 'tequal';
            } else if (grid.getCellValue(row, col) < grid.getCellValue(row, grid.columns[i + 2])) {
              return 'tless';
            } else {
              return 'tinit';
            }
          }
        }
        $scope.colsdef.push(cold)
      }
      $scope.colsdef.unshift({
        field: 'client_name',
        enableSorting: true,
        enableFiltering: true,
        displayName: "Clients",
        group: 'Contact Info',
        visible: true,
        sort: {
          direction: uiGridConstants.ASC,
          priority: 1
        }
      }, {
        field: 'client_status',
        enableSorting: true,
        enableFiltering: true,
        filter: {
          type: uiGridConstants.filter.SELECT,
          selectOptions: [{
              value: 'Client',
              label: 'Client'
            },
            {
              value: 'Test',
              label: 'Test'
            }
          ]
        },
        displayName: "Status",
        group: 'Contact Info',
        width: '100',
        visible: true,
        type: 'boolean',
        cellClass: 'veti',
        cellTemplate: '<i style="vertical-align: -webkit-baseline-middle; text-align:center !important;color:crimson;font-size:25px;" ng-if="row.entity.client_status.length==4" class="fa fa-times"></i><i style="vertical-align: -webkit-baseline-middle; text-align:center !important;color:green;font-size:25px;" ng-if="row.entity.client_status.length>4" class="fa fa-check"></i>'
      })
     // console.log($scope.colsdef)
    }
    $scope.gridOptions1 = {
      exporterMenuCsv: true,
      enableFiltering: true,
      enableGridMenu: false,
      gridMenuShowHideColumns: false,
      exporterPdfOrientation: 'landscape',
      exporterPdfPageSize: 'A3',
      exporterPdfTableHeaderStyle: {
        bold: true,
        fontSize: 12,
        color: 'black'
      },
      //exporterPdfMaxGridWidth: 600,
      rowHeight: 40,
      multiSelect: true,
      exporterCsvFilename: 'clients_' + moment().format("YYYY-MM-dd_HH-mm-ss") + '.csv',
      exporterExcelFilename: 'clients_' + moment().format("YYYY-MM-dd_HH-mm-ss") + '.xlsx',
      exporterOlderExcelCompatibility: false,
      columnDefs: $scope.colsdef,
      onRegisterApi: function (gridApi) {
        $scope.gridApi = gridApi;
      },
      paginationPageSizes: [5, 10, 20],
      paginationPageSize: 20,
    };
    $scope.export = function () {
      var myElement = angular.element(document.querySelectorAll(".custom-csv-link-location"));
      if ($scope.gridApi.selection.getSelectedRows().length == 0)
        $scope.gridApi.exporter.csvExport('all', 'all', myElement);
      else
        $scope.gridApi.exporter.csvExport('selected', 'all', myElement);
    };
    $scope.export3 = function () {
      var myElement = angular.element(document.querySelectorAll(".custom-csv-link-location"));
      if ($scope.gridApi.selection.getSelectedRows().length == 0)
        $scope.gridApi.exporter.excelExport('all', 'all', myElement);
      else
        $scope.gridApi.exporter.excelExport('selected', 'all', myElement);
    };
    $scope.export2 = function () {
      if ($scope.gridApi.selection.getSelectedRows().length == 0)
        $scope.gridApi.exporter.pdfExport('all', 'all');
      else
        $scope.gridApi.exporter.pdfExport('selected', 'all');
    };

    $scope.histos = [];
    dashboardSrv.getDataClients($rootScope.selectedPlatform).then(
      function (res) {
      //  console.log(res)
        $scope.hsCount = res.historique.length;
        //res.historique[2].client_status = 'Test';
        //res.historique[4].res.historique[3]
        // res.historique.length = 1;
        //$scope.histos = res.historique;
        $scope.gridOptions1.data = res.historique;
        $scope.cols = res.histocol.reverse();
        //$scope.cols = ['Y0Q1', 'Y0Q2']
        $scope.initcol($scope.cols);
        $scope.gridOptions1.columnDefs = $scope.colsdef;
       //   console.log(res.historique);
      },
      function (err) {
        console.log(err);
      }
    );
  });